import PropTypes from "prop-types";
import React from "react";

import { Button, Link } from "@swa-ui/core";

import { usePlacement } from "../usePlacement";

const TARGET_TYPE_COMMAND = "command";

export const CallToAction = (props) => {
  const {
    analyticsValue,
    additionalProps,
    ariaLabel,
    buttonType,
    children,
    className,
    external,
    externalLink,
    href,
    linkType,
    newWindow,
    style,
    styleType,
    suffixIcon,
    target,
    targetType,
    text,
    ...remainingProps
  } = props;
  const { configuredCommands, setPlacementsAnalytics } = usePlacement();
  const Component = getComponent();

  return shouldRenderDefaultLink() ? (
    <Link {...getDefaultLinkProps()}>{children ?? text}</Link>
  ) : (
    <Component {...getProps()}>{getContent()}</Component>
  );

  function shouldRenderDefaultLink() {
    return (!buttonType && !linkType) || linkType === "advertisement";
  }

  function getComponent() {
    let Node = shouldRenderProcessedLink() ? Link : Button;

    if (targetType === TARGET_TYPE_COMMAND) {
      Node = configuredCommands[target];
    }

    return Node;
  }

  function shouldRenderProcessedLink() {
    const componentType = linkType ?? buttonType;

    return componentType === "bright" || componentType === "dark" || componentType === "light";
  }

  function getContent() {
    return text ?? children?.[0]?.props?.children ?? children;
  }

  function getDefaultLinkProps() {
    return {
      ...props,
      external: external ?? externalLink,
      href: href ?? target,
      onClick: handleClick,
      suffixIcon: getSuffixIcon(),
    };
  }

  function getProps() {
    const adaptedProps = {
      ...additionalProps,
      "aria-label": ariaLabel,
      className,
      dark: linkType === "dark",
      external: external ?? externalLink,
      href: href ?? target,
      light: getLight(),
      newWindow,
      onClick: handleClick,
      style,
      styleType: getStyleType(),
      suffixIcon: getSuffixIcon(),
      ...getButtonOverrideComponent(),
    };

    return {
      ...remainingProps,
      ...adaptedProps,
    };
  }

  function handleClick() {
    const buttonTypeAnalytics = shouldRenderProcessedLink() ? "link" : "button";

    additionalProps?.onClick?.();
    remainingProps?.onClick?.();

    if (analyticsValue) {
      setPlacementsAnalytics({
        eventType: buttonTypeAnalytics,
        eventDescription: analyticsValue,
      });
    }
  }

  function getLight() {
    const componentType = linkType ?? buttonType;

    return componentType === "bright" || componentType === "light";
  }

  function getStyleType() {
    return shouldRenderProcessedLink() ? "link" : getFormattedButtonStyle();
  }

  function getFormattedButtonStyle() {
    const unformattedStyle = buttonType || linkType || styleType;
    let formattedStyle;

    if (unformattedStyle) {
      formattedStyle = unformattedStyle.split("button-")?.[1] ?? unformattedStyle;
    } else {
      formattedStyle = "no-style";
    }

    return formattedStyle;
  }

  function getSuffixIcon() {
    const iconAliases = {
      "arrow-right": {
        actions: ["rotate90"],
        name: "ArrowThin",
      },
    };
    const suffixIconProps =
      iconAliases[suffixIcon] ||
      (typeof suffixIcon === "string" ? { name: suffixIcon } : suffixIcon);

    return suffixIcon ? { ...suffixIconProps, color: style?.color } : undefined;
  }

  function getButtonOverrideComponent() {
    return buttonType && shouldRenderProcessedLink() ? { overrideComponent: "span" } : {};
  }
};

CallToAction.propTypes = {
  /** This object is spread and mapped to any existing prop for Button component. */
  additionalProps: PropTypes.object,

  /** Mapped to aria-label of Button or Link component. */
  ariaLabel: PropTypes.string,

  /**
   * Mapped to styleType of Button component.
   * Standard Buttons will have 'primary' or 'button-primary' semantic values, while
   * buttonType with link values 'bright'/'dark'/'light' should render a Link.
   */
  buttonType: PropTypes.string,

  /** Content that will be rendered on the Button or Link component. */
  children: PropTypes.node,

  /** Additional class to position the component. */
  className: PropTypes.string,

  /** Mapped to external of Link component. */
  externalLink: PropTypes.bool,

  /** Mapped to href of Button or Link component. Prefer using `target` instead. */
  href: PropTypes.string,

  /**
   * Mapped to dark/light of Link component.
   * Standard Links should have 'bright'/'dark'/'light' values, while linkType with button
   * values like 'primary' or 'button-primary' should render a Button.
   */
  linkType: PropTypes.string,

  /** Mapped to newWindow of Button or Link component. */
  newWindow: PropTypes.bool,

  /** Object to style the element. */
  style: PropTypes.object,

  /**
   * Buttons like primary and secondary look like standard buttons. Link styles looks like links,  and 'framed' and
   * 'no-style' are have minimal styling and are the responsibility of the app to style.
   */
  styleType: PropTypes.string,

  /** Mapped to suffixIcon of Link component. */
  suffixIcon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),

  /** Mapped to href of Button or Link component. */
  target: PropTypes.string,

  /** Mapped to children of Button or Link component. */
  text: PropTypes.string,
};
