import PropTypes from "prop-types";
import React from "react";

import { useDeviceInfo } from "@swa-ui/browser";
import {
  applyOverrides,
  PlacementFactory,
  PlacementSection,
  usePlacement,
  usePlacementIds,
} from "@swa-ui/placements";

const COMPONENT_TYPE = "component";
const LAYOUT_TYPE_HEADING = "heading";

export const PageFactory = (props) => {
  const { componentRenderers, placementId, renderLoading, showBootstrap } = props;
  const { error, isComplete, placement: pageLayoutPlacement } = usePlacement(placementId);
  const { screenSize } = useDeviceInfo();
  const { contentItems } = pageLayoutPlacement?.placement ?? [];
  const allPlacementIds = usePlacementIds(new RegExp("(.+)"));

  return contentItems?.map(renderSection);

  function renderSection(item) {
    const { id, type } = item;
    const { fullWidth } = applyOverrides(item, screenSize);
    let Component;

    if (!id && !type) {
      Component = (
        <PlacementSection {...item} key={item?.heading?.value} layout={LAYOUT_TYPE_HEADING} />
      );
    } else if (type === COMPONENT_TYPE) {
      Component = (
        <PlacementSection {...item} key={id}>
          {componentRenderers?.[id]?.()}
        </PlacementSection>
      );
    } else if (allPlacementIds.includes(id)) {
      Component = (
        <PlacementSection {...item} key={id}>
          <PlacementFactory {...getPlacementFactoryProps({ fullWidth, id })} />
        </PlacementSection>
      );
    } else if (!isComplete && !error) {
      Component = (
        <PlacementSection {...item} key={id}>
          {renderLoading?.()}
        </PlacementSection>
      );
    }

    return Component;
  }

  function getPlacementFactoryProps({ fullWidth, id }) {
    return {
      additionalProps: { fullWidth },
      componentRenderers,
      id,
      shouldWait: !showBootstrap,
    };
  }
};

PageFactory.defaultProps = {
  showBootstrap: true,
};

PageFactory.propTypes = {
  /** Callback functions with custom registered components. */
  componentRenderers: PropTypes.object,

  /** Placement ID to use to identify content to render. */
  placementId: PropTypes.string.isRequired,

  /** Function to run when placements are still loading. */
  renderLoading: PropTypes.func,

  /** Boolean to show or hide bootstrap content while placements are loading. */
  showBootstrap: PropTypes.bool,
};
