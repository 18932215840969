import PropTypes from "prop-types";
import React from "react";

export const Info2 = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path
        d="M14.8,29.5C6.7,29.5,0.1,23,0,15c-0.1-4,1.5-7.8,4.3-10.7C7.1,1.5,11-0.1,14.8,0c0.1,0,0.1,0,0.2,0c8,0,14.5,6.5,14.6,14.6
	c0.1,8.1-6.4,14.9-14.6,15C14.9,29.5,14.8,29.5,14.8,29.5z M14.6,2.3c-3.2,0-6.3,1.3-8.6,3.6c-2.4,2.4-3.7,5.6-3.7,9
	C2.4,21.8,8,27.2,14.8,27.2c0.1,0,0.1,0,0.2,0c6.9-0.1,12.4-5.8,12.3-12.6C27.2,7.7,21.5,2.2,14.8,2.3h0C14.7,2.3,14.7,2.3,14.6,2.3
	z"
      />
      <path
        d="M11.3,14.2c-0.1-0.3-0.3-0.5-0.4-0.8c1.3-1.2,2.7-2.2,4.6-2.2c0.3,0,0.6,0.1,1,0.1c1.3,0.4,1.9,1.4,1.6,2.6
	c-0.3,1-0.7,2-1,3.1c-0.4,1.1-0.8,2.1-1.1,3.2c-0.1,0.2-0.1,0.4-0.1,0.6s0,0.5,0.2,0.6c0.2,0.1,0.5,0,0.7-0.1
	c0.4-0.3,0.8-0.6,1.2-0.9c0.1-0.1,0.3-0.2,0.4-0.4c0,0,0.1,0.1,0.1,0.1c0.4,0.6,0.4,0.7-0.1,1.1c-0.9,0.7-1.7,1.5-2.7,2.1
	c-0.8,0.6-1.8,0.9-2.8,0.8c-1.4-0.1-2.3-1.1-1.9-2.5c0.4-1.6,1.1-3.2,1.6-4.8c0.3-0.9,0.7-1.7,1-2.5c0.1-0.1,0.1-0.3,0.1-0.5
	c0-0.2,0-0.6-0.1-0.7c-0.2-0.1-0.5-0.1-0.7,0c-0.4,0.2-0.8,0.6-1.2,0.8C11.5,14.1,11.4,14.1,11.3,14.2L11.3,14.2z"
      />
      <path
        d="M17.4,9.6c-1,0-1.8-0.6-2.1-1.4c-0.3-0.9,0.1-2,1-2.6C17.2,5,18.1,4.9,19,5.4c0.7,0.4,1.1,1.1,1,1.9
	C19.9,8.6,18.7,9.6,17.4,9.6L17.4,9.6z"
      />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "0 0 30 30",
    };
  }
};

Info2.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Info2.defaultProps = { role: "img" };
