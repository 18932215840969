import PropTypes from "prop-types";
import React, { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { Toast, useCaption } from "@swa-ui/core";
import { getRequestId, getResponseErrorKey, getResponseErrors, isResponseOk } from "@swa-ui/fetch";
import i18n, { updateI18n } from "@swa-ui/locale";

import { ServiceErrorMessage } from "./ServiceErrorMessage";

/**
 * MessageProvider provides four methods which display page-level messages in a React context so messages can be
 * displayed easily, and will exist for all applications.
 * displayMessage - Method to display a Toast message, which can be configured with @swa-ui/core Toast props.
 * props.
 * displayServiceNotifications - Method to display a Toast message for errors with a specific format, including the
 * error code.
 */

export const MessageContext = createContext();
export const MessageProvider = (props) => {
  const { children, experienceId, suppressMessageForTrackingCodes } = props;
  const { captionRef, hideCaption, isCaptionVisible, showCaption } = useCaption(
    "center",
    "hidden",
    handleToastDismiss
  );
  const [message, setMessage] = useState({});
  const { pathname } = useLocation();

  useEffect(() => {
    if (isCaptionVisible && message?.shouldCloseModalOnRouteChange) {
      handleToastClose();
    }
  }, [pathname]);

  return (
    <MessageContext.Provider
      value={{ displayMessage, displayServiceNotifications, setErrorContentOverrides }}
    >
      {isCaptionVisible && <Toast {...getToastProps()} />}
      {children}
    </MessageContext.Provider>
  );

  function displayMessage(
    type,
    body,
    heading = null,
    location = "center-to-window",
    onMessageClose = undefined,
    shouldCloseModalOnRouteChange = true,
    showBackgroundVeil = true
  ) {
    setMessage({
      body,
      heading,
      location,
      onMessageClose,
      shouldCloseModalOnRouteChange,
      showBackgroundVeil,
      type,
    });
    showCaption();
  }

  function displayServiceNotifications(
    response,
    responseBody,
    messageProps = {},
    customMessages = {},
    onMessageClose = undefined,
    shouldCloseModalOnRouteChange = true,
    showBackgroundVeil = true
  ) {
    const errors = getResponseErrors(response);
    const trackingCodes = errors
      .map(getErrorTracking)
      .filter((trackingCode) => !shouldSuppressTrackingCode(trackingCode));
    const errorKeys = trackingCodes.map(
      (trackingCode) => getResponseErrorKey(response) ?? trackingCode ?? getDefaultErrorKey()
    );
    const requestId = getRequestId(response);
    const heading = renderServiceNotificationHeading(errorKeys);
    const body = renderServiceNotificationBody({
      customMessages,
      errorKeys,
      messageProps,
      requestId,
      trackingCodes,
    });

    if (body && !isResponseOk(response)) {
      displayMessage(
        "error",
        body,
        heading,
        undefined,
        onMessageClose,
        shouldCloseModalOnRouteChange,
        showBackgroundVeil
      );
    }
  }

  function renderServiceNotificationBody({
    customMessages = {},
    errorKeys,
    messageProps = {},
    requestId,
    trackingCodes,
  }) {
    const errorKey = errorKeys.length === 1 && errorKeys[0];
    const bodyText = i18n(errorKey, messageProps);
    const customMessage = customMessages?.[errorKey];
    const serviceErrorMessageProps = {
      experienceId,
      requestId,
      trackingCode: trackingCodes.join(", "),
    };
    let body;

    if (customMessage) {
      body = customMessage(messageProps);
    } else if (bodyText !== errorKey) {
      body = bodyText;
    } else {
      body = i18n(getDefaultErrorKey());
    }

    return <ServiceErrorMessage {...serviceErrorMessageProps}>{body}</ServiceErrorMessage>;
  }

  function renderServiceNotificationHeading(errorKeys) {
    const errorKey = errorKeys[0];
    const titleKey = getTitleKeyFromErrorKey(errorKey);
    let heading = null;

    if (errorKeys.length > 1) {
      heading = i18n(getMultipleErrorTitleKey());
    } else {
      heading = i18n(titleKey);

      if (heading === titleKey) {
        heading = i18n(getDefaultErrorTitleKey());
      }
    }

    return heading;
  }

  function getToastProps() {
    return {
      "aria-label-close": i18n("MessageProvider__ARIA_LABEL_CLOSE_BUTTON"),
      captionRef,
      children: message.body,
      location: message.location,
      messageProps: {
        headingProps: {
          children: message.heading,
          styleLevel: 4,
        },
        styleType: message.type,
      },
      on: isCaptionVisible,
      onClose: handleToastClose,
      showBackgroundVeil: message.showBackgroundVeil,
    };
  }

  function getDefaultErrorKey() {
    return "MessageProvider__ERROR_DEFAULT";
  }

  function getDefaultErrorTitleKey() {
    return "MessageProvider__ERROR_DEFAULT_TITLE";
  }

  function getMultipleErrorTitleKey() {
    return "MessageProvider__ERROR_MULTIPLE_TITLE";
  }

  function getTitleKeyFromErrorKey(errorKey) {
    return `${errorKey}__TITLE`;
  }

  function getErrorTracking(error) {
    return error.code || error.trackingCode || error;
  }

  function handleToastDismiss() {
    message?.onMessageClose?.();
  }

  function handleToastClose() {
    hideCaption();
    handleToastDismiss();
  }

  function shouldSuppressTrackingCode(trackingCode) {
    return suppressMessageForTrackingCodes.includes(trackingCode);
  }

  function setErrorContentOverrides(errorOverrides) {
    updateI18n(errorOverrides);
  }
};

MessageContext.displayName = "MessageContext";
MessageProvider.propTypes = {
  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  children: PropTypes.node,

  /** A collection of custom component values that are used to replace the render result of their associated i18n keys. */
  errorContentOverrides: PropTypes.objectOf(PropTypes.func),

  /** Unique identifier for every customer session. This should be passed down from the application. */
  experienceId: PropTypes.string.isRequired,

  /**
   * List of error codes strings. If an error is return from a fetch, typically a toast message is displayed with the
   * error. If the error code is in this list, no error will be displayed.
   */
  suppressMessageForTrackingCodes: PropTypes.arrayOf(PropTypes.string),
};

MessageProvider.defaultProps = {
  suppressMessageForTrackingCodes: [],
};
