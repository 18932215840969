import React from "react";

import BannerGridLayout1 from "./BannerGridLayout1";
import BannerGridLayout2 from "./BannerGridLayout2";
import BannerGridLayout3 from "./BannerGridLayout3";
import BannerGridLayout4 from "./BannerGridLayout4";

export const BannerGrid = (props) => {
  const { placement } = props;
  const { layoutType } = placement;
  const layouts = {
    layout1: BannerGridLayout1,
    layout2: BannerGridLayout2,
    layout3: BannerGridLayout3,
    layout4: BannerGridLayout4,
  };

  const Component = layouts[layoutType];

  return Component ? <Component {...placement} /> : null;
};
