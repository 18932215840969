export const encryptData = async (
  encrypt,
  dataToEncrypt,
  path,
  component,
  feature,
  searchParamName = "searchToken"
) => {
  const JWEToken = await encrypt?.(dataToEncrypt, component, feature);
  let url;

  if (JWEToken) {
    url = new URL(path, window.location.origin);

    const params = url.searchParams;

    params.append(searchParamName, JWEToken);
  }

  return url?.toString();
};
