import { v4 } from "uuid";

import { window } from "@swa-ui/browser";

let id;

export const getExperienceId = () => {
  if (!id) {
    id = window.sessionStorage?.getItem?.("experienceId");

    if (!id) {
      id = v4();
      window.sessionStorage?.setItem?.("experienceId", id);
    }
  }

  return id;
};

export const setExperienceId = (experienceId) => {
  window.sessionStorage?.setItem?.("experienceId", experienceId);
  id = experienceId;
};
