import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { getBootstrapData } from "@swa-ui/bootstrap";
import { logger } from "@swa-ui/log";

export const BootstrapValidator = (props) => {
  const { bootstrapDependencies, children } = props;
  const [bootstrapValid, setBootstrapValid] = useState();

  useEffect(() => {
    setBootstrapValid(isBootstrapValid());
  }, []);

  return bootstrapValid ? children : null;

  function isBootstrapValid() {
    let valid = true;

    if (bootstrapDependencies) {
      const { optional, required } = bootstrapDependencies;

      logMissingBootstrapData(optional);
      valid = isBootstrapDataValid(required);
    }

    return valid;
  }

  function logMissingBootstrapData(dependencies) {
    isBootstrapDataValid(dependencies, false);
  }

  function isBootstrapDataValid(dependencies, dataIsRequired = true) {
    const loggerLevel = dataIsRequired ? "error" : "warn";
    let bootstrapDataIsValid = true;

    dependencies.map((dependency) => {
      const { id, schema } = dependency;
      const bootstrapData = getBootstrapData(id);

      if (!bootstrapData) {
        const loadFailedMessage = `Unable to load module ${id}`;

        console[loggerLevel](loadFailedMessage);
        logger[loggerLevel](loadFailedMessage);
        bootstrapDataIsValid = false;
      } else {
        try {
          schema && schema()?.validateSync(bootstrapData);
        } catch (error) {
          const validationFailedMessage = `Schema validation failed for ${id}`;

          console[loggerLevel](validationFailedMessage, error);
          logger[loggerLevel](validationFailedMessage);
          bootstrapDataIsValid = false;
        }
      }
    });

    return bootstrapDataIsValid;
  }
};

BootstrapValidator.propTypes = {
  /** Dependencies that need to be loaded before the app starts. */
  bootstrapDependencies: PropTypes.object,

  /** Content that will be rendered if dependencies load successfully. */
  children: PropTypes.node.isRequired,
};
