import PropTypes from "prop-types";
import React from "react";

import i18n from "@swa-ui/locale";

import { FlightIndicator } from "../FlightIndicator";
import styles from "./OvernightIndicator.module.scss";

/**
 * OvernightIndicator is a badge to indicate that the flight has overnight connection. Any connection that arrives before 4am
 * and departs after 4am. With the max connection time being 6 hrs and the minimum connection time being whatever the rule for
 * that airport is.
 */

export const OvernightIndicator = (props) => {
  const { className, disabled } = props;

  return <FlightIndicator {...getProps()}>{i18n("OvernightIndicator__TEXT")}</FlightIndicator>;

  function getProps() {
    return {
      ariaLabel: i18n("OvernightIndicator__TEXT_ARIA"),
      className,
      disabled,
      iconColor: "red-1",
      iconName: "NextDay",
      labelClass: styles.overnight,
    };
  }
};

OvernightIndicator.propTypes = {
  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Indicator to show disabled styling. */
  disabled: PropTypes.bool,
};
