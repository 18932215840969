import PropTypes from "prop-types";
import React, { createContext, useEffect, useState } from "react";

import { window } from "@swa-ui/browser";
import { swaDate } from "@swa-ui/date";
import motionPoint from "swa-motion-point/motionPoint";

const availableLocales = ["en", "es"];

export const LocaleContext = createContext();
export const LocaleProvider = (props) => {
  const { children } = props;
  const [locale] = useState(getCurrentLocale());

  useEffect(() => {
    swaDate.locale(locale);
  }, [locale]);

  return (
    <LocaleContext.Provider value={{ availableLocales, locale, switchLocale }}>
      {children}
    </LocaleContext.Provider>
  );

  function getCurrentLocale() {
    const currentLocale = window.MP?.UrlLang;

    return availableLocales.includes(currentLocale) ? currentLocale : availableLocales[0];
  }

  function switchLocale(newLocale) {
    const { MP } = window;

    motionPoint.SrcUrl = MP.SrcUrl;
    motionPoint.UrlLang = MP.UrlLang;
    motionPoint.init();
    window.MP = motionPoint;
    motionPoint.switchLanguage(newLocale);
  }
};

LocaleContext.displayName = "LocaleContext";
LocaleProvider.propTypes = {
  /** Content to be rendered on the page. */
  children: PropTypes.node.isRequired,
};
