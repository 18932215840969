import PropTypes from "prop-types";
import React from "react";

export const Gambling2 = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path
        d="M10,8.1c-1.1,0-2,0.9-2,2c0,1.1,0.9,2,2,2c1.1,0,2-0.9,2-2C12,9,11.1,8.1,10,8.1z M18.4,2.6
	c0-0.6-0.5-1.1-1.1-1.1H2.6C2,1.5,1.5,2,1.5,2.6v14.8c0,0.6,0.5,1.1,1.1,1.1h14.8c0.6,0,1.1-0.5,1.1-1.1V2.6z M20,2v16
	c0,1.1-0.9,2-2,2H2c-1.1,0-2-0.9-2-2V2c0-1.1,0.9-2,2-2h16C19.1,0,20,0.9,20,2z M5.9,8.1c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2
	c-1.1,0-2,0.9-2,2C4,7.2,4.8,8.1,5.9,8.1z M5.9,12.1c-1.1,0-2,0.9-2,2c0,1.1,0.9,2,2,2c1.1,0,2-0.9,2-2C7.9,13,7,12.1,5.9,12.1z
	 M14,4.1c-1.1,0-2,0.9-2,2c0,1.1,0.9,2,2,2c1.1,0,2-0.9,2-2C16,5,15.1,4.1,14,4.1z M16,14.1c0,1.1-0.9,2-2,2s-2-0.9-2-2
	c0-1.1,0.9-2,2-2C15.1,12.1,16,13,16,14.1z"
      />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "-3 -4 26 26",
    };
  }
};

Gambling2.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Gambling2.defaultProps = { role: "img" };
