import PropTypes from "prop-types";
import React from "react";

import { classNames } from "@swa-ui/string";

/**
 * Maintenance renders the Maintenance icon for RMAC.
 */

const BLUE_DARK = "#304cb2";
const BLUE_LIGHT = "#eaedf7";
const BLUE_MEDIUM_1 = "#acb7e0";
const BLUE_MEDIUM_2 = "#8394d1";
const BLUE_MEDIUM_3 = "#5970c1";

const Maintenance = (props) => {
  const { className, height, role, style, width } = props;

  return (
    <svg {...getProps()}>
      <rect
        x="8.59"
        y="14.14"
        width="14.36"
        height="3.26"
        transform="translate(-6.53 15.78) rotate(-45)"
        style={{ fill: BLUE_MEDIUM_1 }}
      />
      <path
        d="M5.05,20.51c-1.29,1.2-1.72,2.95-1.3,4.53,.04,.16,.25,.21,.37,.09l1.39-1.39c.44-.44,1.16-.44,1.6,0l1.17,1.17c.44,.44,.44,1.16,0,1.6l-1.39,1.39c-.12,.12-.07,.33,.09,.37,1.52,.41,3.21,.01,4.41-1.18,1.8-1.8,1.78-4.74-.06-6.52-1.71-1.66-4.54-1.69-6.29-.06Z"
        style={{ fill: BLUE_LIGHT, fillRule: "evenodd" }}
      />
      <path
        d="M7.24,19.38c.85,.17,1.65,.59,2.31,1.24,1.78,1.78,1.78,4.68,0,6.46-.66,.66-1.46,1.07-2.31,1.24,1.45,.3,3.03-.11,4.16-1.24,1.78-1.78,1.78-4.68,0-6.46-1.13-1.13-2.7-1.54-4.16-1.24Z"
        style={{ fill: BLUE_MEDIUM_2, fillRule: "evenodd" }}
      />
      <path
        d="M20.51,5.05c1.2-1.29,2.95-1.72,4.53-1.3,.16,.04,.21,.25,.09,.37l-1.39,1.39c-.44,.44-.44,1.16,0,1.6l1.17,1.17c.44,.44,1.16,.44,1.6,0l1.39-1.39c.12-.12,.33-.07,.37,.09,.41,1.52,.01,3.21-1.18,4.41-1.8,1.8-4.74,1.78-6.52-.06-1.66-1.71-1.69-4.54-.06-6.29Z"
        style={{ fill: BLUE_LIGHT, fillRule: "evenodd" }}
      />
      <path
        d="M19.38,7.24c.17,.85,.59,1.65,1.24,2.31,1.78,1.78,4.68,1.78,6.46,0,.66-.66,1.07-1.46,1.24-2.31,.3,1.45-.11,3.03-1.24,4.16-1.78,1.78-4.68,1.78-6.46,0-1.13-1.13-1.54-2.7-1.24-4.16Z"
        style={{ fill: BLUE_MEDIUM_2, fillRule: "evenodd" }}
      />
      <rect
        x="8.13"
        y="14.33"
        width="14.36"
        height="1.96"
        transform="translate(-6.34 15.31) rotate(-45)"
        style={{ fill: BLUE_MEDIUM_2 }}
      />
      <path
        d="M8.53,2.32h.64c.18,0,.33,.15,.33,.33V15.38h-1.31V2.66c0-.18,.15-.33,.33-.33Z"
        transform="translate(-3.67 8.85) rotate(-45)"
        style={{ fill: BLUE_LIGHT }}
      />
      <rect
        x="10.9"
        y="13.18"
        width="6.53"
        height="1.96"
        rx=".98"
        ry=".98"
        transform="translate(-5.87 14.16) rotate(-45)"
        style={{ fill: BLUE_MEDIUM_2 }}
      />
      <rect
        x="11.09"
        y="13.64"
        width="5.22"
        height="1.96"
        rx=".98"
        ry=".98"
        transform="translate(-6.33 13.97) rotate(-45)"
        style={{ fill: BLUE_MEDIUM_3 }}
      />
      <path
        d="M26.83,25.04l-1.79,1.79c-.53,.53-1.38,.53-1.91,0l-5.46-5.46c-.53-.53-.98-1.13-1.33-1.79h0c-.35-.66-.8-1.26-1.33-1.79l-1.54-1.54,2.77-2.77,1.55,1.55c.52,.52,1.12,.97,1.77,1.32l.05,.03c.65,.35,1.25,.8,1.77,1.32l5.45,5.45c.53,.53,.53,1.38,0,1.91Z"
        style={{ fill: BLUE_MEDIUM_3, fillRule: "evenodd" }}
      />
      <path
        d="M26.14,25.72l-1.34,1.34c-.39,.39-1.14,.29-1.67-.24l-5.46-5.46c-.53-.53-1-1.11-1.39-1.74h0c-.39-.62-.86-1.21-1.39-1.74l-1.54-1.54,2.08-2.08,1.55,1.55c.52,.52,1.1,.99,1.71,1.37l.05,.03c.61,.39,1.19,.85,1.71,1.37l5.45,5.45c.53,.53,.63,1.27,.24,1.67Z"
        style={{ fill: BLUE_DARK, fillRule: "evenodd" }}
      />
      <rect
        x="19.97"
        y="19.8"
        width="1.31"
        height="7.18"
        rx=".65"
        ry=".65"
        transform="translate(-10.5 21.43) rotate(-45)"
        style={{ fill: BLUE_MEDIUM_2 }}
      />
      <rect
        x="22.74"
        y="17.03"
        width="1.31"
        height="7.18"
        rx=".65"
        ry=".65"
        transform="translate(-7.73 22.58) rotate(-45)"
        style={{ fill: BLUE_MEDIUM_2 }}
      />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className: classNames(className),
      height,
      role,
      style,
      viewBox: "0 0 32 32",
      width,
    };
  }
};

Maintenance.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Indicates SVG's height. The width, if not given, will be sized according to SVG's aspect ratio. */
  height: PropTypes.string,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates SVG's width. The height, if not given, will be sized according to SVG's aspect ratio. */
  width: PropTypes.string,
};

Maintenance.defaultProps = {
  height: "32",
  role: "img",
};

export { Maintenance };
