import PropTypes from "prop-types";

import { DateSelector } from "@swa-ui/core";
import { swaDate } from "@swa-ui/date";

import { withFormField } from "../withFormField";
import { formFieldExternalPropTypes } from "../withFormField/FormField";

/**
 * CalendarFormField provides support for a standard DateSelector component to be used in a Form.
 */

export const CalendarFormField = withFormField(DateSelector, {
  renderCaption: (props, value) =>
    value && props.captionFormat ? swaDate(value).format(props.captionFormat) : "",
  useController: true,
});

CalendarFormField.propTypes = {
  ...formFieldExternalPropTypes,

  /** Optional swaDate format that will be used to display the selected date in the caption. */
  captionFormat: PropTypes.string,
};
