import i18n from "@swa-ui/locale";

export const currencyCodeData = {
  full: [
    {
      content: i18n("LABEL__USD"),
      value: "USD",
    },
    {
      content: i18n("LABEL__POINTS"),
      value: "POINTS",
    },
  ],
  short: [
    {
      content: i18n("SYMBOL__DOLLAR_SIGN"),
      value: "USD",
    },
    {
      content: i18n("LABEL__POINTS_SHORT"),
      value: "POINTS",
    },
  ],
};
