import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import { window } from "@swa-ui/browser";
import { classNames, getUniqueId } from "@swa-ui/string";

import { areaPropTypes } from "../Area";
import { Caption } from "../Caption";
import { keyCodes } from "../defines/keyCodes";
import { HorizontalList, horizontalListPropTypes } from "../HorizontalList";
import { Icon } from "../Icon";
import { SelectionMarker } from "../SelectionMarker";
import styles from "./SelectList.module.scss";

const DELAY_FOR_BROWSER = process.env.NODE_ENV === "test" ? 500 : 300;
const PADDING = 8;

/**
 * SelectList provides a flyout component to render a list of vertical options and a set of
 * horizontal options at the bottom of the flyout.
 */
export const SelectList = React.forwardRef((props, ref) => {
  const {
    alignment = "left",
    className,
    disabled = false,
    emptyContent,
    emptyContentDisplayValue,
    heading,
    items = [],
    minimumListWidth = "medium",
    onChange,
    onRevealChange,
    options = [],
    readOnly = false,
    revealed = false,
    value,
  } = props;
  const [captionState, setCaptionState] = useState("hidden");
  const [currentValue, setCurrentValue] = useState(
    value || (items[getInitialHighlightIndex()]?.value ?? "")
  );
  const [, setHasFocus] = useState(false);
  const [highlightIndex, setHighlightIndex] = useState(getInitialHighlightIndex());
  const [itemHeights, setItemHeights] = useState([]);
  const [readyToInitialize, setReadyToInitialize] = useState(false);
  const componentId = useRef(name || getUniqueId("select-list-id"));
  const containerRef = useRef();
  const contentsRef = useRef();
  const emptyContentRef = useRef();
  const headingRef = useRef();
  const itemRefs = useRef([]);
  const listRef = useRef();
  const optionsRef = useRef();
  const triggerRef = useRef();

  useEffect(() => {
    setItemHeights(getItemHeights());
  }, [readyToInitialize]);

  useEffect(() => {
    if (captionState !== "hidden") {
      setReadyToInitialize(true);
    }
  }, [captionState]);

  useEffect(() => {
    revealed ? showCaption() : hideCaption();
  }, [revealed]);

  return (
    <div {...getContainerProps()}>
      <Caption {...getCaptionProps()}>{renderTrigger()}</Caption>
    </div>
  );

  function renderTrigger() {
    return (
      <div {...getTriggerProps()}>
        <div>{getSelectedLabel()}</div>
        <Icon {...getIconProps()} />
      </div>
    );
  }

  function renderList() {
    return (
      <div className={styles.captionContent} onBlur={handleBlur}>
        {heading && renderHeading()}
        <div {...getOuterContainerProps()}>
          {items?.length ? renderItems() : renderEmptyMessage()}
        </div>
        <div className={styles.options} onMouseDown={handleOptionsMouseDown} ref={optionsRef}>
          <div onClick={handleOptionClick} onFocus={(event) => event.stopPropagation()}>
            <HorizontalList {...getOptionsProps()} />
          </div>
        </div>
      </div>
    );
  }

  function renderHeading() {
    return <div {...getHeadingContainerProps()}>{heading}</div>;
  }

  function renderEmptyMessage() {
    return (
      <div className={getContentClass()} ref={emptyContentRef}>
        {emptyContent}
      </div>
    );
  }

  function renderItems() {
    return (
      <>
        {itemHeights?.length && <SelectionMarker {...getSelectionMarkerProps()} />}
        {renderListItems()}
      </>
    );
  }

  function renderListItems() {
    return <ul {...getListProps()}>{items.map(renderListItem)}</ul>;
  }

  function renderListItem(item, index) {
    return <li {...getListItemProps(item, index)}>{item.label}</li>;
  }

  function getContainerProps() {
    return {
      className: classNames(className),
      ref: contentsRef,
    };
  }

  function getCaptionProps() {
    return {
      adjoiningContent: renderList(),
      alignment,
      id: `${componentId.current}-caption`,
      location: disabled || readOnly ? "hidden" : captionState,
      mainClassName: styles.mainContent,
    };
  }

  function getTriggerProps() {
    return {
      "aria-activedescendant":
        captionState !== "hidden" ? `${getItemId(highlightIndex)}` : undefined,
      "aria-controls": `${componentId.current}-caption`,
      "aria-describedby": props["aria-describedby"],
      "aria-expanded": captionState !== "hidden",
      "aria-label": props["aria-label"],
      "aria-owns": `${componentId.current}-list`,
      className: getTriggerClass(),
      onBlur: handleBlur,
      onFocus: handleFocus,
      onKeyDown: disabled ? undefined : handleTriggerKeyDown,
      onMouseDown: disabled ? undefined : handleTriggerMouseDown,
      ref: ref ? setUpPassedRef : triggerRef,
      role: "combobox",
      tabIndex: disabled ? undefined : 0,
    };
  }

  function getIconProps() {
    return {
      className: styles.indicator,
      color: disabled ? "select-list-disabled" : "select-list-icon",
      custom: { type: captionState === "hidden" ? "active" : "inactive" },
      name: "ArrowThin",
    };
  }

  function getOuterContainerProps() {
    return {
      className: styles.outerContainer,
      ref: containerRef,
    };
  }

  function getHeadingContainerProps() {
    return {
      className: styles.heading,
      onMouseDown: (event) => {
        event.stopPropagation();
        event.preventDefault();
      },
      ref: headingRef,
    };
  }

  function getSelectionMarkerProps() {
    return {
      height: !isFocusOnList() || !itemHeights[highlightIndex] ? 0 : itemHeights[highlightIndex],
      yOffset: Math.max(getOffsetFromIndex(highlightIndex), 0) + PADDING + getHeadingHeight(),
    };
  }

  function getOptionsProps() {
    return {
      className: styles.options,
      items: options,
      separator: false,
      spacing: "small",
    };
  }

  function getListProps() {
    return {
      className: getListClass(),
      id: `${componentId.current}-list`,
      onKeyDown: handleKeyDown,
      onMouseMove: handleMouseMove,
      ref: listRef,
      role: "listbox",
      tabIndex: 0,
    };
  }

  function getListItemProps(listItem, index) {
    return {
      "aria-selected": listItem.value === currentValue,
      className: getListItemClass(listItem),
      id: `${getItemId(index)}`,
      key: listItem.value,
      onClick: handleItemClick.bind(this, index),
      ref: (element) => (itemRefs.current[index] = element),
      role: "option",
    };
  }

  function getTriggerClass() {
    return classNames(styles.trigger, {
      [styles.disabled]: disabled,
      [styles.focus]: isFocusOnTrigger() || isFocusOnTriggerChild(),
    });
  }

  function getHeadingHeight() {
    return headingRef?.current?.getBoundingClientRect().height ?? 0;
  }

  function isFocusOnList() {
    return document.activeElement === listRef.current;
  }

  function isFocusOnTrigger() {
    return document.activeElement === triggerRef.current;
  }

  function isFocusOnTriggerChild() {
    return listRef?.current?.contains(document.activeElement);
  }

  function getListItemClass(listItem) {
    return classNames(styles.dropDownListItem, listItem.className, {
      [styles.disabled]: listItem.disabled,
    });
  }

  function getListClass() {
    return classNames(styles.list, getContentClass());
  }

  function getContentClass() {
    return classNames({
      [styles.minimumListWidthLarge]: minimumListWidth === "large",
      [styles.minimumListWidthMedium]: minimumListWidth === "medium",
      [styles.minimumListWidthSmall]: minimumListWidth === "small",
      [styles.minimumListWidthXlarge]: minimumListWidth === "xlarge",
      [styles.minimumListWidthXsmall]: minimumListWidth === "xsmall",
    });
  }

  function handleTriggerKeyDown(event) {
    const { key } = event;
    const actionableKeys = [
      keyCodes.KEY_DOWN,
      keyCodes.KEY_END,
      keyCodes.KEY_ENTER,
      keyCodes.KEY_ESCAPE,
      keyCodes.KEY_HOME,
      keyCodes.KEY_LEFT,
      keyCodes.KEY_PAGE_DOWN,
      keyCodes.KEY_PAGE_UP,
      keyCodes.KEY_RIGHT,
      keyCodes.KEY_SPACE,
      keyCodes.KEY_UP,
    ];

    if (actionableKeys.indexOf(key) !== -1 && captionState === "hidden") {
      openCaption();
    }
  }

  function handleKeyDown(event) {
    const { key } = event;

    if (key === keyCodes.KEY_DOWN || key === keyCodes.KEY_RIGHT) {
      processDown(event);
    } else if (key === keyCodes.KEY_ENTER) {
      processEnter(event);
    } else if (key === keyCodes.KEY_ESCAPE) {
      processEscape();
    } else if (key === keyCodes.KEY_SPACE) {
      processEnter(event);
    } else if (key === keyCodes.KEY_END || key === keyCodes.KEY_PAGE_DOWN) {
      processEnd(event);
    } else if (key === keyCodes.KEY_HOME || key === keyCodes.KEY_PAGE_UP) {
      processHome(event);
    } else if (key === keyCodes.KEY_LEFT || key === keyCodes.KEY_UP) {
      processUp(event);
    }
  }

  function handleTriggerMouseDown() {
    if (captionState === "hidden" && !readOnly) {
      openCaption();
    } else {
      hideCaption();
    }
  }

  function handleOptionsMouseDown(event) {
    if (!optionsRef.current.contains(event.relatedTarget)) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  function handleBlur(event) {
    setHasFocus(false);

    if (!contentsRef.current.contains(event.relatedTarget)) {
      hideCaption();
    }
  }

  function handleFocus(event) {
    setHasFocus(true);

    if (!contentsRef.current.contains(event.relatedTarget)) {
      openCaption();
    }
  }

  function handleItemClick(index, event) {
    const listItem = items[index];

    if (listItem.disabled) {
      event.preventDefault();
    } else {
      setNewSelection(index);
      delayCloseCaption();
    }
  }

  function handleOptionClick() {
    delayCloseCaption();
  }

  function handleMouseMove(event) {
    const mousePosition = event.clientY - containerRef.current.getBoundingClientRect().top;
    const newHighlightIndex = findIndexFromOffset(mousePosition);

    if (!items[newHighlightIndex].disabled) {
      setHighlightIndex(newHighlightIndex);
    }
  }

  function processEscape() {
    delayCloseCaption();
  }

  function processEnter(event) {
    if (captionState === "hidden") {
      showCaption();
    } else {
      setNewSelection(highlightIndex);
      delayCloseCaption();
    }

    event.stopPropagation();
    event.preventDefault();
  }

  function processDown(event) {
    setHighlightIndex(getNextHighlightIndex(highlightIndex));
    event.preventDefault();
  }

  function processUp(event) {
    setHighlightIndex(getPreviousHighlightIndex(highlightIndex));
    event.preventDefault();
  }

  function processEnd(event) {
    let newHighlightIndex = items.length - 1;

    if (items[newHighlightIndex].disabled) {
      newHighlightIndex = getPreviousHighlightIndex(newHighlightIndex);
    }

    setHighlightIndex(newHighlightIndex);
    event.preventDefault();
  }

  function processHome(event) {
    let newHighlightIndex = 0;

    if (items[newHighlightIndex].disabled) {
      newHighlightIndex = getNextHighlightIndex(0);
    }

    setHighlightIndex(newHighlightIndex);
    event.preventDefault();
  }

  function getItemHeights() {
    return itemRefs.current.map((item) => item.getBoundingClientRect().height);
  }

  function getNextHighlightIndex(index) {
    const itemsBelowHighlight = items.slice(index + 1);
    const workingHighlightIndex = itemsBelowHighlight.findIndex((item) => !item.disabled);
    let newHighlightIndex = index;

    if (workingHighlightIndex !== -1) {
      newHighlightIndex = workingHighlightIndex + index + 1;
    }

    return newHighlightIndex;
  }

  function getPreviousHighlightIndex(index) {
    const itemsAboveHighlight = items.slice(0, index).reverse();
    const workingHighlightIndex = itemsAboveHighlight.findIndex((item) => !item.disabled);
    let newHighlightIndex = index;

    if (workingHighlightIndex !== -1) {
      newHighlightIndex = index - workingHighlightIndex - 1;
    }

    return newHighlightIndex;
  }

  function setUpPassedRef(element) {
    typeof ref === "function" && ref(element);
    triggerRef.current = element;
  }

  function getInitialHighlightIndex() {
    let index = 0;

    if (items.length && value) {
      index = items.findIndex((item) => item.value === value);
    }

    return index;
  }

  function setNewSelection(newSelectedIndex) {
    const newValue = items[newSelectedIndex]?.value ?? "";

    setCurrentValue(newValue);
    onChange?.({ target: { value: newValue } }, newSelectedIndex);
  }

  function getItemId(index) {
    return `${componentId.current}-item-${index}`;
  }

  function getSelectedLabel() {
    const item = items[findIndexFromValue(items, currentValue)];

    return item?.displayValue ?? emptyContentDisplayValue;
  }

  function getOffsetFromIndex(index) {
    const heights = index > 0 ? itemHeights.slice(0, index) : [];

    return heights.reduce((partialSum, height) => partialSum + height, 0);
  }

  function findIndexFromOffset(offset) {
    const itemCount = items.length;
    let accumulated = 0;

    const index = itemHeights.findIndex((height) => {
      accumulated += height;

      return accumulated > offset;
    });

    return index === -1 ? itemCount - 1 : index;
  }

  function findIndexFromValue(initializedItems, workingValue) {
    return initializedItems.findIndex((item) => item.value === workingValue);
  }

  function delayCloseCaption() {
    triggerRef.current.focus();
    window.setTimeout(() => {
      hideCaption();
    }, DELAY_FOR_BROWSER);
  }

  function hideCaption() {
    setCaptionState("hidden");
    onRevealChange?.(false);
  }

  function openCaption() {
    if (!readOnly) {
      window.setTimeout(() => listRef.current?.focus(), DELAY_FOR_BROWSER);
      showCaption();
    }
  }

  function showCaption() {
    setCaptionState("below");
    onRevealChange?.(true);
  }
});

SelectList.propTypes = {
  /**
   * This prop controlls whether the drop-down portion of SelectList is right or left aligned to the
   * trigger portion.
   */
  alignment: PropTypes.oneOf(["left", "right"]),

  /**
   * aria-describedby id to element which provides additional accessibility description of container
   * element.
   */
  "aria-describedby": PropTypes.string,

  /** aria-label text to provide additional accessibility description of container element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component
   * for layout purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Indicates Dropdown should apply disabled styling and ignore mouse and key events. */
  disabled: PropTypes.bool,

  /**
   * Content to be displayed when there are not list items. When given, emptyContentDisplayValue
   * must also be specified.
   */
  emptyContent: PropTypes.node,

  /**
   * Content to be displayed when there are not list items and when emptyContent is rendered.
   * emptyContentDisplayValue is rendered as SelectList's trigger.
   */
  emptyContentDisplayValue: PropTypes.string,

  /** Optional content to be displayed at the top of SelectList's flyout. */
  heading: PropTypes.node,

  /** Array of items to define the options available in SelectList's "main" vertical option list. */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool,
      displayValue: PropTypes.string,
      label: PropTypes.node,
      value: PropTypes.string,
    })
  ),

  /** Width of the trigger and flyout. */
  minimumListWidth: PropTypes.oneOf(["large", "medium", "small", "xlarge", "xsmall"]),

  /** Callback function to learn when a selection is made in "main" option list, and the parameters
   *  are the event object and the index of the selected option. */
  onChange: PropTypes.func,

  /** Function to learn when SelectList is opened and closed. */
  onRevealChange: PropTypes.func,

  /**
   * Array of items to provide additional action items at bottom of flyout. These values are
   * passed to HorizontalList component.
   */
  options: horizontalListPropTypes.items,

  /** Indicates this component should be immutable and ignore mouse and key events. */
  readOnly: PropTypes.bool,

  /** Indicates if the area is initially opened or closed. */
  revealed: areaPropTypes.revealed,

  /** Value to be maintained by caller. This will define which option in vertical list is selected. */
  value: PropTypes.string,
};
