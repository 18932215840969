import PropTypes from "prop-types";
import React from "react";

import globalRules from "@swa-ui/core/assets/styles/globalRules.module.scss";
import i18n from "@swa-ui/locale";
import { classNames } from "@swa-ui/string";

import currencyParser from "../currencyParser";
import styles from "./Currency.module.scss";

export const Currency = (props) => {
  const {
    className,
    currencyCode,
    customLabel,
    emphasis,
    largeSymbol,
    maxValue,
    negativeSignShown,
    positiveSignShown,
    roundType,
    showSignForZero,
    showSymbol,
    superScriptSymbol,
    value,
  } = props;

  return (
    <span className={getClassName()}>
      <span className={getCurrencyProps()}>
        {renderAccessibleInformation()}
        <span aria-hidden="true">
          {renderNegativeSign()}
          {renderPositiveSign()}
          {renderSymbol("BEFORE")}
          <span>{renderValue()}</span>
          {renderMaxValueSign()}
          {renderSymbol("AFTER")}
          {renderCustomLabel()}
        </span>
      </span>
    </span>
  );

  function getClassName() {
    return classNames(
      className,
      {
        [styles.emphasis]: emphasis,
      },
      "currency"
    );
  }

  function getCurrencyProps() {
    return currencyCode === "USD" ? styles.dollars : styles.points;
  }

  function renderAccessibleInformation() {
    return (
      <span className={globalRules.hiddenFromScreen}>
        {minusScreenReaderInfo()}
        {i18n(getCurrencyLabelKey(), {
          value: getParsedValue(true),
        })}
      </span>
    );
  }

  function minusScreenReaderInfo() {
    return shouldShowNegativeSign() ? i18n("Currency__MINUS__SCREEN_READER") : null;
  }

  function getCurrencyLabelKey() {
    let currencyLabelKey = `Currency__${currencyCode}__SCREEN_READER`;

    if (isMaxExceeded()) {
      currencyLabelKey += "_MAX";
    }

    return currencyLabelKey;
  }

  function renderNegativeSign() {
    return shouldShowNegativeSign() ? "−" : null;
  }

  function renderPositiveSign() {
    return shouldShowPositiveSign() ? "+" : null;
  }

  function renderSymbol(type) {
    const currency = getCurrency();
    let symbol = null;

    if (showSymbol && !largeSymbol && type === currency?.symbolPosition) {
      symbol = <span className={getCurrencySymbolClass()}>{currency?.symbol}</span>;
    }

    return symbol;
  }

  function renderCustomLabel() {
    return customLabel && <span>{customLabel}</span>;
  }

  function renderValue() {
    return getParsedValue();
  }

  function renderMaxValueSign() {
    return isMaxExceeded() ? getCurrency().maxValueSign : null;
  }

  function getCurrencySymbolClass() {
    return classNames({
      symbol: true,
      symbolSuperscript: superScriptSymbol,
    });
  }

  function getCurrency() {
    const finalValue = isMaxExceeded() ? maxValue : value;

    return currencyParser(finalValue, currencyCode);
  }

  function getParsedValue(hideSymbol) {
    const parsedCurrency = getCurrency();

    return parsedCurrency[getParsedValueMethod(hideSymbol)]();
  }

  function getParsedValueMethod(hideSymbol, showLabel) {
    let currencyMethod;

    if (roundType === "ceil") {
      currencyMethod = "roundedUpValue";
    } else if (roundType === "floor") {
      currencyMethod = "roundedDownValue";
    } else {
      currencyMethod = "fullValue";
    }

    if (!hideSymbol && showSymbol && largeSymbol) {
      currencyMethod += "WithSymbol";
    } else if (showLabel && hideSymbol) {
      currencyMethod += "WithLabel";
    }

    return currencyMethod;
  }

  function shouldShowNegativeSign() {
    const { negative, number } = getCurrency() ?? {};

    return (negativeSignShown || negative) && shouldShowSignForNumber(number);
  }

  function shouldShowPositiveSign() {
    const { negative, number } = getCurrency() ?? {};

    return positiveSignShown && !negative && shouldShowSignForNumber(number);
  }

  function shouldShowSignForNumber(number) {
    return number > 0 || (number === 0 && showSignForZero);
  }

  function isMaxExceeded() {
    return value > parseInt(maxValue);
  }
};

export const currencyPropTypes = {
  className: PropTypes.string,
  currencyCode: PropTypes.oneOf(["USD", "POINTS"]).isRequired,
  customLabel: PropTypes.string,
  emphasis: PropTypes.bool,
  maxValue: PropTypes.number,
  negativeSignShown: PropTypes.bool,
  positiveSignShown: PropTypes.bool,
  roundType: PropTypes.oneOf(["none", "ceil", "floor"]),
  showFullValue: PropTypes.bool,
  showSignForZero: PropTypes.bool,
  showSymbol: PropTypes.bool,
  superScriptSymbol: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

Currency.propTypes = currencyPropTypes;

Currency.defaultProps = {
  roundType: "none",
  showSymbol: true,
  superScriptSymbol: false,
};
