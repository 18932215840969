import PropTypes from "prop-types";
import React from "react";

export const DiningCircle = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path
        d="M19,0C8.5,0,0,8.5,0,19s8.5,19,19,19s19-8.5,19-19S29.5,0,19,0z M30.1,28.3c0.6,0.6,0.6,1.6,0,2.2
	c-0.6,0.6-1.6,0.6-2.2,0l-9-9.4l-8.3,9.4c-0.6,0.6-1.6,0.6-2.2,0c-0.6-0.6-0.6-1.6,0-2.2l8.9-8.9l-1.4-1.4l-2.7-0.7
	c-0.6-0.1-1.1-0.4-1.6-0.8c-0.1-0.1-0.2-0.2-0.3-0.3l-4.7-5.9c-0.2-0.1-0.1-0.4,0-0.6c0.2-0.2,0.5-0.2,0.6-0.1l4.9,4.9
	c0.2,0.2,0.5,0.2,0.7,0c0.2-0.2,0.2-0.5,0-0.7L8,9C7.9,8.9,7.9,8.6,8.1,8.4c0.2-0.1,0.5-0.2,0.6,0l4.9,4.9c0.2,0.2,0.5,0.2,0.7,0
	c0.2-0.2,0.2-0.5,0-0.7L9.4,7.7C9.2,7.5,9.2,7.3,9.4,7.1c0.2-0.2,0.5-0.2,0.6,0l5.9,4.7c0.1,0.1,0.2,0.1,0.3,0.3
	c0.5,0.5,0.7,1,0.8,1.6l0.7,2.7l1.4,1.4L29.3,7.5c0.5-0.5,1.2-0.5,1.8,0c1.9,2-3.4,7-3.4,7l-3.8,3.9c-0.3,0.4-0.8,0.6-1.3,0.6
	c-0.1,0-0.6,0.1-1,0.1c-0.1,0.1-0.4,0.2-0.6,0.3L30.1,28.3z"
      />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "0 0 38 38",
    };
  }
};

DiningCircle.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

DiningCircle.defaultProps = { role: "img" };
