const startingSlash = "^\\/";
const trailingSlash = "\\/$";
const htmlSuffix = "\\.html$";
const v2Prefix = "\\/v2/";
const removeRegex = new RegExp(
  `(${v2Prefix}|${startingSlash}|${trailingSlash}|${htmlSuffix})`,
  "g"
);
let pageIdPrefix = "";

export const getPageId = (pathname) => {
  let pageId = replaceSlashesWithDashes(removePrefixAndSuffix(pathname));

  if (pageId.length === 0) {
    pageId = "landing-home-page";
  }

  return `${pageIdPrefix}${pathname.slice(-1) === "/" ? `${pageId}-index` : pageId}`;
};
export const setPageIdPrefix = (prefix) => {
  pageIdPrefix = prefix;
};

function removePrefixAndSuffix(pathname) {
  return pathname.replace(removeRegex, "");
}

function replaceSlashesWithDashes(pathname) {
  return pathname.replace(/\//g, "-");
}
