import PropTypes from "prop-types";
import React, { useEffect } from "react";

import { window } from "@swa-ui/browser";
import { classNames } from "@swa-ui/string";

import styles from "./BackgroundVeil.module.scss";

/**
 * BackgroundVeil simply fills the entire viewport with a dark, almost-opaque "veil" to hide the page content.
 */

export const BackgroundVeil = (props) => {
  const { className, delayedStart, on, onClick, onKeyDown } = props;

  useEffect(() => {
    if (on && onKeyDown) {
      window.addEventListener("keydown", onKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  }, [on]);

  return <div {...getProps()} />;

  function getProps() {
    return {
      className: getClass(),
      onClick,
    };
  }
  function getClass() {
    return classNames({
      [className]: className,
      [styles.backgroundVeil]: true,
      [styles.delayedStart]: delayedStart,
      [styles.hidden]: !on,
    });
  }
};

BackgroundVeil.propTypes = {
  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Allows the background fade in to be delay approximately a half second. */
  delayedStart: PropTypes.bool,

  /** Indicates if background will be obfuscated. If true, the background will be "grayed". */
  on: PropTypes.bool,

  /** Callback that will be called when background is clicked. */
  onClick: PropTypes.func,

  /** Callback that will be called when a key is pressed. */
  onKeyDown: PropTypes.func,
};

BackgroundVeil.defaultProps = {
  on: false,
};
