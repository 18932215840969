import PropTypes from "prop-types";
import React from "react";

export const Skiing = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path d="M12.6592 2.81309C13.4358 2.81309 14.0651 2.1832 14.0651 1.40654C14.0651 0.629883 13.4355 0 12.6592 0C11.8828 0 11.2535 0.629883 11.2535 1.40654C11.2535 2.1832 11.8828 2.81309 12.6592 2.81309ZM14.9736 13.7432C14.9736 13.3336 14.6417 13.0415 14.2737 13.0415C13.7203 13.0415 13.7104 13.6014 12.9217 13.6014C12.7612 13.6014 12.6032 13.5697 12.4567 13.5021L8.47822 11.4437L9.91816 9.28098C10.0777 9.04321 10.1552 8.77151 10.1552 8.50081C10.1552 8.13753 10.0153 7.7763 9.74238 7.50765L8.55937 6.32142L5.45098 4.76575C5.4375 4.85742 5.43164 4.95117 5.43164 5.04492C5.43164 5.53271 5.6173 6.00527 5.97129 6.35918L8.16943 8.5626L6.82529 10.5771L1.02451 7.58203C0.921094 7.5293 0.809766 7.50293 0.701074 7.50293C0.283447 7.50293 0 7.8457 0 8.20605C0 8.46015 0.138721 8.70527 0.379687 8.82832L11.8641 14.7639C12.2121 14.925 12.5804 15.0017 12.9504 15.0017C14.0654 15 14.9736 14.2588 14.9736 13.7432ZM3.48926 2.68506L3.17871 3.3457C3.3457 3.42773 3.52148 3.46875 3.69727 3.46875C3.96542 3.46875 4.22754 3.375 4.43145 3.20209L5.81602 3.89086C5.81235 3.89086 5.81235 3.89452 5.80869 3.89818L9.16318 5.56107L10.1156 5.18021L10.3024 5.74066C10.4196 6.09603 10.6724 6.38549 11.0055 6.55394L12.7089 7.37719C12.8439 7.44419 12.9859 7.47548 13.1266 7.47548C13.6774 7.47548 14.0636 7.02343 14.0636 6.54003C14.0636 6.19608 13.8743 5.86327 13.5438 5.69921L12.0195 4.93456L11.5179 3.43603C11.3262 2.89131 10.6699 2.16768 9.75879 2.16768C9.53443 2.16768 9.29531 2.21174 9.04307 2.31223L6.66211 3.26074L4.83105 2.35342C4.83132 2.34328 4.83145 2.33314 4.83145 2.32301C4.83145 1.90904 4.61266 1.51148 4.21944 1.31139L3.89355 1.94678L2.87607 1.74111C2.86611 1.73906 2.85732 1.73877 2.84736 1.73877C2.76926 1.73877 2.69279 1.80255 2.69279 1.89325C2.69279 1.93641 2.71178 1.97927 2.74582 2.0093L3.48926 2.68506Z" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "0 0 16 16",
    };
  }
};

Skiing.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Skiing.defaultProps = { role: "img" };
