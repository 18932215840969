import PropTypes from "prop-types";
import React from "react";

export const Flight = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path d="M27.2815227,8.37777778 C29.9333333,5.73333333 29.9333333,3.78888889 29.9333333,3.08888889 C29.9333333,3.08888889 29.9333333,2.46666667 29.69935,2.23333333 C29.4653668,2 28.8414113,2 28.8414113,2 C28.2174559,2 26.1896007,2 23.5377902,4.72222222 L19.0921077,9.15555556 L4.97511606,5.42222222 C4.2731662,5.18888889 3.80519963,5.65555556 3.80519963,5.65555556 L1.93333333,7.52222222 L14.1004643,14.1333333 L12.4625812,15.7666667 C11.5266481,16.6222222 10.5907149,17.9444444 9.73277623,19.1111111 L5.52107707,18.5666667 C4.66313835,18.4111111 4.19517177,18.9555556 4.19517177,18.9555556 L2.32330548,20.8222222 L7.2369545,23.0777778 C6.61299907,24.2444444 6.76898793,25.1 6.76898793,25.1 L6.76898793,25.1 L6.76898793,25.1 L6.76898793,25.1777778 L6.84698236,25.1777778 L6.84698236,25.1777778 C6.84698236,25.1777778 7.70492108,25.3333333 8.87483751,24.7111111 L11.0586815,29.6111111 L12.9305478,27.7444444 C12.9305478,27.7444444 13.3985144,27.2777778 13.32052,26.4222222 L13.32052,26.4222222 L12.8525534,22.1444444 C14.0224698,21.2888889 15.3483751,20.2777778 16.2063138,19.4222222 L17.8441968,17.7888889 L17.8441968,17.7888889 L24.3957289,30 L26.2675952,28.1333333 C26.2675952,28.1333333 26.7355617,27.6666667 26.5795729,26.9666667 L22.8358403,12.8888889 L22.8358403,12.8888889 L27.2815227,8.37777778 L27.2815227,8.37777778 Z" />
    </svg>
  );

  function getProps() {
    return {
      "aria-hidden": props["aria-hidden"],
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      style,
      viewBox: "0 0 32 32",
    };
  }
};

Flight.propTypes = {
  /** aria-hidden text to hide non-interactive content from the accessibility API. */
  "aria-hidden": PropTypes.string,

  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Flight.defaultProps = {
  role: "img",
};
