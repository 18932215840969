import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

/**
 * NoServerSideRendering suppresses rendering of children until the component is mounted. This
 * is useful for components that rely on the DOM being available. For example, if you are using
 * a third-party library that requires the DOM to be available, you can wrap the component in
 * NoServerSideRendering to prevent the component from rendering on the server.  This is
 * particularly useful for components that are rendered on the server and the client.
 */

export const NoServerSideRendering = (props) => {
  const { children, fallback = null } = props;
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return <>{isMounted ? children : typeof fallback === "function" ? fallback() : fallback}</>;
};

NoServerSideRendering.propTypes = {
  /** Content that will be rendered as the page body. */
  children: PropTypes.node.isRequired,

  /** Optional fallback content to be rendered while the component is mounting. */
  fallback: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
};
