import PropTypes from "prop-types";
import React from "react";

import { classNames } from "@swa-ui/string";

import styles from "./Layout.module.scss";

const SIZES = ["large", "medium", "none", "small", "xlarge", "xsmall"];

/**
 * Layout is a wrapper component that provides spacing around its contents. Layout provides options
 * to set top, left, right, bottom margins, or for both left and right margins (sides).
 */

export const Layout = (props) => {
  const { bottom, center, children, className, left, right, sides, top } = props;

  return <div className={getClass()}>{children}</div>;

  function getClass() {
    return classNames(className, styles.layout, {
      [styles[`bottom${capitalizeFirstLetter(bottom)}`]]: bottom,
      [styles[`left${capitalizeFirstLetter(left)}`]]: left,
      [styles[`right${capitalizeFirstLetter(right)}`]]: right,
      [styles[`top${capitalizeFirstLetter(top)}`]]: top,
      [styles.center]: center,
      [styles.sides]: sides,
    });
  }

  function capitalizeFirstLetter(string) {
    return string?.charAt(0).toUpperCase() + string?.slice(1);
  }
};

Layout.propTypes = {
  /** Margin to be applied to element bottom. */
  bottom: PropTypes.oneOf(SIZES),

  /** Content will be centered if true. */
  center: PropTypes.bool,

  /** Content that will be rendered. */
  children: PropTypes.node.isRequired,

  /** Margin to be applied to element left. */
  left: PropTypes.oneOf(SIZES),

  /** Margin to be applied to element right. */
  right: PropTypes.oneOf(SIZES),

  /** Indicates if Layout should apply outer margins to both left and right sides. */
  sides: PropTypes.bool,

  /** Margin to be applied to element top. */
  top: PropTypes.oneOf(SIZES),
};
