// TODO import { ... } from '../styles/_sizes.scss'
export const sizes = {
  size11: "11px",
  size12: "12px",
  size13: "13px",
  size14: "14px",
  size15: "15px",
  size16: "16px",
  size17: "17px",
  size18: "18px",
  size20: "20px",
  size22: "22px",
  size24: "24px",
  size26: "26px",
  size27: "27px",
  size28: "28px",
  size30: "30px",
  size32: "32px",
  size36: "36px",
  size40: "40px",
  size42: "42px",
  size48: "48px",
  size52: "52px",
  size64: "64px",
  size80: "80px",
  size115: "115px",
};

// TODO import { ... } from '../styles/_fontSizes.scss'
export const fontSizes = {
  fontSize11: sizes.size11,
  fontSize12: sizes.size12,
  fontSize13: sizes.size13,
  fontSize14: sizes.size14,
  fontSize15: sizes.size15,
  fontSize16: sizes.size16,
  fontSize18: sizes.size18,
  fontSize20: sizes.size20,
  fontSize22: sizes.size22,
  fontSize24: sizes.size24,
  fontSize26: sizes.size26,
  fontSize28: sizes.size28,
  fontSize30: sizes.size30,
  fontSize32: sizes.size32,
  fontSize36: sizes.size36,
  fontSize40: sizes.size40,
  fontSize42: sizes.size42,
  fontSize48: sizes.size48,
  fontSize52: sizes.size52,
};
