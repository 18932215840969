import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { getBootstrapData } from "@swa-ui/bootstrap";
import { DateSelector, Dialog, DropDown, useDialog } from "@swa-ui/core";
import { Form, TextFormField } from "@swa-ui/form";

import { MktgMediatorContext } from "../MktgMediatorProvider";
import styles from "./PlacementsUpdater.module.scss";
import { timeOptions } from "./timeOptions";

/**
 * PlacementsUpdater submits custom form field data to the Content Service Toolbox network request.
 */

const DROPDOWN_ROWS = [0, 1, 2, 3, 4, 5, 6, 7];
const MAX_DROPDOWN_DISPLAY = 15;

export const PlacementsUpdater = (props) => {
  const { displayToolbox } = props;
  const { dialogRef, hideDialog, isDialogVisible, showDialog } = useDialog("center");
  const { pathname } = useLocation();
  const { startUpdateCycle } = useContext(MktgMediatorContext);
  const [dateStart, setDateStart] = useState(getTodaysDate());
  const [dropdownFields, setDropdownFields] = useState({});
  const [time, setTime] = useState("12:00 PM");
  const updateContentData = getBootstrapData("update-content-data");
  const updateContentDataWithSelect = ["Select"].concat(updateContentData);

  useEffect(() => {
    if (displayToolbox) {
      showDialog();
    }
  }, [displayToolbox]);

  return (
    <Form onSubmit={(formFields) => updatePlacements(formFields)}>
      <Dialog {...getDialogProps()}>
        {renderDropDownRows()}
        {renderDateAndTimeRows()}
      </Dialog>
    </Form>
  );

  function renderDropDownRows() {
    return DROPDOWN_ROWS.map((rowIndex) => (
      <div key={`row${rowIndex}`} className={styles.dropDownRow}>
        <DropDown {...getDropDownProps(rowIndex)} /> :
        <TextFormField {...getFormFieldProps(rowIndex)} />
      </div>
    ));
  }

  function renderDateAndTimeRows() {
    return (
      <div>
        Select date:
        <DateSelector {...getDateSelectorProps()} />
        Select time:
        <DropDown {...getSelectTimeProps()} />
      </div>
    );
  }

  function getDialogProps() {
    return {
      buttonGroupProps: {
        buttonList: [
          {
            label: "Submit data",
            props: {
              name: "submitData",
              styleType: "primary",
              type: "submit",
            },
          },
        ],
      },
      dialogRef,
      heading: "Content Service Toolbox",
      onClose: handleDialogClose,
      portal: false,
      revealed: isDialogVisible,
      showClose: true,
      width: "narrow",
    };
  }

  function getDropDownProps(dropdownIndex) {
    return {
      defaultValue: "Select",
      id: "mockId",
      list: updateContentDataWithSelect,
      maxItemsToDisplay: MAX_DROPDOWN_DISPLAY,
      name: "dropdown",
      onChange: (event) => handleDropdownChange(event.target.value, dropdownIndex),
    };
  }

  function getFormFieldProps(formFieldIndex) {
    return {
      name: `row${formFieldIndex}`,
      type: "text",
    };
  }

  function getDateSelectorProps() {
    return {
      closeContent: "done",
      dateStart,
      daysOfWeekInitials: "SMTWTFS",
      location: "above",
      numberCalendars: 1,
      onSelectStart: (date) => setDateStart(date),
      singleDate: true,
      title: "Select date",
    };
  }

  function getSelectTimeProps() {
    return {
      defaultValue: "12:00 PM",
      id: "mockId",
      list: timeOptions,
      maxItemsToDisplay: MAX_DROPDOWN_DISPLAY,
      name: "dropdown",
      onChange: (event) => setTime(event.target.value),
    };
  }

  function getTodaysDate() {
    const dateYearMonthDayArray = new Date().toISOString().split("T")[0].split("-");
    const month = dateYearMonthDayArray[1];
    const day = dateYearMonthDayArray[2];
    const year = dateYearMonthDayArray[0];

    return `${month}/${day}/${year}`;
  }

  function getFormFieldData(formFieldInputs) {
    const selectedDate = getSelectedDate();
    const formattedDate = new Date(selectedDate);
    const curDate = formattedDate.getTime();
    const dropdownAndFormFields = {};

    DROPDOWN_ROWS.forEach((rowIndex) => {
      const rowName = `row${rowIndex}`;
      const dropdownLabel = dropdownFields[rowName];
      const formFieldInput = formFieldInputs[rowName];

      if (dropdownLabel && dropdownLabel !== "Select" && formFieldInput) {
        dropdownAndFormFields[dropdownLabel] = formFieldInput;
      }
    });

    return {
      ...dropdownAndFormFields,
      curDate,
      selectedDate,
    };
  }

  function getSelectedDate() {
    const dateArray = dateStart.split("/");
    const dateYearMonthDay = `${dateArray[2]}-${dateArray[0]}-${dateArray[1]}`;
    const timeArray = time.split(" ");
    const hoursMinutesArray = timeArray[0].split(":");
    const minuteTime = hoursMinutesArray[1];
    let hourTime = hoursMinutesArray[0];
    const isTimePast1pm = timeArray[1] === "PM" && hourTime !== "12";

    if (isTimePast1pm) {
      hourTime = Number(hourTime) + 12;
    }

    const timeHourMinuteSecond = `${hourTime}:${minuteTime}:00`;

    return `${dateYearMonthDay} ${timeHourMinuteSecond}`;
  }

  function handleDialogClose() {
    hideDialog();
  }

  function handleDropdownChange(label, index) {
    setDropdownFields({
      ...dropdownFields,
      [`row${index}`]: label,
    });
  }

  function updatePlacements(formFieldInputs) {
    const formFieldData = getFormFieldData(formFieldInputs);

    hideDialog();
    startUpdateCycle(pathname, formFieldData);
  }
};

PlacementsUpdater.propTypes = {
  /**
   * State that indicates whether toolbox is currently displayed or not.
   */
  displayToolbox: PropTypes.bool,
};
