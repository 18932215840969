import PropTypes from "prop-types";
import React from "react";

export const PointsDial = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <polygon
        stroke="#FFFFFF"
        points="52.998,59.146 48.29,65.534 40.506,63.992 33.607,67.913
	27.123,63.339 19.226,64.115 15.163,57.299 7.632,54.796 6.693,46.917 0.832,41.567 3.178,33.987 0,26.716 5.227,20.745
	5.28,12.811 12.484,9.482 15.76,2.255 23.695,2.144 29.627,-3.126 36.921,0 44.484,-2.401 49.876,3.421 57.762,4.304 60.319,11.816
	67.164,15.83 66.445,23.733 71.066,30.184 67.195,37.111 68.792,44.883 62.438,49.637 60.735,57.387"
      />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      style,
      viewBox: "0 0 71.066 65.534",
    };
  }
};

PointsDial.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

PointsDial.defaultProps = { role: "img" };
