import * as yup from "yup";

import i18n from "@swa-ui/locale";

export const PHONE_NUMBER_SCHEMA_FIELD_NAMES = {
  PHONE_NUMBER: "phoneNumber",
};

const REGEX = {
  domestic: /^\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$/,
  international: /^[0-9]*$/,
};

export const getPhoneNumberFormFieldSchema = ({ domestic = false } = {}) =>
  (domestic
    ? yup.string().trim().required().matches(REGEX.domestic)
    : yup.string().trim().required().min(4).max(12).matches(REGEX.international)
  ).label(i18n("PhoneNumberFormField__LABEL").toLowerCase());
