"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useDestinationDetailPageAnalytics = void 0;
var _react = require("react");
var _reactRouterDom = require("react-router-dom");
var _application = require("@swa-ui/application");
var _routes = require("./defines/routes");
var useDestinationDetailPageAnalytics = exports.useDestinationDetailPageAnalytics = function useDestinationDetailPageAnalytics() {
  var _useLocation = (0, _reactRouterDom.useLocation)(),
    pathname = _useLocation.pathname;
  var _useContext = (0, _react.useContext)(_application.MktgDataContext),
    setAdobePageData = _useContext.setAdobePageData;
  (0, _react.useEffect)(function () {
    if (pathname === _routes.ROUTES.destinationDetail) {
      var adobeAnalyticsObj = {
        eventinfo: {
          eventtype: "other"
        }
      };
      setAdobePageData(adobeAnalyticsObj, "destinationDetailPage");
    }
  }, [pathname]);
};