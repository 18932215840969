import PropTypes from "prop-types";
import React from "react";

export const Notebook = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <g>
        <path d="M0,37.3h30.8V2.4c0-1.4-0.9-2.4-2.5-2.4H2.6C0.9,0,0.1,1,0.1,2.4 M22.1,3.7h3.7c0.5,0,0.9,0.4,0.9,0.9 c0,0.5-0.4,0.9-0.9,0.9h-3.7c-0.5,0-0.9-0.4-0.9-0.9C21.2,4.1,21.6,3.7,22.1,3.7z M4.4,8.8h21.8c0.5,0,0.9,0.4,0.9,0.9 c0,0.5-0.4,0.9-0.9,0.9H4.4c-0.5,0-0.9-0.4-0.9-0.9C3.5,9.2,3.9,8.8,4.4,8.8z" />
        <path
          fill="#FFFFFF"
          d="M4.5,15.4h21.8c0.5,0,0.9,0.4,0.9,0.9c0,0.5-0.4,0.9-0.9,0.9H4.5c-0.5,0-0.9-0.4-0.9-0.9 C3.6,15.8,4,15.4,4.5,15.4z"
        />
        <path
          fill="#FFFFFF"
          d="M4.5,21.9h21.8c0.5,0,0.9,0.4,0.9,0.9c0,0.5-0.4,0.9-0.9,0.9H4.5c-0.5,0-0.9-0.4-0.9-0.9 C3.6,22.4,4,21.9,4.5,21.9z"
        />
        <path
          fill="#FFFFFF"
          d="M4.5,28.5h21.8c0.5,0,0.9,0.4,0.9,0.9c0,0.5-0.4,0.9-0.9,0.9H4.5c-0.5,0-0.9-0.4-0.9-0.9 C3.6,28.9,4,28.5,4.5,28.5z"
        />
        <path d="M0.1,37.2c0,1.4,0.9,2.4,2.5,2.4h25.7c1.6,0,2.5-1,2.5-2.4" />
        <path d="M0.1,37.2c0,1.4,0.9,2.4,2.5,2.4h25.7c1.6,0,2.5-1,2.5-2.4" />
      </g>
      <g>
        <path d="M53.3,8.4c0.2,0,1.2,0,1.4-0.4V6.7c-0.3-0.4-1.2-0.4-1.4-0.4V8.4z" />
        <path d="M39.3,8V6.7c0-0.2,0.7-0.4,0.7-0.4h12.2v2.2H40.1C40.1,8.4,39.3,8.2,39.3,8z" />
        <path d="M38.3,6.6C38,6.4,36.2,6.9,36,7.3l0,0c0.3,0.4,2.3,1,2.3,0.9V6.6z" />
      </g>
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "0 0 54.7 39.6",
    };
  }
};

Notebook.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Notebook.defaultProps = {
  role: "img",
};
