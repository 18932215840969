import React, { useContext, useEffect, useMemo } from "react";

import { fireTrack } from "@swa-ui/analytics";
import { AnalyticsDataUpdater } from "@swa-ui/application";
import { usePrevious } from "@swa-ui/application";
import { AuthContext, useUserInfo } from "@swa-ui/authentication";
import { getBootstrapData } from "@swa-ui/bootstrap";
import { useDeviceInfo } from "@swa-ui/browser";
import { useGeolocation } from "@swa-ui/geolocation";
import { usePersonas } from "@swa-ui/personas";

import { ChaseContext } from "../ChaseProvider";
import { LocaleContext } from "../LocaleProvider";

export const DotcomAnalyticsDataUpdater = () => {
  const { chaseDetails } = useContext(ChaseContext);
  const { isLoggedIn } = useContext(AuthContext);
  const { locale } = useContext(LocaleContext);
  const { acquisitionSourceCodes, highValueIndicator, offerIdentifier } = chaseDetails ?? {};
  const { getCorporateInfo, getCustomerInfo } = useUserInfo();
  const { isSecureWebView } = useDeviceInfo();
  const { accountNumber, redeemablePoints, tier } = getCustomerInfo() ?? {};
  const { companyId, role } = getCorporateInfo() ?? {};
  const { isCorporatePersona } = usePersonas();
  const geolocation = getBootstrapData("air/stations-data") && useGeolocation();
  const nearestStation = geolocation?.nearestStation;
  const additionalAnalyticsData = useMemo(
    () => getAnalyticsDataUpdaterProps(),
    [accountNumber, nearestStation]
  );
  const loggedIn = isLoggedIn();
  const lastLoggedIn = usePrevious(loggedIn);
  const chaseAnalyticsData = useMemo(() => getChaseAnalyticsDataProps(), [chaseDetails]);
  const eventSpecificAnalyticsData = useMemo(() => getAnalyticsDataEventProps(), [loggedIn]);

  useEffect(() => {
    if (lastLoggedIn !== undefined && loggedIn) {
      fireTrack("globalLogIn");
    } else if (lastLoggedIn && !loggedIn) {
      fireTrack("globalLogOut");
    }
  }, [loggedIn]);

  return (
    <AnalyticsDataUpdater
      additionalAnalyticsData={additionalAnalyticsData}
      eventSpecificAnalyticsData={eventSpecificAnalyticsData}
      chaseAnalyticsData={chaseAnalyticsData}
    />
  );

  function getAnalyticsDataUpdaterProps() {
    return {
      iswebview: isSecureWebView ? "1" : "0",
      member_number: accountNumber ? accountNumber : "",
      member_pointsbalance: accountNumber ? redeemablePoints : "",
      member_status: accountNumber ? tier?.toLowerCase() : "",
      page_language: locale,
      user_loginstate: accountNumber ? "hot" : "cold",
      ...(isCorporatePersona && {
        swabiz_companyid: companyId ? `${companyId}` : "",
        swabiz_userrole: role ? role : "",
      }),
      ...(nearestStation && { nearestStation }),
    };
  }

  function getAnalyticsDataEventProps() {
    const analyticsEventSpecificObjectKey = loggedIn ? "member_login" : "member_logout";

    return lastLoggedIn !== undefined
      ? {
          [analyticsEventSpecificObjectKey]: "1",
        }
      : {};
  }

  function getChaseAnalyticsDataProps() {
    return {
      chase_acqsrccode: acquisitionSourceCodes ? acquisitionSourceCodes : "",
      chase_highvalueindicator: highValueIndicator ? "1" : "0",
      chase_offeridentifier: offerIdentifier ? offerIdentifier : "",
    };
  }
};
