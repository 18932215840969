import React from "react";

import { FormField } from "./FormField";

/**
 * withFormField is an HOC use to provide everything required to use any type of input component in a Form.
 *
 * @param {Function} InputComponent - Component to be wrapped with a form field
 * @param {Object} options - options used to configure the form field
 * @param {boolean} options.fieldFocusFromLabel - should a label be rendered
 * @param {boolean} options.shouldRegister - should the field be registered with use hook form
 * @param {string} options.type - the type that should be passed to the InputComponent (text, hidden, number, etc)
 * @param {boolean} options.useController - should a use hook form controller be used
 */

export const withFormField = (InputComponent, options = {}) => {
  const {
    fieldFocusFromLabel = true,
    shouldRegister = true,
    type,
    useController = false,
    renderCaption,
  } = options;

  return (props) => <FormField {...getFormFieldProps(props)} />;

  function getFormFieldProps(props) {
    return {
      ...props,
      fieldFocusFromLabel,
      InputComponent,
      renderCaption,
      shouldRegister,
      type,
      useController,
    };
  }
};
