import React, { useContext } from "react";

import { MaintenanceContext } from "../MaintenanceProvider";
import { MaintenanceMessage } from "./MaintenanceMessage";

export const Maintenance = (props) => {
  const { children } = props;
  const { isInMaintenance } = useContext(MaintenanceContext);

  return <>{isInMaintenance ? <MaintenanceMessage /> : children}</>;
};
