import { NumberSelector } from "@swa-ui/core";

import { withFormField } from "../withFormField";

/**
 * NumberSelectorFormField provides support for a standard NumberSelector component to be used in a Form.
 */

export const NumberSelectorFormField = withFormField(NumberSelector, {
  useController: true,
});
