import PropTypes from "prop-types";
import React from "react";

import { classNames } from "@swa-ui/string";

import globalRules from "../assets/styles/globalRules.module.scss";
import { fontSizes } from "../defines/sizes";
import { Icon, iconPropTypes } from "../Icon";
import styles from "./Link.module.scss";

/**
 * The Link component provides a clickable area on a page to allow the traveler to perform actions while remaining on
 * the page, or to navigate to another page. There are currently two main styles: "typical" button and no style. If an
 * href is passed, then an anchor tag will be instantiated, otherwise a button element will be used.
 * Anytime a link or a button that looks like a link is needed, then this Link component should be used. If a link or
 * button is needed and looks like a button, then the Button component should be used.
 */

const LINK_FG_DESIGN_TOKEN = "cmp-core-color-link-fg";
const LINK_LIGHT_DESIGN_TOKEN = "cmp-core-color-link-light";

export const Link = React.forwardRef((props, ref) => {
  const {
    children,
    className,
    dark,
    disabled,
    emphasis,
    external,
    href,
    id,
    light,
    newWindow,
    newWindowDescription,
    onClick,
    onFocus,
    overrideComponent,
    overrideComponentProps,
    paddingForFocus,
    prefixIcon,
    showUnderline,
    size,
    style,
    styleType,
    suffixIcon,
    tabIndex,
  } = props;
  const Component = overrideComponent ?? (href && !disabled ? "a" : "button");

  return (
    <Component {...getProps()}>
      {renderPrefixIcon()}
      {children}
      {renderSuffixIcon()}
    </Component>
  );

  function renderPrefixIcon() {
    return prefixIcon ? <Icon className={styles.prefixIcon} {...getIconProps(prefixIcon)} /> : null;
  }

  function renderSuffixIcon() {
    return suffixIcon || external ? (
      <Icon className={styles.suffixIcon} {...getIconProps(suffixIcon)} />
    ) : null;
  }

  function getProps() {
    return {
      "aria-controls": props["aria-controls"],
      "aria-disabled": disabled,
      "aria-expanded": props["aria-expanded"],
      "aria-hidden": props["aria-hidden"],
      "aria-label": getAriaLabel(),
      className: getClass(),
      "data-test": props["data-test"],
      disabled,
      href,
      id,
      onClick: !disabled ? onClick : undefined,
      onFocus,
      ref,
      style,
      tabIndex,
      target: newWindow && href ? "_blank" : null,
      type: Component === "button" ? "button" : undefined,
      ...overrideComponentProps,
    };
  }

  function getIconProps(iconProps) {
    const computedSize = iconProps?.size || size || "fontSize16";
    let computedColor = light ? LINK_LIGHT_DESIGN_TOKEN : LINK_FG_DESIGN_TOKEN;

    if (iconProps && iconProps.color) {
      computedColor = iconProps.color;
    }

    return {
      actions: iconProps?.actions,
      "aria-hidden": iconProps?.["aria-hidden"],
      background: iconProps?.background,
      border: iconProps?.border,
      color: computedColor,
      name: iconProps ? iconProps.name : "External",
      role: iconProps?.role,
      shrink: iconProps?.shrink,
      size: mapFromFontSizeToGlobalSize(computedSize),
      transparentBorder: iconProps?.transparentBorder,
    };
  }

  function getClass() {
    return classNames({
      [className]: className,
      [globalRules[size]]: true,
      [styles.emphasis]: emphasis,
      [styles.disabled]: disabled,
      [styles.dark]: dark,
      [styles.light]: light,
      [styles.link]: true,
      [styles.linkInline]: styleType === "link-inline",
      [styles.outlineForFocus]: !paddingForFocus,
      [styles.showUnderline]: !disabled && showUnderline,
    });
  }

  function getAriaLabel() {
    let ariaLabel = props["aria-label"] || "";

    if (newWindowDescription) {
      ariaLabel = `${ariaLabel} ${newWindowDescription}`;
    }

    return ariaLabel.trim();
  }
});

function mapFromFontSizeToGlobalSize(size) {
  let computedSize = size;

  if (size.indexOf("fontSize") !== -1) {
    // translate from fontSizeXX to sizeXX;
    computedSize = `s${size.slice(5)}`;
  }

  return computedSize;
}

Link.displayName = "Link";

Link.propTypes = {
  /**
   * Indicates the id of another area on a page when this button controls whether that area is concealed or revealed.
   * If Button doesn't control another area, then aria-controls should be undefined.
   */
  "aria-controls": PropTypes.string,

  /**
   * Indicates when this button controls another area on the page whether than area is revealed. If Button doesn't
   * control another area, then aria-expanded should be undefined.
   */
  "aria-expanded": PropTypes.bool,

  /** aria-hidden text to hide non-interactive content from the accessibility API icon. */
  "aria-hidden": PropTypes.bool,

  /** aria-label text to provide additional accessibility description of button or link. */
  "aria-label": PropTypes.string,

  /** Anchor child elements. Recommended to be plain text. */
  children: PropTypes.node.isRequired,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Dark prop indicates if the link will be rendered with a darker than typical color. */
  dark: PropTypes.bool,

  /** Name to use for the data-test attribute used for automated testing. */
  "data-test": PropTypes.string,

  /**
   * Indicates Button should apply disabled styling, ignore click events and provide aria-disabled attribute. disabled
   * should only be given when href is undefined, because anchor tags should never be disabled.
   */
  disabled: PropTypes.bool,

  /** Defines if the button text will be bold or normal weight. */
  emphasis: PropTypes.bool,

  /** Set to `true` if the link is pointing to an external URL outside of the intended SPA domain. */
  external: PropTypes.bool,

  /** Anchor tag hypertext reference (e.g., the link path or URL). */
  href: PropTypes.string,

  /** ID to be added to the element. */
  id: PropTypes.string,

  /**
   * Light prop indicates if the link will be rendered on a dark background, and is not affected by the theme -- that
   * is, the background will always be dark regardless of the theme background.
   */
  light: PropTypes.bool,

  /** Set to `true` if the link is should open a new tab/window when clicked. */
  newWindow: PropTypes.bool,

  /**
   * When <code>newWindow</code> or <code>external</code> are `true`, <code>newWindowDescription</code> must be
   * specified to provide the traveler with addition ARIA information. This prop is typically set to "Opens new
   * window."
   */
  newWindowDescription: PropTypes.string,

  /** onClick event handler will receive the event object as its only argument. */
  onClick: PropTypes.func,

  /** Callback to receive notification when link gains focus. */
  onFocus: PropTypes.func,

  /** Component that when passed, will override <a> or <Button> render. */
  overrideComponent: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.shape({ current: PropTypes.elementType }),
  ]),

  /** Object that includes props to be passed to an override component, such as NavLink. */
  overrideComponentProps: PropTypes.object,

  /**
   * By default, link will not have padding around it. But, at times, in order to show the focus outline, this
   * padding is required.
   */
  paddingForFocus: PropTypes.bool,

  /** Icon that can be placed before link text. See Icon for more details. */
  prefixIcon: PropTypes.shape({
    actions: iconPropTypes.actions,
    background: iconPropTypes.background,
    border: iconPropTypes.border,
    color: iconPropTypes.color,
    name: iconPropTypes.name,
    role: iconPropTypes.role,
    shrink: iconPropTypes.shrink,
    size: iconPropTypes.size,
    transparentBorder: iconPropTypes.transparentBorder,
  }),

  /** Allow the Link to have or not have an underline on focus/hover. */
  showUnderline: PropTypes.bool,

  /** Any valid font size can be applied for links. */
  size: PropTypes.oneOf(Object.keys(fontSizes)),

  /** Object to style the HTML anchor element. */
  style: PropTypes.object,

  /** There are only two types of link-looking buttons. */
  styleType: PropTypes.oneOf(["link", "link-inline"]),

  /**
   * Icon that can be placed after link text. See Icon for more details. Note that if external prop is set, then
   * suffixIcon cannot also be given.
   */
  suffixIcon: PropTypes.shape({
    actions: iconPropTypes.actions,
    background: iconPropTypes.background,
    border: iconPropTypes.border,
    color: iconPropTypes.color,
    name: iconPropTypes.name,
    shrink: iconPropTypes.shrink,
    size: iconPropTypes.size,
    transparentBorder: iconPropTypes.transparentBorder,
  }),

  /** Optional tabIndex attribute to be applied to the <a> or <button> element. */
  tabIndex: PropTypes.number,
};

Link.defaultProps = {
  emphasis: false,
  light: false,
  paddingForFocus: true,
  showUnderline: true,
  size: "fontSize16",
  styleType: "link",
};
