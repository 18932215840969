import PropTypes from "prop-types";
import React from "react";

export const Cruise = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path
        d="M31.2,11.2v3.5L28.8,14l-7.7-2.1c-0.7-0.2-1.5-0.2-2.2,0l-7.7,2.2l-2.4,0.7v-3.5c0-0.3,0.1-0.6,0.4-0.8
	c0.2-0.2,0.5-0.3,0.9-0.3h8.8h2.4h8.8C30.7,10.1,31.2,10.6,31.2,11.2z"
      />
      <path
        d="M39.3,36c-0.7-0.6-1.8-0.6-2.5,0c-0.7,0.7-2,0.7-2.7,0c-1-1-2.4-1.5-3.8-1.5S27.5,35,26.4,36
	c-0.7,0.7-2,0.7-2.7,0c-1-1-2.4-1.5-3.8-1.5c-1.5,0-2.8,0.5-3.8,1.5c-0.7,0.7-2,0.7-2.7,0c-1-1-2.4-1.5-3.8-1.5
	c-1.5,0-2.8,0.5-3.8,1.5c-0.7,0.7-2,0.7-2.7,0c-0.7-0.6-1.8-0.7-2.5,0C0.2,36.3,0,36.7,0,37.2s0.2,0.9,0.5,1.2c1,1,2.4,1.5,3.8,1.5
	c1.5,0,2.8-0.5,3.8-1.5c0.7-0.7,2-0.7,2.7,0c1,1,2.4,1.5,3.8,1.5s2.8-0.5,3.8-1.5c0.7-0.7,2-0.7,2.7,0c1,1,2.4,1.5,3.8,1.5
	s2.8-0.5,3.8-1.5c0.7-0.7,2-0.7,2.7,0c1,1,2.4,1.5,3.8,1.5c1.5,0,2.8-0.5,3.8-1.5c0.3-0.3,0.5-0.8,0.5-1.2
	C39.8,36.7,39.6,36.3,39.3,36z"
      />
      <g>
        <path
          d="M12.2,8c-0.4,0-0.7-0.3-0.7-0.7V6.8c0-0.4,0.3-0.7,0.7-0.7h15.6c0.4,0,0.7,0.3,0.7,0.7v0.5
		c0,0.4-0.3,0.7-0.7,0.7H12.2z"
        />
      </g>
      <g>
        <path
          d="M22,3.6c0,0.4-0.3,0.8-0.8,0.8h-2.5c-0.4,0-0.8-0.3-0.8-0.8V1c0-0.4,0.3-0.8,0.8-0.8h2.5C21.7,0.2,22,0.6,22,1
		V3.6z"
        />
      </g>
      <g>
        <path
          d="M18.8,14.2L1.1,18.9c-0.3,0.1-0.6,0.3-0.7,0.6c-0.1,0.3-0.2,0.7,0.1,1l0.7,0.7c6.6,6,7.2,10.6,8.1,10.6
		c0.1,0,0.2,0,0.4,0c2,0,3.8,0.7,5.2,2c1.1-1.1,2.5-1.7,4-1.9V14.2H18.8z"
        />
        <path
          d="M39.6,19.4c-0.1-0.3-0.4-0.5-0.8-0.6l-17.6-4.6v17.7c1.5,0.2,2.9,0.9,4,1.9c1.3-1.3,3.2-2,5.2-2
		c0.1,0,0.2,0,0.4,0c0.8,0,1.5-5.8,7.8-10.6l0.8-0.7C39.9,20,39.7,19.7,39.6,19.4z"
        />
      </g>
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "0 0 40 40",
    };
  }
};

Cruise.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized according to the SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Cruise.defaultProps = {
  role: "img",
};
