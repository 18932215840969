import PropTypes from "prop-types";
import React from "react";

import { Icon } from "@swa-ui/core";
import i18n from "@swa-ui/locale";
import { classNames } from "@swa-ui/string";

import styles from "./PlaneType.module.scss";

/**
 * PlaneType displays the airplane icon alongside a string provided from the planeType prop.
 */

export const PlaneType = (props) => {
  const { className, planeColor, planeType, showIcon } = props;

  return (
    <div className={classNames(className, styles.planeType)} aria-label={getPlaneTypeAriaLabel()}>
      {showIcon && <Icon {...getIconProps()} />}
      <span>{planeType}</span>
    </div>
  );

  function getIconProps() {
    return {
      "aria-hidden": props["aria-hidden"],
      className: styles.icon,
      color: planeColor,
      name: "Airplane",
      size: "size16",
    };
  }

  function getPlaneTypeAriaLabel() {
    return i18n("PlaneType__INFORMATION_ARIA", {
      PLANE_TYPE: planeType,
    });
  }
};

PlaneType.propTypes = {
  /** aria-hidden text to hide non-interactive content from the accessibility API icon. */
  "aria-hidden": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Value to be used to render the airplane icons color. */
  planeColor: PropTypes.string,

  /** Content that will be rendered inside the component. */
  planeType: PropTypes.string.isRequired,

  /** Boolean value to show or hide the airplane icon. */
  showIcon: PropTypes.bool,
};

PlaneType.defaultProps = {
  showIcon: true,
};
