/* eslint-disable sort-keys */
export default {
  light: {
    // accordion
    "accordion-label-focus": "primary-500",
    "primary-accordion-label": "primary-500",
    "primary-accordion-label-background": "neutral-900",
    "primary-accordion-label-border": "neutral-800",
    "primary-accordion-label-focus": "neutral-700",
    "primary-accordion-label-border-focus": "primary-500",

    "secondary-accordion-label": "primary-200",
    "secondary-accordion-label-background": "neutral-900",
    "secondary-accordion-label-border": "neutral-900",
    "secondary-accordion-label-border2": "neutral-500",
    "secondary-accordion-label-focus": "neutral-700",
    "secondary-accordion-label-border-focus": "primary-300",

    "tertiary-accordion-label": "primary-500",
    "tertiary-accordion-label-background": "neutral-900",
    "tertiary-accordion-label-background-focus": "neutral-900",
    "tertiary-accordion-label-border": "neutral-200",
    "tertiary-accordion-label-focus": "primary-300",
    "tertiary-accordion-label-border-focus": "primary-300",

    // theme / semantic
    "theme-black": "neutral-100",
    "theme-caution": "secondary-500",
    "theme-danger": "tertiary-500",
    "theme-disabled": "neutral-800",
    "theme-error": "tertiary-500",
    "theme-information": "primary-600",
    "theme-information-foreground": "primary-200",
    "theme-neutral": "neutral-200",
    "theme-primary": "primary-500",
    "theme-secondary": "neutral-500",
    "theme-success": "success-500",
    "theme-tertiary": "neutral-700",
    "theme-transparent": "transparent",
    "theme-warning": "secondary-500",
    "theme-white": "neutral-900",

    // theme / illustration
    "theme-height-large": "4rem",
    "theme-height-medium": "3rem",
    "theme-height-small": "2rem",
    "theme-height-xlarge": "6rem",
    "theme-height-xsmall": "1.5rem",
    "theme-border-large": "10px",
    "theme-border-medium": "4px",
    "theme-border-small": "2px",
    "theme-border-xlarge": "6px",
    "theme-border-xsmall": "1px",
    "theme-radius-large": "2rem",
    "theme-radius-medium": "0.5rem",
    "theme-radius-small": "2px",
    "theme-radius-xlarge": "4rem",
    "theme-radius-xsmall": "1px",

    // theme / font
    "theme-font-large": 42,
    "theme-font-medium": 30,
    "theme-font-small": 16,
    "theme-font-xlarge": 115,
    "theme-font-xsmall": 12,

    // body
    "body-content": "primary-100",
    "body-disabled": "neutral-500",
    "body-disabled-background": "neutral-800",
    "body-error": "tertiary-500",
    "body-hover": "neutral-800",
    "body-medium": "neutral-400",
    "body-primary": "primary-500",
    "body-secondary": "primary-300",
    "body-separator": "neutral-500",
    "body-tertiary": "neutral-200",

    // button
    "primary-button-background": "secondary-500",
    "primary-button-background-focus": "primary-500",
    "primary-button-border": "secondary-500",
    "primary-button-border-focus": "primary-200",
    "primary-button-foreground": "primary-200",
    "primary-button-loading": "primary-200",
    "primary-button-feedback": "primary-500",

    "secondary-button-background-focus": "rgba(17,27,64,0.2)",
    "secondary-button-loading": "primary-200",
    "secondary-button-feedback": "primary-200",

    "tertiary-button-loading": "neutral-900",

    "light-button-foreground": "neutral-900",
    "light-button-loading": "primary-200",
    "light-button-feedback": "neutral-900",

    "danger-button-loading": "neutral-900",
    "danger-button-feedback": "neutral-900",

    // checkbox
    "checkbox-background": "neutral-900",
    "checkbox-border": "primary-500",
    "checkbox-border-focus": "primary-500",
    "checkbox-content": "primary-200",
    "checkbox-disabled": "neutral-700",
    "checkbox-focus": "primary-200",
    "checkbox-foreground": "neutral-900",
    "checkbox-selected": "primary-500",
    "checkbox-underline-focus": "primary-500",
    "checkbox-underline-hover": "primary-300",

    // check button
    "check-button-background": "neutral-900",
    "check-button-background-focus": "primary-900",
    "check-button-background-selected-focus": "primary-500",
    "check-button-border": "primary-200",
    "check-button-foreground": "primary-200",
    "check-button-background-icon": "neutral-900",
    "check-button-foreground-icon": "primary-300",
    "check-button-background-selected": "primary-300",
    "check-button-foreground-selected": "neutral-800",

    // dialog
    "dialog-background": "neutral-900",
    "dialog-border": "primary-300",

    // footer
    "footer-content": "primary-500",

    // flight status
    "flight-status-canceled": "secondary-500",
    "flight-status-delayed": "neutral-900",
    "flight-status-on-time": "success-500",

    // form control
    "form-control-caption": "neutral-200",
    "form-control-error": "tertiary-500",
    "form-control-label": "primary-200",

    // global header
    "primary-header-background": "neutral-900",
    "primary-header-heading-icon": "neutral-200",
    "primary-header-logo": "primary-500",
    "primary-header-logo-light": "neutral-900",
    "primary-header-search": "primary-500",
    "primary-header-search-open": "neutral-900",

    "secondary-header-border": "primary-500",
    "secondary-header-logo": "neutral-900",
    "secondary-header-logo-section-background": "primary-500",
    "secondary-header-title-section-background": "primary-300",
    "secondary-header-options-background": "neutral-900",

    // heading
    heading: "primary-200",
    "heading-light": "neutral-900",

    // icon
    "icon-border-transparent": "transparent",

    // link
    link: "primary-500",
    "link-light-focus": "neutral-900",

    // list scroller
    "list-scroller-border": "primary-500",
    "list-scroller-border-focus": "primary-200",
    "list-scroller-button-background": "neutral-900",
    "list-scroller-button-background-focus": "neutral-700",
    "list-scroller-button-content": "primary-300",
    "list-scroller-feedback": "neutral-200",

    // message
    "message-background": "neutral-900",
    "message-caution": "secondary-500",
    "message-error": "tertiary-500",
    "message-information": "primary-500",
    "message-success": "success-500",
    "message-warning": "primary-500",

    // number selector
    "number-selector-border": "neutral-900",
    "number-selector-border-disabled": "neutral-500",
    "number-selector-border-focus": "primary-200",
    "number-selector-button-background": "primary-500",
    "number-selector-button-background-focus": "primary-700",
    "number-selector-button-content": "neutral-900",
    "number-selector-disabled": "primary-700",
    "number-selector-feedback": "neutral-500",
    "number-selector-font-size": 32,
    "number-selector-font-size-small": 24,
    "number-selector-icon-border": "primary-500",

    // password
    "password-validation-message-error": "tertiary-500",

    // radio button
    "radio-button-background-focus-secondary": "primary-950",
    "radio-button-background-secondary": "neutral-900",
    "radio-button-border": "primary-500",
    "radio-button-border-focus-secondary": "primary-200",
    "radio-button-border-secondary": "neutral-500",
    "radio-button-content": "primary-200",
    "radio-button-focus": "primary-200",
    "radio-button-selected": "primary-500",
    "radio-button-underline-focus": "primary-500",
    "radio-button-underline-hover": "primary-300",

    // select list
    "select-list-border-focus": "primary-200",
    "select-list-disabled": "neutral-300",
    "select-list-icon": "primary-500",

    // shimmer
    "shimmer-background": "neutral-700",
    "shimmer-streak": "neutral-900",

    // stops
    "stops-nonstop-background": "secondary-500",
    "stops-nonstop-foreground": "primary-200",
    "stops-stops-background": "neutral-700",
    "stops-stops-foreground": "primary-200",

    // tabbed area
    "tabbed-area-background": "neutral-900",
    "tabbed-area-border": "neutral-500",

    // tabs
    "tabs-background": "neutral-900",
    "tabs-content-border": "primary-500",
    "tabs-focus-border": "inset 0 0 0 1px var(--primary-500)",
    "tabs-focus": "primary-500",
    "tabs-hover": "primary-500",

    // toggle button
    "toggle-button-background": "neutral-800",
    "toggle-button-background-selected": "neutral-500",
    "toggle-button-border": "neutral-700",
    "toggle-button-border-selected": "primary-500",
    "toggle-button-focus": "primary-500",
    "toggle-button-foreground": "primary-200",
    "toggle-button-foreground-selected": "primary-500",

    // vertical list
    "vertical-list-focus-background": "primary-800",
    "vertical-list-hover-background": "rgba(221, 231, 255, 0.75)",

    // SPA
    "spa-primary-background": "neutral-900",
    "spa-primary-border": "primary-500",
    "spa-secondary-background": "neutral-800",
    "spa-secondary-border": "neutral-700",
    "spa-tertiary-background": "neutral-700",
    "spa-tertiary-border": "neutral-500",

    // Disabled color definitions
    "standard-disabled-background": "neutral-500",
    "standard-disabled-foreground": "neutral-800",

    // SPA - flight status
    "flight-status-details-equipment-background": "neutral-700",
    "flight-status-find-reverse-button": "primary-500",
    "flight-status-find-reverse-button-background": "neutral-700",
    "flight-status-summary-background": "neutral-900",
    "flight-status-summary-details-option": "primary-500",

    // badge theming
    "primary-badge-active-text": "primary-100",
    "primary-badge-background": "secondary-500",
    "primary-badge-disabled": "neutral-200",
    "primary-badge-disabled-text": "neutral-900",

    // confirmation number
    "confirmation-number-text": "success-500",

    // flight duration
    "itinerary-flight-duration-primary": "neutral-100",
    "itinerary-flight-duration-secondary": "primary-500",

    // flight invalid
    "itinerary-flight-invalid": "neutral-200",

    // stop duration
    "stop-duration-line": "neutral-500",
    "stop-duration-primary": "neutral-200",
  },
  dark: {
    // accordion
    "primary-accordion-label": "neutral-800",
    "primary-accordion-label-background": "primary-300",
    "primary-accordion-label-border": "primary-500",
    "primary-accordion-label-background-focus": "primary-500",
    "primary-accordion-label-border-focus": "neutral-800",

    "secondary-accordion-label": "neutral-800",
    "secondary-accordion-label-background": "primary-300",
    "secondary-accordion-label-border": "primary-500",
    "secondary-accordion-label-border2": "primary-300",
    "secondary-accordion-label-focus": "primary-500",
    "secondary-accordion-label-border-focus": "primary-300",

    // autocomplete
    "autocomplete-border": "neutral-500",

    // body
    "body-content": "neutral-800",
    "body-disabled": "neutral-500",
    "body-disabled-background": "neutral-800",
    "body-error": "danger-500",
    "body-hover": "neutral-200",
    "body-medium": "neutral-700",
    "body-primary": "neutral-900",
    "body-secondary": "neutral-700",
    "body-separator": "neutral-800",
    "body-tertiary": "neutral-500",

    // button
    "primary-button-background": "secondary-500",
    "primary-button-background-focus": "secondary-500",
    "primary-button-border": "secondary-500",
    "primary-button-border-focus": "neutral-800",
    "primary-button-foreground": "primary-200",
    "primary-button-foreground-focus": "primary-200",
    "primary-button-loading": "primary-200",
    "primary-button-ripple": "primary-200",

    "secondary-button-background-focus": "rgba(17,27,64,0.2)",
    "secondary-button-loading": "primary-200",
    "secondary-button-ripple": "primary-300",

    "light-button-foreground": "neutral-900",
    "light-button-loading": "primary-200",
    "light-button-ripple": "primary-300",

    "danger-button-loading": "primary-200",
    "danger-button-ripple": "neutral-900",

    // checkbox
    "checkbox-background": "primary-300",
    "checkbox-border": "neutral-900",
    "checkbox-content": "neutral-900",
    "checkbox-focus": "neutral-900",
    "checkbox-foreground": "neutral-900",
    "checkbox-selected": "primary-500",
    "checkbox-underline-focus": "primary-600",
    "checkbox-underline-hover": "primary-300",

    // check button
    "check-button-background": "primary-200",
    "check-button-background-focus": "primary-900",
    "check-button-background-selected-focus": "primary-500",
    "check-button-border": "neutral-900",
    "check-button-foreground": "neutral-900",
    "check-button-background-icon": "neutral-800",
    "check-button-foreground-icon": "primary-300",
    "check-button-background-selected": "primary-500",
    "check-button-foreground-selected": "neutral-800",

    // dialog
    "dialog-background": "rgba(17,27,64,0.2)",
    "dialog-border": "primary-300",

    // flight status
    "flight-status-canceled": "secondary-500",
    "flight-status-delayed": "neutral-900",
    "flight-status-on-time": "success-500",

    // footer
    "footer-content": "neutral-900",

    // form control
    "form-control-caption": "neutral-900",
    "form-control-error": "danger-500",
    "form-control-label": "neutral-800",

    // global header
    "primary-header-background": "primary-300",
    "primary-header-heading-icon": "primary-600",
    "primary-header-logo": "neutral-900",
    "primary-header-logo-light": "neutral-900",
    "primary-header-search": "neutral-900",
    "primary-header-search-open": "neutral-900",

    "secondary-header-border": "neutral-700",
    "secondary-header-logo": "neutral-900",
    "secondary-header-logo-section-background": "primary-500",
    "secondary-header-title-section-background": "primary-300",
    "secondary-header-options-background": "primary-200",

    // heading
    heading: "neutral-900",
    "heading-light": "neutral-900",

    // link
    link: "primary-900",
    "link-light-focus": "primary-900",

    // message
    "message-background": "neutral-100",
    "message-caution": "secondary-500",
    "message-error": "tertiary-500",
    "message-information": "primary-500",
    "message-success": "success-500",
    "message-warning": "primary-500",

    // password
    "password-validation-message-error": "tertiary-500",

    // radio button
    "radio-button-border": "neutral-900",
    "radio-button-content": "neutral-900",
    "radio-button-focus": "neutral-900",
    "radio-button-selected": "primary-600",
    "radio-button-underline-focus": "primary-600",
    "radio-button-underline-hover": "primary-600",

    // shimmer
    "shimmer-background": "primary-300",
    "shimmer-streak": "neutral-900",

    // stop
    "stops-nonstop-background": "secondary-500",
    "stops-nonstop-foreground": "primary-200",
    "stops-stops-background": "primary-300",
    "stops-stops-foreground": "neutral-900",

    // tabbed area
    "tabbed-area-background": "primary-100",
    "tabbed-area-border": "primary-300",

    // toggle button
    "toggle-button-background": "primary-100",
    "toggle-button-background-selected": "primary-300",
    "toggle-button-border": "primary-100",
    "toggle-button-border-selected": "neutral-800",
    "toggle-button-focus": "neutral-800",
    "toggle-button-foreground": "neutral-900",
    "toggle-button-foreground-selected": "neutral-900",

    // SPA
    "spa-primary-background": "primary-200",
    "spa-primary-border": "primary-300",
    "spa-secondary-primary-border": "primary-500",
    "spa-secondary-background": "primary-200",
    "spa-secondary-border": "primary-500",
    "spa-tertiary-background": "primary-100",
    "spa-tertiary-border": "primary-500",

    // SPA - flight status
    "flight-status-details-equipment-background": "primary-500",
    "flight-status-find-reverse-button": "neutral-900",
    "flight-status-find-reverse-button-background": "primary-500",
    "flight-status-summary-background": "primary-300",
    "flight-status-summary-details-option": "secondary-500",

    // Disabled color definitions
    "standard-disabled-background": "neutral-200",
    "standard-disabled-foreground": "neutral-700",
  },
};
