import PropTypes from "prop-types";
import React from "react";

export const Nine = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path
        strokeWidth={0}
        d="M18.7,13.5c-0.6,0-1,0.2-1.4,0.7c-0.4,0.4-0.6,1.2-0.6,2.2s0.2,1.8,0.6,2.2c0.4,0.5,0.9,0.7,1.5,0.7
			s1.1-0.2,1.5-0.7s0.6-1.1,0.6-2c0-1-0.2-1.7-0.7-2.3C19.8,13.7,19.2,13.5,18.7,13.5z"
      />
      <path
        strokeWidth={0}
        d="M19,0C8.5,0,0,8.5,0,19s8.5,19,19,19s19-8.5,19-19S29.5,0,19,0z M22.7,25.1c-1.1,1.3-2.5,1.9-4.2,1.9
			c-1.2,0-2.2-0.3-3-1c-0.8-0.7-1.2-1.6-1.5-2.9l2.9-0.3c0.1,0.6,0.3,1,0.6,1.3s0.7,0.4,1.2,0.4c0.6,0,1.2-0.3,1.6-0.9
			s0.7-1.8,0.8-3.6c-0.8,0.9-1.7,1.3-2.8,1.3c-1.2,0-2.3-0.5-3.2-1.4c-0.9-1-1.3-2.2-1.3-3.7c0-1.6,0.5-2.9,1.4-3.9s2.2-1.5,3.6-1.5
			c1.6,0,2.9,0.6,3.9,1.9c1,1.2,1.5,3.3,1.5,6.1C24.3,21.8,23.7,23.9,22.7,25.1z"
      />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      style,
      viewBox: "0 0 38 38",
    };
  }
};

Nine.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Nine.defaultProps = {
  role: "img",
};
