import React, { useContext } from "react";

import { useAppSetting } from "@swa-ui/application";
import { getBootstrapData } from "@swa-ui/bootstrap";
import { useDeviceInfo, window } from "@swa-ui/browser";
import { Dialog, useDialog } from "@swa-ui/core";
import i18n from "@swa-ui/locale";
import { usePersonas } from "@swa-ui/personas";

import { AuthContext } from "../../AuthProvider";

export const MissingInformationRedirectDialog = () => {
  const { screenSize } = useDeviceInfo();
  const { cleanUpSession } = useContext(AuthContext);
  const { isCorporatePersona } = usePersonas();
  const { hideDialog } = useDialog("center");

  const forceNavigationToHomepageForIncompleteAccount = useAppSetting(
    "forceNavigationToHomepageForIncompleteAccount",
    false
  );

  return <Dialog {...getDialogProps()}>{renderDialogContent()}</Dialog>;

  function renderDialogContent() {
    const dialogMessage = isCorporatePersona
      ? i18n("MissingInformationRedirectDialog__CORPORATE_MESSAGE")
      : i18n("MissingInformationRedirectDialog__MESSAGE");

    return <p>{dialogMessage}</p>;
  }

  function getDialogProps() {
    return {
      buttonGroupProps: getButtonGroupProps(),
      closeOnBackgroundClick: false,
      heading: i18n("MissingInformationRedirectDialog__HEADING"),
      onClose: handleDialogClose,
      revealed: true,
      width: "wide",
    };
  }

  function getButtonGroupProps() {
    return {
      buttonList: [
        ...(!forceNavigationToHomepageForIncompleteAccount
          ? [
              {
                label: i18n("MissingInformationRedirectDialog__CONTINUE_AS_GUEST"),
                props: {
                  "aria-label": i18n("MissingInformationRedirectDialog__GUEST_ARIA_LABEL"),
                  name: "continueAsGuest",
                  onClick: handleDialogClose,
                  styleType: "secondary",
                  type: "button",
                },
              },
            ]
          : []),
        ...(isCorporatePersona
          ? [
              {
                label: i18n("MissingInformationRedirectDialog__REDIRECT_TO_HOMEPAGE"),
                props: {
                  "aria-label": i18n(
                    "MissingInformationRedirectDialog__CORPORATE_REDIRECT_ARIA_LABEL"
                  ),
                  name: "redirectToCorporateLoginPage",
                  onClick: handleRedirect,
                  styleType: "secondary",
                  type: "button",
                },
              },
            ]
          : [
              {
                label: i18n("MissingInformationRedirectDialog__REDIRECT_TO_HOMEPAGE"),
                props: {
                  "aria-label": i18n("MissingInformationRedirectDialog__REDIRECT_ARIA_LABEL"),
                  name: "redirectToIndexPage",
                  onClick: handleRedirect,
                  styleType: "primary",
                  type: "button",
                },
              },
            ]),
      ],
      equalSize: screenSize === "small",
    };
  }

  function handleDialogClose() {
    cleanUpSession();
    hideDialog();
  }

  function handleRedirect() {
    const { HOME_PAGE } = getBootstrapData("urls");

    cleanUpSession();
    window.location.replace(HOME_PAGE);
  }
};
