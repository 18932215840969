import * as yup from "yup";

let schema;

export const getLocationDataSchema = () => {
  if (!schema) {
    schema = yup.object().shape({
      areacode: yup.string(),
      asnum: yup.string(),
      bw: yup.string(),
      city: yup.string(),
      continent: yup.string(),
      country_code: yup.string(),
      county: yup.string(),
      dma: yup.string(),
      fips: yup.string(),
      georegion: yup.string(),
      inflight: yup.boolean(),
      latitude: yup.number(),
      longitude: yup.number(),
      msa: yup.string(),
      pmsa: yup.string(),
      region_code: yup.string(),
      throughput: yup.string(),
      timezone: yup.string(),
      zip: yup.string(),
    });
  }

  return schema;
};
