import PropTypes from "prop-types";
import React, { useContext } from "react";

import { PlacementFactory, PlacementsContext } from "@swa-ui/placements";
import { classNames } from "@swa-ui/string";

import { SiteStatusContext } from "../SiteStatusProvider";
import styles from "./DotcomSystemMessages.module.scss";

export const DotcomSystemMessages = (props) => {
  const { className } = props;
  const { siteStatus: { fitState } = {} } = useContext(SiteStatusContext);
  const { placements } = useContext(PlacementsContext);
  const systemMessagePlacementIds = [
    getPlacementId("messageIncidentAlert"),
    getPlacementId("messageTravelAdvisory"),
    getPlacementId("messageSystemAlert"),
  ];

  return (
    shouldRender() && (
      <div className={classNames(className, styles.dotcomSystemMessages)}>
        {systemMessagePlacementIds.map((systemMessagePlacementId) => (
          <PlacementFactory id={systemMessagePlacementId} key={systemMessagePlacementId} />
        ))}
      </div>
    )
  );

  function shouldRender() {
    return systemMessagePlacementIds.some((systemMessagePlacementId) =>
      Object.keys(placements).includes(systemMessagePlacementId)
    );
  }

  function getPlacementId(placementId) {
    return fitState ? `${placementId}fit` : placementId;
  }
};

DotcomSystemMessages.propTypes = {
  /** Additional classes for positioning the component. */
  className: PropTypes.string,
};
