import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import { window } from "@swa-ui/browser";
import { classNames } from "@swa-ui/string";

import styles from "./CalendarSecondary.module.scss";
import { Month } from "./Month";

const BROWSER_DELAY = 16;

/**
 * Component to display calender months. Currently not intended to be used outside of DateSelector.
 */

export const CalendarSecondary = (props) => {
  const {
    className,
    dateEnd,
    dateStart,
    daysOfWeekInitials,
    firstBookableDate,
    focusField,
    lastBookableDate,
    numberDates,
    onClick,
    revealed,
  } = props;
  const [hoverDate, setHoverDate] = useState();
  const [offsetTop, setOffsetTop] = useState(0);
  const containerRef = useRef();
  const endMarker = useRef();
  const monthsDisplayed = getMonthsToDisplay();
  const monthRefs = [...Array(monthsDisplayed)].map(() => useRef());
  const monthOffsets = useRef([]);

  useEffect(() => {
    if (containerRef.current?.offsetTop !== 0) {
      setOffsetTop(containerRef.current.offsetTop);

      window.setTimeout(() => {
        const heightOfCalendarContent = endMarker.current.offsetTop;
        const heightOfViewport = window.innerHeight - offsetTop;
        const selectedMonth = getMonth();
        const top = Math.min(
          heightOfCalendarContent - heightOfViewport,
          monthOffsets.current[selectedMonth]
        );

        containerRef.current.scrollTo({ behavior: "smooth", left: 0, top });
      }, 1);
    }
  }, [containerRef.current?.offsetTop, revealed]);

  useEffect(() => {
    for (let ndx = 0; ndx < monthsDisplayed; ndx += 1) {
      monthOffsets.current[ndx] = monthRefs[ndx].current.offsetTop;
    }
  }, [monthRefs[0]?.current]);

  useEffect(() => {
    const selectedMonth = getMonth();

    window.setTimeout(() => {
      containerRef.current?.scrollTo?.({
        behavior: "smooth",
        left: 0,
        top: monthOffsets.current[selectedMonth],
      });
    }, BROWSER_DELAY);
  }, [dateEnd, dateStart]);

  useEffect(() => {
    const { overflow } = window.document.body.style;

    if (revealed) {
      window.document.body.style.overflow = "hidden";
    }

    return () => {
      window.document.body.style.overflow = overflow || "";
    };
  }, [revealed]);

  return (
    <div {...getProps()}>
      {[...Array(monthsDisplayed)].map((_, index) => (
        <Month key={index} {...getMonthProps(index)} />
      ))}
      <div className={styles.endMarker} ref={endMarker}>
        &nbsp;
      </div>
    </div>
  );

  function getProps() {
    return {
      className: classNames(className, styles.calendar),
      ref: containerRef,
    };
  }

  function getMonthProps(calendarIndex) {
    const { month, year } = getSubsequentMonthYear(calendarIndex);

    return {
      className: getMonthClass(calendarIndex),
      dateEnd,
      dateStart,
      daysOfWeekInitials,
      firstBookableDate,
      focusField,
      hoverDate,
      lastBookableDate,
      month,
      numberDates,
      onClick,
      onMouseEnter: handleMouseEnter,
      onMouseLeave: handleMouseLeave,
      ref: monthRefs[calendarIndex],
      year,
    };
  }

  function getMonthClass(calendarIndex) {
    return classNames(styles.month, {
      [styles.separator]: calendarIndex < monthsDisplayed - 1,
    });
  }

  function handleMouseEnter(date) {
    setHoverDate(date);
  }

  function handleMouseLeave() {
    setHoverDate(undefined);
  }

  function getMonthsToDisplay() {
    const lastDate = new Date(lastBookableDate);
    const todayDate = new Date();
    let months = (lastDate.getFullYear() - todayDate.getFullYear()) * 12;

    months -= todayDate.getMonth();
    months += lastDate.getMonth();

    return months + 1;
  }

  function getMonth() {
    const date = focusField === "end" ? dateEnd : dateStart;
    const todayMonth = new Date().getMonth() + 1;
    let month = todayMonth;

    if (date) {
      month = parseInt(date.split("/")[0]);
    }

    if (dateStart && focusField === "end") {
      const startMonth = parseInt(dateStart.split("/")[0]);

      if (startMonth < month) {
        month -= 1;
      }
    }

    return month > todayMonth ? month - todayMonth : month + 12 - todayMonth;
  }

  function getSubsequentMonthYear(index) {
    let today = new Date();
    const todayMonth = today.getMonth();
    const todayYear = today.getFullYear();

    today = new Date(`${todayMonth + 1}/1/${todayYear}`);
    today.setMonth(todayMonth + index);

    return {
      month: `${today.getMonth() + 1}`,
      year: `${today.getFullYear()}`,
    };
  }
};

CalendarSecondary.propTypes = {
  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** End date if given should be in this format: MM/DD/YYYY (02/02/2020). */
  dateEnd: PropTypes.string,

  /** Start date if given should be in this format: MM/DD/YYYY (02/02/2020). */
  dateStart: PropTypes.string,

  /** Initial letters of each day of the week: Sunday, Monday. */
  daysOfWeekInitials: PropTypes.string,

  /** First date that can be booked. Should be in this format: MM/DD/YYYY. */
  firstBookableDate: PropTypes.string,

  /** Indicates which date the traveler is selecting. */
  focusField: PropTypes.oneOf(["end", "start"]),

  /** Last date that can be booked. Should be in this format: MM/DD/YYYY. */
  lastBookableDate: PropTypes.string,

  /** One or two dates can be selected so the calendar will allow for start and end dates. */
  numberDates: PropTypes.number,

  /** Event handler to learn when an end date is selected. The event handler will be passed a date string. */
  onClick: PropTypes.func,
};
