import PropTypes from "prop-types";
import React from "react";

export const Hotel = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path d="M9,3 L11,3" strokeLinecap="round" strokeWidth="2" />
      <path d="M5,4 L15,4" strokeLinecap="round" strokeWidth="2" />
      <path d="M3,5 L17,5" strokeLinecap="round" strokeWidth="2" />

      <path d="M4,4 L4,27" strokeWidth="3" />
      <path d="M10,4 L10,27" strokeWidth="3" />
      <path d="M16,4 L16,27" strokeWidth="3" />
      <path d="M22,11 L22,27" strokeWidth="3" />
      <path d="M28,11 L28,27" strokeWidth="3" />

      <path d="M4,12 L28,12" strokeLinecap="round" strokeWidth="3" />
      <path d="M4,20 L28,20" strokeLinecap="round" strokeWidth="3" />
      <path d="M3,28 L29,28" strokeLinecap="round" strokeWidth="3" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      style,
      viewBox: "0 0 32 32",
    };
  }
};

Hotel.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Hotel.defaultProps = {
  role: "img",
};
