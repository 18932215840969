import PropTypes from "prop-types";
import React from "react";

export const DepartingCircle = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path
        d="M17.5,0C7.8,0,0,7.8,0,17.5C0,27.1,7.8,35,17.5,35S35,27.1,35,17.5C35,7.8,27.2,0,17.5,0z M26,9.6
	c0,0.4,0,1.6-1.6,3.2l-2.7,2.8l2.3,8.6c0.1,0.4-0.2,0.7-0.2,0.7L22.7,26l-4-7.5l-1,1c-0.5,0.5-1.3,1.1-2.1,1.7l0.3,2.6
	c0,0.5-0.2,0.8-0.2,0.8l-1.1,1.1l-1.3-3.1c-0.4,0.2-0.7,0.2-0.9,0.2h-0.1c0-0.2,0-0.6,0.2-1l-3.1-1.4l1.1-1.1c0,0,0.3-0.3,0.8-0.2
	l2.6,0.3c0.5-0.7,1.1-1.5,1.7-2.1l1-1l-7.4-4.1l1.1-1.1c0,0,0.3-0.3,0.7-0.1l8.6,2.3l2.7-2.7c1.6-1.7,2.9-1.7,3.2-1.7
	c0,0,0.4,0,0.5,0.1C26,9.2,26,9.6,26,9.6z"
      />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "0 0 35 35",
    };
  }
};

DepartingCircle.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

DepartingCircle.defaultProps = { role: "img" };
