import { window } from "@swa-ui/browser";

const ERROR_CODE_UI = "900000000";
const LOG_MESSAGE_FAILED_TO_FETCH = "Failed to fetch";
const LOG_UNIVERSAL_EXCEPTION = "UniversalException";
const LOG_URL = "/api/logging/v1/logging/desktop/log";

const not = (any) => !any;

export const logger = {
  debug(message, context) {
    this.log(message, context, "DEBUG");
  },

  error(message, context) {
    this.log(message, context, "ERROR");
  },

  info(message, context) {
    this.log(message, context, "INFO");
  },

  init(config) {
    if (!this.config) {
      this.config = config;

      // Removing sonar for this line due to sonar defect, addressed in newer sonar versions.
      // prettier-ignore
      window.addEventListener?.("error", (event) => { // NOSONAR
        this.error(event?.message, {
          codes: [ERROR_CODE_UI],
          component: LOG_UNIVERSAL_EXCEPTION,
          stack: event?.error?.stack,
        });
      });

      // Removing sonar for this line due to sonar defect, addressed in newer sonar versions.
      // prettier-ignore
      window.addEventListener?.("unhandledrejection", (event) => { // NOSONAR
        this.error(event?.reason?.message, {
          component: LOG_UNIVERSAL_EXCEPTION,
          stack: event?.reason?.stack,
        });
      });
    }
  },

  async log(message, context = {}, level = "INFO") {
    const { apiKey, appId, channelId, experienceId, url = LOG_URL } = this.config;

    if (shouldLogRequest()) {
      try {
        await window.fetch(url, {
          body: JSON.stringify(getApiLoggingBody()),
          headers: {
            "content-type": "application/json",
            "x-api-key": apiKey,
            "x-app-id": appId,
            "x-channel-id": channelId,
            "x-user-experience-id": experienceId,
          },
          method: "POST",
        });
      } catch (e) {
        /* empty */
      }
    }

    function shouldLogRequest() {
      return not(
        message === LOG_MESSAGE_FAILED_TO_FETCH && context.component === LOG_UNIVERSAL_EXCEPTION
      );
    }

    function getApiLoggingBody() {
      return {
        messages: [
          {
            action: getContextPathname(),
            component: context?.component ?? "",
            count: "1",
            details: getDetails(),
            errorCode: getErrorCode(),
            httpCode: getHttpCode(),
            level: context?.level ?? level,
            location: getLocation(),
            message,
            timestamp: Math.floor(Date.now() / 1000),
          },
        ],
      };
    }

    function getDetails() {
      const buildDetails = () => ({
        callDuration: context.callDuration,
        code: context.code,
        errorCode: getErrorCode(),
        errorThrown: context.error,
        experienceId,
        httpCode: getHttpCode(),
        message: context.message,
        requestId: context.requestId,
        textStatus: context.statusText,
        url: context.url,
      });

      return JSON.stringify(context?.error?.stack ?? buildDetails());
    }

    function getErrorCode() {
      const { codes = [""] } = context;

      return codes[0];
    }

    function getHttpCode() {
      return context?.status ?? "";
    }

    function getLocation() {
      const pathname = window?.location?.pathname ?? "";

      return pathname.split("/").filter(Boolean).join("/");
    }

    function getContextPathname() {
      const contextUrl = context?.url;

      return contextUrl ? new window.URL(contextUrl).pathname : null;
    }
  },

  warn(message, context) {
    this.log(message, context, "WARN");
  },
};
