import PropTypes from "prop-types";
import React from "react";

export const Call = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path d="M16,6.88l-.44-1.36-.07-.2,0-.11L15.38,5l-.45-1.36A2.28,2.28,0,0,0,12.48,2a6.52,6.52,0,0,0-.84.18l2.47,7.52.33-.09.31-.09A2,2,0,0,0,16,6.88Z" />
      <path d="M20.63,26.76l.67-.34-3.8-7h0l-1.3.71a22.17,22.17,0,0,1-2.73-4.67h0a21.57,21.57,0,0,1-1.52-5.16L13.34,10h.05L10.92,2.42l-.73.22c-2.44.77-2.27,7.25.61,13.92h0C13.43,22.63,17.51,27,20,26.93a2.21,2.21,0,0,1-.09,1.46,2.26,2.26,0,0,1-1.73,1.25.19.19,0,0,0-.16.2.18.18,0,0,0,.18.16h0a2.65,2.65,0,0,0,2-1.47,2.52,2.52,0,0,0,.12-1.66A1.63,1.63,0,0,0,20.63,26.76Z" />
      <path d="M22.52,21.53l-.1-.19-.06-.11-.09-.18-.69-1.26a2.15,2.15,0,0,0-2.85-1l-.28.15-.29.17,3.8,7c.32-.18.56-.33.73-.45a2.17,2.17,0,0,0,.52-2.83Z" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      style,
      viewBox: "0 0 32 32",
    };
  }
};

Call.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Call.defaultProps = {
  role: "img",
};
