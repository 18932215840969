import PropTypes from "prop-types";
import React from "react";

export const OutdoorGear = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path
        d="M 4.80789473684218 23.361702127659562  C 4.578947368421127 27.65106382978722  4.350000000000075 33.7787234042553  4.80789473684218 34.544680851063816  
  C 4.80789473684218 34.544680851063816  5.036842105263234 35.693617021276594  6.2578947368421804 35.31063829787232  C 7.097368421052707 35.080851063829776  
  6.944736842105339 31.25106382978722  6.944736842105339 31.25106382978722  C 6.944736842105339 31.25106382978722  6.715789473684286 19.225531914893608  
  6.715789473684286 19.225531914893608  C 6.715789473684286 19.225531914893608  6.79210526315797 1.8382978723404129  6.79210526315797 1.8382978723404129  
  C 6.79210526315797 1.8382978723404129  6.715789473684287 0.22978723404254048  5.952631578947445 0.3063829787233916  C 5.189473684210602 0.3063829787233916  
  4.655263157894814 1.3021276595744553  4.578947368421129 2.757446808510626  C 4.578947368421129 2.757446808510626  4.578947368421129 3.140425531914881  
  4.6552631578948125 4.825531914893604  C 4.731578947368496 7.046808510638286  4.80789473684218 23.361702127659562  4.80789473684218 23.361702127659562  
  Z M 1.98421052631579 35.08085106382976  C 1.98421052631579 35.08085106382976  1.98421052631579 34.00851063829785  1.98421052631579 34.00851063829785  
  C 2.7473684210526326 34.00851063829785  3.3578947368421055 33.778723404255295  3.3578947368421055 33.62553191489359  C 3.3578947368421055 33.54893617021274  
  3.2052631578947377 33.395744680851045  2.9763157894736847 33.319148936170194  C 3.3578947368421055 33.24255319148934  3.663157894736843 33.08936170212764  
  3.663157894736843 32.93617021276594  C 3.663157894736843 32.70638297872338  2.9000000000000004 32.47659574468083  1.98421052631579 32.47659574468083  
  C 1.98421052631579 32.47659574468083  1.98421052631579 7.582978723404231  1.98421052631579 7.582978723404231  C 1.98421052631579 7.582978723404231  
  2.1368421052631583 7.582978723404231  2.1368421052631583 7.582978723404231  C 2.1368421052631583 7.582978723404231  2.289473684210526 6.9702127659574185  
  2.5184210526315796 6.89361702127657  C 2.5184210526315796 6.89361702127657  2.9000000000000004 6.817021276595719  2.9000000000000004 6.510638297872312  
  C 2.9000000000000004 6.510638297872312  3.128947368421054 6.357446808510612  3.052631578947368 5.897872340425504  C 3.052631578947368 5.897872340425504  
  2.823684210526315 5.897872340425504  2.823684210526315 5.897872340425504  C 2.823684210526315 5.897872340425504  2.823684210526315 1.6851063829786976  
  2.823684210526315 1.6851063829786976  C 2.823684210526315 1.3787234042552932  2.594736842105262 1.2255319148935908  2.3657894736842104 1.2255319148935908  
  C 2.3657894736842104 1.2255319148935908  1.4500000000000006 1.2255319148935908  1.4500000000000006 1.2255319148935908  C 1.1447368421052628 1.2255319148935908  
  0.9157894736842107 1.4553191489361443  0.9157894736842107 1.6851063829786976  C 0.9157894736842107 1.6851063829786976  0.9157894736842107 5.821276595744655  
  0.9157894736842107 5.821276595744655  C 0.9157894736842107 5.821276595744655  0.6105263157894736 5.821276595744655  0.6105263157894736 5.821276595744655  
  C 0.5342105263157894 6.357446808510612  0.763157894736842 6.510638297872315  0.763157894736842 6.510638297872315  C 0.763157894736842 6.89361702127657  
  1.1447368421052628 6.89361702127657  1.1447368421052628 6.89361702127657  C 1.3736842105263156 6.9702127659574185  1.526315789473684 7.582978723404231  
  1.526315789473684 7.582978723404231  C 1.526315789473684 7.582978723404231  1.6789473684210527 7.582978723404231  1.6789473684210527 7.582978723404231  
  C 1.6789473684210527 7.582978723404231  1.6789473684210527 32.39999999999998  1.6789473684210527 32.39999999999998  C 0.763157894736842 32.476595744680836  
  0 32.70638297872338  0 32.93617021276594  C 0 33.08936170212764  0.3052631578947371 33.242553191489336  0.763157894736842 33.319148936170194  
  C 0.5342105263157894 33.395744680851045  0.38157894736842124 33.4723404255319  0.38157894736842124 33.62553191489359  C 0.38157894736842124 33.85531914893615  
  0.9921052631578948 34.00851063829785  1.6789473684210527 34.00851063829785  C 1.6789473684210527 34.00851063829785  1.6789473684210527 35.08085106382976  
  1.6789473684210527 35.08085106382976  L 1.98421052631579 35.08085106382976  Z M 14.042105263158028 33.85531914893619  C 14.80526315789487 33.85531914893619  
  15.415789473684345 33.62553191489364  15.415789473684345 33.47234042553193  C 15.415789473684345 33.39574468085108  15.263157894736976 33.24255319148938  
  15.034210526315924 33.16595744680853  C 15.415789473684345 33.24255319148938  15.644736842105399 33.08936170212768  15.644736842105399 32.93617021276597  
  C 15.644736842105399 32.70638297872342  14.881578947368558 32.476595744680864  13.965789473684348 32.476595744680864  C 13.965789473684348 32.476595744680864  
  13.965789473684348 7.582978723404272  13.965789473684348 7.582978723404272  C 13.965789473684348 7.582978723404272  14.118421052631714 7.582978723404272  
  14.118421052631714 7.582978723404272  C 14.118421052631714 7.582978723404272  14.271052631579082 6.970212765957463  14.500000000000135 6.893617021276615  
  C 14.500000000000135 6.893617021276615  14.881578947368558 6.817021276595762  14.881578947368558 6.510638297872358  C 14.881578947368558 6.510638297872358  
  15.110526315789611 6.357446808510657  15.034210526315924 5.897872340425549  C 15.034210526315924 5.897872340425549  14.652631578947505 5.897872340425549  
  14.652631578947505 5.897872340425549  C 14.652631578947505 5.897872340425549  14.652631578947505 1.6851063829787414  14.652631578947505 1.6851063829787414  
  C 14.652631578947505 1.378723404255337  14.423684210526451 1.2255319148936346  14.194736842105398 1.2255319148936346  C 14.194736842105398 1.2255319148936346  
  13.278947368421187 1.2255319148936346  13.278947368421187 1.2255319148936346  C 13.202631578947505 1.2255319148936346  12.973684210526452 1.455319148936188  
  12.973684210526452 1.6851063829787414  C 12.973684210526452 1.6851063829787414  12.973684210526452 5.821276595744699  12.973684210526452 5.821276595744699  
  C 12.973684210526452 5.821276595744699  12.668421052631714 5.821276595744699  12.668421052631714 5.821276595744699  C 12.59210526315803 6.280851063829804  
  12.821052631579084 6.434042553191508  12.821052631579084 6.434042553191508  C 12.821052631579084 6.817021276595762  13.202631578947505 6.817021276595762  
  13.202631578947505 6.817021276595762  C 13.431578947368557 6.893617021276615  13.584210526315925 7.506382978723422  13.584210526315925 7.506382978723422  
  C 13.584210526315925 7.506382978723422  13.736842105263294 7.506382978723422  13.736842105263294 7.506382978723422  C 13.736842105263294 7.506382978723422  
  13.736842105263294 32.32340425531917  13.736842105263294 32.32340425531917  C 12.821052631579084 32.32340425531917  12.057894736842243 32.55319148936172  
  12.057894736842243 32.78297872340428  C 12.057894736842243 32.93617021276598  12.363157894736977 33.08936170212768  12.821052631579084 33.16595744680853  
  C 12.59210526315803 33.24255319148938  12.439473684210663 33.31914893617024  12.439473684210663 33.47234042553193  C 12.439473684210663 33.70212765957449  
  13.050000000000137 33.85531914893619  13.736842105263294 33.85531914893619  C 13.736842105263294 33.85531914893619  13.736842105263294 34.9276595744681  
  13.736842105263294 34.9276595744681  L 14.042105263158028 34.9276595744681  C 14.042105263158028 34.9276595744681  14.042105263158028 33.85531914893619  
  14.042105263158028 33.85531914893619  Z M 8.547368421052507 31.25106382978722  C 8.547368421052507 31.25106382978722  8.77631578947356 19.225531914893608  
  9 19.225531914893608  L 9 1.8382978723404129  C 8.776315789473557 1.8382978723404129  8.852631578947241 0.22978723404254048  9.615789473684083 
  0.3063829787233916  C 10.378947368420926 0.3063829787233916  10.913157894736715 1.3021276595744553  10.9894736842104 2.757446808510626  C 10.9894736842104 
  2.757446808510626  10.9894736842104 3.140425531914881  10.913157894736715 4.825531914893604  C 10.836842105263031 7.046808510638286  10.760526315789347 
  23.361702127659562  10.760526315789347 23.361702127659562  C 10.9894736842104 27.65106382978722  11.218421052631454 33.7787234042553  10.760526315789347 
  34.544680851063816  C 10.760526315789347 34.544680851063816  10.531578947368294 35.693617021276594  9.310526315789348 35.31063829787232  C 8.471052631578821 
  35.080851063829776  8.547368421052507 31.25106382978722  8.547368421052507 31.25106382978722  Z M 23.886842105263142 35.46382978723404  C 21.29210526315787 
  35.46382978723404  19.61315789473682 33.31914893617021  19.765789473684187 29.565957446808504  C 21.1394736842105 21.98297872340425  20.986842105263136 
  13.634042553191486  20.299999999999976 5.208510638297872  C 20.071052631578922 2.2212765957446807  22.1315789473684 0  24.802631578947345 0  C 27.473684210526297 
  0  29.381578947368414 2.2212765957446807  28.9236842105263 5.361702127659575  C 27.70263157894735 13.710638297872343  27.70263157894735 22.059574468085103  
  28.313157894736825 30.40851063829787  C 28.54210526315788 33.242553191489364  26.405263157894723 35.46382978723404  23.886842105263142 35.46382978723404  Z "
      />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      style,
      viewBox: "-5 -2 38 38",
    };
  }
};

OutdoorGear.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

OutdoorGear.defaultProps = { role: "img" };
