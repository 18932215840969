import PropTypes from "prop-types";
import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";

import { useAppSetting } from "@swa-ui/application";
import { swaDate } from "@swa-ui/date";
import { useGeolocation } from "@swa-ui/geolocation";
import { NATIVE_APP_INTERFACE_NAMES, sendMessageToNativeApp } from "@swa-ui/hybrid";

import { AuthContext } from "../AuthProvider";
import { decodeCookie } from "../cookie";
import { authRecommendation } from "../defines/authRecommendation";

export const MfaContext = createContext();

export const MfaProvider = (props) => {
  const { children } = props;
  const { auth, handleIdTokenOnlyExchange } = useContext(AuthContext);
  const [mfaExpiredCheck, setMfaExpiredCheck] = useState(0);
  const mfaStatus = useMemo(getMfaStatus, [auth]);
  const timeoutId = useRef(100);
  const mfaTimeUntilNextAuthenticationInSeconds = useAppSetting(
    "mfaTimeUntilNextAuthenticationInSeconds",
    1800
  );
  const mfaEnabled = useAppSetting("mfaEnabled", true);
  const geolocation = useGeolocation();
  const isUserInFlight = geolocation.locationData?.inflight;

  useEffect(() => {
    setMfaResetTimeout();
  }, [auth]);

  return <MfaContext.Provider value={getContextValue()}>{children}</MfaContext.Provider>;

  function getContextValue() {
    return {
      mfaAuthenticated: isMfaAuthenticated(),
      ...mfaStatus,
      handleMfaAuthenticated: () => {
        handleIdTokenOnlyExchange(auth.idToken);
        sendMessageToNativeApp(NATIVE_APP_INTERFACE_NAMES.MFA_AUTHENTICATED);
      },
    };
  }

  function setMfaResetTimeout() {
    const expirationDurationInSeconds = getExpirationDurationInSeconds();

    clearMfaTimeout();

    if (expirationDurationInSeconds > 0) {
      timeoutId.current = setTimeout(() => {
        setMfaExpiredCheck((mfaExpiredCheck + 1) % 2);
      }, expirationDurationInSeconds * 1000);
    }
  }

  function isMfaAuthenticated() {
    return (
      !mfaEnabled ||
      isUserInFlight ||
      getTimeSinceLastMfaInSeconds() < mfaTimeUntilNextAuthenticationInSeconds
    );
  }

  function getExpirationDurationInSeconds() {
    return mfaTimeUntilNextAuthenticationInSeconds - getTimeSinceLastMfaInSeconds();
  }

  function getTimeSinceLastMfaInSeconds() {
    const now = swaDate().unix();
    const { mfaOtpTime = now } = mfaStatus ?? {};

    return now - mfaOtpTime;
  }

  function clearMfaTimeout() {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }

    timeoutId.current = 0;
  }

  function getMfaStatus() {
    const idTokenData = getIdTokenData();

    return {
      mfaOtpTime: idTokenData?.otp_time ?? 0,
      mfaRequired:
        (idTokenData?.auth_rec?.toLowerCase() ?? authRecommendation.APPROVE) !==
        authRecommendation.APPROVE,
    };
  }

  function getIdTokenData() {
    return auth.idToken ? decodeCookie(auth.idToken) : {};
  }
};

MfaContext.displayName = "MfaContext";
MfaProvider.propTypes = {
  /** Content to be rendered on the page. */
  children: PropTypes.node.isRequired,
};
