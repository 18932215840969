import { getSearchParam } from "@swa-ui/browser";

import { siteToChannel } from "../defines";

export const getChannelId = () => {
  const channelParam = getSearchParam("channel");
  const site = process.env.SITE;

  return channelParam ? channelParam.toLowerCase() : siteToChannel[site];
};
