import PropTypes from "prop-types";
import React from "react";

import { BannerWithCard } from "../BannerWithCard";
import { BannerLayout1 } from "./BannerLayout1";
import { BannerLayout2 } from "./BannerLayout2";
import { BannerLayout3 } from "./BannerLayout3";

/**
 * Banner component provides an entry point for placements with displayType as 'banner'.
 *
 * @param props
 * @returns {*|null}
 * @constructor
 */

export const Banner = (props) => {
  const { placement } = props;
  const { bannerType, layoutType } = placement ?? {};
  const banners = {
    banner: {
      layout1: <BannerLayout1 {...props} />,
      layout2: <BannerLayout2 {...props} />,
      layout3: <BannerLayout3 {...props} />,
    },
    "banner-with-card": <BannerWithCard {...props} />,
  };

  return banners?.[bannerType]?.[layoutType] ?? banners?.[bannerType] ?? null;
};

Banner.propTypes = {
  /** Placement object for displayType banner. */
  placement: PropTypes.shape({
    /** Type of banner intended for the placement. */
    bannerType: PropTypes.oneOf(["banner", "banner-with-card"]).isRequired,

    /** Type of banner layout for a given banner type. */
    layoutType: PropTypes.oneOf(["layout1", "layout2", "layout3"]).isRequired,
  }),
};
