import PropTypes from "prop-types";
import React from "react";

import { ScrollToTop, useDeviceInfo } from "@swa-ui/browser";
import { ErrorBoundary } from "@swa-ui/error";
import { classNames } from "@swa-ui/string";

import { ManageAppSettings } from "../ManageAppSettings";
import { MessageProvider } from "../MessageProvider";
import { Toolbox } from "../Toolbox";
import { useMbox } from "../useMbox";
import { usePageId } from "../usePageId";
import { Version } from "../Version";
import { VisualizeI18n } from "../VisualizeI18n";
import { ErrorPage } from "./ErrorPage";
import { SkipToContent } from "./SkipToContent";

/**
 * Page is the outermost component for all SPAs. It provides the page's layout and includes the global header and
 * footer.
 */

export const Page = (props) => {
  const {
    basePath,
    children,
    className,
    experienceId,
    footer,
    header,
    light,
    suppressMessageForTrackingCodes,
  } = props;
  const ErrorFallback = (errorProps) => <ErrorPage {...getErrorPageProps(errorProps)} />;
  const { mbox: mboxClassName = "" } = useMbox("pageClassName");
  const { environment, screenSize } = useDeviceInfo();
  const { pageId } = usePageId();
  const isProdEnvironment = environment === "production";

  return (
    <div className={classNames(className, mboxClassName, screenSize, pageId)}>
      <ScrollToTop />
      <SkipToContent contentSelector={"[id=pageContent]"} light={light} />
      <MessageProvider {...{ experienceId, suppressMessageForTrackingCodes }}>
        <Version basePath={basePath} />
        {!isProdEnvironment && <ManageAppSettings />}
        <VisualizeI18n>
          {header}
          <ErrorBoundary FallbackComponent={ErrorFallback} showDetails>
            <Toolbox />
            <div id="pageContent">{children}</div>
          </ErrorBoundary>
          {footer}
        </VisualizeI18n>
      </MessageProvider>
    </div>
  );

  function getErrorPageProps(errorProps) {
    const { error, errorInfo } = errorProps;

    return {
      basePath,
      error,
      errorInfo,
      experienceId,
    };
  }
};

Page.propTypes = {
  /** Application's base path like '/gift-card'. */
  basePath: PropTypes.string.isRequired,

  /** Content that will be rendered as the page body. */
  children: PropTypes.node.isRequired,

  /**
   * Additional classes for positioning the page. Given classes may only position the page for layout purposes, and
   * cannot change how the page renders in any other way.
   */
  className: PropTypes.string,

  /** Unique identifier for every customer session. This should be passed down from the application. */
  experienceId: PropTypes.string.isRequired,

  /** React component to display at bottom of each page. */
  footer: PropTypes.node,

  /** React component to display at the top of each page which typically provides a logo and navigation options. */
  header: PropTypes.node,

  /** Indicates if the app is using a dark background header. This value is passed to the header links for styling. */
  light: PropTypes.bool,

  /**
   * List of error codes strings. If an error is return from a fetch, typically a toast message is displayed with the
   * error. If the error code is in this list, no error will be displayed.
   */
  suppressMessageForTrackingCodes: PropTypes.arrayOf(PropTypes.string),
};
