import PropTypes from "prop-types";
import React from "react";

export const Arrow = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path d="M25.09 18.88 16.61 28 13.7 25.12 20 18.4 4 18.4 4 13.6 20 13.6 13.7 6.88 16.61 4 25.09 13.12 28 16 25.09 18.88" />
    </svg>
  );

  function getProps() {
    return {
      "aria-hidden": props["aria-hidden"],
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      style,
      viewBox: "0 0 32 32",
    };
  }
};

Arrow.propTypes = {
  /** aria-hidden text to hide non-interactive content from the accessibility API icon. */
  "aria-hidden": PropTypes.string,

  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Arrow.defaultProps = {
  role: "img",
};
