import PropTypes from "prop-types";
import React from "react";

export const Heart = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path
        d="M4.375 1.875C2.64938 1.875 1.25 3.26 1.25 4.96875C1.25 6.34812 1.79688 9.62188 7.18 12.9313C7.27643 12.9899 7.38713 13.021 7.5 13.021C7.61287 13.021 7.72357 12.9899 7.82 12.9313C13.2031 9.62188 13.75 6.34812 13.75 4.96875C13.75 3.26 12.3506 1.875 10.625 1.875C8.89938 1.875 7.5 3.75 7.5 3.75C7.5 3.75 6.10062 1.875 4.375 1.875Z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      style,
      viewBox: "0 0 15 15",
    };
  }
};

Heart.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Heart.defaultProps = { role: "img" };
