import PropTypes from "prop-types";
import React, { useContext } from "react";

import { classNames } from "@swa-ui/string";

import { Background, backgroundPropTypes } from "../Background";
import { ConfigurationContext } from "../ConfigurationContext";
import { Grid } from "../Grid";
import { Heading, headingPropTypes } from "../Heading";
import styles from "./Section.module.scss";

/**
 * Section implements a typical html section element, which contains a heading and a paragraph of content,
 * and creates context to enable auto-sizing for child Headings.
 */

export const Section = (props) => {
  const { backgroundSize, backgroundUrl, children, className, grid, headingProps, role } = props;
  const configurationContext = useContext(ConfigurationContext) ?? {};
  const { nestedHeadingLevel } = configurationContext;
  const Container = grid ? Grid : React.Fragment;

  return (
    <>
      {backgroundUrl && <Background imageUrl={backgroundUrl} size={backgroundSize} />}
      <Container {...getContainerProps()}>
        <section {...getSectionProps()}>
          {renderSectionHeading()}
          <ConfigurationContext.Provider
            value={{ ...configurationContext, nestedHeadingLevel: getNestedHeadingLevel() }}
          >
            {children}
          </ConfigurationContext.Provider>
        </section>
      </Container>
    </>
  );

  function renderSectionHeading() {
    return (
      headingProps && (
        <Heading {...{ headingLevel: getNestedHeadingLevel(), ...headingProps }}>
          {headingProps.children}
        </Heading>
      )
    );
  }

  function getContainerProps() {
    return grid ? { className: styles.mainGrid } : {};
  }

  function getSectionProps() {
    return {
      className: classNames(className, { [styles.section]: grid }),
      role,
    };
  }

  function getNestedHeadingLevel() {
    return nestedHeadingLevel ? nestedHeadingLevel + 1 : 1;
  }
};

Section.propTypes = {
  /**
   * Size determines how the background will fill the window. For more information, look up CSS properties contain and
   * cover. For cover, the background-position is set to center.
   */
  backgroundSize: backgroundPropTypes.size,

  /** Background image URL to use for the full bleed background. */
  backgroundUrl: backgroundPropTypes.imageUrl,

  /** Content that will be rendered in heading. */
  children: PropTypes.node,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Whether or not to wrap children inside of a Grid core component. */
  grid: PropTypes.bool,

  /** See Heading component for available options. */
  headingProps: PropTypes.shape(headingPropTypes),

  /** Name used for aria role. */
  role: PropTypes.string,
};
