import PropTypes from "prop-types";
import React from "react";

export const RemoteControlToys = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path
        d="M41.2,14.9c-1.3,0-2.4-1.1-2.4-2.4c0-1.3,1.1-2.4,2.4-2.4c1.3,0,2.4,1.1,2.4,2.4
		C43.6,13.9,42.5,14.9,41.2,14.9 M26.1,14.9l0-2.4c0,0,10.3,0,10.3,0l0,2.4L26.1,14.9z M21.2,14.9c-1.3,0-2.4-1.1-2.4-2.4
		c0-1.3,1.1-2.4,2.4-2.4c1.3,0,2.4,1.1,2.4,2.4C23.5,13.9,22.5,14.9,21.2,14.9 M25,2.2h12.5c0,0,2.7,5.2,2.7,5.2H22.2
		C22.2,7.3,25,2.2,25,2.2 M43.3,7.5l-0.5-0.4h0.9c0.6,0,0.8-0.5,0.8-1.1c0-0.6-0.2-1.1-0.8-1.1h-2.1l-1.7-2.9
		c-0.2-0.4-0.4-0.8-0.7-1.2c-0.6-0.6-1.5-1-2.7-1h0h-2.9h0h-4.8h0h-2.9h0c-1.2,0-2.1,0.4-2.7,1c-0.3,0.3-0.6,0.7-0.7,1.2L20.8,5
		h-2.1c-0.6,0-0.8,0.5-0.8,1.1c0,0.6,0.2,1.1,0.8,1.1h0.9l-0.5,0.4c-1.1,0.3-2.9,1.2-2.9,3.4v6.3h4.2c1.7,0,3.3,1.2,3.8,2.9h17h0.6
		v3c0,0.5,0.4,0.8,0.8,0.8h2.6c0.5,0,0.8-0.4,0.8-0.8V10.9C46.1,8.7,44.4,7.8,43.3,7.5"
      />
      <path
        d="M16.1,29.6c-2.1,0-3.8-1.7-3.8-3.8c0-2.1,1.7-3.8,3.8-3.8c2.1,0,3.8,1.7,3.8,3.8C20,27.8,18.2,29.6,16.1,29.6
		 M6.2,29.6c-2.1,0-3.8-1.7-3.8-3.8c0-2.1,1.7-3.8,3.8-3.8c2.1,0,3.8,1.7,3.8,3.8C10.1,27.8,8.4,29.6,6.2,29.6 M20.4,19.3h-5.9v-1.4
		c0-0.5-0.3-0.7-0.7-0.7h-2.2v-3.7c0.2-0.2,0.4-0.4,0.4-0.7c0-0.5-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.4-0.9,0.9c0,0.3,0.2,0.6,0.4,0.7
		v3.7H8.5c-0.4,0-0.7,0.3-0.7,0.7v1.4H2c-1,0-2,1-2,2v8.9c0,1,1,2,2,2h18.5c1,0,2-1,2-2v-8.9C22.4,20.3,21.4,19.3,20.4,19.3"
      />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "0 0 46.1 32.1",
    };
  }
};

RemoteControlToys.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

RemoteControlToys.defaultProps = {
  role: "img",
};
