import PropTypes from "prop-types";
import React from "react";

import { Grid, Icon, Link } from "@swa-ui/core";
import { classNames } from "@swa-ui/string";

import { HtmlValue } from "../HtmlValue";
import styles from "./SystemMessage.module.scss";

/**
 * SystemMessage renders content as a text block.
 */

export const SystemMessage = (props) => {
  const { messageList } = props;

  return messageList ? (
    <>
      {messageList.map((message, index) => (
        <div
          key={`${message.labelText}-${index}`}
          className={getSystemMessageClass(message.labelText)}
        >
          <Grid>
            <div className={styles.labelGridRow}>
              {message.icon === "noIcon" ? null : <Icon {...getIconProps()} />}
              <div>
                <span className={styles.labelText}>{message.labelText} </span>
                {message.messageText && renderMessageTextItems(message)}
              </div>
            </div>
          </Grid>
        </div>
      ))}
    </>
  ) : null;

  function getSystemMessageClass(messageLabel) {
    return classNames({
      [styles.labelWrap]: !!messageLabel,
      [styles.systemMessage]: true,
    });
  }

  function getIconProps() {
    return {
      background: {
        color: "system-message-alert-background",
        type: "circle",
      },
      className: styles.icon,
      color: "system-message-alert-icon",
      name: "Error",
      size: "size14",
      transparentBorder: false,
    };
  }

  function renderMessageTextItems(message) {
    return message.messageText.map((item, messageTextIndex) => {
      const messageTextContent = (
        <span className={styles.spanTextContent} key={`${item.value}-${messageTextIndex}`}>
          <HtmlValue htmlValue={item.value} />
        </span>
      );

      return item.url ? (
        <Link
          className={getMessageTextClass(messageTextIndex, message.messageText.length)}
          href={item.url}
          key={`${item.value}-${messageTextIndex}-link`}
        >
          {messageTextContent}
        </Link>
      ) : (
        messageTextContent
      );
    });
  }

  function getMessageTextClass(messageTextIndex, totalMessageItems) {
    return classNames({
      [styles.messageTextContent]: true,
      [styles.messageTextContentDivider]: messageTextIndex + 1 !== totalMessageItems,
    });
  }
};

SystemMessage.propTypes = {
  /** Content to be rendered. */
  messageList: PropTypes.arrayOf(PropTypes.object),
};
