import PropTypes from "prop-types";
import React from "react";

export const ThumbsUp = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path d="M25.58,14.5h-4.62c-.72,0-1.35,.4-1.35,1.12v.47c0,.72,.6,1.12,1.32,1.12h4.66c.72,0,1.32-.39,1.32-1.12v-.47c0-.72-.6-1.12-1.32-1.12h0Z" />
      <path d="M4.08,16.49c0-.66,.54-1.21,1.21-1.21h2.24c.66,0,1.21,.54,1.21,1.21v10.3c0,.66-.54,1.21-1.21,1.21h-2.24c-.66,0-1.21-.54-1.21-1.21v-10.3Z" />
      <path d="M20.3,6.37c-1.41-3.58-3.61-2.28-3.37-1.38,1.19,4.4-.36,7.76-5.5,10-.74,.32-2.08,.89-2.08,2.41v7.77c0,2.33,1.09,2.71,2.81,2.71h5.08c.45,0,.89-.1,1.28-.27-.22-.31-.34-.68-.34-1.08v-.47c0-.52,.21-1,.56-1.33,.27-.27,.27-.11,0-.38-.34-.34-.56-.81-.56-1.33v-.47c0-.52,.21-.99,.56-1.33,0-.02,.19-.17,.22-.19-.49-.36-.78-.91-.78-1.52v-.47c0-.52,.21-1,.56-1.34,.15-.15,.33-.28,.52-.37h0c-.78-.78-.78-2.05-.14-3.14,1.8-3.09,1.91-5.95,1.19-7.8h0Z" />
      <path d="M24.56,24.98h-4.14c-.72,0-1.32,.43-1.32,1.15v.47c0,.72,.6,1.15,1.32,1.15h4.14c.72,0,1.32-.42,1.32-1.15v-.47c0-.72-.59-1.15-1.32-1.15h0Z" />
      <path d="M25.41,21.47h-5c-.72,0-1.32,.43-1.32,1.15v.47c0,.72,.6,1.15,1.32,1.15h5c.72,0,1.32-.42,1.32-1.15v-.47c0-.72-.59-1.15-1.32-1.15h0Z" />
      <path d="M26.6,18.02h-6.19c-.72,0-1.32,.4-1.32,1.12v.47c0,.72,.6,1.12,1.32,1.12h6.19c.72,0,1.32-.39,1.32-1.12v-.47c0-.72-.59-1.12-1.32-1.12h0Z" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "0 0 32 32",
    };
  }
};

ThumbsUp.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

ThumbsUp.defaultProps = { role: "img" };
