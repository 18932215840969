import { setDataKeyPrefix, setRetrievalFunction } from "@swa-ui/bootstrap";
import { window } from "@swa-ui/browser";

setRetrievalFunction(window.require);
setDataKeyPrefix("swa-bootstrap-dotcom-landing-page/");

export const bootstrapDependencies = {
  optional: [],
  required: [{ id: "search-environments" }],
};
