import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { FetchOptionsContext, useFetch } from "@swa-ui/fetch";

export const useGet = (url, AuthContext = {}, getCondition = true, fetchOverrideOptions = {}) => {
  const fetchOptions = useContext(FetchOptionsContext);
  const { appendLogoutHandlers, authPending, isLoggedIn } = useContext(AuthContext) || {};
  const { cache, data, error, get, loading } = useFetch(url, {
    ...fetchOptions,
    ...fetchOverrideOptions,
  });
  const { pathname } = useLocation();
  const loggedIn = isLoggedIn && isLoggedIn();
  const [dataToRender, setDataToRender] = useState(data);

  useEffect(() => {
    setDataToRender(data);
  }, [data]);

  useEffect(() => {
    getCondition && !authPending && !loading && get();
    appendLogoutHandlers && appendLogoutHandlers(cache.clear, url);
  }, [authPending, loggedIn, pathname, getCondition]);

  useEffect(() => {
    error && cache.clear();
  }, [error]);

  function refresh() {
    cache.clear().then(() => getCondition && get());
  }

  function resetData() {
    setDataToRender(null);
  }

  return {
    cache,
    data: loggedIn ? dataToRender : null,
    dataPending: loading || (!dataToRender && !error),
    error,
    get,
    loading,
    refresh,
    resetData,
  };
};
