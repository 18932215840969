"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useBookingSectionAnalytics = useBookingSectionAnalytics;
var _react = require("react");
var _reactRouterDom = require("react-router-dom");
var _application = require("@swa-ui/application");
var _browser = require("@swa-ui/browser");
function useBookingSectionAnalytics(placementInfo) {
  var _ref = placementInfo !== null && placementInfo !== void 0 ? placementInfo : {},
    _ref$items = _ref.items,
    items = _ref$items === void 0 ? [] : _ref$items;
  var _useLocation = (0, _reactRouterDom.useLocation)(),
    pathname = _useLocation.pathname;
  var _useScreenSize = (0, _browser.useScreenSize)(),
    screenSize = _useScreenSize.screenSize;
  var _useContext = (0, _react.useContext)(_application.MktgDataContext),
    setMktgPageData = _useContext.setMktgPageData;
  (0, _react.useEffect)(function () {
    if ((pathname === "/" || pathname === "/landing/home-page/") && items.length) {
      setMktgPageData({
        widget_position: getBookingWidgetPosition()
      }, "landingHomePage");
    }
  }, [pathname, items]);
  function getBookingWidgetPosition() {
    var _items$;
    var placementId = (_items$ = items[0]) === null || _items$ === void 0 ? void 0 : _items$.id;
    var bookingWidgetPosition = placementId === "bookingWidget" ? "left" : "right";
    if (screenSize === "small") {
      bookingWidgetPosition = placementId === "bookingWidget" ? "top" : "bottom";
    }
    return bookingWidgetPosition;
  }
}