import PropTypes from "prop-types";
import React from "react";

export const Home = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path
        d="M44,15.3l-7.5-5.4V1.1H31v4.8l-7.7-5.6c-0.6-0.4-1.4-0.4-2,0l-20.6,15c-0.8,0.5-0.9,1.6-0.4,2.4
		c0.3,0.5,0.8,0.7,1.4,0.7c0.3,0,0.7-0.1,1-0.3L22.3,3.8L42,18c0.8,0.5,1.8,0.4,2.4-0.4C44.9,16.9,44.7,15.8,44,15.3"
      />
      <path
        d="M28.3,22.8h-4.8V18h4.8V22.8z M28.3,30h-4.8v-4.8h4.8V30z M21.1,22.8h-4.8V18h4.8V22.8z M21.1,30h-4.8v-4.8
		h4.8V30z M22.3,6.3l-15.2,11v21.1h30.4V17.3L22.3,6.3z"
      />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "0 -1 42 41",
    };
  }
};

Home.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Home.defaultProps = {
  role: "img",
};
