import React, { useContext } from "react";

import { ApplicationContext, SuppressForNative } from "@swa-ui/application";
import { useDeviceInfo } from "@swa-ui/browser";
import { DesktopHeaderControl, DotcomHeader, MobileHeaderControl } from "@swa-ui/dotcom";

import styles from "./LandingPageHeader.module.scss";

/**
 * LandingPageHeader will render the header for static html pages.
 */

export const LandingPageHeader = (props) => {
  const { light, menuItemSeparator, uppercaseMenuItems } = props;
  const { persona, site } = useContext(ApplicationContext);
  const { isLargeOrXlarge } = useDeviceInfo();

  return (
    <header className={styles.header}>
      <SuppressForNative>
        <DotcomHeader {...getHeaderProps()} />
      </SuppressForNative>
    </header>
  );

  function getHeaderProps() {
    const type = getNavigationType();
    const control =
      type === "desktop" ? (
        <DesktopHeaderControl light={light} />
      ) : (
        <MobileHeaderControl site={site} />
      );

    return {
      appHeader: null,
      control,
      light,
      menuItemSeparator,
      pageTitle: "",
      persona,
      type,
      uppercaseMenuItems,
    };
  }

  function getNavigationType() {
    return isLargeOrXlarge ? "desktop" : "mobile";
  }
};
