const stringP = `['"].*['"]`;
const variableP = "[A-Za-z0-9_]+";
const operationP = `\\s*(${stringP}|${variableP})\\s*[+]\\s*(${stringP}|${variableP})\\s*`;
const statementP = `${variableP}|${stringP}|${operationP}`;
const conditionalP = `\\s*(${variableP})\\s*\\?\\s*(${statementP})\\s*:\\s*(${statementP})+\\s*`;
const outsideQuotesLookahead = "(?=(([^']*'[^']*')*[^']*)$)";

const interoperableRe = new RegExp(
  `.*(\\$\\{\\s*(${stringP}|${variableP}|${operationP}|${conditionalP}\\s*)+\\}).*`
);
const conditionalRe = new RegExp(conditionalP);
const operationRe = new RegExp(operationP);
const splitConditionalRe = new RegExp(`\\?${outsideQuotesLookahead}`);
const splitOperationRe = new RegExp(`\\+${outsideQuotesLookahead}`);
const splitStatementsRe = new RegExp(`:${outsideQuotesLookahead}`);
const stringRe = new RegExp(stringP);

const stripOuterWhitespace = (token) => token.replace(/^(\s*)/, "").replace(/(\s*)$/, "");
const stripTokenDelimiter = (token) => token.replace(/^\$\{/, "").replace(/\}$/, "");
const stripWhitespace = (token) => token.replace(/\s*/g, "");

const resolveToken = (token, data) => {
  const stringToken = stringRe.test(token);

  return stringToken
    ? stripOuterWhitespace(token).replace(/["']/g, "")
    : data[stripWhitespace(token)];
};

const resolveOperation = (token, data) => {
  const [a, b] = token.split(splitOperationRe);

  return resolveToken(a, data) + resolveToken(b, data);
};

const resolveData = (token, data = {}) => {
  const tokenIsOperation = operationRe.test(token);

  return tokenIsOperation ? resolveOperation(token, data) : resolveToken(token, data);
};

const evaluateConditional = (token, data) => {
  const [expression, statements] = token.split(splitConditionalRe);
  const [statement1, statement2] = statements.split(splitStatementsRe);
  const statement = data[stripWhitespace(expression)]
    ? stripOuterWhitespace(statement1)
    : stripOuterWhitespace(statement2);

  return resolveData(statement, data);
};

const interpolateToken = (token, data) => {
  const strippedToken = stripTokenDelimiter(token);
  const tokenIsConditional = conditionalRe.test(strippedToken);

  return tokenIsConditional
    ? evaluateConditional(strippedToken, data)
    : resolveData(strippedToken, data);
};

const interpolate = (template, data) => {
  const interoperable = interoperableRe.test(template);
  let result = template;

  if (interoperable) {
    const tokens = template.split(/(\$\{.*?\})/);

    result = tokens.reduce((acc, token = "") => {
      const tokenInteroperable = interoperableRe.test(token);

      return tokenInteroperable ? acc + interpolateToken(token, data) : acc + token;
    }, "");
  }

  return result;
};

export default interpolate;
