import PropTypes from "prop-types";
import React from "react";

import { swaDate } from "@swa-ui/date";
import { classNames } from "@swa-ui/string";

import { ItineraryDate } from "../ItineraryDate";
import { ItinerarySegment, itinerarySegmentPropTypes } from "../ItinerarySegment";
import styles from "./ItineraryDateRange.module.scss";

/**
 * ItineraryDateRange renders a date within a span element something like this: Jan 10 - 22.
 */

export const ItineraryDateRange = (props) => {
  const {
    className,
    endDate,
    endDateFormat,
    startDate,
    startDateFormat,
    styleSetting = {},
  } = props;
  const departDateFormat = startDateFormat ?? "MMM DD";
  const returnDateFormat = endDateFormat ?? getFormattedReturnDate();

  return (
    <span className={classNames(className, styles.layout)}>
      <ItineraryDate {...getDateProps(startDate, departDateFormat)} />
      {endDate ? (
        <>
          <ItinerarySegment {...styleSetting}>-</ItinerarySegment>
          <ItineraryDate {...getDateProps(endDate, returnDateFormat)} />
        </>
      ) : null}
    </span>
  );

  function getDateProps(date, format) {
    return {
      date,
      format,
      styleSetting,
    };
  }

  function getFormattedReturnDate() {
    return swaDate(startDate).isSame(swaDate(endDate), "month") ? "DD" : "MMM DD";
  }
};

ItineraryDateRange.propTypes = {
  /** Additional classes for positioning the component. */
  className: PropTypes.string,

  /** Used to render the end date. */
  endDate: PropTypes.string,

  /** Used to render end date format. */
  endDateFormat: PropTypes.string,

  /** Used to render the start date. */
  startDate: PropTypes.string.isRequired,

  /** Used to render start date format. */
  startDateFormat: PropTypes.string,

  /** Options to use to style rendered date. */
  styleSetting: PropTypes.shape(itinerarySegmentPropTypes),
};
