import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import { window } from "@swa-ui/browser";
import { useFetch } from "@swa-ui/fetch";
import { logger } from "@swa-ui/log";

import { HtmlValue } from "../HtmlValue";

/**
 * ContentFragment pulls back fragment file content from Netstorage.
 */

export const ContentFragment = (props) => {
  const { href } = props;
  const { fullPath, origin } = getURL();
  const { get } = useFetch(origin);
  const [content, setContent] = useState();
  const loadedHref = useRef();

  useEffect(() => {
    if ((content && loadedHref.current !== href) || !href) {
      setContent("");
    }

    get(fullPath)
      .then(async (response) => {
        const body = typeof response === "string" ? response : response?.data;

        if (body) {
          loadedHref.current = href;
          setContent(body);
        } else {
          logger.error("Unable to retrieve fragment.", {
            status: response.status,
            statusText: response.statusText,
          });
        }
      })
      .catch((error) => {
        logger.error("Unable to retrieve fragment.", { error });
      });
  }, [href]);

  return content ? <HtmlValue htmlValue={content} /> : null;

  function getURL() {
    const isRelativePath = href.startsWith("/");
    const url = new URL(href, isRelativePath ? window.location.origin : href);

    return { fullPath: url.href.replace(url.origin, ""), origin: url.origin };
  }
};

ContentFragment.propTypes = {
  /**
   * Path containing part of HTML file to fetch from an external source.
   */
  href: PropTypes.string.isRequired,
};
