import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { window } from "@swa-ui/browser";
import { Dialog } from "@swa-ui/core";
import { isResponseOk, useFetch } from "@swa-ui/fetch";
import i18n from "@swa-ui/locale";

import { MessageContext } from "../MessageProvider";
import { VersionInformation } from "./VersionInformation";

const MILLISECONDS_IN_A_MINUTE = 1000 * 60;
const STALE_DURATION_IN_MINUTES = 1;
const STALE_DURATION_IN_MILLISECONDS = STALE_DURATION_IN_MINUTES * MILLISECONDS_IN_A_MINUTE;

export const Version = (props) => {
  const { basePath } = props;
  const { displayMessage } = useContext(MessageContext);
  const { get, response } = useFetch();
  const { pathname } = useLocation();
  const [showVersionOutOfDate, setShowVersionOutOfDate] = useState(false);
  const [versionInformation, setVersionInformation] = useState({
    clientVersion: process.env.VERSION,
  });

  useEffect(() => {
    const { lastUpdateTime } = versionInformation;

    if (!lastUpdateTime || lastUpdateTime < new Date().getTime() - STALE_DURATION_IN_MILLISECONDS) {
      retrieveVersion();
    }
  }, [pathname]);

  useEffect(() => {
    const { clientVersion, version: serverVersion } = versionInformation;

    if (
      clientVersion &&
      serverVersion &&
      getMajorVersion(clientVersion) !== getMajorVersion(serverVersion)
    ) {
      setShowVersionOutOfDate(true);
    }
  }, [versionInformation]);

  useEffect(() => {
    // Removing sonar for this line due to sonar defect, addressed in newer sonar versions.
    window.addEventListener("keyup", displayVersionInformationOnShiftF9); // NOSONAR

    return () => {
      window.removeEventListener("keyup", displayVersionInformationOnShiftF9);
    };
  }, [versionInformation]);

  return showVersionOutOfDate ? (
    <Dialog {...getDialogProps()}>
      <div>{i18n("Version__TEXT")}</div>
    </Dialog>
  ) : null;

  function getDialogProps() {
    return {
      buttonGroupProps: {
        buttonList: [
          {
            label: i18n("Version__BUTTON"),
            props: {
              styleType: "danger",
            },
          },
        ],
      },
      heading: i18n("Version__TITLE"),
      onClick: handleAppReload,
      revealed: showVersionOutOfDate,
    };
  }

  function handleAppReload() {
    window.localStorage.clear();
    window.sessionStorage.clear();

    return (window.location.href = `${basePath}/?version=${versionInformation.version}`);
  }

  function displayVersionInformationOnShiftF9(event) {
    if (event.keyCode === 120 && event.shiftKey) {
      displayMessage("information", <VersionInformation versionInformation={versionInformation} />);
    }
  }

  async function retrieveVersion() {
    const body = await get(`${basePath}/version.json`);

    if (isResponseOk(response) && body) {
      updateVersion(body);
    }
  }

  function updateVersion(newVersion) {
    setVersionInformation(
      addLastUpdatedTime({
        ...versionInformation,
        ...newVersion,
      })
    );
  }

  function addLastUpdatedTime(state) {
    return { ...state, lastUpdateTime: new Date().getTime() };
  }

  function getMajorVersion(version) {
    return version.split(".")[0];
  }
};
