import * as yup from "yup";

import i18n from "@swa-ui/locale";

export const PHONE_TYPE_SCHEMA_FIELD_NAMES = {
  PHONE_TYPE: "phoneType",
};

export const getPhoneTypeFormFieldSchema = () =>
  yup
    .string()
    .required(i18n("PhoneTypeFormField__REQUIRED_ERROR"))
    .label(i18n("PhoneTypeFormField__LABEL").toLowerCase());
