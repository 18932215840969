import PropTypes from "prop-types";
import React, { createContext, useContext, useEffect, useState } from "react";

import { AuthContext } from "@swa-ui/authentication";
import { useDeviceInfo } from "@swa-ui/browser";
import {
  BackgroundVeil,
  Button,
  Dialog,
  Grid,
  Heading,
  Icon,
  Link,
  SlideIntoView,
  useDialog,
} from "@swa-ui/core";
import { VacationsLightTheme } from "@swa-ui/design-tokens";
import i18n from "@swa-ui/locale";
import { PersonasContext } from "@swa-ui/personas";
import { PlacementFactory, usePlacement } from "@swa-ui/placements";
import { classNames } from "@swa-ui/string";

import { Copyright } from "./Copyright";
import styles from "./DotcomHeaderMobile.module.scss";
import { OverlayMenus } from "./OverlayMenus";

const MENU_CLOSED = "closed";
const MENU_OPEN = "open";
const MENU_SELECTION_NONE = "";
const MENU_SLIDE_CLOSED = "slide";

/**
 * DotcomHeaderMobile provides the header for smaller devices / screens.
 */
export const DotcomHeaderMobileContext = createContext();
export const DotcomHeaderMobile = (props) => {
  const { control, headerControlPortalId, pageTitle } = props;
  const { placement: navBottom } = usePlacement("navBottom");
  const { placement: navLogo } = usePlacement("navLogo");
  const { placement: navTop } = usePlacement("navTop");
  const { handleLogout, isLoggedIn } = useContext(AuthContext);
  const [menuRevealed, setMenuRevealed] = useState(MENU_CLOSED);
  const [menuSelection, setMenuSelection] = useState("");
  const [displayedPlacementId, setDisplayedPlacementId] = useState("");
  const { hideDialog, isDialogVisible, showDialog } = useDialog("center", "hidden");
  const { isSecureWebView } = useDeviceInfo();
  const { isVacationsPersona } = useContext(PersonasContext);

  useEffect(() => {
    setMenuSelection(getActiveMenu());
  }, [navTop]);

  useEffect(() => {
    if (displayedPlacementId) {
      showDialog();
    } else {
      hideDialog();
    }
  }, [displayedPlacementId]);

  return (
    <DotcomHeaderMobileContext.Provider
      value={{ closeHeaderMenu: handleCloseMenu, displayPlacement }}
    >
      <BackgroundVeil {...getVeilProps()} />
      <Dialog {...getDialogProps()}>
        <PlacementFactory id={displayedPlacementId} />
      </Dialog>
      <Grid className={classNames(styles.logoLayout, styles.logoSection)}>
        <div
          className={classNames(styles.logoContainer, styles.container, {
            [styles.centeredLogo]: isSecureWebView,
          })}
        >
          {renderLogoSection()}
        </div>
      </Grid>
      <Grid className={classNames(styles.titleLayout, styles.titleSection)}>
        <div className={classNames(styles.title)}>
          {renderOptionsSection()}
          <SlideIntoView {...getSlideProps()}>{renderOverlay()}</SlideIntoView>
        </div>
      </Grid>
    </DotcomHeaderMobileContext.Provider>
  );

  function renderLogoSection() {
    return (
      <>
        {!isSecureWebView && (
          <span className={styles.menuContainer}>
            <Button {...getMenuProps()}>
              <Icon color="white" name="Menu" />
            </Button>
          </span>
        )}
        {renderLogo()}
        {!isSecureWebView && <span className={styles.controlContainer}>{control}</span>}
      </>
    );
  }

  function renderOptionsSection() {
    return (
      !!pageTitle && (
        <div className={styles.container}>
          <Heading light styleLevel={4}>
            {pageTitle}
          </Heading>
        </div>
      )
    );
  }

  function renderOverlay() {
    const navBottomContentMenus = navBottom?.placement?.navDrawer?.navDrawerContent;
    const navTopContentMenus = navTop?.placement?.navDrawer?.navDrawerContent;
    const ThemeWrapper = isVacationsPersona ? VacationsLightTheme : React.Fragment;

    return menuRevealed ? (
      <ThemeWrapper>
        <div className={styles.overlay}>
          <div id={headerControlPortalId} />
          {navTopContentMenus && (
            <OverlayMenus
              closeMenu={handleCloseMenu}
              menus={navTopContentMenus}
              onChangeRevealedMenu={handleChangeRevealedMenu}
              revealedTitle={menuSelection}
            />
          )}
          <div className={styles.placements}>
            <PlacementFactory id="navPromo1" />
          </div>
          {navBottomContentMenus && <OverlayMenus menus={navBottomContentMenus} />}
          {isLoggedIn() && (
            <Link className={styles.logout} emphasis={false} onClick={handleLogoutClick}>
              {i18n("DotcomHeaderMobile__LOGOUT")}
            </Link>
          )}
          <Copyright />
        </div>
      </ThemeWrapper>
    ) : null;
  }

  function getMenuProps() {
    return {
      "aria-label": i18n("DotcomHeaderMobile__MENU_ARIA"),
      className: styles.menu,
      onClick: handleMenuClick,
      styleType: "no-style",
    };
  }

  function getDialogProps() {
    return {
      onClose: handleClose,
      revealed: isDialogVisible,
      showClose: true,
    };
  }

  function handleClose() {
    setDisplayedPlacementId("");
  }

  function renderLogo() {
    return (
      <Button {...getLogoButtonProps()}>
        <img {...getLogoImageProps()} />
      </Button>
    );
  }

  function getLogoButtonProps() {
    const { altText, href, url } = navLogo?.placement || {};

    return {
      "aria-label": altText,
      className: styles.logo,
      href: isSecureWebView ? null : href ?? url ?? "/",
      styleType: "no-style",
    };
  }

  function getLogoImageProps() {
    const { altText: alt, height, src, width } = navLogo?.placement || {};

    return {
      alt,
      height,
      role: "img",
      src,
      width,
    };
  }

  function getVeilProps() {
    return {
      onClick: handleVeilClick,
      on: menuRevealed === MENU_OPEN,
    };
  }

  function getSlideProps() {
    return {
      className: styles.slider,
      onTransformationEnd: menuRevealed === MENU_SLIDE_CLOSED ? handleMenuSlideComplete : undefined,
      slideDirection:
        menuRevealed === MENU_CLOSED
          ? "hidden"
          : menuRevealed === MENU_OPEN
          ? "slideRight"
          : "hideLeft",
    };
  }

  function handleMenuClick() {
    setMenuRevealed(menuRevealed === MENU_CLOSED ? MENU_OPEN : MENU_SLIDE_CLOSED);
  }

  function handleVeilClick() {
    handleCloseMenu();
  }

  function handleCloseMenu() {
    setMenuRevealed(MENU_SLIDE_CLOSED);
  }

  function displayPlacement(placementId) {
    handleCloseMenu();
    setDisplayedPlacementId(placementId);
  }

  function handleMenuSlideComplete() {
    setMenuRevealed(MENU_CLOSED);
  }

  function handleChangeRevealedMenu(selectedTitle) {
    setMenuSelection(menuSelection === selectedTitle ? MENU_SELECTION_NONE : selectedTitle);
  }

  async function handleLogoutClick() {
    await handleLogout();
    handleCloseMenu();
  }

  function getActiveMenu() {
    const globalNavContent = navTop?.placement?.navDrawer?.navDrawerContent;
    let selectedMenu = MENU_SELECTION_NONE;

    if (globalNavContent) {
      globalNavContent.forEach((menu) => {
        const children = menu.content ?? menu.children;

        children.forEach((menuContent) => {
          if (menuContent.isAutoExpanded) {
            selectedMenu = menuContent.title;
          }
        });
      });
    }

    return selectedMenu;
  }
};

DotcomHeaderMobileContext.displayName = "DotcomHeaderMobileContext";
DotcomHeaderMobile.propTypes = {
  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * Header control component used to control items like login and locale switching
   */
  control: PropTypes.node,

  /* Element ID to used to display customer information that isn't located directly in the header */
  headerControlPortalId: PropTypes.string,

  /** Page's title */
  pageTitle: PropTypes.string,
};
