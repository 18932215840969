import * as yup from "yup";

import i18n from "@swa-ui/locale";

import { childAgeList } from "../defines/childAgeList";

export const childAgeSchema = () =>
  yup.object().shape({
    travelerCounters: yup.array().of(
      yup.object().shape({
        childAge: yup
          .array()
          .transform((value) => value.filter(Boolean))
          .of(getChildAgeSchema()),
      })
    ),
  });

function getChildAgeSchema() {
  return yup.string().required().oneOf(childAgeList).label(i18n("schema__CHILD_AGE").toLowerCase());
}
