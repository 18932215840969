import * as yup from "yup";

export const setYupLocale = (i18n) => {
  yup.setLocale({
    mixed: {
      default: i18n("setYupLocale__FORM_FIELD_NOT_VALID"),
      defined: i18n("setYupLocale__FORM_FIELD_NOT_VALID"),
      matches: i18n("setYupLocale__FORM_FIELD_NOT_VALID"),
      notOneOf: i18n("setYupLocale__FORM_FIELD_NOT_VALID"),
      notType: i18n("setYupLocale__FORM_FIELD_NOT_VALID"),
      oneOf: i18n("setYupLocale__FORM_FIELD_NOT_VALID"),
      required: i18n("setYupLocale__FORM_FIELD_REQUIRED"),
    },
    number: {
      default: i18n("setYupLocale__FORM_FIELD_NOT_VALID"),
      integer: i18n("setYupLocale__FORM_FIELD_NOT_VALID"),
      lessThan: i18n("setYupLocale__FORM_FIELD_NOT_VALID"),
      max: i18n("setYupLocale__FORM_FIELD_NOT_VALID"),
      min: i18n("setYupLocale__FORM_FIELD_NOT_VALID"),
      moreThan: i18n("setYupLocale__FORM_FIELD_NOT_VALID"),
      negative: i18n("setYupLocale__FORM_FIELD_NOT_VALID"),
      positive: i18n("setYupLocale__FORM_FIELD_NOT_VALID"),
      required: i18n("setYupLocale__FORM_FIELD_REQUIRED"),
    },
    string: {
      email: i18n("setYupLocale__FORM_FIELD_NOT_VALID"),
      length: i18n("setYupLocale__FORM_FIELD_NOT_VALID"),
      lowercase: i18n("setYupLocale__FORM_FIELD_NOT_VALID"),
      matches: i18n("setYupLocale__FORM_FIELD_NOT_VALID"),
      max: i18n("setYupLocale__FORM_FIELD_NOT_VALID"),
      min: i18n("setYupLocale__FORM_FIELD_NOT_VALID"),
      required: i18n("setYupLocale__FORM_FIELD_REQUIRED"),
      trim: i18n("setYupLocale__FORM_FIELD_NOT_VALID"),
      uppercase: i18n("setYupLocale__FORM_FIELD_NOT_VALID"),
      url: i18n("setYupLocale__FORM_FIELD_NOT_VALID"),
      uuid: i18n("setYupLocale__FORM_FIELD_NOT_VALID"),
    },
  });
};
