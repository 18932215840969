import { useContext } from "react";

import { ApplicationContext } from "../ApplicationContextProvider";

export const SuppressForChannels = (props) => {
  const { channels, children } = props;
  const { channelId } = useContext(ApplicationContext);

  return channels.includes(channelId) ? null : children;
};
