import { withFormField } from "@swa-ui/form";

import { StateAndCountryAutocomplete } from "../StateAndCountryAutocomplete";

/**
 * CountryFormField provides support for a StateAndCountryAutocomplete component to be used in a Form.
 */

export const CountryFormField = withFormField(StateAndCountryAutocomplete, {
  useController: true,
});
