import PropTypes from "prop-types";
import React from "react";

import i18n from "@swa-ui/locale";
import { classNames } from "@swa-ui/string";

import styles from "./FlightStops.module.scss";

/**
 * FlightStops displays the number of stops for a given flight.
 */

export const FlightStops = (props) => {
  const { className, disabled, stops } = props;

  return (
    <div aria-label={getAriaLabel()} className={getClass()}>
      {renderBadgeText()}
    </div>
  );

  function getAriaLabel() {
    return i18n("FlightStops__FLIGHT_STOPS_ARIA", {
      STOPS: stops > 3 ? "3+" : stops,
    });
  }

  function renderBadgeText() {
    return i18n("FlightStops__BADGE_FLIGHT_STOPS", {
      STOPS: stops > 3 ? "3+" : stops,
    });
  }

  function getClass() {
    return classNames(className, styles.flightStops, {
      [styles.disabled]: disabled,
      [styles.notNonstop]: stops !== 0,
    });
  }
};

FlightStops.propTypes = {
  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Indicator to define if badge is disabled. */
  disabled: PropTypes.bool,

  /** Value for the number of stops in the flight. */
  stops: PropTypes.number.isRequired,
};
