import PropTypes from "prop-types";
import React from "react";

export const Bus = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path d="M3.8312 12.6667C3.3214 12.6667 2.88873 12.4885 2.53317 12.132C2.17762 11.7755 1.99984 11.3426 1.99984 10.8334H0.666504V4.50004C0.666504 4.17921 0.780743 3.90455 1.00922 3.67607C1.23768 3.44761 1.51233 3.33337 1.83317 3.33337H11.4332L15.3332 7.23337V10.8334H13.9998C13.9998 11.3426 13.8214 11.7755 13.4645 12.132C13.1077 12.4885 12.6743 12.6667 12.1645 12.6667C11.6547 12.6667 11.2221 12.4885 10.8665 12.132C10.5109 11.7755 10.3332 11.3426 10.3332 10.8334H5.6665C5.6665 11.3445 5.48807 11.7778 5.1312 12.1334C4.77435 12.4889 4.34102 12.6667 3.8312 12.6667ZM9.99984 6.66671H13.2332L10.8998 4.33337H9.99984V6.66671ZM5.83317 6.66671H8.99984V4.33337H5.83317V6.66671ZM1.6665 6.66671H4.83317V4.33337H1.6665V6.66671ZM3.83082 11.8334C4.11017 11.8334 4.34706 11.7369 4.5415 11.5441C4.73595 11.3512 4.83317 11.1151 4.83317 10.8357C4.83317 10.5564 4.73673 10.3195 4.54385 10.125C4.35098 9.9306 4.11487 9.83337 3.83552 9.83337C3.55618 9.83337 3.31928 9.92981 3.12484 10.1227C2.93039 10.3156 2.83317 10.5517 2.83317 10.831C2.83317 11.1104 2.92961 11.3473 3.12249 11.5417C3.31537 11.7362 3.55148 11.8334 3.83082 11.8334ZM12.1642 11.8334C12.4435 11.8334 12.6804 11.7369 12.8748 11.5441C13.0693 11.3512 13.1665 11.1151 13.1665 10.8357C13.1665 10.5564 13.0701 10.3195 12.8772 10.125C12.6843 9.9306 12.4482 9.83337 12.1689 9.83337C11.8895 9.83337 11.6526 9.92981 11.4582 10.1227C11.2637 10.3156 11.1665 10.5517 11.1665 10.831C11.1665 11.1104 11.2629 11.3473 11.4558 11.5417C11.6487 11.7362 11.8848 11.8334 12.1642 11.8334ZM1.6665 9.83337H2.28317C2.47206 9.54448 2.70817 9.33337 2.9915 9.20004C3.27484 9.06671 3.55539 9.00004 3.83317 9.00004C4.11095 9.00004 4.3915 9.06671 4.67484 9.20004C4.95817 9.33337 5.19428 9.54448 5.38317 9.83337H10.6165C10.8054 9.54448 11.0415 9.33337 11.3248 9.20004C11.6082 9.06671 11.8887 9.00004 12.1665 9.00004C12.4443 9.00004 12.7248 9.06671 13.0082 9.20004C13.2915 9.33337 13.5276 9.54448 13.7165 9.83337H14.3332V7.66671H1.6665V9.83337Z" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "0 -1 16 16",
    };
  }
};

Bus.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Bus.defaultProps = { role: "img" };
