import PropTypes from "prop-types";
import React from "react";

export const Time = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path d="M16,25.47A9.47,9.47,0,1,1,25.48,16,9.48,9.48,0,0,1,16,25.47M16,4A12,12,0,1,0,28,16,12,12,0,0,0,16,4" />
      <polygon points="21.68 15.51 21.67 15.51 17.38 15.51 17.38 9.2 14.59 9.2 14.59 18.31 21.67 18.31 21.68 15.51" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "4 4 24 24",
    };
  }
};

Time.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Time.defaultProps = {
  role: "img",
};
