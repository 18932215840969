import applicationKeys from "@swa-ui/application/i18nKeys";
import authenticationKeys from "@swa-ui/authentication/i18nKeys";
import { getBootstrapData } from "@swa-ui/bootstrap";
import dotcomKeys from "@swa-ui/dotcom/i18nKeys";
import formKeys from "@swa-ui/form/i18nKeys";
import itineraryKeys from "@swa-ui/itinerary/i18nKeys";
import { setI18nRoot, setLang } from "@swa-ui/locale";
import placementsKeys from "@swa-ui/placements/i18nKeys";
import vacationsKeys from "@swa-ui/vacations/i18nKeys";

const i18nOverrides = getBootstrapData("swa-bootstrap-i18n/i18n-override");

setLang("global");
setI18nRoot({
  global: {
    ...applicationKeys,
    ...authenticationKeys,
    ...dotcomKeys,
    ...formKeys,
    ...itineraryKeys,
    ...placementsKeys,
    ...vacationsKeys,
    ...i18nOverrides?.global,
  },
});
