import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import { useDeviceInfo } from "@swa-ui/browser";
import { classNames } from "@swa-ui/string";

import { Area } from "../Area";
import { ButtonGroup } from "../ButtonGroup";
import { keyCodes } from "../defines/keyCodes";
import { Drawer } from "../Drawer";
import { NumberSelectorGroup, numberSelectorGroupPropTypes } from "../NumberSelectorGroup";
import styles from "./PassengerSelector.module.scss";

const Y_OFFSET = -12;

/**
 * PassengerSelector renders a modal type flyout with an Input component to provide a way for a
 * traveler to specific passenger count and passenger types.
 */
export const PassengerSelector = (props) => {
  const { footer, footerRevealed, groups, maxCount, name, onCancel, onChange, onUpdate } = props;
  const { screenSize } = useDeviceInfo();
  const [currentGroups, setCurrentGroups] = useState();
  const [open, setOpen] = useState(false);
  const [totalCount, setTotalCount] = useState(getInitalTotalCount());
  const initialValues = useRef(getValues(groups));

  useEffect(() => {
    setCurrentGroups(cloneGroups(groups));
  }, [groups]);

  return currentGroups ? (
    <div role="group">
      <Drawer {...getProps()}>
        <div className={styles.passengerSelector} onKeyDown={handleKeydown}>
          <div className={styles.contentContainer}>{open && renderGroups()}</div>
          {footer && renderFooter()}
          <ButtonGroup {...getButtonGroupProps()} />
        </div>
      </Drawer>
    </div>
  ) : null;

  function renderGroups() {
    return currentGroups.map((group, index) => {
      const { heading, items } = group;

      return (
        <div key={index} className={getGroupClass()}>
          {heading && renderHeading(heading)}
          <NumberSelectorGroup {...getNumberSelectorProps(items, index)} />
        </div>
      );
    });
  }

  function renderHeading(heading) {
    return <div className={styles.heading}>{heading}</div>;
  }

  function renderFooter() {
    return (
      <Area revealed={footerRevealed}>
        <div className={styles.footer}>{footer}</div>
      </Area>
    );
  }

  function getProps() {
    return {
      captionProps: { showPointer: false, yOffset: screenSize === "small" ? undefined : Y_OFFSET },
      closeOnClickOff: false,
      concealOnTriggerClick: false,
      fullScreen: screenSize === "small",
      onRevealChange: handleRevealChange,
      revealed: open,
      showPointer: true,
      styleType: "no-style",
      triggerComponent: "input",
      triggerProps: getInputProps(),
    };
  }

  function getInputProps() {
    return {
      "aria-label": props["aria-label"],
      name,
      onMouseDown: handleMouseDown,
      readOnly: true,
      role: "combobox",
      suffixIcon: { color: "link", name: "Passenger" },
      value: `${totalCount}`,
    };
  }

  function getNumberSelectorProps(items, index) {
    return {
      className: styles.numberSelectorProps,
      items,
      maxCount,
      onChange: handleChange.bind(this, index),
    };
  }

  // TODO - PHX-1707
  // TODO Finalize PassengerSelector including moving to new repo and removing English text
  function getButtonGroupProps() {
    return {
      buttonList: [
        {
          label: "Cancel",
          props: {
            onClick: handleCancel,
            styleType: "secondary",
          },
        },
        {
          label: "Apply",
          props: {
            onClick: handleApply,
            styleType: "tertiary",
          },
        },
      ],
      className: styles.buttonGroup,
      ensureVisible: false,
      equalSize: true,
      moreContentIndicator: false,
      spaceBetween: "large",
    };
  }

  function getGroupClass() {
    return classNames(styles.content, { [styles.small]: screenSize === "small" });
  }

  function handleRevealChange(isOpen) {
    setOpen(isOpen);
  }

  function handleMouseDown(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  function handleKeydown(event) {
    const { key } = event;

    if (key === keyCodes.KEY_ENTER) {
      applyValues();
    } else if (key === keyCodes.KEY_ESCAPE) {
      cancelValues();
    }
  }

  function handleChange(index, updatedValues) {
    const values = getValues(currentGroups);

    values[index] = updatedValues;
    setCurrentGroups(fillIn(updatedValues, index));
    setTotalCount(getTotalCount(values));
    onUpdate?.(values);
  }

  function handleCancel() {
    cancelValues();
  }

  function handleApply() {
    applyValues();
  }

  function cancelValues() {
    const restoredValues = initialValues.current;

    setCurrentGroups(fillIn(restoredValues));
    setTotalCount(getTotalCount(restoredValues));
    onCancel?.(restoredValues);
    setOpen(false);
  }

  function applyValues() {
    const values = getValues(currentGroups);

    initialValues.current = values;
    onChange?.(values);
    setOpen(false);
  }

  function cloneGroups(source) {
    return source.map((group) => {
      const { heading, items } = group;

      return {
        heading,
        items: items.map((item) => {
          const {
            adjunctContent,
            adjunctContentRevealed,
            key,
            maximumValue,
            title,
            titleCaption,
            value,
          } = item;

          return {
            adjunctContent,
            adjunctContentRevealed,
            key,
            maximumValue,
            title,
            titleCaption,
            value,
          };
        }),
      };
    });
  }

  function getValues(updatedGroups) {
    return updatedGroups.map((group) => group.items.map((item) => item.value));
  }

  function fillIn(updateValues, groupIndex) {
    return currentGroups.map((group, index) => {
      const { heading, items } = group;

      return {
        heading,
        items: items.map((item, itemIndex) => {
          const {
            adjunctContent,
            adjunctContentRevealed,
            key,
            maximumValue,
            title,
            titleCaption,
            value,
          } = item;

          return {
            adjunctContent,
            adjunctContentRevealed,
            key,
            maximumValue,
            title,
            titleCaption,
            value: index === groupIndex ? updateValues[itemIndex] : value,
          };
        }),
      };
    });
  }

  function getInitalTotalCount() {
    const allGroups = [].concat(...groups);

    return allGroups.reduce((partialSum, group) => {
      const { items } = group;

      return partialSum + items.reduce((itemPartialSum, item) => itemPartialSum + item.value, 0);
    }, 0);
  }

  function getTotalCount(values) {
    const allValues = [].concat(...values);

    return allValues.reduce((partialSum, value) => partialSum + value, 0);
  }
};

PassengerSelector.propTypes = {
  /** aria-label text to provide additional accessibility description of input element. */
  "aria-label": PropTypes.string,

  /** Optional footer to display at bottom of Caption, just above ButtonGroup. */
  footer: PropTypes.node,

  /**
   * Flay to indicate if footer should be revealed. When this prop changes, the reveal/conceal will
   * be animated.
   */
  footerRevealed: PropTypes.bool,

  /**
   * Each "section" of number selectors "groups". item are the NumberSelectors to be displayed with
   * an optional heading display above each group. See NumberSelectorGroup for more information
   * about items.
   *
   * TODO consider making heading a "real" heading
   */
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.node,
      items: numberSelectorGroupPropTypes.items,
    })
  ),

  /**
   * Maximum value that can be selected across all NumberSelector. If the total of values reaches
   * this maximum, then all increase buttons will be disabled.
   */
  maxCount: PropTypes.number,

  /** Name assigned to the select form control. */
  name: PropTypes.string,

  /**
   * Function that informs when the traveler has escaped/canceled from the flyout and opted to
   * not save any udpates made.
   */
  onCancel: PropTypes.func,

  /** Function to inform when the traveler has accepts the value changes. */
  onChange: PropTypes.func,

  /** Function to inform when updates to a passenger count is made. */
  onUpdate: PropTypes.func,
};
