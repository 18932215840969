// *********** Vision Validations ***********
// https://stash1-tools.swacorp.com/projects/UI/repos/swa-form/browse/src/validators/validation-settings.js

// *********** Leap Frog Validations ***********
// eslint-disable-next-line max-len
// https://stash1-tools.swacorp.com/projects/COM/repos/commerce-project/browse/web/src/main/java/com/swacorp/dotcom/loyalty/validator/FieldValidator.java#37,208

const alpha = "a-zA-Z";
const hashtag = "\\#";
const hyphen = "\\-";
const numeric = "\\d";
const period = "\\.";
const punct = "!\"#$%&'()*+,-./:;<=>?@[\\]^_`{|}~";
const space = " ";
const whiteSpace = "\\s";

export const regexes = {
  alpha: allowedPattern(alpha),
  alphaHashtagHyphenPeriodSpace: allowedPattern(`${alpha}${hashtag}${hyphen}${period}${space}`),
  alphaNumeric: allowedPattern(`${alpha}${numeric}`),
  alphaNumericHyphenPeriodSpace: allowedPattern(`${alpha}${numeric}${hyphen}${period}${space}`),
  alphaNumericPunct: allowedPattern(`${alpha}${numeric}${punct}`),
  alphaNumericPunctSpace: allowedPattern(`${alpha}${numeric}${punct}${space}`),
  alphaNumericPunctWhiteSpace: allowedPattern(`${alpha}${numeric}${punct}${whiteSpace}`),
  alphaNumericSpace: allowedPattern(`${alpha}${numeric}${space}`),
  alphaSpace: allowedPattern(`${alpha}${space}`),
  noBackslash: /^[^\\]+$/,
  noDoubleQuotes: /^[^"]+$/,
  numeric: allowedPattern(numeric),
  phone: /^\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$/,
  positive: /^[1-9]/,
  validDate: /^(0[1-9]|1[0-2])\/(0[1-9]|1[0-9]|2[0-9]|3[0-1])\/[0-9]{4}$/,
  // eslint-disable-next-line max-len
  validEmail:
    /^(([^<>()[\]\\.,;:\s@"|]+(\.[^<>()[\]\\.,;:\s@"|]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
};

function allowedPattern(allowed) {
  return new RegExp(`^[${allowed}]*$`);
}
