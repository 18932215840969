import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { classNames } from "@swa-ui/string";

import { DropDown, dropDownPropTypes } from "../DropDown";
import { Multiselect } from "../Multiselect";
import styles from "./Autocomplete.module.scss";

/**
 * Autocomplete facilitates selecting an value by filtering selectable values according to user entry
 * matches. Autocomplete can perform as a controlled or un-controlled component decided by an onInputChange being present.
 * If an onInputChange is not passed, the component will perform un-controlled.
 */

export const Autocomplete = React.forwardRef((props, ref) => {
  const {
    className,
    defaultValue,
    disabled,
    error,
    id,
    inputStyleType,
    lineWrap,
    list,
    maxItemsToDisplay,
    minimumCharsToMatchCheck,
    minimumListWidth,
    moreContentIndicator,
    multipleSelection,
    name,
    noMatchFoundText,
    onBlur,
    onChange,
    onInputChange,
    option,
    readOnly,
    renderToken,
    getSelectionValueText,
    required,
    showPointer,
    value,
    values,
  } = props;
  const [currentList, setCurrentList] = useState(list);
  const [currentValue, setCurrentValue] = useState(value || "");
  const [currentValues, setCurrentValues] = useState(values || []);

  useEffect(() => {
    setCurrentValue(value || "");
    setCurrentValues(values || []);
    setCurrentList(filterList(value));
  }, [list, value, values]);

  return multipleSelection ? <Multiselect {...getProps()} /> : <DropDown {...getProps()} />;

  function getProps() {
    const filteredList = isListAnArrayOfStrings()
      ? currentList
      : // eslint-disable-next-line no-unused-vars
        currentList.map(({ alias, ...rest }) => ({ ...rest }));

    return {
      allowSpaceBarToSelect: false,
      "aria-autocomplete": "list",
      "aria-describedby": props["aria-describedby"],
      "aria-required": required,
      autoComplete: "off",
      className: classNames(className),
      comboBox: true,
      defaultValue,
      disabled,
      error,
      getSelectionValueText: getSelectionValueText || getValueText,
      id,
      inputMode: "text",
      lineWrap,
      list: filteredList,
      maxItemsToDisplay,
      minimumListWidth,
      moreContentIndicator,
      name,
      onBlur: handleBlur,
      onChange: multipleSelection ? handleMultipleSelectionChange : handleChange,
      onInputChange: handleInputChange,
      option,
      readOnly,
      ref,
      renderToken,
      required,
      showArrow: false,
      showPointer,
      styleType: inputStyleType,
      value: multipleSelection ? currentValues : currentValue,
    };
  }

  function handleBlur(event) {
    let newValue = event.target.value;

    if (newValue && !onInputChange) {
      const capitalizedNewValue = newValue.toUpperCase();
      const filteredValue = isListAnArrayOfStrings()
        ? list.find((item) => item.toUpperCase() === capitalizedNewValue)
        : list.find(
            (item) =>
              (typeof item.label === "string" && item.label?.toUpperCase()) === capitalizedNewValue
          )?.value;

      if (filteredValue && isValuePresent(filteredValue)) {
        setCurrentValue(filteredValue);
        newValue = filteredValue;
      }
    }

    onBlur?.({ target: { value: newValue } });
  }

  function handleMultipleSelectionChange(event) {
    const { value: newValues } = event.target;

    if (!onInputChange) {
      setCurrentValues(newValues);
    }
    onChange?.(newValues);
  }

  function handleChange(event) {
    const newValue = event.target.value;

    if (!onInputChange) {
      setCurrentValue(newValue);
    }
    onChange?.(event);
  }

  function handleInputChange(event) {
    const newValue = event?.target?.value;

    if (onInputChange) {
      onInputChange(event);
    } else {
      setCurrentValue(newValue);
      setCurrentList(filterList(newValue));
    }
  }

  function getValueText() {
    return currentValue;
  }

  function filterList(newValue = "") {
    let filteredList = list;

    if (!multipleSelection && shouldControlFilter()) {
      const uppercaseNewValue = newValue.toUpperCase();

      if (isListAnArrayOfStrings()) {
        filteredList = list.filter((item) => item.toUpperCase().startsWith(uppercaseNewValue));
      } else if (isAllLabelsStrings()) {
        filteredList = list.filter(
          ({ alias = "", label, value: itemValue }) =>
            label.toUpperCase().startsWith(uppercaseNewValue) ||
            itemValue === uppercaseNewValue ||
            alias === uppercaseNewValue
        );
      }

      if (!filteredList?.length && newValue?.length >= minimumCharsToMatchCheck) {
        filteredList = [
          {
            disabled: true,
            label: <i className={styles.noMatch}>{noMatchFoundText}</i>,
            value: noMatchFoundText,
          },
        ];
      }
    }

    return filteredList ?? [];
  }

  function isValuePresent(newValue) {
    return isListAnArrayOfStrings()
      ? list.includes(newValue)
      : list.find((item) => item?.value === newValue);
  }

  function isAllLabelsStrings() {
    return list.every((item) => typeof item?.label === "string");
  }

  function isListAnArrayOfStrings() {
    return list && typeof list[0] === "string";
  }

  function shouldControlFilter() {
    return onInputChange === undefined;
  }
});

export const autocompletePropTypes = {
  /** aria-describedby id to element which provides additional accessibility description of input element. */
  "aria-describedby": PropTypes.string,

  /** Additional class for the component to provide a background color. */
  className: PropTypes.string,

  /**
   * Initial values to select in list. Each value should be separated by a pipe character. For example:
   * "option one|option three".
   */
  defaultValue: PropTypes.string,

  /** Indicates Input should apply disabled styling and ignore mouse and key events. */
  disabled: dropDownPropTypes.disabled,

  /**
   * Indicates Dropdown should apply error styling and apply aria-invalid attribute -- in essence, Input will
   * provide error styling.
   */
  error: dropDownPropTypes.error,

  /** Callback method to get content to display in Input field. */
  getSelectionValueText: PropTypes.func,

  /** ID to be added to input element. */
  id: dropDownPropTypes.id,

  /** Indicates the style of the input element. */
  inputStyleType: dropDownPropTypes.styleType,

  /** Option to tell DropDown how to handle long lines. */
  lineWrap: dropDownPropTypes.lineWrap,

  /** Options to display in multiselect list. */
  list: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(
      PropTypes.shape({
        alias: PropTypes.string,
        className: PropTypes.string,
        disabled: PropTypes.bool,
        label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        onMouseDown: PropTypes.func,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      })
    ),
  ]),

  /** Total number of items visible. If more items available than can be displayed, they can be scrolled into view. */
  maxItemsToDisplay: dropDownPropTypes.maxItemsToDisplay,

  /** Minimum characters to be entered before displaying no match found text. */
  minimumCharsToMatchCheck: PropTypes.number,

  /** Width of dropdown. See dropDownPropTypes for more info. */
  minimumListWidth: dropDownPropTypes.minimumListWidth,

  /** Denotes if a "faded edge" will be rendered to indicate more content is scrollable. */
  moreContentIndicator: PropTypes.bool,

  /** Allows Autocomplete to select more than a single item using Multiselect instead of DropDown. */
  multipleSelection: PropTypes.bool,

  /** Name to be assigned to Input. */
  name: PropTypes.string,

  /** Text to be displayed when a user has input a minimum of three characters and no matches are found */
  noMatchFoundText: PropTypes.string.isRequired,

  /** Optional event handler to learn when focus is lost from input element. */
  onBlur: PropTypes.func,

  /**
   * Optional callback to learn of selection changes. The values of the selected items will be returned as pipe-
   * separated strings. See defaultValue prop description for an example.
   */
  onChange: dropDownPropTypes.onChange,

  /** Optional callback to learn of changes to Input when readOnly is false. */
  onInputChange: dropDownPropTypes.onInputChange,

  /** Option button to appear on left side of Input. See Input for more details. */
  option: dropDownPropTypes.option,

  /** Indicates Input should be immutable and ignore mouse and key events. */
  readOnly: PropTypes.bool,

  /**
   * renderToken provides a way to force a render. Typically, DropDown will re-render when a value
   * changes, but providing a different value since last render will ensure re-render.
   */
  renderToken: PropTypes.string,

  /** Indicates Input should apply aria-required attribute. */
  required: PropTypes.bool,

  /** Indicates if the caret icon is displayed in results list. */
  showPointer: PropTypes.bool,

  /** The value that is given to the Input component and is used to search for list results. */
  value: PropTypes.string,

  /** values is an array of preselected values and is used when multipleSelection is true. */
  values: PropTypes.arrayOf(PropTypes.string),
};

Autocomplete.propTypes = autocompletePropTypes;

Autocomplete.defaultProps = {
  list: [],
  minimumCharsToMatchCheck: 3,
  minimumListWidth: "large",
  moreContentIndicator: true,
  multipleSelection: false,
  readOnly: false,
  showPointer: true,
};

Autocomplete.displayName = "Autocomplete";
