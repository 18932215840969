import PropTypes from "prop-types";
import React from "react";

import { classNames } from "@swa-ui/string";

import styles from "./Tile.module.scss";

/**
 * Tile is to be used within a Card to denote a discrete section using a gray background color.
 */

export const Tile = (props) => {
  const { backgroundColor, borderRadius, children, className, description, onClick } = props;

  return (
    <div {...getProps()}>
      {children}
      {description}
    </div>
  );

  function getProps() {
    return {
      className: classNames({
        [className]: className,
        [styles.borderRadius]: borderRadius,
        [styles.primary]: backgroundColor === "primary",
        [styles.tile]: true,
      }),
      onClick,
    };
  }
};

Tile.propTypes = {
  /** Options for the background color of the Tile. */
  backgroundColor: PropTypes.oneOf(["none", "primary"]),

  /** Determines if there will be a border radius around the Tile. */
  borderRadius: PropTypes.bool,

  /** Content that will be displayed when Tile is rendered. */
  children: PropTypes.node,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Optional JSX to be displayed to the right of the children. */
  description: PropTypes.node,

  /** Optional event handler for when Tile is clicked. */
  onClick: PropTypes.func,
};

Tile.defaultProps = {
  backgroundColor: "primary",
};
