import PropTypes from "prop-types";
import React from "react";

import { classNames } from "@swa-ui/string";

import { Icon } from "../Icon";
import { List } from "../List";
import styles from "./Rating.module.scss";

const MAX_ITEMS = 5;
const RATING_DISABLED_DESIGN_TOKEN = "cmp-core-color-rating-disabled";
const RATING_FG_DESIGN_TOKEN = "cmp-core-color-rating-fg";
const RATING_NONE_DESIGN_TOKEN = "cmp-core-color-rating-none";
const STATUS_EMPTY = "empty";
const STATUS_FULL = "full";
const STATUS_HALF = "half";

/**
 * Provide visual representation of rating such number of stars for a hotel.
 */

export const Rating = (props) => {
  const { className, color, disabled, iconNameFull, iconNameHalf, placeholders, rating } = props;

  return <List {...getProps()} />;

  function renderItems() {
    const icons = [];
    const maxItems = getMaxItems();
    let currentRating = roundHalf(rating);
    let index;
    let status;

    for (index = 1; index <= maxItems; index += 1) {
      if (currentRating >= 1) {
        currentRating -= 1;
        status = STATUS_FULL;
      } else if (currentRating === 0.5) {
        currentRating = 0;
        status = STATUS_HALF;
      } else {
        status = STATUS_EMPTY;
      }

      icons.push(<Icon {...getIconProps(status)} />);
    }

    return icons;
  }

  function getProps() {
    return {
      animateInitialRender: true,
      className: classNames(styles.rating, className),
      items: renderItems(),
    };
  }

  function getIconProps(status) {
    return {
      color:
        status === STATUS_EMPTY
          ? RATING_NONE_DESIGN_TOKEN
          : disabled
          ? RATING_DISABLED_DESIGN_TOKEN
          : color,
      name: status === STATUS_FULL || status === STATUS_EMPTY ? iconNameFull : iconNameHalf,
      size: "size20",
    };
  }

  function getMaxItems() {
    return placeholders ? MAX_ITEMS : Math.ceil(roundHalf(rating));
  }

  function roundHalf(value) {
    return Math.round(value * 2) / 2;
  }
};

Rating.propTypes = {
  /**
   * Additional classes for positioning the component. Given classes may only position this component
   * for layout purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Color to be used for rating icons. This should be a reference to a theme entry. */
  color: PropTypes.string,

  /** When disabled is set, the rating will be displayed using the standard gray. */
  disabled: PropTypes.bool,

  /** Name of icon using to display the rating. */
  iconNameFull: PropTypes.string,

  /** Name of icon to be used for half ratings. */
  iconNameHalf: PropTypes.string,

  /** When placeholders is true, "empty slots" will be rendered on the right for ratings four and below. */
  placeholders: PropTypes.bool,

  /**
   * Number of icons (stars) to display. Value will be rounded to nearest half rating. Using a number
   * that is a whole or half rating works best as only whole and half values will be rendered.
   */
  rating: PropTypes.oneOfType([
    PropTypes.oneOf([0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5]),
    PropTypes.number,
  ]),
};

Rating.defaultProps = {
  color: RATING_FG_DESIGN_TOKEN,
  iconNameFull: "Star",
  iconNameHalf: "HalfStar",
  placeholders: false,
};
