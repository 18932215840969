import PropTypes from "prop-types";
import React from "react";

export const LuggageCart = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path d="M62.910,49.143 L21.590,49.143 C17.366,49.143 13.929,45.679 13.929,41.422 L13.929,7.710 C13.929,5.776 12.367,4.201 10.447,4.201 L2.089,4.201 C0.936,4.201 0.000,3.258 0.000,2.095 C0.000,0.933 0.936,-0.009 2.089,-0.009 L10.447,-0.009 C14.671,-0.009 18.107,3.454 18.107,7.711 L18.107,41.423 C18.107,43.358 19.669,44.932 21.589,44.932 L62.910,44.932 C64.063,44.932 64.999,45.875 64.999,47.037 C65.000,48.200 64.063,49.143 62.910,49.143 ZM60.274,41.415 L57.465,41.415 L57.465,17.878 L60.274,17.878 C61.026,17.878 61.944,18.802 61.944,19.563 L61.944,39.735 C61.944,41.363 60.274,41.415 60.274,41.415 ZM29.362,17.876 L54.496,17.876 L54.496,41.414 L29.362,41.414 L29.362,17.876 ZM46.918,12.805 L42.247,12.805 L36.915,12.805 L36.915,16.167 L33.514,16.167 L33.514,10.969 C33.514,10.206 34.248,9.474 34.997,9.474 L48.778,9.474 C49.527,9.474 50.259,10.206 50.259,10.969 L50.259,16.167 L46.918,16.167 L46.918,12.805 ZM21.905,39.735 L21.905,19.563 C21.905,18.802 22.823,17.878 23.570,17.878 L26.380,17.878 L26.380,41.415 L23.570,41.415 C22.823,41.415 21.905,40.493 21.905,39.735 ZM27.023,52.077 C30.014,52.077 32.439,54.521 32.439,57.536 C32.439,60.551 30.014,62.995 27.023,62.995 C24.031,62.995 21.606,60.551 21.606,57.536 C21.606,54.521 24.031,52.077 27.023,52.077 ZM56.528,52.077 C59.519,52.077 61.944,54.521 61.944,57.536 C61.944,60.551 59.519,62.995 56.528,62.995 C53.536,62.995 51.111,60.551 51.111,57.536 C51.111,54.521 53.536,52.077 56.528,52.077 Z" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      style,
      viewBox: "0 0 65 63",
    };
  }
};

LuggageCart.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

LuggageCart.defaultProps = { role: "img" };
