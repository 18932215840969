import PropTypes from "prop-types";
import React from "react";

export const XCorp = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <polygon points="15.98 14.9 12.54 9.98 10.7 9.98 14.97 16.08 15.5 16.84 15.5 16.84 15.5 16.84 19.15 22.07 20.99 22.07 16.52 15.67 15.98 14.9" />
      <path d="m16,0C7.16,0,0,7.16,0,16s7.16,16,16,16,16-7.16,16-16S24.84,0,16,0Zm2.66,23.11l-3.79-5.52-4.75,5.52h-1.23l5.43-6.32-5.43-7.91h4.14l3.59,5.23,4.5-5.23h1.23l-5.18,6.02h0l5.63,8.2h-4.14Z" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "-2 -2 36 36",
    };
  }
};

XCorp.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

XCorp.defaultProps = { role: "img" };
