/* eslint-disable sort-keys */
export default {
  light: {
    "autocomplete-disabled": "neutral-700",
    "autocomplete-separator": "neutral-500",
    "confirmation-number-text": "success-500",
    "flight-indicator-disabled": "neutral-200",
    "itinerary-badge-emphasis": "primary-200",
    "itinerary-badge-font-large": "1.25rem",
    "itinerary-badge-font-medium": "1.125rem",
    "itinerary-badge-font-small": "0.75rem",
    "itinerary-badge-font-xlarge": "1.5rem",
    "itinerary-badge-font-xsmall": "0.625rem",
    "itinerary-badge-normal": "neutral-200",
    "itinerary-badge-subtle": "neutral-200",
    "itinerary-flight-duration-primary": "neutral-100",
    "itinerary-flight-duration-secondary": "primary-500",
    "itinerary-flight-invalid": "neutral-200",
    "itinerary-recent-search-itinerary-dates": "neutral-300",
    "next-day-indicator": "success-500",
    "overnight-indicator": "tertiary-500",
    "primary-badge-active-text": "primary-100",
    "primary-badge-background": "secondary-500",
    "primary-badge-disabled": "neutral-200",
    "primary-badge-disabled-text": "neutral-900",
    "stop-duration-line": "neutral-500",
    "stop-duration-primary": "neutral-200",
    "vacations-destination-description": "neutral-200",
  },
  dark: {},
};
