import PropTypes from "prop-types";
import React from "react";

export const TripAdvisor = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path
        d="M18.3,22.9c-0.8,0.8-1.5,1.6-2.3,2.5c-0.8-0.8-1.5-1.7-2.3-2.5c-3.6,2.7-7.7,2.3-10.4-0.5C1.3,20.3,0.6,15.3,3.7,12
	C3,11.3,2.4,10.5,1.4,9.5c1.5,0,2.7,0.1,3.9,0c0.8-0.1,1.7-0.3,2.4-0.6c5.3-2.8,10.7-3,16-0.2c1.2,0.6,2.3,1,3.7,0.9
	c0.9-0.1,1.9,0,3.1,0c-0.9,1-1.5,1.8-2.3,2.7c2.3,1.9,2.5,4.5,2.1,7.1c-0.2,1.3-1.1,2.6-2.1,3.5C25.9,25.1,21.9,25.7,18.3,22.9
	L18.3,22.9z M8.7,12.6c-2.8,0-4.9,2.1-4.9,4.9c0,2.8,2.1,4.9,4.9,4.9c2.8,0,5-2.1,5-4.9C13.6,14.7,11.5,12.6,8.7,12.6L8.7,12.6z
	 M23.3,12.6c-2.8,0-4.9,2.1-4.9,4.8c0,2.8,2.1,5,5,5c2.8,0,4.9-2.2,4.9-4.9C28.2,14.7,26.1,12.6,23.3,12.6L23.3,12.6z M21.3,10.2
	c-3-1.5-8.4-1.4-10.4,0.1c2.7,1.3,4.7,3.3,5.1,6.6C16.5,13.5,18.4,11.6,21.3,10.2L21.3,10.2z"
      />
      <path d="M11.4,17.5c0,1.3-1.4,2.7-2.7,2.6c-1.3,0-2.5-1.2-2.6-2.6c0-1.4,1.1-2.7,2.5-2.7C10,14.9,11.3,16.1,11.4,17.5z" />
      <path d="M25.9,17.5c0,1.4-1.2,2.7-2.6,2.7c-1.3,0-2.7-1.4-2.6-2.7c0-1.3,1.2-2.5,2.6-2.6C24.6,14.8,25.9,16.1,25.9,17.5L25.9,17.5z" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "0 0 32 32",
    };
  }
};

TripAdvisor.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

TripAdvisor.defaultProps = { role: "img" };
