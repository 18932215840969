import React from "react";

import { Input } from "@swa-ui/core";

import { withFormField } from "../withFormField";
import styles from "./HiddenFormField.module.scss";

/**
 * HiddenFormField provides support for a standard Input component to be used as
 * a hidden field in a Form.
 */

export const HiddenFormField = (props) => (
  <div className={styles.hidden}>{withFormField(Input, { type: "hidden" })(props)}</div>
);
