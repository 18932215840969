import { removeCookie } from "../cookie";
import { storesToDelete } from "../defines/storesToDelete";

const {
  CORPORATE_STORES_TO_DELETE,
  MWEB_STORES_TO_DELETE,
  RESPONSIVE_STORES_TO_DELETE,
  VISION_STORES_TO_DELETE,
} = storesToDelete;

export const removeStore = (storeName, useLocalStorage = false) => {
  const storage = useLocalStorage ? localStorage : sessionStorage;

  Object.keys(storage).forEach((storageKey) => {
    storageKey.startsWith(storeName) && storage.removeItem(storageKey);
  });
};

export const removeCorporateStores = () => {
  CORPORATE_STORES_TO_DELETE.forEach((storeName) => {
    removeStore(storeName);
  });
};

export const removeMwebStores = () => {
  MWEB_STORES_TO_DELETE.forEach((storeName) => {
    removeStore(storeName, true);
  });
};

export const removeVisionStores = () => {
  VISION_STORES_TO_DELETE.forEach((storeName) => {
    removeStore(storeName);
  });
};

export const removeResponsiveStores = () => {
  RESPONSIVE_STORES_TO_DELETE.forEach((storeName) => {
    removeStore(storeName);
  });
};

export const removeCookies = (isCorporateSite) => {
  removeCookie("id_token", "/", location?.hostname);
  removeCookie("token"); // Leapfrog token
  removeCookie("AccountBarCookie", "/", location?.hostname); // Leapfrog Cookie for static pages
  isCorporateSite && removeCookie("cid", "/", location?.hostname);
  isCorporateSite && removeCookie("corporateToken", "/", location?.hostname);
};

export const removeRefreshToken = () => {
  removeCookie("refresh_token", "/", location?.hostname);
};
