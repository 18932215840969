import * as yup from "yup";

import { getBootstrapData } from "@swa-ui/bootstrap";
import { dateFormats, swaDate } from "@swa-ui/date";
import i18n from "@swa-ui/locale";

import { getDateSchema } from "./getDateSchema";

let schema;

const firstBookableDate = swaDate().set("date", swaDate().get("date"));

export const bookingWidgetSchema = () => {
  const stationIds = getBootstrapData("air/stations-data").map((station) => station.id);

  if (!schema) {
    schema = yup
      .object()
      .shape({
        destinationAirportCode: yup
          .string()
          .trim()
          .required()
          .label(i18n("schema__ARRIVAL_AIRPORT").toLowerCase()),
        originationAirportCode: yup
          .string()
          .trim()
          .uppercase()
          .required()
          .oneOf(stationIds)
          .label(i18n("schema__DEPARTURE_AIRPORT").toLowerCase()),
        departureDate: getDateSchema()
          .test(
            "FirstBookableDate",
            i18n("schema__ERROR_MIN_DATE", firstBookableDate.format("MM/DD/YYYY")),
            (departDateValue) => swaDate(departDateValue).isAfter(swaDate(), "day")
          )
          .required()
          .label(i18n("schema__DEPART_DATE").toLowerCase()),
        returnDate: getDateSchema()
          .required()
          .label(i18n("schema__RETURN_DATE").toLowerCase())
          .when("departDate", (departDate, returnDateTestSchema) =>
            returnDateTestSchema.test(
              "BeforeDepartureDate",
              i18n("schema__ERROR_MIN_DATE", i18n("schema__DEPART_DATE").toLowerCase()),
              (returnDateValue) =>
                swaDate(returnDateValue, dateFormats.default, true).isValid() &&
                swaDate(returnDateValue).isAfter(departDate, "day")
            )
          ),
      })
      .noUnknown();
  }

  return schema;
};
