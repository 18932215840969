import { getBootstrapData } from "@swa-ui/bootstrap";

/**
 * getAllCarLocations
 *
 * Return value: array of all car location info.
 */

export const getAllCarLocations = () => getBootstrapData("car/car-locations-data");

/**
 * getCarLocation
 *
 * Parameter Example: 'DAL'
 * Return value: Car Location info for given ID. 'undefined' will be return if ID is invalid.
 */

export const getCarLocation = (carLocationCode) =>
  getAllCarLocations().find((locationInfo) => locationInfo.id === carLocationCode);

/**
 * getCarLocationName outputs the display name from a given airport code.
 *
 * Parameter example: 'ABQ'
 * Return value: Albuquerque, NM - ABQ
 *
 */

export const getCarLocationName = (carLocationCode) => {
  const carLocation = getCarLocation(carLocationCode);

  return carLocation ? carLocation.displayName : "";
};

/**
 * getCarLocationForName returns the car location info for the given location display name.
 *
 * Parameter example: 'Albuquerque, NM - ABQ'
 * Return value: car location info
 *
 */
export const getCarLocationForName = (displayName) =>
  getAllCarLocations()?.find((carLocation) => carLocation.displayName === displayName);
