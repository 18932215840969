import PropTypes from "prop-types";
import React from "react";

import { Link } from "../../Link";

/**
 * EditButton uses Link to render a button with text on the left side and Edit icon on the right side.
 */

export const EditButton = (props) => {
  const { className, label, onClick } = props;

  return <Link {...getProps()}>{label}</Link>;

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      emphasis: false,
      onClick,
      suffixIcon: { name: "Edit", transparentBorder: false },
    };
  }
};

EditButton.propTypes = {
  /** aria-label text to provide additional accessibility description for the button element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Text on the left side of the Edit icon. */
  label: PropTypes.string.isRequired,

  /** Function to be called when EditButton is clicked. */
  onClick: PropTypes.func,
};
