"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useDestinationThemeModuleAnalytics = useDestinationThemeModuleAnalytics;
var _react = require("react");
var _reactRouterDom = require("react-router-dom");
var _application = require("@swa-ui/application");
function useDestinationThemeModuleAnalytics(placementData) {
  var themeStations = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var _useLocation = (0, _reactRouterDom.useLocation)(),
    pathname = _useLocation.pathname;
  var _useContext = (0, _react.useContext)(_application.MktgDataContext),
    setMktgPageData = _useContext.setMktgPageData;
  var _ref = placementData !== null && placementData !== void 0 ? placementData : {},
    _ref$contentBlockId = _ref.contentBlockId,
    contentBlockId = _ref$contentBlockId === void 0 ? "" : _ref$contentBlockId;
  (0, _react.useEffect)(function () {
    if ((pathname === "/" || pathname === "/landing/home-page/") && themeStations.length) {
      setMktgPageData({
        moduledetaildestinationthemes: getModuleDetails()
      }, "landingHomePage");
    }
  }, [pathname, themeStations]);
  function getModuleDetails() {
    return themeStations === null || themeStations === void 0 ? void 0 : themeStations.map(function (station) {
      return "".concat(contentBlockId, ";;").concat(station, ";");
    }).join();
  }
}