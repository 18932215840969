import PropTypes from "prop-types";
import React from "react";

export const Bed2 = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path d="M9.3335 18.6666C11.5468 18.6666 13.3335 16.88 13.3335 14.6666C13.3335 12.4533 11.5468 10.6666 9.3335 10.6666C7.12016 10.6666 5.3335 12.4533 5.3335 14.6666C5.3335 16.88 7.12016 18.6666 9.3335 18.6666ZM9.3335 13.3333C10.0668 13.3333 10.6668 13.9333 10.6668 14.6666C10.6668 15.4 10.0668 16 9.3335 16C8.60016 16 8.00016 15.4 8.00016 14.6666C8.00016 13.9333 8.60016 13.3333 9.3335 13.3333ZM25.3335 9.33329H14.6668V20H4.00016V6.66663H1.3335V26.6666H4.00016V22.6666H28.0002V26.6666H30.6668V14.6666C30.6668 11.72 28.2802 9.33329 25.3335 9.33329ZM28.0002 20H17.3335V12H25.3335C26.8002 12 28.0002 13.2 28.0002 14.6666V20Z" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "0 0 32 32",
    };
  }
};

Bed2.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Bed2.defaultProps = { role: "img" };
