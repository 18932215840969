import PropTypes from "prop-types";
import React from "react";

export const Advisory = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path
        d="M19.9,29.9c-1.3,0-2.2-1-2.2-2.2c0-1.2,0.9-2.1,2.2-2.1c1.3,0,2.2,1,2.2,2.1C22.1,28.9,21.1,29.9,19.9,29.9
		 M21.9,12.1l-0.7,11.6h-2.8l-0.6-11.6H21.9z M39.3,31.8l-7-12.1c-1-1.7-2.6-4.5-3.6-6.3l-7-12.1c-1-1.7-2.6-1.7-3.6,0l-7,12.1
		c-1,1.7-2.6,4.5-3.6,6.3l-7,12.1c-1,1.7-0.2,3.1,1.8,3.1h14h7.2h14C39.5,35,40.3,33.6,39.3,31.8"
      />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "0 0 39.7 35",
    };
  }
};

Advisory.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Advisory.defaultProps = {
  role: "img",
};
