import PropTypes from "prop-types";
import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import { window } from "@swa-ui/browser";
import i18n, { updateI18n } from "@swa-ui/locale";
import { logger } from "@swa-ui/log";
import { merge } from "@swa-ui/object";
import { usePersistedState } from "@swa-ui/persistence";

import { MktgDataContext } from "../MktgDataProvider";
import { useAppSetting } from "../useAppSetting";

const DEFAULT_MBOX_TIMEOUT = 2000;
const EMPTY_CALL_STATISTICS = {
  errorMessage: "",
  failedMboxCalls: 0,
  totalMboxCalls: 0,
};

/**
 * MboxProvider provides loading details and mbox state from Adobe in a React context so the information is available to
 * components in an app's render tree.
 *
 * loading - Boolean to indicate if the network call is in progress.
 * loadingDetails - Stores information on what is currently loaded and tracks currently (current) and previously loaded
 * (last) pageId and context.
 * loadMboxes - Method to load mboxes.
 * mboxes - State used to store target, test, and test_style returned from Adobe.
 */

export const MboxContext = createContext();
export const MboxProvider = (props) => {
  const { appId, children } = props;
  const { pathname: currentPathname } = useLocation();
  const callStatistics = useRef(EMPTY_CALL_STATISTICS);
  const [loadingDetails, setLoadingDetails] = useState({
    current: {},
    last: {},
  });
  const DEFAULT_MBOXES = { appId, target: {}, test: {}, test_i18n: {}, test_style: {} };
  const [mboxes, setMboxes] = usePersistedState({
    defaultValue: DEFAULT_MBOXES,
    key: "mboxProvider-mboxes",
  });
  const mboxDefaults = useAppSetting("mboxDefaults", DEFAULT_MBOXES);
  const mboxNames = useAppSetting("mboxes", []);
  const mboxTimeout = useAppSetting("mboxTimeout", DEFAULT_MBOX_TIMEOUT);
  const { updateMktgGlobalData } = useContext(MktgDataContext);

  useEffect(() => {
    callStatistics.current = EMPTY_CALL_STATISTICS;
  }, [currentPathname]);

  useEffect(() => {
    if (loadingDetails.current.pathname) {
      retrieveMboxes();
    }
  }, [loadingDetails]);

  useEffect(() => {
    if (appId !== mboxes.appId) {
      setMboxUsingDefaults(appId);
    }
  }, []);

  useEffect(() => {
    const { failedMboxCalls, errorMessage, totalMboxCalls } = callStatistics.current;
    const redirectMbox = mboxes?.redirect;

    updateMktgGlobalData({
      mbox: { appId, ...mboxes },
      mbox_failedcalls: failedMboxCalls,
      mbox_timeoutartifact: errorMessage,
      mbox_totalcalls: totalMboxCalls,
    });

    redirectMbox && window.location.assign(redirectMbox);
  }, [mboxes]);

  return (
    <MboxContext.Provider value={getContextValue()}>
      {renderTestStyles()}
      {children}
    </MboxContext.Provider>
  );

  function renderTestStyles() {
    const testStyle = mboxes.test_style ?? {};
    const styleHrefs = Object.values(testStyle);
    let testStyles = null;

    if (styleHrefs.length > 0) {
      testStyles = styleHrefs.map((href) => (
        <link key={href} type="text/css" rel="stylesheet" href={href} />
      ));
    }

    return testStyles;
  }

  function getContextValue() {
    return {
      loading: loadingDetails.current.pathname === currentPathname,
      loadingDetails,
      loadMboxes,
      mboxes,
    };
  }

  function loadMboxes(pathnameToLoad, context = {}) {
    if (!loadingDetails.current.pathname) {
      setLoadingDetails({
        ...loadingDetails,
        current: { context, pathname: pathnameToLoad },
      });
    }
  }

  function retrieveMboxes() {
    const { getOffers } = window?.adobe?.target ?? {};

    if (mboxNames && mboxNames.length > 0 && getOffers) {
      getOffers({
        request: {
          execute: {
            mboxes: mboxNames.map((name, index) => ({
              index,
              name,
              parameters: getMboxParameters(),
            })),
          },
        },
        timeout: mboxTimeout,
      })
        .then(processResponse)
        .catch(handleError)
        .finally(handleLoadComplete);
    } else {
      setMboxUsingDefaults();
      handleLoadComplete();

      if (!getOffers) {
        logMboxFailure("getOffers is not defined");
      }
    }
  }

  function getMboxParameters() {
    return {
      ...loadingDetails.current.context,
    };
  }

  function processResponse(response) {
    const { mboxes: responseMboxes = [] } = response?.execute ?? {};
    const newMboxes = getDefaults();

    responseMboxes.forEach((responseMbox) => {
      const { options: responseOptions = [] } = responseMbox;

      responseOptions.forEach((responseOption) => {
        const { content = "{}", type } = responseOption;

        if (content.startsWith("<script>")) {
          newMboxes.scripts.push(content);
        } else if (type === "redirect") {
          newMboxes.redirect = content;
        } else {
          const mboxValue = JSON.parse(content);

          if (mboxValue.test_i18n) {
            applyMboxI18n(mboxValue.test_i18n);
          }

          newMboxes.test = {
            ...newMboxes.test,
            ...mboxValue.test,
          };
          newMboxes.test_style = {
            ...newMboxes.test_style,
            ...mboxValue.test_style,
          };
          newMboxes.test_i18n = {
            ...newMboxes.test_i18n,
            ...mboxValue.test_i18n,
          };
          newMboxes.target = merge(newMboxes.target, mboxValue.target ?? {});
        }
      });
    });

    updateTotalMboxCalls();
    setMboxes(newMboxes);
  }

  function applyMboxI18n(mboxI18n = {}) {
    const newI18nKeys = Object.fromEntries(
      Object.entries(mboxI18n).map(([i18nKey, i18nOverrideKey]) => [i18nKey, i18n(i18nOverrideKey)])
    );

    updateI18n(newI18nKeys);
  }

  function handleError(error) {
    incrementFailedMboxCalls(error?.message);
    setMboxUsingDefaults();
    logMboxFailure(error?.message);
  }

  function updateTotalMboxCalls() {
    const { totalMboxCalls, ...rest } = callStatistics.current;

    callStatistics.current = { ...rest, totalMboxCalls: totalMboxCalls + 1 };
  }

  function incrementFailedMboxCalls(errorMessage = "") {
    updateTotalMboxCalls();
    const { failedMboxCalls, ...rest } = callStatistics.current;

    callStatistics.current = {
      ...rest,
      errorMessage,
      failedMboxCalls: failedMboxCalls + 1,
    };
  }

  function setMboxUsingDefaults(newAppId) {
    setMboxes(getDefaults(newAppId));
  }

  function getPersistedStylesheet(test_style, newAppId) {
    let testStyles = {};

    if (newAppId) {
      testStyles = mboxDefaults?.test_style;
    } else if (test_style && Object.keys(test_style)?.length) {
      testStyles = test_style;
    }

    return testStyles;
  }

  function getDefaults(newAppId) {
    const { test_style } = mboxes;

    return {
      appId: newAppId ? newAppId : mboxes.appId,
      scripts: [],
      target: {},
      test: mboxDefaults?.test ?? {},
      test_i18n: mboxDefaults?.test_i18n ?? {},
      test_style: getPersistedStylesheet(test_style, newAppId),
    };
  }

  function logMboxFailure(error) {
    logger.error("REQUEST FOR MBOX FAILED", { error });
  }

  function handleLoadComplete() {
    setLoadingDetails({
      current: {},
      last: loadingDetails.current,
    });
  }
};

MboxContext.displayName = "MboxContext";
MboxProvider.propTypes = {
  /**
   * String that identifies the app for when making requests. The value, something like: 'gift-card', is added to
   * requests.
   */
  appId: PropTypes.string.isRequired,

  /** Content to be rendered on the page. */
  children: PropTypes.node.isRequired,
};
