import { withFormField } from "@swa-ui/form";

import { AutocompleteStations } from "../AutocompleteStations";
import { getStationName } from "../Stations";

/**
 * AutocompleteStationsFormField provides support for a standard AutocompleteStations
 * component to be used in a Form.
 */

export const AutocompleteStationsFormField = withFormField(AutocompleteStations, {
  renderCaption: (props, value) => (value ? getStationName(value) : ""),
  useController: true,
});
