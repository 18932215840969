import React from "react";

import { Icon } from "@swa-ui/core";
import i18n from "@swa-ui/locale";

import styles from "./ErrorPlacement.module.scss";

export const ErrorPlacement = () => (
  <div className={styles.error}>
    <div className={styles.content}>
      <Icon
        name="Picture"
        size="size64"
        color="body-disabled"
        aria-label={i18n("ErrorPlacement__ICON_ARIA")}
      />
      {i18n("ErrorPlacement__TEXT")}
    </div>
  </div>
);
