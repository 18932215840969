import React from "react";

import { Card } from "@swa-ui/core";

import { VacationsBookingFormWide } from "../VacationsBookingForm";

export const VacationsBookingWidget = () => (
  <Card>
    <VacationsBookingFormWide />
  </Card>
);
