import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { classNames } from "@swa-ui/string";

import { Accordion } from "../Accordion";
import { Card, cardPropTypes } from "../Card";
import { Heading, headingPropTypes } from "../Heading";
import styles from "./CollapsibleCard.module.scss";

/**
 * CollapsibleCard is used to show and hide content of a Card using an Accordion.
 */

export const CollapsibleCard = (props) => {
  const {
    children,
    className,
    footerProps,
    fullHeight,
    fullWidthContent,
    headingProps,
    id,
    name,
    revealed,
  } = props;
  const [reveal, setReveal] = useState(revealed);

  useEffect(() => {
    setReveal(revealed);
  }, [revealed]);

  return (
    <Card {...getProps()}>
      <Accordion {...getAccordionProps()}>
        <div className={classNames({ [styles.contentContainer]: !fullWidthContent })}>
          {children}
        </div>
        {reveal && !footerProps?.children ? <div className={styles.bottom} /> : null}
      </Accordion>
    </Card>
  );

  function getProps() {
    return {
      animate: false,
      className,
      footerProps: reveal && footerProps ? footerProps : null,
      fullHeight,
      fullHeightContent: true,
      fullWidthContent: true,
      id,
    };
  }

  function getAccordionProps() {
    return {
      fullWidthContent: true,
      indicatorClassName: styles.indicator,
      label: (
        <Heading className={styles.heading} styleLevel={headingProps.styleLevel}>
          {headingProps.children}
        </Heading>
      ),
      labelBorder: false,
      name,
      onChange: handleChange,
      revealed,
      styleType: "tertiary",
    };
  }

  function handleChange(newRevealState) {
    setReveal(newRevealState);
  }
};

CollapsibleCard.propTypes = {
  /** Content that will be rendered when CollapsibleCard is expanded. */
  children: PropTypes.node.isRequired,

  /**
   * Additional classes for positioning the component. Given classes may only position this component
   * for layout purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * Optional footer displayed when CollapsibleCard is expanded, with options for the footer
   * background color and children. See Card for more details.
   */
  footerProps: cardPropTypes.footerProps,

  /** Determines if the CollapsibleCard component will spread the entire height of its parent container. */
  fullHeight: PropTypes.bool,

  /** Determines if the child content will spread to the entire width of the CollapsibleCard. */
  fullWidthContent: PropTypes.bool,

  /** See Heading component for available options. */
  headingProps: PropTypes.shape(headingPropTypes).isRequired,

  /** Id. Used as unique identifier on DOM. */
  id: PropTypes.string,

  /**
   * <code>name</code> will be used to tie the clickable area with Accordion's content. The given
   * name is used for the aria-controls attribute to be A11Y compliant.
   */
  name: PropTypes.string.isRequired,

  /** Indicates if CollapsibleCard is revealed or concealed. */
  revealed: PropTypes.bool,
};

CollapsibleCard.defaultProps = {
  fullWidthContent: false,
  revealed: false,
};
