import PropTypes from "prop-types";
import React from "react";

import { classNames } from "@swa-ui/string";

import { ToggleButton } from "./ToggleButton";
import styles from "./ToggleButtonGroup.module.scss";

/**
 * Simple component to show a group of ToggleButtons.
 */

export const ToggleButtonGroup = (props) => {
  const { className, buttonContentList, onClick, selectedIndex } = props;

  return (
    <div className={classNames(className, styles.ToggleButtonGroup)}>
      {buttonContentList.map(renderButton)}
    </div>
  );

  function renderButton(content, index) {
    return (
      <ToggleButton
        key={index}
        onClick={handleClick.bind(this, index)}
        selected={selectedIndex === index}
      >
        {content}
      </ToggleButton>
    );
  }

  function handleClick(index) {
    onClick && onClick(index);
  }
};

ToggleButtonGroup.propTypes = {
  /** Content that will be rendered in button. */
  buttonContentList: PropTypes.arrayOf(PropTypes.node).isRequired,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * Callback to inform caller when a button is clicked. The zero based index of the button will be passed for the
   * only argument.
   */
  onClick: PropTypes.func,

  /** Zero based index of which button is selected. There will always be a single button selected. */
  selectedIndex: PropTypes.number,
};

ToggleButtonGroup.defaultProps = {
  selectedIndex: 0,
};
