// This is to ensure that directly referenced theme/color names throughout the core use the
// CSS variable syntax to utilize the color theme mechanism provided by `@swa-ui/theme` without
// bringing it in as a dependency and using it's helpers via context. Once all references to direct
// colors are replaced with hex value strings throughout all applications, this helper will be removed.
export const getColorVariable = (colorName) => {
  let colorVariable;

  if (typeof colorName === "string") {
    colorVariable = colorName.startsWith("#") ? colorName : `var(--${colorName}, ${colorName})`;
  }

  return colorVariable;
};
