import React from "react";

import { Grid, Icon, Link } from "@swa-ui/core";
import { HtmlValue } from "@swa-ui/placements";
import { usePlacement } from "@swa-ui/placements";
import { classNames } from "@swa-ui/string";
import { useColorTheme } from "@swa-ui/theme";

import { Callouts } from "./Callouts";
import { ColumnList } from "./ColumnList";
import styles from "./DotcomDesktopFooter.module.scss";

/**
 * DotcomFooter provides the footer for each page. There are three different presentations rendered, based on screen
 * size.
 */

export const DotcomDesktopFooter = () => {
  const { placement } = usePlacement("globalNavFooter");
  const placementData = placement?.globalNavFooterLinks?.[0] ?? {};
  const globalNavCopyright = placement?.globalNavCopyright ?? {};
  const { linkColumns } = placementData;
  const { copyright, legend, logos } = globalNavCopyright;
  const { getColorName } = useColorTheme();
  const year = new Date().getFullYear();
  const fullCopyright = `© ${year} ${copyright?.value}`;

  return (
    <Grid className={classNames(styles.footer, styles.layout)}>
      <Callouts className={styles.callouts} />
      {linkColumns && <ColumnList className={styles.linkColumns} linkColumns={linkColumns} />}
      {legend && (
        <div className={styles.legend}>
          <Icon name="External" size="size13" color={getColorName("footer-content")} />
          {legend?.value}
        </div>
      )}
      {copyright && (
        <div className={styles.copyright}>
          <HtmlValue htmlValue={fullCopyright} />
        </div>
      )}
      {logos && (
        <div className={styles.linksAndLogo}>
          {logos.map((logo, index) => renderLogo(logo, index))}
        </div>
      )}
    </Grid>
  );

  function renderLogo(logo, index) {
    const { altText, height, src, url, width } = logo;

    return (
      <Link href={url} key={`${url}-${index}`}>
        <img alt={altText} src={src} height={height} width={width} />
      </Link>
    );
  }
};

DotcomDesktopFooter.propTypes = {};

DotcomDesktopFooter.defaultProps = {};
