import PropTypes from "prop-types";
import React from "react";

export const Offers = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path
        d="M142.675,61.803c0.008-2.17,0.094-50.144,0.094-54.386c0-4.344-3.028-7.417-7.42-7.417c-4.39,0-54.494,0.106-54.494,0.106
	L80.818,0.15c-2.291-0.006-4.588,0.863-6.322,2.607L2.612,74.642c-3.483,3.48-3.483,9.119,0,12.605l52.983,52.974
	c3.48,3.491,9.126,3.491,12.596,0l71.894-71.879C141.886,66.544,142.739,64.165,142.675,61.803z M113.848,16.812
	c6.675,0,12.098,5.421,12.098,12.107c0,6.68-5.423,12.11-12.098,12.11c-6.702,0-12.114-5.43-12.114-12.11
	C101.734,22.233,107.146,16.812,113.848,16.812z"
      />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      style,
      viewBox: "0 0 143 140",
    };
  }
};

Offers.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Offers.defaultProps = {
  role: "img",
};
