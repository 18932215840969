import PropTypes from "prop-types";
import React from "react";

import { Icon } from "@swa-ui/core";
import i18n from "@swa-ui/locale";
import { classNames } from "@swa-ui/string";

import { FlightDuration } from "../FlightDuration";
import { getShortDisplayStationName } from "../Stations";
import styles from "./StopDuration.module.scss";

/**
 * StopDuration displays the time and location related to the layover of the current connecting flight.
 */

export const StopDuration = (props) => {
  const { airportCode, changePlanes, className, disabled, durationTime, showSeparator } = props;

  return (
    <div {...getClass()}>
      <span className={styles.textContainer}>
        {changePlanes && <Icon {...getChangePlanesIconProps()} />}
        <span className="screen-reader-only">{i18n("StopDuration__CHANGE_PLANES_ARIA")}</span>
        <div
          className={classNames(styles.stopText, {
            [styles.disabled]: disabled,
          })}
        >
          <span aria-hidden="true">{airportCode}</span>,&nbsp;
          <span className="screen-reader-only">{getShortDisplayStationName(airportCode)}</span>
          <FlightDuration
            aria-label={i18n("StopDuration__STOP_DURATION_ARIA")}
            className={styles.durationTime}
            duration={durationTime}
          />
        </div>
      </span>
      {showSeparator && <div className={styles.line} />}
    </div>
  );

  function getClass() {
    return {
      className: classNames(className, styles.stopDuration),
    };
  }

  function getChangePlanesIconProps() {
    return {
      "aria-hidden": "true",
      className: styles.icon,
      color: "gray-4",
      name: "ChangePlanes",
      size: "size24",
    };
  }
};

StopDuration.propTypes = {
  /** Value for the airport code for the stop location. */
  airportCode: PropTypes.string,

  /** Boolean value for if the flight leg has a plane change. */
  changePlanes: PropTypes.bool,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Boolean value for determining if the stop duration is disabled or not. */
  disabled: PropTypes.bool,

  /** Value for duration of the stop in minutes. */
  durationTime: PropTypes.number.isRequired,

  /** Boolean value to show horizontal line or not. */
  showSeparator: PropTypes.bool,
};

StopDuration.defaultProps = {
  showSeparator: true,
};
