const toCamelCase = (str = "") => {
  const capitalize = (word = "") => word[0].toUpperCase() + word.slice(1).toLowerCase();

  return str
    .split("-")
    .filter(Boolean)
    .reduce((result, word, i) => result + (i ? capitalize(word) : word), []);
};

const toScaledValue = (styleString = "", scaleFactor = 1, shouldScalePlacement = false) => {
  const number = parseFloat(styleString);
  const scaledNumber = +(number * scaleFactor).toFixed(4);

  return shouldScalePlacement
    ? styleString.replace(`${number}px`, `${scaledNumber}px`)
    : styleString;
};

export const getScaleAdjustedStyles = (scaleFactor, shouldScalePlacement, styles = {}) => {
  const newStyles = {};

  Object.keys(styles).forEach((key) => {
    newStyles[toCamelCase(key)] = toScaledValue(styles[key], scaleFactor, shouldScalePlacement);
  });

  return newStyles;
};
