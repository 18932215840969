import PropTypes from "prop-types";
import { useContext } from "react";

import { classNames } from "@swa-ui/string";

import { DesignTokensThemeContext } from "../DesignTokensThemeProvider";

/**
 * useDesignTokensTheme uses React's useContext hook and Provider to return a className with component
 * styleType design tokens.
 */

export const useDesignTokensTheme = (props) => {
  const { component, styleType } = props;
  const {
    inverse = false,
    modeName = "",
    themeName = "",
  } = useContext(DesignTokensThemeContext) ?? {};
  const colorAttributesClassName = [
    themeName,
    modeName,
    inverse ? "inverse" : "",
    component,
    styleType,
  ]
    .filter(Boolean)
    .join("-");
  const nonColorAttributesClassName = [themeName, component, styleType].filter(Boolean).join("-");

  return {
    themeClass: classNames(colorAttributesClassName, nonColorAttributesClassName),
  };
};

useDesignTokensTheme.propTypes = {
  /** Lowercased component name, for example "button".  */
  component: PropTypes.string.isRequired,

  /** Lowercased component styleType, for example "primary". */
  styleType: PropTypes.string.isRequired,
};
