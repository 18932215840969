import { useRef, useState } from "react";

import { useDismiss } from "../useDismiss";

/**
 * useDialog encapsulates the functionality required to drive a Dialog component.
 * Current functionality:
 * - ESC to close
 * - close on clicking outside of component.
 *
 * location: String that indicates the directional position of the dialog.
 * initialLocation: String that indicates the starting directional position of the dialog.
 * onClose: callback to be informed when Dialog closes.
 */

export const useDialog = (
  location = "above",
  initialLocation = "hidden",
  onClose = () => {},
  restoreFocusOnClose = true
) => {
  const [dialogLocation, setDialogLocation] = useState(initialLocation);
  const dialogRef = useRef(null);
  const isDialogVisible = dialogLocation !== "hidden";

  useDismiss(dialogRef, hideDialog, isDialogVisible, onClose, restoreFocusOnClose);

  return {
    dialogLocation,
    dialogRef,
    hideDialog,
    isDialogVisible,
    showDialog,
  };

  function hideDialog() {
    setDialogLocation("hidden");
  }

  function showDialog() {
    setDialogLocation(location);
  }
};
