import PropTypes from "prop-types";
import React from "react";

import { SMALL, useDeviceInfo } from "@swa-ui/browser";
import { Card, Grid, Heading } from "@swa-ui/core";
import { classNames } from "@swa-ui/string";

import { applyOverrides } from "../applyOverrides";
import { CallToAction } from "../CallToAction";
import { HtmlValue } from "../HtmlValue";
import styles from "./BannerWithCardLayout2.module.scss";
import {
  backgroundProperties,
  callToActionPropType,
  cardAlignmentPropType,
  textPropType,
} from "./BannerWithCardProptypes";

export const BannerWithCardLayout2 = (props) => {
  const { background, callToAction, cardAlignment, disclaimer, text } = props;
  const { primaryText, primaryTitle, secondaryTitle } = text ?? {};
  const { align } = applyOverrides(cardAlignment);
  const { isLargeOrXlarge, screenSize } = useDeviceInfo();

  return screenSize === SMALL ? renderContentForSmallScreen() : renderContent();

  function renderContent() {
    return (
      <div className={styles.bannerWithCardContainer} style={{ ...getBannerWithCardStyles() }}>
        {renderGraphicImagePath()}
        <Grid className={getGridContainerClass()}>
          <Card className={styles.cardContainer}>{renderCardContent()}</Card>
        </Grid>
      </div>
    );
  }

  function renderContentForSmallScreen() {
    return (
      <div className={styles.bannerWithCardContainer}>
        <div className={styles.backgroundContainer} style={{ ...getBackgroundStyles() }} />
        <Card className={styles.cardContainer}>{renderCardContent()}</Card>
      </div>
    );
  }

  function renderGraphicImagePath() {
    const graphicContent = background?.[0] ?? {};
    const { imagePath } = applyOverrides(graphicContent, screenSize);

    return (
      <div className={styles.backgroundContainer} style={{ ...getBackgroundStyles() }}>
        {imagePath && (
          <img className={styles.bannerWithCardImage} role="presentation" src={imagePath} />
        )}
      </div>
    );
  }

  function renderCardContent() {
    const headingStyleLevel = 4;

    return (
      <div className={styles.cardContentContainer}>
        {primaryTitle?.value && <Heading {...getHeadingProps(primaryTitle, headingStyleLevel)} />}
        {secondaryTitle?.value && <Heading {...getHeadingProps(secondaryTitle)} />}
        {primaryText?.value && <HtmlValue htmlValue={primaryText.value} />}
        {callToAction && <CallToAction {...getCallToActionProps()} />}
        {disclaimer?.value && renderDisclaimer()}
      </div>
    );
  }

  function renderDisclaimer() {
    return <HtmlValue className={styles.disclaimer} htmlValue={disclaimer.value} />;
  }

  function getBannerWithCardStyles() {
    return {
      ...(align === "right" && isLargeOrXlarge && { justifyItems: "end" }),
    };
  }

  function getBackgroundStyles() {
    const backgroundContent = background?.[0] ?? {};
    const { color, imagePath, type } = applyOverrides(backgroundContent, screenSize);

    return {
      ...(screenSize === SMALL && type === "image" && { backgroundImage: `url(${imagePath})` }),
      ...(type === "color" && { backgroundColor: color }),
      ...(align === "left" && { justifyContent: "flex-end" }),
    };
  }

  function getHeadingProps(headingTitle, styleLevel) {
    return {
      children: <span {...getStyleProps(headingTitle)}>{headingTitle.value}</span>,
      headingLevel: 2,
      styleLevel,
    };
  }

  function getCallToActionProps() {
    return {
      className: styles.ctaLink,
      ...(screenSize === SMALL && { additionalProps: { fullWidth: true } }),
      ...callToAction,
    };
  }

  function getStyleProps(styleProps) {
    const { color, fontSize, fontWeight } = styleProps ?? {};

    return {
      style: {
        color,
        fontSize,
        fontWeight,
      },
    };
  }

  function getGridContainerClass() {
    return classNames({
      [styles.leftAlignmentGridContainer]: align === "left",
      [styles.rightAlignmentGridContainer]: align === "right",
    });
  }
};

BannerWithCardLayout2.propTypes = {
  /** Background image/color content for the banner. */
  background: PropTypes.oneOfType([backgroundProperties, PropTypes.arrayOf(backgroundProperties)]),

  /** Optional object in the card container that contains link/button props. */
  callToAction: callToActionPropType,

  /** Card to be aligned left/right on the background image, and shifts the image accordingly */
  cardAlignment: cardAlignmentPropType,

  /** Optional object in the card container that contains HTML value for a legal disclaimer. */
  disclaimer: PropTypes.shape({
    value: PropTypes.string.isRequired,
  }),

  /** Optional object in the card container for two headings, and text content to be displayed. */
  text: PropTypes.shape({
    primaryText: textPropType,
    primaryTitle: textPropType,
    secondaryTitle: textPropType,
  }),
};
