import PropTypes from "prop-types";
import React from "react";

export const UnaccompaniedMinorUX = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path
        className="cls-1"
        d="M115.51,73.62c12.06,0,21.58,10.15,21.58,23.48s-10.15,26.02-21.58,26.02-21.58-12.06-21.58-26.02c.63-13.33,9.52-23.48,21.58-23.48Z"
      />
      <path
        className="cls-1"
        d="M114.24,135.18l1.27,.63,1.27-.63c.63,0,11.42-6.35,20.31-13.33l5.08,1.9c13.96,5.08,13.96,13.96,13.96,13.96v4.44c-3.17,2.54-15.87,10.15-39.98,10.15s-38.08-8.25-41.89-10.15v-4.44c0-2.54,2.54-9.52,13.96-13.96,1.27-.63,4.44-1.27,6.35-1.9,6.98,6.35,19.04,13.33,19.67,13.33Z"
      />
      <path
        className="cls-1"
        d="M64.73,0c19.67,0,34.91,15.87,34.91,38.71s-15.87,42.52-34.91,42.52S29.83,60.93,29.83,38.71c0-22.85,15.23-38.71,34.91-38.71Z"
      />
      <path
        className="cls-1"
        d="M.63,113.6s-.63-4.44-.63-8.89,3.81-17.14,22.85-24.12c3.81-1.27,11.42-3.81,11.42-3.81,10.79,10.79,30.46,20.94,30.46,20.94,0,0,17.77-9.52,29.83-20.31h0c-4.44,5.08-6.98,12.06-6.98,20.31,0,6.35,1.9,12.69,4.44,17.77-1.9,.63-5.08,1.9-6.98,2.54-8.89,3.17-13.96,8.25-16.5,13.33h-4.44C21.58,130.74,.63,113.6,.63,113.6Z"
      />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      style,
      viewBox: "0 0 156.13 152.32",
    };
  }
};

UnaccompaniedMinorUX.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

UnaccompaniedMinorUX.defaultProps = {
  role: "img",
};
