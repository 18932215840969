import PropTypes from "prop-types";
import React, { useEffect } from "react";

import { fireTrack } from "@swa-ui/analytics";
import { Button, CardGrid, Icon } from "@swa-ui/core";
import { sizes } from "@swa-ui/core/defines";
import i18n from "@swa-ui/locale";

import { MfaHeading } from "../MfaHeading";
import styles from "./MfaSuccess.module.scss";

/**
 * MfaSuccess renders a div with children.
 */
export const MfaSuccess = (props) => {
  const { name, onContinue } = props;

  useEffect(() => {
    fireTrack("squid", { mfasuccess: "1", page_description: "button:mfa success" });
  }, []);

  return (
    <CardGrid className={styles.grid}>
      <MfaHeading
        heading={i18n("MfaSuccess__HEADING", name)}
        key="success-heading"
        subheading={i18n("MfaSuccess__SUBHEADING")}
      />
      <Icon {...getSuccessIconProps()} />
      <Button {...getContinueButtonProps()}>{i18n("MfaSuccess__CONTINUE")}</Button>
    </CardGrid>
  );

  function getSuccessIconProps() {
    return {
      className: styles.field,
      color: "success-500",
      name: "Success",
      role: "presentation",
      size: sizes.size80,
    };
  }

  function getContinueButtonProps() {
    return {
      className: styles.field,
      fullWidth: true,
      onClick: onContinue,
    };
  }
};

MfaSuccess.propTypes = {
  /**
   * The first name of the user to display in the success message.
   */
  name: PropTypes.string,

  /**
   * The function to call when the user clicks the continue button.
   */
  onContinue: PropTypes.func,
};
