import { gradientConfig } from "./gradientConfig";

/**
 * Helper function that accepts an established gradient name as a parameter and returns its css style.
 *
 * @param {string} gradientName - Name of configured gradient to be displayed
 * @param {string} direction - Orientation of gradient, based on existing css gradient attributes.
 * For example: "to top", "to bottom right", etc.
 * @returns {string} Linear gradient css style
 */

export const getLinearGradient = (gradientName, direction) => {
  let gradientStops = gradientConfig[gradientName];
  let gradientStyle;

  if (gradientStops) {
    gradientStops = gradientStops
      .map((stop) => `var(${stop.designToken}) ${stop.percentage}%`)
      .join(", ");

    gradientStyle = `linear-gradient(${direction ? `${direction}, ` : ""}${gradientStops})`;
  }

  return gradientStops ? gradientStyle : "";
};
