import PropTypes from "prop-types";
import React from "react";

export const Cultural = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path d="m13.46,4.32h-.9c-.06,0-.12.02-.17.07-.04.04-.07.1-.07.17v.81h-.48v-.81c0-.06-.02-.12-.07-.17-.04-.04-.1-.07-.17-.07h-.9c-.06,0-.12.02-.17.07-.04.04-.07.1-.07.17v1.41h-.73v-3.28c0-.06-.02-.12-.07-.17-.04-.04-.1-.07-.17-.07h-.65v-.49l2-.68s.09-.05.11-.09c.03-.04.04-.09.04-.14s-.02-.1-.04-.14c-.03-.04-.07-.07-.11-.09L8.7.11c-.05-.02-.11-.01-.17,0-.05.02-.1.07-.12.12-.06.49,0,1.72-.02,2.21h-.52c-.06,0-.12.02-.17.07-.04.04-.07.1-.07.17v1.27h-1.48v-1.27c0-.06-.02-.12-.07-.17-.04-.04-.1-.07-.17-.07h-.61v-.49l2-.68s.09-.05.11-.09c.03-.04.04-.09.04-.14s-.02-.1-.04-.14c-.03-.04-.07-.07-.11-.09L5.15.11c-.06-.02-.12-.02-.17,0-.05.02-.1.07-.12.12-.06.49,0,1.72-.02,2.21h-.56c-.06,0-.12.02-.17.07-.04.04-.07.1-.07.17v3.28h-.73v-1.41c0-.06-.02-.12-.07-.17-.04-.04-.1-.07-.17-.07h-.9c-.06,0-.12.02-.17.07-.04.04-.07.1-.07.17v.81h-.48v-.81c0-.06-.02-.12-.07-.17-.04-.04-.1-.07-.17-.07H.33c-.06,0-.12.02-.17.07-.04.04-.07.1-.07.17v8.91c0,.06.02.12.07.17.04.04.1.07.17.07h13.12c.06,0,.12-.02.17-.07.04-.04.07-.1.07-.17V4.55c0-.06-.02-.12-.07-.17-.04-.04-.1-.07-.17-.07Zm-4.6-2.86v-.79l1.19.4-1.19.4Zm-3.55,0v-.79l1.19.4-1.19.4Zm1.58,7.74c-.41,0-.81.16-1.11.46-.29.29-.46.69-.46,1.11v2.47h-2.01v-5.42h7.15v5.42h-2.01v-2.47c0-.41-.17-.81-.46-1.11-.29-.29-.69-.46-1.11-.46Zm-2.38-6.28h1.17v1.27c0,.06.02.12.07.17.04.04.1.07.17.07h1.95c.06,0,.12-.02.17-.07.04-.04.07-.1.07-.17v-1.27h1.17v3.05h-4.76v-3.05Zm5.96,3.52v.91H3.32v-.91h7.15ZM.57,4.79h.43v.81c0,.06.02.12.07.17.04.04.1.07.17.07h.95c.06,0,.12-.02.17-.07.04-.04.07-.1.07-.17v-.81h.43v8.44H.57V4.79Zm5.23,8.44v-2.47c0-.29.12-.57.32-.78.21-.21.48-.32.78-.32s.57.12.78.32c.21.21.32.48.32.78v2.47h-2.19Zm7.42,0h-2.28V4.79h.43v.81c0,.06.02.12.07.17.04.04.1.07.17.07h.95c.06,0,.12-.02.17-.07.04-.04.07-.1.07-.17v-.81h.43v8.44Z" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "0 0 14 14",
    };
  }
};

Cultural.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Cultural.defaultProps = { role: "img" };
