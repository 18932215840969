import { RadioButton } from "@swa-ui/core";

import { withFormField } from "../withFormField";

/**
 * RadioButtonFormField provides support for a standard Radio component to be used in a Form.
 */

export const RadioButtonFormField = withFormField(RadioButton, {
  useController: true,
});
