import * as yup from "yup";

import {
  getPhoneCountryCodeFormFieldSchema,
  PHONE_COUNTRY_CODE_SCHEMA_FIELD_NAMES,
} from "../PhoneCountryCodeFormField/PhoneCountryCodeFormField.schema";
import {
  getPhoneNumberFormFieldSchema,
  PHONE_NUMBER_SCHEMA_FIELD_NAMES,
} from "../PhoneNumberFormField/PhoneNumberFormField.schema";
import {
  getPhoneTypeFormFieldSchema,
  PHONE_TYPE_SCHEMA_FIELD_NAMES,
} from "../PhoneTypeFormField/PhoneTypeFormField.schema";

export const PHONE_SCHEMA_FIELD_NAMES = {
  ...PHONE_COUNTRY_CODE_SCHEMA_FIELD_NAMES,
  ...PHONE_NUMBER_SCHEMA_FIELD_NAMES,
  ...PHONE_TYPE_SCHEMA_FIELD_NAMES,
};

export const getPhoneFormFieldsSchema = (options = {}) => {
  const phoneNumberFieldName =
    options.phoneNumberFieldName ?? PHONE_NUMBER_SCHEMA_FIELD_NAMES.PHONE_NUMBER;
  const phoneCountryCodeFieldName =
    options.phoneCountryCodeFieldName ?? PHONE_COUNTRY_CODE_SCHEMA_FIELD_NAMES.PHONE_COUNTRY_CODE;
  const phoneTypeFieldName = options.phoneTypeFieldName ?? PHONE_TYPE_SCHEMA_FIELD_NAMES.PHONE_TYPE;

  return {
    ...(options.showPhoneType && {
      [phoneTypeFieldName]: getPhoneTypeFormFieldSchema(),
    }),
    [phoneCountryCodeFieldName]: getPhoneCountryCodeFormFieldSchema(),
    [phoneNumberFieldName]: yup
      .string()
      .trim()
      .when(phoneCountryCodeFieldName, {
        is: (value) => `${value}` === "1",
        otherwise: getPhoneNumberFormFieldSchema(),
        then: getPhoneNumberFormFieldSchema({ domestic: true }),
      }),
  };
};
