import { window } from "@swa-ui/browser";
import { dateFormats, swaDate } from "@swa-ui/date";

import { FLIGHT_CAR, FLIGHT_HOTEL } from "../defines/packageType";

function base64EncodeString(string) {
  const toSolidBytes = (match, p1) => {
    const charCode = `0x${p1}`;

    return String.fromCharCode(charCode);
  };

  return window.btoa(encodeURIComponent(string).replace(/%([0-9A-F]{2})/g, toSolidBytes));
}

export const base64Encoder = (formData) => {
  const {
    departureDate,
    destinationAirportCode,
    originationAirportCode,
    packageType,
    promoCode,
    returnDate,
    travelers,
  } = formData;
  let adults = 0;
  let children = 0;
  let lapChildren = 0;

  travelers.forEach((room) => {
    adults += room.adults;
    children += room.children;
    lapChildren += room.lapChildren;
  });

  const submittedFormData = {
    destination: {
      locationByCode: {
        code: destinationAirportCode,
        context: "LOCATION_ID",
      },
    },
    flight: {
      departureDate: swaDate(departureDate).format(dateFormats.iso),
      returnDate: swaDate(returnDate).format(dateFormats.iso),
    },
    origin: {
      locationByCode: {
        code: originationAirportCode,
        context: "IATA",
      },
    },
    passengerCounts: [
      {
        passengerType: "ADT",
        count: adults,
      },
      {
        passengerType: "CHD",
        count: children,
      },
      {
        passengerType: "INF",
        count: lapChildren,
      },
    ],
    promotionCode: promoCode,
  };

  if (packageType !== FLIGHT_CAR) {
    submittedFormData.hotel = {
      rooms: [],
    };

    travelers.forEach((room) => {
      const newRoom = {
        adults: 0,
        children: [],
      };

      newRoom.adults = room.adults;

      room.childrenAge.forEach((age) => {
        newRoom.children.push({ age: age });
      });

      if (room.lapChildren) {
        newRoom.infants = room.lapChildren;
      }

      submittedFormData.hotel.rooms.push(newRoom);
    });
  }

  if (packageType !== FLIGHT_HOTEL) {
    submittedFormData.car = {};
  }

  return base64EncodeString(JSON.stringify(submittedFormData));
};
