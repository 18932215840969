import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import { window } from "@swa-ui/browser";
import { classNames } from "@swa-ui/string";

import { List, listPropTypes } from "../List";
import { Paginator, paginatorPropTypes } from "../Paginator";

const DELAY_FOR_BROWSER = 16;
const NO_SCROLL = -999;
const TRANSITION_FROM_LEFT = 50;
const TRANSITION_FROM_RIGHT = 50;
const TRANSITION_IN_DURATION = 200;
const TRANSITION_INIT_DURATION = 500;
const TRANSITION_INITIAL_DURATION = 300;

/**
 * ListPaginator integrates the Paginator with the List component to allow items to be displayed in "chunks".
 */

export const ListPaginator = (props) => {
  const {
    animateInitialRender,
    ariaLabelInput,
    ariaLabelNext,
    ariaLabelPrevious,
    ariaMessageFirst,
    ariaMessageLast,
    className,
    defaultPageNumber,
    items,
    maxItemsToDisplay,
    onPageChange,
    pageLabel,
    pageNumber,
    scrollToTopOffset,
    separatorLabel,
  } = props;
  const [currentItems, setCurrentItems] = useState(items);
  const [currentPageNumber, setCurrentPageNumber] = useState(pageNumber || defaultPageNumber);
  const [currentTransitionDirection, setCurrentTransitionDirection] = useState("init");
  const ref = useRef();

  useEffect(() => {
    setCurrentItems(items);
    setPage();
  }, [items]);

  useEffect(() => {
    setPage();
  }, [pageNumber]);

  return (
    <div className={classNames(className)} ref={ref}>
      <List {...getListProps()} />
      <Paginator {...getPaginatorProps()} />
    </div>
  );

  function getListProps() {
    const transitionIn = {
      duration: TRANSITION_IN_DURATION,
      transformations: [
        { action: "opacity", amount: "1" },
        { action: "translateX", amount: "0%" },
      ],
    };
    const init = {
      duration: TRANSITION_INIT_DURATION,
      transformations: [
        { action: "opacity", amount: "1" },
        { action: "translateX", amount: "0%" },
      ],
    };
    const transitionInitial = {
      duration: TRANSITION_INITIAL_DURATION,
      transformations: [
        { action: "opacity", amount: "0" },
        { action: "translateX", amount: "0%" },
      ],
    };
    const slideInFromLeft = {
      duration: TRANSITION_FROM_LEFT,
      transformations: [
        { action: "opacity", amount: "0" },
        { action: "translateX", amount: "-75%" },
      ],
    };
    const slideInFromRight = {
      duration: TRANSITION_FROM_RIGHT,
      transformations: [
        { action: "opacity", amount: "0" },
        { action: "translateX", amount: "75%" },
      ],
    };
    const transitions = {
      init,
      refresh: transitionInitial,
      slideInFromLeft,
      slideInFromRight,
    };

    return {
      animateInitialRender,
      animationToken: currentPageNumber,
      items: currentItems,
      maxItemsToDisplay,
      startingIndex: (currentPageNumber - 1) * maxItemsToDisplay,
      transitionIn,
      transitionInitial: animateInitialRender ? transitionInitial : undefined,
      transitionOut: transitions[currentTransitionDirection],
    };
  }

  function getPaginatorProps() {
    return {
      ariaLabelInput,
      ariaLabelNext,
      ariaLabelPrevious,
      ariaMessageFirst,
      ariaMessageLast,
      maxItemsToDisplay,
      numberOfItems: items?.length,
      onPageChange: handlePageChange,
      pageLabel,
      pageNumber: currentPageNumber,
      separatorLabel,
    };
  }

  function setPage() {
    setCurrentPageNumber(Math.min(pageNumber || 1, getMaxPageNumber()));
    setCurrentTransitionDirection("refresh");
  }

  function handlePageChange(newPageNumber) {
    setCurrentTransitionDirection(
      newPageNumber < currentPageNumber ? "slideInFromLeft" : "slideInFromRight"
    );

    setCurrentPageNumber(newPageNumber);
    onPageChange && onPageChange(newPageNumber);

    if (scrollToTopOffset !== NO_SCROLL) {
      const topOfList = cumulativeOffset(ref.current).top + scrollToTopOffset;

      window.setTimeout(
        () => window.scrollTo({ behavior: "smooth", left: 0, top: topOfList }),
        DELAY_FOR_BROWSER
      );
    }
  }

  function getMaxPageNumber() {
    return (
      Math.floor(items.length / maxItemsToDisplay) + (items.length % maxItemsToDisplay ? 1 : 0)
    );
  }

  // TODO move this to swa-ui-browser PHX-388
  function cumulativeOffset(element) {
    let left = 0;
    let top = 0;

    do {
      top += element.offsetTop || 0;
      left += element.offsetLeft || 0;
      element = element.offsetParent;
    } while (element);

    return {
      left: left,
      top: top,
    };
  }
};

ListPaginator.propTypes = {
  /** Indicator that the list will be show via transition when first rendered. The change/idle props will be used. */
  animateInitialRender: listPropTypes.animateInitialRender,

  /** Either a function that takes the max page number and returns aria-label text, or simply the aria-label text itself, to provide additional accessibility description of input element. */
  ariaLabelInput: PropTypes.oneOfType([PropTypes.func, PropTypes.string]).isRequired,

  /** aria-label text to be applied to next button element. */
  ariaLabelNext: PropTypes.string.isRequired,

  /** aria-label text to be applied to previous button element. */
  ariaLabelPrevious: PropTypes.string.isRequired,

  /** aria-label text to be read when the first page is reached. */
  ariaMessageFirst: PropTypes.string.isRequired,

  /** aria-label text to be read when the last page is reached. */
  ariaMessageLast: PropTypes.string.isRequired,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Page that will be initially displayed. */
  defaultPageNumber: paginatorPropTypes.defaultPageNumber,

  /**
   * Complete list of items to be rendered. The items rendered at a given time is determined by the current page
   * number and maxItemsToDisplay.
   */
  items: listPropTypes.items,

  /** Total number of items visible at a time. */
  maxItemsToDisplay: listPropTypes.maxItemsToDisplay,

  /**
   * Optional callback to be informed when a new page is displayed. The new page number will be the only returned
   * argument.
   */
  onPageChange: paginatorPropTypes.onPageChange,

  /** Label to indicate page number. Currently it is used as the text for "Page" in this label: "Page xx of yy". */
  pageLabel: paginatorPropTypes.pageLabel,

  /**
   * Optional page number that can be controlled by the caller. If not given, Paginator will use its own internal
   * page number.
   */
  pageNumber: paginatorPropTypes.pageNumber,

  /**
   * When paginating, the list will scroll to the top of the list so new items can be more easily seen. Additionally
   * a positive or negative value can adjust where the scroll position will be. If the value is -999, no scrolling
   * will occur.
   */
  scrollToTopOffset: PropTypes.number,

  /**
   * Label to help denote the current page number and total number of pages. Currently it is used as the text for "of"
   * in this label: "Page xx of yy".
   */
  separatorLabel: paginatorPropTypes.separatorLabel,
};

ListPaginator.defaultProps = {
  defaultPageNumber: 1,
  maxItemsToDisplay: 10,
  scrollToTopOffset: NO_SCROLL,
};
