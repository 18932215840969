import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import { classNames } from "@swa-ui/string";

import { Area } from "../Area";
import { NumberSelector } from "../NumberSelector";
import { SelectionMarker } from "../SelectionMarker";
import styles from "./NumberSelectorGroup.module.scss";

const INITIALIZE_STEP_BEFORE = 0;
const INITIALIZE_STEP_INITIALIZING = 1;
const INITIALIZE_STEP_INITIALIZED = 0;
const PADDING = 0;

/**
 * NumberSelectorGroup renders a list of NumberSelectors, and provides additional logic like
 * accumulating totals around all NumberSelectors in group.
 */
export const NumberSelectorGroup = (props) => {
  const { className, items, maxCount, onChange, size = "large" } = props;
  const [readyToInit, setReadyToInit] = useState(INITIALIZE_STEP_BEFORE);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [workingItems, setWorkingItems] = useState([]);
  const containerRef = useRef();
  const itemContainerRefs = [...Array(items.length)].map(() => useRef());
  const numberSelectorRefs = [...Array(items.length)].map(() => useRef());

  useEffect(() => {
    readyToInit === INITIALIZE_STEP_INITIALIZING && setReadyToInit(INITIALIZE_STEP_INITIALIZED);
  }, [readyToInit]);

  useEffect(() => {
    setWorkingItems(
      items.map((item) => {
        const {
          adjunctContent,
          adjunctContentRevealed,
          key,
          maximumValue,
          title,
          titleCaption,
          value,
        } = item;

        return {
          adjunctContent,
          adjunctContentRevealed,
          key,
          maximumValue,
          title,
          titleCaption,
          value,
        };
      })
    );
  }, [items]);

  useEffect(() => {
    readyToInit === INITIALIZE_STEP_BEFORE && setReadyToInit(INITIALIZE_STEP_INITIALIZING);
  }, [workingItems]);

  return (
    <div {...getProps()}>
      {size === "large" && isInitialized() && <SelectionMarker {...getSelectionMarkerProps()} />}
      <ul className={getListClass()}>{workingItems.map(renderNumberSelector)}</ul>
    </div>
  );

  function renderNumberSelector(item, index) {
    const { adjunctContent, key } = item;

    return (
      <li {...getContainerProps(index)} key={key}>
        <div className={getContainerClass()} ref={itemContainerRefs[index]}>
          <div className={styles.content}>
            <div className={classNames(styles.title, { [styles.small]: size === "small" })}>
              {item.title}
            </div>
            <div className={styles.titleCaption}>{item.titleCaption}</div>
          </div>
          <NumberSelector {...getNumberSelectorProps(item, index)} />
        </div>
        <div className={styles.break} />
        {adjunctContent && renderAdjunct(item)}
      </li>
    );
  }

  function renderAdjunct(item) {
    const { adjunctContent, adjunctContentRevealed } = item;

    return <Area {...getAreaProps(adjunctContentRevealed)}>{adjunctContent}</Area>;
  }

  function getProps() {
    return {
      className: classNames(styles.numberSelectorGroup, className),
      ref: containerRef,
    };
  }

  function getContainerProps(index) {
    return {
      onClick: () => {
        selectedIndex !== index && numberSelectorRefs[index].current.focus();
        setSelectedIndex(index);
      },
      onMouseEnter: () => setSelectedIndex(index),
    };
  }

  function getAreaProps(adjunctContentRevealed) {
    return { revealed: adjunctContentRevealed };
  }

  function getSelectionMarkerProps() {
    const itemRef = itemContainerRefs[selectedIndex].current;
    const boundingRect = itemRef.getBoundingClientRect();
    const { height, y } = boundingRect;
    const yOffset = y - getYoffsetContainer();

    return {
      className: styles.selectionMarker,
      height,
      yOffset: yOffset + PADDING,
    };
  }

  function isInitialized() {
    return itemContainerRefs?.[0]?.current;
  }

  function getYoffsetContainer() {
    return containerRef.current.getBoundingClientRect().y;
  }

  function getNumberSelectorProps(item, index) {
    const { maximumValue = 9, value: itemValue } = item;

    return {
      "aria-label-decrement": "dec",
      "aria-label-increment": "inc",
      "aria-label-value": "value",
      disableIncrement: maxCount && getTotalCount() >= maxCount,
      maximumValue: Math.min(
        maximumValue,
        maxCount ? itemValue + maxCount - getTotalCount() : maximumValue
      ),
      onChange: handleChange.bind(this, index),
      onFocus: () => setSelectedIndex(index),
      ref: numberSelectorRefs[index],
      size,
      value: itemValue,
    };
  }

  function getListClass() {
    return classNames(styles.list, { [styles.small]: size === "small" });
  }

  function getContainerClass() {
    return classNames(styles.container, {
      [styles.small]: size === "small",
    });
  }

  function handleChange(index, value) {
    const working = [...workingItems];

    working[index].value = value;
    setWorkingItems(working);
    onChange?.(working.map((item) => item.value));
  }

  function getTotalCount() {
    return workingItems.reduce((partialSum, item) => partialSum + item.value, 0);
  }
};

export const numberSelectorGroupPropTypes = {
  /**
   * Additional classes for positioning the component. Given classes may only position this component
   * for layout purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * NumberSelectors to be displayed. The title props indicate what will be rendered to the left of
   * the number selectors.
   */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      adjunctContent: PropTypes.node,
      adjunctContentRevealed: PropTypes.bool,
      key: PropTypes.string.isRequired,
      title: PropTypes.node,
      titleCaption: PropTypes.node,
    })
  ).isRequired,

  /**
   * Maximum value that can be selected across all NumberSelector. If the total of values reaches
   * this maximum, then all increase buttons will be disabled.
   */
  maxCount: PropTypes.number,

  /** A function that updates the value when a value change is made. */
  onChange: PropTypes.func,
};

NumberSelectorGroup.propTypes = numberSelectorGroupPropTypes;
