import { useContext } from "react";

import { DeviceInfoContext } from "../DeviceInfoProvider";

/**
 * Hook used to expose the screen size provided by the DeviceInfoContext.
 */

export const useScreenSize = () => {
  const { isLargeOrXlarge, isSmallOrMedium, screenSize } = useContext(DeviceInfoContext);

  return {
    isLargeOrXlarge,
    isSmallOrMedium,
    screenSize,
  };
};
