import PropTypes from "prop-types";
import React from "react";

import { AppFooter, SuppressForNative } from "@swa-ui/application";
import { useDeviceInfo } from "@swa-ui/browser";
import { DotcomFooter } from "@swa-ui/dotcom";

/**
 * LandingPageFooter will render the footer for static html pages.
 */

export const LandingPageFooter = (props) => {
  const { className } = props;
  const { isLargeOrXlarge } = useDeviceInfo();

  return (
    <SuppressForNative>
      <div className={className}>
        <AppFooter />
        <DotcomFooter type={getNavigationType()} />
      </div>
    </SuppressForNative>
  );

  function getNavigationType() {
    return isLargeOrXlarge ? "desktop" : "mobile";
  }
};

LandingPageFooter.propTypes = {
  /**
   * Additional classes for positioning the component.
   */
  className: PropTypes.string,
};
