import PropTypes from "prop-types";
import React from "react";

import { classNames } from "@swa-ui/string";

import styles from "./Separator.module.scss";

const DOT_RATIO = 3;
const LARGE = 4;
const MEDIUM = 2;
const SEPARATOR_FG_DESIGN_TOKEN = "cmp-core-color-separator-fg";
const SMALL = 1;

/**
 * Simple component to render a horizontal or vertical line with options to control line's
 * thickness and its end caps.
 */

export const Separator = (props) => {
  const { className, color, lineCap, orientation, weight } = props;

  return (
    <div className={getClass()} role="separator">
      {lineCap === "dot" ? <div {...getDotProps()} /> : null}
      <div {...getLineProps()} />
      {lineCap === "dot" ? <div {...getDotProps()} /> : null}
    </div>
  );

  function getLineProps() {
    const size = `${getWeight()}px`;

    return {
      className: styles.line,
      style: {
        "--separator-color": `var(--${color})`,
        "--separator-radius": lineCap === "round" && size,
        "--separator-weight": size,
      },
    };
  }

  function getDotProps() {
    return {
      className: styles.lineCapDot,
      style: {
        "--separator-color": `var(--${color})`,
        "--separator-dot-weight": `${getWeight() * DOT_RATIO}px`,
      },
    };
  }

  function getClass() {
    return classNames(className, styles.separator, {
      [styles.vertical]: orientation === "vertical",
    });
  }

  function getWeight() {
    return weight === "small" ? SMALL : weight === "medium" ? MEDIUM : LARGE;
  }
};

Separator.propTypes = {
  /**
   * Additional classes for positioning the component. Given classes may only position this component
   * for layout purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * Color for separator should be a theme definition such as primary-accordion-label, or a
   * standard color definition like success-300. A hex value will also work, but is discouraged.
   */
  color: PropTypes.string,

  /** Specify how separator ends will look. */
  lineCap: PropTypes.oneOf(["butt", "dot", "round"]),

  /** Direction of separator. */
  orientation: PropTypes.oneOf(["horizontal", "vertical"]),

  /** Definition of separator's thickness. */
  weight: PropTypes.oneOf(["large", "medium", "small"]),
};

Separator.defaultProps = {
  color: SEPARATOR_FG_DESIGN_TOKEN,
  lineCap: "round",
  orientation: "horizontal",
  weight: "medium",
};
