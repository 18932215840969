import PropTypes from "prop-types";
import React from "react";

export const Calendar = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <rect strokeWidth="0" x="8" y="14.13" width="4" height="4" rx="1" />
      <rect strokeWidth="0" x="14" y="14.13" width="4" height="4" rx="1" />
      <rect strokeWidth="0" x="20" y="14.13" width="4" height="4" rx="1" />
      <rect strokeWidth="0" x="8" y="20" width="4" height="4" rx="1" />
      <rect strokeWidth="0" x="14" y="20" width="4" height="4" rx="1" />
      <rect strokeWidth="0" x="20" y="20" width="4" height="4" rx="1" />
      <path
        strokeWidth="0"
        d="M26.5,6H24V7a2,2,0,0,1-4,0V6H12V7A2,2,0,0,1,8,7V6H5.5A1.5,1.5,0,0,0,4,7.51v19A1.51,1.51,0,0,0,5.5,28h21A1.51,1.51,0,0,0,28,26.5v-19A1.5,1.5,0,0,0,26.5,6ZM25,26H7a1,1,0,0,1-1-1V13a1,1,0,0,1,1-1H25a1,1,0,0,1,1,1V25A1,1,0,0,1,25,26Z"
      />
      <path strokeWidth="0" d="M22,8a1,1,0,0,0,1-1V5a1,1,0,1,0-2,0V7A1,1,0,0,0,22,8Z" />
      <path strokeWidth="0" d="M10,8a1,1,0,0,0,1-1V5A1,1,0,0,0,9,5V7A1,1,0,0,0,10,8Z" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      style,
      viewBox: "0 0 32 32",
    };
  }
};

Calendar.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Calendar.defaultProps = {
  role: "img",
};
