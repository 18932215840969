import PropTypes from "prop-types";
import React from "react";

import { Content, contentPropTypes } from "@swa-ui/core";

import { HtmlValue } from "../HtmlValue";
import { isHtmlValue } from "../isHtmlValue";

export const TextContent = (props) => {
  const { color, fontSize, fontSizing, fontWeight, size, value } = props || {};

  return renderContent();

  function renderContent() {
    let content;

    if (value) {
      if (isHtmlValue(value)) {
        content = <HtmlValue htmlValue={value} />;
      } else {
        content = <div {...getTextProps()}>{value}</div>;
      }

      if (size) {
        content = <Content {...getContentProps()}>{content}</Content>;
      }
    }

    return content;
  }

  function getContentProps() {
    return {
      fontSizing,
      isContainer: false,
      size,
    };
  }

  function getTextProps() {
    return {
      style: {
        color,
        fontSize,
        fontWeight,
      },
    };
  }
};

TextContent.propTypes = {
  /** Color of the text. */
  color: PropTypes.string,

  /** Font size of the text in rem, em, px, % etc. */
  fontSize: PropTypes.string,

  /** Refer @swa-ui/core/Content for prop description. */
  fontSizing: contentPropTypes.fontSizing,

  /** Font weight of the text. */
  fontWeight: PropTypes.string,

  /** Refer @swa-ui/core/Content for prop description. */
  size: contentPropTypes.size,

  /** Text content for placement. */
  value: PropTypes.string.isRequired,
};

TextContent.defaultProps = {
  fontSizing: "snapToGrid",
};
