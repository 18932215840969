import React from "react";

import { ObjectVisual } from "../ObjectVisual";
import styles from "./VersionInformation.module.scss";

export const VersionInformation = (props) => {
  const { versionInformation } = props;

  return (
    <div className={styles.container}>
      <ObjectVisual object={versionInformation} showBraces={false} />
    </div>
  );
};
