import PropTypes from "prop-types";
import React from "react";

export const CheckIn = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <polygon points="25.6,16.4 17.5,24.5 14.1,21.2 11.3,24 17.5,30.2 28.6,19.3 	" />
      <path d="M18.9,36.2c-7.6,0-13.7-6.1-13.7-13.7S11.3,8.8,18.9,8.8S32.6,15,32.6,22.5C32.6,30.1,26.5,36.2,18.9,36.2 M18.9,4.9c-9.7,0-17.6,7.9-17.6,17.6s7.9,17.6,17.6,17.6s17.6-7.9,17.6-17.6S28.7,4.9,18.9,4.9" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "1 4 36 36.5",
    };
  }
};

CheckIn.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

CheckIn.defaultProps = {
  role: "img",
};
