import { window } from "../window";

/**
 * Retrieves the value of a specified search parameter from the current URL's query string.
 *
 * @param {string} searchParam - The name of the search parameter to retrieve.
 * @returns {string|null} The value of the search parameter if it exists, otherwise `null`.
 */

export const getSearchParam = (searchParam) => {
  const searchParams = new window.URLSearchParams(window?.location?.search);

  return searchParams.get(searchParam);
};
