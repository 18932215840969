import React, { useEffect, useState } from "react";

import { window } from "@swa-ui/browser";
import { setLang } from "@swa-ui/locale";

/** The VisualizeI18n component enables the ability to press Shift + F8 to toggle between seeing
 * text of the screen, processed by the i18n mechanism, or to visualize the corresponding i18n keys.
 */
export const VisualizeI18n = (props) => {
  const [visualizeKeys, setVisualizeKeys] = useState(false);
  const langToggle = ["global", "visualize"]; // assume language is set to historic value "global"
  const activeI18nLang = langToggle[Number(visualizeKeys)];

  useEffect(() => {
    // Removing sonar for this line due to sonar defect, addressed in newer sonar versions.
    window.addEventListener("keyup", visualizeI18nKeysOnShiftF8); // NOSONAR

    return () => {
      window.removeEventListener("keyup", visualizeI18nKeysOnShiftF8);
    };
  }, [visualizeKeys]);

  useEffect(() => {
    setLang(activeI18nLang);
  }, [visualizeKeys]);

  return <React.Fragment key={`i18n-${activeI18nLang}`}>{props.children}</React.Fragment>;

  function visualizeI18nKeysOnShiftF8(event) {
    if (event.keyCode === 119 && event.shiftKey) {
      setVisualizeKeys(!visualizeKeys);
    }
  }
};
