import PropTypes from "prop-types";
import { useEffect, useRef } from "react";
import ResizeObserver from "resize-observer-polyfill";

/**
 * This custom hook will use the passed callback to inform the parent of useResizeObserver if its content has changed in
 * size.
 *
 * Much of this code was derived from Eyas Mattare's article:
 * https://medium.com/@eymaslive/react-hooks-useobserve-use-resizeobserver-custom-hook-45ec95ad9844
 */

export const useResizeObserver = (props) => {
  const { callback, element } = props;
  const current = element && element.current;
  const observer = useRef(null);

  useEffect(() => {
    if (observer && observer.current && current) {
      observer.current.unobserve(current);
    }

    observer.current = new ResizeObserver(callback);
    observe();

    return () => {
      if (observer && observer.current && element && element.current) {
        observer.current.unobserve(element.current);
      }
    };
  }, [current]);

  function observe() {
    if (element && element.current && observer.current) {
      observer.current.observe(element.current);
    }
  }
};

useResizeObserver.propTypes = {
  /**
   * Optional callback to be informed of content size changes. The argument passed to this callback is from the
   * ResizeObserver. Look at ResizeObserver documentation to learn what is passed.
   */
  callback: PropTypes.func,

  /** DOM element to be observed. This values should be a ref which is given to the content. */
  element: PropTypes.object,
};
