import PropTypes from "prop-types";
import React from "react";

export const Error = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path
        d="M17.5,27.8c-1.5,0-2.6-1.2-2.6-2.5s1.1-2.5,2.6-2.5s2.6,1.1,2.6,2.5C20,26.6,18.9,27.8,17.5,27.8 M19.9,7.2
		l-0.8,13.4h-3.3L15.1,7.2H19.9z M17.3,0C7.7,0.1-0.1,7.9,0,17.6C0.1,27.2,7.9,35,17.6,34.9c9.6,0,17.4-7.9,17.3-17.6
		C34.9,7.7,27-0.1,17.3,0"
      />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "0 0 34.9 34.9",
    };
  }
};

Error.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Error.defaultProps = {
  role: "img",
};
