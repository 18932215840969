export function getResponseErrors(response) {
  const { status, data: responseData } = response;
  const serviceData = responseData?.data ?? responseData ?? response ?? {};
  let responseErrors = [];

  if (status === 207 && Array.isArray(serviceData)) {
    responseErrors = serviceData.map(getResponseErrors).flat();
  } else {
    const { code, errors = [], notifications = [] } = serviceData;
    const fieldErrors = notifications?.fieldErrors ?? [];
    const formErrors = notifications?.formErrors ?? [];

    responseErrors = [...(code ? [{ code }] : [])]
      .concat(errors)
      .concat(formErrors.map((error) => ({ code: error.trackingCode })))
      .concat(fieldErrors)
      .filter(isNotUndefinedNull);
  }

  return responseErrors;
}

export function isNotUndefinedNull(index) {
  return index !== undefined && index !== null;
}
