import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import { useDeviceInfo } from "@swa-ui/browser";
import { useDesignTokensTheme } from "@swa-ui/design-tokens";
import { classNames } from "@swa-ui/string";

import { Area } from "../Area";
import { AriaLive } from "../AriaLive";
import { CloseButton } from "../CloseButton";
import { headingPropTypes } from "../Heading";
import { Icon } from "../Icon";
import { Section } from "../Section";
import styles from "./Message.module.scss";

/**
 * Message provides a way to inform the user of new information. The information can be presented for
 * several intentions like warning, error and success.
 *
 * By default, the close button will collapse the message by shrinking and fading it, but can be
 * overridden via onClose callback.
 */

const MESSAGE_ICON_FG_DESIGN_TOKEN = "cmp-core-color-message-fg";
const MESSAGE_ICON_BG_DESIGN_TOKEN = "cmp-core-color-message-bg";

export const Message = (props) => {
  const {
    animateInitialRender = true,
    children,
    className,
    closeAvailable = true,
    headingProps,
    on,
    onClose,
    role,
    showClose,
    styleType = "information",
    width = "full-width",
  } = props;
  const { screenSize } = useDeviceInfo();
  // TODO: Assess usage of message styleTypes and consolidate to match design tokens (PHX-1647)
  const adaptedStyles = {
    caution: "caution",
    danger: "danger",
    error: "danger",
    information: "information",
    neutral: "neutral",
    primary: "information",
    secondary: "caution",
    success: "success",
    tertiary: "neutral",
    warning: "caution",
  };
  const adaptedIconNames = {
    caution: "Info",
    danger: "Exclamation",
    error: "Exclamation",
    information: "Info",
    neutral: "Info",
    primary: "Info",
    secondary: "Info",
    success: "Check",
    tertiary: "Info",
    warning: "Info",
  };

  const adaptedStyle = adaptedStyles[styleType];
  const iconName = adaptedIconNames[styleType];
  const [revealed, setRevealed] = useState(on);
  const { themeClass } = useDesignTokensTheme({
    component: "message",
    styleType: adaptedStyle,
  });
  const headingRef = useRef();
  const ref = useRef();

  useEffect(() => {
    setRevealed(true);
  }, [on]);

  return (
    <Area {...getProps()}>
      <div {...getContentProps()}>
        <AriaLive>{getAriaLiveContent()}</AriaLive>
        {renderIcon()}
        {headingProps?.children && renderSection()}
        {!headingProps?.children && renderContentNoHeading()}
      </div>
    </Area>
  );

  function renderIcon() {
    const hasInvertedStyle = ["caution", "information", "neutral"].includes(adaptedStyle);
    const shouldShrink = adaptedStyle === "success" || adaptedStyle === "danger";
    const backgroundColor = hasInvertedStyle ? "transparent" : MESSAGE_ICON_FG_DESIGN_TOKEN;
    const iconColor = hasInvertedStyle
      ? MESSAGE_ICON_FG_DESIGN_TOKEN
      : MESSAGE_ICON_BG_DESIGN_TOKEN;
    const iconSizes = {
      large: 28,
      medium: 24,
      small: 20,
      xlarge: 28,
    };
    const iconSize = iconSizes[screenSize];

    function getIconProps() {
      return {
        background: { color: backgroundColor, type: "circle" },
        className: styles.icon,
        color: iconColor,
        name: iconName,
        ...(shouldShrink ? { shrink: 0.7 } : {}),
        size: `size${iconSize}`,
        transparentBorder: false,
      };
    }

    return (
      <div {...getIconBackgroundProps()}>
        <Icon {...getIconProps()} />
      </div>
    );
  }

  function renderSection() {
    return (
      <Section {...getSectionProps()}>
        <div ref={ref}>{children}</div>
      </Section>
    );
  }

  function renderContentNoHeading() {
    return (
      <div className={styles.contentContainer}>
        {shouldShowClose() && <CloseButton {...getCloseButtonProps()} />}
        <div className={styles.childrenNoHeading} ref={ref}>
          {children}
        </div>
      </div>
    );
  }

  function renderHeadingChildren() {
    return (
      <div className={styles.headingContainer}>
        {headingProps.children}
        {shouldShowClose() && <CloseButton {...getCloseButtonProps()} />}
      </div>
    );
  }

  function getProps() {
    return {
      animate: (animateInitialRender && revealed) || !revealed,
      className: getClass(),
      revealed,
    };
  }

  function getCloseButtonProps() {
    return {
      "aria-label": props["aria-label-close"],
      onClick: handleClick,
    };
  }

  function getIconBackgroundProps() {
    return {
      className: classNames(styles.iconBackground, {
        [styles.noChildrenIcon]: !children,
      }),
    };
  }

  function getSectionProps() {
    return {
      className: styles.section,
      headingProps: headingProps
        ? {
            ...headingProps,
            children: <div ref={headingRef}>{renderHeadingChildren()}</div>,
            className: styles.heading,
            spacing: "none",
            styleLevel: 3,
          }
        : undefined,
    };
  }

  function getContentProps() {
    return {
      "aria-label": props["aria-label"],
      className: classNames(styles.content, themeClass, {
        [styles.contentOnly]: !headingProps?.children,
        [styles.headingOnly]: !children,
      }),
      role,
    };
  }

  function getClass() {
    return classNames(className, {
      [styles.fullWidth]: width === "full-width",
      [styles.message]: true,
      [styles.widthExtraLarge]: width === "xlarge",
      [styles.widthLarge]: width === "large",
      [styles.widthMedium]: width === "medium",
      [styles.widthSmall]: width === "small",
    });
  }

  function handleClick(event) {
    setRevealed(false);
    onClose && onClose(event);
  }

  function getAriaLiveContent() {
    return ref.current && getTextContent();
  }

  function getTextContent() {
    return [headingRef?.current?.textContent, ref?.current?.textContent].filter(Boolean).join(" ");
  }

  function shouldShowClose() {
    return showClose !== undefined ? showClose : closeAvailable;
  }
};

export const messagePropTypes = {
  /**
   * True indicates that the initial render will be animated. When the message is closed via close
   * button, the collapse will always be animated.
   */
  animateInitialRender: PropTypes.bool,

  /** aria-label text to provide additional accessibility description of content element */
  "aria-label": PropTypes.string,

  /** aria-label text to provide additional accessibility description of button element. */
  "aria-label-close": PropTypes.string,

  /** aria-live text to provide additional accessibility description for Message. */
  "aria-live": PropTypes.string,

  /** Content that will be rendered in heading. */
  children: PropTypes.node,

  /**
   * Additional classes for positioning the component. Given classes may only position this component
   * for layout purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * Render close button to dismiss modal. This prop will be removed in a future release. Use
   * showClose instead.
   */
  closeAvailable: PropTypes.bool,

  /** See Heading component for available options. */
  headingProps: PropTypes.shape(headingPropTypes),

  /** Indicator to say that the Message is visible. */
  on: PropTypes.bool,

  /** Optional callback to be informed when Toast is closed. */
  onClose: PropTypes.func,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Present close button to dismiss modal. */
  showClose: PropTypes.bool,

  /** The intent for the information presented can be defined by its styleType. */
  styleType: PropTypes.oneOf([
    "caution",
    "danger",
    "error",
    "information",
    "neutral",
    "primary",
    "secondary",
    "success",
    "tertiary",
    "warning",
  ]),

  /**
   * Width for InformationTip content. If a width is not given, the InformationTip width will be
   * governed by content.
   */
  width: PropTypes.oneOf(["full-width", "large", "medium", "small", "xlarge"]),
};

Message.propTypes = messagePropTypes;
