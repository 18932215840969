import PropTypes from "prop-types";
import React, { useContext } from "react";

import { getBootstrapData } from "@swa-ui/bootstrap";
import { window } from "@swa-ui/browser";
import { Button } from "@swa-ui/core";
import { encryptData, EncryptionContext } from "@swa-ui/encryption";

import { getPersistedVacationsBookingData } from "../vacationsPersistence";

export const UpdatePassengerInfoCallToAction = (props) => {
  const { ariaLabel, buttonType, children } = props;
  const { encrypt } = useContext(EncryptionContext);
  const urlData = getBootstrapData("urls");

  return <Button {...getProps()}>{children}</Button>;

  function getProps() {
    return {
      "aria-label": ariaLabel,
      onClick: handleClick,
      styleType: buttonType,
    };
  }

  async function handleClick() {
    const { airConfirmationNumber, passengerFirstName, passengerLastName } =
      getPersistedVacationsBookingData();
    const formDataToEncrypt = {
      CONFIRMATION_NUMBER: airConfirmationNumber,
      FIRST_NAME: passengerFirstName,
      LAST_NAME: passengerLastName,
    };

    const url = await encryptData(
      encrypt,
      formDataToEncrypt,
      urlData.AIR_VIEW_RESERVATION,
      "UpdatePassengerInfoCallToAction",
      "vacationUpdatePassengerInfo"
    );

    window.location.assign(url);
  }
};

UpdatePassengerInfoCallToAction.propTypes = {
  /** Mapped to aria-label of Button or Link component. */
  ariaLabel: PropTypes.string,

  /**
   * Mapped to styleType of Button component.
   * Standard Buttons will have 'primary' or 'button-primary' semantic values, while
   * buttonType with link values 'bright'/'dark'/'light' should render a Link.
   */
  buttonType: PropTypes.string,

  /** Content that will be rendered on the Button or Link component. */
  children: PropTypes.node,
};
