import PropTypes from "prop-types";
import React from "react";

import { Dialog } from "../Dialog";

/**
 * Displays Modal content centered in the window. If the content is too large to fit vertically,
 * then content will be positioned at the top of the page and the position will be set to absolute
 * to allow the window to be scrolled.
 *
 * ***** Modal will be deprecated in an upcoming version. Use Dialog instead. *****
 */

export const Modal = (props) => {
  const { children, modalRef } = props;

  return (
    <Dialog {...props} dialogRef={modalRef}>
      {children}
    </Dialog>
  );
};

Modal.propTypes = {
  /** aria-label text to provide accessibility description for Dialog. */
  "aria-label": PropTypes.string.isRequired,

  /** aria-label text to provide additional accessibility description of button element. */
  "aria-label-close": PropTypes.string,

  /** aria-live text to provide accessibility description for Modal. */
  "aria-live": PropTypes.string,

  /** Content for "main" element. */
  children: PropTypes.node.isRequired,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Allow modal to be dismissed when background veil is clicked. */
  closeOnBackgroundClick: PropTypes.bool,

  /**
   * ClassName that can be applied to the Modal's content. This is different than the className property which is
   * applied to the outer most element for positioning purposes.
   */
  contentClassName: PropTypes.string,

  /** Indicates if Modal should be position near the top of window with appropriate margins on left and right. */
  fullScreen: PropTypes.bool,

  /** Ref passed to allow useModal to close Modal on clicking outside of component. */
  modalRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.object })]),

  /** Callback to inform parent component when the overlay should be closed because the background was clicked. */
  onClose: PropTypes.func,

  /** Indicates if Modal content is visible. */
  revealed: PropTypes.bool,

  /** Present close button to dismiss modal. */
  showClose: PropTypes.bool,

  /**
   * Modals will be placed on a grid with specific widths matching the grid's column definitions. The columns occupied
   * will vary dependant on the device. The narrow prop will define the number of columns the modal will span.
   */
  width: PropTypes.oneOf(["medium", "large", "narrow", "small", "wide", "xlarge"]),
};

Modal.defaultProps = {
  closeOnBackgroundClick: false,
  revealed: false,
  showClose: true,
  width: "medium",
};
