import React from "react";

import { Grid, Link } from "@swa-ui/core";
import i18n from "@swa-ui/locale";
import { usePlacement } from "@swa-ui/placements";

import styles from "./DotcomMobileFooter.module.scss";

/**
 * DotcomMobileFooter provides the footer for small and medium screen sizes.
 */

export const DotcomMobileFooter = () => {
  const linkRows = usePlacement("footer")?.placement?.placement?.linkRows;
  const year = new Date().getFullYear();

  return (
    <Grid className={styles.layout}>
      <div className={styles.linkRows}>{renderLinkRows()}</div>
      <div className={styles.copyright}>{i18n("DotcomMobileFooter__COPYRIGHT", { year })}</div>
    </Grid>
  );

  function renderLinkRows() {
    return (
      Array.isArray(linkRows) &&
      linkRows.map((linkRow, index) => (
        <div className={styles.linkRow} key={index}>
          {renderLinkRow(linkRow)}
        </div>
      ))
    );
  }

  function renderLinkRow(linkRow) {
    return (
      Array.isArray(linkRow.linkListItems) &&
      linkRow.linkListItems.map((linkListItem, linkListItemIndex) =>
        renderLinkListItem(linkListItem, linkListItemIndex, linkRow)
      )
    );
  }

  function renderLinkListItem(linkListItem, linkListItemIndex, linkRow) {
    // React.Fragment must be used below because shorthand syntax (<>) does not support adding keys
    return (
      linkListItem.linkTarget &&
      linkListItem.linkText && (
        <React.Fragment key={linkListItemIndex}>
          <span key={linkListItem.linkTarget}>
            <Link href={linkListItem.linkTarget} emphasis={false} size="fontSize14">
              {linkListItem.linkText}
            </Link>
          </span>
          {linkListItemIndex < linkRow.linkListItems.length - 1 && (
            <span className={styles.separator}>|</span>
          )}
        </React.Fragment>
      )
    );
  }
};
