import PropTypes from "prop-types";
import React, { createContext } from "react";

import { window } from "@swa-ui/browser";
import { FetchInterceptor, getResponseCodes } from "@swa-ui/fetch";
import { usePersistedState } from "@swa-ui/persistence";

/**
 * MaintenanceProvider looks at channel api responses and determines if the maintenance code is present. The maintenance
 * status is supplied in a React context, so the status is available to components in an app's render tree.
 * Additionally, if a response contains the waiting room code, the current page is reloaded so Akamai can display the
 * waiting room page.
 *
 * isInMaintenance - Indicator if site should be in maintenance mode.
 */

export const MaintenanceContext = createContext();
export const MaintenanceProvider = (props) => {
  const { chapi, children, maintenanceCodes, waitingRoomCode } = props;
  const [maintenance, setMaintenance] = usePersistedState({
    defaultValue: { isInMaintenance: false },
    key: "maintenanceProvider-maintenance",
  });

  return (
    <FetchInterceptor onResponse={checkResponseForMaintenance}>
      <MaintenanceContext.Provider value={{ isInMaintenance: maintenance.isInMaintenance }}>
        {children}
      </MaintenanceContext.Provider>
    </FetchInterceptor>
  );

  function checkResponseForMaintenance(response) {
    if (chapi && response.url.includes(`/api/${chapi}/`)) {
      const errorCodes = getResponseCodes(response.data);

      if (errorCodes.includes(waitingRoomCode)) {
        window.location.reload();
      } else {
        setMaintenanceIfChanged(
          response.data?.meta?.maintenance_mode ??
            errorCodes.some((errorCode) => maintenanceCodes.includes(errorCode))
        );
      }
    }

    return response;
  }

  function setMaintenanceIfChanged(value) {
    if (maintenance.isInMaintenance !== value) {
      setMaintenance({ isInMaintenance: value });
    }
  }
};

MaintenanceContext.displayName = "MaintenanceContext";
MaintenanceProvider.propTypes = {
  /** Api name to retrieve maintenance state. This name will be something like 'travel-funds'. */
  chapi: PropTypes.string,

  /** Current page's content to be rendered inside MaintenanceProvider wrapper. */
  children: PropTypes.node.isRequired,

  /** Error codes provided by app to indicate site is in maintenance mode. */
  maintenanceCodes: PropTypes.arrayOf(PropTypes.string),

  /**
   * Code, typically provided by an application layer, that defines the tracking code to take site to the waiting
   * room. The code is known by Akamai, when detected, will render a waiting page when site cannot be produced due to
   * server issues.
   */
  waitingRoomCode: PropTypes.string,
};
