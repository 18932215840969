import globalDefines from "./gridDefines.module.scss";

export const grid = {
  largeColumnSpacing: globalDefines.largeGutterSpacing,
  largeNumCols: globalDefines.largeNumCols,
  largePadding: globalDefines.largePaddingHorizontal,
  largeRowSpacing: globalDefines.largeRowSpacing,

  mediumColumnSpacing: globalDefines.mediumGutterSpacing,
  mediumNumCols: globalDefines.mediumNumCols,
  mediumPadding: globalDefines.mediumPaddingHorizontal,
  mediumRowSpacing: globalDefines.mediumRowSpacing,

  smallColumnSpacing: globalDefines.smallGutterSpacing,
  smallNumCols: globalDefines.smallNumCols,
  smallPadding: globalDefines.smallPaddingHorizontal,
  smallRowSpacing: globalDefines.smallRowSpacing,

  xlargeColumnSpacing: globalDefines.xlargeGutterSpacing,
  xlargeNumCols: globalDefines.xlargeNumCols,
  xlargePadding: globalDefines.xlargePaddingHorizontal,
  xlargeRowSpacing: globalDefines.xlargeRowSpacing,
};

export const media = {
  largeMax: globalDefines.largeMax,
  largeMin: globalDefines.largeMin,
  mediumMax: globalDefines.mediumMax,
  mediumMin: globalDefines.mediumMin,
  smallMax: globalDefines.smallMax,
  smallMin: globalDefines.smallMin,
  xlargeMin: globalDefines.xlargeMin,
};
