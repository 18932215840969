import PropTypes from "prop-types";
import React from "react";

export const Wheelchair = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <g stroke="none">
        <path
          fill="none"
          d="M9.2,25.75a.67.67,0,0,0-.75.66.76.76,0,0,0,1.5,0A.73.73,0,0,0,9.2,25.75Z"
        />
        <path
          fill="none"
          d="M19.23,17.5a4.61,4.61,0,0,0-4.5,4.41,4.29,4.29,0,0,0,4.32,4.22,4.62,4.62,0,0,0,4.5-4.41A4.29,4.29,0,0,0,19.23,17.5Z"
        />
        <path d="M26.69,4H23.92a1.2,1.2,0,0,0-.75.28,1.54,1.54,0,0,0-.56.85L20.36,13H11.73a1.06,1.06,0,0,0-.75.28,1.29,1.29,0,0,0,.75,2.35h4a7.33,7.33,0,0,1,3.47-.94,7.54,7.54,0,0,1,3.19.75L25,6.72h1.73A1.28,1.28,0,0,0,28,5.41,1.33,1.33,0,0,0,26.69,4Z" />
        <path d="M19.23,15.63a6.65,6.65,0,0,0-6.47,6.28,6.09,6.09,0,0,0,6.19,6,6.58,6.58,0,0,0,6.48-6.28A6.09,6.09,0,0,0,19.23,15.63Zm-.18,10.5a4.29,4.29,0,0,1-4.32-4.22,4.61,4.61,0,0,1,4.5-4.41,4.29,4.29,0,0,1,4.32,4.22A4.62,4.62,0,0,1,19.05,26.13Z" />
        <path d="M9.2,24.81a1.59,1.59,0,0,0-1.6,1.6A1.64,1.64,0,0,0,9.2,28a1.58,1.58,0,0,0,1.59-1.59A1.64,1.64,0,0,0,9.2,24.81Zm0,2.25a.72.72,0,0,1-.75-.65.67.67,0,0,1,.75-.66.66.66,0,1,1,0,1.31Z" />
        <path d="M8.45,17.59a1.05,1.05,0,0,0-.75.29,1.28,1.28,0,0,0-.57,1,1.41,1.41,0,0,0,1,1.31L7,24.16H5.31a1.31,1.31,0,1,0,0,2.61H6.66v-.37A2.5,2.5,0,0,1,9.2,24a1.6,1.6,0,0,1,.65.09L11,20.22h1.13a8.64,8.64,0,0,1,1.31-2.63Z" />
      </g>
    </svg>
  );

  function getProps() {
    return {
      "aria-hidden": props["aria-hidden"],
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      style,
      viewBox: "0 0 32 32",
    };
  }
};

Wheelchair.propTypes = {
  /** aria-hidden text to hide non-interactive content from the accessibility API icon. */
  "aria-hidden": PropTypes.string,

  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Wheelchair.defaultProps = {
  role: "img",
};
