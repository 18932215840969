let cache = {};
let dataKeyPrefix = "";
let retrievalFunction;

export const setRetrievalFunction = (callback) => {
  retrievalFunction = callback;
};

export const setDataKeyPrefix = (prefix) => {
  dataKeyPrefix = prefix;
};

export const put = (dataKey, value) => {
  cache[dataKey] = value;
};

export const clearCache = () => {
  cache = {};
};

export const getBootstrapData = (dataKey) => {
  const prefixedDataKey = dataKeyPrefix + dataKey;
  const fullDataKey = `${prefixedDataKey}.${process.env.SITE}`;
  let result = cache[fullDataKey] ?? cache[prefixedDataKey] ?? cache[dataKey];

  try {
    if (!result && retrievalFunction) {
      result = retrievalFunction(fullDataKey);
    }
  } catch (fullDataKeyError) {
    try {
      if (!result && retrievalFunction) {
        result = retrievalFunction(prefixedDataKey);
      }
    } catch (prefixedDataKeyError) {
      try {
        if (!result && retrievalFunction) {
          result = retrievalFunction(dataKey);
        }
      } catch (dataKeyError) {
        result = undefined;
      }
    }
  }

  return result;
};
