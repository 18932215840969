import PropTypes from "prop-types";
import React from "react";

import { classNames } from "@swa-ui/string";

import colors from "../../defines/colors";

/**
 * NonRevLogo renders the Southwest Non-Revenue logo.
 */

const NonRevLogo = (props) => {
  const { className, fillColor, height, role, strokeColor, style, width } = props;

  return (
    <svg {...getProps()}>
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="-357.77"
          y1="510.95"
          x2="810.47"
          y2="1328.95"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#3656cc" />
          <stop offset="0.35" stopColor="#304cb2" />
          <stop offset="0.72" stopColor="#283b80" />
        </linearGradient>
        <linearGradient id="linear-gradient-2" x1="119.56" y1="-170.75" x2="1287.8" y2="647.25">
          <stop offset="0" stopColor="#3656cc" />
          <stop offset="0.35" stopColor="#304cb2" />
          <stop offset="0.72" stopColor="#283b80" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-3"
          x1="-366.87"
          y1="554.54"
          x2="1104.07"
          y2="554.54"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.3" stopColor="#e31c23" />
          <stop offset="0.8" stopColor="#c0161c" />
        </linearGradient>
        <linearGradient id="linear-gradient-4" x1="-84.7" y1="120.97" x2="1083.53" y2="938.98">
          <stop offset="0" stopColor="#3656cc" />
          <stop offset="0.35" stopColor="#304cb2" />
          <stop offset="0.72" stopColor="#283b80" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-5"
          x1="-51.65"
          y1="746.16"
          x2="1324.11"
          y2="746.16"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#fab911" />
          <stop offset="0.25" stopColor="#f9b115" />
          <stop offset="0.8" stopColor="#f59b1c" />
        </linearGradient>
        <linearGradient id="linear-gradient-6" x1="23.6" y1="-33.7" x2="1191.83" y2="784.31">
          <stop offset="0" stopColor="#3656cc" />
          <stop offset="0.35" stopColor="#304cb2" />
          <stop offset="0.72" stopColor="#283b80" />
        </linearGradient>
        <linearGradient id="linear-gradient-7" x1="-51.65" y1="1.66" x2="1324.11" y2="1.66">
          <stop offset="0" stopColor="#fab911" />
          <stop offset="0.25" stopColor="#f9b115" />
          <stop offset="0.8" stopColor="#f59b1c" />
        </linearGradient>
        <linearGradient id="linear-gradient-8" x1="-51.65" y1="503.93" x2="1324.11" y2="503.93">
          <stop offset="0" stopColor="#fab911" />
          <stop offset="0.25" stopColor="#f9b115" />
          <stop offset="0.8" stopColor="#f59b1c" />
        </linearGradient>
      </defs>
      <g clipPath="url(#clip-path)">
        <path
          fill="url(#linear-gradient)"
          d="M705.86,1016.64c-10.38-2.28-20.71-4.62-31-7.15s-20.41-5.22-30.46-8.08q-30.18-8.61-59.22-19.21a906.38,906.38,0,0,1-111.39-49.41,1008.07,1008.07,0,0,1-102.31-61.94,1171.05,1171.05,0,0,1-94-72.08c-30.06-25.46-58.87-52.31-86.66-80.18s-54.4-56.88-80.16-86.76c-6.43-7.45-75.82-90.64-75.82-90.64L0,498.92V1024H741.47Q723.58,1020.49,705.86,1016.64Z"
        />
        <path
          fill="url(#linear-gradient-2)"
          d="M600.08,525.73s68.06,84.28,74.36,91.79q37.81,45.22,78.53,87.64t85,81.2a1166.77,1166.77,0,0,0,92.38,73.1A1000.08,1000.08,0,0,0,1024,918.64V0H183.74C225,52.78,452.46,340.94,452.46,340.94Z"
        />
        <path
          fill="#1f3173"
          d="M110.65,631.83q38.64,44.82,80.16,86.76c27.79,27.87,56.6,54.72,86.66,80.18a1171.05,1171.05,0,0,0,94,72.08,1008.07,1008.07,0,0,0,102.31,61.94A906.38,906.38,0,0,0,585.21,982.2q29,10.54,59.22,19.21,15.08,4.29,30.46,8.08c10.26,2.53,20.59,4.87,31,7.15q17.7,3.89,35.61,7.36h37.85c-18.31-3.42-36.52-7-54.56-11-10.38-2.28-20.71-4.62-31-7.15s-20.4-5.21-30.45-8.08q-30.18-8.61-59.22-19.21a907.84,907.84,0,0,1-111.39-49.4,1010,1010,0,0,1-102.31-61.95,1171.05,1171.05,0,0,1-94-72.08c-30.06-25.46-58.87-52.31-86.66-80.18s-54.4-56.88-80.16-86.76c-6.43-7.45-75.83-90.64-75.83-90.64L0,472.35v26.57l34.83,42.27S104.22,624.38,110.65,631.83Z"
        />
        <path
          fill="#1f3173"
          d="M141,617.52q37.77,45.22,78.55,87.64t84.92,81.2a1166.93,1166.93,0,0,0,92.39,73.1,999.38,999.38,0,0,0,100.73,63.1,892.84,892.84,0,0,0,109.89,50.7q28.65,10.9,58.51,19.91c10,3,20,5.81,30.14,8.45s20.42,5.07,30.7,7.46c23.63,5.46,47.57,10.36,71.68,14.92H1024v-14.82c-4.65-1.08-9.3-2.18-13.92-3.32q-15.39-3.78-30.49-8.08-30.15-8.61-59.2-19.21A908.91,908.91,0,0,1,809,929.17a1013.75,1013.75,0,0,1-102.34-61.95,1175.93,1175.93,0,0,1-94-72.08C582.6,769.68,553.81,742.83,526,715s-54.44-56.88-80.17-86.76c-6.46-7.45-75.82-90.64-75.82-90.64L219.73,355.14S87.87,193.24,0,85.09V442.37l66.58,83.36S134.65,610,141,617.52Z"
        />
        <path
          fill="url(#linear-gradient-3)"
          d="M141,617.52q37.77,45.22,78.55,87.64t84.92,81.2a1166.93,1166.93,0,0,0,92.39,73.1,999.38,999.38,0,0,0,100.73,63.1,892.84,892.84,0,0,0,109.89,50.7q28.65,10.9,58.51,19.91c10,3,20,5.81,30.14,8.45s20.42,5.07,30.7,7.46c23.63,5.46,47.57,10.36,71.68,14.92H1024v-14.82c-4.65-1.08-9.3-2.18-13.92-3.32q-15.39-3.78-30.49-8.08-30.15-8.61-59.2-19.21A908.91,908.91,0,0,1,809,929.17a1013.75,1013.75,0,0,1-102.34-61.95,1175.93,1175.93,0,0,1-94-72.08C582.6,769.68,553.81,742.83,526,715s-54.44-56.88-80.17-86.76c-6.46-7.45-75.82-90.64-75.82-90.64L219.73,355.14S87.87,193.24,0,85.09V442.37l66.58,83.36S134.65,610,141,617.52Z"
        />
        <path
          fill="url(#linear-gradient-4)"
          d="M829.87,795.14C799.81,769.68,771,742.83,743.22,715s-54.44-56.88-80.17-86.76c-4.27-4.94-36.23-43.19-57.27-68.4,20.17,25,46,56.86,49.76,61.35q37.82,45.23,78.53,87.64t85,81.2a1166.77,1166.77,0,0,0,92.38,73.1,995.2,995.2,0,0,0,100.75,63.1q5.88,3.21,11.82,6.34V928a1016.6,1016.6,0,0,1-100.12-60.76A1172.35,1172.35,0,0,1,829.87,795.14Z"
        />
        <path
          fill="url(#linear-gradient-5)"
          d="M829.87,795.14C799.81,769.68,771,742.83,743.22,715s-54.44-56.88-80.17-86.76c-4.27-4.94-36.23-43.19-57.27-68.4,20.17,25,46,56.86,49.76,61.35q37.82,45.23,78.53,87.64t85,81.2a1166.77,1166.77,0,0,0,92.38,73.1,995.2,995.2,0,0,0,100.75,63.1q5.88,3.21,11.82,6.34V928a1016.6,1016.6,0,0,1-100.12-60.76A1172.35,1172.35,0,0,1,829.87,795.14Z"
        />
        <path fill="url(#linear-gradient-6)" d="M150.88,3.32,148.2,0H0V3.32H150.88Z" />
        <path fill="url(#linear-gradient-7)" d="M150.88,3.32,148.2,0H0V3.32H150.88Z" />
        <path
          fill="#1f3173"
          d="M382.87,525.73S451,610,457.24,617.52q37.83,45.22,78.53,87.64t85,81.2a1167.09,1167.09,0,0,0,92.4,73.1,996.84,996.84,0,0,0,100.71,63.1,891.08,891.08,0,0,0,109.91,50.7q28.63,10.9,58.49,19.91c10,3,20,5.81,30.15,8.45q5.77,1.5,11.6,2.92v-72q-5.94-3.13-11.82-6.34a995.2,995.2,0,0,1-100.75-63.1A1166.77,1166.77,0,0,1,819.05,790q-44.24-38.78-85-81.2t-78.53-87.64c-3.77-4.49-29.59-36.4-49.76-61.35l-18.56-22.24L436.91,355.14S203.66,68.71,150.88,3.32H0V42.59c76.23,96.87,235.25,298.35,235.25,298.35Z"
        />
        <path
          fill="url(#linear-gradient-8)"
          d="M382.87,525.73S451,610,457.24,617.52q37.83,45.22,78.53,87.64t85,81.2a1167.09,1167.09,0,0,0,92.4,73.1,996.84,996.84,0,0,0,100.71,63.1,891.08,891.08,0,0,0,109.91,50.7q28.63,10.9,58.49,19.91c10,3,20,5.81,30.15,8.45q5.77,1.5,11.6,2.92v-72q-5.94-3.13-11.82-6.34a995.2,995.2,0,0,1-100.75-63.1A1166.77,1166.77,0,0,1,819.05,790q-44.24-38.78-85-81.2t-78.53-87.64c-3.77-4.49-29.59-36.4-49.76-61.35l-18.56-22.24L436.91,355.14S203.66,68.71,150.88,3.32H0V42.59c76.23,96.87,235.25,298.35,235.25,298.35Z"
        />
        <path
          fill="url(#linear-gradient-9)"
          d="M370,537.56s69.36,83.19,75.82,90.64Q484.44,673,526,715c27.79,27.87,56.58,54.72,86.65,80.18a1175.93,1175.93,0,0,0,94,72.08A1013.75,1013.75,0,0,0,809,929.17a908.91,908.91,0,0,0,111.37,49.4q29,10.56,59.2,19.21,15.12,4.31,30.49,8.08c4.62,1.14,9.27,2.24,13.92,3.32v-4.64q-5.82-1.42-11.6-2.92c-10.15-2.64-20.19-5.48-30.15-8.45q-29.84-9-58.49-19.91a891.08,891.08,0,0,1-109.91-50.7,996.84,996.84,0,0,1-100.71-63.1,1167.09,1167.09,0,0,1-92.4-73.1q-44.25-38.77-85-81.2t-78.53-87.64c-6.29-7.51-74.37-91.79-74.37-91.79L235.25,340.94S76.23,139.46,0,42.59v42.5c87.87,108.15,219.73,270,219.73,270Z"
        />
        <path
          fill="url(#linear-gradient-9)"
          d="M129.55,628.2Q168.19,673,209.71,715c27.79,27.87,56.6,54.72,86.66,80.18a1171.05,1171.05,0,0,0,94,72.08,1010,1010,0,0,0,102.31,61.95,907.84,907.84,0,0,0,111.39,49.4q29,10.56,59.22,19.21,15.07,4.31,30.45,8.08c10.27,2.53,20.6,4.87,31,7.15,18,4,36.25,7.57,54.56,11h19.17c-24.11-4.56-48-9.46-71.68-14.92-10.28-2.39-20.56-4.82-30.7-7.46s-20.18-5.48-30.14-8.45q-29.85-9-58.51-19.91a892.84,892.84,0,0,1-109.89-50.7,999.38,999.38,0,0,1-100.73-63.1,1166.93,1166.93,0,0,1-92.39-73.1q-44.24-38.77-84.92-81.2Q178.69,662.85,141,617.52c-6.33-7.51-74.4-91.79-74.4-91.79L0,442.37v30l53.72,65.21S123.12,620.75,129.55,628.2Z"
        />
        <path
          fill="url(#linear-gradient-9)"
          d="M436.91,355.14,587.22,537.56l18.56,22.24c21,25.21,53,63.46,57.27,68.4Q701.65,673,743.22,715c27.77,27.87,56.59,54.72,86.65,80.18a1172.35,1172.35,0,0,0,94,72.08A1016.6,1016.6,0,0,0,1024,928v-9.34a1000.08,1000.08,0,0,1-93.67-59.18A1166.77,1166.77,0,0,1,838,786.36q-44.24-38.77-85-81.2t-78.53-87.64c-6.3-7.51-74.36-91.79-74.36-91.79L452.46,340.94S225,52.78,183.74,0H148.2l2.68,3.32C203.66,68.71,436.91,355.14,436.91,355.14Z"
        />
      </g>
    </svg>
  );

  function getProps() {
    return {
      "aria-hidden": props["aria-hidden"],
      "aria-label": props["aria-label"],
      className: classNames(className),
      fill: fillColor,
      height,
      role,
      stroke: strokeColor,
      style,
      viewBox: "0 0 1024 1024",
      width,
    };
  }
};

NonRevLogo.propTypes = {
  /** aria-hidden text to hide non-interactive content from the accessibility API icon. */
  "aria-hidden": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Color name from color palette or name of an entry in color theme file, themes.js. */
  fillColor: PropTypes.oneOfType([PropTypes.oneOf(Object.keys(colors)), PropTypes.string]),

  /** Indicates SVG's height. The width, if not given, will be sized according to SVG's aspect ratio. */
  height: PropTypes.string,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Color name from color palette or name of an entry in color theme file, themes.js. */
  strokeColor: PropTypes.oneOfType([PropTypes.oneOf(Object.keys(colors)), PropTypes.string]),

  /** Indicates SVG's width. The height, if not given, will be sized according to SVG's aspect ratio. */
  width: PropTypes.string,
};

NonRevLogo.defaultProps = {
  height: "32",
  role: "img",
};

export { NonRevLogo };
