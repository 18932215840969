import PropTypes from "prop-types";
import React from "react";

import { classNames } from "@swa-ui/string";

import { Button } from "../Button";
import { SlideIntoView } from "../SlideIntoView";
import styles from "./ToggleButton.module.scss";

/**
 * Heading creates a heading element for the specified heading level (h1-h6). Using this component to create headings
 * will provide consistent styling across applications. An optional prop may be given to specify one of the six
 * predefined style treatments, or the default style for the given level will be used.
 */

export const ToggleButton = (props) => {
  const { children, className, onClick, selected } = props;

  return (
    <div className={getClass()}>
      <SlideIntoView {...getSlideIntoViewProps()}>
        <div className={styles.selectedLayer} />
      </SlideIntoView>
      <Button {...getButtonProps()}>
        <div className={styles.content}>{children}</div>
      </Button>
    </div>
  );

  function getButtonProps() {
    return {
      className: styles.button,
      onClick,
      selected,
      styleType: "no-style",
    };
  }

  function getSlideIntoViewProps() {
    return {
      className: styles.focusOuterContainer,
      innerClassName: styles.focusContainer,
      slideDirection: selected ? "slideUp" : "hideDown",
    };
  }

  function getClass() {
    return classNames({
      [className]: className,
      [styles.selected]: selected,
      [styles.toggleButton]: true,
    });
  }
};

ToggleButton.propTypes = {
  /** Content that will be rendered in the button. */
  children: PropTypes.node.isRequired,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Callback to inform caller when button is clicked. */
  onClick: PropTypes.func,

  /** Indicator to define if button is selected. */
  selected: PropTypes.bool,
};

ToggleButton.defaultProps = {};
