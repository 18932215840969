import PropTypes from "prop-types";
import React, { useRef, useState } from "react";

import { useResizeObserver } from "@swa-ui/browser";
import { classNames } from "@swa-ui/string";

import { Area } from "../Area";
import { ButtonGroup, buttonGroupPropTypes } from "../ButtonGroup";
import { TransitionBlock } from "../TransitionBlock";
import styles from "./TabbedArea.module.scss";

/**
 * TabbedArea provides an element on the screen with tabbed navigation to view different "panels"
 * for each tab.
 */
export const TabbedArea = (props) => {
  const {
    animateArea,
    animateSize,
    areaContentList,
    buttonList,
    className,
    contentBorder,
    descriptionLeft,
    descriptionRight,
    equalSize,
    focusOnSelectionChange,
    onChange,
    selectedIndex,
    size,
    spaceBetween,
  } = props;
  const [contentWidth, setContentWidth] = useState(0);
  const ref = useRef(null);

  useResizeObserver({ callback: updateWidth, element: ref });

  return (
    <div className={classNames(className, styles.tabbedArea)} ref={ref}>
      <ButtonGroup {...getButtonGroupProps()} />
      <Area {...getAreaProps()}>{renderContent()}</Area>
    </div>
  );

  function getAreaProps() {
    return {
      animate: animateSize,
      className: classNames(styles.content, { [styles.contentBorder]: contentBorder }),
      revealed: true,
      role: "tabpanel",
    };
  }

  function renderContent() {
    return animateArea ? (
      <TransitionBlock animationToken={`${selectedIndex}-${contentWidth}`}>
        {areaContentList[selectedIndex]}
      </TransitionBlock>
    ) : (
      areaContentList[selectedIndex]
    );
  }

  function getButtonGroupProps() {
    return {
      buttonList,
      descriptionLeft,
      descriptionRight,
      enableKeyboardSupport: true,
      equalSize,
      focusOnSelectionChange,
      moreContentIndicator: false,
      onClick: handleClick,
      selectedIndex,
      size,
      spaceBetween,
      styleType: "tab",
    };
  }

  function handleClick(index) {
    onChange && onChange(index);
  }

  function updateWidth(value) {
    setContentWidth(value[0].contentRect.width);
  }
};

TabbedArea.propTypes = {
  /** Set to true if the tab contents should provide transitions when switch tabs. */
  animateArea: PropTypes.bool,

  /** Set to true if the tab contents should transition size changes. */
  animateSize: PropTypes.bool,

  /**
   * Array of content that will be displayed in each tab's panel. There should be an entry in the
   * array for each tab. TabbedArea will provide a border around the entire TabbedArea component,
   * but no other styling for the panel content is defined and should be provided by content being
   * passed in.
   */
  areaContentList: PropTypes.arrayOf(PropTypes.node).isRequired,

  /**
   * Array of content that will be displayed in each tab button. These tabs correspond with each panel.
   * All tab content styling must be provided by this content. The tabs will render the appropriate
   * background color and border, but nothing else.
   */
  buttonList: buttonGroupPropTypes.buttonList.isRequired,

  /**
   * Additional classes for positioning the component. Given classes may only position this component
   * for layout purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Indicates if the area content should display with a border. */
  contentBorder: PropTypes.bool,

  /** Optional JSX content to be displayed to the left of the button group. */
  descriptionLeft: buttonGroupPropTypes.descriptionLeft,

  /** Optional JSX content to be displayed to the right of the button group. */
  descriptionRight: buttonGroupPropTypes.descriptionRight,

  /** Setting this prop to true will set all buttons to the same width. */
  equalSize: buttonGroupPropTypes.equalSize,

  /** When set, ButtonGroup will always set focus on a newly selected item. */
  focusOnSelectionChange: PropTypes.bool,

  /**
   * onChange should be given so the caller will be informed when a tab is selected. The only
   * argument passed to the callback function is the zero based index.
   */
  onChange: PropTypes.func,

  /**
   * Zero based index of the selected tab. TabbedArea does not keep track of which tab/panel is
   * selected- keeping selectedIndex in state is the responsibility of the calling component.
   */
  selectedIndex: buttonGroupPropTypes.selectedIndex,

  /**
   * Button size can be set to change the size and width of each button. See button component for
   * more info.
   */
  size: buttonGroupPropTypes.size,

  /** Distance between buttons in ButtonGroup. */
  spaceBetween: buttonGroupPropTypes.spaceBetween,
};

TabbedArea.defaultProps = {
  animateArea: true,
  animateSize: true,
  contentBorder: true,
  descriptionRight: <div className={styles.description} />,
  selectedIndex: 0,
};
