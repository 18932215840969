import PropTypes from "prop-types";
import React from "react";

export const Youtube = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <polygon strokeWidth={0} points="14 19.23 19.89 15.88 14 12.53 14 19.23" />
      <path
        strokeWidth={0}
        d="M16,2A14,14,0,1,0,30,16,14,14,0,0,0,16,2Zm7.25,17.39A1.8,1.8,0,0,1,22,20.68a42.29,42.29,0,0,1-5.66.31,42.42,42.42,0,0,1-5.67-.31,1.83,1.83,0,0,1-1.28-1.29,19.23,19.23,0,0,1-.3-3.51,19.29,19.29,0,0,1,.3-3.51,1.83,1.83,0,0,1,1.28-1.29,42.42,42.42,0,0,1,5.67-.31,42.29,42.29,0,0,1,5.66.31,1.8,1.8,0,0,1,1.28,1.29,18.68,18.68,0,0,1,.31,3.51A18.62,18.62,0,0,1,23.25,19.39Z"
      />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      style,
      viewBox: "0 0 32 32",
    };
  }
};

Youtube.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Youtube.defaultProps = {
  role: "img",
};
