import PropTypes from "prop-types";
import React from "react";

export const Star = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <polygon
        points="17.3,0.7 22.4,11.1 33.9,12.7 25.6,20.8 27.5,32.2 17.3,26.8 7.1,32.2 9,20.8 0.7,12.7
	12.2,11.1 "
      />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      style,
      viewBox: "0 0 34.6 32.9",
    };
  }
};

Star.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Star.defaultProps = { role: "img" };
