import React from "react";

import { Heading } from "@swa-ui/core";

import styles from "./ListPlacements.module.scss";

export const ListHeading = (props) => {
  const { title } = props;

  return title && <Heading {...getHeadingProps()} />;

  function getHeadingProps() {
    return {
      children: <span {...getStyleProps(title)}>{title.value}</span>,
      className: styles.heading,
      headingLevel: 2,
      styleLevel: 2,
    };
  }

  function getStyleProps(styleProps) {
    const { color, fontSize, fontWeight } = styleProps ?? {};

    return {
      style: {
        color,
        fontSize,
        fontWeight,
      },
    };
  }
};
