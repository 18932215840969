import PropTypes from "prop-types";
import React from "react";

import { classNames } from "@swa-ui/string";

import colors from "../defines/colors";
import * as svgList from "./svgs";

/**
 * ExternalSvg renders Vision Svgs using the src path.
 */

const ExternalSvg = (props) => {
  const { className, fillColor, height, name, role, src, style, width } = props;

  return (
    <svg {...getProps()}>
      {name && <title>{name}</title>}
      <image {...getImageProps()} />
    </svg>
  );

  function getImageProps() {
    let imageSrc = src;

    imageSrc = imageSrc.replace(".svg", "");

    return {
      height,
      role: "presentation",
      src: `${imageSrc}.png`,
      width,
      xlinkHref: `${imageSrc}.svg`,
    };
  }

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className: classNames(className),
      fill: fillColor,
      height,
      role,
      style,
      viewBox: `0 0 ${width || height} ${height}`,
      width,
    };
  }
};

ExternalSvg.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Color name from color palette or name of an entry in color theme file, themes.js. */
  fillColor: PropTypes.oneOfType([PropTypes.oneOf(Object.keys(colors)), PropTypes.string]),

  /** Indicates SVG's height. The width, if not given, will be sized according to SVG's aspect ratio. */
  height: PropTypes.string,

  /** Name of svg such as Airlines or Heart. */
  name: PropTypes.oneOfType([PropTypes.oneOf(Object.keys(svgList)), PropTypes.string]),

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Defines the complete file path and file name without extension. */
  src: PropTypes.string,

  /** Indicates SVG's width. The height, if not given, will be sized according to SVG's aspect ratio. */
  width: PropTypes.string,
};

ExternalSvg.defaultProps = {
  height: "32",
  role: "img",
};

export { ExternalSvg };
