import PropTypes from "prop-types";
import React, { useRef } from "react";

import { FormattedInput } from "../FormattedInput";
import { inputPropTypes } from "../Input";

/**
 * FormattedDate will use the FormattedInput component to accept user input using a date template. FormattedDate
 * provides some additional logic to facilitate date entry and help ensure valid dates are entered.
 */

export const FormattedDate = React.forwardRef((props, ref) => {
  const {
    autoComplete,
    className,
    defaultValue,
    disabled,
    error,
    format,
    id,
    inputMode,
    inputClassName,
    name,
    onBlur,
    onChange,
    onClick,
    onFocus,
    onKeyDown,
    onMouseDown,
    pattern,
    readOnly,
    required,
    shortFormat,
    size,
    styleType,
    suffixIcon,
    value,
  } = props;
  const formatTemplate =
    format === "MM/DD" || format === "MM/YY" || shortFormat ? "__/__" : "__/__/____";
  const inputRef = ref || useRef();
  const previousText = useRef(formatTemplate);
  const shortFormatMaximumLength = 6;

  return <FormattedInput {...getInputProps()} />;

  function getInputProps() {
    return {
      acceptableCharacters: "0123456789",
      "aria-describedby": props["aria-describedby"],
      "aria-disabled": disabled,
      "aria-label": props["aria-label"],
      "aria-labelledby": props["aria-labelledby"],
      "aria-required": required,
      autoComplete,
      className,
      customTemplateReplacement:
        format === "MM/DD" || format === "MM/YY" || shortFormat ? replaceInputForFormat : undefined,
      "data-test": props["data-test"],
      defaultValue,
      disabled,
      error,
      formatTemplate,
      id,
      inputClassName,
      inputMode,
      name,
      onBlur,
      onChange,
      onClick,
      onFocus,
      onInput:
        format === "MM/DD" || format === "MM/YY" || shortFormat ? handleInput : handleLongInput,
      onKeyDown,
      onMouseDown,
      pattern,
      readOnly,
      ref: inputRef,
      required,
      size,
      styleType,
      suffixIcon,
      value,
    };
  }

  function handleInput(newValue, editMode) {
    let adjustedValue = newValue;

    if (!editMode) {
      const dateValues = getValues(newValue);

      adjustedValue = `${dateValues.month}${dateValues.year}`;
      previousText.current = adjustedValue;
    }

    return adjustedValue;
  }

  function handleLongInput(newValue, editMode) {
    let adjustedValue = newValue;

    if (!editMode) {
      const dateValues = getAllValues(newValue);

      adjustedValue = `${dateValues.month}${dateValues.day}${dateValues.year}`;
      previousText.current = adjustedValue;
    }

    return adjustedValue;
  }

  function getValues(newValue) {
    const numberOfMonthDigits = getNumberOfMonthDigits(newValue);
    const [month, year] = splitStringAtIndex(newValue, numberOfMonthDigits);

    return {
      month: getMonth(month, newValue),
      year,
    };
  }

  function getAllValues(newValue) {
    const numberOfMonthDigits = getNumberOfMonthDigits(newValue);
    const [month, dayAndYear] = splitStringAtIndex(newValue, numberOfMonthDigits);
    const numberOfDayDigits = getNumberOfDayDigits(dayAndYear, month);
    const [day, year] = splitStringAtIndex(dayAndYear, numberOfDayDigits);

    return {
      day: getDay(day, dayAndYear),
      month: getMonth(month, newValue),
      year,
    };
  }

  function getDay(day, dayAndYear) {
    const number = parseInt(day);
    let adjustedDay = day;

    if (day.length === 1 && (number > 3 || dayAndYear?.length > 1)) {
      adjustedDay = `0${day}`;
    }

    return adjustedDay;
  }

  function getDaysInMonth(month) {
    const daysInMonth = [
      "",
      "31",
      "29",
      "31",
      "30",
      "31",
      "30",
      "31",
      "31",
      "30",
      "31",
      "30",
      "31",
    ];

    return daysInMonth[month];
  }

  function getMonth(month, newValue) {
    const number = parseInt(month);
    const splitNumbers = month.split("");
    let adjustedMonth = month;

    if (
      (number < 10 && splitNumbers[0] !== "0" && splitNumbers[0] !== "1") ||
      (splitNumbers[0] === "1" &&
        splitNumbers[1] !== "0" &&
        splitNumbers[1] !== "1" &&
        splitNumbers[1] !== "2" &&
        newValue?.length >= 2)
    ) {
      adjustedMonth = `0${month}`;
    }

    return adjustedMonth;
  }

  function getNumberOfDayDigits(dayAndYear, month) {
    const splitNumbers = dayAndYear.split("");
    let numberOfDayDigits = 1;

    if (dayAndYear?.length > 1) {
      if (
        splitNumbers[0] === "0" ||
        splitNumbers[0] === "1" ||
        splitNumbers[0] === "2" ||
        splitNumbers[0] === "3"
      ) {
        numberOfDayDigits = 2;
      }

      if (
        splitNumbers[0] === "3" &&
        parseInt(dayAndYear.substring(0, 2)) > getDaysInMonth(parseInt(month))
      ) {
        numberOfDayDigits = 1;
      }
    }

    return numberOfDayDigits;
  }

  function getNumberOfMonthDigits(newValue) {
    const splitNumbers = newValue.split("");
    let numberOfMonthDigits = 2;

    if (splitNumbers[0] !== "0" && splitNumbers[0] !== "1") {
      numberOfMonthDigits = 1;
    }

    if (
      splitNumbers[0] === "1" &&
      splitNumbers[1] !== undefined &&
      splitNumbers[1] !== "0" &&
      splitNumbers[1] !== "1" &&
      splitNumbers[1] !== "2"
    ) {
      numberOfMonthDigits = 1;
    }

    return numberOfMonthDigits;
  }

  function replaceInputForFormat(inputValue) {
    const inputChars = inputValue.split("");
    let result = [...inputChars];

    if (inputChars.length > shortFormatMaximumLength) {
      const monthYear = inputValue.match(/\d+/g);
      let month;

      if (format === "MM/DD") {
        month = monthYear[0];
        const day = monthYear[1];

        result = [month, "/", day];
      } else {
        let year;

        if (monthYear[0].length === 4) {
          month = monthYear[1];
          year = monthYear[0].substring(2, 4);
        } else {
          month = monthYear[0];
          year = monthYear[1]?.substring(2, 4);
        }

        result = [month, "/", year];
      }
    }

    return result.join("");
  }

  function splitStringAtIndex(string, index) {
    return [string.slice(0, index), string.slice(index)];
  }
});

FormattedDate.propTypes = {
  /** aria-describedby id to element which provides additional accessibility description of input element. */
  "aria-describedby": PropTypes.string,

  /** aria-label text to provide additional accessibility description of input element. */
  "aria-label": PropTypes.string,

  /** aria-labelledby id to element which provides additional accessibility description of input element. */
  "aria-labelledby": PropTypes.string,

  /** HTML input element attribute for autoComplete. */
  autoComplete: PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Name to use for the data-test attribute used for automated testing. */
  "data-test": PropTypes.string,

  /**
   * Initial value for input. If given, Input assumes that the component will not be "controlled" and the value will
   * be maintained in the DOM.
   */
  defaultValue: PropTypes.string,

  /** Indicates Input should apply disabled styling and ignore mouse and key events. */
  disabled: PropTypes.bool,

  /** Indicates Input should apply error styling and apply aria-invalid attribute. */
  error: PropTypes.bool,

  /** There are three supported formats that can be used. */
  format: PropTypes.oneOf(["MM/DD", "MM/DD/YYYY", "MM/YY"]),

  /** ID to be added to input element. */
  id: PropTypes.string,

  /** Optional class to be added to input element. The className prop is added to the input element's container. */
  inputClassName: PropTypes.string,

  /** Optional string to tell mobile device browsers to display an appropriate keyboard for the type of input. */
  inputMode: inputPropTypes.inputMode,

  /** Name to be added to input element. */
  name: PropTypes.string,

  /** Optional event handler to learn when focus is lost from input element. */
  onBlur: PropTypes.func,

  /** Optional event handler to learn when input element's content is changed. */
  onChange: PropTypes.func,

  /** Optional event handler to learn when input element is clicked. */
  onClick: PropTypes.func,

  /** Optional event handler to learn when focus is given to input element. */
  onFocus: PropTypes.func,

  /** Optional event handler to learn when a key is pressed. */
  onKeyDown: PropTypes.func,

  /** Optional event handler to learn when a mouse button pressed. */
  onMouseDown: PropTypes.func,

  /** Indicates Input should be immutable and ignore mouse and key events. */
  readOnly: PropTypes.bool,

  /** Indicates Input should apply aria-required attribute. */
  required: PropTypes.bool,

  /** Indicates if formatted date should be __/__/____ or __/__. */
  shortFormat: PropTypes.bool,

  /** Indicates the height and padding of the input element. */
  size: PropTypes.oneOf(["large", "small"]),

  /** Indicates the style of the input element. */
  styleType: PropTypes.oneOf(["primary", "secondary"]),

  /** Icon that can be placed at end of input field. See Input for more details. */
  suffixIcon: inputPropTypes.suffixIcon,

  /** Value to be maintained by caller. This will define FormattedDate as a controlled component. */
  value: PropTypes.string,
};

FormattedDate.defaultProps = {
  format: "MM/DD/YYYY",
  inputMode: "numeric",
};

FormattedDate.displayName = "FormattedDate";
