import PropTypes from "prop-types";
import React from "react";

export const CarCircle = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path d="M 12.375 0 C 5.5404762 0 0 5.5404762 0 12.375 C 0 19.209524 5.5404762 24.75 12.375 24.75 C 19.209524 24.75 24.75 19.209524 24.75 12.375 C 24.75 5.5404762 19.209524 -3.5527137e-15 12.375 0 z M 9.40625 6.25 L 10.875 6.25 L 13.3125 6.25 L 13.34375 6.25 L 14.78125 6.25 L 14.8125 6.25 C 15.4275 6.25 15.85525 6.42275 16.15625 6.71875 C 16.32525 6.88375 16.44825 7.0945 16.53125 7.3125 L 17.375 8.78125 L 18.4375 8.78125 C 18.7305 8.78125 18.84375 9.0215 18.84375 9.3125 C 18.84375 9.6035 18.7305 9.84375 18.4375 9.84375 L 17.96875 9.84375 L 18.21875 10.03125 C 18.76875 10.16825 19.6875 10.62875 19.6875 11.71875 L 19.6875 17.8125 C 19.6875 18.0465 19.487 18.21875 19.25 18.21875 L 17.90625 18.21875 C 17.66925 18.21875 17.46875 18.0465 17.46875 17.8125 L 17.46875 16.3125 L 17.1875 16.3125 L 7.03125 16.3125 L 6.71875 16.3125 L 6.71875 17.8125 C 6.71875 18.0465 6.52025 18.21875 6.28125 18.21875 L 4.9375 18.21875 C 4.6985 18.21875 4.53125 18.0465 4.53125 17.8125 L 4.53125 11.71875 C 4.53125 10.62875 5.41975 10.16825 5.96875 10.03125 L 6.21875 9.84375 L 5.78125 9.84375 C 5.48725 9.84375 5.34375 9.6035 5.34375 9.3125 C 5.34375 9.0215 5.48725 8.78125 5.78125 8.78125 L 6.8125 8.78125 L 7.65625 7.3125 C 7.73925 7.0945 7.86125 6.88375 8.03125 6.71875 C 8.33225 6.42275 8.78925 6.25 9.40625 6.25 z M 8.9375 7.34375 C 8.9465 7.35275 7.5625 9.9375 7.5625 9.9375 L 16.65625 9.9375 C 16.65625 9.9375 15.28925 7.35275 15.28125 7.34375 L 8.9375 7.34375 z M 7 11.375 C 6.339 11.375 5.8125 11.9085 5.8125 12.5625 C 5.8125 13.2165 6.339 13.75 7 13.75 C 7.662 13.75 8.21875 13.2165 8.21875 12.5625 C 8.21875 11.9085 7.662 11.375 7 11.375 z M 17.1875 11.375 C 16.5265 11.375 16 11.9085 16 12.5625 C 16 13.2165 16.5265 13.75 17.1875 13.75 C 17.8495 13.75 18.40625 13.2165 18.40625 12.5625 C 18.40625 11.9085 17.8495 11.375 17.1875 11.375 z M 9.5 12.53125 L 9.5 13.75 L 14.71875 13.75 L 14.71875 12.53125 C 14.72075 12.53425 9.517 12.53125 9.5 12.53125 z" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "0 0 24.75 24.75",
    };
  }
};

CarCircle.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

CarCircle.defaultProps = { role: "img" };
