import PropTypes from "prop-types";
import React from "react";

export const Edit = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path d="M2.57,30a.59.59,0,0,1-.4-.16A.61.61,0,0,1,2,29.26l2.44-8.88a.94.94,0,0,1,.14-.26L22,2.76a2.75,2.75,0,0,1,2-.74A6.08,6.08,0,0,1,28,4,6.58,6.58,0,0,1,29.9,7.3,2.92,2.92,0,0,1,29.23,10L11.87,27.39a.58.58,0,0,1-.25.15L2.74,30A.47.47,0,0,1,2.57,30Zm.82-1.39,7-1.85L5.3,21.63ZM5.8,20.53l5.65,5.65L26.81,10.84,21.16,5.19ZM23.93,3.17a1.53,1.53,0,0,0-1.15.41l-.81.8L27.64,10l.8-.8c.77-.76.45-2.78-1.21-4.48A5,5,0,0,0,23.93,3.17Z" />
    </svg>
  );

  function getProps() {
    return {
      "aria-hidden": props["aria-hidden"],
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      style,
      viewBox: "0 0 32 32",
    };
  }
};

Edit.propTypes = {
  /** aria-hidden text to hide non-interactive content from the accessibility API. */
  "aria-hidden": PropTypes.string,

  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Edit.defaultProps = {
  role: "img",
};
