import { withFormField } from "@swa-ui/form";

import { SuffixAutocomplete } from "../SuffixAutocomplete";

/**
 * SuffixFormField provides support for a SuffixAutocomplete component to be used in a Form.
 */

export const SuffixFormField = withFormField(SuffixAutocomplete, {
  useController: true,
});
