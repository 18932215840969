import PropTypes from "prop-types";
import React from "react";

export const Sun = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path d="m13.53,20.2c3.73,0,6.75-3.02,6.75-6.75s-3.02-6.75-6.75-6.75-6.75,3.02-6.75,6.75,3.02,6.75,6.75,6.75Z" />
      <path d="m26.69,16.15c-.93-.59-1.43-1.6-1.43-2.7s.51-2.11,1.43-2.7c.25-.17.42-.59.34-.93-.08-.34-.42-.59-.76-.59-1.1-.08-2.03-.68-2.53-1.6-.51-.93-.59-2.02-.17-3.04.17-.34.08-.67-.17-.93-.25-.25-.59-.34-.93-.17-1.01.42-2.11.42-3.04-.17-.93-.51-1.52-1.52-1.6-2.53,0-.34-.25-.68-.59-.76-.34-.08-.67,0-.93.34-.59.93-1.6,1.43-2.7,1.43s-2.11-.51-2.7-1.43c-.17-.25-.59-.42-.93-.34-.42,0-.68.34-.68.67-.08,1.1-.68,2.02-1.6,2.53-1.01.59-2.11.59-3.12.17-.25-.17-.67-.08-.93.17-.25.25-.34.67-.17.93.51,1.01.42,2.11-.17,3.04-.51.93-1.52,1.52-2.53,1.6-.34,0-.68.25-.76.59-.08.34,0,.68.34.93.93.59,1.43,1.6,1.43,2.7s-.51,2.11-1.43,2.7c-.25.17-.42.59-.34.93.08.34.42.67.76.67,1.1.08,2.02.67,2.53,1.6.51.93.59,2.02.17,3.04-.17.34-.08.67.17.93.25.25.59.34.93.17,1.01-.42,2.11-.42,3.04.17.93.51,1.52,1.52,1.6,2.53,0,.34.25.67.59.76.34.08.68,0,.93-.34.59-.93,1.6-1.43,2.7-1.43s2.11.51,2.7,1.43c.17.25.42.34.67.34h.25c.34-.08.59-.42.59-.76.08-1.1.68-2.02,1.6-2.53.93-.51,2.02-.59,3.04-.17.34.17.67.08.93-.17.25-.25.34-.59.17-.93-.42-1.01-.42-2.11.17-3.04.51-.93,1.52-1.52,2.53-1.6.34,0,.68-.25.76-.59.08-.34.08-.76-.17-.93Zm-13.16,5.74c-4.64,0-8.44-3.8-8.44-8.44s3.8-8.44,8.44-8.44,8.44,3.8,8.44,8.44-3.8,8.44-8.44,8.44Z" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "-2 0 32 32",
    };
  }
};

Sun.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Sun.defaultProps = { role: "img" };
