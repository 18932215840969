import PropTypes from "prop-types";
import React from "react";

import { Svg } from "@swa-ui/core";

/**
 * SvgAdapter adds props for compatibility with Vision Svg.
 */

export const SvgAdapter = (props) => {
  const { fillColor, name, size, height, src, title, ...remainingProps } = props;

  return <Svg {...getProps()} />;

  function getProps() {
    const adaptedProps = {
      fillColor: props.style?.color ?? fillColor,
      name: title ?? name,
      size: height ?? size,
      src,
    };

    return {
      ...remainingProps,
      ...adaptedProps,
    };
  }
};

SvgAdapter.propTypes = {
  /** Svgs can use any standard brand color or the name of a CSS var. */
  fillColor: PropTypes.string,

  /** Indicates SVG's height. The width will be sized accordion to Svg's aspect ratio. */
  height: PropTypes.string,

  /** Name of svg such as Airlines or Heart. */
  name: PropTypes.string.isRequired,

  /** Indicates SVG's height. The width will be sized accordion to Svg's aspect ratio. */
  size: PropTypes.string,

  /** Svg path to be rendered. */
  src: PropTypes.string,

  /** Object to style the HTML span element. */
  style: PropTypes.object,

  /** Name of svg such as Airlines or Heart. */
  title: PropTypes.string,
};
