import PropTypes from "prop-types";
import React from "react";

import { RadioButtonFormField } from "@swa-ui/form";
import i18n from "@swa-ui/locale";

import { PHONE_TYPE_SCHEMA_FIELD_NAMES } from "./PhoneTypeFormField.schema";

/**
 * PhoneTypeFormField facilitates the selection of what kind of phone number the user is providing,
 * such as a home phone or a work phone.
 */

export const PhoneTypeFormField = (props) => {
  const { caption, className, defaultValue, direction, disabled, label, name } = props;

  return <RadioButtonFormField {...getFormFieldProps()} />;

  function getFormFieldProps() {
    return {
      caption,
      className,
      componentProps: {
        "aria-label": props["aria-label"] ?? i18n("PhoneTypeFormField__ARIA"),
        defaultValue,
        direction,
        radioButtonInfoList: [
          {
            label: i18n("PhoneTypeFormField__HOME"),
            value: "HOME",
          },
          {
            label: i18n("PhoneTypeFormField__MOBILE"),
            value: "MOBILE",
          },
          {
            label: i18n("PhoneTypeFormField__WORK"),
            value: "BUSINESS",
          },
          {
            label: i18n("PhoneTypeFormField__OTHER"),
            value: "OTHER",
          },
        ],
        required: true,
      },
      disabled,
      label: label ?? i18n("PhoneTypeFormField__LABEL"),
      name: name ?? PHONE_TYPE_SCHEMA_FIELD_NAMES.PHONE_TYPE,
    };
  }
};

PhoneTypeFormField.propTypes = {
  /** Content to be read by the screen reader. */
  "aria-label": PropTypes.string,

  /** Content below the form field. */
  caption: PropTypes.string,

  /** Class name for positioning the component. */
  className: PropTypes.string,

  /** Default selected phone type. */
  defaultValue: PropTypes.string,

  /** Which direction the radio buttons should be displayed. */
  direction: PropTypes.oneOf(["column", "row"]),

  /** Indicates whether the field is disabled.  */
  disabled: PropTypes.bool,

  /** Label to identify the form field. */
  label: PropTypes.node,

  /** Form field name used in schema validation. */
  name: PropTypes.string,
};

PhoneTypeFormField.defaultProps = {
  defaultValue: "HOME",
  direction: "row",
  name: "phoneType",
};
