import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import { getBootstrapData } from "@swa-ui/bootstrap";
import { useDeviceInfo, window } from "@swa-ui/browser";
import {
  Autocomplete,
  autocompletePropTypes,
  Button,
  Caption,
  Checkbox,
  Input,
  keyCodes,
  TransitionBlock,
} from "@swa-ui/core";
import i18n from "@swa-ui/locale";
import { classNames, getUniqueId } from "@swa-ui/string";

import { AIR } from "../defines/itineraryType";
import {
  getAreaName,
  getStation,
  getStationForName,
  getStationName,
  getStationOrAreaName,
  getStations,
} from "../Stations";
import {
  addSimpleLabelValue,
  getIndexLetters,
  highlightMatchArea,
  shouldShowAutocomplete,
} from "../utilities/helpers/AutocompleteHelpers";
import styles from "./AutocompleteStations.module.scss";

const AREA_SEPARATOR = "AREA SEPARATOR";
const CHANGE_TYPE_AREA_PARENT = 1;
const CHANGE_TYPE_AREA_CHILDREN = 2;
const CHECKBOX = false;
const CUSTOM = true;
const DELAY_FOR_BROWSER = 16;
const MINIMUM_CHARS_TO_SEARCH = 3;
const NO_ANIMATION_DURATION = 0.001;

/**
 * AutocompleteStations facilitates selecting an airport station by filter selectable stations
 * according to user entry matches.
 *
 * Autocomplete informs the application of the user select via onChange handler. When nearbyAirports
 * prop is given, event.target.value will contain and array with the selected airport codes,
 * otherwise the traveler selection will be a string set to the selected station code.
 *
 * Note that the calling application must set up bootstrap so station data is available. Primarily
 * this requires setDataKeyPrefix be called with application's prefix key.
 */

export const AutocompleteStations = React.forwardRef((props, ref) => {
  const {
    className,
    defaultValue,
    disabled,
    error,
    filteredStations,
    id,
    invalidRouteText,
    labelText,
    maxItemsToDisplay,
    minimumListWidth,
    nearbyAirports,
    noMatchFoundText,
    onBlur,
    onChange,
    option,
    readOnly,
    required,
    suppressValidationTriggerOnChange,
    touchDevice,
    value,
  } = props;
  const { KEY_ENTER, KEY_ESCAPE } = keyCodes;
  const [currentList, setCurrentList] = useState([]);
  const [currentValue, setCurrentValue] = useState(value || "");
  const [location, setLocation] = useState("hidden");
  const [selections, setSelections] = useState([]);
  const airportGroups = getBootstrapData("air/airport-groups");
  const areasAdded = useRef([]);
  const containerRef = useRef([]);
  const isTouchDevice = touchDevice !== undefined ? touchDevice : useDeviceInfo()?.isTouchDevice;
  const previousResultsLength = useRef(0);
  const previousContent = useRef("");
  const previousSelections = useRef("");
  const searchInputRef = useRef();
  const sectionRefs = getIndexLetters(AIR).map(() => useRef());
  const stations = getStations();
  let stationsAdded = useRef([]);

  useEffect(() => {
    let list = getList(value);
    let newValue = value;

    if (Array.isArray(value)) {
      if (value.length > 1) {
        newValue = getAreaName(value[0]) || getAreaNameFromChild(getStation(value[0]));
      }

      const station = getStation(value?.[0]);

      if (value.length > 1 || isInArea(station)) {
        list = removeStationsFromListNotInGroup(
          isArea(station) ? station : getStation(station.parents[0]),
          list
        );
      }

      setSelections(translateStationIdsToStationNames(value?.[0] === "" ? [] : value));
    }

    setCurrentValue(newValue || "");
    setCurrentList(list);
  }, [value]);

  return <>{isTouchDevice ? renderFullScreen() : <Autocomplete {...getProps()} />}</>;

  function renderFullScreen() {
    return (
      <Caption {...getCaptionProps()}>
        <Input {...getInputProps()} />
      </Caption>
    );
  }

  function renderFullScreenContent() {
    return (
      <>
        <div className={styles.mainContainer} onKeyDown={handleKeyDown} tabIndex={-1}>
          <div className={styles.headingOptions}>
            <div className={styles.labelText}>{labelText}</div>
            <Input {...getSearchInputProps()} />
          </div>
          <div className={styles.options} ref={containerRef}>
            <TransitionBlock {...getTransitionBlockProps()}>
              {shouldShowAutocomplete(currentValue, currentList)
                ? renderAutocompleteList()
                : renderList()}
            </TransitionBlock>
          </div>
        </div>
        {renderIndex()}
      </>
    );
  }

  function getTransitionBlockProps() {
    const noAnimation = { duration: NO_ANIMATION_DURATION, transformations: ["fadeReset"] };
    const resultsChanged = currentList?.length !== previousResultsLength.current;
    const valueChanged = selections.current !== previousSelections.current;

    previousResultsLength.current = currentList.length;
    previousSelections.current = currentValue;

    return {
      animationToken:
        currentValue?.length >= MINIMUM_CHARS_TO_SEARCH
          ? `${currentList?.length}-${selections.join("")}`
          : "none",
      transitionIn: valueChanged && !resultsChanged ? noAnimation : undefined,
      transitionOut: valueChanged && !resultsChanged ? noAnimation : undefined,
    };
  }

  function renderAutocompleteList() {
    return <ul className={styles.list}>{renderAutocompleteItems()}</ul>;
  }

  function renderAutocompleteItems() {
    return currentList.map((resultItem, index) =>
      isCheckbox(resultItem) ? (
        <li key={resultItem.value}>
          <Checkbox {...getCheckboxProps(resultItem)} />
        </li>
      ) : (
        <li key={resultItem.value} {...getListItemProps(resultItem, index)}>
          {resultItem.label}
        </li>
      )
    );
  }

  function isCheckbox(listItem) {
    return (
      nearbyAirports &&
      (listItem.custom === CHECKBOX || listItem.value.indexOf("Area Airports") !== -1)
    );
  }

  function getCheckboxProps(listItem) {
    return {
      className: getCheckboxClass(listItem),
      disabled: listItem.disabled,
      label: listItem.label,
      name: listItem.value,
      onChange: () => handleMobileNearbyAirportsChange(listItem),
      onMouseDown: (event) => {
        event.preventDefault();
        event.stopPropagation();
      },
      tabIndex: -1,
      value: isSelectionAlreadyInList(listItem.value),
    };
  }

  function getCheckboxClass(listItem) {
    return classNames({
      [styles.area]: listItem.role === "none",
      [styles.indentCheckbox]: listItem.indent,
    });
  }

  function isSelectionAlreadyInList(itemValue) {
    return selections.indexOf(itemValue) !== -1;
  }

  function renderList() {
    const indexLetters = getIndexLetters(AIR);

    return indexLetters.map((indexLetter, index) => (
      <div key={index} ref={sectionRefs[index]}>
        <div className={styles.letterSection}>
          <div>{indexLetter}</div>
          <div className={styles.separator} />
        </div>
        {getStations()?.map((station) => {
          const stationName = getStationName(station.id);
          const firstLetter = stationName.charAt(0).toUpperCase();

          return firstLetter &&
            indexLetter === firstLetter &&
            !filteredStations.includes(station.id) ? (
            <Button {...getButtonProps(station.id)}>{stationName}</Button>
          ) : null;
        })}
      </div>
    ));
  }

  function renderIndex() {
    const indexLetters = getIndexLetters(AIR);

    return (
      <div className={styles.indexContainer}>
        <div className={getIndexClass()}>
          {indexLetters.map((indexLetter, index) => (
            <Button key={indexLetter} {...getIndexButtonProps(index)}>
              {indexLetter}
            </Button>
          ))}
        </div>
      </div>
    );
  }

  function getProps() {
    return {
      "aria-describedby": props["aria-describedby"],
      "aria-required": required,
      className,
      "data-test": props["data-test"],
      defaultValue,
      disabled,
      error,
      getSelectionValueText: getValueText,
      id,
      inputMode: "text",
      list: currentList,
      location,
      maxItemsToDisplay,
      minimumListWidth,
      multipleSelection: nearbyAirports,
      noMatchFoundText,
      onBlur: handleBlur,
      onChange: nearbyAirports ? handleNearbyAirportsChange : handleChange,
      onInputChange: handleInputChange,
      option,
      readOnly,
      ref,
      renderToken: !nearbyAirports && `${getUniqueId("AutocompleteStations")}`,
      required,
      showArrow: false,
      value: currentValue,
      values: nearbyAirports ? selections : undefined,
    };
  }

  function getCaptionProps() {
    return {
      adjoiningContent: renderFullScreenContent(),
      "aria-label-close": i18n("AutocompleteStations__CLOSE_ARIA_LABEL"),
      id,
      location,
      mainClassName: styles.mainContent,
      onClose: handleClose,
      showClose: true,
      showPointer: false,
      stackingContext: false,
    };
  }

  function getInputProps() {
    return {
      "aria-describedby": props["aria-describedby"],
      disabled,
      error,
      id,
      inputMode: "none",
      onFocus: () => {
        !readOnly && setLocation("full-screen");
      },
      onInputChange: handleInputChange,
      option,
      readOnly: location === "full-screen",
      ref,
      required,
      value: getValueText(),
    };
  }

  function getListItemProps(listItem, index) {
    return {
      className: classNames(listItem.className, styles.listItem, {
        [styles.disabled]: listItem.disabled,
        [styles.indent]: listItem.indent,
      }),
      onClick: handleAutocompleteClick.bind(this, index),
    };
  }

  function getSearchInputProps() {
    return {
      className: styles.input,
      onChange: handleInputChange,
      ref: searchInputRef,
      value: getValueText(),
    };
  }

  function getButtonProps(stationId) {
    return {
      className: styles.stationOption,
      key: stationId,
      onClick: handleClick.bind(this, stationId),
      styleType: "no-style",
      tabIndex: -1,
    };
  }

  function getIndexButtonProps(index) {
    return {
      className: styles.indexButton,
      onClick: handleIndexSelection.bind(this, index),
      styleType: "no-style",
      tabIndex: -1,
    };
  }

  function getIndexClass() {
    return classNames({
      [styles.indexes]: [styles.indexes],
      [styles.container]: shouldShowAutocomplete(currentValue, currentList),
    });
  }

  function handleBlur(event) {
    if (!Array.isArray(currentValue)) {
      let station = currentValue?.toUpperCase();

      station = getStation(station) || getStationForName(station);

      if (!station) {
        if (!isAreaName(currentValue)) {
          setSelections([]);
          fireChangeEvent(currentValue);
        }
      } else {
        setCurrentValue(station.id);
        setSelections([station.displayName]);
        fireChangeEvent(nearbyAirports ? [station.id] : station.id);
      }
    } else {
      processValueOnExit();
      onBlur && onBlur(event);
    }
  }

  function getChangeValue(selectedValues) {
    let listItem = getAddedValue(selectedValues);

    if (!listItem) {
      listItem = getRemovedValue(selectedValues);
    }

    return listItem;
  }

  function getAddedValue(selectedValues) {
    return selectedValues.find((selectedValue) => !getValueInSelections(selections, selectedValue));
  }

  function getRemovedValue(selectedValues) {
    return selections.find((selectedValue) => !getValueInSelections(selectedValues, selectedValue));
  }

  function getValueInSelections(searchList, selectedValue) {
    return searchList.find(
      (selectedOption) => selectedOption === selectedValue || getStation(selectedValue)?.displayName
    );
  }

  function handleNearbyAirportsChange(itemValue) {
    let newValue = getChangeValue(itemValue);

    if (newValue) {
      const station = getStation(newValue) || getStationForName(newValue);

      if (isArea(station) || isInArea(station)) {
        nearbyAirportsChange(newValue);
      } else {
        setCurrentValue(station.id);
        setCurrentList(getList(station.id));
        setSelections([station.id]);
        fireChangeEvent(getReturnValue(station.id));
      }
    } else {
      newValue = getStation(itemValue[0]) || getStationForName(itemValue[0])?.id;
      setCurrentValue(newValue || "");
      setCurrentList(getList(newValue || ""));
      setSelections(newValue ? [newValue] : []);
    }
  }

  function handleMobileNearbyAirportsChange(listItem) {
    const { value: newValue } = listItem;

    nearbyAirportsChange(newValue);
  }

  function nearbyAirportsChange(newValue) {
    let updatedSelections = isSelectionAlreadyInList(newValue)
      ? removeSelection(newValue)
      : addSelection(newValue);
    const typeOfChange = getWhatChanged(updatedSelections);
    let areaName = getSelectedAreaName(updatedSelections);

    updatedSelections = removeStationsNotInGroup(updatedSelections, newValue);

    if (typeOfChange === CHANGE_TYPE_AREA_PARENT) {
      if (areaName) {
        updatedSelections = [areaName].concat(getAllAreaChildrenNames(areaName));
        setCurrentValue(updatedSelections);
        setCurrentList(
          removeStationsFromListNotInGroup(
            getStationForName(areaName),
            getList(getStationForName(areaName).id)
          )
        );
      } else {
        updatedSelections = [];
        setCurrentValue("");
        setCurrentList(getList(""));
      }
    } else if (typeOfChange === CHANGE_TYPE_AREA_CHILDREN && updatedSelections.length !== 1) {
      if (!areaName) {
        areaName = getAreaNameFromChild(getFirstSelectedOption(updatedSelections));

        if (areaName && getAreaChildCount(areaName) === updatedSelections.length) {
          updatedSelections = [areaName].concat(getAllAreaChildrenNames(areaName));
        }
      } else {
        if (getAreaChildCount(areaName) !== updatedSelections.length + 1) {
          updatedSelections = removeAreaName(updatedSelections, areaName);
        }
      }

      if (areaName) {
        setCurrentList(getList(areaName));
      }

      setCurrentValue(updatedSelections);
    } else {
      const stationId = getStationForName(updatedSelections[0])?.id;

      setCurrentValue(stationId);

      if (!areaName) {
        areaName = getAreaNameFromChild(getFirstSelectedOption(updatedSelections));
      }

      if (areaName) {
        setCurrentList(getList(areaName));
      } else {
        setCurrentList(getList(stationId));
      }
    }

    setSelections(updatedSelections);
  }

  function removeStationsNotInGroup(updatedSelections, newValue) {
    const stationId = getStationForName(newValue);
    const areaName = getAreaNameFromChild(stationId);
    let cleanedSelections = updatedSelections;

    if (areaName) {
      const areaChildren = getStationForName(areaName).children;

      cleanedSelections = cleanedSelections.filter((selection) => {
        const station = getStation(selection) || getStationForName(selection);
        const selectionId = station?.id;

        return areaChildren.indexOf(selectionId) !== -1;
      });
    }

    return cleanedSelections;
  }

  function removeStationsFromListNotInGroup(parentArea, list) {
    const childIds = parentArea.children;
    let resultsList = list.filter((listItem) => {
      const { value: displayName } = listItem;

      return (
        listItem.disabled ||
        isArea(getStationForName(displayName)) ||
        childIds.find((childId) => getStation(childId).displayName === displayName)
      );
    });

    resultsList = removeAdjacentSeparators(resultsList);

    return removeEndingAreaSeparator(resultsList);
  }

  function handleChange(event) {
    const stationId = getStationForName(event.target.value)?.id;

    setCurrentList(getList(stationId));
    setCurrentValue(stationId);
    fireChangeEvent(getReturnValue(stationId));
  }

  function removeAreaName(newValues, areaName) {
    return newValues.filter((selectedOption) => selectedOption !== areaName);
  }

  function removeSelection(selection) {
    return selections.filter((selectedOption) => selectedOption !== selection);
  }

  function addSelection(itemValue) {
    return selections.length ? selections.slice().concat(itemValue) : [itemValue];
  }

  function getWhatChanged(newValues) {
    const currentAreaName = getSelectedAreaName(newValues);
    const previousAreaName = getSelectedAreaName(selections);
    let typeOfChange;

    if (currentAreaName !== previousAreaName) {
      typeOfChange = CHANGE_TYPE_AREA_PARENT;
    } else {
      const currentSelectedChildrenCount = newValues.length;
      const previousSelectedChildrenCount = selections.length;

      if (currentSelectedChildrenCount !== previousSelectedChildrenCount) {
        typeOfChange = CHANGE_TYPE_AREA_CHILDREN;
      }
    }

    return typeOfChange;
  }

  function getAllAreaChildrenNames(areaName) {
    return getStationForName(areaName)?.children.map((child) => getStation(child).displayName);
  }

  function getSelectedAreaName(newValues) {
    return newValues.find((selectedOption) => isAreaName(selectedOption));
  }

  function translateStationIdsToStationNames(newValue) {
    return newValue.map((stationId) => getStationOrAreaName(stationId) || stationId);
  }

  function getAreaNameFromChild(child) {
    const parent = child?.parents[0];

    return parent && !isRegion(parent) && getStation(parent)?.displayName;
  }

  function getFirstSelectedOption(newValues) {
    const childName = newValues.find((selectedOption) => getStationForName(selectedOption));

    return getStationForName(childName);
  }

  function getAreaChildCount(areaName) {
    const groupParent = getStationForName(areaName);

    return groupParent.children.length;
  }

  function handleClick(stationId) {
    setLocation("hidden");
    setCurrentList(getList(stationId));
    setCurrentValue(stationId);
    fireChangeEvent(getReturnValue(stationId));
  }

  function handleInputChange(event) {
    const list = getList(event.target.value);

    setCurrentList(list);

    if (shouldSearchForResults(event.target.value)) {
      setSelections([]);
    }

    setCurrentValue(event.target.value);

    if (list.length !== currentList.length && !isTouchDevice) {
      // refresh flyout by closing then opening again
      setLocation("hidden");
      window.setTimeout(
        () => setLocation(isTouchDevice ? "full-screen" : "below"),
        DELAY_FOR_BROWSER
      );
    }
  }

  function getValueText() {
    let content = currentValue;

    if (currentValue) {
      if (Array.isArray(currentValue)) {
        let areaMatch;

        if (currentValue.length > 1) {
          areaMatch =
            getAreaName(currentValue[0]) ||
            getAreaNameFromChild(getStation(currentValue[0]) || getStationForName(currentValue[0]));
        }

        if (areaMatch) {
          content = areaMatch;
        } else {
          const airportMatch = getStation(currentValue[0]) || getStationForName(currentValue[0]);

          if (airportMatch) {
            content = airportMatch.isAirportGroup ? airportMatch.displayName : airportMatch.id;
          } else if (selections.length > 1) {
            content =
              getSelectedAreaName(selections) ||
              getAreaNameFromChild(getFirstSelectedOption(selections));
          }
        }
      }
    }

    previousContent.current = content;

    return content;
  }

  function getList(newValue) {
    let list = [];

    if (shouldSearchForResults(newValue)) {
      newValue = Array.isArray(newValue) ? newValue[0] : newValue.toUpperCase();
      list = getStationList(newValue);

      if (!list.length) {
        if (filteredStations.includes(newValue)) {
          list = [
            {
              custom: true,
              disabled: true,
              label: <i className={styles.invalidRoute}>{invalidRouteText}</i>,
              value: invalidRouteText,
            },
          ];
        } else {
          if ((isTouchDevice && currentValue?.length) || !isTouchDevice) {
            list = [
              {
                custom: true,
                disabled: true,
                label: <i className={styles.invalidRoute}>{noMatchFoundText}</i>,
                value: noMatchFoundText,
              },
            ];
          }
        }
      }
    }

    return list;
  }

  function shouldSearchForResults(newValue) {
    return (
      (Array.isArray(newValue) && newValue?.length && newValue[0].length) ||
      newValue?.length >= MINIMUM_CHARS_TO_SEARCH
    );
  }

  function handleKeyDown(event) {
    const { key } = event;

    if (key === KEY_ENTER || key === KEY_ESCAPE) {
      const station = getStation(currentValue.toUpperCase());

      if (station) {
        setCurrentValue(station.id);
        setCurrentList(getList(station.id));
        setSelections([station?.displayName]);
      }

      fireChangeEvent(getReturnValue(station?.id || currentValue));
      setLocation("hidden");
    }
  }

  function handleClose() {
    if (currentValue !== null && currentValue !== undefined) {
      processValueOnExit();
    }
    setLocation("hidden");
  }

  function handleAutocompleteClick(index) {
    const stationId = getStationForName(currentList[index].value)?.id;

    setCurrentValue(stationId);
    setCurrentList(getList(stationId));
    fireChangeEvent(getReturnValue(stationId));
    setLocation("hidden");
  }

  function handleIndexSelection(index) {
    containerRef.current.scrollTo({
      behavior: "smooth",
      left: 0,
      top:
        sectionRefs[index].current.getBoundingClientRect().y -
        containerRef.current.getBoundingClientRect().top +
        containerRef.current.scrollTop,
    });
  }

  function getStationList(searchCriteria) {
    areasAdded.current = [];
    stationsAdded.current = [];

    let resultList = getAirportMatch(searchCriteria);

    resultList = resultList.concat(getAreaAirports(searchCriteria));
    resultList = resultList.concat(getNameMatch(searchCriteria));
    resultList = resultList.concat(getAliasMatch(searchCriteria));
    resultList = filterStations(resultList);
    resultList = sortStations(resultList, searchCriteria);
    resultList = removeAdjacentSeparators(resultList);
    resultList = removeEndingAreaSeparator(resultList);
    resultList = resultList.map((station) => highlightMatchArea(AIR, searchCriteria, station));

    return resultList;
  }

  function processValueOnExit() {
    const newValue = nearbyAirports && currentValue ? selections : currentValue;

    fireChangeEvent(getReturnValue(newValue));
  }

  function fireChangeEvent(newValue) {
    onChange?.(
      { target: { value: newValue } },
      { suppressTrigger: suppressValidationTriggerOnChange }
    );
  }

  function getReturnValue(newValue) {
    let returnValue = newValue;

    if (nearbyAirports) {
      returnValue = typeof newValue === "string" ? [newValue] : newValue;

      if (returnValue.length === 1) {
        const station = getStation(returnValue[0]) || getStationForName(returnValue[0]);

        returnValue = [station?.id ?? currentValue];
      } else {
        returnValue = returnValue.map(
          (displayName) => displayName && getStationForName(displayName).id
        );
      }
    }

    return returnValue;
  }

  function getAirportMatch(searchCriteria) {
    const airportMatch = getStation(searchCriteria);
    let resultList = [];

    if (airportMatch) {
      const region = getRegionMatch(airportMatch.id);

      if (airportMatch.children?.length || isInArea(airportMatch) || region) {
        resultList = getAreaAirports(airportMatch.id);
      } else {
        stationsAdded = addSimpleLabelValue(resultList, airportMatch, stationsAdded, CUSTOM);
      }
    }

    return resultList;
  }

  function getNameMatch(searchCriteria) {
    let resultList = [];

    stations.forEach((station) => {
      const { displayName } = station;
      const index = displayName.toUpperCase().indexOf(searchCriteria);

      if (index === 0) {
        const region = getRegionMatch(station.id);

        if (isInArea(station) || station.children?.length || region) {
          resultList = resultList.concat(getAreaAirports(station.id));
        } else {
          stationsAdded = addSimpleLabelValue(resultList, station, stationsAdded, CUSTOM);
        }
      }
    });

    stations.forEach((station) => {
      const { displayName } = station;
      const index = displayName.toUpperCase().indexOf(searchCriteria);

      if (index !== -1 && index !== 0) {
        const region = getRegionMatch(station.id);

        if (isInArea(station) || station.children?.length || region) {
          resultList = resultList.concat(getAreaAirports(station.id));
        } else {
          stationsAdded = addSimpleLabelValue(resultList, station, stationsAdded, CUSTOM);
        }
      }
    });

    return resultList;
  }

  function getAliasMatch(searchCriteria) {
    let resultList = [];

    stations.forEach((station) => {
      const { altSearchNames } = station;

      if (altSearchNames?.length) {
        altSearchNames.forEach((name) => {
          const index = name.toUpperCase().indexOf(searchCriteria);

          if (index !== -1) {
            const region = getRegionMatch(station.id);

            if (isInArea(station) || region) {
              resultList = resultList.concat(getAreaAirports(station.id));
            } else {
              stationsAdded = addSimpleLabelValue(resultList, station, stationsAdded, CUSTOM);
            }
          }
        });
      }
    });

    return resultList;
  }

  function getAreaAirports(airportCode) {
    const airportMatch = getStation(airportCode);
    const isNearbyAirportsDisabled = nearbyAirports === false;
    let resultList = [];

    if (airportMatch) {
      const { children, parents } = airportMatch;
      const region = getRegionMatch(airportMatch.id);

      if (children?.length || parents?.length || region) {
        const parent = region || (children?.length ? airportMatch : getStation(parents[0]));
        const parentId = region?.parentId || parent.id;

        if (areasAdded.current.indexOf(parentId) === -1) {
          const childStationCodes = region?.childAirportCodes || parent.children;

          // add area title to front of list
          resultList = [
            {
              className: classNames({
                [styles.separatorContent]:
                  isNearbyAirportsDisabled || region || isRegion(airportMatch.id),
              }),
              custom: region || isRegion(airportMatch.id) ? CUSTOM : CHECKBOX,
              disabled: isNearbyAirportsDisabled || region || isRegion(airportMatch.id),
              label: (
                <span className={styles.section}>
                  <span className={styles.label}>{region?.parentName || parent.displayName}</span>
                  <span className={styles.separator}> </span>
                </span>
              ),
              role: "none",
              value: region?.parentName || parent.displayName,
            },
          ];

          if (!children?.length) {
            stationsAdded = addSimpleLabelValue(
              resultList,
              airportMatch,
              stationsAdded,
              region || isRegion(airportMatch.id) ? CUSTOM : CHECKBOX,
              region || parent
            );
          }

          childStationCodes.forEach((stationCode) => {
            const station = getStation(stationCode);

            if (stationCode !== airportCode && station) {
              stationsAdded = addSimpleLabelValue(
                resultList,
                station,
                stationsAdded,
                getRegionMatch(stationCode) ? CUSTOM : CHECKBOX,
                region || parent
              );
            }
          });

          // add end of section separator
          resultList.push({
            className: styles.listItemSeparator,
            custom: true,
            disabled: true,
            label: (
              <span className={styles.section}>
                <span className={styles.separator}> </span>
              </span>
            ),
            role: "none",
            value: `${AREA_SEPARATOR}-${parentId}`,
          });

          areasAdded.current.push(parentId);
        }
      }
    }

    return resultList;
  }

  function getRegionMatch(stationId) {
    const regions = airportGroups.filter((group) => !group.multiSelectGroup);

    return regions.find((region) =>
      region.childAirportCodes.find((childAirportCode) => childAirportCode === stationId)
    );
  }

  function isArea(station) {
    return station?.isAirportGroup && !isRegion(station?.parents[0]);
  }

  function isInArea(station) {
    return station?.parents?.length && !isRegion(station?.parents[0]);
  }

  function isRegion(stationId) {
    return airportGroups.find(
      (region) => region.parentId === stationId && !region.multiSelectGroup
    );
  }

  function filterStations(resultList) {
    return resultList.filter(
      (station) => filteredStations.indexOf(getStationForName(station.value)?.id) === -1
    );
  }

  function sortStations(resultList, searchCriteria) {
    return resultList.sort((nextStation, currentStation) => {
      let sortIndex = 0;

      if (
        nextStation?.value.indexOf(AREA_SEPARATOR) !== -1 &&
        currentStation?.value.indexOf(AREA_SEPARATOR) !== -1
      ) {
        sortIndex =
          getStationMatch(currentStation, searchCriteria) -
          getStationMatch(nextStation, searchCriteria);
      }

      return sortIndex;
    });
  }

  function removeAdjacentSeparators(resultList) {
    const list = [];
    const numberListItems = resultList.length;
    let indexListItem;

    for (indexListItem = 0; indexListItem < numberListItems; indexListItem += 1) {
      const listItem = resultList[indexListItem];

      if (!(isSeparator(listItem.value) && isAreaName(resultList[indexListItem + 1]?.value))) {
        list.push(listItem);
      }
    }

    return list;
  }

  function isSeparator(itemValue) {
    return itemValue.indexOf(AREA_SEPARATOR) !== -1;
  }

  function isAreaName(itemValue) {
    return airportGroups.some((group) => group.parentName === itemValue);
  }

  function removeEndingAreaSeparator(resultList) {
    const lastListItem = resultList[resultList.length - 1];
    let list = resultList;

    if (resultList.length && lastListItem.value.indexOf(AREA_SEPARATOR) !== -1) {
      list = list.slice(0, -1);
    }

    return list;
  }

  function getStationMatch(data, searchCriteria) {
    return data.value?.toUpperCase()?.indexOf(searchCriteria);
  }
});

AutocompleteStations.propTypes = {
  /**
   * aria-describedby id to element which provides additional accessibility description of input
   * element.
   */
  "aria-describedby": PropTypes.string,

  /** Additional class for the component to provide a background color. */
  className: PropTypes.string,

  /**
   * Initial values to select in list. When nearbyAirports is set, then value will be an array,
   * otherwise a simple string should be provided.
   */
  defaultValue: PropTypes.string,

  /** Indicates Input should apply disabled styling and ignore mouse and key events. */
  disabled: autocompletePropTypes.disabled,

  /**
   * Indicates Dropdown should apply error styling and apply aria-invalid attribute -- in essence,
   * Input will provide error styling.
   */
  error: autocompletePropTypes.error,

  /** Stations codes that should not be shown in list of available selections. */
  filteredStations: PropTypes.arrayOf(PropTypes.string),

  /** ID to be added to input element. */
  id: autocompletePropTypes.id,

  /**
   * Required text to display when an airport entered cannot be a valid origin/destination pair.
   * invalidRouteText is only required when filteredStations is given.
   */
  invalidRouteText: PropTypes.string,

  /**
   * Text to appear about the search field that's displayed in the mobile view. This label would
   * typically be the same text that appears above the autocomplete field such as 'Depart' or 'Return'.
   */
  labelText: PropTypes.string.isRequired,

  /**
   * Total number of items visible. If more items available than can be displayed, they can be
   * scrolled into view.
   */
  maxItemsToDisplay: autocompletePropTypes.maxItemsToDisplay,

  /** Width of dropdown. See autocompletePropTypes/DropDown for more info. */
  minimumListWidth: autocompletePropTypes.minimumListWidth,

  /** Indicator to retrieve nearby airports in results set. */
  nearbyAirports: PropTypes.bool,

  /** Required text to display when no match is found. */
  noMatchFoundText: PropTypes.string.isRequired,

  /** Optional event handler to learn when focus is lost from input element. */
  onBlur: autocompletePropTypes.onBlur,

  /**
   * Optional callback to learn of selection changes. The values of the selected items will
   * be returned as an array of strings.
   */
  onChange: autocompletePropTypes.onChange,

  /** Option button to appear on left side of Input. See Input for more details. */
  option: autocompletePropTypes.option,

  /** Indicates AutocompleteStations's Input should be immutable and ignore mouse and key events. */
  readOnly: PropTypes.bool,

  /** Indicates Input should apply aria-required attribute. */
  required: PropTypes.bool,

  /**
   * Optional suppression of React-hook-form's trigger on-change. Thus preventing validation
   * check until the time specified by the form's mode.
   */
  suppressValidationTriggerOnChange: PropTypes.bool,

  /** Optional way to implicitly request the mobile view. */
  touchDevice: PropTypes.bool,

  /** To allow the application to "control" the Input's value, the value prop may be given. */
  value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
};

AutocompleteStations.defaultProps = {
  filteredStations: [],
  minimumListWidth: "large",
  nearbyAirports: false,
  suppressValidationTriggerOnChange: false,
};
