import PropTypes from "prop-types";
import React from "react";

export const Four = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path
        strokeWidth={0}
        d="M19,0C8.5,0,0,8.5,0,19s8.5,19,19,19s19-8.5,19-19S29.5,0,19,0z M24.7,23.7h-2v3.2h-2.9v-3.2h-6.4v-2.6
			l6.8-10h2.5v10h2V23.7z"
      />
      <polygon points="19.8,21.1 19.8,15.7 16.2,21.1 		" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      style,
      viewBox: "0 0 38 38",
    };
  }
};

Four.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Four.defaultProps = {
  role: "img",
};
