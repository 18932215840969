import PropTypes from "prop-types";
import React, { useContext } from "react";
import { Provider as UseHttpProvider } from "use-http";

import { FetchOptionsContext } from "../FetchOptionsContext";

/**
 * Provider component that supplies headers, interceptors and error handlers to useFetch.
 * See https://use-http.com/ for more details.
 */

const FetchProvider = (props) => {
  const { children } = props;
  const interceptorOptions = useContext(FetchOptionsContext);

  return <UseHttpProvider {...getProviderProps()}>{children}</UseHttpProvider>;

  function getProviderProps() {
    return {
      options: {
        interceptors: {
          request: handleRequest,
          response: handleResponse,
        },
        onError: handleError,
        ...interceptorOptions,
      },
    };
  }

  async function handleRequest({ options, url, path, route }) {
    return interceptorOptions.requestHandlers.reduce(
      (accumulatedOptions, requestHandler) => requestHandler(accumulatedOptions, url, path, route),
      options
    );
  }

  async function handleResponse({ response }) {
    return interceptorOptions.responseHandlers.reduce(
      (accumulatedResponse, requestHandler) => requestHandler(accumulatedResponse),
      response
    );
  }

  function handleError({ error }) {
    interceptorOptions.errorHandlers.map((errorHandler) => errorHandler(error));
  }
};

FetchProvider.propTypes = {
  /**
   * Child components that this component will provide configuration for.
   */
  children: PropTypes.node.isRequired,
};

export { FetchProvider };
