import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import { window } from "@swa-ui/browser";
import { classNames, getUniqueId } from "@swa-ui/string";

import { Caption } from "../Caption";
import { keyCodes } from "../defines/keyCodes";
import { Heading, headingPropTypes } from "../Heading";
import { Icon } from "../Icon";
import { Input, inputPropTypes } from "../Input";
import { SelectionMarker } from "../SelectionMarker";
import { Transform } from "../Transform";
import { createHashCode } from "../utils";
import styles from "./DropDown.module.scss";

const DELAY_FOR_BROWSER = process.env.NODE_ENV === "test" ? 500 : 100;
const DROPDOWN_ARROW_DESIGN_TOKEN = "cmp-core-color-dropdown-arrow";
const DROPDOWN_ARROW_FOCUS_DESIGN_TOKEN = "cmp-core-color-dropdown-arrow-focus";
const DROPDOWN_ICON_DESIGN_TOKEN = "cmp-core-color-dropdown-icon";
const HEIGHT_LARGE = 360;
const HEIGHT_MEDIUM = 288;
const HEIGHT_SMALL = 180;
const INIT_STEP_PREP = 0;
const INIT_STEP_SETUP = 1;
const INIT_STEP_INITIALIZED = 2;
const MARGIN_ALLOWANCE = 32;
const MASK_FADE_SIZE = 25;
const PADDING = 8;
const SCROLL_DURATION = 666;
const SCROLL_DURATION_IMMEDIATE = 0.001;
const XOFFSET_LARGE = -18;
const XOFFSET_SMALL = -8;
const XOFFSET_TRIGGER = -9;
const YOFFSET = -5;
const YOFFSET_TRIGGER = 0;

/**
 * DropDown provides the equivalent as a standard HTML select control, but using SWA brand styling
 * using the Caption component. DropDown also creates a hidden select control to allow autofill in
 * forms to preselect a DropDown option.
 */

export const DropDown = React.forwardRef((props, ref) => {
  const {
    allowSpaceBarToSelect = true,
    autoComplete = "off",
    bestFit = false,
    className,
    comboBox = false,
    defaultValue,
    disabled = false,
    error,
    fullScreen = false,
    headingProps,
    id,
    inputMode = "none",
    lineWrap = "wrap",
    list = [],
    maxHeight,
    maxItemsToDisplay = 10,
    minimumListWidth,
    moreContentIndicator = true,
    multiselectable = false,
    name,
    onBlur,
    onChange,
    onTriggerClick,
    onInputChange,
    option,
    readOnly = false,
    reinitOnValueChange = false,
    renderToken,
    required,
    showArrow = true,
    showPointer = true,
    size = "large",
    styleType,
    triggerComponent = "input",
    value = "",
    values = [],
    yOffset,
  } = props;
  const [captionState, setCaptionState] = useState("hidden");
  const [currentValue, setCurrentValue] = useState(value);
  const [highlightIndex, setHighlightIndex] = useState(-1);
  const [itemHeights, setItemHeights] = useState([]);
  const [items, setItems] = useState([]);
  const [recalculateHeights, setRecalculateHeights] = useState(false);
  const [scrollOffset, setScrollOffset] = useState(0);
  const componentId = useRef(name || getUniqueId("select-id"));
  const containerRef = useRef();
  const hasFocus = useRef(false);
  const headingRef = useRef();
  const init = useRef(false);
  const initializationStep = useRef(INIT_STEP_PREP);
  const inputRef = useRef();
  const itemRefs = useRef([]);
  const previousHash = useRef();
  const scroll = useRef(false);
  const touchMovement = useRef(0);
  const touchPosition = useRef(0);

  useEffect(() => {
    const hashCode = getHashOfValues();

    if (list?.length) {
      let initializedItems = items;

      if (previousHash.current !== hashCode) {
        initializedItems = initializeItems();
        const initialHighlightIndex = getInitialHighlightIndex(initializedItems);

        setItems(initializedItems);
        setHighlightIndex(initialHighlightIndex);
      }

      if (hasFocus.current && initializedItems?.length && captionState === "hidden") {
        setCaptionState(getCaptionLocation());
      } else if (previousHash.current !== hashCode) {
        setRecalculateHeights(!recalculateHeights);
        setScrollOffset(0);
      }
    } else if (!list?.length) {
      hideCaption();
      setItems([]);
      setItemHeights([]);
      itemRefs.current = [];
      setScrollOffset(0);
    }

    previousHash.current = hashCode;
  }, [list]);

  useEffect(() => {
    if (!init.current) {
      setCurrentValue(value === "" && defaultValue ? defaultValue : value);
      init.current = true;
    } else {
      setCurrentValue(value);

      if (reinitOnValueChange) {
        const initializedItems = initializeItems();

        setItems(initializedItems);
      }
    }
  }, [renderToken, value]);

  useEffect(() => {
    setItemHeights(getItemHeights());
  }, [recalculateHeights]);

  useEffect(() => {
    if (captionState !== "hidden") {
      window.addEventListener("wheel", handleWindowWheel, { passive: false }); // NOSONAR
      window.document.body.style.touchAction = "none";
    }

    return () => {
      window.removeEventListener("wheel", handleWindowWheel, {
        passive: false,
      });
      window.document.body.style.touchAction = "auto";
    };
  }, [captionState]);

  useEffect(() => {
    if (captionState !== "hidden") {
      setItemHeights(getItemHeights());
      ensureHighlightVisible(highlightIndex);
      initializationStep.current = INIT_STEP_SETUP;
    }
  }, [captionState]);

  useEffect(() => {
    if (initializationStep.current === INIT_STEP_SETUP) {
      initializationStep.current = INIT_STEP_INITIALIZED;
    }
  }, [initializationStep]);

  useEffect(() => {
    if (itemHeights.length) {
      ensureHighlightVisible(highlightIndex);
    }
  }, [itemHeights]);

  return (
    <div {...getContainerProps()}>
      {renderHtmlSelectElement()}
      <Caption {...getCaptionProps()}>{renderTrigger()}</Caption>
    </div>
  );

  function renderHtmlSelectElement() {
    return (
      <select {...getSelectProps()}>
        {items?.map((listItem, index) => (
          <option key={index}>{listItem.value}</option>
        ))}
      </select>
    );
  }

  function renderTrigger() {
    return isInputTrigger() ? (
      <Input {...getInputProps()} />
    ) : (
      <div {...getTriggerProps()}>
        <div>{getSelectedValue()}</div>
        <Icon {...getIconProps()} />
      </div>
    );
  }

  function renderList() {
    return (
      <div className={styles.captionContent}>
        {headingProps && <Heading {...headingProps} className={styles.heading} ref={headingRef} />}
        <div {...getOuterContainerProps()}>
          {highlightIndex !== -1 && itemHeights?.length && (
            <SelectionMarker {...getSelectionMarkerProps()} />
          )}
          <Transform {...getListContainerProps()}>{renderItems()}</Transform>
        </div>
      </div>
    );
  }

  function renderItems() {
    return <ul {...getListProps()}>{items.map(renderListItem)}</ul>;
  }

  function renderListItem(item, index) {
    return <li {...getListItemProps(item, index)}>{item.label}</li>;
  }

  function getContainerProps() {
    return {
      "aria-label": props["aria-label"],
      className: classNames(className),
    };
  }

  // TODO - Apply aria-hidden as true on the select element, since this is not visible to the user.
  // A custom select component is used by the user, so the screen reader should ignore the hidden native select HTML element.
  function getSelectProps() {
    return {
      className: styles.select,
      onBlur,
      onChange: handleSelectChange,
      required,
      tabIndex: -1,
    };
  }

  function getCaptionProps() {
    return {
      adjoinedContentClassName: classNames({
        [styles.fullWidth]: minimumListWidth === "full-width",
      }),
      adjoiningContent: items?.length ? renderList() : null,
      alignment: showArrow ? "right" : minimumListWidth === "full-width" ? "left" : "center",
      bestFit,
      constrainFocus: false,
      id: `${componentId.current}-caption`,
      location: disabled || readOnly ? "hidden" : captionState,
      mainClassName: styles.mainContent,
      pointerAlignment: showArrow ? "right" : "center",
      pointerXOffset: showArrow ? getPointerXoffset() : 0,
      showClose: fullScreen,
      showPointer,
      yOffset: yOffset || isInputTrigger() ? YOFFSET : YOFFSET_TRIGGER,
    };
  }

  function getInputProps() {
    return {
      "aria-activedescendant":
        captionState !== "hidden" ? `${getItemId(highlightIndex)}` : undefined,
      "aria-autocomplete": props["aria-autocomplete"],
      "aria-controls": `${componentId.current}-caption`,
      "aria-describedby": props["aria-describedby"],
      "aria-expanded": captionState !== "hidden",
      "aria-label": props["aria-label"],
      "aria-owns": `${componentId.current}-list`,
      "aria-required": required,
      autoComplete,
      className,
      "data-test": props["data-test"],
      disabled,
      error,
      id,
      inputMode,
      name,
      onBlur: handleBlur,
      onChange: onInputChange,
      onClick: onTriggerClick,
      onFocus: handleFocus,
      onInput: handleSelectChange,
      onKeyDown: handleKeyDown,
      onMouseDown: handleInputMouseDown,
      option,
      readOnly: readOnly || !comboBox,
      ref: ref ? setUpPassedRef : inputRef,
      required,
      role: "combobox",
      size,
      styleType,
      suffixIcon: showArrow ? getSuffixIconProps() : undefined,
      value: getSelectedValue(),
    };
  }

  function getTriggerProps() {
    return {
      "aria-activedescendant":
        captionState !== "hidden" ? `${getItemId(highlightIndex)}` : undefined,
      "aria-controls": `${componentId.current}-caption`,
      "aria-describedby": props["aria-describedby"],
      "aria-expanded": captionState !== "hidden",
      "aria-label": props["aria-label"],
      "aria-owns": `${componentId.current}-list`,
      className: getTriggerClass(),
      "data-test": props["data-test"],
      onBlur: handleBlur,
      onClick: onTriggerClick,
      onFocus: handleFocus,
      onKeyDown: handleKeyDown,
      onMouseDown: handleInputMouseDown,
      ref: ref ? setUpPassedRef : inputRef,
      role: "combobox",
      tabIndex: 0,
    };
  }

  function getIconProps() {
    return {
      className: styles.indicator,
      color: DROPDOWN_ICON_DESIGN_TOKEN,
      custom: { type: captionState === "hidden" ? "active" : "inactive" },
      name: "ArrowThin",
    };
  }

  function getPointerXoffset() {
    return isInputTrigger() ? (size === "large" ? XOFFSET_LARGE : XOFFSET_SMALL) : XOFFSET_TRIGGER;
  }

  function isInputTrigger() {
    return triggerComponent === "input";
  }

  function getSelectedValue() {
    const item = items[findIndexFromValue(items, currentValue)];

    return `${item?.displayValue ?? item?.value ?? currentValue}`;
  }

  function getListContainerProps() {
    const contentHeight = getTotalHeightAllItems();
    const containerHeight = getHeight();
    let maskEnd = 0;
    let maskEndStart = 0;
    let maskStart = 0;
    let maskStartEnd = 0;

    maskEnd = containerHeight + scrollOffset - PADDING;
    maskEndStart =
      maskEnd -
      Math.max(0, Math.min(MASK_FADE_SIZE, contentHeight - (scrollOffset + containerHeight)));
    maskStart = scrollOffset + PADDING;
    maskStartEnd = scrollOffset + Math.min(MASK_FADE_SIZE, scrollOffset);

    return {
      className: classNames({ [styles.moreContentIndicator]: moreContentIndicator }),
      duration:
        initializationStep.current < INIT_STEP_INITIALIZED
          ? SCROLL_DURATION_IMMEDIATE
          : SCROLL_DURATION,
      style: {
        "--mask-end": `${maskEnd}px`,
        "--mask-end-start": `${maskEndStart}px`,
        "--mask-start": `${maskStart}px`,
        "--mask-start-end": `${maskStartEnd}px`,
      },
      transformations: [
        {
          action: "translateY",
          amount: `${-scrollOffset}px`,
        },
      ],
    };
  }

  function getOuterContainerProps() {
    return {
      className: styles.outerContainer,
      ref: containerRef,
      style: {
        maxHeight: `${itemHeights?.length ? getHeight() : 0}px`,
        maxWidth: `${window.innerWidth - MARGIN_ALLOWANCE}px`,
      },
      tabIndex: -1,
    };
  }

  function getSelectionMarkerProps() {
    return {
      height: itemHeights[highlightIndex] ?? 0,
      yOffset:
        Math.max(getOffsetFromIndex(highlightIndex) - scrollOffset, 0) +
        PADDING +
        getHeadingHeight(),
    };
  }

  function getSuffixIconProps() {
    return {
      actions: ["rotate180"],
      color:
        captionState === "hidden" ? DROPDOWN_ARROW_FOCUS_DESIGN_TOKEN : DROPDOWN_ARROW_DESIGN_TOKEN,
      custom: captionState === "hidden" ? {} : { type: "active" },
      name: "ArrowThin",
    };
  }

  function getListProps() {
    return {
      "aria-multiselectable": multiselectable,
      className: getListClass(),
      id: `${componentId.current}-list`,
      onMouseMove: handleMouseMove,
      onTouchEnd: handleTouchEnd,
      onTouchMove: handleTouchMove,
      onTouchStart: handleTouchStart,
      onWheel: handleWheel,
      role: "listbox",
    };
  }

  function getListItemProps(listItem, index) {
    return {
      "aria-selected": multiselectable
        ? values.includes(listItem.value)
        : listItem.value === currentValue,
      className: getListItemClass(listItem),
      id: `${getItemId(index)}`,
      key: listItem.value,
      onClick: handleItemClick.bind(this, index),
      ref: (element) => (itemRefs.current[index] = element),
      role: "option",
      tabIndex: -1,
    };
  }

  function getListItemClass(listItem) {
    return classNames(styles.dropDownListItem, listItem.className, {
      [styles.disabled]: listItem.disabled,
      [styles.ellipsis]: lineWrap === "ellipsis",
      [styles.simple]: isSimple(listItem),
    });
  }

  function getTriggerClass() {
    return classNames(styles.trigger, {
      [styles.minimumTriggerWidthSmall]: minimumListWidth === "small",
      [styles.minimumTriggerWidthMedium]: minimumListWidth === "medium",
      [styles.minimumTriggerWidthLarge]: minimumListWidth === "large",
      [styles.minimumTriggerWidthXlarge]: minimumListWidth === "xlarge",
      [styles.minimumTriggerWidthXsmall]: minimumListWidth === "xsmall",
    });
  }

  function getListClass() {
    return classNames({
      [styles.minimumListWidthSmall]: minimumListWidth === "small",
      [styles.minimumListWidthMedium]: minimumListWidth === "medium",
      [styles.minimumListWidthLarge]: minimumListWidth === "large",
      [styles.minimumListWidthXlarge]: minimumListWidth === "xlarge",
      [styles.minimumListWidthXsmall]: minimumListWidth === "xsmall",
    });
  }

  function handleWindowWheel(event) {
    const parentElement = event.target.closest("li");

    if (
      parentElement &&
      [...parentElement.classList].find(
        (listClassName) => listClassName.indexOf("dropDownListItem") !== -1
      )
    ) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  function handleBlur(event) {
    hasFocus.current = false;

    if (!findParentListItemForTarget(event.relatedTarget)) {
      hideCaption();

      if (event.target.value !== undefined) {
        onBlur?.(event);
        event.stopPropagation();
        event.preventDefault();
      }
    }
  }

  function findParentListItemForTarget(target) {
    return containerRef?.current && containerRef.current.contains(target);
  }

  function handleInputMouseDown() {
    if (captionState === "hidden" && !readOnly) {
      showCaption();
    } else {
      hasFocus.current && hideCaption();
    }
  }

  function handleFocus() {
    hasFocus.current = true;
    !readOnly && showCaption();
  }

  function handleKeyDown(event) {
    const { key } = event;

    if (isNavigationKey(key)) {
      processNavigation(event);
    } else if (key === keyCodes.KEY_ENTER) {
      processEnter(event);
    } else if (key === keyCodes.KEY_ESCAPE) {
      processEscape();
    } else if (key === keyCodes.KEY_SPACE) {
      processSpace(event);
    } else if (!comboBox && isSearchableKey(key)) {
      processSearch(event);
    }
  }

  function isNavigationKey(key) {
    return (
      [
        keyCodes.KEY_DOWN,
        keyCodes.KEY_END,
        keyCodes.KEY_HOME,
        keyCodes.KEY_LEFT,
        keyCodes.KEY_PAGE_DOWN,
        keyCodes.KEY_PAGE_UP,
        keyCodes.KEY_RIGHT,
        keyCodes.KEY_UP,
      ].indexOf(key) !== -1
    );
  }

  function isSearchableKey(key) {
    return "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890".indexOf(key) !== -1;
  }

  function processSearch(event) {
    const newHighlightIndex = findMatchingItem(event.key);

    event.preventDefault();
    event.stopPropagation();
    showCaption();

    if (newHighlightIndex !== -1) {
      setHighlightIndex(newHighlightIndex);
      ensureHighlightVisible(newHighlightIndex);
    }
  }

  function findMatchingItem(key) {
    let matchingItemIndex = -1;

    if (isListSearchable()) {
      const keyToMatch = key.toUpperCase();

      matchingItemIndex = findMatchingItemInSection(keyToMatch, highlightIndex + 1);

      if (matchingItemIndex === -1) {
        matchingItemIndex = findMatchingItemInSection(keyToMatch, 0, highlightIndex + 1);
      }
    }

    return matchingItemIndex;
  }

  function isListSearchable() {
    return areAllLabelsStrings();
  }

  function areAllLabelsStrings() {
    return items.every((item) => isSimple(item));
  }

  function findMatchingItemInSection(keyToMatch, startingIndex, lastIndex) {
    const workingItems = items.slice(startingIndex, lastIndex);
    const indexMatch = workingItems.findIndex(
      (item) => !item.disabled && item.label.substring(0, 1).toUpperCase() === keyToMatch
    );

    return indexMatch === -1 ? -1 : indexMatch + startingIndex;
  }

  function processNavigation(event) {
    const { key } = event;

    if (captionState === "hidden") {
      showCaption();
    } else if (key === keyCodes.KEY_DOWN) {
      processDown(event);
    } else if (key === keyCodes.KEY_END) {
      processEnd(event);
    } else if (key === keyCodes.KEY_LEFT) {
      !comboBox && processUp(event);
    } else if (key === keyCodes.KEY_HOME) {
      processHome(event);
    } else if (key === keyCodes.KEY_PAGE_DOWN) {
      processPageDown(event);
    } else if (key === keyCodes.KEY_PAGE_UP) {
      processPageUp(event);
    } else if (key === keyCodes.KEY_RIGHT) {
      !comboBox && processDown(event);
    } else if (key === keyCodes.KEY_UP) {
      processUp(event);
    }
  }

  function processDown(event) {
    const newHighlightIndex = getNextHighlightIndex(highlightIndex);

    if (newHighlightIndex === highlightIndex) {
      scrollToBottom();
    } else {
      setHighlightIndex(newHighlightIndex);
      ensureHighlightVisible(newHighlightIndex);
    }
    event.preventDefault();
  }

  function processUp(event) {
    const newHighlightIndex = getPreviousHighlightIndex(highlightIndex);

    if (newHighlightIndex === highlightIndex) {
      setScrollOffset(0);
    } else {
      setHighlightIndex(newHighlightIndex);
      ensureHighlightVisible(newHighlightIndex);
    }
    event.preventDefault();
  }

  function processEnd(event) {
    let newHighlightIndex = items.length - 1;

    if (items[newHighlightIndex].disabled) {
      newHighlightIndex = getPreviousHighlightIndex(newHighlightIndex);
      scrollToBottom();
    } else {
      ensureHighlightVisible(newHighlightIndex);
    }

    setHighlightIndex(newHighlightIndex);
    event.preventDefault();
  }

  function processHome(event) {
    let newHighlightIndex = 0;

    if (items[newHighlightIndex].disabled) {
      newHighlightIndex = getNextHighlightIndex(0);
      setScrollOffset(0);
    } else {
      ensureHighlightVisible(newHighlightIndex);
    }

    setHighlightIndex(newHighlightIndex);
    event.preventDefault();
  }

  function getPreviousHighlightIndex(index) {
    const itemsAboveHighlight = items.slice(0, index).reverse();
    const workingHighlightIndex = itemsAboveHighlight.findIndex((item) => !item.disabled);
    let newHighlightIndex = index;

    if (workingHighlightIndex !== -1) {
      newHighlightIndex = index - workingHighlightIndex - 1;
    }

    return newHighlightIndex;
  }

  function scrollToBottom() {
    const containerHeight = getContainerRect().height;
    const totalHeightAllItems = getTotalHeightAllItems();

    setScrollOffset(totalHeightAllItems - containerHeight);
  }

  function processPageDown(event) {
    const maxScrollOffset = getMaxScrollOffset();
    const newHighlightIndex = getFirstItemNextPage();
    const offset = getOffsetFromIndex(newHighlightIndex);

    setHighlightIndex(newHighlightIndex);
    setScrollOffset(offset > maxScrollOffset ? maxScrollOffset : offset);
    event.preventDefault();
  }

  function processPageUp(event) {
    const containerHeight = getContainerRect().height;
    const newHighlightIndex = getLastItemPreviousPage();
    const offset = getOffsetFromIndex(newHighlightIndex);

    setHighlightIndex(newHighlightIndex);
    setScrollOffset(
      Math.max(offset + getCurrentItemHeight(newHighlightIndex) - containerHeight, 0)
    );
    event.preventDefault();
  }

  function getLastItemPreviousPage() {
    return getPreviousHighlightIndex(getFirstVisibleItem());
  }

  function getFirstItemNextPage() {
    return getNextHighlightIndex(getLastVisibleItem());
  }

  function getLastVisibleItem() {
    const containerHeight = getContainerRect().height;
    let index = findIndexFromOffset(containerHeight - 1 + scrollOffset);

    if (items[index].disabled) {
      index = getPreviousHighlightIndex(index);
    }

    return index;
  }

  function processEscape() {
    hideCaption();
  }

  function processSpace(event) {
    if (captionState === "hidden") {
      showCaption();
    } else {
      allowSpaceBarToSelect && processEnter(event);
    }
  }

  function processEnter(event) {
    const listItem = items[highlightIndex];

    if (captionState === "hidden") {
      showCaption();
    } else if (!listItem?.disabled && listItem?.onClick) {
      listItem.onClick(event);

      if (listItem.closeOnSelect) {
        delayCloseCaption();
      }
    } else {
      highlightIndex !== -1 && setNewSelection(highlightIndex);
      delayCloseCaption();
    }

    event.stopPropagation();
    event.preventDefault();
  }

  function handleItemClick(index, event) {
    const listItem = items[index];

    if (!scroll.current) {
      if (listItem.disabled) {
        event.preventDefault();
      } else if (listItem.onClick) {
        listItem.onClick(listItem.value);
        event.stopPropagation();
        event.preventDefault();
        inputRef?.current?.focus();

        if (listItem.closeOnSelect) {
          delayCloseCaption();
        }
      } else {
        setNewSelection(index);
        inputRef?.current?.focus();
        delayCloseCaption();
      }
    }
  }

  function delayCloseCaption() {
    window.setTimeout(() => hideCaption(), DELAY_FOR_BROWSER);
  }

  function handleMouseMove(event) {
    const mousePosition = event.clientY - getContainerRect().top + scrollOffset;

    if (mousePosition >= scrollOffset) {
      const newHighlightIndex = findIndexFromOffset(mousePosition);

      if (!items[newHighlightIndex].disabled) {
        setHighlightIndex(newHighlightIndex);
      }
    }
  }

  function handleSelectChange(event) {
    setHighlightIndex(getIndexFromValueForBeginMatch(items, event.target.value));
    setCurrentValue(event.target.value);
  }

  function getIndexFromValueForBeginMatch(initializedItems, valueEntered) {
    const searchValue = valueEntered.toUpperCase();
    const searchLength = valueEntered.length;
    let index = initializedItems.findIndex(
      (item) => item.value.substring(0, searchLength).toUpperCase() === searchValue
    );

    if (index !== -1 && initializedItems[index].disabled) {
      index = getNextHighlightIndex(index, initializedItems);
    }

    return index;
  }

  function handleTouchEnd() {
    scroll.current = false;
    touchMovement.current = 0;
    touchPosition.current = 0;
  }

  function handleTouchMove(event) {
    const currentPosition = event.changedTouches[0]?.pageY;
    let delta = Math.round(touchMovement.current + (touchPosition.current - currentPosition));

    event.preventDefault();
    event.stopPropagation();

    if (Math.abs(delta) > getCurrentItemHeight(highlightIndex)) {
      delta > 0 ? moveDown() : moveUp();
      delta = 0;
    }

    touchMovement.current = delta;
    touchPosition.current = currentPosition;
  }

  function handleTouchStart(event) {
    scroll.current = true;
    touchPosition.current = event.changedTouches[0]?.pageY;
  }

  function handleWheel(event) {
    if (event.deltaY < -3) {
      moveUp();
    } else if (event.deltaY > 3) {
      moveDown();
    }

    const newHighlightIndex = findIndexFromOffset(getOffsetOfMouse(event.clientY) + scrollOffset);

    if (!items[newHighlightIndex].disabled) {
      setHighlightIndex(newHighlightIndex);
    }

    ensureHighlightVisible(newHighlightIndex);
  }

  function ensureHighlightVisible(newHighlightIndex) {
    if (isItemBelowView(newHighlightIndex)) {
      const containerHeight = getContainerRect().height;
      const offset = getOffsetFromIndex(newHighlightIndex);

      setScrollOffset(offset + getCurrentItemHeight(newHighlightIndex) - containerHeight);
    } else if (isItemAboveView(newHighlightIndex)) {
      const offset = getOffsetFromIndex(newHighlightIndex);

      setScrollOffset(offset);
    }
  }

  function isItemBelowView(index) {
    const containerHeight = getContainerRect().height;

    return getOffsetFromIndex(index) + getCurrentItemHeight(index) > scrollOffset + containerHeight;
  }

  function isItemAboveView(index) {
    return getOffsetFromIndex(index) < scrollOffset;
  }

  function moveDown() {
    const firstVisibleItem = getFirstVisibleItem();
    const offset = getOffsetFromIndex(firstVisibleItem) + getCurrentItemHeight(firstVisibleItem);

    setScrollOffset(Math.min(offset, getMaxScrollOffset()));
  }

  function moveUp() {
    const firstVisibleIndex = findIndexFromOffset(scrollOffset);
    const offset = getOffsetFromIndex(firstVisibleIndex) - getCurrentItemHeight(firstVisibleIndex);

    setScrollOffset(Math.max(offset, 0));
  }

  function getCaptionLocation() {
    return fullScreen ? "full-screen" : "below";
  }

  function getFirstVisibleItem() {
    let index = findIndexFromOffset(scrollOffset);

    if (items[index].disabled) {
      index = getNextHighlightIndex(index);
    }

    return index;
  }

  function getHeadingHeight() {
    return headingRef?.current?.getBoundingClientRect().height ?? 0;
  }

  function getHeight() {
    const maxHeights = {
      large: HEIGHT_LARGE,
      medium: HEIGHT_MEDIUM,
      small: HEIGHT_SMALL,
    };

    return maxHeight && !fullScreen ? maxHeights[maxHeight] : getOffsetFromIndex(getMaxItems());
  }

  function getOffsetFromIndex(index) {
    const heights = index > 0 ? itemHeights.slice(0, index) : [];

    return heights.reduce((partialSum, height) => partialSum + height, 0);
  }

  function getMaxScrollOffset() {
    const containerHeight = getContainerRect().height;
    const visibleArea = containerHeight - PADDING * 2;

    return getTotalHeightAllItems() - visibleArea;
  }

  function getOffsetOfMouse(clientY) {
    const containerTop = getContainerRect().top;

    return clientY - containerTop - PADDING;
  }

  function getContainerRect() {
    return containerRef?.current?.getBoundingClientRect();
  }

  function getHashOfValues() {
    return createHashCode(stringifyValues());
  }

  function stringifyValues() {
    return getValues().reduce((accumulator, workingValue) => accumulator + workingValue, 0);
  }

  function getValues() {
    return list.map((item) => `${item.value}`);
  }

  function initializeItems() {
    let initializedItems = list;

    if (list?.length) {
      const firstListItem = list[0];

      if (typeof firstListItem === "number" || typeof firstListItem === "string") {
        initializedItems = list.map((item) => ({
          displayValue: item,
          label: item,
          value: item,
        }));
      }
    }

    return initializedItems;
  }

  function getInitialHighlightIndex(initializedItems) {
    const workingValue = value || defaultValue;
    let workingIndex = -1;

    if (workingValue) {
      workingIndex = findIndexFromValue(initializedItems, workingValue);
    }

    if (workingIndex === -1) {
      workingIndex = getNextHighlightIndex(-1, initializedItems);
    }

    return workingIndex;
  }

  function getItemHeights() {
    const refs = itemRefs.current.filter((item) => item);

    return refs.map((item) => item.getBoundingClientRect().height);
  }

  function getNextHighlightIndex(index, workingItems) {
    const itemsBelowHighlight = (workingItems ?? items).slice(index + 1);
    const workingHighlightIndex = itemsBelowHighlight.findIndex((item) => !item.disabled);
    let newHighlightIndex = index;

    if (workingHighlightIndex !== -1) {
      newHighlightIndex = workingHighlightIndex + index + 1;
    }

    return newHighlightIndex;
  }

  function setNewSelection(newSelectedIndex) {
    const newValue = items[newSelectedIndex]?.value ?? "";

    // TODO this causes a flash -- see full airport name then replaced with airport code
    setCurrentValue(newValue);
    onChange?.({ target: { value: newValue } });
  }

  function setUpPassedRef(element) {
    ref(element);
    inputRef.current = element;
  }

  function isSimple(listItem) {
    return typeof listItem.label === "string";
  }

  function hideCaption() {
    setCaptionState("hidden");
  }

  function showCaption() {
    setCaptionState(items.length ? getCaptionLocation() : "hidden");
  }

  function getMaxItems() {
    return fullScreen ? items.length : Math.min(maxItemsToDisplay, items.length);
  }

  function getTotalHeightAllItems() {
    return itemHeights.reduce((accumulator, workingValue) => accumulator + workingValue, 0);
  }

  function findIndexFromOffset(offset) {
    const itemCount = items.length;
    let accumulated = 0;

    const index = itemHeights.findIndex((height) => {
      accumulated += height;

      return accumulated > offset;
    });

    return index === -1 ? itemCount - 1 : index;
  }

  function getCurrentItemHeight(index) {
    return itemHeights[index];
  }

  function findIndexFromValue(initializedItems, workingValue) {
    return initializedItems.findIndex((item) => item.value === workingValue);
  }

  function getItemId(index) {
    return `${componentId.current}-item-${index}`;
  }
});

export const dropDownPropTypes = {
  /**
   * For DropDown, pressing select will select the currently highlighted item, but when DropDown is
   * used by Autocomplete, this behavior is not desirable and can be disabled.
   */
  allowSpaceBarToSelect: PropTypes.bool,

  /** The aria-autocomplete property describes the type of autocompletion interaction model
   *  a combobox will use when dynamically helping users complete text input. */
  "aria-autocomplete": PropTypes.oneOf(["none", "inline", "list", "both"]),

  /**
   * aria-describedby id to element which provides additional accessibility description of input
   * element.
   */
  "aria-describedby": PropTypes.string,

  /** aria-label text to provide additional accessibility description of input element. */
  "aria-label": PropTypes.string,

  /** Value to pass to Input component to facilitate auto-fill. */
  autoComplete: PropTypes.string,

  /**
   * Indicator if DropDown should allow Caption component to find best placement in window. See
   * Caption component for more info.
   */
  bestFit: PropTypes.bool,

  /**
   * Additional classes for positioning the component. Given classes may only position this component
   * for layout purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * Indicator if Input component will accept input, or if DropDown will only allow selection from
   * the list.
   */
  comboBox: PropTypes.bool,

  /** Name to use for the data-test attribute used for automated testing. */
  "data-test": PropTypes.string,

  /** Initial value to select in list. */
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  /** Indicates Dropdown should apply disabled styling and ignore mouse and key events. */
  disabled: PropTypes.bool,

  /**
   * Indicates Dropdown should apply error styling and apply aria-invalid attribute -- in essence,
   * Input will provide error styling.
   */
  error: PropTypes.bool,

  /** Indicates whether the list of items should be displayed in full screen mode. */
  fullScreen: PropTypes.bool,

  /**
   * Content to be displayed in flyout above the selection list. See Heading component for available
   * options.
   */
  headingProps: PropTypes.shape(headingPropTypes),

  /** ID to be added to input element. */
  id: PropTypes.string,

  /**
   * Optional string to tell mobile device browsers to display an appropriate keyboard for the type
   * of input.
   */
  inputMode: inputPropTypes.inputMode,

  /**
   * When lines are too long to fit the given width, lines can either wrap or show ellipsis to
   * denote more text.
   */
  lineWrap: PropTypes.oneOf(["ellipsis", "wrap"]),

  /**
   * Options to display in select list.
   * List items can be simple string array, or an array of numbers.
   * Alternately, the list can be JSX markup items. When this option is used, all items must
   * define the label, and value fields. If displayValue not given, value will be used to display
   * the selection in the input field.
   * closeOnSelect is only recognized when onClick is given and it can be set to true to prevent
   * the DropDown from closing when a selection is made.
   */
  list: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(
      PropTypes.shape({
        closeOnSelect: PropTypes.bool,
        disabled: PropTypes.bool,
        displayValue: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
        label: PropTypes.oneOfType([PropTypes.node, PropTypes.number, PropTypes.string]),
        onClick: PropTypes.func,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      })
    ),
  ]),

  /**
   * Maximum height of Caption. When all list items are the same height, then using maxItemsToDisplay
   * will probably be preferred. But, DropDown can accept selection of varying heights, so
   * maxItemsToDisplay loses its meaning, and using maxHeight is probably better. If maxHeight
   * given, then it will be used and maxItemsToDisplay will be ignored.
   */
  maxHeight: PropTypes.oneOf(["large", "medium", "small"]),

  /**
   * Total number of items visible. If more items available than can be displayed, they can be
   * scrolled into view.
   */
  maxItemsToDisplay: PropTypes.number,

  /**
   * Minimum width for drop down list. Normally the width of the list is governed by the content in
   * the list. But this can cause the width to change as the traveler is paging through the list. In
   * these cases, and when a more specific size is desired (like when the list is typically very
   * narrow), DropDown allows for a specific size to be defined.
   */
  minimumListWidth: PropTypes.oneOf(["full-width", "large", "medium", "small", "xlarge", "xsmall"]),

  /** Denotes if a "faded edge" will be rendered to indicate more content is scrollable. */
  moreContentIndicator: PropTypes.bool,

  /** Optional flag to specify if multiple options can be selected. */
  multiselectable: PropTypes.bool,

  /** Name assigned to the select form control. */
  name: PropTypes.string,

  /** Optional event handler to learn when focus is lost from input element. */
  onBlur: PropTypes.func,

  /**
   * Optional callback to learn of selection changes. The value of the selected item will be the
   * only parameter.
   */
  onChange: PropTypes.func,

  /** Optional callback to learn of changes to Input when readOnly is false. */
  onInputChange: PropTypes.func,

  /**
   * Optional callback fired on trigger click.
   */
  onTriggerClick: PropTypes.func,

  /** Text to display for button "overlaid" on right side of input. */
  option: inputPropTypes.option,

  /** Indicates DropDown's Input should be immutable and ignore mouse and key events. */
  readOnly: PropTypes.bool,

  /**
   * Typically when the value prop is changed, the list shouldn't be reinitialized and DropDown
   * completely re-rendered. There are times however, like when DropDown is used by Multiselect,
   * that the list should be completely reinitialized.
   */
  reinitOnValueChange: PropTypes.bool,

  /**
   * renderToken provides a way to force a render. Typically DropDown will re-render when a value
   * changes, but providing a different value since last render will rensure re-render.
   */
  renderToken: PropTypes.string,

  /** Indicates DropDown should apply aria-required attribute. */
  required: PropTypes.bool,

  /** Indicates if the suffix arrow icon is displayed. */
  showArrow: PropTypes.bool,

  /** Indicates if the caret icon is displayed in results list. */
  showPointer: PropTypes.bool,

  /** Indicates the height and padding of the input element. */
  size: inputPropTypes.size,

  /** Indicates the style of the input element. */
  styleType: PropTypes.oneOf(["primary", "secondary"]),

  /** Clickable component (trigger) to show selection. */
  triggerComponent: PropTypes.oneOf(["input", "trigger"]),

  /** Value to be maintained by caller. This will define DropDown as a controlled component. */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  /** Array of selected values when the component is multiselectable. */
  values: PropTypes.array,

  /** Positive or negative value to nudge the flyout vertically. */
  yOffset: PropTypes.number,
};

DropDown.displayName = "DropDown";

DropDown.propTypes = dropDownPropTypes;
