import PropTypes from "prop-types";

const backgroundProperties = PropTypes.shape({
  color: PropTypes.string,
  imagePath: PropTypes.string,
  overrides: PropTypes.shape({
    large: PropTypes.shape({
      color: PropTypes.string,
      imagePath: PropTypes.string,
      type: PropTypes.oneOf(["color", "image"]),
    }),
    medium: PropTypes.shape({
      color: PropTypes.string,
      imagePath: PropTypes.string,
      type: PropTypes.oneOf(["color", "image"]),
    }),
    small: PropTypes.shape({
      color: PropTypes.string,
      imagePath: PropTypes.string,
      type: PropTypes.string,
    }),
  }),
  type: PropTypes.oneOf(["color", "image"]),
});

const callToActionPropType = PropTypes.shape({
  buttonType: PropTypes.string,
  newWindow: PropTypes.bool,
  target: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.string,
});

const cardAlignmentPropType = PropTypes.shape({
  align: PropTypes.string,
  overrides: PropTypes.shape({
    large: PropTypes.shape({
      align: PropTypes.string.isRequired,
    }),
    medium: PropTypes.shape({
      align: PropTypes.string.isRequired,
    }),
    small: PropTypes.shape({
      align: PropTypes.string.isRequired,
    }),
  }),
});

const textPropType = PropTypes.shape({
  color: PropTypes.string,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.string,
  value: PropTypes.string.isRequired,
});

export { backgroundProperties, callToActionPropType, cardAlignmentPropType, textPropType };
