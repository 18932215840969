import { withFormField } from "@swa-ui/form";

import { AutocompleteVacationDestinations } from "../AutocompleteVacationDestinations";

/**
 * AutocompleteVacationDestinationsFormField provides support for a standard AutocompleteVacationDestinations
 * component to be used in a Form.
 */

export const AutocompleteVacationDestinationsFormField = withFormField(
  AutocompleteVacationDestinations,
  {
    useController: true,
  }
);
