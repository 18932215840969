import PropTypes from "prop-types";
import React from "react";

import { classNames } from "@swa-ui/string";

import styles from "./Pulse.module.scss";

export const Pulse = (props) => {
  const { animate, children, className, delayIndex } = props;

  return <div {...getProps()}>{children}</div>;

  function getProps() {
    return {
      "aria-hidden": true,
      className: classNames(className, { [styles.animated]: animate }),
      key: delayIndex,
      style: getStyle(),
    };
  }

  function getStyle() {
    const delayBase = 3;
    const animationDelay = `${-(delayBase - delayIndex / 10)}s`;

    return {
      animationDelay,
    };
  }
};

Pulse.propTypes = {
  /** animate indicates if the Pulse is active. */
  animate: PropTypes.bool,

  /** children content is what will be "pulsed". This can be anything such as an icon or a simple div. */
  children: PropTypes.node.isRequired,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * delayIndex is used to cascade the animation when multiple Pulse components are rendered. This value is also used
   * for the React key.
   */
  delayIndex: PropTypes.number,
};

Pulse.defaultProps = {
  animate: true,
  delayIndex: 0,
};
