import PropTypes from "prop-types";
import React from "react";

import { ButtonGroup } from "@swa-ui/core";

import styles from "./ButtonGroupAdapter.module.scss";

/**
 * ButtonGroupAdapter renders a list of buttons using button group component
 */

export const ButtonGroupAdapter = (props) => {
  const { placement } = props;

  return <ButtonGroup {...getButtonGroupProps()} />;

  function getButtonGroupProps() {
    const defaultSelectedIndex = -1;
    const buttonList = placement.items.map((item) => ({
      label: item.text,
      props: {
        href: item.props?.target,
        ...item.props,
      },
    }));

    return {
      buttonList,
      className: styles.buttonGroup,
      defaultSelectedIndex,
      showSelected: false,
      spaceBetween: "small",
    };
  }
};

ButtonGroupAdapter.propTypes = {
  placement: PropTypes.shape({
    /** Label of each button. */
    label: PropTypes.string,

    /** Object that contains button components props. */
    props: PropTypes.arrayOf(PropTypes.object),
  }),
};
