import PropTypes from "prop-types";
import React from "react";

/*
 * The HighlightSubstring React component emphasizes a portion of string by wrapping part of the
 * string with a <strong> element.
 *
 * Example:
 * value: "The brown fox jumps"
 * substring: "fox"
 *
 * Return
 * "The brown <strong>fox</strong> jumps"
 */

export const HighlightSubstring = (props) => {
  const { substring, value } = props;

  return renderString();

  function renderString() {
    let content = value;

    if (value !== undefined && substring !== undefined) {
      const lowercaseItem = value.toLowerCase();
      const matchIndex = lowercaseItem.lastIndexOf(substring.toLowerCase());

      if (matchIndex !== -1) {
        content = (
          <>
            {value.substring(0, matchIndex)}
            <strong>{value.substring(matchIndex, matchIndex + substring.length)}</strong>
            {value.substring(matchIndex + substring.length)}
          </>
        );
      }
    }

    return content;
  }
};

HighlightSubstring.propTypes = {
  /** The substring that needs to be highlighted within the value. */
  substring: PropTypes.string,

  /** The value to be displayed potentially with the substring to be highlighted. */
  value: PropTypes.string,
};
