import PropTypes from "prop-types";
import React from "react";

export const Five = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path
        strokeWidth={0}
        d="M19,0C8.5,0,0,8.5,0,19s8.5,19,19,19s19-8.5,19-19S29.5,0,19,0z M23.2,24.8c-1,1.4-2.4,2.1-4.3,2.1
		c-1.5,0-2.6-0.4-3.6-1.2c-0.9-0.8-1.5-1.8-1.6-3.1l3-0.3c0.1,0.7,0.3,1.2,0.8,1.6c0.4,0.4,0.9,0.6,1.5,0.6s1.2-0.3,1.6-0.8
		s0.7-1.3,0.7-2.3s-0.2-1.7-0.7-2.2s-1-0.7-1.7-0.7c-0.9,0-1.6,0.4-2.3,1.1l-2.4-0.4l1.5-8.2h8v2.8H18l-0.5,2.7
		c0.7-0.3,1.4-0.5,2.1-0.5c1.3,0,2.5,0.5,3.4,1.5s1.4,2.2,1.4,3.8C24.3,22.6,23.9,23.8,23.2,24.8z"
      />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      style,
      viewBox: "0 0 38 38",
    };
  }
};

Five.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Five.defaultProps = {
  role: "img",
};
