import { withFormField } from "@swa-ui/form";

import { StateAndCountryAutocomplete } from "../StateAndCountryAutocomplete";

/**
 * StateFormField provides support for a StateAndCountryAutocomplete component to be used in a Form.
 */

export const StateFormField = withFormField(StateAndCountryAutocomplete, {
  useController: true,
});
