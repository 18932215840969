import PropTypes from "prop-types";
import React from "react";

export const NFLRedzone = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path d="M27.24,4.76C26.16,3.68,22.49,4,20.11,4.3l7.59,7.59c.33-2.38.62-6.05-.46-7.13" />
      <path d="M4.76,27.24c1.08,1.08,4.75.79,7.13.46L4.3,20.11c-.33,2.38-.62,6.05.46,7.13" />
      <path d="M20.8,15a.92.92,0,0,1-1.3,0l-.61-.61L17.3,16l.61.61a.92.92,0,0,1-1.3,1.3L16,17.3l-1.59,1.59.61.61a.92.92,0,0,1-1.3,1.3L11.2,18.28A.92.92,0,0,1,12.5,17l.61.61L14.7,16l-.61-.61a.92.92,0,0,1,1.3-1.3l.61.61,1.59-1.59L17,12.5a.92.92,0,0,1,1.3-1.3l2.52,2.52a.92.92,0,0,1,0,1.3M17.75,5.11A17.58,17.58,0,0,0,5.11,17.76l9.13,9.13A17.58,17.58,0,0,0,26.89,14.25Z" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "4 4 24 24",
    };
  }
};

NFLRedzone.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

NFLRedzone.defaultProps = {
  role: "img",
};
