import PropTypes from "prop-types";
import React, { useState } from "react";

import { Button } from "@swa-ui/core";

/**
 * ObjectVisual is a simple component that recursively renders a JSX-friendly representation of an
 * JavaScript object data type. Nested objects are hidden with ellipses (...) by default to avoid deeply nested
 * object values from cluttering the rendered output.
 */

export const ObjectVisual = (props) => {
  const { object = {}, showBraces = true } = props;
  const objectKeys = Object.keys(object);
  const shouldRender = objectKeys.length > 0;
  const sanitizedContent = getSanitizedContent();
  const [revealed, setRevealed] = useState(false);

  return (
    <span>
      {showBraces && <>&#123;</>}
      {!showBraces || revealed ? (
        <ul style={{ padding: "0 1rem" }}>{sanitizedContent}</ul>
      ) : (
        shouldRender && (
          <Button
            styleType="no-style"
            onClick={() => {
              setRevealed(true);
            }}
          >
            ...
          </Button>
        )
      )}
      {showBraces && <>&#125;</>}
      {revealed && (
        <Button
          styleType="no-style"
          onClick={() => {
            setRevealed(false);
          }}
        >
          hide
        </Button>
      )}
    </span>
  );

  function getSanitizedContent() {
    return objectKeys?.map((key) => {
      const normalizedValue = normalizeValue(object[key]);

      return (
        <li key={key}>
          <b>{key}:</b> {normalizedValue}
        </li>
      );
    });
  }

  function normalizeValue(value) {
    let content = value;

    if (typeof value === "object" && value !== null && value !== undefined) {
      if (Array.isArray(value)) {
        content = `[ ${value.map(normalizeValue).join(", ")} ]`;
      } else {
        content = <ObjectVisual object={value} normalizeValue={normalizeValue} />;
      }
    } else if (typeof value === "boolean") {
      content = `${value}`;
    } else if (typeof value === "string") {
      content = `"${value}"`;
    } else if (typeof value === "function") {
      const shouldInvoke = value?.name?.startsWith("is");

      content = shouldInvoke ? `${value()}` : <i>fn()</i>;
    }

    return content ?? `${content}`;
  }
};

ObjectVisual.propTypes = {
  /**
   * Object to visualize.
   */
  object: PropTypes.object,

  /**
   * Boolean to toggle whether to display the wrapping object curly braces, or simply display as a list.
   */
  showBraces: PropTypes.bool,
};
