import PropTypes from "prop-types";
import React from "react";

import { Button, Caption, Link, useCaption } from "@swa-ui/core";
import { TextFormField } from "@swa-ui/form";
import i18n from "@swa-ui/locale";
import { classNames } from "@swa-ui/string";

import { useVacationsFormLogic } from "../../useVacationsFormLogic";
import styles from "./PromoCode.module.scss";

export const PromoCode = (props) => {
  const { className } = props;
  const { captionLocation, captionRef, hideCaption, showCaption } = useCaption("below");
  const { formProps, formData } = useVacationsFormLogic();
  const { promoCodeProps } = formProps;
  return (
    <div className={classNames(className, styles.container)}>
      <Caption {...getCaptionProps()}>
        <Link {...getLinkProps()}>
          {i18n(formData.promoCode ? "PromoCode__EDIT_PROMO_CODE" : "PromoCode__USE_PROMO_CODE")}
        </Link>
      </Caption>
    </div>
  );

  function renderPromoCodeContent() {
    return (
      <div className={styles.captionContainer} ref={captionRef}>
        <p className={styles.captionTitle}>{i18n("PromoCode__CAPTION_TITLE")}</p>
        <p className={styles.captionDescription}>{i18n("PromoCode__CAPTION_DESCRIPTION")}</p>
        <TextFormField {...getEnterPromoFormFieldProps()} />
        <Button {...getApplyButtonProps()}>{i18n("PromoCode__APPLY")}</Button>
      </div>
    );
  }

  function getCaptionProps() {
    return {
      adjoiningContent: renderPromoCodeContent(),
      alignment: "center",
      bestFit: true,
      location: captionLocation,
      pointerAlignment: "center",
      showPointer: true,
    };
  }

  function getLinkProps() {
    return {
      className: styles.link,
      emphasis: false,
      onClick: () => showCaption(),
      showUnderline: true,
    };
  }

  function getEnterPromoFormFieldProps() {
    return {
      ...promoCodeProps,
      caption: i18n("PromoCode__FORM_FIELD_CAPTION"),
    };
  }

  function getApplyButtonProps() {
    return {
      className: styles.applyButton,
      fullWidth: true,
      id: "promoCodeSubmit",
      onClick: () => hideCaption(),
      styleType: "primary",
    };
  }
};

PromoCode.propTypes = {
  /** Additional class to position the component.  */
  className: PropTypes.string,
};
