var stringP = "['\"].*['\"]";
var variableP = '[A-Za-z0-9_]+';
var actionP = "@" + variableP + "\\(((" + stringP + ")*|(" + variableP + ")*)\\)";
var operationP = "\\s*(" + stringP + "|" + variableP + ")\\s*[+]\\s*(" + stringP + "|" + variableP + ")\\s*";
var statementP = variableP + "|" + stringP + "|" + operationP;
var conditionalP = "\\s*(" + variableP + ")\\s*\\?\\s*(" + statementP + ")\\s*:\\s*(" + statementP + ")+\\s*";
var outsideQuotesLookahead = "(?=(([^']*'[^']*')*[^']*)$)";
var actionRe = new RegExp(actionP);
var conditionalRe = new RegExp(conditionalP);
var interoperableRe = new RegExp(".*(\\$\\{\\s*(" + actionP + "|" + stringP + "|" + variableP + "|" + operationP + "|" + conditionalP + "\\s*)+\\}).*");
var operationRe = new RegExp(operationP);
var splitActionRe = new RegExp("\\([\"']?");
var splitConditionalRe = new RegExp("\\?" + outsideQuotesLookahead);
var splitOperationRe = new RegExp("\\+" + outsideQuotesLookahead);
var splitStatementsRe = new RegExp(":" + outsideQuotesLookahead);
var stringRe = new RegExp(stringP);

var stripOuterWhitespace = function stripOuterWhitespace(token) {
  return token.replace(/^(\s*)/, '').replace(/(\s*)$/, '');
};

var stripTokenDelimiter = function stripTokenDelimiter(token) {
  return token.replace(/^\$\{/, '').replace(/\}$/, '');
};

var stripWhitespace = function stripWhitespace(token) {
  return token.replace(/\s*/g, '');
};

var resolveToken = function resolveToken(token, data) {
  var stringToken = stringRe.test(token);
  return stringToken ? stripOuterWhitespace(token).replace(/["']/g, '') : data[stripWhitespace(token)];
};

var resolveAction = function resolveAction(name, parameter, data) {
  var functor = resolveToken(name, data);
  return function (event) {
    return functor(event, parameter);
  };
};

var resolveOperation = function resolveOperation(token, data) {
  var _token$split = token.split(splitOperationRe),
      a = _token$split[0],
      b = _token$split[1];

  return resolveToken(a, data) + resolveToken(b, data);
};

var resolveData = function resolveData(token, data) {
  if (data === void 0) {
    data = {};
  }

  var tokenIsOperation = operationRe.test(token);
  return tokenIsOperation ? resolveOperation(token, data) : resolveToken(token, data);
};

var evaluateAction = function evaluateAction(token, data) {
  var _token$split2 = token.split(splitActionRe),
      name = _token$split2[0],
      parameter = _token$split2[1];

  var strippedName = name.replace(/[@:]/g, '');
  var strippedParameter = parameter.replace(/[()"']/g, '');
  return resolveAction(strippedName, strippedParameter, data);
};

var evaluateConditional = function evaluateConditional(token, data) {
  var _token$split3 = token.split(splitConditionalRe),
      expression = _token$split3[0],
      statements = _token$split3[1];

  var _statements$split = statements.split(splitStatementsRe),
      statement1 = _statements$split[0],
      statement2 = _statements$split[1];

  var statement = data[stripWhitespace(expression)] ? stripOuterWhitespace(statement1) : stripOuterWhitespace(statement2);
  return resolveData(statement, data);
};

var interpolateToken = function interpolateToken(token, data) {
  var strippedToken = stripTokenDelimiter(token);
  var tokenIsConditional = conditionalRe.test(strippedToken);
  var tokenIsActionable = actionRe.test(strippedToken);
  var interpolatedToken;

  if (tokenIsConditional) {
    interpolatedToken = evaluateConditional(strippedToken, data);
  } else if (tokenIsActionable) {
    interpolatedToken = evaluateAction(strippedToken, data);
  } else {
    interpolatedToken = resolveData(strippedToken, data);
  }

  return interpolatedToken;
};

var interpolate = function interpolate(template, data) {
  var interoperable = interoperableRe.test(template);
  var result = template;

  if (interoperable) {
    var tokens = template.split(/(\$\{.*?\})/).filter(Boolean);
    result = tokens.reduce(function (acc, token) {
      if (token === void 0) {
        token = '';
      }

      var tokenInteroperable = interoperableRe.test(token);
      var interpolatedToken = tokenInteroperable ? interpolateToken(token, data) : token; // eslint-disable-next-line lodash/prefer-lodash-typecheck

      return typeof interpolatedToken === 'function' ? interpolatedToken : acc + interpolatedToken;
    }, '');
  }

  return result;
};

module.exports = interpolate;