import dayjs from "dayjs";

/**
 * This is a function from moment.js
 * Because there is no equivalent function in dayjs this is being used as a helper function and extended to dayjs.
 * The purpose of this function is to take the provided time string and parse it while keeping the original timezone
 * and not converting it to local or UTC time.
 * See https://momentjscom.readthedocs.io/en/latest/moment/01-parsing/14-parse-zone/ for more details.
 */

export const parseZone = (option, dayjsClass, dayjsFactory) => {
  const matchOffset = /Z|[+-]\d\d:?\d\d/gi; // +00:00 -00:00 +0000 -0000 or Z
  const chunkOffset = /([+-]|\d\d)/gi; // timezone chunker: '+10:00' > ['10',  '00'] ; '-1530'  > ['-15', '30']
  const absFloor = (number) => {
    if (number < 0) {
      // -0 -> 0
      return Math.ceil(number) || 0;
    } else {
      return Math.floor(number);
    }
  };
  const toInt = (argumentForCoercion) => {
    const coercedNumber = +argumentForCoercion;
    let value = 0;

    if (coercedNumber !== 0 && isFinite(coercedNumber)) {
      value = absFloor(coercedNumber);
    }

    return value;
  };
  const offsetFromString = (matcher, string) => {
    const matches = (string || "").match(matcher);

    if (matches === null) {
      return null;
    }
    const chunk = matches[matches.length - 1] || [];
    const parts = `${chunk}`.match(chunkOffset) || ["-", 0, 0];
    const minutes = +(parts[1] * 60) + toInt(parts[2]);

    return minutes === 0 ? 0 : parts[0] === "+" ? minutes : -minutes;
  };

  dayjsFactory.parseZone = (str) => {
    const tZone = offsetFromString(matchOffset, str);

    return tZone ? dayjs(str).utcOffset(tZone) : dayjs(str).utcOffset(0, true);
  };
};
