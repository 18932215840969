import PropTypes from "prop-types";
import React from "react";

import { Icon } from "@swa-ui/core";

/**
 * IconAdapter adds props for compatibility with Vision Icons.
 */

export const IconAdapter = (props) => {
  const { color, icon, iconPrefix, name, size, ...remainingProps } = props;

  return <Icon {...getProps()} />;

  function getProps() {
    const adaptedProps = {
      color: props.style?.color ?? color,
      name: getIconNameWithPrefix() ?? name,
      size: getIconSize(size) ?? size,
    };

    return {
      ...remainingProps,
      ...adaptedProps,
    };
  }

  function getIconNameWithPrefix() {
    const isIconNamePrefixed = iconPrefix !== undefined && icon?.indexOf?.(iconPrefix) === -1;

    return isIconNamePrefixed ? iconPrefix + icon : icon;
  }

  function getIconSize(sizeProp) {
    const sizeConverter = {
      "extra-large": "48px",
      "extra-small": "19px",
      jumbo: "51px",
      large: "38px",
      medium: "28px",
      micro: "16px",
      nano: "12px",
      "over-large": "44px",
      "semi-medium": "26px",
      "semi-small": "20px",
      small: "22px",
    };

    return sizeConverter[sizeProp];
  }
};

IconAdapter.propTypes = {
  /** Icons can use any standard brand color or the name of a CSS var. */
  color: PropTypes.string,

  /** Icon ref. */
  elementRef: PropTypes.func,

  /** Indicates the icon name. */
  icon: PropTypes.string,

  /** Prefix used before the icon name. */
  iconPrefix: PropTypes.string,

  /** ID to be added to the element. */
  id: PropTypes.string,

  /** Name of icon such as Flight or Menu. */
  name: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,
};
