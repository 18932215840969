import { useEffect, useRef } from "react";

/**
 * Simple hook to return if a component is mounted
 *
 * @param {Function} [onMount] - Optional callback to be executed once the component is mounted.
 * @returns {boolean} The current mount state of the component.
 */

export const useIsMounted = (onMount) => {
  const isMounted = useRef(false);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      onMount?.(isMounted.current);
    }
  }, []);

  return isMounted.current;
};
