import PropTypes from "prop-types";
import React from "react";

export const TVRadio = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path d="M3.5,14.4V6.8c0-0.7,0.4-1.2,1.2-1.2c0.7,0,1.2,0.4,1.2,1.2v7.6H3.5z" />
      <path
        d="M18.5,27.7c-2.7,0-4.8-2.2-4.8-4.8c0-2.7,2.1-4.8,4.8-4.8c2.7,0,4.8,2.1,4.8,4.8
		C23.3,25.6,21.1,27.7,18.5,27.7 M21.6,30.4c-0.4,0-0.7-0.3-0.7-0.7c0-0.4,0.3-0.7,0.7-0.7c0.4,0,0.7,0.3,0.7,0.7
		C22.3,30.1,22,30.4,21.6,30.4 M18.5,30.4c-0.4,0-0.7-0.3-0.7-0.7c0-0.4,0.3-0.7,0.7-0.7c0.4,0,0.7,0.3,0.7,0.7
		C19.2,30.1,18.9,30.4,18.5,30.4 M15.4,30.4c-0.4,0-0.7-0.3-0.7-0.7c0-0.4,0.3-0.7,0.7-0.7c0.4,0,0.7,0.3,0.7,0.7
		C16.1,30.1,15.8,30.4,15.4,30.4 M10,27.6c0,0.3-0.2,0.6-0.5,0.6c-0.3,0-0.5-0.3-0.5-0.6V20c0-0.3,0.2-0.5,0.4-0.5
		c0.3,0,0.5,0.2,0.5,0.5V27.6z M7.5,27.6c0,0.3-0.2,0.6-0.5,0.6c-0.3,0-0.5-0.3-0.5-0.6V20c0-0.3,0.2-0.5,0.5-0.5
		c0.3,0,0.5,0.2,0.5,0.5V27.6z M5,27.6c0,0.3-0.2,0.6-0.5,0.6c-0.3,0-0.5-0.3-0.5-0.6V20c0-0.3,0.2-0.5,0.4-0.5
		C4.8,19.5,5,19.8,5,20V27.6z M24.7,16.2H2c-1,0-2,1-2,2v11.3c0,1,1,2,2,2h22.8c1,0,2-1,2-2V18.2C26.7,17.2,25.8,16.2,24.7,16.2"
      />
      <path
        d="M52.6,24.5h-2.9v-0.7h2.9V24.5z M53.1,0H11.9c-1.3,0-2.6,1.3-2.6,2.6v10.7h1.5h1.6V3h40.4v19.6h-23V25v0.7
		h23.4c1.3,0,2.6-1.3,2.6-2.6V2.6C55.7,1.3,54.4,0,53.1,0"
      />
      <path d="M29.6,28.6v3l14.2,0c0-1.6-1.5-3-3.1-3L29.6,28.6L29.6,28.6z" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "0 0 55.7 31.6",
    };
  }
};

TVRadio.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

TVRadio.defaultProps = {
  role: "img",
};
