import PropTypes from "prop-types";
import React from "react";

export const Permitted = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path
        d="M11.46,11.09c6.36,0,11.55,5.19,11.46,11.45c0,3.04-1.16,5.73-3.04,7.78
	c-2.06,2.24-5.1,3.67-8.42,3.67C5.1,34,0,28.9,0,22.55c0-3.31,1.43-6.35,3.67-8.41C5.73,12.26,8.51,11.09,11.46,11.09z M18.09,28.54
	c1.43-1.61,2.33-3.67,2.33-5.99c0-4.92-4.03-8.95-8.96-8.95c-2.33,0-4.39,0.89-6,2.33c-1.79,1.61-2.96,4.03-2.96,6.62
	c0,4.92,4.03,8.95,8.96,8.95C14.06,31.49,16.48,30.33,18.09,28.54z M15.94,18.79l1.88,1.88l-7.16,7.16l-4.12-4.12l1.88-1.79
	l2.24,2.15L15.94,18.79z M24.08,28.78c1.16-1.79,1.85-3.91,1.85-6.24c0.02-1.35-0.21-2.65-0.64-3.86c0.69-0.92,1.41-1.8,2.12-2.46
	l1.93-1.93L15,6.51l2.21-2.2c0,0,0.55-0.55,1.38-0.27l16.64,4.4l5.24-5.22C43.59,0,45.98,0,46.71,0c0,0,0.74,0,1.01,0.27
	S48,1.28,48,1.28c0,0.83,0,3.12-3.13,6.23l-5.24,5.32l4.41,16.59c0.18,0.83-0.37,1.38-0.37,1.38L41.47,33l-7.72-14.39l-1.93,1.92
	c-1.01,1.01-2.57,2.2-3.95,3.21l0.55,5.04c0.09,1.01-0.46,1.56-0.46,1.56l-2.21,2.2L24.08,28.78z"
      />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "0 0 48 34",
    };
  }
};

Permitted.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Permitted.defaultProps = {
  role: "img",
};
