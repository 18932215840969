import PropTypes from "prop-types";
import React from "react";

export const EarlyBird = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <rect x="2" y="23.01" width="27.94" height="2.35" />
      <path d="M16,13.11a7.61,7.61,0,0,0-7.61,7.61H23.53A7.53,7.53,0,0,0,16,13.11" />
      <path d="M3.32,18.09A1.29,1.29,0,0,0,2,19.41a1.33,1.33,0,0,0,1.32,1.31H6a9.28,9.28,0,0,1,.34-2.57h-3Z" />
      <path d="M8.24,14.43a10.94,10.94,0,0,1,1.89-1.78L8.07,10.59a1.29,1.29,0,1,0-1.83,1.83Z" />
      <path d="M16,10.76c.46,0,.86.06,1.32.06V8a1.32,1.32,0,1,0-2.64,0v2.86A9.72,9.72,0,0,1,16,10.76" />
      <path d="M23.7,14.43l2-2a1.3,1.3,0,0,0-1.84-1.83l-2.06,2.06a13.87,13.87,0,0,1,1.89,1.78" />
      <path d="M28.68,18.09H25.59a9.61,9.61,0,0,1,.34,2.58h2.75A1.34,1.34,0,0,0,30,19.35a1.36,1.36,0,0,0-1.32-1.26" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      style,
      viewBox: "1 1 29 29",
    };
  }
};

EarlyBird.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

EarlyBird.defaultProps = {
  role: "img",
};
