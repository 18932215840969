import PropTypes from "prop-types";
import React from "react";
import { animated, useSpring } from "react-spring";

export const Search = (props) => {
  const { className, custom, role, size, style } = props;
  const { morph } = useSpring({ morph: getMorphIndex(custom && custom.type) });

  return (
    <svg {...getProps()}>
      <path d="M 2,13  a 11,11 0 1 1 22,0  a 11,11 0 1 1 -22,0" fill="none" strokeWidth="4"></path>
      <path d="M22,22 L28,28" fill="none" strokeLinecap="round" strokeWidth="6"></path>
      <animated.path
        d="M 4,13  a 9,9 0 1 1 18,0  a 9,9 0 1 1 -18,0"
        fill={morph.interpolate({
          output: ["transparent", "#d3d3ff", "#d3d3ff"],
          range: [0, 1, 2],
        })}
        strokeWidth="0"
      ></animated.path>
      <animated.path
        d="M 8,11  a 4,5 0 1 1 8,0  a 4,4 0 1 1 -8,0"
        fill={morph.interpolate({
          output: ["transparent", "#d3d3ff", "#ffffff"],
          range: [0, 1, 2],
        })}
        strokeWidth="0"
      ></animated.path>
      <animated.path
        d="M 2,17  a 9,9 0 1 1 22,-8"
        fill="none"
        stroke="#b0b0ff"
        strokeWidth={morph.interpolate({
          output: ["0", "0", "1"],
          range: [0, 1, 2],
        })}
      ></animated.path>
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      style,
      viewBox: "0 0 32 32",
    };
  }
};

function getMorphIndex(type) {
  let index = 0;

  if (type === "active") {
    index = 1;
  }

  if (type === "focus") {
    index = 2;
  }

  return index;
}

Search.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Search.defaultProps = {
  role: "img",
};
