import PropTypes from "prop-types";
import React from "react";

export const ECigarette = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path d="M26,16.25h-.84v.8h-.53v-.8h-.84v-.5h.84V15h.53v.8H26Zm-11.2,0H13.37v-.5h1.39Zm-2.69,1.66H27.21V14.09H12.07Z" />
      <path d="M3.72,17.9A.75.75,0,0,1,3,17.21V14.79a.75.75,0,0,1,.72-.69H11v3.82H3.72Z" />
      <path d="M28.29,17.85a.74.74,0,0,0,.71-.68V14.79a.74.74,0,0,0-.71-.68Z" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "2 2 28 28",
    };
  }
};

ECigarette.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

ECigarette.defaultProps = {
  role: "img",
};
