import { Multiselect } from "@swa-ui/core";

import { withFormField } from "../withFormField";

/**
 * MultiselectFormField provides support for a standard Multiselect component to be used in a Form.
 */

export const MultiselectFormField = withFormField(Multiselect, {
  useController: true,
});
