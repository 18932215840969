/**
 * Calculates the cumulative offset of an element relative to a parent element.
 *
 * @param {HTMLElement} element - The element to calculate the offset for.
 * @param {HTMLElement} parent - The parent element to calculate the offset relative to.
 * @returns {Object} - An object with 'left' and 'top' properties representing the x and y offsets respectively.
 */

const getCumulativeOffset = (element, parent) => {
  let left = 0;
  let top = 0;
  let workingElement = element;

  do {
    left += workingElement.offsetLeft || 0;
    top += workingElement.offsetTop || 0;
    workingElement = workingElement.offsetParent;
  } while (workingElement && workingElement !== parent);

  if (workingElement === parent) {
    top += workingElement.offsetTop;
    left += workingElement.offsetLeft;
  }

  return {
    left: left,
    top: top,
  };
};

export { getCumulativeOffset };
