import PropTypes from "prop-types";
import React from "react";

export const Shield = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path d="M11.9963 0C8.64257 2.901 4.43106 4.632 0.000346296 4.9305V13.838C-0.0212229 16.622 0.965443 19.32 2.77876 21.4375C5.05205 24.0625 8.151 26.25 11.9958 28C15.8321 26.25 18.9306 24.0625 21.2129 21.4375C23.0262 19.325 24.0159 16.6315 23.9998 13.851V4.9305C19.5666 4.635 15.3516 2.9035 11.9963 0ZM17.9259 15.0895C17.9259 15.2055 17.8797 15.3165 17.7974 15.399C17.7152 15.481 17.6033 15.527 17.4869 15.527H13.6025V19.39C13.6025 19.506 13.5563 19.6175 13.4741 19.6995C13.3918 19.7815 13.28 19.8275 13.1636 19.8275H10.8461C10.6039 19.8275 10.4072 19.6315 10.4072 19.39V15.5445H6.52277C6.2805 15.5445 6.08386 15.3485 6.08386 15.107V12.8015C6.08386 12.56 6.2805 12.364 6.52277 12.364H10.3897V8.492C10.3897 8.2505 10.5863 8.0545 10.8286 8.0545H13.1285C13.2448 8.0545 13.3567 8.1005 13.439 8.1825C13.5212 8.2645 13.5674 8.376 13.5674 8.492V12.3465H17.4343C17.5507 12.3465 17.662 12.393 17.7448 12.475C17.827 12.557 17.8732 12.6685 17.8732 12.7845L17.9259 15.09V15.0895Z" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "-3 0 32 32",
    };
  }
};

Shield.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Shield.defaultProps = { role: "img" };
