import PropTypes from "prop-types";
import React from "react";

export const Transfer = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path d="M28.9,8.4l2.9-0.1l0.1,4.3l6.9-6.5L31.6,0l0.1,4.1l-2.9,0.1c-5,0.1-8.4,2.8-11,7.2l-4,6.8 c-1.9,3.2-3.8,5.1-7.5,5.2l-3.7,0.1c-1.2,0-2.1,1-2.1,2.2c0,1.2,1,2.1,2.2,2.1l3.8-0.1c5-0.1,8.4-2.8,11-7.2l4-6.8 C23.3,10.4,25.2,8.5,28.9,8.4" />
      <path d="M32.1,21.3l-2.9,0.1c-3.4,0.1-4.9-1.6-7.2-4.1c0,0-2.1,3.6-2.3,3.8c2.9,3,5.5,4.6,9.6,4.5l2.9-0.1l0.1,4.3 l6.9-6.5L32,17.3L32.1,21.3z" />
      <path d="M2.2,10.3l3.8-0.1c3.4-0.1,4.9,1.6,7.2,4.1c0,0,2.2-3.6,2.3-3.8c-2.9-3-5.5-4.6-9.6-4.5L2.1,6C0.9,6,0,7,0,8.2 C0,9.3,1,10.3,2.2,10.3" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "0 0 39.2 29.9",
    };
  }
};

Transfer.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Transfer.defaultProps = {
  role: "img",
};
