import PropTypes from "prop-types";
import React from "react";

import i18n from "@swa-ui/locale";
import { classNames } from "@swa-ui/string";

import styles from "./FlightNumber.module.scss";

/**
 * FlightNumber displays the flight number next to the hashtag symbol.
 */

export const FlightNumber = (props) => {
  const { airlineCode, className, flightNumber, showHashtag } = props;

  return (
    <div {...getProps()}>
      <span aria-hidden="true">
        {showHashtag && "#"}
        {airlineCode}
        {flightNumber}
      </span>
    </div>
  );

  function getProps() {
    return {
      ["aria-label"]: getFlightNumberAriaLabel(),
      className: classNames(className, styles.flightNumber),
    };
  }

  function getFlightNumberAriaLabel() {
    return i18n("FlightNumber__FLIGHT_NUMBER__FLIGHT_INFORMATION_ARIA", {
      AIRLINE_CODE: airlineCode,
      FLIGHT_NUMBER: flightNumber,
    });
  }
};

FlightNumber.propTypes = {
  /** Airline code that will be redered. */
  airlineCode: PropTypes.string,
  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Content that will be rendered inside the component. */
  flightNumber: PropTypes.string.isRequired,

  /** Boolean value to show or hide the hashtag symbol. */
  showHashtag: PropTypes.bool,
};

FlightNumber.defaultProps = {
  showHashtag: true,
};
