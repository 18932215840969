import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import { ControlledPlayList, controlledPlayListPropTypes } from "../ControlledPlayList";
import { transformPropTypes } from "../Transform";

const TRANSITION_INITIAL = { duration: 100, transformations: ["fadeReset"] };
const TRANSITION_IN = { duration: 400, transformations: ["fadeReset"] };
const TRANSITION_OUT = { duration: 200, transformations: ["fade"] };

/**
 * TransitionBlock transitions its content smoothly when the child content changes. To signal the
 * content has changed and should be re-rendered, TransitionBlock requires the animationToken prop
 * to change.
 */

export const TransitionBlock = (props) => {
  const {
    animationToken,
    children,
    className,
    onTransformationEnd,
    transformOrigin,
    transitionComplete,
    transitionIn,
    transitionInitial,
    transitionOut,
    transitionSetup,
  } = props;
  const [currentAnimationToken, setCurrentAnimationToken] = useState();
  const [currentChildren, setCurrentChildren] = useState(children);
  const playList = useRef([]);

  useEffect(() => {
    if (animationToken !== currentAnimationToken || currentAnimationToken === undefined) {
      playList.current = getPlayList();
      setCurrentAnimationToken(animationToken);
      setCurrentChildren(children);
    }
  }, [animationToken]);

  return <ControlledPlayList {...getProps()} />;

  function getProps() {
    return {
      animationToken: currentAnimationToken,
      className,
      onPlayListComplete: onTransformationEnd,
      playList: playList.current,
      transformOrigin,
    };
  }

  function getPlayList() {
    let workingPlayList = [];

    if (currentAnimationToken === undefined) {
      workingPlayList = [{ step: { ...transitionInitial, children } }];
    } else if (transitionComplete) {
      workingPlayList = [
        { step: { ...transitionOut, children: currentChildren } },
        { step: { ...transitionSetup, children } },
        { step: { ...transitionIn, children } },
        { step: { ...transitionComplete, children } },
      ];
    } else if (transitionSetup) {
      workingPlayList = [
        { step: { ...transitionOut, children: currentChildren } },
        { step: { ...transitionSetup, children } },
        { step: { ...transitionIn, children } },
      ];
    } else {
      workingPlayList = [
        { step: { ...transitionOut, children: currentChildren } },
        { step: { ...transitionIn, children } },
      ];
    }

    return workingPlayList;
  }
};

export const transitionBlockPropTypes = {
  /**
   * Unique id that forces an animation (re-render). TransitionBlock may be rendered many times during its life cycle,
   * but it is not always desirable to animate the content when rendering. The calling component is responsible for
   * letting TransitionBlock know that an animation is required by passing a different value for animationToken than
   * the last render.
   */
  animationToken: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /** Content to be rendered on page. */
  children: PropTypes.node,

  /** Optional class name to position page content. By-in-large, this shouldn't be needed. */
  className: PropTypes.string,

  /** Function called when the animation is complete. */
  onTransformationEnd: PropTypes.func,

  /** transformOrigin pertains to how things are rotated and scaled. See Transform for more info. */
  transformOrigin: controlledPlayListPropTypes.transformOrigin,

  /** Animation options to apply to child content. See Transform for more info. */
  transitionComplete: PropTypes.shape({
    clearAll: transformPropTypes.clearAll,
    delay: transformPropTypes.delay,
    duration: transformPropTypes.duration,
    transformations: transformPropTypes.transformations,
    transformOrigin: transformPropTypes.transformOrigin,
  }),

  /** Animation options to apply to child content. See Transform for more info. */
  transitionIn: PropTypes.shape({
    delay: transformPropTypes.delay,
    duration: transformPropTypes.duration,
    transformations: transformPropTypes.transformations,
    transformOrigin: transformPropTypes.transformOrigin,
  }),

  /** Animation options to apply to child content. See Transform for more info. */
  transitionInitial: PropTypes.shape({
    delay: transformPropTypes.delay,
    duration: transformPropTypes.duration,
    transformations: transformPropTypes.transformations,
    transformOrigin: transformPropTypes.transformOrigin,
  }),

  /** Animation options to apply to child content. See Transform for more info. */
  transitionOut: PropTypes.shape({
    delay: transformPropTypes.delay,
    duration: transformPropTypes.duration,
    transformations: transformPropTypes.transformations,
    transformOrigin: transformPropTypes.transformOrigin,
  }),

  /** Animation options to apply to child content. See Transform for more info. */
  transitionSetup: PropTypes.shape({
    delay: transformPropTypes.delay,
    duration: transformPropTypes.duration,
    transformations: transformPropTypes.transformations,
    transformOrigin: transformPropTypes.transformOrigin,
  }),
};

TransitionBlock.propTypes = transitionBlockPropTypes;

TransitionBlock.defaultProps = {
  transitionIn: TRANSITION_IN,
  transitionInitial: TRANSITION_INITIAL,
  transitionOut: TRANSITION_OUT,
};
