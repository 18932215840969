import PropTypes from "prop-types";
import React from "react";

export const Retail = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path
        d="M9.7,7c0,1.6-1.3,2.8-2.8,2.8C5.4,9.9,4.1,8.6,4.1,7c0-1.6,1.3-2.8,2.8-2.8C8.5,4.2,9.7,5.5,9.7,7 M12.9,0
		C12.4,0,2.4,0,1.5,0C0.6,0,0,0.6,0,1.5c0,0.9,0,11.4,0,11.4l0,0c0,0.5,0.2,1,0.5,1.3l15,15c0.7,0.7,1.9,0.7,2.6,0l11-11.1
		c0.7-0.7,0.7-1.9,0-2.6l-15-15C13.9,0.2,13.4,0,12.9,0"
      />
      <path
        d="M29.2,22.5l-1.3,1.3l-11,11L0.2,18.1v4.7l16.8,16.9l13.4-13.4c0.7-0.7,0.7-1.8,0-2.5
		C29.7,23.1,29.2,22.5,29.2,22.5"
      />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "-4 0 38 42",
    };
  }
};

Retail.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Retail.defaultProps = {
  role: "img",
};
