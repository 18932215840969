import { useEffect, useRef, useState } from "react";

import { getItem, setItem } from "../persistence";

/**
 * usePersistedState stores values into the session storage and retrieves values from the session storage.
 */

export const usePersistedState = (configuration) => {
  const { key, defaultValue, onExpiration } = configuration;
  const persistedData = getItem(key, defaultValue, configuration);
  const { value, expiry } = persistedData;
  const [state, setState] = useState(value);
  const expirationTimeout = useRef();
  const isMounted = useRef(false);

  useEffect(() => {
    if (isMounted.current) {
      setItem(key, state, configuration);

      clearTimeout(expirationTimeout.current);
      expirationTimeout.current = undefined;
    }
  }, [key, state]);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
    }
  }, []);

  useEffect(() => {
    if (onExpiration && expiry && !expirationTimeout.current) {
      const now = new Date().getTime();
      const timeRemaining = expiry - now;

      expirationTimeout.current = setTimeout(() => {
        onExpiration();
      }, timeRemaining);
    }
  });

  return [state, setState];
};
