import PropTypes from "prop-types";
import React from "react";

import { useDeviceInfo } from "@swa-ui/browser";
import { Background, Heading, Layout } from "@swa-ui/core";
import { classNames } from "@swa-ui/string";

import { applyOverrides } from "../applyOverrides";
import { backgroundPropType, headingPropType } from "../Banner/BannerUtils";
import styles from "./HeroImagePlacement.module.scss";

/**
 * This component is intended to be used for a Hero Image shot with an optional heading,
 * usually placed on top of the page. Must be used within a Section for the headings to
 * get the correct heading level.
 *
 * @param {*} props
 * @returns {JSX.Element}
 */
export const HeroImagePlacement = (props) => {
  const { placement } = props;
  const { background, primaryHeading, secondaryHeading } = placement;
  const { screenSize } = useDeviceInfo();
  const headingExistsOutside = primaryHeading?.below || secondaryHeading?.below;
  const headingExistsInside =
    !primaryHeading?.below || (secondaryHeading?.value && !secondaryHeading?.below);

  return (
    <>
      {renderBanner()}
      {headingExistsOutside && renderOutsideHeadings()}
    </>
  );

  function renderBanner() {
    const headings = headingExistsInside ? renderInsideHeadings() : null;

    return (
      <div {...getProps()}>
        <Background {...getBackgroundProps()}>{headings}</Background>
      </div>
    );
  }

  function renderInsideHeadings() {
    const uncenteredHeadingExists =
      (primaryHeading?.value && !primaryHeading?.below && !primaryHeading?.center) ||
      (secondaryHeading?.value && !secondaryHeading?.below && !secondaryHeading?.center);

    return renderHeadingsWithLayout({
      layoutSides: uncenteredHeadingExists,
      layoutTop: "none",
      renderPrimaryHeading: primaryHeading?.value && !primaryHeading?.below,
      renderSecondaryHeading: secondaryHeading?.value && !secondaryHeading?.below,
    });
  }

  function renderOutsideHeadings() {
    const uncenteredHeadingExists =
      (primaryHeading?.value && primaryHeading?.below && !primaryHeading?.center) ||
      (secondaryHeading?.value && secondaryHeading?.below && !secondaryHeading?.center);

    return renderHeadingsWithLayout({
      layoutSides: uncenteredHeadingExists,
      layoutTop: "large",
      renderPrimaryHeading: primaryHeading?.value && primaryHeading?.below,
      renderSecondaryHeading: secondaryHeading?.value && secondaryHeading?.below,
    });
  }

  function renderHeadingsWithLayout({
    layoutSides,
    layoutTop,
    renderPrimaryHeading,
    renderSecondaryHeading,
  }) {
    return (
      <Layout className={styles.layoutWrapper} sides={layoutSides} top={layoutTop}>
        {renderPrimaryHeading && renderHeading(primaryHeading)}
        {renderSecondaryHeading && renderHeading(secondaryHeading)}
      </Layout>
    );
  }

  function renderHeading(headingObject) {
    return (
      headingObject?.value && (
        <Heading {...getHeadingProps(headingObject)}>
          <span style={{ ...headingObject }}>{headingObject?.value}</span>
        </Heading>
      )
    );
  }

  function getProps() {
    const { height = "medium" } = placement;

    return {
      className: classNames({
        [styles.container]: true,
        [styles[height]]: true,
      }),
    };
  }

  function getBackgroundProps() {
    const { imagePath } = applyOverrides(background, screenSize);

    return {
      backgroundAttachment: "local",
      imageUrl: imagePath,
    };
  }

  function getHeadingProps(headingObject) {
    return {
      className: classNames({
        [styles.center]: headingObject?.center,
        [styles.heading]: true,
        [styles.multiInside]: !headingObject?.below && secondaryHeading?.value,
        [styles.singleInside]: !headingObject?.below && !secondaryHeading?.value,
      }),
    };
  }
};

HeroImagePlacement.propTypes = {
  placement: PropTypes.shape({
    background: backgroundPropType,
    height: PropTypes.oneOf(["small", "medium", "large"]),
    primaryHeading: headingPropType,
    secondaryHeading: headingPropType,
  }),
};
