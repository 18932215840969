import PropTypes from "prop-types";
import React from "react";

import { classNames } from "@swa-ui/string";

import { CardGrid } from "../CardGrid";
import { Loading, loadingPropTypes } from "../Loading";
import styles from "./DepictionRow.module.scss";

/**
 * DepictionRow renders a depiction row of options. See LoadingDepiction for more information and
 * examples.
 */
export const DepictionRow = (props) => {
  const { columnDefinitions, numberOfColumns, numberOfRows } = props;
  const itemCount = [...Array(columnDefinitions?.length || numberOfColumns)];
  const rowCount = [...Array(numberOfRows)];

  return <CardGrid {...getProps()}>{rowCount.map(() => itemCount.map(renderItem))}</CardGrid>;

  function renderItem(_, index) {
    return <Loading {...getItemProps(columnDefinitions?.[index] ?? {})} key={index} />;
  }

  function getProps() {
    return {
      className: styles.depictionRow,
      style: { "--numberOfColumns": numberOfColumns },
    };
  }

  function getItemProps(itemDefinition) {
    const {
      className: itemClassName,
      color = props.color,
      gridColumn,
      height = props.height,
      shape = props.shape,
    } = itemDefinition;

    return {
      className: classNames(itemClassName, { [styles.columnSpan]: gridColumn }),
      color,
      height,
      shape,
      style: gridColumn && { "--gridColumn": gridColumn },
    };
  }
};

DepictionRow.propTypes = {
  /**
   * Color of each shape to be rendered. This color will be applied to all shape but can overridden
   * for individual shapes in columnDefinitions.
   */
  color: loadingPropTypes.color,

  /**
   * Array of custom shapes definitions. The number of shape definitions should match number of
   * columns given (taking into account that some shapes may span more than one column.
   */
  columnDefinitions: PropTypes.arrayOf(
    PropTypes.shape({
      ...loadingPropTypes,

      /** Use same values as you would for CSS. Example: "1/3" */
      gridColumn: PropTypes.string,
    })
  ),

  /** Height of all shapes. */
  height: loadingPropTypes.height,

  /** Number of columns to be rendered in row. */
  numberOfColumns: PropTypes.number,

  /** Number of repetitions of this row. */
  numberOfRows: PropTypes.number,

  /** Shape of each item rendered. Can be override in columnDefinitions for individual shapes. */
  shape: loadingPropTypes.shape,
};

DepictionRow.defaultProps = {
  color: "tertiary",
  height: "medium",
  numberOfColumns: 1,
  numberOfRows: 1,
  shape: "rectangle",
};
