import PropTypes from "prop-types";
import React, { useContext } from "react";

import { fireTrack } from "@swa-ui/analytics";
import { FetchInterceptor, getResponseCodes, isResponseOk } from "@swa-ui/fetch";

import { MktgDataContext } from "../MktgDataProvider";

/**
 * AnalyticsProvider provides analytics state and the function to update the analytics state in a React context, so it
 * is available to components in an app's render tree.
 *
 * analytics - State used to store analytics.
 * setAnalytics - Function to update the analytics state.
 */

export const AnalyticsInterceptor = (props) => {
  const { chapi, children } = props;
  const { updateMktgGlobalData } = useContext(MktgDataContext);

  return <FetchInterceptor onResponse={handleFetchResponse}>{children}</FetchInterceptor>;

  function handleFetchResponse(response) {
    const experienceId = getExperienceId(response);
    const requestId = getRequestId(response);

    if (isChapiCall(response?.url)) {
      updateMktgGlobalData({
        global_experienceid: experienceId,
        global_requestid: requestId,
      });

      if (!isResponseOk(response)) {
        const trackingCode = getResponseCodes(response.data).join(",");
        const errorCode = getServiceErrorCode(response.data);
        const errorTracking = {
          error_code: errorCode,
          error_trackingcode: trackingCode,
          global_experienceid: experienceId,
          global_requestid: requestId,
          page_description: "error message",
        };

        if (errorCode !== "invalid_token" || response?.url?.includes?.("customer-details-secure")) {
          fireTrack("squid", errorTracking);
        }
      }
    }

    return response;
  }

  function isChapiCall(apiUrl) {
    return !/placements|uimetadata/.test(apiUrl) && new RegExp(`/api/${chapi}/`).test(apiUrl);
  }

  function getDefaultErrorCode() {
    return "ERROR__DEFAULT";
  }

  function getExperienceId(response) {
    return response?.headers?.get?.("x-user-experience-id") ?? "";
  }

  function getRequestId(response) {
    return response?.headers?.get?.("x-request-id") ?? "";
  }

  function getServiceErrorCode(data) {
    return (
      data?.errors?.[0]?.message ??
      data?.notifications?.formErrors?.[0]?.code ??
      getDefaultErrorCode()
    );
  }
};

AnalyticsInterceptor.propTypes = {
  /** Content that will be rendered as the page body. */
  children: PropTypes.node.isRequired,
};
