import React from "react";

import { ManageMyVacationsForm } from "../ManageMyVacationsForm/ManageMyVacationsForm";
import styles from "./ManageMyVacationsFlyout.module.scss";

export const ManageMyVacationsFlyout = () => (
  <div className={styles.manageMyVacationsFlyout}>
    <ManageMyVacationsForm />
  </div>
);
