import PropTypes from "prop-types";
import React from "react";

import { classNames, getUniqueId } from "@swa-ui/string";

/**
 * Heart renders the Southwest Heart logo.
 */

const Heart = (props) => {
  const { className, height, role, style, width } = props;
  const uniqueId = getUniqueId("heart-id");

  return (
    <svg {...getProps()}>
      <linearGradient
        id={`SVGID_1_${uniqueId}`}
        gradientUnits="userSpaceOnUse"
        x1="190.9443"
        y1="10.52"
        x2="206.4014"
        y2="10.52"
        gradientTransform="matrix(1 0 0 -1 0 35)"
      >
        <stop offset="0" style={{ stopColor: "#3656CC" }} />
        <stop offset="0.35" style={{ stopColor: "#304CB2" }} />
        <stop offset="0.72" style={{ stopColor: "#283B80" }} />
      </linearGradient>
      <linearGradient
        id={`SVGID_2_${uniqueId}`}
        gradientUnits="userSpaceOnUse"
        x1="193.1406"
        y1="22.7305"
        x2="210.602"
        y2="10.5039"
        gradientTransform="matrix(1 0 0 -1 0 35)"
      >
        <stop offset="0.3" style={{ stopColor: "#E61C23" }} />
        <stop offset="0.8" style={{ stopColor: "#C3161C" }} />
      </linearGradient>
      <linearGradient
        id={`SVGID_3_${uniqueId}`}
        gradientUnits="userSpaceOnUse"
        x1="203.9639"
        y1="24.3096"
        x2="213.9473"
        y2="17.3191"
        gradientTransform="matrix(1 0 0 -1 0 35)"
      >
        <stop offset="0" style={{ stopColor: "#FDBC11" }} />
        <stop offset="0.25" style={{ stopColor: "#FCB415" }} />
        <stop offset="0.8" style={{ stopColor: "#F89E1C" }} />
      </linearGradient>

      <path
        fill="#CCCCCC"
        d="M16.955,10.784   c-2.029,0-3.663,0.707-4.969,1.45   c-1.307-0.743-2.938-1.45-4.968-1.45   c-4.057,0-6.736,2.65-6.736,7.178   c0,5.524,5.275,10.921,11.704,15.254   c6.43-4.334,11.704-9.729,11.704-15.254    C23.69,13.435,21.013,10.784,16.955,10.784z"
      />

      <path
        fill={`url(#SVGID_1_${uniqueId})`}
        d="M0.944,18.059   c0,5.648,5.956,10.902,11.042,14.38   c1.464-1.002,2.976-2.152,4.415-3.416   c-6.738-5.644-13.062-10.693-15.331-12.501   C0.989,17.005,0.944,17.52,0.944,18.059z"
      />

      <path
        fill={`url(#SVGID_2_${uniqueId})`}
        d="M22.577,20.679   c-4.883-4.027-7.84-6.077-11.011-7.98   c-0.854-0.516-2.569-1.27-4.492-1.27    c-1.842,0-3.389,0.573-4.455,1.69   c-0.694,0.725-1.165,1.636-1.434,2.741   c2.529,1.766,9.396,6.676,16.313,12.154   C19.808,25.815,21.743,23.314,22.577,20.679z"
      />

      <path
        fill={`url(#SVGID_3_${uniqueId})`}
        d="M21.343,13.122   c-1.05-1.094-2.589-1.683-4.36-1.683   c-1.9,0-3.348,0.614-4.357,1.162   c1.894,1.054,6.009,3.439,10.272,6.579   c0.066-0.424,0.103-0.85,0.103-1.278   C23,15.913,22.419,14.247,21.343,13.122z"
      />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className: classNames(className),
      height,
      role,
      style,
      viewBox: "-3 4 30 34",
      width,
    };
  }
};

Heart.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Indicates SVG's height. The width, if not given, will be sized according to SVG's aspect ratio. */
  height: PropTypes.string,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates SVG's width. The height, if not given, will be sized according to SVG's aspect ratio. */
  width: PropTypes.string,
};

Heart.defaultProps = {
  height: "32",
  role: "img",
};

export { Heart };
