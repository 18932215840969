import PropTypes from "prop-types";
import React from "react";

import { SMALL, useDeviceInfo } from "@swa-ui/browser";
import { classNames } from "@swa-ui/string";

import { applyOverrides } from "../applyOverrides";
import { CallToAction } from "../CallToAction";
import styles from "./BannerLayout2.module.scss";
import {
  backgroundPropType,
  callToActionPropType,
  getBackgroundStyle,
  graphicPropType,
  renderText,
  textPropType,
} from "./BannerUtils";

/**
 * Renders Banner placement with layoutType as layout2.
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const BannerLayout2 = (props) => {
  const { placement, placementData } = props;
  const { background, callToAction, graphic, text } = placement ?? {};
  const { screenSize } = useDeviceInfo();
  const { align } = applyOverrides(graphic, screenSize);

  return (
    <div className={styles.container} style={getBackgroundStyle(background, screenSize)}>
      {renderContent()}
    </div>
  );

  function renderContent() {
    return (
      <div className={getLayoutContainerClass()}>
        <div className={styles.graphicContainer}>{renderGraphic()}</div>
        <div className={styles.textContainer}>
          {renderTextContent()}
          {renderCallToAction()}
        </div>
      </div>
    );
  }

  function renderTextContent() {
    const { primary, secondary } = text || {};

    return (
      <div className={styles.text}>
        {renderText(primary)}
        {renderText(secondary)}
      </div>
    );
  }

  function renderGraphic() {
    const { altText, display, imagePath } = applyOverrides(graphic, screenSize);

    return (
      display !== "none" &&
      imagePath && <img alt={altText} className={styles.image} src={imagePath} />
    );
  }

  function renderCallToAction() {
    return callToAction && <CallToAction {...getCallToActionProps()} />;
  }

  function getCallToActionProps() {
    return {
      ...(screenSize === SMALL && { additionalProps: { fullWidth: true } }),
      ...callToAction,
      ...placementData,
    };
  }

  function getLayoutContainerClass() {
    return classNames({
      [styles.alignLeft]: align === "left",
      [styles.alignRight]: align === "right",
      [styles.twoColumnsLayout]: true,
    });
  }
};

BannerLayout2.propTypes = {
  /** Placement object for displayType banner. */
  placement: PropTypes.shape({
    /** Background image/color content for the banner. */
    background: backgroundPropType,

    /** Call To Action button / Link displayed in the banner placement. */
    callToAction: callToActionPropType,

    /** Graphic image displayed in the banner placement. */
    graphic: graphicPropType,

    /** Text displayed in the banner placement. */
    text: PropTypes.shape({
      primary: textPropType,
      secondary: textPropType,
    }),
  }),

  placementData: PropTypes.shape({
    /** The dynamic value that will be used as an event description for analytics. */
    analyticsValue: PropTypes.string,
  }),
};
