import PropTypes from "prop-types";
import React from "react";

import { Icon } from "@swa-ui/core";
import i18n from "@swa-ui/locale";
import { classNames } from "@swa-ui/string";

import styles from "./FlightInvalid.module.scss";

/**
 * FlightInvalid displays the timeline icon alongside a string provided from the invalidText prop.
 */

export const FlightInvalid = (props) => {
  const { className, invalidText } = props;

  return (
    <div
      className={classNames(className, styles.flightInvalid)}
      aria-label={getFlightInvalidAriaLabel()}
    >
      <Icon name="TimeLine" size="size22" />
      <span>{invalidText}</span>
    </div>
  );

  function getFlightInvalidAriaLabel() {
    return i18n("FlightInvalid__INVALID_FLIGHT_ARIA", {
      INVALID_RESPONSE: invalidText,
    });
  }
};

FlightInvalid.propTypes = {
  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Text that will be rendered inside the component. */
  invalidText: PropTypes.string.isRequired,
};
