import PropTypes from "prop-types";
import React from "react";

export const VacationsCircle = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path
        d="M16,2C8.3,2,2,8.3,2,16s6.3,14,14,14s14-6.3,14-14S23.7,2,16,2z M25.2,12c0.2-0.2,0.5-0.1,0.6,0
		c0.2,0.2,0.1,0.5,0,0.6l-0.6,0.6c-0.2-0.2-0.4-0.4-0.6-0.6L25.2,12z M22.3,11c0-0.2,0.2-0.4,0.5-0.4c0.2,0,0.4,0.2,0.4,0.5l0,0.9
		C23,12,22.8,12,22.7,12h-0.4L22.3,11z M22.7,12.7c1.4,0.1,2.4,1.2,2.3,2.6c-0.1,1.3-1.2,2.3-2.6,2.2c-1.3-0.1-2.4-1.2-2.3-2.6
		C20.2,13.6,21.3,12.6,22.7,12.7z M22.8,18.2l0,0.9c0,0.2-0.2,0.4-0.4,0.4s-0.4-0.2-0.4-0.4l0-0.9c0.1,0,0.2,0,0.4,0H22.8z
		 M20.5,17.5l-0.7,0.6c-0.2,0.1-0.4,0.1-0.6-0.1c-0.2-0.2-0.1-0.5,0-0.6l0.7-0.6C20.1,17.2,20.3,17.3,20.5,17.5z M19.6,11.8
		c0.2-0.2,0.5-0.2,0.6,0l0.6,0.7c-0.2,0.2-0.5,0.3-0.7,0.5l-0.6-0.7C19.5,12.2,19.5,11.9,19.6,11.8z M18.6,14.4l0.9,0
		c0,0.3,0,0.6,0,0.8l-1,0v0c-0.2,0-0.4-0.2-0.4-0.5C18.1,14.6,18.3,14.4,18.6,14.4z M9.1,18.7c-1.1-1.1-1.3-2.6-0.9-3.9l-3.1,1.7
		c-0.5-2.3,1.1-4,2.9-4.6h0l-2.3-0.7c1.1-1.6,3.1-1.8,4.6-1.1c0.9-1.5,2.8-2.6,4.7-1.7l-1.6,1.8c1.8-0.5,4.1,0.1,4.8,2.3l-3.5,0.2
		c1,0.9,1.7,2.2,1.3,3.8l-3.4-2.1l3,7c-0.7,0.2-1.6,0.7-2.2,1.1L11,15L9.1,18.7z M12.6,23.9c1.6-1.5,3.9-2.4,6.3-2.4
		c2.4,0,4.6,1,6.2,2.4H12.6z M25.4,18.5c-0.2,0.2-0.4,0.1-0.6,0l-0.6-0.7c0.2-0.2,0.5-0.3,0.6-0.5l0.6,0.7
		C25.6,18,25.6,18.3,25.4,18.5z M26.5,15.8L26.5,15.8l-1,0c0.1-0.2,0.1-0.5,0.2-0.8l0.9,0c0.2,0,0.4,0.2,0.4,0.5
		C27,15.6,26.8,15.8,26.5,15.8z"
      />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "0 0 32 32",
    };
  }
};

VacationsCircle.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * Custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

VacationsCircle.defaultProps = { role: "img" };
