"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useViewPageAnalytics = useViewPageAnalytics;
var _react = require("react");
var _reactRouterDom = require("react-router-dom");
var _application = require("@swa-ui/application");
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var pnrWheelchairAssistance = ["NEED_LIFT", "NEED_ASSISTANCE"];
var analyticsPnrType = {
  COMPANION: {
    pnr_with_companion: "1"
  },
  GDS: {
    pnr_booked_with_gds: "1"
  },
  NON_REVENUE: {
    isnonrev: "1"
  }
};
function useViewPageAnalytics(reservations) {
  var _ref = reservations !== null && reservations !== void 0 ? reservations : {},
    _ref$associated_reser = _ref.associated_reservations,
    associated_reservations = _ref$associated_reser === void 0 ? [] : _ref$associated_reser,
    _ref$bounds = _ref.bounds,
    bounds = _ref$bounds === void 0 ? [] : _ref$bounds,
    _ref$disrupted = _ref.disrupted,
    disrupted = _ref$disrupted === void 0 ? false : _ref$disrupted,
    pnrId = _ref.id,
    _ref$passengers = _ref.passengers,
    passengers = _ref$passengers === void 0 ? [] : _ref$passengers,
    _ref$pnr_digital_chan = _ref.pnr_digital_channel_source,
    pnr_digital_channel_source = _ref$pnr_digital_chan === void 0 ? "" : _ref$pnr_digital_chan,
    _ref$pnr_type = _ref.pnr_type,
    pnr_type = _ref$pnr_type === void 0 ? "" : _ref$pnr_type,
    _ref$pnr_sub_type = _ref.pnr_sub_type,
    pnr_sub_type = _ref$pnr_sub_type === void 0 ? "" : _ref$pnr_sub_type,
    _ref$trip_type = _ref.trip_type,
    trip_type = _ref$trip_type === void 0 ? "" : _ref$trip_type;
  var _useLocation = (0, _reactRouterDom.useLocation)(),
    pathname = _useLocation.pathname;
  var _useContext = (0, _react.useContext)(_application.MktgDataContext),
    setMktgPageData = _useContext.setMktgPageData;
  var isVacation = pnr_sub_type === "SOUTHWEST_GETAWAYS";
  (0, _react.useEffect)(function () {
    var viewPagePNRAnalyticsObject = _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, analyticsPnrType[pnr_type]), disrupted && {
      isreaccom: "1"
    }), hasInternationalBound() && {
      pnr_international: "1"
    }), isEarlyBirdPurchased() && {
      pnr_eb: "1"
    }), isMinorUnaccompanied() && {
      pnr_unaccompanied_minor: "1"
    }), isWheelchairNeeded() && {
      pnr_wheelchair: "1"
    }), pnr_digital_channel_source === "CORPORATE" && {
      pnr_swabiz: "1"
    }), isVacation && {
      pnrisvacation: "1"
    }), {}, {
      vacationbannerdisplayed: isVacation ? "1" : "0"
    });
    if (pathname.includes("/air/manage-reservation/view")) {
      setMktgPageData(_objectSpread(_objectSpread({
        pnr: pnrId !== null && pnrId !== void 0 ? pnrId : "",
        trip_type: getTripType(trip_type === null || trip_type === void 0 ? void 0 : trip_type.toLowerCase())
      }, viewPagePNRAnalyticsObject), getPassengersObject()), "viewPage");
    }
  }, [pathname, reservations]);
  function getPassengersObject() {
    var passengerAges = {
      ADULT: 0,
      INFANT_IN_SEAT: 0,
      LAP_CHILD: 0,
      MINOR: 0,
      YOUTH: 0
    };
    passengers === null || passengers === void 0 || passengers.forEach(function (passenger) {
      var passengerAgeType = passenger === null || passenger === void 0 ? void 0 : passenger.age_type;
      passengerAges[passengerAgeType] = passengerAges[passengerAgeType] + 1;
      if (passenger !== null && passenger !== void 0 && passenger.lap_child_name) {
        passengerAges.LAP_CHILD = passengerAges.LAP_CHILD + 1;
      }
    });
    return {
      pax_adult: "".concat(passengerAges.ADULT),
      pax_child_0_4: "".concat(passengerAges.INFANT_IN_SEAT),
      pax_child_5_11: "".concat(passengerAges.MINOR),
      pax_lapchild: "".concat(passengerAges.LAP_CHILD),
      pax_teen: "".concat(passengerAges.YOUTH)
    };
  }
  function getTripType(trip) {
    var tripType = "MC";
    if (trip === "roundtrip") {
      tripType = "RT";
    } else if (trip === "oneway") {
      tripType = "OW";
    }
    return tripType;
  }
  function hasInternationalBound() {
    return bounds === null || bounds === void 0 ? void 0 : bounds.some(function (bound) {
      return (bound === null || bound === void 0 ? void 0 : bound.international) === true;
    });
  }
  function hasMinorTraveler() {
    return passengers === null || passengers === void 0 ? void 0 : passengers.some(function (passenger) {
      return (passenger === null || passenger === void 0 ? void 0 : passenger.age_type) === "MINOR";
    });
  }
  function isEarlyBirdPurchased() {
    return passengers === null || passengers === void 0 ? void 0 : passengers.some(function (passenger) {
      var _passenger$segment_fe;
      return passenger === null || passenger === void 0 || (_passenger$segment_fe = passenger.segment_features) === null || _passenger$segment_fe === void 0 ? void 0 : _passenger$segment_fe.some(function (segmentFeature) {
        var _segmentFeature$ancil;
        return segmentFeature === null || segmentFeature === void 0 || (_segmentFeature$ancil = segmentFeature.ancillaries_purchased) === null || _segmentFeature$ancil === void 0 ? void 0 : _segmentFeature$ancil.includes("AUTO_CHECKIN");
      });
    });
  }
  function isMinorUnaccompanied() {
    return hasMinorTraveler() && !(associated_reservations !== null && associated_reservations !== void 0 && associated_reservations.some(function (reservation) {
      return (reservation === null || reservation === void 0 ? void 0 : reservation.type) === "ADULT";
    }));
  }
  function isWheelchairNeeded() {
    return passengers === null || passengers === void 0 ? void 0 : passengers.some(function (passenger) {
      var _passenger$special_se;
      return pnrWheelchairAssistance.includes(passenger === null || passenger === void 0 || (_passenger$special_se = passenger.special_services) === null || _passenger$special_se === void 0 ? void 0 : _passenger$special_se.wheelchair_assistance);
    });
  }
}