import PropTypes from "prop-types";
import React from "react";

import { useDeviceInfo } from "@swa-ui/browser";

import { applyOverrides } from "../applyOverrides";
import { CarouselPlacement } from "./CarouselPlacement";
import { MatrixPlacement } from "./MatrixPlacement";

export const AdaptiveListPlacement = (props) => {
  const { placement } = props;
  const { items, settings } = placement;
  const { screenSize } = useDeviceInfo();
  const { maxItemsInMatrix } = applyOverrides(settings, screenSize);
  let Component;

  if (items.length > maxItemsInMatrix) {
    Component = <CarouselPlacement {...props} />;
  } else {
    Component = <MatrixPlacement {...props} />;
  }

  return Component;
};

AdaptiveListPlacement.propTypes = {
  placement: PropTypes.shape({
    /** List of items to show in List, object shape depends on item type. */
    items: PropTypes.arrayOf(PropTypes.object),

    /** Name of the item component to render. */
    listItemType: PropTypes.string,

    /** Placement configuration. */
    settings: PropTypes.shape({
      /** Center the carousel when possible, pass-through to ListScroller. */
      center: PropTypes.bool,

      /** Array of arrays (matrix) with information about the layout to render. */
      layout: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),

      /** Number of items that matrix supports. If items exceeds this number, it shows a carousel. */
      maxItemsInMatrix: PropTypes.number,

      /** Number of items show in Carousel, pass-through to ListScroller. */
      numberOfColumns: PropTypes.number,

      /** Override for all properties in settings by device screen size. */
      overrides: PropTypes.object,

      /** Wether or not to show the pages indicator, pass-through to ListScroller. */
      showPositionIndicator: PropTypes.bool,
    }),
  }),
};
