import React from "react";

import { DropDown } from "@swa-ui/core";
import { useColorTheme } from "@swa-ui/theme";

/**
 * ThemeSelector provides the ability to select a theme in the header.
 */

export const ThemeSelector = () => {
  const { getDark, setDark } = useColorTheme();

  return <DropDown {...getThemeDropDownProps()} />;

  function getThemeDropDownProps() {
    return {
      defaultSelectedIndex: getDark() === "light" ? 0 : 1,
      list: ["light", "dark"],
      minimumListWidth: "small",
      name: "themeSelector",
      onChange: handleThemeChange,
      size: "small",
    };
  }

  function handleThemeChange(event) {
    const {
      target: { value },
    } = event;

    setDark(value);
  }
};
