var MP = {
  /* ! mp_trans_disable_start */
  Version: '1.0.23',
  Domains: {
    es: 'espanol.southwest.com'
  },
  SrcLang: 'en',

  /* ! mp_trans_disable_end */
  UrlLang: 'mp_js_current_lang',
  SrcUrl: decodeURIComponent('mp_js_orgin_url'),
  window: typeof window !== 'undefined' ? window : undefined,
  document: typeof document !== 'undefined' ? document : undefined,

  /* ! mp_trans_disable_start */
  init: function init() {
    if (MP.UrlLang.indexOf('p_js_') === 1) {
      MP.SrcUrl = MP.window.top.document.location.href;
      MP.UrlLang = MP.SrcLang;
    }
  },
  getCookie: function getCookie(name) {
    var start = MP.document.cookie.indexOf("".concat(name, "="));

    if (start < 0) {
      return null;
    }

    start = start + name.length + 1;
    var end = MP.document.cookie.indexOf(';', start);

    if (end < 0) {
      end = MP.document.cookie.length;
    }

    while (MP.document.cookie.charAt(start) === ' ') {
      start += 1;
    }

    return decodeURIComponent(MP.document.cookie.substring(start, end));
  },
  setCookie: function setCookie(name, value, path, domain) {
    var cookie = "".concat(name, "=").concat(encodeURIComponent(value));

    if (path) {
      cookie += "; path=".concat(path);
    }

    if (domain) {
      cookie += "; domain=".concat(domain);
    }

    var now = new Date();
    now.setTime(now.getTime() + 1000 * 60 * 60 * 24 * 365);
    cookie += "; expires=".concat(now.toUTCString());
    MP.document.cookie = cookie;
  },

  /* eslint-disable max-len */
  switchLanguage: function switchLanguage(lang) {
    var script = null;

    if (lang !== MP.SrcLang) {
      script = MP.document.createElement('SCRIPT');
      script.src = "".concat(location.protocol, "//").concat(MP.Domains[lang], "/").concat(MP.SrcLang).concat(lang, "/?1023749632;").concat(encodeURIComponent(location.href));
      MP.document.body.appendChild(script);
    } else if (lang === MP.SrcLang && MP.UrlLang !== MP.SrcLang) {
      script = MP.document.createElement('SCRIPT');
      script.src = "".concat(location.protocol, "//").concat(MP.Domains[MP.UrlLang], "/").concat(MP.SrcLang).concat(MP.UrlLang, "/?1023749634;").concat(encodeURIComponent(location.href));
      MP.document.body.appendChild(script);
    }

    return false;
  },
  setWindow: function setWindow(win) {
    MP.window = win;
  },
  setDocument: function setDocument(doc) {
    MP.document = doc;
  },
  switchToLang: function switchToLang(url) {
    MP.window.top.location.href = url;
  }
  /* ! mp_trans_disable_end */

};
module.exports = MP;