import PropTypes from "prop-types";
import React, { useContext } from "react";

import { FetchOptionsContext } from "../FetchOptionsContext";

/**
 * Fetch options used by useFetch. Options will be shallow merged with any other existing context options.
 * See https://use-http.com/#/?id=options for more details.
 */

export const FetchOptions = (props) => {
  const { children, options } = props;
  const fetchOptions = useContext(FetchOptionsContext);

  return (
    <FetchOptionsContext.Provider value={updateOptions()}>{children}</FetchOptionsContext.Provider>
  );

  function updateOptions() {
    return {
      ...fetchOptions,
      ...options,
      headers: {
        ...fetchOptions.headers,
        ...options.headers,
      },
    };
  }
};

FetchOptions.propTypes = {
  /**
   * Content to be rendered and will use these fetch options.
   */
  children: PropTypes.node,

  /**
   * Fetch options used by useFetch. Options will be shallow merged with any other existing context options.
   * See https://use-http.com/#/?id=options for more details.
   */
  options: PropTypes.object,
};
