import { useRef, useState } from "react";

import { useDismiss } from "../useDismiss";

/**
 * useModal encapsulates the functionality required to drive a Modal component.
 * Current functionality:
 * - ESC to close
 * - close on clicking outside of component.
 *
 * location: String that indicates the directional position of the modal.
 * initialLocation: String that indicates the starting directional position of the modal.
 * onClose: callback to be informed when Dialog closes.
 */

export const useModal = (
  location = "above",
  initialLocation = "hidden",
  onClose = () => {},
  restoreFocusOnClose = true
) => {
  const [modalLocation, setModalLocation] = useState(initialLocation);
  const modalRef = useRef(null);
  const isModalVisible = modalLocation !== "hidden";

  useDismiss(modalRef, hideModal, isModalVisible, onClose, restoreFocusOnClose);

  return {
    hideModal,
    isModalVisible,
    modalLocation,
    modalRef,
    showModal,
  };

  function hideModal() {
    setModalLocation("hidden");
  }

  function showModal() {
    setModalLocation(location);
  }
};
