import PropTypes from "prop-types";
import React, { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { window } from "@swa-ui/browser";
import { swaDate } from "@swa-ui/date";
import { isResponseOk, useFetch } from "@swa-ui/fetch";

const MILLISECONDS_IN_A_MINUTE = 1000 * 60;
const SITE_STATUS_URL = "/swa-resources/config/status.json";
const STALE_DURATION_IN_MINUTES = 1;
const STALE_DURATION_IN_MILLISECONDS = STALE_DURATION_IN_MINUTES * MILLISECONDS_IN_A_MINUTE;

/**
 * SiteStatusProvider provides fit status, and any other site wide status, in a React context so the status is available
 * to components in an app's render tree. The site wide status is retrieved from a file that is managed by WCM.
 *
 * The siteStatus supplies a React context with these values:
 * fitState - Boolean to indicate if there is a Flight In Trouble state.
 * fitUrl - When fitState is true, a URL will be available for the FIT message content.
 * lastUpdateTime - Time when the site status was last retrieved.
 */

export const SiteStatusContext = createContext();
export const SiteStatusProvider = (props) => {
  const { children } = props;
  const { get, response } = useFetch();
  const { pathname } = useLocation();
  const [siteStatus, setSiteStatus] = useState(getDefaultSiteStatus);

  useEffect(() => {
    const { lastUpdateTime } = siteStatus;

    if (!lastUpdateTime || lastUpdateTime < swaDate().valueOf() - STALE_DURATION_IN_MILLISECONDS) {
      retrieveSiteStatus();
    }
  }, [pathname]);

  return <SiteStatusContext.Provider value={{ siteStatus }}>{children}</SiteStatusContext.Provider>;

  function getDefaultSiteStatus() {
    const defaultSiteStatus = window.swa?.status ?? {
      fitState: false,
      fitUrl: "",
    };

    return addLastUpdatedTime(defaultSiteStatus);
  }

  async function retrieveSiteStatus() {
    const body = await get(SITE_STATUS_URL);

    if (isResponseOk(response) && body?.status) {
      updateSiteStatus(body.status);
    }
  }

  function updateSiteStatus(newSiteStatus) {
    setSiteStatus(
      addLastUpdatedTime({
        ...siteStatus,
        ...newSiteStatus,
      })
    );
  }

  function addLastUpdatedTime(status) {
    return { ...status, lastUpdateTime: swaDate().valueOf() };
  }
};

SiteStatusProvider.propTypes = {
  /** Content that will be rendered as the page body. */
  children: PropTypes.node.isRequired,
};
