import PropTypes from "prop-types";
import React from "react";

export const Zero = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path
        strokeWidth={0}
        d="M20,13.8c-0.3-0.2-0.6-0.3-1-0.3s-0.7,0.1-1,0.3s-0.5,0.6-0.7,1.3C17.1,15.8,17,17.2,17,19
			s0.1,3.1,0.3,3.8c0.2,0.7,0.4,1.2,0.7,1.4c0.3,0.2,0.6,0.3,1,0.3s0.7-0.1,1-0.3s0.5-0.7,0.7-1.3c0.2-0.8,0.3-2.1,0.3-3.9
			s-0.1-3.1-0.3-3.8C20.5,14.5,20.3,14,20,13.8z"
      />
      <path
        strokeWidth={0}
        d="M19,0C8.5,0,0,8.5,0,19s8.5,19,19,19s19-8.5,19-19S29.5,0,19,0z M22.6,25.4c-0.9,1.1-2,1.6-3.6,1.6
			c-1.5,0-2.8-0.6-3.7-1.8c-0.9-1.2-1.4-3.3-1.4-6.3s0.5-5.1,1.5-6.4c0.9-1.1,2-1.6,3.6-1.6c1.5,0,2.7,0.5,3.6,1.6
			c1,1.3,1.5,3.4,1.5,6.4C24.1,22,23.6,24.1,22.6,25.4z"
      />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      style,
      viewBox: "0 0 38 38",
    };
  }
};

Zero.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Zero.defaultProps = {
  role: "img",
};
