import PropTypes from "prop-types";
import React, { useContext } from "react";

import { FetchOptionsContext } from "../FetchOptionsContext";

/**
 * Use to configure request, response and error handlers.
 * See https://use-http.com/#/?id=requestresponse-interceptors-with-provider for more details.
 */

export const FetchInterceptor = (props) => {
  const { children, onError, onRequest, onResponse } = props;
  const fetchOptions = useContext(FetchOptionsContext);

  return (
    <FetchOptionsContext.Provider value={appendInterceptorSettings()}>
      {children}
    </FetchOptionsContext.Provider>
  );

  function appendInterceptorSettings() {
    return {
      ...fetchOptions,
      errorHandlers: onError
        ? fetchOptions.errorHandlers.concat(onError)
        : fetchOptions.errorHandlers,
      requestHandlers: onRequest
        ? fetchOptions.requestHandlers.concat(onRequest)
        : fetchOptions.requestHandlers,
      responseHandlers: onResponse
        ? fetchOptions.responseHandlers.concat(onResponse)
        : fetchOptions.responseHandlers,
    };
  }
};

FetchInterceptor.propTypes = {
  /**
   * Content to be rendered. Any useFetch calls made from the children will be intercepted.
   */
  children: PropTypes.node,

  /**
   * Runs when the request gets an error. If retrying, it is only called on the last retry attempt.
   */
  onError: PropTypes.func,

  /**
   * Allows you to do something before an http request is sent out.
   */
  onRequest: PropTypes.func,

  /**
   * Allows you to do something after an http response is received.
   */
  onResponse: PropTypes.func,
};
