import PropTypes from "prop-types";
import React from "react";

import { Button } from "@swa-ui/core";

import styles from "./BaseAuthenticationForm.module.scss";
import { LoginSubmitButton } from "./LoginSubmitButton";

export const ActionSection = (props) => {
  const { additionalButtonProps } = props;

  return (
    <div className={styles.primary}>
      {additionalButtonProps && <Button {...additionalButtonProps} />}
      <LoginSubmitButton />
    </div>
  );
};

ActionSection.propTypes = {
  /** Props to be passed to the additional button. */
  additionalButtonProps: PropTypes.shape(Button.propTypes),
};
