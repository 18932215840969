import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import { window } from "@swa-ui/browser";

import { Step, stepPropTypes } from "./Step";

const DELAY_FOR_BROWSER = 16;

/**
 * ControlledPlayList performs element transitions using steps provided by the caller. This is useful when more than
 * one step is needed to perform an animation. ControlledPlayList works for cases where the steps need to be dynamic or
 * the child content changes between steps.
 *
 * When a list of steps can be defined upfront, and when the child content doesn't need to change between steps,
 * PlayList should be used. See that component for more information.
 */

export const ControlledPlayList = React.memo((props) => {
  const { animationToken, className, onPlayListComplete, onStepEnd, playList, transformOrigin } =
    props;
  const [currentAnimationToken, setCurrentAnimationToken] = useState();
  const [currentPlayList, setCurrentPlayList] = useState([]);
  const [currentPlayListIndex, setCurrentPlayListIndex] = useState();
  const playListCompleteCalled = useRef(false);

  useEffect(() => {
    if (animationToken !== currentAnimationToken || currentAnimationToken === undefined) {
      setCurrentPlayList(playList);
      setCurrentPlayListIndex(0);
      setCurrentAnimationToken(animationToken);
      playListCompleteCalled.current = false;
    }
  }, [animationToken]);

  return currentPlayList?.length && currentPlayListIndex !== undefined ? (
    <Step {...getProps()} />
  ) : null;

  function getProps() {
    return {
      className,
      onStepEnd: handleStepEnd,
      step: currentPlayList[Math.min(currentPlayListIndex, currentPlayList.length - 1)].step,
      transformOrigin,
    };
  }

  function handleStepEnd() {
    if (currentPlayListIndex === currentPlayList.length - 1) {
      onPlayListComplete && onPlayListComplete();
      playListCompleteCalled.current = true;
    } else if (currentPlayListIndex < currentPlayList.length) {
      onStepEnd && onStepEnd(currentPlayListIndex);
      window.setTimeout(() => {
        setCurrentPlayListIndex(currentPlayListIndex + 1);
      }, DELAY_FOR_BROWSER);
    }
  }
});

export const controlledPlayListPropTypes = {
  /**
   * Unique id that forces an animation (re-render). TransitionBlock may be rendered many times during its life cycle,
   * but it is not always desirable to animate the content when rendering. The calling component is responsible for
   * letting TransitionBlock know that an animation is required by passing a different value for animationToken than
   * the last render.
   */
  animationToken: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Informs the caller when all actions in the playList have been completed. */
  onPlayListComplete: PropTypes.func,

  /** Informs the caller between each set of actions. */
  onStepEnd: stepPropTypes.onStepEnd,

  /** Animation options to apply to child content. See Transform for more info. */
  playList: PropTypes.arrayOf(stepPropTypes.step),

  /**
   * transformOrigin pertains to how things are rotated and scaled. See Transform for more info. transformOrigin can
   * be given for each step, or can be given at the component to affect all steps.
   */
  transformOrigin: stepPropTypes.transformOrigin,
};

ControlledPlayList.displayName = "ControlledPlayList";

ControlledPlayList.propTypes = controlledPlayListPropTypes;
