import PropTypes from "prop-types";
import React from "react";

export const CreditCard = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path
        d="M4.6,17.4h5c0.7,0,1.3,0.6,1.3,1.3c0,0.7-0.6,1.3-1.3,1.3h-5c-0.7,0-1.3-0.6-1.3-1.3C3.3,18,3.9,17.4,4.6,17.4
		 M4.6,12.4h11.5c0.7,0,1.3,0.6,1.3,1.3c0,0.7-0.6,1.3-1.3,1.3H4.6c-0.7,0-1.3-0.6-1.3-1.3C3.3,12.9,3.9,12.4,4.6,12.4 M32.2,12.4h5
		c0.7,0,1.3,0.6,1.3,1.3c0,0.7-0.6,1.3-1.3,1.3h-5c-0.7,0-1.3-0.6-1.3-1.3C30.9,12.9,31.5,12.4,32.2,12.4 M0,23.7
		c0,1.6,1.3,2.9,2.9,2.9h35.9c1.6,0,2.9-1.3,2.9-2.9V9.2H0V23.7z"
      />
      <path d="M38.8,0H2.9C1.3,0,0,1.3,0,2.9v2.6h41.7V2.9C41.7,1.3,40.4,0,38.8,0" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "0 0 41.7 26.7",
    };
  }
};

CreditCard.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

CreditCard.defaultProps = {
  role: "img",
};
