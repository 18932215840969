import PropTypes from "prop-types";
import React from "react";

import { SMALL, useDeviceInfo, XLARGE } from "@swa-ui/browser";
import { Heading, Layout, Section } from "@swa-ui/core";
import { classNames } from "@swa-ui/string";

import { applyOverrides } from "../applyOverrides";
import { CallToAction } from "../CallToAction";
import styles from "./PlacementSection.module.scss";

export const PlacementSection = (props) => {
  const { background, callToAction, children, className, fullWidth, heading, layout } = props;
  const { screenSize } = useDeviceInfo();

  return (
    <Section className={classNames(styles.section, className)}>
      <div {...getSectionBackgroundProps()}>{renderSection()}</div>
    </Section>
  );

  function renderSection() {
    let content;

    if (layout === "compact") {
      content = screenSize === SMALL ? renderSmallCompactSection() : renderCompactSection();
    } else if (layout === "heading") {
      content = renderHeadingSection();
    } else {
      content = renderDefaultSection();
    }

    return content;
  }

  function renderCompactSection() {
    return (
      <>
        <Layout {...getCompactLayoutProps()}>
          {renderHeading()}
          {renderCallToAction()}
        </Layout>
        {children}
      </>
    );
  }

  function renderSmallCompactSection() {
    return (
      <>
        <Layout bottom="medium" sides>
          {renderHeading()}
        </Layout>
        {children}
        <Layout top="medium" sides>
          <div>{renderCallToAction()}</div>
        </Layout>
      </>
    );
  }

  function renderHeadingSection() {
    return <Layout {...getDefaultLayoutProps()}>{renderHeading()}</Layout>;
  }

  function renderDefaultSection() {
    return (
      <>
        {renderHeading()}
        <Layout {...getDefaultLayoutProps()}>{renderChildren()}</Layout>
        {renderCallToAction()}
      </>
    );
  }

  function renderChildren() {
    return React.cloneElement(children, {
      className: classNames(children.props.className, {
        [styles.content]: !fullWidth,
      }),
    });
  }

  function renderCallToAction() {
    return callToAction ? <CallToAction {...callToAction} /> : null;
  }

  function renderHeading() {
    return heading ? <Heading {...getHeadingProps()} /> : null;
  }

  function getSectionBackgroundProps() {
    const { color, imagePath, type } = applyOverrides(background, screenSize);
    const { spaced } = props;

    return {
      className: classNames({
        [styles.spaced]: spaced,
      }),
      style: {
        ...(type === "image" && { backgroundImage: `url(${imagePath})` }),
        ...(type === "color" && { backgroundColor: color }),
      },
    };
  }

  function getCompactLayoutProps() {
    return {
      bottom: "medium",
      center: screenSize === XLARGE,
      className: styles.compactHeading,
      sides: fullWidth,
    };
  }

  function getDefaultLayoutProps() {
    return {
      center: screenSize === XLARGE,
      sides: !fullWidth,
    };
  }

  function getHeadingProps() {
    const { color, fontSize, fontWeight, headingLevel = 2, styleLevel, value } = heading ?? {};

    return {
      children: <span style={{ color, fontSize, fontWeight }}>{value}</span>,
      headingLevel,
      styleLevel,
    };
  }
};

PlacementSection.propTypes = {
  /** Background image/color content for the section. Defaults to an empty object. */
  background: PropTypes.shape({
    color: PropTypes.string,
    imagePath: PropTypes.string,
    type: PropTypes.oneOf(["color", "image"]),
  }),

  /** Props for Call To Action. */
  callToAction: PropTypes.shape({
    buttonType: PropTypes.string,
    newWindow: PropTypes.bool,
    target: PropTypes.string,
    text: PropTypes.string,
    type: PropTypes.string,
  }),

  /** Content of the section. */
  children: PropTypes.node,

  /** Class configuration. */
  className: PropTypes.string,

  /** Makes the section full width, removing the Grid. */
  fullWidth: PropTypes.bool,

  /** Heading block for the section. */
  heading: PropTypes.shape({
    headingLevel: PropTypes.number,
    styleLevel: PropTypes.number,
    text: PropTypes.string,
  }),

  /** Changes the layout structure of this section. */
  layout: PropTypes.oneOf(["compact", "default", "heading"]),

  /** Add internal spacing for the section. */
  spaced: PropTypes.bool,
};
