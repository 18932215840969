import * as yup from "yup";

import { regexes } from "@swa-ui/form";
import i18n from "@swa-ui/locale";

export const REDRESS_NUMBER_SCHEMA_FIELD_NAME = {
  REDRESS_NUMBER: "redressNumber",
};

export const getRedressNumberFormFieldSchema = () =>
  yup
    .string()
    .max(13)
    .matches(regexes.alphaNumeric)
    .label(i18n("RedressNumberFormField__LABEL").toLowerCase());
