import PropTypes from "prop-types";
import React from "react";

export const WhereWeFly = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path
        d="M14.2,6.1C14.2,6.1,14.2,6.1,14.2,6.1C14.2,6.1,14.2,6.1,14.2,6.1c-1.2-0.6-2.4-0.9-3.6-1
	C10.3,5.8,10,6.4,9.7,7c1.2,0,2.5,0.3,3.6,0.9c1.3,0.6,2.4,1.5,3.2,2.6c0.2-0.7,0.6-1.3,1.1-1.8C16.7,7.7,15.5,6.8,14.2,6.1"
      />
      <path
        d="M16.8,14.7C16.8,14.7,16.8,14.6,16.8,14.7c0-0.1-0.1-0.1-0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0
	c-2.5-0.6-5-0.3-7.2,0.7c0,0.1,0,0.2,0,0.3c0,0.7-0.1,1.4-0.4,2.2c2-1.3,4.6-1.9,7.1-1.3c0.6,0.1,1.1,0.3,1.6,0.6
	C17.5,16.3,17.1,15.5,16.8,14.7"
      />
      <path
        d="M4.1,17.2c-0.8,0-1.5-0.7-1.5-1.5c0-0.8,0.7-1.5,1.5-1.5c0.8,0,1.5,0.7,1.5,1.5C5.5,16.5,4.9,17.2,4.1,17.2
	 M4.1,11.6c-2.2,0-4.1,1.9-4.1,4.1c0,0.7,0.2,1.4,0.4,2.1C1.7,21.2,4.1,24,4.1,24s2.3-2.7,3.6-6c0.3-0.8,0.5-1.5,0.5-2.2
	C8.1,13.5,6.3,11.6,4.1,11.6"
      />
      <path
        d="M21.2,13.5c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3c0.7,0,1.3,0.6,1.3,1.3
	C22.4,12.9,21.9,13.5,21.2,13.5 M21.2,8.7c-2,0-3.6,1.6-3.6,3.6c0,0.6,0.1,1.2,0.4,1.9c1.1,2.9,3.2,5.4,3.2,5.4s2.1-2.4,3.2-5.3
	c0.3-0.7,0.4-1.3,0.4-2C24.7,10.3,23.1,8.7,21.2,8.7"
      />
      <path
        d="M6.9,3.9c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1c0.6,0,1,0.5,1,1C7.9,3.4,7.5,3.9,6.9,3.9 M6.9,0
	C5.3,0,4.1,1.3,4.1,2.9c0,0.5,0.1,1,0.3,1.5c0.9,2.3,2.5,4.3,2.5,4.3s1.6-1.9,2.5-4.2c0.2-0.5,0.3-1,0.3-1.6C9.8,1.3,8.5,0,6.9,0"
      />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "0 0 24.7 24",
    };
  }
};

WhereWeFly.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

WhereWeFly.defaultProps = {
  role: "img",
};
