import PropTypes from "prop-types";
import React from "react";

import { SubmitFormField } from "@swa-ui/form";
import i18n from "@swa-ui/locale";

export const LoginSubmitButton = (props) => {
  const { fullWidth, submitButtonText } = props;

  return (
    <SubmitFormField {...getSubmitButtonProps()}>
      {submitButtonText ?? i18n("BaseAuthenticationForm__BUTTON__LOG_IN")}
    </SubmitFormField>
  );

  function getSubmitButtonProps() {
    return {
      componentProps: {
        fullWidth,
      },
      hideBottomSection: true,
      name: "submit",
    };
  }
};

LoginSubmitButton.propTypes = {
  /** Whether the submit button should be full width. */
  fullWidth: PropTypes.bool,

  /** Text to be displayed on the submit button. */
  submitButtonText: PropTypes.string,
};

LoginSubmitButton.defaultProps = {
  fullWidth: false,
};
