import PropTypes from "prop-types";
import React from "react";

export const Feedback = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path d="M24.7,8.14a5.47,5.47,0,0,1,0,6.91,1,1,0,1,1-1.5-1.22,3.57,3.57,0,0,0,0-4.47,1,1,0,0,1,1.5-1.22Zm3.81-1.7a9.48,9.48,0,0,1,0,10.21,1,1,0,0,1-1.34.3,1,1,0,0,1-.29-1.34,7.56,7.56,0,0,0,0-8.13,1,1,0,0,1,.29-1.33A1,1,0,0,1,28.51,6.44ZM13.62,4.38a6.5,6.5,0,0,1,6.46,6.78c0,3.91-2.9,7.42-6.46,7.42s-6.46-3.51-6.46-7.42A6.53,6.53,0,0,1,13.62,4.38ZM8.27,17.83a28.24,28.24,0,0,0,5.56,3.78A31.09,31.09,0,0,0,19.27,18l.92.31.32.1.55.18c3.46,1.24,4.18,3.48,4.18,4.36a11.86,11.86,0,0,1-.12,1.65s-3.41,3.07-11.29,3.07S2.12,24.55,2.12,24.55A11.86,11.86,0,0,1,2,22.9c0-.82.72-3.12,4.18-4.36Z" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      style,
      viewBox: "0 0 32 32",
    };
  }
};

Feedback.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Feedback.defaultProps = {
  role: "img",
};
