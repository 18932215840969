import PropTypes from "prop-types";
import React from "react";

export const Car = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path
        d="M157.384,43.78l-2.896-2.086h5.218c3.36,0,4.737-2.773,4.737-6.148c0-3.378-1.377-6.156-4.737-6.156h-12.01l-9.704-17.01
	c-0.953-2.538-2.398-4.836-4.342-6.75C130.196,2.19,125.1,0,118.038,0h-0.116h-16.7h-0.228H73.047h-0.238H56.122h-0.115
	c-7.08,0-12.162,2.19-15.615,5.629c-1.951,1.914-3.39,4.212-4.347,6.75L26.34,29.39h-12c-3.374,0-4.749,2.777-4.749,6.156
	c0,3.375,1.375,6.148,4.749,6.148h5.214l-2.912,2.086C10.331,45.368,0,50.833,0,63.481v70.619c0,2.72,2.202,4.926,4.946,4.926
	h15.388c2.738,0,4.938-2.206,4.938-4.926v-17.192h3.411H145.36h3.408v17.192c0,2.72,2.204,4.926,4.934,4.926h15.407
	c2.724,0,4.928-2.206,4.928-4.926V63.481C174.037,50.833,163.7,45.368,157.384,43.78z M50.71,12.64h72.773
	c0.098,0.095,15.676,30.01,15.676,30.01H34.881C34.881,42.65,50.806,12.735,50.71,12.64z M28.555,86.955
	c-7.589,0-13.738-6.16-13.738-13.749c0-7.585,6.149-13.736,13.738-13.736c7.593,0,13.755,6.151,13.755,13.736
	C42.31,80.795,36.148,86.955,28.555,86.955z M57.091,86.93l0.038-14.183c0.2,0,59.807,0.033,59.782,0l0.011,14.216L57.091,86.93z
	 M145.478,86.955c-7.586,0-13.74-6.16-13.74-13.749c0-7.585,6.154-13.736,13.74-13.736c7.598,0,13.739,6.151,13.739,13.736
	C159.217,80.795,153.075,86.955,145.478,86.955z"
      />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      style,
      viewBox: "0 0 175 140",
    };
  }
};

Car.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Car.defaultProps = {
  role: "img",
};
