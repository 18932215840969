import { withFormField } from "@swa-ui/form";

import { TripTypeDrawer } from "../TripTypeDrawer";

/**
 * TripTypeFormField provides support for a standard TripTypeDrawer
 * component to be used in a Form.
 */
export const TripTypeFormField = withFormField(TripTypeDrawer, {
  useController: true,
});
