import PropTypes from "prop-types";
import React from "react";

import { FormattedInputFormField, TextFormField } from "@swa-ui/form";
import i18n from "@swa-ui/locale";

import { PHONE_NUMBER_SCHEMA_FIELD_NAMES } from "./PhoneNumberFormField.schema";

const INTERNATIONAL_PHONE_NUMBER_LENGTH = 12;
const NORTH_AMERICAN_PHONE_NUMBER_LENGTH = 10;

/**
 * PhoneNumberFormField facilitates the input of a phone number based on if the country code is domestic or i18n.
 */

export const PhoneNumberFormField = (props) => {
  const {
    caption,
    className,
    componentProps = {},
    defaultValue,
    disabled,
    domestic,
    label,
    name,
  } = props;

  return domestic ? (
    <FormattedInputFormField {...getPhoneNumberProps()} />
  ) : (
    <TextFormField {...getPhoneNumberProps()} />
  );

  function getPhoneNumberProps() {
    return {
      caption: caption ?? i18n("PhoneNumberFormField__CAPTION"),
      componentProps: {
        acceptableCharacters: "0123456789",
        "aria-label": props["aria-label"] ?? i18n("PhoneNumberFormField__ARIA"),
        className,
        defaultValue: getUnformattedPhoneNumber(defaultValue),
        inputMode: "tel",
        maxLength: domestic
          ? NORTH_AMERICAN_PHONE_NUMBER_LENGTH
          : INTERNATIONAL_PHONE_NUMBER_LENGTH,
        required: true,
        ...(domestic && {
          formatTemplate: "(___) ___-____",
        }),
        ...componentProps,
      },
      disabled,
      label: label ?? i18n("PhoneNumberFormField__LABEL"),
      name: name ?? PHONE_NUMBER_SCHEMA_FIELD_NAMES.PHONE_NUMBER,
    };
  }

  function getUnformattedPhoneNumber(number) {
    return number?.replace(/[()-\s]+/g, "");
  }
};

PhoneNumberFormField.propTypes = {
  /** Content to be read by the screen reader. */
  "aria-label": PropTypes.string,

  /** Content below the form field. */
  caption: PropTypes.string,

  /** Class name for positioning the component. */
  className: PropTypes.string,

  /** Additional props to pass to the base form field component. */
  componentProps: PropTypes.object,

  /** Default value for the Phone number. */
  defaultValue: PropTypes.string,

  /** Indicates whether the field is disabled. */
  disabled: PropTypes.bool,

  /** Indicates whether the phone number is domestic. */
  domestic: PropTypes.bool,

  /** Label to identify the form field. */
  label: PropTypes.node,

  /** Form field name used in schema validation and service call payload. */
  name: PropTypes.string,
};
