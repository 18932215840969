export default {
  light: {
    "footer-content": "blue-1",
    "header-control-link": "blue-1",
    "header-link": "blue-1",
    "header-separator": "white",
    "page-background": "neutral-800",
    "primary-header-background": "white",
    "primary-header-heading-icon": "gray-4",
    "primary-header-logo": "blue-1",
    "primary-header-logo-light": "white",
    "primary-header-search": "blue-1",
    "primary-header-search-autocomplete": "blue-1",
    "primary-header-search-autocomplete-input": "blue-3",
    "primary-header-search-button": "blue-3",
    "primary-header-search-disabled": "gray-4",
    "primary-header-search-icon": "blue-3",
    "primary-header-search-label": "gray-4",
    "primary-header-search-open": "white",
    "primary-header-search-shadow": "gray-4",
    "secondary-header-border": "blue-1",
    "secondary-header-logo": "white",
    "secondary-header-logo-section-background": "blue-1",
    "secondary-header-navigation-link-section-background": "blue-1",
    "secondary-header-navigation-link-section-foreground": "gray-2",
    "secondary-header-options-background": "white",
    "secondary-header-title-section-background": "blue-2",
  },
  dark: {
    "footer-content": "white",
    "header-control-link": "white",
    "header-link": "white",
    "header-separator": "white",
    "primary-header-background": "blue-2",
    "primary-header-heading-icon": "blue-1-light",
    "primary-header-logo": "white",
    "primary-header-logo-light": "white",
    "primary-header-search": "white",
    "primary-header-search-button": "white",
    "primary-header-search-icon": "white",
    "primary-header-search-label": "white",
    "primary-header-search-open": "white",
    "secondary-header-border": "gray-2",
    "secondary-header-logo": "white",
    "secondary-header-logo-section-background": "blue-1",
    "secondary-header-title-section-background": "blue-2",
    "secondary-header-options-background": "blue-3",
  },
};
