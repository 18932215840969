import PropTypes from "prop-types";
import React from "react";

export const Celebration = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <circle cx="20.44" cy="16.78" r="1.58" />
      <circle cx="12.34" cy="13.37" r="2.15" />
      <path d="M18.02,23.22l-3.25-3.96,.1-1.38,2.33-4.12c.13-.24,.08-.53-.13-.7-.23-.19-.57-.16-.76,.07l-2.54,2.9h-2.61l-3.07-2.58c-.29-.24-.73-.17-.93,.16h0c-.14,.23-.11,.52,.06,.72l3.13,3.61,.26,3.34h.03l.33,5.23c.03,.38,.34,.68,.72,.68s.69-.29,.72-.66l.45-5.25h.41l3.7,3c.31,.25,.76,.22,1.03-.08,.25-.27,.26-.69,.03-.98Z" />
      <path d="M24.88,17.72l-1.96-3.09c-.11-.17-.32-.24-.51-.17h0c-.23,.09-.34,.35-.23,.57l1.04,2.21c.1,.2,0,.44-.19,.54l-3.63,1.72-.82,.29v-1.51c-.01-.18-.16-.34-.34-.35h0c-.2-.02-.38,.14-.39,.34l-.14,2.35c-.01,.21,.16,.39,.38,.38l1.71-.08-1.76,6.13c-.07,.27,.03,.55,.27,.69h0c.3,.18,.69,.07,.85-.24l1.94-3.63,1.37,3.8c.08,.23,.31,.36,.55,.32h0c.25-.04,.43-.26,.41-.51l-.38-7.74,1.75-1.5c.15-.13,.18-.35,.07-.52Z" />
      <path d="M22.02,12.65s-.06-.05-.16-.13c-.1-.08-.25-.19-.45-.31-.09-.06-.2-.12-.31-.18-.11-.06-.23-.12-.35-.17-.13-.05-.25-.11-.39-.16-.07-.02-.14-.05-.2-.07-.07-.02-.14-.04-.21-.06-.14-.04-.27-.07-.41-.09-.14-.03-.27-.04-.4-.06-.13-.02-.26-.01-.38-.02-.06,0-.12,0-.17,0-.06,0-.11,0-.16,0-.1,0-.2,0-.28,.02s-.15,.03-.21,.03c-.12,.02-.18,.03-.18,.03l-.08-1.15s.08,0,.23,.02c.07,0,.16,.01,.26,.02s.21,.03,.34,.05c.06,.01,.12,.02,.19,.03s.13,.03,.2,.05c.14,.04,.28,.07,.42,.13,.14,.05,.29,.1,.43,.17,.14,.07,.29,.13,.43,.21,.07,.04,.14,.07,.21,.12s.13,.08,.2,.13c.13,.08,.25,.18,.37,.26,.11,.09,.22,.18,.32,.27,.1,.09,.19,.17,.27,.26,.16,.16,.28,.31,.37,.41,.08,.11,.12,.17,.12,.17Z" />
      <path d="M22.02,11.78s-.03-.09-.12-.23c-.04-.07-.09-.16-.15-.25-.06-.1-.13-.21-.22-.31-.08-.11-.18-.23-.28-.35-.05-.06-.11-.12-.17-.18-.06-.06-.11-.12-.18-.18-.06-.06-.13-.12-.19-.18-.07-.06-.14-.11-.21-.17-.07-.05-.14-.11-.21-.17-.07-.05-.15-.1-.22-.15l-.11-.07-.12-.07c-.08-.04-.16-.09-.23-.13-.07-.04-.16-.07-.24-.11s-.16-.07-.23-.1c-.15-.07-.31-.1-.45-.15-.03-.01-.07-.02-.1-.03l-.1-.03c-.07-.02-.14-.03-.2-.04-.13-.03-.24-.06-.35-.07-.1-.01-.2-.03-.27-.03-.15-.02-.24-.03-.24-.03l.21-1.13s.1,.03,.27,.09c.08,.03,.19,.06,.31,.1,.12,.03,.24,.1,.38,.16,.07,.03,.14,.06,.22,.1,.04,.02,.07,.03,.11,.05s.07,.04,.11,.06c.15,.09,.31,.17,.47,.27,.08,.05,.16,.11,.24,.16,.08,.06,.16,.1,.24,.17,.07,.06,.15,.13,.23,.19l.11,.1,.1,.1,.21,.21c.07,.07,.13,.15,.19,.22,.06,.07,.13,.14,.18,.22,.06,.07,.11,.15,.17,.23s.1,.15,.15,.22c.05,.07,.1,.14,.14,.22,.08,.15,.16,.28,.21,.41,.07,.13,.11,.25,.16,.36,.04,.11,.07,.21,.1,.28,.07,.15,.09,.24,.09,.24Z" />
      <path d="M22.56,11.46s0-.24-.05-.58c-.04-.34-.12-.8-.25-1.23-.03-.1-.07-.21-.1-.31-.04-.1-.08-.2-.12-.28-.08-.18-.18-.33-.26-.44-.04-.05-.08-.1-.12-.14-.03-.04-.07-.07-.1-.09s-.05-.03-.07-.04c-.01,0-.02-.01-.02-.01l.81-.81s.01,.02,.03,.05,.06,.07,.09,.13c.03,.06,.08,.13,.12,.21,.04,.08,.08,.17,.11,.27,.07,.2,.13,.43,.16,.66,.02,.12,.03,.24,.03,.36,0,.12,0,.24,0,.37-.02,.97-.27,1.87-.27,1.87Z" />
      <polygon points="19.19 4 19.52 4.68 20.27 4.79 19.73 5.32 19.86 6.06 19.19 5.72 18.52 6.06 18.65 5.32 18.1 4.79 18.85 4.68 19.19 4" />
      <polygon points="15.06 7.63 15.39 8.31 16.14 8.42 15.6 8.95 15.72 9.7 15.06 9.35 14.38 9.7 14.51 8.95 13.97 8.42 14.72 8.31 15.06 7.63" />
      <path d="M22.86,5.99c0,.27-.22,.5-.5,.5s-.5-.22-.5-.5,.22-.5,.5-.5,.5,.22,.5,.5Z" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "0 0 32 32",
    };
  }
};

Celebration.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Celebration.defaultProps = { role: "img" };
