import PropTypes from "prop-types";
import React from "react";

export const Sparkles = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path d="M25.3333 12L27 8.33337L30.6667 6.66671L27 5.00004L25.3333 1.33337L23.6667 5.00004L20 6.66671L23.6667 8.33337L25.3333 12Z" />
      <path d="M25.3333 20L23.6667 23.6667L20 25.3333L23.6667 27L25.3333 30.6667L27 27L30.6667 25.3333L27 23.6667L25.3333 20Z" />
      <path d="M15.3335 12.6667L12.0002 5.33337L8.66683 12.6667L1.3335 16L8.66683 19.3334L12.0002 26.6667L15.3335 19.3334L22.6668 16L15.3335 12.6667ZM13.3202 17.32L12.0002 20.2267L10.6802 17.32L7.7735 16L10.6802 14.68L12.0002 11.7734L13.3202 14.68L16.2268 16L13.3202 17.32Z" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "0 0 32 32",
    };
  }
};

Sparkles.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Sparkles.defaultProps = { role: "img" };
