import PropTypes from "prop-types";
import React from "react";

import { Grid, Layout, Message } from "@swa-ui/core";

import { HtmlValue } from "../HtmlValue";
import styles from "./MessageBlock.module.scss";

/**
 * MessageBlock renders the Message core component, and applies WCM placement data overrides
 * for various props based on screen size.
 */

export const MessageBlock = (props) => {
  const { placement } = props;
  const { fullWidth = false, messages } = placement ?? {};
  const message = messages?.length && messages[0];
  const Container = fullWidth ? React.Fragment : Grid;

  return (
    message && (
      <Container>
        <Layout className={styles.messageBlock} bottom="medium">
          <Message {...getMessageProps()}>
            <HtmlValue htmlValue={message.text?.value} />
          </Message>
        </Layout>
      </Container>
    )
  );

  function getMessageProps() {
    const { bannerType: styleType, heading: headingProps } = message;

    return {
      ...props,
      headingProps: {
        ...headingProps,
        children: headingProps?.value,
      },
      styleType,
    };
  }
};

MessageBlock.propTypes = {
  /** Placement object with message implementation details. */
  placement: PropTypes.shape({
    /** Array of messages to render, or single message object.  */
    messages: PropTypes.arrayOf(
      PropTypes.shape({
        /** What kind of Message style is rendered. See @swa-ui/core Message component for details. */
        bannerType: PropTypes.string,

        /** Props to be passed to the Heading component inside of Message. */
        heading: PropTypes.object,

        /** Text value to be used as the Message component's body. */
        text: PropTypes.object,
      })
    ),
  }),
};
