import PropTypes from "prop-types";
import React from "react";

import { Layout } from "../Layout";
import { Loading, loadingPropTypes } from "../Loading";
import { createHashCode } from "../utils";
import { DepictionBulletItem } from "./DepictionBulletItem";
import { DepictionRow } from "./DepictionRow";
import styles from "./LoadingDepiction.module.scss";

const TEMPLATE_ACCORDION = [
  {
    bottomMargin: "xlarge",
    color: "secondary",
    height: "xlarge",
  },
  {
    bottomMargin: "medium",
    numberOfColumns: 1,
  },
  {
    bottomMargin: "xlarge",
    color: "secondary",
    columnDefinitions: [
      {
        gridColumn: "1/7",
        height: "large",
      },
      {
        className: styles.right,
        color: "tertiary",
        gridColumn: "7/9",
        shape: "circle",
      },
    ],
    numberOfColumns: 8,
    numberOfRows: 3,
  },
];
const TEMPLATE_BULLETS = [
  {
    bottomMargin: "xlarge",
    color: "secondary",
  },
  {
    bottomMargin: "large",
  },
  {
    bottomMargin: "medium",
    numberOfRows: 4,
    shape: "bullet-list",
  },
];
const TEMPLATE_MATRIX = [
  {
    bottomMargin: "medium",
    color: "secondary",
  },
  {
    bottomMargin: "xlarge",
    color: "tertiary",
  },
  {
    bottomMargin: "medium",
    color: "tertiary",
    height: "xlarge",
    numberOfColumns: 3,
    numberOfRows: 2,
  },
];
const TEMPLATE_SIMPLE = [
  {
    bottomMargin: "xlarge",
    color: "secondary",
    height: "xlarge",
  },
  {
    bottomMargin: "large",
    color: "tertiary",
    columnDefinitions: [{ gridColumn: "1/3" }],
    numberOfColumns: 3,
  },
  {
    bottomMargin: "xlarge",
    color: "tertiary",
    columnDefinitions: [{ gridColumn: "1/2" }],
    height: "small",
    numberOfColumns: 3,
  },
  {
    height: "large",
  },
];

const TEMPLATES = {
  accordion: TEMPLATE_ACCORDION,
  bullets: TEMPLATE_BULLETS,
  matrix: TEMPLATE_MATRIX,
  simple: TEMPLATE_SIMPLE,
};

export const LoadingDepiction = (props) => {
  const { template } = props;

  return Array.isArray(template) ? renderCustom() : renderTemplate();

  function renderTemplate() {
    return TEMPLATES[template].map(renderCustomItem);
  }

  function renderCustom() {
    return template.map(renderCustomItem);
  }

  function renderCustomItem(itemDefinition, index) {
    const { ...itemProps } = itemDefinition;
    const {
      bottomMargin = "none",
      columnDefinitions,
      numberOfColumns,
      numberOfRows,
      shape,
      topMargin = "none",
    } = itemProps;
    const key = `${createHashCode(Object.keys(itemDefinition).join())}-${index}}`;

    delete itemProps.bottomMargin;
    delete itemProps.topMargin;

    return (
      <Layout bottom={bottomMargin} className={styles.fullWidth} key={key} top={topMargin}>
        {shape === "bullet-list" ? (
          <DepictionBulletItem {...itemProps} />
        ) : columnDefinitions?.length || numberOfColumns > 1 || numberOfRows > 1 ? (
          <DepictionRow {...itemProps} />
        ) : (
          <Loading {...itemProps} />
        )}
      </Layout>
    );
  }
};

LoadingDepiction.propTypes = {
  /**
   * Template can be a string matching one of the predefined templates, or it can be an array of
   * objects that defined each line of a custom template. See the predefined template definitions
   * for examples to create a custom template.
   */
  template: PropTypes.oneOfType([
    PropTypes.oneOf(Object.keys(TEMPLATES)),
    PropTypes.arrayOf(
      PropTypes.shape({
        ...loadingPropTypes,
        shape: PropTypes.oneOfType([loadingPropTypes.shape, PropTypes.oneOf(["bullet-list"])]),
      })
    ),
  ]),
};

LoadingDepiction.defaultProps = {
  template: "simple",
};
