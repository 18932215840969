import PropTypes from "prop-types";
import React, { createContext, useState } from "react";

import { useAppSetting, usePageId } from "@swa-ui/application";
import { isResponseOk, useFetch } from "@swa-ui/fetch";

const CHASE_OFFERS_ENDPOINT = "/api/chase/v2/chase/offers";

export const ChaseContext = createContext();
export const ChaseProvider = (props) => {
  const { children } = props;
  const { pageId } = usePageId();
  const { post, response } = useFetch();
  const [chaseDetails, setChaseDetails] = useState({});
  const mboxParameters = useAppSetting("mboxParameters", {});

  return <ChaseContext.Provider value={getContextValue()}>{children}</ChaseContext.Provider>;

  function getContextValue() {
    return { chaseDetails, getChaseContext };
  }

  async function getChaseContext() {
    let chaseContext = Promise.resolve({});

    if (shouldFetchChaseOffers()) {
      const chaseOffers = await fetchChaseOffers();
      const { highValueIndicator, offerIdentifier, offers } = chaseOffers ?? {};

      if (offers?.length) {
        const acquisitionSourceCodes = offers
          ?.map((offer) => offer?.acquisitionSourceCode)
          ?.join(",");

        chaseContext = {
          highValueIndicator,
          offerIdentifier,
          acquisitionSourceCodes,
        };
        setChaseDetails(chaseContext);
      }
    }

    return chaseContext;
  }

  function shouldFetchChaseOffers() {
    const { offerId, acquisitionSourceCodes, highValueIndicator } = mboxParameters;

    return offerId || acquisitionSourceCodes || highValueIndicator;
  }

  async function fetchChaseOffers() {
    let chaseOffersResponse = {};

    await post(CHASE_OFFERS_ENDPOINT, { pageId });

    if (isResponseOk(response)) {
      chaseOffersResponse = response.data;
    }

    return chaseOffersResponse;
  }
};

ChaseContext.displayName = "ChaseContext";
ChaseProvider.propTypes = {
  /** Content to be rendered on the page. */
  children: PropTypes.node.isRequired,
};
