import PropTypes from "prop-types";
import React from "react";

export const Stops = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path
        d="M38.2,25.5c-1.3,0-2.4-1.1-2.4-2.4c0-1.3,1.1-2.4,2.4-2.4c1.3,0,2.4,1.1,2.4,2.4
		C40.6,24.4,39.5,25.5,38.2,25.5 M35.7,18c-1.9,0.9-3.2,2.8-3.2,5.1c0,3.1,2.5,5.6,5.7,5.6c3.1,0,5.7-2.5,5.7-5.6
		c0-2.2-1.3-4.1-3.2-5.1c-0.8-0.4-1.6-0.6-2.5-0.6C37.3,17.4,36.5,17.6,35.7,18"
      />
      <path
        d="M35.7,8.1C32.7,3.3,27.4,0,21.2,0h0h0c-7.3,0-13.6,4.7-16,11.2L5,11.8c-0.7,2.1-1,4-1.1,6.2
		C1.7,18.7,0,20.8,0,23.2c0,3,2.5,5.5,5.5,5.5c3,0,5.5-2.4,5.5-5.5c0-2.1-1.2-4-3-4.9C7.6,18.2,7.3,18.1,7,18c0.1-2.1,0.5-4.2,1.1-6
		c2-5.3,7.1-9,13.1-9c4.9,0,9.3,2.6,11.8,6.4l-3.7,1.8l7.8,3.1l2.2-8.1L35.7,8.1z"
      />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "0 0 43.9 28.7",
    };
  }
};

Stops.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Stops.defaultProps = {
  role: "img",
};
