import PropTypes from "prop-types";
import React from "react";

import { useDeviceInfo } from "@swa-ui/browser";
import { Heading, Icon, LineClamp, Link } from "@swa-ui/core";
import { swaDate } from "@swa-ui/date";
import i18n from "@swa-ui/locale";

import { FLIGHT_CAR, FLIGHT_HOTEL, FLIGHT_HOTEL_CAR } from "../../defines/packageType";
import styles from "./VacationsRecentSearchItem.module.scss";

export const VacationsRecentSearchItem = (props) => {
  const {
    adults,
    children,
    departureDate,
    desktopImage,
    destinationAirportCode,
    mobileImage,
    originationAirportCode,
    packageType,
    returnDate,
    rooms,
    searchVacationRedirectPageUrl,
  } = props;
  const { screenSize } = useDeviceInfo();
  const image = screenSize === "small" ? mobileImage : desktopImage;
  const packageTypes = {
    [FLIGHT_CAR]: i18n("PackageOptions__FLIGHT_CAR"),
    [FLIGHT_HOTEL]: i18n("PackageOptions__FLIGHT_HOTEL"),
    [FLIGHT_HOTEL_CAR]: i18n("PackageOptions__FLIGHT_HOTEL_CAR"),
  };

  return (
    <Link {...getLinkProps()}>
      <img alt="" className={styles.recentSearchImage} src={image} />
      <div className={styles.contentContainer}>
        <ul className={styles.recentSearchContent}>
          <li>
            <LineClamp {...getLineClampProps()}>
              <Heading {...getHeadingProps()}>
                <div title={`${originationAirportCode} - ${destinationAirportCode}`}>
                  {`${originationAirportCode} - ${destinationAirportCode}`}
                </div>
              </Heading>
            </LineClamp>
          </li>
          <li>{packageTypes[packageType]}</li>
          <li>{getPassengersInfo()}</li>
          <li>{getTravelDatesInfo()}</li>
        </ul>
        <div className={styles.icon}>
          <Icon {...getIconProps()} />
        </div>
      </div>
    </Link>
  );

  function getLineClampProps() {
    return {
      lineCount: 1,
    };
  }

  function getHeadingProps() {
    return {
      className: styles.recentSearchContentHeader,
      headingLevel: 3,
    };
  }

  function getLinkProps() {
    return {
      className: styles.recentSearchItem,
      emphasis: false,
      href: searchVacationRedirectPageUrl,
      newWindow: true,
      showUnderline: false,
    };
  }

  function getIconProps() {
    return {
      actions: ["rotate90"],
      name: "ArrowThin",
    };
  }

  function getPassengersInfo() {
    const passengers = [];

    if (adults === 1) {
      passengers.push(`${adults} ${i18n("VacationsRecentSearch__ADULT")}`);
    } else if (adults > 1) {
      passengers.push(`${adults} ${i18n("VacationsRecentSearch__ADULTS")}`);
    }

    if (children === 1) {
      passengers.push(`${children} ${i18n("VacationsRecentSearch__CHILD")}`);
    } else if (children > 1) {
      passengers.push(`${children} ${i18n("VacationsRecentSearch__CHILDREN")}`);
    }

    if (packageType !== FLIGHT_CAR) {
      if (rooms === 1) {
        passengers.push(`${rooms} ${i18n("VacationsRecentSearch__ROOM")}`);
      } else if (rooms > 1) {
        passengers.push(`${rooms} ${i18n("VacationsRecentSearch__ROOMS")}`);
      }
    }

    return passengers.join(", ").trim();
  }

  function getTravelDatesInfo() {
    const dates = [];

    if (departureDate && returnDate) {
      dates.push(getFormattedDate(departureDate));
      dates.push(getFormattedDate(returnDate));
    }

    return dates.length === 2 ? dates.join(" - ") : null;
  }

  function getFormattedDate(input) {
    return swaDate(new Date(input)).format("ddd, MMM DD");
  }
};

VacationsRecentSearchItem.propTypes = {
  searchItem: PropTypes.shape({
    /** Total adults in the trip */
    adults: PropTypes.number,

    /** Total children in the trip */
    children: PropTypes.number,

    /** Start date of the trip */
    departureDate: PropTypes.string,

    /** Image URL path for the desktop image */
    desktopImage: PropTypes.string,

    /** Destination location */
    destinationAirportCode: PropTypes.string,

    /** Image URL path for the mobile image */
    mobileImage: PropTypes.string,

    /** Depart location */
    originationAirportCode: PropTypes.string,

    /** Return date of the trip */
    returnDate: PropTypes.string,

    /** Search location image desription */
    rooms: PropTypes.number,

    /** Redirect url to OJT. */
    searchVacationRedirectPageUrl: PropTypes.string,

    /** Vacation package type eg: Flight + Hotel */
    vacationPackageType: PropTypes.string,
  }),
};
