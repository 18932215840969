import PropTypes from "prop-types";
import React from "react";
import { Redirect, Route } from "react-router-dom";

import { ExternalRedirect } from "../ExternalRedirect";

/**
 * The ProtectedRoute component is a logical wrapper around the `react-router-dom` Route component,
 * enabling conditional redirecting in the application based on Boolean criteria from the `shouldRender` prop.
 */
export const ProtectedRoute = (props) => {
  const { children, redirectPath, redirectType, shouldRender, ...options } = props;

  const renderRedirect = () => {
    let redirectElement = null;

    if (redirectType === "internal") {
      redirectElement = <Redirect to={redirectPath} />;
    } else if (redirectType === "external") {
      redirectElement = <ExternalRedirect to={redirectPath} />;
    }

    return redirectElement;
  };

  return <Route {...options} render={() => (shouldRender ? children : renderRedirect())} />;
};

ProtectedRoute.propTypes = {
  /** The React Component to render when `shouldRender` is false. */
  children: PropTypes.node.isRequired,

  /** Object containing additional properties passed directly to the `Route` component from `react-router-dom`. */
  options: PropTypes.shape({
    exact: PropTypes.bool,
    path: PropTypes.string.isRequired,
  }),

  /** URL path to redirect to when `shouldRender` is true. */
  redirectPath: PropTypes.string.isRequired,

  /**
   * Can be either string of value `'internal'` or `'external'` to determine whether to use `react-router-dom`
   * `Redirect` or `ExternalRedirect` component when `shouldRender` is false.
   */
  redirectType: PropTypes.oneOf(["internal", "external"]),

  /** Set to true to render `children`, set to false to redirect to the path defined in `redirectPath`. */
  shouldRender: PropTypes.bool,
};

ProtectedRoute.defaultProps = {
  redirectType: "internal",
};
