import PropTypes from "prop-types";
import React from "react";

export const TimeLine = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path d="M27,12a2,2,0,0,1-2,2,1.7,1.7,0,0,1-.51-.07l-3.56,3.55A1.77,1.77,0,0,1,21,18a2,2,0,0,1-4,0,1.77,1.77,0,0,1,.07-.52l-2.55-2.55a2,2,0,0,1-1,0L8.93,19.49A1.7,1.7,0,0,1,9,20a2,2,0,1,1-2-2,1.7,1.7,0,0,1,.51.07l4.56-4.55A1.77,1.77,0,0,1,12,13a2,2,0,0,1,4,0,1.77,1.77,0,0,1-.07.52l2.55,2.55a2,2,0,0,1,1,0l3.55-3.56A1.7,1.7,0,0,1,23,12a2,2,0,0,1,4,0Zm0,0a2,2,0,0,1-2,2,1.7,1.7,0,0,1-.51-.07l-3.56,3.55A1.77,1.77,0,0,1,21,18a2,2,0,0,1-4,0,1.77,1.77,0,0,1,.07-.52l-2.55-2.55a2,2,0,0,1-1,0L8.93,19.49A1.7,1.7,0,0,1,9,20a2,2,0,1,1-2-2,1.7,1.7,0,0,1,.51.07l4.56-4.55A1.77,1.77,0,0,1,12,13a2,2,0,0,1,4,0,1.77,1.77,0,0,1-.07.52l2.55,2.55a2,2,0,0,1,1,0l3.55-3.56A1.7,1.7,0,0,1,23,12a2,2,0,0,1,4,0Z" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      style,
      viewBox: "0 0 32 32",
    };
  }
};

TimeLine.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

TimeLine.defaultProps = {
  role: "img",
};
