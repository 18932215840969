const DEFAULT_TRACKING = "0999999";

export const getResponseErrorTrackingCode = (response) => {
  const responseBody = response?.data;
  const getTrackingFromErrors = () => responseBody?.errors?.[0]?.code?.toString();
  const getTrackingFromFieldErrors = () =>
    responseBody?.notifications?.fieldErrors?.[0]?.trackingCode;
  const getTrackingFromNotifications = () =>
    responseBody?.notifications?.formErrors?.[0]?.trackingCode;

  return (
    getTrackingFromErrors() ??
    getTrackingFromNotifications() ??
    getTrackingFromFieldErrors() ??
    responseBody?.code?.toString() ??
    DEFAULT_TRACKING
  );
};
