import { useEffect, useState } from "react";

import { window } from "../window";

/**
 * @typedef {Object} useIntersectionObserverReturnType
 * @property {Boolean} isOnViewPort
 */

/**
 * Custom hook to observe changes in the intersection of a target element with an ancestor element or with a top-level document's viewport.
 *
 * @function useIntersectionObserver
 * @param {Object} elementRef - Target element ref.
 * @param {Function} [callback] - A callback to run when target is visible on viewport
 * @param {Object} [options] - Intersection observer options.
 * @param {Number} [options.freezeOnceVisible = false] - Disable observer once element is visible on viewport.
 * @param {Object} [options.root = null] - Element ref that is used as the viewport for checking visibility of the target. Must be the ancestor of the target. Defaults to the browser viewport.
 * @param {String} [options.rootMargin = "0%"] - Margin around the root.Defaults to all zeros.
 * @param {Number|Number[]} [options.threshold = 0] - Indicates at what percentage of the target's visibility the observer's callback should be executed. e.g. 0.1 or [0, 0.5, 1].
 * @returns {useIntersectionObserverReturnType}
 */

export const useIntersectionObserver = (elementRef, callback, options = {}) => {
  const { freezeOnceVisible = false, root = null, rootMargin = "0%", threshold = 0 } = options;
  const [entry, setEntry] = useState({});
  const isFrozen = entry?.isIntersecting && freezeOnceVisible;
  const updateEntry = ([entryDetails]) => {
    const { isIntersecting } = entryDetails ?? {};

    if (isIntersecting && callback) {
      callback();
    }
    setEntry(entryDetails);
  };

  useEffect(() => {
    const node = elementRef?.current;
    const rootNode = root?.current;
    const isObserverSupported = !!window.IntersectionObserver;
    let observer;

    if (isObserverSupported && node && !isFrozen) {
      observer = new window.IntersectionObserver(updateEntry, {
        root: rootNode,
        rootMargin,
        threshold,
      });
      observer.observe(node);
    }

    return () => observer?.disconnect();
  }, [elementRef, isFrozen, root, rootMargin, threshold]);

  return { isOnViewPort: !!entry?.isIntersecting };
};
