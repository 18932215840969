import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import { window } from "@swa-ui/browser";

import { Transform, transformPropTypes } from "../Transform";

const DELAY_BEFORE_NEXT_STEP = 1;

/**
 * PlayList sequentially plays a list of transformations by stepping through them one at a time. Each transformation is
 * typically animated and are controlled using a "play list" which defines each step.
 *
 * PlayList is useful when the steps can be defined upfront and executed sequentially without changing the child
 * content. For animation sequences that are dynamic or the content needs to change, see ControlledPlayList.
 */

export const PlayList = React.memo((props) => {
  const { children, className, onPlayListComplete, playList, repetitions } = props;
  const [currentPlayList, setCurrentPlayList] = useState([]);
  const [currentPlayListIndex, setCurrentPlayListIndex] = useState(0);
  const repetitionCount = useRef(0);

  useEffect(() => {
    setCurrentPlayList(playList);
    setCurrentPlayListIndex(0);
  }, [JSON.stringify(playList)]);

  return <Transform {...getProps()}>{children}</Transform>;

  function getProps() {
    let step = currentPlayList?.[currentPlayListIndex];

    if (!step && playList?.length) {
      step = {
        duration: playList[0].duration,
        transformations: playList[0].transformations,
        transformOrigin: playList[0].transformOrigin,
      };
    }

    return step
      ? {
          className,
          delay: step.delay,
          duration: step.duration,
          onTransformationEnd: currentPlayList?.[currentPlayListIndex]
            ? handleTransformationEnd
            : undefined,
          precision: 0.1,
          transformations: step.transformations,
          transformOrigin: step.transformOrigin,
        }
      : {};
  }

  function handleTransformationEnd() {
    if (currentPlayListIndex < currentPlayList.length - 1) {
      window.setTimeout(
        () => setCurrentPlayListIndex(currentPlayListIndex + 1),
        DELAY_BEFORE_NEXT_STEP
      );
    } else {
      if (repetitionCount.current < repetitions - 1) {
        repetitionCount.current += 1;
        window.setTimeout(() => setCurrentPlayListIndex(0), DELAY_BEFORE_NEXT_STEP);
      }

      onPlayListComplete && onPlayListComplete();
    }
  }
}, skipRender);

function skipRender(prevProps, nextProps) {
  return (
    nextProps.optimizeRerenders &&
    JSON.stringify(prevProps.playList) === JSON.stringify(nextProps.playList)
  );
}

export const playListPropTypes = {
  /** Content to be rendered. This is the content that the playlist will transform. */
  children: PropTypes.node.isRequired,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Unique identifier to force the playList to re-render when the value changes. */
  id: PropTypes.string,

  // TODO rename this to onPlayListEnd
  /**
   * Callback to inform when the entire playlist is played. If the playlist is to be repeated three times, then it
   * will only be called once.
   */
  onPlayListComplete: PropTypes.func,

  /**
   * Callback to inform after each playlist step is played. If the playlist is to be repeated three times, and the
   * playlist has three steps, the callback will only be made nine times. The step index is returned as the only
   * parameter.
   */
  onStepEnd: PropTypes.func,

  /** If this prop is true, then React.memo will only allow a re-render when the playList has changed. */
  optimizeRerenders: PropTypes.bool,

  /**
   * Transformations to serially play. This is an array of arrays. That is, Transform accepts an array of actions
   * because it can perform multiple, simultaneous transformations. This array are sets of those arrays which will be
   * played sequentially.
   */
  playList: PropTypes.arrayOf(
    PropTypes.shape({
      delay: PropTypes.number,
      duration: PropTypes.number,
      transformations: transformPropTypes.transformations,
    })
  ),
};

PlayList.displayName = "PlayList";

PlayList.propTypes = playListPropTypes;

PlayList.defaultProps = {
  optimizeRerenders: false,
};
