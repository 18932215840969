import React from "react";

import { getBootstrapData } from "@swa-ui/bootstrap";
import { Link } from "@swa-ui/core";
import i18n from "@swa-ui/locale";

/**
 * AccountHeldError renders a customized error message with a link.
 */
export const AccountHeldError = () => {
  const { ACCOUNT_HELP } = getBootstrapData("urls");

  return (
    <span>
      {i18n("AccountHeldError__ERROR")} {renderLink()}
    </span>
  );

  function renderLink() {
    return (
      <Link size="fontSize13" href={ACCOUNT_HELP}>
        {i18n("AccountHeldError__LINK", { link: ACCOUNT_HELP })}
      </Link>
    );
  }
};
