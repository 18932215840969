import PropTypes from "prop-types";
import React, { useState } from "react";

import { useAppSetting } from "@swa-ui/application";
import { Button, Card, InformationTip, LineClamp, Rating } from "@swa-ui/core";
import { swaDate } from "@swa-ui/date";
import i18n from "@swa-ui/locale";
import { CallToAction, PlacementFactory } from "@swa-ui/placements";
import { classNames } from "@swa-ui/string";

import { formatPrice } from "../../utils/formatPrice";
import styles from "./PackageCard.module.scss";

// TODO - Ensure error and empty value handling is done instead of optional chaining to
//  extract data. ECOM-58825
export const PackageCard = (props) => {
  const { callToAction, navigationLink, packageData } = props;
  const { flightOptions, hotelOption, packageSavings, prices } = packageData;
  const earnQuantity = prices?.[0]?.earn?.total?.earnQuantity;
  const separateBookingAmount = prices[0]?.metadata?.label?.find(
    (item) => item.code === "PACKAGE_IBS_PER_PAX"
  )?.cash?.amount;
  const totalSalesAmountPerGuest = prices[0]?.metadata?.label?.find(
    (item) => item.code === "PACKAGE_COST_PER_PAX"
  )?.cash?.amount;
  const hotelOptionImageUrl = hotelOption?.images?.[0]?.url;
  const { name, address, starRating, duration } = hotelOption;
  const { iataCode, dateTime, city } = flightOptions?.[0]?.departure || {};
  const basePathOJTWebapp = useAppSetting("basePathOJTWebapp");
  const [imageLoadError, setImageLoadError] = useState(false);

  return (
    <Card className={styles.packageCard} fullWidthContent fullHeightContent>
      {renderImage()}
      <div className={styles.layout}>
        {renderLocation()}
        {renderRatings()}
        {renderHotelName()}
        {renderFrom()}
        {renderDurationAndPrices()}
        {renderPoints()}
        {renderDepartDate()}
        {renderCallToAction()}
      </div>
    </Card>
  );

  function renderImage() {
    return <img {...getImageProps()} />;
  }

  function renderLocation() {
    let location;

    if (address.country?.code === "US") {
      location = `${address.city}, ${address.state}`;
    } else {
      location = `${address.city}, ${address.country?.name}`;
    }

    return <LineClamp {...getLineClampProps()}>{location}</LineClamp>;

    function getLineClampProps() {
      return {
        className: styles.location,
        lineCount: 1,
        textContent: location,
      };
    }
  }

  function renderHotelName() {
    return <div className={styles.hotel}>{name}</div>;
  }

  function renderRatings() {
    return (
      <div className={styles.rating}>
        <Rating color={"secondary-vacations-400"} rating={starRating} />
      </div>
    );
  }

  function renderFrom() {
    return (
      <div className={styles.from}>{`${i18n("PackageCard__FROM")} ${city} (${iataCode})`}</div>
    );
  }

  function renderDurationAndPrices() {
    return (
      <>
        <div className={styles.duration}>{i18n("PackageCard__DURATION", duration)}</div>
        <div className={styles.originalPrice}>
          {packageSavings && separateBookingAmount && (
            <>
              <span className={styles.originalPriceContent}>
                {formatPrice(separateBookingAmount)}
              </span>
              {renderInformationTipForPriceInfo()}
            </>
          )}
        </div>
        <div className={styles.savings}>
          <span className={styles.savingsAmount}>{formatPrice(totalSalesAmountPerGuest)}</span>
          <span className={styles.savingsText}>{i18n("PackageCard__PER_GUEST")}</span>
        </div>
      </>
    );
  }

  function renderPoints() {
    return <div className={styles.points}>{i18n("PackageCard__EARN_POINTS", earnQuantity)}</div>;
  }

  function renderDepartDate() {
    return (
      <div className={styles.depart}>
        {`${i18n("PackageCard__DEPART")} ${swaDate(dateTime).format(
          i18n("PackageCard__DEPART_DATE_FORMAT")
        )}`}
      </div>
    );
  }

  function renderCallToAction() {
    return <CallToAction {...getCallToActionProps()} />;
  }

  function getImageProps() {
    return {
      alt: "",
      className: classNames(styles.image, { [styles.imageError]: imageLoadError }),
      onError: (err) => setImageLoadError(!!err),
      src: hotelOptionImageUrl,
    };
  }

  function getCallToActionProps() {
    return {
      ...callToAction,
      className: styles.navButton,
      href: `${basePathOJTWebapp}${navigationLink}`,
    };
  }
};

function renderInformationTipForPriceInfo() {
  return <InformationTip {...getInformationTipProps()} />;
}

function getInformationTipProps() {
  return {
    className: styles.informationTipOriginalPrice,
    content: <PlacementFactory id="packageToolTip" />,
    location: "below",
    size: "small",
    width: "xsmall",
  };
}

PackageCard.propTypes = {
  /** WCM controlled CTA details. */
  callToAction: PropTypes.object,

  /** Deep link for the OpenJaw hosted page for the package. */
  navigationLink: PropTypes.string,

  /** Object containing vacation package details. */
  packageData: PropTypes.shape({
    hotelOption: PropTypes.shape({
      address: PropTypes.shape({
        city: PropTypes.string,
        country: PropTypes.shape({
          code: PropTypes.string,
          name: PropTypes.string,
        }),
        state: PropTypes.string,
      }),
      duration: PropTypes.number,
      location: PropTypes.shape({
        name: PropTypes.string,
      }),
      name: PropTypes.string,
      starRating: PropTypes.number,
      images: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string,
        })
      ),
    }),
    flightOptions: PropTypes.arrayOf(
      PropTypes.shape({
        departure: PropTypes.shape({
          city: PropTypes.string,
          dateTime: PropTypes.string,
          iataCode: PropTypes.string,
          name: PropTypes.string,
        }),
      })
    ),
    flightOptionResults: PropTypes.number,
    packageSavings: PropTypes.shape({
      combineAndSaveAmount: PropTypes.shape({
        cash: PropTypes.shape({
          amount: PropTypes.number,
        }),
      }),
      separateBookingAmount: PropTypes.shape({
        cash: PropTypes.shape({
          amount: PropTypes.number,
        }),
      }),
    }),
    prices: PropTypes.arrayOf(
      PropTypes.shape({
        earn: PropTypes.shape({
          total: PropTypes.shape({
            earnQuantity: PropTypes.number,
          }),
        }),
      })
    ),
    roomOptionResults: PropTypes.number,
  }),
};
