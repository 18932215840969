import PropTypes from "prop-types";
import React, { useState } from "react";

import { classNames } from "@swa-ui/string";

import { PlayList } from "../PlayList";
import styles from "./SlideIntoView.module.scss";

/**
 * SlideIntoView provides a way to move element into view and out of view.
 */

export const SlideIntoView = (props) => {
  const {
    children,
    className,
    delay,
    hideWhenOffScreen,
    innerClassName,
    onTransformationEnd,
    slideDirection,
  } = props;
  const [hidden, setHidden] = useState(isHidden());

  return (
    <div className={getClass()}>
      <PlayList {...getPlayListProps()}>{children}</PlayList>
    </div>
  );

  function getPlayListProps() {
    return {
      className: innerClassName,
      id: slideDirection,
      onPlayListComplete: handleTransformationEnd,
      playList: getPlayList(),
    };
  }

  function getClass() {
    return classNames(className, {
      [styles.hidden]: isHidden() && hidden && hideWhenOffScreen,
    });
  }

  function handleTransformationEnd() {
    setHidden(isHidden());
    onTransformationEnd && onTransformationEnd();
  }

  function getPlayList() {
    const playLists = {
      default: [
        {
          delay,
          transformations: [getTranslateX("0%"), getTranslateY("0%"), getOpacity(1)],
        },
      ],
      hidden: [
        {
          delay,
          duration: 1,
          transformations: [getTranslateX("0%"), getTranslateY("0%"), getOpacity(0)],
        },
      ],
      hideDown: [
        {
          delay,
          transformations: [getTranslateX("0%"), getTranslateY("75%"), getOpacity(0)],
        },
      ],
      hideLeft: [
        {
          delay,
          transformations: [getTranslateX("-75%"), getTranslateY("0%"), getOpacity(0)],
        },
      ],
      hideRight: [
        {
          delay,
          transformations: [getTranslateX("75%"), getTranslateY("0%"), getOpacity(0)],
        },
      ],
      hideUp: [
        {
          delay,
          transformations: [getTranslateX("0%"), getTranslateY("-75%"), getOpacity(0)],
        },
      ],
      slideDown: [
        {
          duration: 1,
          transformations: [getTranslateX("0%"), getTranslateY("-75%"), getOpacity(0)],
        },
        {
          delay,
          transformations: [getTranslateX("0%"), getTranslateY("0%"), getOpacity(1)],
        },
      ],
      slideLeft: [
        {
          duration: 1,
          transformations: [getTranslateX("75%"), getTranslateY("0%"), getOpacity(0)],
        },
        {
          delay,
          transformations: [getTranslateX("0%"), getTranslateY("0%"), getOpacity(1)],
        },
      ],
      slideRight: [
        {
          duration: 1,
          transformations: [getTranslateX("-75%"), getTranslateY("0%"), getOpacity(0)],
        },
        {
          delay,
          transformations: [getTranslateX("0%"), getTranslateY("0%"), getOpacity(1)],
        },
      ],
      slideUp: [
        {
          duration: 1,
          transformations: [getTranslateX("0%"), getTranslateY("75%"), getOpacity(0)],
        },
        {
          delay,
          transformations: [getTranslateX("0%"), getTranslateY("0%"), getOpacity(1)],
        },
      ],
    };

    return playLists[slideDirection];
  }

  function isHidden() {
    return (
      slideDirection === "hideDown" ||
      slideDirection === "hideLeft" ||
      slideDirection === "hideRight" ||
      slideDirection === "hideUp" ||
      slideDirection === "hidden"
    );
  }

  function getTranslateX(amount) {
    return {
      action: "translateX",
      amount: amount,
    };
  }

  function getTranslateY(amount) {
    return {
      action: "translateY",
      amount: amount,
    };
  }

  function getOpacity(amount) {
    return {
      action: "opacity",
      amount: `${amount}`,
    };
  }
};

SlideIntoView.propTypes = {
  /** Content for "main" element. */
  children: PropTypes.node.isRequired,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Milliseconds before slide animation begins. */
  delay: PropTypes.number,

  /**
   * Typically when an element is moved off-screen, the desire is to hide it. But at times, perhaps when something
   * is slid into the element's place, the element's contents shouldn't collapse and shouldn't be hidden between
   * states.
   */
  hideWhenOffScreen: PropTypes.bool,

  /** Classname to be applied to PlayList's div element. */
  innerClassName: PropTypes.string,

  /** Function called once slide animation is complete. */
  onTransformationEnd: PropTypes.func,

  /**
   * Where content will be placed. Typically content will be rendered as hidden, then slide into view. For example,
   * content should be hidden initially, then "slideRight" given, then to remove from page, specify "hideLeft".
   */
  slideDirection: PropTypes.oneOf([
    "default",
    "hidden",
    "hideDown",
    "hideLeft",
    "hideRight",
    "hideUp",
    "slideDown",
    "slideLeft",
    "slideRight",
    "slideUp",
  ]),
};

SlideIntoView.defaultProps = {
  hideWhenOffScreen: true,
  slideDirection: "default",
};
