import PropTypes from "prop-types";
import React, { useContext, useRef, useState } from "react";

import { AppSettingsContext } from "@swa-ui/application";
import { window } from "@swa-ui/browser";
import { BackgroundVeil, Button, Caption, Grid, Icon, Link } from "@swa-ui/core";
import globalRules from "@swa-ui/core/assets/styles/globalRules.module.scss";
import { keyCodes } from "@swa-ui/core/defines";
import i18n from "@swa-ui/locale";
import { HtmlValue, PlacementFactory } from "@swa-ui/placements";
import { usePlacement } from "@swa-ui/placements";
import { classNames } from "@swa-ui/string";

import { SearchAutocomplete } from "../../SearchAutocomplete";
import styles from "./DotcomHeaderDesktop.module.scss";
import { Overlay } from "./Overlay";

/**
 * DotcomHeaderControl provides the header for larger devices / screens.
 */

const CARET_CENTER = 6;
const DELAY_FOR_BROWSER = 100;
const MENU_SELECTION_NONE = -1;
const SEARCH_ACTION = "GLOBALNAVSEARCH";

export const DotcomHeaderDesktop = (props) => {
  const { control, light, menuItemSeparator, onSearch, uppercaseMenuItems } = props;
  const { appSettings } = useContext(AppSettingsContext);
  const { placement: globalNavHeader } = usePlacement("globalNavHeader");
  const [selection, setSelection] = useState({
    currentSelection: MENU_SELECTION_NONE,
    nextSelection: MENU_SELECTION_NONE,
  });
  const menuNavRef = useRef();
  const menuItemRefs = useRef([]);

  return globalNavHeader ? (
    <Grid className={classNames(styles.dotcomHeaderDesktop, styles.layout)}>
      <div className={styles.container}>
        {renderControl()}
        <div className={styles.menubar}>
          {renderLogo()}
          {renderMenu()}
          {renderAriaInfoNewWindow()}
        </div>
      </div>
    </Grid>
  ) : null;

  function renderLogo() {
    return (
      <Button {...getLogoButtonProps()}>
        <img {...getLogoImageProps()} />
      </Button>
    );
  }

  function renderControl() {
    return control;
  }

  function renderMenu() {
    return (
      <>
        <BackgroundVeil {...getVeilProps()} />
        <Caption {...getCaptionProps()}>
          <div className={styles.menu} ref={menuNavRef} role="navigation">
            {globalNavHeader?.globalNavPrimaryLinks &&
              globalNavHeader?.globalNavPrimaryLinks.map((menuItem, index) => (
                <span
                  key={menuItem.value ?? index}
                  ref={(el) => (menuItemRefs.current[index] = el)}
                >
                  {renderMainMenuItem(menuItem, index)}
                </span>
              ))}
          </div>
        </Caption>
      </>
    );
  }

  function renderMainMenuItem(menuItem, menuItemIndex) {
    let mainMenuItem;

    if (menuItem?.action === SEARCH_ACTION) {
      mainMenuItem = renderSearch(menuItem, menuItemIndex);
    } else {
      mainMenuItem = (
        <>
          {menuItemSeparator && menuItemIndex !== 0 && (
            <span className={styles.menuItemSeparator}>{menuItemSeparator}</span>
          )}
          <Link {...getMainMenuItemProps(menuItem, menuItemIndex)}>
            <HtmlValue htmlValue={menuItem.value} />
          </Link>
        </>
      );
    }

    return mainMenuItem;
  }

  function renderSearch(menuItem, menuItemIndex) {
    return appSettings.searchAvailable ? (
      <Link {...getMainMenuItemProps(menuItem, menuItemIndex, true)}>
        <Icon {...getSearchIconProps()} />
      </Link>
    ) : null;
  }

  function renderOverlay() {
    const { currentSelection } = selection;
    const overlayContent = globalNavHeader?.globalNavPrimaryLinks?.[currentSelection];
    let overlay;

    if (overlayContent?.action === SEARCH_ACTION) {
      overlay = <SearchAutocomplete className={styles.search} onSearch={handleSearch} />;
    } else if (overlayContent?.flyout?.type === "placement") {
      overlay = <PlacementFactory id={overlayContent.flyout.id} shouldWait={false} />;
    } else {
      overlay = <Overlay productIndex={currentSelection} />;
    }

    return overlay;
  }

  function renderAriaInfoNewWindow() {
    return (
      <div className={globalRules.hiddenFromScreen} id="a11y-message-header--new-window">
        {i18n("DotcomHeaderDesktop__NEW_WINDOW_ARIA")}
      </div>
    );
  }

  function getLogoButtonProps() {
    const { altText, href, url } = globalNavHeader?.globalNavLogo ?? {};

    return {
      "aria-label": altText,
      className: getLogoClass(),
      href: href ?? url ?? "/",
      styleType: "no-style",
    };
  }

  function getLogoImageProps() {
    const { altText: alt, height, src: logoSrc, width } = globalNavHeader?.globalNavLogo ?? {};
    const { currentSelection } = selection;

    const src =
      light || currentSelection !== MENU_SELECTION_NONE ? getLightLogoSrc(logoSrc) : logoSrc;

    return {
      alt,
      height,
      role: "img",
      src,
      width,
    };
  }

  function getLightLogoSrc(logoType) {
    return logoType.replace("dark", "light");
  }

  function getVeilProps() {
    const { currentSelection } = selection;

    return {
      onClick: handleVeilClick,
      onKeyDown: handleVeilKeyDown,
      on: currentSelection !== MENU_SELECTION_NONE,
    };
  }

  function getCaptionProps() {
    const { currentSelection, nextSelection } = selection;
    const location =
      currentSelection === MENU_SELECTION_NONE || currentSelection !== nextSelection
        ? "hidden"
        : "below";

    return {
      adjoiningContent: currentSelection !== MENU_SELECTION_NONE && renderOverlay(),
      alignment: "right",
      bestFit: false,
      className: getCaptionClass(),
      id: `header${currentSelection}-${nextSelection}`,
      location,
      mainClassName: styles.mainContent,
      onTransformationEnd: handleShowComplete,
      pointerAlignment: "right",
      pointerXOffset: getPointerXOffset(),
      role: "navigation",
    };
  }

  function getMainMenuItemProps(menuItem, menuIndex, iconOnlyItem) {
    const { currentSelection } = selection;
    const { href, url } = menuItem;

    return {
      className: classNames({
        [styles.iconOnlyMenuItem]: iconOnlyItem,
        [styles.menuItem]: !iconOnlyItem,
        [styles.menuItemClosed]: !iconOnlyItem && currentSelection === MENU_SELECTION_NONE,
        [styles.menuItemUppercase]: uppercaseMenuItems,
      }),
      emphasis: true,
      href: href ?? url,
      light: light || currentSelection !== MENU_SELECTION_NONE,
      onClick: !href && !url ? handleMenuItemClick.bind(this, menuItem, menuIndex) : undefined,
      paddingForFocus: true,
      size: "fontSize13",
    };
  }

  function getSearchIconProps() {
    const { currentSelection } = selection;

    return {
      color:
        currentSelection === MENU_SELECTION_NONE
          ? "cmp-dotcom-color-header-search"
          : "primary-header-search-open",
      name: "Search",
      size: "size22",
    };
  }

  function getPointerXOffset() {
    const { currentSelection } = selection;
    let xOffset = 0;

    if (menuItemRefs.current[currentSelection]) {
      const navPosition = menuNavRef.current.getBoundingClientRect();
      const menuOptionPosition = menuItemRefs.current[currentSelection].getBoundingClientRect();
      const xPosition = navPosition.right - menuOptionPosition.x;
      const centerPosition = menuOptionPosition.width / 2;

      xOffset = -xPosition + centerPosition + CARET_CENTER + CARET_CENTER;
    }

    return xOffset;
  }

  function getLogoClass() {
    const { currentSelection } = selection;

    return classNames({
      [styles.logo]: true,
      [styles.priorityStacking]: currentSelection !== MENU_SELECTION_NONE,
    });
  }

  function getCaptionClass() {
    const { currentSelection } = selection;

    return classNames({
      [styles.caption]: true,
      [styles.open]: currentSelection !== MENU_SELECTION_NONE,
    });
  }

  function handleVeilClick() {
    setSelection({
      currentSelection: selection.currentSelection,
      nextSelection: MENU_SELECTION_NONE,
    });
  }

  function handleVeilKeyDown(event) {
    if (event.key === keyCodes.KEY_ESCAPE) {
      setSelection({
        currentSelection: selection.currentSelection,
        nextSelection: MENU_SELECTION_NONE,
      });
    }
  }

  function handleMenuItemClick(menuItem, menuItemIndex) {
    const { currentSelection } = selection;
    let newNextSelection = menuItemIndex;

    if (currentSelection !== MENU_SELECTION_NONE) {
      newNextSelection =
        currentSelection === newNextSelection ? MENU_SELECTION_NONE : menuItemIndex;
    }

    setSelection({
      currentSelection: currentSelection,
      nextSelection: newNextSelection,
    });
  }

  function handleShowComplete() {
    const { currentSelection, nextSelection } = selection;

    if (currentSelection !== nextSelection) {
      window.setTimeout(() => {
        setSelection({
          currentSelection: nextSelection,
          nextSelection: nextSelection,
        });
      }, DELAY_FOR_BROWSER);
    }
  }

  function handleSearch(searchCriteria) {
    setSelection({
      currentSelection: selection.currentSelection,
      nextSelection: MENU_SELECTION_NONE,
    });
    onSearch && onSearch(searchCriteria);
  }
};

DotcomHeaderDesktop.propTypes = {
  /** Header control used for items like  account and locale for the header. */
  control: PropTypes.node,

  /** Indicates whether the app is using a dark Header background, to be passed to elements for styling. */
  light: PropTypes.bool,

  /** Adds the value of the prop as a separator between the menu links. */
  menuItemSeparator: PropTypes.string,

  /** Calllback that will be used when traveler performs a search. */
  onSearch: PropTypes.func,

  /** Transform menu item links into uppercase. Default: true */
  uppercaseMenuItems: PropTypes.bool,
};

DotcomHeaderDesktop.defaultProps = {
  uppercaseMenuItems: true,
};
