import PropTypes from "prop-types";
import React from "react";

export const Beach = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path d="M10.4688 14.25L10 13.625H10.4406L14.6003 9.47187C15.0094 9.0625 15.0094 8.49375 14.6003 8.08469C14.2031 7.6875 13.6188 7.6875 13.2156 8.09063L11.4313 9.875H9.19375L10.2125 5.8L14.5656 7.00344L14.6503 6.70344C15.0066 5.41594 14.8691 4.11594 14.2663 3.04406C13.7475 2.12219 12.91 1.41594 11.8725 1.00625C11.8719 0.99375 11.875 0.98125 11.875 0.96875C11.875 0.709375 11.6656 0.5 11.4062 0.5C11.2281 0.5 11.0781 0.6 10.9966 0.74375C10.6125 0.659062 10.2188 0.61875 9.82156 0.61875C7.50906 0.61875 5.57781 1.99094 5.01219 4.0375L4.9275 4.3375L9.61187 5.63438L8.54969 9.875H7.38437L5.19687 11.75H0.9375C0.41875 11.75 0 12.1687 0 12.6875C0 13.1062 0.278125 13.4594 0.659375 13.5784L0.15625 14.25H0V14.875H15V14.25H10.4688ZM12.4909 2.6625C12.4625 2.38156 12.4128 2.14375 12.35 1.93781C12.9284 2.29406 13.3972 2.77531 13.7222 3.35031C14.1972 4.19406 14.3347 5.20656 14.1219 6.23156L12.1656 5.69125C12.4594 4.52844 12.575 3.48781 12.4909 2.6625ZM5.70938 3.90625C6.28438 2.33437 7.84375 1.29063 9.70312 1.24688C8.88125 1.85625 8.11875 3.0125 7.66531 4.44688L5.70938 3.90625ZM8.26875 4.6125C8.94375 2.49687 10.3187 1.19063 11.1659 1.425C11.6534 1.55969 11.8191 2.20625 11.8691 2.725C11.9441 3.4625 11.8347 4.45031 11.5628 5.525L8.26875 4.6125ZM9.0375 10.5H10.8063L9.55625 11.75H8.725L9.0375 10.5ZM7.61563 10.5H8.39344L8.08094 11.75H6.15625L7.61563 10.5ZM0.625 12.6875C0.625 12.5156 0.765625 12.375 0.9375 12.375H9.81563L13.6594 8.53437C13.7159 8.47531 13.8063 8.40625 13.9125 8.40625C14.0156 8.40625 14.1031 8.47188 14.1597 8.52844C14.325 8.69375 14.325 8.8625 14.1597 9.02844L10.1813 13H0.9375C0.765625 13 0.625 12.8594 0.625 12.6875ZM7.45594 14.25H0.9375L1.40625 13.625H7.61219L7.45594 14.25ZM8.1 14.25L8.25625 13.625H9.21875L9.6875 14.25H8.1Z" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "0 0 16 16",
    };
  }
};

Beach.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Beach.defaultProps = { role: "img" };
