import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";

import { getResponseErrorKey, isResponseOk } from "@swa-ui/fetch";
import i18n, { updateI18n } from "@swa-ui/locale";

import { AuthContext } from "../../AuthProvider";
import {
  isValidLogin,
  LOGIN_RR_MISMATCH_ERROR_CODE,
  logInvalidLoginError,
} from "../../loginValidator";
import { useUserInfo } from "../../UserInfoProvider";
import { BaseAuthenticationForm } from "../BaseAuthenticationForm";
import { AccountHeldError } from "./AccountHeldError";

/**
 * Provides a function for handling authentication for a given user interaction
 */

export const LoginForm = (props) => {
  const { formType, onCancel, onLoggedIn } = props;
  const { handleLogin, handleLogout } = useContext(AuthContext);
  const { currentAccountNumber, currentCompanyId } = useUserInfo();
  const [errorMessage, setErrorMessage] = useState();
  const [loginFormType, setLoginFormType] = useState(formType);

  useEffect(() => {
    updateI18n({ LoginForm__ERROR_ACCOUNT_HELD: AccountHeldError });
  }, []);

  useEffect(() => {
    formType === "reAuthentication" && !currentAccountNumber
      ? setLoginFormType("login")
      : setLoginFormType(formType);
  }, [formType, currentAccountNumber]);

  return <BaseAuthenticationForm {...getProps()} />;

  function getProps() {
    const commonProps = {
      errorMessage,
      heading: i18n(
        loginFormType === "reAuthentication"
          ? "LoginForm__TITLE_LOG_IN_TO_CONTINUE"
          : "LoginForm__TITLE"
      ),
      onSubmit: handleSubmit,
    };

    const formProps = {
      login: {
        canEnroll: true,
        canSaveCredentials: true,
      },
      reAuthentication: {
        additionalButtonProps: {
          children: i18n("LoginForm__BUTTON__CANCEL"),
          onClick: onCancel,
          styleType: "secondary",
        },
        shouldPreventUserNameOrCompanyIdChange: true,
      },
    }[loginFormType];

    return {
      ...commonProps,
      ...formProps,
    };
  }

  async function handleSubmit(formData) {
    setErrorMessage(null);
    const companyId = formData?.companyId ? formData.companyId : currentCompanyId;
    const username = formData?.username ? formData.username : currentAccountNumber;
    const { body, responseLogin: response } = await handleLogin(formData, username, companyId);

    if (!isResponseOk(response)) {
      setErrorMessage(getResponseErrorKey(response, "LoginForm__ERROR_DEFAULT"));
    } else if (!isValidLogin(username, body, response?.headers)) {
      // Closes a potential security gap to ensure that the account being logged in with the request is in fact the account
      // being returned in the response. It mitigates potential attack vectors such as a MITM (man in the middle) or possible
      // issues in the downstream systems.
      logInvalidLoginError(username, body, response?.headers);
      setErrorMessage(
        getResponseErrorKey({ code: LOGIN_RR_MISMATCH_ERROR_CODE }, "LoginForm__ERROR_DEFAULT")
      );
      handleLogout();
    } else {
      onLoggedIn?.();
    }
  }
};

LoginForm.propTypes = {
  /**
   * Which fields to display to the user. Default is login.
   * `'login'` allows creating account link and forgot password functionality.
   * `'reAuthentication'` prevents user to change username and allows cancel of the opperation
   */
  formType: PropTypes.oneOf(["login", "reAuthentication"]),

  /**
   * Triggered when cancel button is clicked.
   */
  onCancel: PropTypes.func,

  /**
   * Triggered when authentication has successfully completed.
   */
  onLoggedIn: PropTypes.func,
};

LoginForm.defaultProps = {
  formType: "login",
};
