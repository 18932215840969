import React from "react";

import { useDeviceInfo } from "@swa-ui/browser";
import { Grid } from "@swa-ui/core";
import {
  CalendarFormField,
  Form,
  RadioButtonFormField,
  SubmitFormField,
  TextFormField,
} from "@swa-ui/form";
import {
  AutocompleteStationsFormField,
  AutocompleteVacationDestinationsFormField,
} from "@swa-ui/itinerary";
import i18n from "@swa-ui/locale";

import { FLIGHT_CAR, FLIGHT_HOTEL, FLIGHT_HOTEL_CAR } from "../defines/packageType";
import { useVacationsFormLogic } from "../useVacationsFormLogic";
import { TravelerSelector } from "./TravelerSelector";
import styles from "./VacationsBookingFormSlim.module.scss";

export const VacationsBookingFormSlim = () => {
  const { isSmallOrMedium } = useDeviceInfo();
  const { defaultSubmitButtonText, formProps } = useVacationsFormLogic();
  const {
    departureDateProps,
    destinationAirportCodeProps,
    formComponentProps,
    originationAirportCodeProps,
    packageOptionsProps,
    promoCodeProps,
    returnDateProps,
    submitButtonProps,
    travelerSelectorProps,
  } = formProps;

  return (
    <Grid className={styles.layout}>
      <Form {...formComponentProps}>
        <RadioButtonFormField {...getPackageRadioOptionProps()} />
        <AutocompleteStationsFormField {...getOriginationFormFieldProps()} />
        <AutocompleteVacationDestinationsFormField {...getDestinationFormFieldProps()} />
        <CalendarFormField className={styles.departureDate} {...departureDateProps} />
        <CalendarFormField className={styles.returnDate} {...returnDateProps} />
        <TravelerSelector className={styles.travelers} {...travelerSelectorProps} dropdown="true" />
        <TextFormField className={styles.promo} {...getEnterPromoFormFieldProps()} />
        <div className={styles.submit}>
          <SubmitFormField className={styles.submitButton} {...submitButtonProps}>
            {defaultSubmitButtonText}
          </SubmitFormField>
        </div>
      </Form>
    </Grid>
  );

  function getPackageRadioOptionProps() {
    const { name, onChange, value } = packageOptionsProps;

    return {
      className: styles.package,
      componentProps: {
        defaultValue: value,
        direction: isSmallOrMedium ? "column" : "row",
        onChange,
        radioButtonInfoList: [
          {
            label: i18n("VacationsBookingFormSlim__FLIGHT_HOTEL"),
            value: FLIGHT_HOTEL,
          },
          {
            label: i18n("VacationsBookingFormSlim__FLIGHT_HOTEL_CAR"),
            value: FLIGHT_HOTEL_CAR,
          },
          {
            label: i18n("VacationsBookingFormSlim__FLIGHT_CAR"),
            value: FLIGHT_CAR,
          },
        ],
      },
      hideBottomSection: true,
      hideTopSection: true,
      name,
    };
  }

  function getDestinationFormFieldProps() {
    return {
      ...destinationAirportCodeProps,
      className: styles.destinationAirportCode,
    };
  }

  function getOriginationFormFieldProps() {
    return {
      ...originationAirportCodeProps,
      className: styles.originationAirportCode,
    };
  }

  function getEnterPromoFormFieldProps() {
    return {
      ...promoCodeProps,
      label: i18n("VacationsBookingFormSlim__PROMO_CODE_OPTIONAL"),
    };
  }
};
