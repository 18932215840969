import PropTypes from "prop-types";
import React from "react";

export const Lock = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path d="M-475,287h9v-6.1h-9V287z M-473.9,280.1v-1.8c0-1.8,1.5-3.2,3.4-3.2c1.9,0,3.4,1.4,3.4,3.2v1.8h-1.5v-1.8 c0-1-0.8-1.8-1.8-1.8c-1,0-1.8,0.8-1.8,1.8v1.8H-473.9z" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "-475 274 9 12",
    };
  }
};

Lock.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Lock.defaultProps = {
  role: "img",
};
