import { getBootstrapData } from "@swa-ui/bootstrap";

/**
 * getStations
 *
 * Return value: array of all station info.
 */

export const getStations = () =>
  getBootstrapData("air/all-air-stations") ?? getBootstrapData("air/stations-data");

/**
 * getStation
 *
 * Parameter Example: 'DAL'
 * Return value: Station info for given ID. 'undefined' will be return if ID is invalid.
 */

export const getStation = (stationCode) =>
  getStations().find((stationInfo) => stationInfo.id === stationCode);

/**
 * getInternationalStationCode
 *
 * Return value: Array of all international station ID.
 */

export const getInternationalStationCode = () => {
  const internationalStations = getStations().filter(
    (station) => station?.internationalStation === true
  );

  return internationalStations.map((internationalStationCode) => internationalStationCode?.id);
};

/**
 * getStationName outputs the display name from a given airport code.
 *
 * Parameter example: 'ABQ'
 * Return value: Albuquerque, NM - ABQ
 *
 */

export const getStationName = (stationCode) => {
  const station = getStation(stationCode);

  return station && !station.isAirportGroup ? station.displayName : "";
};

/**
 * getStationNameAndState outputs the station name combined with the state federal unit.
 *
 * @param {string} stationCode
 * @returns {string}
 *
 */

export const getStationNameAndState = (stationCode) => {
  const station = getStation(stationCode);
  let stationNameAndState = "";

  if (station) {
    const { stateFederalUnit, stationName } = station;

    stationNameAndState = stationName;

    if (stateFederalUnit?.length > 0) {
      stationNameAndState = `${stationNameAndState}, ${stateFederalUnit}`;
    }
  }

  return stationNameAndState;
};

/**
 * getShortDisplayStationName outputs the display name from a given airport code.
 *
 * Parameter example: 'ABQ'
 * Return value: Albuquerque
 *
 */

export const getShortDisplayStationName = (stationCode) => {
  const station = getStation(stationCode);

  return station && !station.isAirportGroup ? station.shortDisplayName : "";
};

/**
 * getStationForName returns the station info for the given station display name.
 *
 * Parameter example: 'Albuquerque, NM - ABQ'
 * Return value: station info
 *
 */
export const getStationForName = (displayName) =>
  getStations()?.find((station) => station.displayName === displayName);

/**
 * getAreaName returns the station info for the given airport group id.
 *
 * Parameter example: 'SFC'
 * Return value: station group info
 *
 */
export function getAreaName(stationId) {
  const station = getStation(stationId);

  return station?.isAirportGroup ? station?.displayName : undefined;
}

/**
 * getStationOrAreaName returns the station info for the given station id. StationId can be a
 * reference to an airport group or a typical station.
 *
 * Parameter example: 'ABQ'
 * Return value: station info
 *
 */
export function getStationOrAreaName(stationId) {
  const station = getStation(stationId);

  return station ? station?.displayName : "";
}
