import PropTypes from "prop-types";
import React from "react";

import { classNames } from "@swa-ui/string";

import { Area } from "../Area";
import { Heading, headingPropTypes } from "../Heading";
import { TransitionBlock, transitionBlockPropTypes } from "../TransitionBlock";
import styles from "./Card.module.scss";

/**
 * Card provides an area on a page with styling to denote a discrete section. The card supports a header and footer
 * and will smoothly adjust its height if the card's content changes. This transition is accomplished via the Area
 * component.
 */

export const Card = (props) => {
  const {
    animate,
    animationToken,
    children,
    className,
    description,
    footerProps,
    fullHeight,
    fullHeightContent,
    fullWidthContent,
    headingProps,
    id,
    revealed,
  } = props;

  return (
    <Area {...getProps()}>
      {animationToken !== undefined ? (
        <TransitionBlock animationToken={animationToken}>{renderContent()}</TransitionBlock>
      ) : (
        renderContent()
      )}
    </Area>
  );

  function renderContent() {
    return (
      <>
        {renderHeader()}
        <div className={getContainerClass()}>{children}</div>
        {footerProps?.children ? <div {...getFooterClass()}>{footerProps.children}</div> : null}
      </>
    );
  }

  function renderHeader() {
    let header = null;

    if (description || headingProps) {
      header = (
        <div className={styles.header}>
          <div className={styles.headingContainer}>
            {headingProps && (
              <Heading
                className={classNames(headingProps.className, {
                  [styles.heading]: description,
                })}
                {...headingProps}
              />
            )}
          </div>
          {description}
        </div>
      );
    }

    return header;
  }

  function getProps() {
    return {
      animate,
      className: classNames(className, styles.card),
      fullHeight,
      id,
      revealed,
    };
  }

  function getContainerClass() {
    return classNames({
      [styles.bottom]: !footerProps?.children && !fullHeightContent,
      [styles.container]: true,
      [styles.fullHeightContent]: fullHeightContent,
      [styles.fullWidthContent]: fullWidthContent,
      [styles.top]: !description && !fullHeightContent && !headingProps,
    });
  }

  function getFooterClass() {
    return {
      className: classNames({
        [styles.primary]: footerProps?.backgroundColor === "primary",
        [styles.secondary]: footerProps?.backgroundColor === "secondary",
      }),
    };
  }
};

export const cardPropTypes = {
  /** Indicates if content size changes will be animated. */
  animate: PropTypes.bool,

  /**
   * Indicates if content changes will be animated using TransitionBlock component. This must be a
   * different value than the last time the content was rendered. If undefined, no transition will
   * occur.
   */
  animationToken: transitionBlockPropTypes.animationToken,

  /** Content that will be displayed when Card is rendered. */
  children: PropTypes.node,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Optional JSX to be displayed to the right of the heading. */
  description: PropTypes.node,

  /** Optional footer with options for the footer background color and children. */
  footerProps: PropTypes.shape({
    backgroundColor: PropTypes.oneOf(["none", "primary", "secondary"]),
    children: PropTypes.node,
  }),

  /** Determines if the Card component will spread the entire height of its parent container. */
  fullHeight: PropTypes.bool,

  /** Determines if the child content will spread to the entire height of the Card. */
  fullHeightContent: PropTypes.bool,

  /** Determines if the child content will spread to the entire width of the Card. */
  fullWidthContent: PropTypes.bool,

  /** See Heading component for available options. */
  headingProps: PropTypes.shape(headingPropTypes),

  /** Id. Used as unique identifier on DOM. */
  id: PropTypes.string,

  /** Indicates if the Card is revealed or concealed. */
  revealed: PropTypes.bool,
};

Card.propTypes = cardPropTypes;

Card.defaultProps = {
  animate: true,
  footerProps: {
    backgroundColor: "none",
  },
  fullHeight: false,
  fullHeightContent: false,
  fullWidthContent: false,
  revealed: true,
};
