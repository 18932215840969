import PropTypes from "prop-types";
import React, { forwardRef, useRef, useState } from "react";

import { Drawer, VerticalList } from "@swa-ui/core";

import styles from "./TripTypeDrawer.module.scss";

/**
 * TripTypeDrawer renders a list of trip types in a vertical list which allows a user to choose whether a booking is
 * one-way or round-trip...e.t.c.  This component is designed to have the selected value of the list be controlled
 * by it's {value} prop.
 *
 * @component
 * @example
 * const handleChange = (value, tripTypeIndex) => {
 *    console.log(value, tripTypeIndex);
 * };
 * const tripTypeList = [{ label: "One-Way", value: "oneway" }, {label: "Round-Trip", value: "roundtrip" }];
 * const value = "roundtrip";
 *
 * return (
 *   <TripTypeDrawer handleChange={handleChange} tripTypeList={tripTypeList} value={value} />
 * )
 */
export const TripTypeDrawer = forwardRef((props, ref) => {
  const { className = "", handleChange, tripTypeList, value } = props;
  const [revealed, setRevealed] = useState(false);
  const triggerRef = useRef();

  return <Drawer {...getProps()} />;

  function renderChildren() {
    return (
      <div className={styles.dropdown}>
        <VerticalList {...getVerticalListProps()} />
      </div>
    );
  }

  function renderOptions(option) {
    return <span className={styles.listItem}>{option.label}</span>;
  }

  function getProps() {
    return {
      children: renderChildren(),
      className,
      onRevealChange: handleRevealChange,
      ref: triggerRef,
      revealed,
      showPointer: true,
      triggerContent: getTriggerContent(),
    };
  }

  function getVerticalListProps() {
    return {
      items: tripTypeList.map(renderOptions),
      onClick: handleClick,
    };
  }

  function getTriggerContent() {
    return tripTypeList.find((trip) => trip.value === value)?.label;
  }

  function handleRevealChange(open) {
    setRevealed(open);
  }

  function handleClick(index) {
    const { value: newTripTypeValue } = tripTypeList?.at(index);

    closeDrawer();
    handleChange(newTripTypeValue, index);
  }

  function closeDrawer() {
    setRevealed(false);
  }
});

TripTypeDrawer.propTypes = {
  /** Additional class to position the component.  */
  className: PropTypes.string,

  /** Callback function for when the selected trip type changes. */
  handleChange: PropTypes.func.isRequired,

  /** List of trip type (label, value) pairs used to display a list of clickable trip type options. */
  tripTypeList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }).isRequired
  ),

  /** String value of the trip type that corresponds to one of the options value fields in tripTypeList */
  value: PropTypes.string.isRequired,
};
