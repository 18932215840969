import PropTypes from "prop-types";
import { useEffect } from "react";

import { timeouts, useFetch } from "@swa-ui/fetch";
import { isHybridEnabled, useIsBlankRoute } from "@swa-ui/hybrid";

import { useAppSetting } from "../useAppSetting";

export const UiMetadataUpdater = (props) => {
  const { appId, channelId, chapi, chapiVersion } = props;
  const { get, post } = useFetch("", { timeout: timeouts.noncritical });
  const disableMetadataCall = useAppSetting("disableMetadataCall", false);
  const legacyMetadataCall = useAppSetting("legacyMetadataCall", false);
  const isHybridEnabledOnBlankPage = isHybridEnabled() && useIsBlankRoute();

  useEffect(() => {
    if (!isHybridEnabledOnBlankPage && chapi && !disableMetadataCall) {
      retrieveUiMetadata();
    }
  }, [isHybridEnabledOnBlankPage]);

  return null;

  async function retrieveUiMetadata() {
    if (legacyMetadataCall) {
      await post(`/api/${chapi}/${chapiVersion}/${chapi}/feature/uimetadata`, {
        application: appId,
        site: channelId,
      });
    } else {
      await get(`/api/${chapi}/${chapiVersion}/${chapi}/metadata`);
    }
  }
};

UiMetadataUpdater.propTypes = {
  /**
   * String that identifies the app for when making requests. The value, something like: 'gift-card', is added to
   * request headers.
   */
  appId: PropTypes.string.isRequired,

  /**
   * String that identifies the app for when making requests. The value, something like: 'southwest' or 'ios', is
   * added to request headers.
   */
  channelId: PropTypes.string.isRequired,

  /**
   * Api name to retrieve maintenance state. This name will be something like 'travel-funds'. This should be passed
   * down from the application.
   */
  chapi: PropTypes.string,

  /**
   * Version number to locate specific chapi version and will be similar to: 'v1'. This should be passed down from
   * the application.
   */
  chapiVersion: PropTypes.string,
};
