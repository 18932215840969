import PropTypes from "prop-types";
import React from "react";

import { PersonasProvider } from "@swa-ui/personas";
import { ColorThemeProvider } from "@swa-ui/theme";

import { ApplicationContextProvider } from "../ApplicationContextProvider";
import { AppSettingsProvider } from "../AppSettingsProvider";

export const ApplicationProviders = (props) => {
  const { appId, applicationContext, children, defaultAppSettings, persona } = props;

  return (
    <ColorThemeProvider>
      <AppSettingsProvider {...{ appId, defaultAppSettings }}>
        <ApplicationContextProvider context={applicationContext}>
          <PersonasProvider persona={persona}>{children}</PersonasProvider>
        </ApplicationContextProvider>
      </AppSettingsProvider>
    </ColorThemeProvider>
  );
};

ApplicationProviders.propTypes = {
  /** Unique identifier of the current SPA. */
  appId: PropTypes.node,

  /** Application context used to expose values such as basePath, site, experienceId. */
  applicationContext: PropTypes.object,

  /** Content that will be rendered with the provided context. */
  children: PropTypes.node,

  /** Default applications settings provided by the SPA. */
  defaultAppSettings: PropTypes.object,

  /** Optional persona value provided by the SPA. */
  persona: PropTypes.string,
};
