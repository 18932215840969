/* eslint-disable sort-keys */
export default {
  light: {
    "password-validation-message": "gray-4",
    "password-validation-message-error": "red-1",
    "password-validation-message-valid": "green-1",
  },
  dark: {
    "password-validation-message": "white",
    "password-validation-message-error": "red-2",
    "password-validation-message-valid": "green-2",
  },
};
