import React from "react";

import { Grid } from "@swa-ui/core";
import { CalendarFormField, Form, SubmitFormField } from "@swa-ui/form";
import {
  AutocompleteStationsFormField,
  AutocompleteVacationDestinationsFormField,
} from "@swa-ui/itinerary";

import { useVacationsFormLogic } from "../useVacationsFormLogic";
import { PackageOptions } from "./PackageOptions";
import { PromoCode } from "./PromoCode";
import { TravelerSelector } from "./TravelerSelector";
import styles from "./VacationsBookingFormWide.module.scss";

export const VacationsBookingFormWide = () => {
  const { defaultSubmitButtonText, formProps } = useVacationsFormLogic();
  const {
    departureDateProps,
    destinationAirportCodeProps,
    formComponentProps,
    originationAirportCodeProps,
    packageOptionsProps,
    promoCodeProps,
    returnDateProps,
    submitButtonProps,
    travelerSelectorProps,
  } = formProps;

  return (
    <Grid className={styles.layout}>
      <Form {...formComponentProps}>
        <PackageOptions className={styles.package} {...packageOptionsProps} />
        <TravelerSelector className={styles.travelers} {...travelerSelectorProps} />
        <PromoCode className={styles.promo} {...promoCodeProps} />
        <Grid className={styles.formInputs}>
          <AutocompleteStationsFormField {...getOriginationFormFieldProps()} />
          <AutocompleteVacationDestinationsFormField {...getDestinationFormFieldProps()} />
          <CalendarFormField className={styles.departureDate} {...departureDateProps} />
          <CalendarFormField className={styles.returnDate} {...returnDateProps} />
        </Grid>
        <div className={styles.submit}>
          <SubmitFormField className={styles.submitButton} {...submitButtonProps}>
            {defaultSubmitButtonText}
          </SubmitFormField>
        </div>
      </Form>
    </Grid>
  );

  function getDestinationFormFieldProps() {
    return {
      ...destinationAirportCodeProps,
      className: styles.destinationAirportCode,
    };
  }

  function getOriginationFormFieldProps() {
    return {
      ...originationAirportCodeProps,
      className: styles.originationAirportCode,
    };
  }
};
