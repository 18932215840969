import PropTypes from "prop-types";
import React from "react";

export const Location = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path
        d="M56.126,0C25.146,0,0,25.581,0,57.133c0,9.68,2.132,19.161,5.919,29.079c17.127,45.827,50.196,84.405,50.196,84.405
		s32.292-37.391,49.604-82.91c4.158-10.504,6.517-20.489,6.517-30.574C112.235,25.581,87.114,0,56.126,0z M56.126,76.244
		c-11.128,0-20.141-9.168-20.141-20.506c0-11.305,9.013-20.474,20.141-20.474c11.103,0,20.124,9.169,20.124,20.474
		C76.25,67.076,67.229,76.244,56.126,76.244z"
      />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      style,
      viewBox: "0 0 112 170",
    };
  }
};

Location.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Location.defaultProps = {
  role: "img",
};
