import { useContext } from "react";

import { usePrevious } from "@swa-ui/application";

import { AuthContext } from "../AuthProvider";

/**
 * Returns true if user was logged in, and has just logged out.
 */

export const useIsJustLoggedOut = () => {
  const { isLoggedIn } = useContext(AuthContext);
  const loggedIn = isLoggedIn();
  const previouslyLoggedIn = usePrevious(loggedIn) ?? false;

  return previouslyLoggedIn && !loggedIn;
};
