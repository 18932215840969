/**
 * map iterates through the provided object, and returns an object whose values have been run
 * through the iteratee parameter function.
 *
 * @param {object} object the object to iterate over
 * @param {Function} iteratee Function used to transform the object values per iteration
 */
export const map = (object, iteratee) =>
  Object.entries(object ?? {}).reduce(
    (accumulatedObject, [key, value]) => ({
      ...accumulatedObject,
      [key]: typeof iteratee === "function" ? iteratee(value) : value,
    }),
    {}
  );
