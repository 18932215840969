import PropTypes from "prop-types";
import React from "react";

export const Contact = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path
        d="M144.551,75.061C148.783,33.166,121.908,0,76.609,0C34.311,0,0,34.294,0,76.608c0,42.296,34.311,76.604,76.609,76.604
	c17.709,0,34.002-6.039,46.971-16.128l0.025,0.016l-9.961-12.707l-0.008-0.021c-10.23,7.967-23.08,12.7-37.027,12.7
	c-33.412,0-60.474-27.073-60.474-60.464c0-33.396,27.062-60.47,60.474-60.47c37.018,0,54.861,26.063,51.815,58.922
	c-0.622,6.792-3.025,19.42-17.351,20.688c-11.764,1.021-13.063-8.605-10.713-22.252l6.795-33.336
	c-3.416-1.49-12.338-4.159-25.283-4.159c-26.931,0-45.685,21.277-45.685,52.368c0,14.89,7.584,23.819,18.753,23.819
	c11.753,0,20.785-8.337,26.439-18.897h0.309c1.725,6.098,8.654,20.045,28.336,18.73C137.876,110.15,142.973,90.614,144.551,75.061z
	 M85.295,55.34c-4.907,27.083-14.441,40.171-22.314,40.171c-4.613,0-6.694-3.559-6.694-10.414c0-20.385,10.705-34.514,22.605-34.514
	c2.832,0,5.516,0.448,6.986,0.895L85.295,55.34z"
      />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      style,
      viewBox: "0 0 145 153",
    };
  }
};

Contact.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Contact.defaultProps = {
  role: "img",
};
