import PropTypes from "prop-types";
import React from "react";

import { useDeviceInfo } from "@swa-ui/browser";
import { ListMatrix } from "@swa-ui/core";

import { HtmlValue } from "../HtmlValue";
import styles from "./BannerGridLayout3.module.scss";

const BannerGridLayout3 = ({ items = [] }) => {
  const { screenSize } = useDeviceInfo();

  return (
    <div className={styles.bannerGridContainer}>
      <ListMatrix {...getListMatrixProps()} />
    </div>
  );

  function getListMatrixProps() {
    return {
      items: getItems(),
      layout: "grid",
      numberOfColumns: 3,
    };
  }

  function getItems() {
    return items?.map((item) => {
      // eslint-disable-next-line no-unused-vars
      const { callToAction, image, text } = item;
      const imagePath = image?.overrides?.[screenSize]?.path ?? image.path;

      return {
        content: (
          <div className={styles.bannerGridItem} key={text}>
            <img alt={image.altText} className={styles.bannerGridItemImage} src={imagePath} />
            {renderText(text?.primary, "primaryText")}
            <HtmlValue htmlValue={text.secondary.value} />
          </div>
        ),
      };
    });
  }

  function renderText(text, type) {
    return (
      <div className={styles[type]} style={{ ...getTextProps(text) }}>
        {text.value}
      </div>
    );
  }

  function getTextProps(text) {
    const { color, fontSize, fontWeight } = text;

    return {
      color,
      fontSize,
      fontWeight,
    };
  }
};

BannerGridLayout3.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      callToAction: PropTypes.object,
      image: PropTypes.shape({
        altText: PropTypes.string,
        overrides: PropTypes.object,
        path: PropTypes.string,
      }),
      text: PropTypes.shape({
        primary: PropTypes.object,
        secondary: PropTypes.object,
      }),
    })
  ).isRequired,
  layoutType: PropTypes.string.isRequired,
};

export default BannerGridLayout3;
