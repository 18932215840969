import { preprocessContent } from "./preprocessContent";

export const processedPlacementsList = {
  "accordion-block": (placement, additionalProps) => {
    const placementData = { ...placement.placementData, ...additionalProps?.templateData };

    return { ...placement, placementData };
  },
  "flex-placement": (placement, additionalProps) => {
    const processedPlacement = preprocessContent(
      { ...placement.placement },
      additionalProps?.templateData
    );

    return { ...placement, placement: processedPlacement };
  },
};
