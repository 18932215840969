import PropTypes from "prop-types";
import React from "react";

import { classNames } from "@swa-ui/string";

import colors from "../../defines/colors";

/**
 * Business simply renders the text "Business" using an SVG to match exist SwaBiz SVG logo.
 */

const Business = (props) => {
  const { className, fillColor, height, role, strokeColor, style, width } = props;

  return (
    <svg {...getProps()}>
      <path
        d="
                  M12 52.78 h-13 V5.3 H12 c8.84 0 15.76 3.21 15.76 11.84 0 5.7-3.28 9.19-8.49 10.55 v.14
                  c5.78 1 10.55 5.06 10.55 11.62-.05 9.7-7.39 13.33-18.08 13.33z
                  m-.29-42.27 h-6.27 v15 h6.13 c6.84 0 9.84-2.5 9.84-7.84 0-4.95-3.35-7.16-9.7-7.16z
                  m.57 20.1 h-6.84 v17 h6.41c7.85 0 11.48-2.42 11.48-8.13 0-5.48-2.92-8.87-11.07-8.87z

                  M40 18.49 v23.17 c0 4.14 1.42 6.63 5.84 6.63 4.85 0 8.56-4.92 9.27-5.92 v-23.88 h6.06 V45
                  a39.23 39.23 0 00.71 7.77 h-6.06 a38 38 0 01-.57-5 13.86 13.86 0 01-11.12 5.85
                  c-7.34 0-10.19-4.78-10.19-10.84 v-24.29z

                  M78.52 53.71 a19.57 19.57 0 01-11.12-3.28 l2.49-4.49 a15 15 0 008.49 2.78 c4.13 0 6.7-1.64 6.7-4.56
                  s-2.78-4.21-7.77-6.42 c-5.28-2.28-9.13-4.78-9.13-10.48 0-5.49 4.28-9.62 11.27-9.62
                  a17.16 17.16 0 0110.33 3.13l-2.71 4.35 a12.56 12.56 0 00-7.55-2.49
                  c-3.64 0-5.56 1.64-5.56 4.35 0 3.06 2.85 4.2 6.7 5.84 5.49 2.28 10.34 4.78 10.34 10.55 0 6.42-4.78 10.34-12.48 10.34z

                  M97.9 12.43 a4.21 4.21 0 01-4.21-4.2 4.21 4.21 0 018.42 0 4.21 4.21 0 01-4.21 4.2z
                  m-3.06 40.35 v-34.29 H101 v34.29z

                  M128.69 52.78 v-23.17 c0-4.13-1.43-6.7-5.77-6.7-5 0-8.7 4.92-9.41 5.92 v23.95h-6 v-34.29 h6 v4.78
                  a14.18 14.18 0 0111.12-5.63 c7.27 0 10.12 4.77 10.12 10.76 v24.38z

                  M157.41 48.65 a17 17 0 009.41-2.92 l2.36 4.42 a20.78 20.78 0 01-12 3.49 c-11 0-16.61-7.91-16.61-18.25
                  s6.56-17.75 15.75-17.75 c9.49 0 14.62 7.2 13.62 18.32 H147 c.22 6.98 3.07 12.69 10.41 12.69z

                  m6.59-17.33 c-.14-4.63-2.21-8.76-7.84-8.76-5.13 0-8.05 3.63-8.91 8.76z

                  M184.93 53.71 a19.52 19.52 0 01-11.12-3.28l2.49-4.49a14.93 14.93 0 008.48 2.78
                  c4.14 0 6.71-1.64 6.71-4.56 s-2.78-4.21-7.77-6.42
                  c-5.28-2.28-9.13-4.78-9.13-10.48 0-5.49 4.28-9.62 11.26-9.62
                  a17.18 17.18 0 0110.34 3.13l-2.71 4.35a12.59 12.59 0 00-7.55-2.49
                  c-3.64 0-5.57 1.64-5.57 4.35 0 3.06 2.86 4.2 6.71 5.84 5.49 2.28 10.33 4.78 10.33 10.55 0 6.42-4.77 10.34-12.47 10.34z

                  M212 53.71 a19.57 19.57 0 01-11.12-3.28l2.5-4.49 a14.93 14.93 0 008.48 2.78
                  c4.14 0 6.7-1.64 6.7-4.56s-2.78-4.21-7.77-6.42
                  c-5.27-2.28-9.12-4.78-9.12-10.48 0-5.49 4.27-9.62 11.26-9.62 a17.2 17.2 0 0110.34 3.13l-2.71 4.35
                  a12.59 12.59 0 00-7.56-2.49
                  c-3.63 0-5.56 1.64-5.56 4.35 0 3.06 2.85 4.2 6.7 5.84 5.49 2.28 10.34 4.78 10.34 10.55.01 6.42-4.77 10.34-12.48 10.34z"
      />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className: classNames(className),
      fill: fillColor,
      height,
      role,
      stroke: strokeColor,
      style,
      viewBox: "0 0 225 60",
      width,
    };
  }
};

Business.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Color name from color palette or name of an entry in color theme file, themes.js. */
  fillColor: PropTypes.oneOfType([PropTypes.oneOf(Object.keys(colors)), PropTypes.string]),

  /** Indicates SVG's height. The width, if not given, will be sized according to SVG's aspect ratio. */
  height: PropTypes.string,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Color name from color palette or name of an entry in color theme file, themes.js. */
  strokeColor: PropTypes.oneOfType([PropTypes.oneOf(Object.keys(colors)), PropTypes.string]),

  /** Indicates SVG's width. The height, if not given, will be sized according to SVG's aspect ratio. */
  width: PropTypes.string,
};

Business.defaultProps = {
  fillColor: colors["primary-500"],
  height: "32",
  role: "img",
};

export { Business };
