import { window } from "../window";

/**
 * Helper method that accepts an object and converts to a string to append on URL as a query param.
 * @param queryParamObject object of key/value pairs to be converted to query params.
 * @return string
 */

export const createQueryParams = (queryParamObject) =>
  new window.URLSearchParams(queryParamObject).toString();
