import PropTypes from "prop-types";
import React from "react";

import { useHref } from "../useHref/useHref";

export function encryptedLink(Component, props) {
  const {
    additionalUrlSearchParams = {},
    componentName,
    dataToEncrypt,
    feature,
    linkProps,
    url,
  } = props;
  let { href } = useHref(dataToEncrypt, url, componentName, feature);

  return <Component {...getLinkProps()}>{props.children}</Component>;

  function getLinkProps() {
    return {
      ...linkProps,
      href: getLinkHref(),
    };
  }

  function getLinkHref() {
    href = new URL(href, location.origin);

    href.search = new URLSearchParams({
      ...Object.fromEntries(href.searchParams),
      ...additionalUrlSearchParams,
    }).toString();

    return href.toString();
  }
}

encryptedLink.propTypes = {
  /** Additional URL search parameters. */
  additionalUrlSearchParams: PropTypes.object,

  /** Content to be rendered inside the component. */
  children: PropTypes.node.isRequired,

  /** Name of the component. */
  componentName: PropTypes.string.isRequired,

  /** Data to encrypt and include in the URL. */
  dataToEncrypt: PropTypes.object.isRequired,

  /** Feature name related to the encrypted link. */
  feature: PropTypes.string.isRequired,

  /** Link props to pass to the component. */
  linkProps: PropTypes.object.isRequired,

  /** Base URL */
  url: PropTypes.string.isRequired,
};
