import PropTypes from "prop-types";
import React from "react";

export const ArrowDouble = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path d="m.31,2.16c-.42-.49-.42-1.29,0-1.79.41-.5,1.08-.5,1.5,0l5.88,6.95c.42.49.42,1.3,0,1.8l-5.88,6.97c-.42.49-1.09.49-1.5,0-.41-.5-.41-1.3,0-1.79l5.12-6.07L.31,2.16Z" />
      <path d="m8.31,2.16c-.42-.49-.42-1.29,0-1.79.41-.5,1.08-.5,1.5,0l5.88,6.95c.42.49.42,1.3,0,1.8l-5.88,6.97c-.42.49-1.09.49-1.5,0-.41-.5-.41-1.3,0-1.79l5.12-6.07-5.12-6.05Z" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "-5 -5 24 24",
    };
  }
};

ArrowDouble.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

ArrowDouble.defaultProps = { role: "img" };
