import PropTypes from "prop-types";
import React from "react";

import { classNames } from "@swa-ui/string";

import "../../generated/platforms/scss/internal.module.scss";
import { DesignTokensThemeProvider } from "../DesignTokensThemeProvider";

/**
 * Theme for internal styles.
 */

export const InternalTheme = (props) => {
  const { children } = props;

  return <DesignTokensThemeProvider {...getProps()}>{children}</DesignTokensThemeProvider>;

  function getProps() {
    return {
      className: classNames("internal-theme"),
      inverse: false,
      modeName: "",
      themeName: "internal",
    };
  }
};

InternalTheme.propTypes = {
  /** Content that will be rendered within the theme. */
  children: PropTypes.node,
};
