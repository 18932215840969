"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _airManageReservation = require("./airManageReservation");
Object.keys(_airManageReservation).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _airManageReservation[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _airManageReservation[key];
    }
  });
});
var _landingHomePage = require("./landingHomePage");
Object.keys(_landingHomePage).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _landingHomePage[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _landingHomePage[key];
    }
  });
});
var _vacations = require("./vacations");
Object.keys(_vacations).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _vacations[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _vacations[key];
    }
  });
});