import PropTypes from "prop-types";
import React from "react";

import { BulletList, Heading, Layout } from "@swa-ui/core";

import { getBackgroundStyle } from "../Banner/BannerUtils";
import { CallToAction } from "../CallToAction";
import { HtmlValue } from "../HtmlValue";
import styles from "./ListBlockPlacement.module.scss";

/**
 * ListBlockPlacement renders a bulleted list with optional background, heading, callout, columns and call to action.
 * This placement also allows for the heading to be replaced by the consumer.
 */
export const ListBlockPlacement = (props) => {
  const { heading: headingOverride, placement } = props;
  const { list } = placement;
  const { items, settings } = list;
  const { columns = 1 } = settings ?? {};

  return (
    <div {...getBackgroundProps()}>
      {renderCallout()}
      {renderHeading()}
      {renderList()}
      {renderCallToAction()}
    </div>
  );

  function renderCallout() {
    const { callout } = placement;
    const { value, ...calloutStyles } = callout ?? {};

    return callout && <span style={{ ...calloutStyles }}>{value}</span>;
  }

  function renderCallToAction() {
    const { callToAction } = placement;

    return (
      callToAction && (
        <Layout top="xlarge">
          <CallToAction {...callToAction} />
        </Layout>
      )
    );
  }

  function renderHeading() {
    const { heading } = placement;
    const defaultHeading = heading && <Heading {...getHeadingProps(heading)} />;

    return headingOverride ? headingOverride : defaultHeading;
  }

  function renderList() {
    const columnsConfiguration = getColumnsConfiguration();
    const normalizedItems = getNormalizedItems();

    return (
      <div className={styles.container}>
        {columnsConfiguration.map((columnConfiguration, index) => {
          const itemsForThisColumn = normalizedItems.slice(
            columnConfiguration.startIndex,
            columnConfiguration.endIndex + 1
          );

          return (
            <div className={styles.column} key={index}>
              <BulletList {...getBulletListProps(itemsForThisColumn)} />
            </div>
          );
        })}
      </div>
    );
  }

  // TODO: Consolidate Background placement logic into a shareable placement component.
  function getBackgroundProps() {
    const { background } = placement;
    const backgroundStyles = getBackgroundStyle(background);

    return {
      style: backgroundStyles,
    };
  }

  function getColumnsConfiguration() {
    const columnsConfiguration = [];
    let itemsRemaining = items.length;
    let columnsRemaining = columns;
    let currentItemIndex = 0;

    while (itemsRemaining > 0) {
      const currentColumnItemsLength = Math.ceil(itemsRemaining / columnsRemaining);
      const endIndex = currentItemIndex + currentColumnItemsLength - 1;

      columnsConfiguration.push({
        startIndex: currentItemIndex,
        endIndex,
      });
      itemsRemaining -= currentColumnItemsLength;
      columnsRemaining -= 1;
      currentItemIndex = endIndex + 1;
    }

    return columnsConfiguration;
  }

  function getNormalizedItems() {
    return items.map((item) => {
      const content = <HtmlValue htmlValue={item.content ?? item.value} />;
      const svg = item.svg ?? item.image;

      return {
        ...item,
        ...(svg && { svg }),
        content,
      };
    });
  }

  function getBulletListProps(itemList) {
    return {
      ...settings,
      items: itemList,
    };
  }

  // TODO: Consolidate Heading placement logic into a shareable placement component.
  function getHeadingProps(heading) {
    const { color, fontSize, fontWeight, headingLevel = 2, styleLevel, value } = heading ?? {};

    return {
      children: <span style={{ color, fontSize, fontWeight }}>{value}</span>,
      headingLevel,
      styleLevel,
    };
  }
};

ListBlockPlacement.propTypes = {
  /** Used to override the default heading rendering behavior. */
  heading: PropTypes.node,

  /** Object consisting of an data used to construct the component. */
  placement: PropTypes.object.isRequired,
};
