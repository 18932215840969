import PropTypes from "prop-types";
import React, { useContext, useState } from "react";

/**
 * OverlayProvider provides all overlay configuration settings in a React context
 * so the configuration settings are available to components in an app's render tree.
 */
export const OverlayContext = React.createContext();
export const OverlayProvider = (props) => {
  const { children, overlayConfiguration } = props;
  const overlayContextConfiguration = useContext(OverlayContext);
  const [activeDialog, setActiveDialog] = useState();

  return (
    <OverlayContext.Provider
      value={{
        ...overlayContextConfiguration,
        ...overlayConfiguration,
        activeDialog,
        setActiveDialog,
      }}
    >
      {children}
    </OverlayContext.Provider>
  );
};

const overlayProviderTypes = {
  /** App that will be provided with configuration values. */
  children: PropTypes.node.isRequired,

  /** Configuration object used to override global values with local values. */
  overlayConfiguration: PropTypes.object,
};

OverlayContext.displayName = "OverlayContext";
OverlayProvider.propTypes = overlayProviderTypes;
