import PropTypes from "prop-types";
import React from "react";

export const Picture = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path
        strokeWidth={0}
        d="M27.97,2.21H3.62c-.78,0-1.41,.63-1.41,1.41V27.97c0,.78,.63,1.41,1.41,1.41H27.97c.78,0,1.41-.63,1.41-1.41V3.62c0-.78-.63-1.41-1.41-1.41ZM3.41,27.97V3.62c0-.12,.09-.21,.21-.21H27.97c.12,0,.21,.09,.21,.21v15.06l-6.19-6.19c-.22-.22-.62-.22-.85,0L5.45,28.18h-1.83c-.12,0-.21-.1-.21-.21Zm24.56,.21H7.15l14.42-14.42,6.61,6.61v7.6c0,.12-.1,.21-.21,.21Z"
      />
      <path
        strokeWidth={0}
        d="M10.75,13.51c1.52,0,2.76-1.24,2.76-2.76s-1.24-2.76-2.76-2.76-2.76,1.24-2.76,2.76,1.24,2.76,2.76,2.76Zm0-4.33c.86,0,1.56,.7,1.56,1.56s-.7,1.56-1.56,1.56-1.56-.7-1.56-1.56,.7-1.56,1.56-1.56Z"
      />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      style,
      viewBox: "0 0 32 32",
    };
  }
};

Picture.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Picture.defaultProps = {
  role: "img",
};
