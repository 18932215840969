import { doRectanglesIntersect } from "../doRectanglesIntersect";
import { getCumulativeOffset } from "../getCumulativeOffset";
import { getCumulativeTransformations } from "../getCumulativeTransformations";

/**
 * Determines if two HTML elements intersect each other.
 *
 * @param {HTMLElement} element1 - The first HTML element.
 * @param {HTMLElement} element2 - The second HTML element.
 * @returns {boolean} - Returns true if the elements intersect, false otherwise.
 */

const isIntersectingRect = (element1, element2) => {
  const { transformY, transformX } = getCumulativeTransformations();
  const { height: height1, width: width1 } = element1.getBoundingClientRect();
  const { height: height2, width: width2 } = element2.getBoundingClientRect();
  const x1 = getCumulativeOffset(element1).left + transformX;
  const y1 = getCumulativeOffset(element1).top + transformY;
  const x2 = getCumulativeOffset(element2).left;
  const y2 = getCumulativeOffset(element2).top;

  return doRectanglesIntersect(
    x1,
    y1,
    x1 + width1,
    y1 + height1,
    x2,
    y2,
    x2 + width2,
    y2 + height2
  );
};

export { isIntersectingRect };
