const EARTH_RADIUS_IN_MILES = 3959;

export const getClosestPoints = (origin, points) =>
  points
    .reduce((closestPoints, point) => {
      const distanceApart = haversine(origin, point);
      const shortestDistance = closestPoints?.[0]?.[1] ?? Number.MAX_VALUE;
      const distanceDuple = [point, distanceApart];

      if (distanceApart < shortestDistance) {
        closestPoints = [distanceDuple];
      } else if (distanceApart <= shortestDistance) {
        closestPoints.push(distanceDuple);
      }

      return closestPoints;
    }, [])
    .map((point) => point[0]);

function haversine(pointA, pointB) {
  const pointALatitude = parseFloat(pointA?.latitude);
  const pointALongitude = parseFloat(pointA?.longitude);
  const pointBLatitude = parseFloat(pointB?.latitude);
  const pointBLongitude = parseFloat(pointB?.longitude);
  let distance;

  if (
    isCoordinate(pointALatitude, pointALongitude) &&
    isCoordinate(pointBLatitude, pointBLongitude)
  ) {
    const longitudeDistance = toRadians(pointBLongitude - pointALongitude);
    const latitudeDistance = toRadians(pointBLatitude - pointALatitude);
    const squaredHalvedChord =
      Math.sin(latitudeDistance / 2) * Math.sin(latitudeDistance / 2) +
      Math.sin(longitudeDistance / 2) *
        Math.sin(longitudeDistance / 2) *
        Math.cos(toRadians(pointALatitude)) *
        Math.cos(toRadians(pointBLatitude));
    const angularDistanceInRads =
      2 * Math.atan2(Math.sqrt(squaredHalvedChord), Math.sqrt(1 - squaredHalvedChord));

    distance = Math.ceil(EARTH_RADIUS_IN_MILES * angularDistanceInRads);
  }

  return distance;
}

function isCoordinate(latitude, longitude) {
  return Number(latitude) === latitude && Number(longitude) === longitude;
}

function toRadians(angleInDegrees) {
  return (angleInDegrees * Math.PI) / 180;
}
