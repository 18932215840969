import PropTypes from "prop-types";
import React from "react";

import { window } from "@swa-ui/browser";

import { FetchInterceptor } from "../FetchInterceptor";
import { getResponseCodes } from "../getResponseCodes";
import { isResponseOk } from "../isResponseOk";

/**
 * FetchErrorLogger logs fetch errors. Error logging can be suppressed by http
 * status code or 9 digit error code.
 */

export const FetchErrorLogger = (props) => {
  const { children, codesToSuppress = {}, logStatusUsingErrorCodeForPaths = [] } = props;

  return <FetchInterceptor onResponse={handleFetchResponse}>{children}</FetchInterceptor>;

  function handleFetchResponse(response) {
    const codes = getResponseCodes(response);

    if (!didCallExecuteAsExpected(response, codes)) {
      const { data, status, statusText, url } = response;
      const {
        error,
        httpStatusCode,
        message,
        notifications,
        requestId = response.headers?.get?.("x-request-id"),
      } = data ?? {};

      response.data.error = {
        codes,
        error,
        httpStatusCode: getHttpStatusCode(httpStatusCode, codes, url),
        message,
        notifications,
        requestId,
        status: getHttpStatusCode(status, codes, url),
        statusText,
        url,
      };
    }

    return response;
  }

  function didCallExecuteAsExpected(response, codes) {
    const { status, url } = response;
    const path = new window.URL(url).pathname;

    return (
      isResponseOk(response) ||
      isHttpStatusCodeSuppressed(status, path) ||
      areAllCodesSuppressed(codes, status, path)
    );
  }

  function getHttpStatusCode(httpStatusCode, codes, url) {
    return shouldLogStatusFromErrorCode(codes, url)
      ? parseInt(codes?.[0].substring(0, 3))
      : httpStatusCode;
  }

  function shouldLogStatusFromErrorCode(codes, url) {
    return (
      codes?.[0]?.match?.(/^\d{9}$/) &&
      logStatusUsingErrorCodeForPaths.some((path) => url.includes(path))
    );
  }

  function isHttpStatusCodeSuppressed(status, path) {
    return isSuppressed([status.toString()], path);
  }

  function areAllCodesSuppressed(codes, status, path) {
    const codesWithStatusPrefix = codes.map((code) => `${status}:${code}`);

    return codes.length && (isSuppressed(codes, path) || isSuppressed(codesWithStatusPrefix, path));
  }

  function isSuppressed(codes, path) {
    const codesToSuppressForPath = getCodesToSuppress(path);

    return (
      codes.filter((code) => !codesToSuppressForPath.includes(code?.toString?.())).length === 0
    );
  }

  function getCodesToSuppress(path) {
    const pathCodesToSuppress = codesToSuppress[path] ?? [];
    const globalCodesToSuppress = codesToSuppress.global ?? [];

    return pathCodesToSuppress.concat(globalCodesToSuppress);
  }
};

FetchErrorLogger.propTypes = {
  /** Content that will be rendered as the page body. */
  children: PropTypes.node.isRequired,

  /**
   * Object with an array of http status codes per request path that will not be logged.
   * A special 'global' path can be used in order to suppress error logging across all paths.
   *
   * Example:
   *     {
   *         "/api/travel-funds/v1/travel-funds/feature/gift-cards": ["404", "404622370", "404:404622370"],
   *         "/api/security/v4/security/token": ["400:400618202"],
   *         "global": ["404", "404622370", "404:404622370"],
   *     }
   * */
  codesToSuppress: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),

  /**
   * Array of paths that will log the http status code using the first
   * 3 digits from the 9 digit error code.
   * */
  logStatusUsingErrorCodeForPaths: PropTypes.arrayOf(PropTypes.string),
};
