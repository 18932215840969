import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import { getBootstrapData } from "@swa-ui/bootstrap";
import { useDeviceInfo } from "@swa-ui/browser";
import { Button, Caption, Link, useCaption } from "@swa-ui/core";
import { useTravelerSelectionModalAnalytics } from "@swa-ui/dotcom-analytics";
import { SelectFormField } from "@swa-ui/form";
import i18n from "@swa-ui/locale";
import { classNames } from "@swa-ui/string";

import { childAgeList } from "../../defines/childAgeList";
import { FLIGHT_HOTEL, FLIGHT_HOTEL_CAR } from "../../defines/packageType";
import { packageTypeProps } from "../../proptypes/packageType";
import styles from "./TravelerSelector.module.scss";
import { TravelerSelectorInputField } from "./TravelerSelectorInputField";

const ADULTS = "adults";
const CHILDREN = "children";
const DEFAULT_ADULTS = 2;
const DEFAULT_CHILD_AGE = 2;
const LAP_CHILDREN = "lapChildren";
const MAXIMUM_ADULTS_PER_ROOM = 4;
const MAXIMUM_CHILDREN_PER_ROOM = 2;
const MINIMUM_ADULTS = 1;
const ROOM_MAX_COUNT = 4;
const ROOM_MIN_COUNT = 1;
const TRAVELERS_MAX_COUNT = 8;

export const TravelerSelector = (props) => {
  const { className, onChange, packageType, travelers, dropdown } = props;
  const { screenSize } = useDeviceInfo();
  const { captionRef, captionLocation, hideCaption, showCaption, isCaptionVisible } = useCaption(
    screenSize === "small" ? "full-screen" : "below"
  );
  const [updateFocus, setUpdateFocus] = useState(false);
  const addRoomLink = useRef();
  const removeRoomLink = useRef();
  const urlData = getBootstrapData("urls");

  useTravelerSelectionModalAnalytics(isCaptionVisible);

  useEffect(() => {
    if (updateFocus) {
      moveFocusToAddRemoveRoomButton();
      setUpdateFocus(false);
    }
  }, [updateFocus]);

  return (
    <Caption {...getCaptionProps()}>
      {dropdown ? (
        <SelectFormField {...getDropDownProps()} />
      ) : (
        <Link {...getLinkProps()}>{renderTriggerText()}</Link>
      )}
    </Caption>
  );

  function renderTriggerText() {
    return `${renderRooms()}${hasRooms() ? ", " : ""}${renderTravelers()}`;
  }

  function renderRooms() {
    const rooms = travelers.length;

    return hasRooms() ? i18n("TravelerSelector__ROOMS_COUNT", rooms) : "";
  }

  function renderTravelers() {
    return i18n("TravelerSelector__TRAVELERS_COUNT", getAllPassengersCount());
  }

  function renderTravelerSelectorContent() {
    return (
      <div className={styles.captionContent} ref={captionRef}>
        <div className={styles.scrollableSection}>
          <div className={getRoomContainerClassNames()}>{travelers.map(renderRoomsCaption)}</div>
        </div>
        <div className={styles.submitSection}>
          {renderBabyOnBoardMessage()}
          <Button {...getApplyButtonProps()}>{i18n("TravelerSelector__APPLY")}</Button>
        </div>
      </div>
    );
  }

  function renderRoomsCaption(room, index) {
    return (
      <div
        key={`room-${index}-${room.adults}-${room.children}-${room.lapChildren}`}
        className={styles.roomsCaptionContainer}
      >
        {renderRoomHeader(index)}
        <div className={styles.inputFieldContainer}>
          <TravelerSelectorInputField {...getInputFieldProps(ADULTS, room.adults, index)} />
        </div>
        <div className={styles.inputFieldContainer}>
          <TravelerSelectorInputField {...getInputFieldProps(CHILDREN, room.children, index)} />
          {renderChildrenAgeSelector(room, index)}
        </div>
        <div className={styles.inputFieldContainer}>
          <TravelerSelectorInputField
            {...getInputFieldProps(LAP_CHILDREN, room.lapChildren, index)}
          />
        </div>
      </div>
    );
  }

  function renderChildrenAgeSelector(room, roomIndex) {
    return (
      room.childrenAge.length > 0 && (
        <>
          <div className={styles.childrenAgeHeading}>{i18n("TravelerSelector__SELECT_AGE")}</div>
          <div className={styles.childrenAgeContainer}>
            {room.childrenAge.map((childAge, childIndex) => {
              const formattedChildAge = childAge === 0 ? "<1" : childAge;

              return (
                <SelectFormField
                  key={`room-${roomIndex}-child-${childIndex}`}
                  {...getChildAgeSelectorProps(formattedChildAge, roomIndex, childIndex)}
                />
              );
            })}
          </div>
        </>
      )
    );
  }

  function renderBabyOnBoardMessage() {
    return (
      <div className={styles.babyOnBoardContainer}>
        <div className={styles.babyOnBoardHeading}>
          {i18n("TravelerSelector__BABY_ON_BOARD_TITLE")}
        </div>
        <div className={styles.babyOnBoardDescription}>
          {i18n("TravelerSelector__BABY_ON_BOARD_DESCRIPTION")}
          <Link {...getLearnMoreLinkProps()}>
            {i18n("TravelerSelector__BABY_ON_BOARD_LEARN_MORE")}
          </Link>
        </div>
      </div>
    );
  }

  function renderRoomHeader(index) {
    const roomNumber = index + 1;
    const addRoomEnabled =
      travelers.length < ROOM_MAX_COUNT &&
      roomNumber === travelers.length &&
      getAllPassengersCount() < TRAVELERS_MAX_COUNT;
    const removeRoomEnabled = travelers.length > ROOM_MIN_COUNT && roomNumber === travelers.length;

    return (
      hasRooms() && (
        <div className={styles.roomSelectionContainer}>
          <div className={styles.roomSelectionHeading}>
            {i18n("TravelerSelector__ROOM_NUMBER", roomNumber)}
          </div>
          {removeRoomEnabled && (
            <Link {...getRemoveRoomProps(roomNumber)}>{i18n("TravelerSelector__REMOVE_ROOM")}</Link>
          )}
          {addRoomEnabled && (
            <Link {...getAddRoomProps(roomNumber + 1)}>{i18n("TravelerSelector__ADD_ROOM")}</Link>
          )}
        </div>
      )
    );
  }

  function getDropDownProps() {
    return {
      componentProps: {
        list: [],
        onTriggerClick: showCaption,
        readonly: true,
        value: renderTriggerText(),
      },
      label: i18n("TravelerSelector__ARIA_LABEL__TRAVELER_SELECTOR"),
      name: "travelersDropdownUI",
    };
  }

  function getLinkProps() {
    return {
      className: styles.link,
      emphasis: true,
      onClick: showCaption,
      suffixIcon: {
        name: "ArrowSolid",
        role: "presentation",
        size: "30",
      },
    };
  }

  function getInputFieldProps(passengerType, value, roomIndex) {
    const travelersInRoom = travelers[roomIndex];
    const maximumPassengersPerRoom = {
      [ADULTS]: getMaximumAdultsInRoom(travelersInRoom),
      [CHILDREN]: MAXIMUM_CHILDREN_PER_ROOM - travelersInRoom[LAP_CHILDREN],
      [LAP_CHILDREN]: getMaximumLapChildrenInRoom(travelersInRoom),
    };

    return {
      defaultValue: value,
      maximumValue:
        getAllPassengersCount() === TRAVELERS_MAX_COUNT
          ? travelersInRoom[passengerType]
          : maximumPassengersPerRoom[passengerType],
      minimumValue: passengerType === ADULTS ? 1 : 0,
      onChange: (newValue) => handleInputFieldChange(passengerType, roomIndex, newValue),
      passengerType,
      roomIndex,
    };
  }

  function getMaximumAdultsInRoom(travelersInRoom) {
    let maximumAdults = MAXIMUM_ADULTS_PER_ROOM;
    const otherPassengersCount = getAllPassengersCount() - travelersInRoom[ADULTS];

    if (otherPassengersCount > maximumAdults) {
      maximumAdults = TRAVELERS_MAX_COUNT - otherPassengersCount;
    }

    return maximumAdults;
  }

  function getMaximumLapChildrenInRoom(travelersInRoom) {
    let maximumLapChildren = MAXIMUM_CHILDREN_PER_ROOM - travelersInRoom[CHILDREN];

    if (travelersInRoom[ADULTS] < MAXIMUM_CHILDREN_PER_ROOM) {
      if (travelersInRoom[CHILDREN] > 1) {
        maximumLapChildren = 0;
      } else {
        maximumLapChildren = travelersInRoom[ADULTS];
      }
    }

    return maximumLapChildren;
  }

  function getCaptionProps() {
    return {
      adjoiningContent: renderTravelerSelectorContent(),
      alignment: "center",
      bestFit: false,
      className,
      location: captionLocation,
      pointerAlignment: "center",
      showPointer: true,
    };
  }

  function getChildAgeSelectorProps(childAge, roomIndex, childIndex) {
    return {
      componentProps: {
        "aria-label": i18n("TravelerSelector__ARIA_LABEL__CHILD_AGE_SELECTION", childIndex + 1),
        className: styles.childrenAgeInput,
        defaultValue: childAge,
        list: childAgeList,
        maxItemsToDisplay: 8,
        onChange: (event) => handleChildAgeInputChange(event.target.value, roomIndex, childIndex),
        size: "small",
        value: childAge,
      },
      hideBottomSection: true,
      label: i18n("TravelerSelector__CHILD_NUMBER", childIndex + 1),
      name: `childAge${childIndex}Room${roomIndex}`,
    };
  }

  function getAddRoomProps(roomNumber) {
    return {
      "aria-label": i18n("TravelerSelector__ARIA_LABEL__ADD_ROOM", roomNumber),
      className: styles.addRoom,
      emphasis: true,
      id: "travelerSelectorAddRoom",
      onClick: handleAddRoom,
      ref: addRoomLink,
      size: "fontSize12",
    };
  }

  function getRemoveRoomProps(roomNumber) {
    return {
      "aria-label": i18n("TravelerSelector__ARIA_LABEL__REMOVE_ROOM", roomNumber),
      className: styles.removeRoom,
      emphasis: true,
      id: "travelerSelectorRemoveRoom",
      onClick: handleRemoveRoom,
      ref: removeRoomLink,
    };
  }

  function getLearnMoreLinkProps() {
    return {
      "aria-label": i18n("TravelerSelector__ARIA_LABEL__LEARN_MORE"),
      emphasis: false,
      href: urlData.LAP_CHILD_POLICY,
      newWindow: true,
      size: "fontSize14",
      styleType: "link",
    };
  }

  function getApplyButtonProps() {
    return {
      className: styles.travelerSelectorApplyButton,
      id: "travelerSelectorApply",
      onClick: hideCaption,
      styleType: "primary",
    };
  }

  function getRoomContainerClassNames() {
    return classNames({
      [styles.selectorContainer]: true,
      [styles.selectorContainerRowGap]: travelers.length > 1,
    });
  }

  function handleAddRoom() {
    const newTravelers = [...travelers];

    newTravelers.push({
      adults:
        getAllPassengersCount() === TRAVELERS_MAX_COUNT - MINIMUM_ADULTS
          ? MINIMUM_ADULTS
          : DEFAULT_ADULTS,
      children: 0,
      childrenAge: [],
      lapChildren: 0,
    });
    onChange(newTravelers);
    setUpdateFocus(true);
  }

  function handleRemoveRoom() {
    const newTravelers = [...travelers];

    newTravelers.pop();
    onChange(newTravelers);
    setUpdateFocus(true);
  }

  function handleInputFieldChange(passengerType, roomIndex, newValue) {
    const newTravelers = [...travelers];
    const previousValue = travelers[roomIndex][passengerType];

    newTravelers[roomIndex][passengerType] = newValue;

    if (passengerType === ADULTS) {
      const { adults, lapChildren } = newTravelers[roomIndex];

      if (lapChildren > adults) {
        newTravelers[roomIndex].lapChildren = adults;
      }
    }

    if (passengerType === CHILDREN) {
      const childrenAgeArray = newTravelers[roomIndex].childrenAge;

      if (newTravelers[roomIndex].children > previousValue) {
        childrenAgeArray.push(DEFAULT_CHILD_AGE);
      } else {
        childrenAgeArray.pop();
      }
    }

    onChange(newTravelers);
  }

  function handleChildAgeInputChange(newValue, roomIndex, childIndex) {
    const newTravelers = [...travelers];
    const childAgeValue = Number(newValue);

    newTravelers[roomIndex].childrenAge[childIndex] = Number.isNaN(childAgeValue)
      ? 0
      : childAgeValue;
    onChange(newTravelers);
  }

  function hasRooms() {
    return packageType === FLIGHT_HOTEL || packageType === FLIGHT_HOTEL_CAR;
  }

  function getAllPassengersCount() {
    return travelers.reduce(
      (total, room) => total + room.adults + room.children + room.lapChildren,
      0
    );
  }

  function moveFocusToAddRemoveRoomButton() {
    if (addRoomLink.current) {
      addRoomLink.current?.focus();
    } else {
      removeRoomLink.current?.focus();
    }
  }
};

TravelerSelector.propTypes = {
  /** Additional class to position the component.  */
  className: PropTypes.string,

  /** Determines whether text or a dropdown is used. */
  dropdown: PropTypes.string,

  /** Callback function to be called after one of the package type button groups is selected. */
  onChange: PropTypes.func.isRequired,

  /** Package type selected. */
  packageType: packageTypeProps.isRequired,

  /** List of rooms with passengers. */
  travelers: PropTypes.arrayOf(PropTypes.object).isRequired,
};
