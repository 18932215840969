import PropTypes from "prop-types";
import React from "react";

export const Gambling = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path d="M5.49077 5.67448C5.46757 5.67448 5.44476 5.67603 5.42208 5.67792C5.47694 5.55328 5.49974 5.41688 5.4884 5.28117C5.47706 5.14545 5.43194 5.01474 5.35715 4.90092C5.28236 4.78711 5.18028 4.69382 5.06021 4.62955C4.94014 4.56529 4.8059 4.53209 4.66972 4.53298C4.53353 4.53387 4.39974 4.56882 4.28052 4.63466C4.1613 4.70049 4.06045 4.79511 3.98716 4.90989C3.91387 5.02467 3.87046 5.15597 3.8609 5.29182C3.85133 5.42767 3.87592 5.56376 3.9324 5.68767C3.81609 5.70815 3.70562 5.75364 3.60862 5.82101C3.51161 5.88837 3.4304 5.976 3.3706 6.07784C3.31079 6.17967 3.27382 6.29328 3.26223 6.41081C3.25065 6.52834 3.26473 6.64699 3.30351 6.75854C3.34228 6.87009 3.40482 6.97189 3.48681 7.0569C3.56879 7.1419 3.66826 7.20809 3.77834 7.25087C3.88841 7.29366 4.00647 7.31202 4.12434 7.3047C4.24221 7.29738 4.35709 7.26454 4.46102 7.20845V7.54185C4.46102 7.61659 4.36515 7.79142 4.28759 7.92073C4.27827 7.93623 4.27323 7.95392 4.27299 7.97201C4.27275 7.99009 4.27732 8.00791 4.28623 8.02365C4.29513 8.03939 4.30806 8.05248 4.32369 8.06158C4.33932 8.07068 4.35708 8.07547 4.37517 8.07545H5.20837C5.22646 8.07547 5.24422 8.07068 5.25985 8.06158C5.27548 8.05248 5.28841 8.03939 5.29732 8.02365C5.30623 8.00791 5.31079 7.99009 5.31055 7.97201C5.31031 7.95392 5.30527 7.93623 5.29595 7.92073C5.21834 7.79142 5.12252 7.61659 5.12252 7.54185V7.21834C5.23249 7.27398 5.35364 7.30398 5.47687 7.3061C5.6001 7.30821 5.72221 7.28238 5.83402 7.23054C5.94584 7.1787 6.04446 7.1022 6.12247 7.00679C6.20049 6.91138 6.25587 6.79953 6.28447 6.67965C6.31306 6.55976 6.31413 6.43496 6.28758 6.3146C6.26103 6.19425 6.20756 6.08147 6.13118 5.98474C6.0548 5.88802 5.9575 5.80985 5.84658 5.75611C5.73567 5.70238 5.61402 5.67447 5.49077 5.67448Z" />
      <path d="M8.67576 11.7539V1.03645C8.6755 0.832932 8.59454 0.637827 8.45063 0.493921C8.30673 0.350015 8.11162 0.269056 7.90811 0.268799H1.36237C1.15886 0.269067 0.963765 0.35003 0.819862 0.493934C0.675958 0.637838 0.594995 0.832936 0.594727 1.03645V11.7539C0.594995 11.9575 0.675958 12.1526 0.819862 12.2965C0.963765 12.4404 1.15886 12.5213 1.36237 12.5216H7.90798C8.11151 12.5214 8.30665 12.4404 8.45059 12.2965C8.59452 12.1526 8.6755 11.9575 8.67576 11.7539ZM0.903374 11.7539V1.03645C0.903549 0.914812 0.951945 0.79821 1.03795 0.712202C1.12396 0.626194 1.24056 0.577797 1.3622 0.577622H7.90798C8.02961 0.577797 8.14621 0.626194 8.23222 0.712202C8.31823 0.79821 8.36662 0.914812 8.3668 1.03645V11.7539C8.36662 11.8756 8.31823 11.9922 8.23222 12.0782C8.14621 12.1642 8.02961 12.2126 7.90798 12.2128H1.36237C1.24071 12.2126 1.12407 12.1642 1.03803 12.0782C0.951985 11.9922 0.90356 11.8756 0.903374 11.7539Z" />
      <path d="M14.048 4.97552L9.15979 2.17542C9.14648 2.16779 9.1314 2.16379 9.11606 2.16382C9.10072 2.16385 9.08565 2.16792 9.07237 2.17561C9.0591 2.1833 9.04808 2.19434 9.04042 2.20764C9.03275 2.22093 9.02872 2.236 9.02872 2.25134V2.32418C9.02874 2.36432 9.03936 2.40374 9.0595 2.43846C9.07964 2.47319 9.10859 2.50197 9.14342 2.52192L13.8949 5.24358C13.9873 5.29647 14.0549 5.38389 14.0828 5.48661C14.1107 5.58933 14.0967 5.69895 14.0439 5.79134L9.21979 14.2207C9.16697 14.313 9.07972 14.3805 8.97716 14.4086C8.8746 14.4366 8.76512 14.4228 8.67273 14.3702L6.19332 12.9345C6.12567 12.8954 6.04888 12.8747 5.9707 12.8747H5.79203C5.77322 12.8747 5.75493 12.881 5.74001 12.8924C5.72508 12.9038 5.71435 12.9199 5.70947 12.9381C5.70459 12.9562 5.70584 12.9755 5.71302 12.9929C5.7202 13.0103 5.73291 13.0248 5.74919 13.0342L8.51876 14.6378C8.68227 14.7312 8.87619 14.756 9.05791 14.7065C9.23964 14.6571 9.39431 14.5375 9.48794 14.3741L14.3116 5.9447C14.4052 5.78122 14.43 5.58727 14.3805 5.40552C14.3311 5.22378 14.2115 5.0691 14.048 4.97552Z" />
      <path d="M7.41808 12.8747C7.39273 12.8747 7.36811 12.8831 7.34807 12.8986C7.32803 12.9142 7.31371 12.9359 7.30736 12.9604C7.30101 12.985 7.30299 13.0109 7.31298 13.0342C7.32297 13.0575 7.34042 13.0768 7.36258 13.0891L8.50342 13.7269C8.59823 13.7811 8.71068 13.7954 8.81605 13.7668C8.92143 13.7381 9.01112 13.6688 9.06543 13.5741L13.4174 5.9698C13.4443 5.92286 13.4616 5.87109 13.4685 5.81744C13.4753 5.76379 13.4715 5.70932 13.4573 5.65713C13.4431 5.60495 13.4188 5.55607 13.3857 5.5133C13.3526 5.47052 13.3114 5.43468 13.2645 5.40783L9.3563 3.17173C9.32341 3.15316 9.28622 3.14353 9.24846 3.1438C9.21069 3.14408 9.17365 3.15424 9.14103 3.17328C9.10841 3.19233 9.08135 3.21958 9.06254 3.25234C9.04374 3.2851 9.03384 3.32221 9.03384 3.35998L9.02868 11.7539C9.02868 11.9011 8.99969 12.0468 8.94338 12.1828C8.88707 12.3187 8.80452 12.4423 8.70047 12.5463C8.59641 12.6504 8.47288 12.7329 8.33692 12.7892C8.20097 12.8455 8.05525 12.8745 7.90809 12.8745L7.41808 12.8747Z" />
      <path d="M2.7415 2.47872C2.47507 1.97305 2.32093 1.39709 2.28321 1.24643C2.28395 1.21091 2.27242 1.17623 2.25057 1.14822C2.22872 1.12022 2.19788 1.1006 2.16325 1.09268C2.13885 1.08695 2.11345 1.08695 2.08904 1.09268C2.05441 1.1006 2.02356 1.12022 2.00171 1.14824C1.97986 1.17626 1.96834 1.21095 1.96909 1.24647C1.93137 1.39714 1.77731 1.97305 1.51079 2.47872C1.47036 2.48009 1.43208 2.4973 1.40422 2.52663C1.37635 2.55596 1.36113 2.59507 1.36184 2.63552C1.36255 2.67597 1.37912 2.71452 1.40799 2.74286C1.43687 2.7712 1.47572 2.78706 1.51618 2.78702H1.73884C1.77042 2.78714 1.80128 2.77754 1.82721 2.75952C1.85315 2.7415 1.8729 2.71593 1.8838 2.68629C1.8947 2.65664 1.8962 2.62436 1.88811 2.59384C1.88002 2.56331 1.86273 2.53601 1.83859 2.51565C1.86082 2.47047 1.88218 2.42522 1.90265 2.3799H2.34965C2.37024 2.42511 2.39159 2.47036 2.41371 2.51565C2.38956 2.53601 2.37227 2.56331 2.36418 2.59385C2.35609 2.62438 2.3576 2.65666 2.3685 2.68631C2.37941 2.71595 2.39917 2.74152 2.42511 2.75954C2.45105 2.77756 2.48192 2.78715 2.5135 2.78702H2.73616C2.77662 2.78705 2.81546 2.77118 2.84432 2.74284C2.87319 2.7145 2.88975 2.67594 2.89045 2.6355C2.89116 2.59505 2.87593 2.55595 2.84807 2.52662C2.8202 2.49729 2.78193 2.48009 2.7415 2.47872ZM1.99662 2.11956C2.04515 1.99325 2.08825 1.87171 2.12615 1.7585C2.16409 1.87171 2.20728 1.99325 2.25568 2.11956H1.99662Z" />
      <path d="M7.75374 10.0024H7.53108C7.4995 10.0023 7.46864 10.0119 7.44271 10.0299C7.41677 10.048 7.39701 10.0735 7.38612 10.1032C7.37522 10.1328 7.37372 10.1651 7.3818 10.1956C7.38989 10.2262 7.40718 10.2535 7.43133 10.2738C7.40909 10.319 7.38772 10.3642 7.36722 10.4096H6.92027C6.89968 10.3643 6.87831 10.319 6.85617 10.2738C6.88031 10.2535 6.8976 10.2262 6.90569 10.1956C6.91378 10.1651 6.91227 10.1328 6.90138 10.1032C6.89048 10.0735 6.87072 10.048 6.84479 10.0299C6.81885 10.0119 6.788 10.0023 6.75642 10.0024H6.53371C6.49326 10.0024 6.45441 10.0183 6.42555 10.0466C6.39669 10.075 6.38012 10.1135 6.37942 10.154C6.37872 10.1944 6.39394 10.2335 6.4218 10.2628C6.44967 10.2922 6.48794 10.3094 6.52837 10.3107C6.79484 10.8164 6.94895 11.3923 6.98667 11.543C6.98592 11.5785 6.99745 11.6132 7.0193 11.6412C7.04115 11.6692 7.07199 11.6888 7.10662 11.6967C7.13102 11.7025 7.15643 11.7025 7.18083 11.6967C7.21546 11.6888 7.2463 11.6692 7.26815 11.6412C7.29 11.6132 7.30153 11.5785 7.30078 11.543C7.3385 11.3923 7.49256 10.8164 7.75908 10.3107C7.79951 10.3094 7.83779 10.2922 7.86565 10.2628C7.89352 10.2335 7.90874 10.1944 7.90803 10.1539C7.90733 10.1135 7.89075 10.0749 7.86188 10.0466C7.83301 10.0183 7.79415 10.0024 7.7537 10.0024H7.75374ZM7.14377 11.031C7.10583 10.9178 7.06264 10.7962 7.0142 10.6699H7.2733C7.2249 10.7962 7.18167 10.9178 7.14377 11.031Z" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "0 0 15 15",
    };
  }
};

Gambling.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Gambling.defaultProps = { role: "img" };
