import { window } from "@swa-ui/browser";

import { handleRouteChange } from "./handleRouteChange";

const GLOBAL_MESSAGE_KEYS = {
  ROUTE_CHANGE: "ROUTE_CHANGE",
};

let messagesFromNativeApp;

export const WEBVIEW_MESSAGE_KEYS = {
  ADD_CHASE_SESSION: "ADD_CHASE_SESSION",
  ADD_OAUTH: "ADD_OAUTH",
  APPLE_PAY_AUTHORIZED: "APPLE_PAY_AUTHORIZED",
  AUTH_EVENT: "AUTH_EVENT",
  DEEP_LINK_CONTINUE: "DEEP_LINK_CONTINUE",
  EXTERNAL_PAYMENT_AUTHORIZED: "EXTERNAL_PAYMENT_AUTHORIZED",
  PAYPAL_AUTHORIZED: "PAYPAL_AUTHORIZED",
  REMOVE_OAUTH: "REMOVE_OAUTH",
  ROUTE_CHANGE: "ROUTE_CHANGE",
  SAVE_CHASE_OFFERS: "SAVE_CHASE_OFFERS",
};

export const useMessagesFromNativeApp = ({ additionalMessagesFromNativeApp, appId, history }) => {
  const messages = {
    ...messagesFromNativeApp,
    [GLOBAL_MESSAGE_KEYS.ROUTE_CHANGE]: (value, state) =>
      handleRouteChange(history, appId, value, state),
    ...additionalMessagesFromNativeApp,
  };

  const validMessageKeys = { ...GLOBAL_MESSAGE_KEYS, ...WEBVIEW_MESSAGE_KEYS };

  messagesFromNativeApp = Object.keys(messages).reduce(
    (validMessages, currentMessageKey) => ({
      ...validMessages,
      ...(!!validMessageKeys[currentMessageKey] && {
        [currentMessageKey]: messages[currentMessageKey],
      }),
    }),
    {}
  );

  return messagesFromNativeApp;
};

export const decodeMessage = (encodedMessage) => {
  try {
    const decodedMessage = window.atob(encodedMessage) || "{}";

    return JSON.parse(decodedMessage) || {};
  } catch (e) {
    return {};
  }
};
