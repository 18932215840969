import PropTypes from "prop-types";
import React from "react";

import { useDeviceInfo } from "@swa-ui/browser";

import { TextContent } from "../TextContent";
import styles from "./BannerLayout3.module.scss";
import {
  backgroundPropType,
  getBackgroundStyle,
  graphicPropType,
  textPropType,
} from "./BannerUtils";

/**
 * Renders Banner placement with layoutType as layout3.
 */
export const BannerLayout3 = (props) => {
  const { placement } = props;
  const { background, graphic, text } = placement ?? {};
  const { screenSize } = useDeviceInfo();

  return <div style={getBackgroundStyle(background, screenSize)}>{renderContent()}</div>;

  function renderContent() {
    return (
      <div className={styles.bannerContent}>
        <div>{renderGraphic()}</div>
        <div className={styles.bannerText}>{renderTextContent()}</div>
      </div>
    );
  }

  function renderGraphic() {
    const { altText, imagePath } = graphic || {};

    return imagePath && <img className={styles.bannerImage} alt={altText} src={imagePath} />;
  }

  function renderTextContent() {
    const { secondary } = text ?? {};

    return <TextContent {...secondary} />;
  }
};

BannerLayout3.propTypes = {
  /** Placement object for displayType banner. */
  placement: PropTypes.shape({
    /** Background image/color content for the banner. */
    background: backgroundPropType,

    /** Graphic image to be displayed in the banner placement. */
    graphic: graphicPropType,

    /** Text content displayed in the banner placement. */
    text: PropTypes.shape({
      secondary: textPropType,
    }),
  }),
};
