import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";

import { getFocusableItems, window } from "@swa-ui/browser";
import { classNames, getUniqueId } from "@swa-ui/string";

import globalRules from "../assets/styles/globalRules.module.scss";
import styles from "./focusContainer.module.scss";

const DELAY_FOR_ANIMATION = 300;
const FIRST_FOCUSABLE_ITEM = 1;
const LAST_FOCUSABLE_ITEM_FROM_END = 2;

/**
 * Component that "constraints" the tabOrder so that tabbing will not cause focus to go outside
 * FocusContainer's children.
 */
export const FocusContainer = (props) => {
  const {
    children,
    className,
    defaultFocusIndex,
    disableInitialFocus = false,
    doc,
    id,
    revealed,
  } = props;
  const uniqueId = useRef(id || getUniqueId("focusContainer-id"));
  const scopeSelector = `[id="${uniqueId.current}"]`;
  let timer;

  useEffect(() => {
    if (!disableInitialFocus && revealed) {
      timer = window.setTimeout(() => {
        const focusableItems = getFocusableItems(scopeSelector, doc);

        if (defaultFocusIndex) {
          focusableItems[defaultFocusIndex].focus();
        } else {
          setFocusToItemAfterFirstGuardRail();
        }
      }, DELAY_FOR_ANIMATION);
    }

    return () => {
      window.clearTimeout(timer);
    };
  }, [revealed]);

  return (
    <div className={classNames(className, styles.focusContainer)} id={uniqueId.current}>
      <div {...getGuardrailProps(handleFirstFocus)}>first</div>
      {children}
      <div {...getGuardrailProps(handleLastFocus)}>last</div>
    </div>
  );

  function getGuardrailProps(focusHandler) {
    return {
      "aria-hidden": props["aria-hidden"] || !revealed,
      className: globalRules.hiddenFromScreen,
      onFocus: focusHandler,
      tabIndex: "0",
    };
  }

  function handleFirstFocus(event) {
    const focusableItems = getFocusableItems(scopeSelector, doc);
    const itemCount = focusableItems?.length;

    if (itemCount && event.relatedTarget === focusableItems[FIRST_FOCUSABLE_ITEM]) {
      focusableItems[itemCount - LAST_FOCUSABLE_ITEM_FROM_END].focus();
    }
  }

  function handleLastFocus(event) {
    const focusableItems = getFocusableItems(scopeSelector, doc);
    const itemCount = focusableItems?.length;

    if (
      itemCount &&
      event.relatedTarget === focusableItems[itemCount - LAST_FOCUSABLE_ITEM_FROM_END]
    ) {
      setFocusToItemAfterFirstGuardRail();
    }
  }

  function setFocusToItemAfterFirstGuardRail() {
    const focusableItems = getFocusableItems(scopeSelector, doc);

    focusableItems[FIRST_FOCUSABLE_ITEM].focus();
  }
};

FocusContainer.propTypes = {
  /** Controls the aria-hidden attribute to be applied to the guardrail element and set the tab index. */
  "aria-hidden": PropTypes.bool,

  /** Content that will be rendered inside container. */
  children: PropTypes.node,

  /**
   * Additional classes for positioning the component. Given classes may only position this component
   * for layout purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Index of item to receive focus if disableInitialFocus is not set. */
  defaultFocusIndex: PropTypes.number,

  /**
   * FocusContainer will initially set to defaultFocusIndex or the first focusable item unless this
   * boolean is set.
   */
  disableInitialFocus: PropTypes.bool,

  /**
   * By default this will the global document object, but can be overridden to support iFrames. This
   * is used by Caption to get a reference to a DOM element.
   */
  doc: PropTypes.object,

  /** Prop tells FocusContainer to use focus constraint when reveal is set. */
  revealed: PropTypes.bool,
};
