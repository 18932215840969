import PropTypes from "prop-types";
import React, { useContext } from "react";

import { Accordion } from "@swa-ui/core";

import { ObjectVisual } from "../../ObjectVisual";

/**
 * ContextVisual is responsible for taking a context object from the ContextVisualizer and ContextVisualProvider
 * to display the context values.
 */
export const ContextVisual = (props) => {
  const {
    context: { context = {}, displayName, memoizedValues },
    revealed = false,
  } = props;
  const contextValues = useContext(context) ?? memoizedValues ?? {};

  return (
    displayName && (
      <Accordion {...getProps()}>
        <ObjectVisual showBraces={false} object={contextValues} />
      </Accordion>
    )
  );

  function getProps() {
    return {
      label: displayName,
      name: displayName,
      revealed,
    };
  }
};

ContextVisual.propTypes = {
  /** Context object provider by visualizer. */
  context: PropTypes.object,

  /** Boolean to control whether the Accordion is opened or closed. */
  revealed: PropTypes.bool,
};
