import PropTypes from "prop-types";
import React from "react";

export const DollarSlash = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path
        d="m8.5,14h-1.5v-1.03c-.79-.07-1.73-.36-2.5-.78l.55-1.91c.87.34,2.15.77,3.09.56.82-.18,1.03-.92.06-1.34-.82-.37-3.64-.76-3.64-3.11,0-.98.62-2.33,2.44-2.72v-1.17h1.5s0,1.08,0,1.08c.71.05,1.69.18,2.53.45-.08.37-.44,1.95-.44,1.95-.58-.2-1.52-.54-2.42-.49-1.11.06-1.2.88-.44,1.26,1.54.7,3.78,1.29,3.78,3.38,0,1.6-1.52,2.51-3,2.71v1.17Z"
        strokeWidth="0"
      />
      <line fill="none" x1=".78" y1="11.55" x2="14.78" y2="4.55" />
      <circle fill="none" cx="8" cy="8" r="7.5" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      style,
      viewBox: "0 0 16 16",
    };
  }
};

DollarSlash.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

DollarSlash.defaultProps = { role: "img" };
