/**
Merges two JavaScript objects or arrays.
* If the second parameter is an array, it concatenates the two arrays.
* If the second parameter is an object, it merges the two objects. If the same key exists in both objects,
* and the value of that key in both objects is also an object, then it recursively merges those nested objects.

@param {Object|Array} object1 - The first object or array to merge.
@param {Object|Array} object2 - The second object or array to merge.
@returns {Object|Array} - The merged object or array.
*/
export const merge = (object1, object2) =>
  Array.isArray(object2)
    ? [...new Set([...object1, ...object2])]
    : Object.entries(object2).reduce(
        (accumulatedObject, [object2Key, object2Value]) => {
          if (
            Object.keys(accumulatedObject).includes(object2Key) &&
            typeof object2Value === "object"
          ) {
            accumulatedObject[object2Key] = merge(accumulatedObject[object2Key], object2Value);
          } else {
            accumulatedObject[object2Key] = object2Value;
          }

          return accumulatedObject;
        },
        { ...object1 }
      );
