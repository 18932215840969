import React, { useContext } from "react";

import { ApplicationContext } from "../ApplicationContextProvider";

export const withApplicationContextId =
  (Component, identifierKey = "id") =>
  (props) => {
    const { id, depends, ...passThroughProps } = props;
    const context = useContext(ApplicationContext);

    passThroughProps[identifierKey] = [...depends]
      .sort()
      .reduce((key, item) => (context[item] ? `${key}_${context[item]}` : key), id);

    return <Component {...passThroughProps} />;
  };
