import { window } from "@swa-ui/browser";

// TODO: Remove this function once we're deploying to the mobile site and smaller breakpoints are disabled on www.
const getMobileOrigin = () => {
  const { origin } = window?.location ?? { origin: "" };
  let baseMobileUrl = "https://mobile.southwest.com";

  if (origin.includes("southwest.com")) {
    baseMobileUrl = origin.replace(/http(s)?:\/\/[^.]*/, "https://mobile");
  }

  return baseMobileUrl;
};

const getWwwOrigin = () => {
  const { origin } = window?.location ?? { origin: "" };
  let baseMobileUrl = "https://www.southwest.com";

  if (origin.includes("southwest.com")) {
    baseMobileUrl = origin.replace(/http(s)?:\/\/[^.]*/, "https://southwest");
  }

  return baseMobileUrl;
};

const getMobileHref = (link) => {
  const { origin } = window?.location ?? { origin: "" };
  let href = link.target || link.linkTarget || link.href || link.url;

  if (href) {
    const separator = href.includes("?") ? "&" : "?";

    // TODO: Remove mapping once we're deploying to the mobile site and smaller breakpoints are disabled on www.
    // If we're on the mobile site, no extra mapping is required. Extra mapping is only required when
    // we're running the smaller mobile breakpoints on www.southwest.com or in a mobile environment.
    if (!/mobile\.southwest\.com/.test(origin) && !/swabiz/.test(origin)) {
      // If we have a full href with origin and protocol, make www.southwest.com links relative and
      // make hard coded mobile links work in any environment
      if (/^https/.test(href)) {
        if (/mobile/.test(origin)) {
          href = href.replace(/http(s)?[:]\/\/(mobile\.)?southwest\.com\/?/, "/");
          href = href.replace(/http(s)?[:]\/\/www\.southwest\.com\/?/, `${getWwwOrigin()}/`);
        } else if (/southwest\./.test(origin)) {
          href = href.replace(/http(s)?[:]\/\/(www\.)?southwest\.com\/?/, "/");
          href = href.replace(/http(s)?[:]\/\/mobile\.southwest\.com\/?/, `${getMobileOrigin()}/`);
        }
      } else if (!/mobile/.test(origin) && /^\//.test(href)) {
        // Currently, mobile content assumes you are on the mobile site. Since we are running only on www,
        // relative path hrefs should be prefixed with the appropriate mobile domain for the environment.
        href = getMobileOrigin() + href;
      }
    }

    if (link.clickCode) {
      href += `${separator}clk=${link.clickCode}`;
    }
  }

  return href;
};

export default getMobileHref;
