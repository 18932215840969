import PropTypes from "prop-types";
import { useContext } from "react";

import { PlacementsContext } from "../PlacementsContext";

/**
 * usePlacementIds retrieves the specified placement IDs using the ID regex pattern.
 */

export const usePlacementIds = (idPattern) => {
  const { placements } = useContext(PlacementsContext);
  const placementIds = Object.keys(placements);

  return placementIds.filter((id) => idPattern.test(id));
};

usePlacementIds.propTypes = {
  /**
   * Regex pattern used to retrieve an array of placement IDs.
   */
  idPattern: PropTypes.string.isRequired,
};
