import PropTypes from "prop-types";
import React, { createContext, Suspense, useState } from "react";

export const ContentLoadingContext = createContext();

/**
 * ContentLoadingProvider uses React Suspense to trigger a loading component while awaiting data.
 */
export const ContentLoadingProvider = (props) => {
  const { children, fallback } = props;
  const [loadingComponent, setLoadingComponent] = useState(fallback);

  return (
    <ContentLoadingContext.Provider value={getContextValue()}>
      <Suspense fallback={loadingComponent}>{children}</Suspense>
    </ContentLoadingContext.Provider>
  );

  function getContextValue() {
    return {
      setLoadingComponent,
    };
  }
};

ContentLoadingProvider.propTypes = {
  /** Content to be rendered when not loading in suspense. */
  children: PropTypes.node.isRequired,

  /** Replace children with this optional component. Can also use `setLoadingComponent` via context. */
  fallback: PropTypes.node,
};
ContentLoadingContext.displayName = "ContentLoadingProvider";
