/**
 * getValue retrieves a value from an object, or returns a default value if it does not exist.
 *
 * @param {object} object the object to retrieve the value from
 * @param {string|array} path either a string or array of instructions on how to traverse the object structure
 * @param {*} defaultValue value returned if the value is null or undefined within the object.
 */

export const getValue = (object, path, defaultValue) => {
  let value;

  if (typeof object === "object" && (typeof path === "string" || Array.isArray(path))) {
    const paths = String.prototype.split.call(path, /[,[\].]+?/).filter(Boolean);

    value = paths.reduce((currentValue, key) => currentValue?.[key], object);
  }

  return value ?? defaultValue;
};
