import PropTypes from "prop-types";
import React from "react";

import { Link } from "@swa-ui/core";
import i18n from "@swa-ui/locale";

import styles from "./BlockPlacement.module.scss";

/**
 * BlockPlacement renders block-placement type.
 */

export const BlockPlacement = (props) => {
  const {
    placement: { backgroundImage, backgroundImageAltText, linkType, target },
  } = props;

  return (
    <Link {...getLinkProps()}>
      <img {...getImageProps()} />
    </Link>
  );

  function getLinkProps() {
    return {
      className: styles.image,
      href: target,
      newWindow: linkType === "browser",
      newWindowDescription:
        linkType === "browser" ? i18n("BlockPlacement__OPENS_NEW_WINDOW") : undefined,
    };
  }

  function getImageProps() {
    return {
      alt: backgroundImageAltText,
      className: styles.image,
      src: backgroundImage,
    };
  }
};

BlockPlacement.propTypes = {
  /** Object that contains image and link props. */
  placement: PropTypes.shape({
    /** Defines the complete file path and file name without extension. */
    backgroundImage: PropTypes.string,

    /** Text to provide accessibility description for this img element. */
    backgroundImageAltText: PropTypes.string,

    /** If linkType is "app" or "webview" link should open in same tab, if "browser" link should open in external tab. */
    linkType: PropTypes.oneOf(["app", "browser", "webview"]),

    /** Anchor tag hypertext reference (e.g., the link path or URL). */
    target: PropTypes.string,
  }),
};
