import PropTypes from "prop-types";
import React, { Fragment } from "react";

import { MEDIUM, SMALL, useDeviceInfo } from "@swa-ui/browser";
import { Grid } from "@swa-ui/core";
import { classNames } from "@swa-ui/string";

import { applyOverrides } from "../applyOverrides";
import { CallToAction } from "../CallToAction";
import { HtmlValue } from "../HtmlValue";
import styles from "./BannerLayout1.module.scss";
import {
  backgroundPropType,
  callToActionPropType,
  getBackgroundStyle,
  graphicPropType,
  renderText,
  textPropType,
} from "./BannerUtils";

/**
 * Renders Banner placement with layoutType as layout1.
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const BannerLayout1 = (props) => {
  const {
    additionalProps: { fullWidth = true },
    placement,
    placementData,
  } = props;
  const { background, callToAction, disclaimer, graphic, text } = placement ?? {};
  const { screenSize } = useDeviceInfo();
  const { align } = applyOverrides(graphic, screenSize);

  return (
    <div className={styles.container} style={getBackgroundStyle(background, screenSize)}>
      {renderContent()}
    </div>
  );

  function renderContent() {
    const WrapperComponent = fullWidth ? Grid : Fragment;

    return (
      <WrapperComponent className={styles.layout}>
        <div className={styles.content}>
          <div className={getContentFirstClass()}>
            {(align === "left" || align === "top") && renderGraphic()}
            {renderTextContent()}
          </div>
          <div className={styles.contentSecond}>
            {align === "right" && screenSize !== MEDIUM && renderGraphic()}
            {renderCallToAction()}
          </div>
          {(screenSize === SMALL || screenSize === MEDIUM) && renderDisclaimer()}
        </div>
      </WrapperComponent>
    );
  }

  function renderTextContent() {
    const { callout, primary, secondary } = text || {};

    return (
      <>
        <div className={styles.textContainer}>
          {renderText(callout)}
          <div className={styles.textHero}>
            {renderText(primary)}
            {renderText(secondary)}
          </div>
          {screenSize !== SMALL && screenSize !== MEDIUM && renderDisclaimer()}
        </div>
        {screenSize === MEDIUM && align === "right" && renderGraphic()}
      </>
    );
  }

  function renderGraphic() {
    const { altText, display, imagePath } = applyOverrides(graphic, screenSize);

    return (
      display !== "none" &&
      imagePath && <img alt={altText} className={styles.image} src={imagePath} />
    );
  }

  function renderCallToAction() {
    return (
      callToAction && (
        <div className={styles.callToAction}>
          <CallToAction {...getCallToActionProps()} />
        </div>
      )
    );
  }

  function getCallToActionProps() {
    return {
      ...(screenSize === SMALL && { additionalProps: { fullWidth: true } }),
      ...callToAction,
      ...placementData,
    };
  }

  function renderDisclaimer() {
    return disclaimer && <HtmlValue htmlValue={disclaimer?.value} />;
  }

  function getContentFirstClass() {
    return classNames({
      [styles.contentFirst]: !(screenSize === MEDIUM && align === "right"),
      [styles.contentFirstAlternate]: screenSize === MEDIUM && align === "right",
    });
  }
};

BannerLayout1.propTypes = {
  /** Placement object for displayType banner. */
  placement: PropTypes.shape({
    /** Background image/color content for the banner. */
    background: backgroundPropType,

    /** Call To Action button / Link to be displayed in the banner placement. */
    callToAction: callToActionPropType,

    /** Disclaimer text to be displayed in the banner placement. */
    disclaimer: PropTypes.shape({
      value: PropTypes.string,
    }),

    /** Graphic image to be displayed in the banner placement. */
    graphic: graphicPropType,

    /** Text content displayed in the banner placement. */
    text: PropTypes.shape({
      callout: textPropType,
      primary: textPropType,
      secondary: textPropType,
    }),
  }),

  placementData: PropTypes.shape({
    /** The dynamic value that will be used as an event description for analytics. */
    analyticsValue: PropTypes.string,
  }),
};
