import PropTypes from "prop-types";
import React from "react";

export const Linkedin = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path
        strokeWidth={0}
        d="M16,2A14,14,0,1,0,30,16,14,14,0,0,0,16,2ZM12.64,22.19H9.81V13.12h2.83ZM11.23,11.88a1.63,1.63,0,1,1,1.63-1.63A1.63,1.63,0,0,1,11.23,11.88Zm12,10.31H20.37V17.78c0-1.05,0-2.4-1.46-2.4s-1.69,1.14-1.69,2.32v4.49H14.4V13.12h2.71v1.24h0a3,3,0,0,1,2.67-1.46c2.85,0,3.38,1.87,3.38,4.31Z"
      />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      style,
      viewBox: "0 0 32 32",
    };
  }
};

Linkedin.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Linkedin.defaultProps = {
  role: "img",
};
