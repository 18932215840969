import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";

import { fireTrack } from "@swa-ui/analytics";
import { MessageContext } from "@swa-ui/application";
import { Badge, SelectList } from "@swa-ui/core";
import { RecentSearchContext } from "@swa-ui/form";
import i18n from "@swa-ui/locale";

import { AIR, CAR } from "../defines/itineraryType";
import { ItineraryDate } from "../ItineraryDate";
import styles from "./ItineraryRecentSearch.module.scss";

const DELAY_FOR_BROWSER = 500;
const SEPARATOR = "-";
const SEPARATOR_WITH_SPACES = " - ";

/**
 * ItineraryRecentSearch presents selectable list of recent search sets that can be chosen to
 * facilitate search form entry.
 */

export const ItineraryRecentSearch = (props) => {
  const { alignment, className, list, onChange, type } = props;
  const {
    clearRecentSearches,
    isRemembering,
    startRememberingRecentSearches,
    stopRememberingRecentSearches,
  } = useContext(RecentSearchContext);
  const { displayMessage } = useContext(MessageContext);
  const [currentList, setCurrentList] = useState(list);
  const [revealed, setRevealed] = useState(false);
  const [value, setValue] = useState();

  useEffect(() => {
    if (isRemembering(type)) {
      setCurrentList(list);
    }
  }, [list]);

  return <SelectList {...getProps()} />;

  function renderRecentSearchOption(option) {
    return {
      displayValue: renderStationDetails(option),
      label: renderLabel(option),
      value: renderStationDetails(option, false),
    };
  }

  function renderStationDetails(option, includeSpace = true) {
    const { station1, station2, station3 } = option;
    const separator = includeSpace ? SEPARATOR_WITH_SPACES : SEPARATOR;
    let content = `${station1}${separator}${station2}`;

    if (station3) {
      content = `${content}${separator}${station3}`;
    }

    return content;
  }

  function renderLabel(option) {
    const { departureDate, returnDate } = option;

    return (
      <div className={styles.labelContainer}>
        <div className={styles.itineraryOriginDestination}>{renderStationDetails(option)}</div>
        <div className={styles.itineraryDates}>
          <ItineraryDate date={departureDate} format={i18n("ItineraryRecentSearch__DATE_FORMAT")} />
          {returnDate && renderReturnDate(returnDate)}
        </div>
      </div>
    );
  }

  function renderReturnDate(returnDate) {
    return (
      <>
        &nbsp;{SEPARATOR}&nbsp;
        <ItineraryDate date={returnDate} format={i18n("ItineraryRecentSearch__DATE_FORMAT")} />
      </>
    );
  }

  function renderEmpty() {
    return (
      <div className={styles.emptyContainer}>
        <Badge {...getBadgeProps()}>
          {isRemembering(type)
            ? i18n("ItineraryRecentSearch__EMPTY")
            : i18n("ItineraryRecentSearch__DISABLED")}
        </Badge>
      </div>
    );
  }

  function getProps() {
    return {
      alignment,
      "aria-label": i18n("ItineraryRecentSearch__ARIA_LABEL"),
      className,
      emptyContent: renderEmpty(),
      emptyContentDisplayValue: i18n("ItineraryRecentSearch__EMPTY"),
      heading: <div>{i18n("ItineraryRecentSearch__HEADING")}</div>,
      items: currentList.map(renderRecentSearchOption),
      onChange: handleChange,
      onRevealChange: handleRevealChange,
      options: getOptions(),
      revealed,
      value,
    };
  }

  function getBadgeProps() {
    return {
      center: true,
      fullWidth: true,
      padding: "xlarge",
      styleType: "tertiary",
      type: "capsule",
    };
  }

  function handleChange(event, index) {
    setValue(event.target.value);
    onChange(index);
  }

  function handleRevealChange(open) {
    setRevealed(open);

    if (open && type) {
      fireTrack("squid", { page_description: `responsive drop down:recent search ${type}` });
    }
  }

  function handleRemember() {
    if (isRemembering(type)) {
      stopRememberingRecentSearches([type]);
      setCurrentList([]);
      displayMessage(
        "success",
        i18n("ItineraryRecentSearch__SUCCESS_MESSAGE"),
        i18n("ItineraryRecentSearch__TITLE_SUCCESS_MESSAGE"),
        "center-to-window",
        handleMessageClose
      );
      fireTrack("squid", { page_description: "widget:stop remembering" });
    } else {
      startRememberingRecentSearches([type]);
      fireTrack("squid", { page_description: "widget:enable recent searches" });
    }
  }

  function handleMessageClose() {
    window.setTimeout(() => {
      setRevealed(false);
    }, DELAY_FOR_BROWSER);
  }

  function handleClear() {
    clearRecentSearches([type]);
    setCurrentList([]);
    fireTrack("squid", { page_description: "widget:clear searches" });
  }

  function getOptions() {
    const options = [
      {
        componentProps: {
          children: isRemembering(type)
            ? i18n("ItineraryRecentSearch__STOP_REMEMBERING")
            : i18n("ItineraryRecentSearch__ENABLE"),
          emphasis: false,
          onClick: handleRemember,
          showUnderline: false,
        },
      },
    ];

    if (currentList.length) {
      options.push({
        componentProps: {
          children: i18n("ItineraryRecentSearch__CLEAR"),
          emphasis: false,
          onClick: handleClear,
          showUnderline: false,
        },
      });
    }

    return options;
  }
};

ItineraryRecentSearch.propTypes = {
  /** Determines the alignment of the flyout relative to the trigger. */
  alignment: PropTypes.oneOf(["left", "right"]),

  /** Classes for positioning the component. */
  className: PropTypes.string,

  /**
   * List items to be displayed. Airports should be three-letter codes. Dates should be formatted
   * like so: 02/29/2024 or 2024-2-29.
   */
  list: PropTypes.arrayOf(
    PropTypes.shape({
      departureDate: PropTypes.string.isRequired,
      returnDate: PropTypes.string,
      station1: PropTypes.string.isRequired,
      station2: PropTypes.string.isRequired,
      station3: PropTypes.string,
    })
  ),

  /**
   * Callback function to learn when a selection is made in the list. The index of the selected
   * item will be returned.
   */
  onChange: PropTypes.func.isRequired,

  /** Type of recent searches. */
  type: PropTypes.oneOf([AIR, CAR]).isRequired,
};

ItineraryRecentSearch.defaultProps = {
  alignment: "right",
  list: [],
};
