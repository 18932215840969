import PropTypes from "prop-types";
import React, { useContext, useEffect, useMemo, useRef } from "react";

import { window } from "@swa-ui/browser";
import { CustomerLightTheme } from "@swa-ui/design-tokens";
import { classNames, getUniqueId } from "@swa-ui/string";

import { AriaLive } from "../AriaLive";
import { BackgroundVeil } from "../BackgroundVeil";
import { ButtonGroup, buttonGroupPropTypes } from "../ButtonGroup";
import { Caption } from "../Caption";
import { keyCodes } from "../defines/keyCodes";
import { DialogHeading } from "../DialogHeading";
import { DialogSubheading } from "../DialogSubheading";
import { OverlayContext, OverlayProvider } from "../OverlayProvider";
import { PortalRoot } from "../PortalRoot";
import styles from "./Dialog.module.scss";

/**
 * Displays Modal content centered in the window. Optionally a header can be rendered. Options must be given and when an
 * option is clicked, the application is responsible for closing the dialog (setting revealed prop).
 */

export const DialogContext = React.createContext();
export const Dialog = (props) => {
  const {
    buttonGroupProps,
    children,
    className,
    closeOnBackgroundClick,
    contentClassName,
    dialogRef,
    footer,
    fullScreen,
    fullWidth,
    heading,
    onClick,
    onClose,
    portal,
    revealed,
    showBackgroundVeil,
    showClose,
    subheading,
    width,
  } = props;
  const PortalWrapper = useMemo(
    () =>
      portal
        ? (portalProps) => (
            <PortalRoot>
              <CustomerLightTheme>{portalProps.children}</CustomerLightTheme>
            </PortalRoot>
          )
        : React.Fragment,
    [portal]
  );
  const overlayContextConfiguration = useContext(OverlayContext) ?? {};
  const { activeDialog, setActiveDialog } = overlayContextConfiguration;
  const describedById = useRef(getUniqueId("dialogDescribeById"));
  const dialogId = useRef(getUniqueId("dialog-id"));
  const focusRef = useRef(null);
  const labelledById = useRef(getUniqueId("dialogLabelledById"));

  useEffect(() => {
    if (revealed) {
      setActiveDialog(dialogId.current);
    } else {
      setActiveDialog(undefined);
    }
  }, [revealed]);

  return (
    <PortalWrapper>
      <OverlayProvider value={{ ...overlayContextConfiguration }}>
        {showBackgroundVeil && dialogId.current === activeDialog && (
          <BackgroundVeil {...getBackgroundVeilProps()} />
        )}
        <DialogContext.Provider
          value={{
            describedById: describedById.current,
            focusRef,
            labelledById: labelledById.current,
          }}
        >
          <div className={getClass()}>
            <Caption {...getCaptionProps()} />
          </div>
        </DialogContext.Provider>
      </OverlayProvider>
    </PortalWrapper>
  );

  function renderContent() {
    return (
      <div className={classNames(styles.dialog, { [styles.fullWidth]: fullWidth })}>
        {heading && renderHeading()}
        {subheading && renderSubheading()}
        {children}
        {buttonGroupProps?.buttonList?.length && renderOptions()}
        {footer && renderFooter()}
      </div>
    );
  }

  function renderHeading() {
    return (
      <DialogHeading className={styles.heading} styleLevel={2}>
        {heading}
      </DialogHeading>
    );
  }

  function renderSubheading() {
    return <DialogSubheading>{subheading}</DialogSubheading>;
  }

  function renderOptions() {
    return (
      <div className={styles.options}>
        <ButtonGroup {...getButtonGroupProps()} />
      </div>
    );
  }

  function renderFooter() {
    return <div className={styles.footer}>{footer}</div>;
  }

  function getBackgroundVeilProps() {
    return {
      on: revealed,
      onClick: closeOnBackgroundClick ? onClose : undefined,
      onKeyDown: handleKeyDown,
    };
  }

  function getCaptionProps() {
    return {
      adjoiningContent: (
        <div {...getContentProps()}>
          <AriaLive>{getAriaLiveContent()}</AriaLive>
          {renderContent()}
        </div>
      ),
      "aria-describedby": describedById.current,
      "aria-label": props["aria-label"],
      "aria-label-close": props["aria-label-close"],
      "aria-labelledby": labelledById.current,
      captionRef: dialogRef,
      className: classNames(className, styles.modal),
      disableInitialFocus: focusRef.current !== null,
      location: getLocation(),
      onClose,
      showClose,
      showPointer: false,
    };
  }

  function getLocation() {
    let location;

    if (revealed && dialogId.current === activeDialog) {
      location = fullScreen ? "full-screen" : "center-vertically";
    } else {
      location = "hidden";
    }

    return location;
  }

  function getContentProps() {
    return {
      className: classNames(styles.content, contentClassName, {
        [styles.hidden]: !revealed,
      }),
    };
  }

  function getButtonGroupProps() {
    return {
      ...buttonGroupProps,
      descriptionLeft: <div className={styles.descriptionLeft} />,
      ensureVisible: false,
      moreContentIndicator: false,
      onClick,
      selectedIndex: -1,
      spaceBetween: "large",
    };
  }

  function getClass() {
    return classNames(className, {
      [styles.medium]: width === "medium",
      [styles.modalContainer]: !revealed,
      [styles.large]: width === "large" || width === "wide",
      [styles.small]: width === "small" || width === "narrow",
      [styles.visible]: revealed,
      [styles.xlarge]: width === "xlarge",
    });
  }

  function handleKeyDown(event) {
    if (event.key === keyCodes.KEY_SPACE && event.target === window.document.body) {
      event.preventDefault();
    }
  }

  function getAriaLiveContent() {
    return props["aria-live"];
  }
};

Dialog.propTypes = {
  /** aria-label text to provide accessibility description for Dialog. */
  "aria-label": PropTypes.string,

  /** aria-label text to provide additional accessibility description of button element. */
  "aria-label-close": PropTypes.string,

  /** aria-live text to provide additional accessibility description for Dialog. */
  "aria-live": PropTypes.string,

  /** Props to be passed directly to ButtonGroup. See that component for more info. */
  buttonGroupProps: PropTypes.shape(buttonGroupPropTypes),

  /** Content for "main" element. */
  children: PropTypes.node.isRequired,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Allow modal to be dismissed when background veil is clicked. */
  closeOnBackgroundClick: PropTypes.bool,

  /**
   * ClassName that can be applied to the Dialog's content. This is different than the className property which is
   * applied to the outer most element for positioning purposes.
   */
  contentClassName: PropTypes.string,

  /** Ref passed to allow useDialog to close Dialog on clicking outside of component. */
  dialogRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.object })]),

  /** Footer to be rendered below buttonGroup. */
  footer: PropTypes.node,

  /** Indicates if Dialog should be position near the top of window with appropriate margins on left and right. */
  fullScreen: PropTypes.bool,

  /** Determines if the children will spread to the entire width of its container. */
  fullWidth: PropTypes.bool,

  /** Option title for Dialog */
  heading: PropTypes.node,

  /** Callback to receive notification of an option click. */
  onClick: PropTypes.func,

  /** Callback to receive notification of click to dismiss Dialog. */
  onClose: PropTypes.func,

  /** Toggle whether to use the new React Portal implementation. */
  portal: PropTypes.bool,

  /** Indicates if Dialog content is visible. */
  revealed: PropTypes.bool,

  /** Name used for aria role. */
  role: PropTypes.string,

  /**
   * When showBackgroundVeil is true, a mostly opaque background will be displayed behind the Toast
   * overlay to hide the page contents.
   */
  showBackgroundVeil: PropTypes.bool,

  /** Present close button to dismiss dialog. */
  showClose: PropTypes.bool,

  /**
   * Optional subheading for Dialog. This subheading will receive focus when the dialog is
   * first opened. */
  subheading: PropTypes.node,

  /**
   * Modals will be placed on a grid with specific widths matching the grid's column definitions. The columns occupied
   * will vary dependant on the device. The narrow prop will define the number of columns the modal will span.
   */
  width: PropTypes.oneOf(["medium", "large", "narrow", "small", "wide", "xlarge"]),
};

Dialog.defaultProps = {
  closeOnBackgroundClick: true,
  portal: true,
  revealed: false,
  showBackgroundVeil: true,
  width: "medium",
};
