import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";

import { fireTrack } from "@swa-ui/analytics";
import { MessageContext } from "@swa-ui/application";
import { getResponseErrorKey } from "@swa-ui/fetch";

import { MfaContext } from "../../MfaProvider";
import { useUserInfo } from "../../UserInfoProvider";
import { MfaContactSelectForm } from "../MfaContactSelectForm";
import { MfaEnterPasscodeForm } from "../MfaEnterPasscodeForm";
import { MfaSuccess } from "../MfaSuccess";
import { useMfaServices } from "./useMfaServices";

const MFA_API_ERROR_MAPPING_URL = "/api/mfa/v1/mfa/";
const MFA_STEP_CONTACT_SELECT = "MFA_STEP_CONTACT_SELECT";
const MFA_STEP_ENTER_PASSCODE = "MFA_STEP_ENTER_PASSCODE";
const MFA_STEP_SUCCESS = "MFA_STEP_SUCCESS";

/**
 * MfaAuthorizer is a component that handles the MFA authorization process. It will prompt the
 * user to select a contact method, enter a passcode, and then display a success message.
 * Once the user is authorized, it will call the onAuthorized callback.
 */
export const MfaAuthorizer = (props) => {
  const { mfaServices, onAuthorized, onError } = props;
  const [currentStep, setCurrentStep] = useState(MFA_STEP_CONTACT_SELECT);
  const [selectedContact, setSelectedContact] = useState();
  const [contacts, setContacts] = useState();
  const { getMfaDevices, loading, resendMfaOtp, sendMfaOtp, restartMfa, validateMfaOtp } =
    mfaServices ?? useMfaServices();
  const [validationErrorKey, setValidationErrorKey] = useState();
  const [passwordResendSuccess, setPasswordResendSuccess] = useState(false);
  const { displayServiceNotifications } = useContext(MessageContext);
  const { getCustomerInfo } = useUserInfo();
  const { handleMfaAuthenticated } = useContext(MfaContext);
  const { firstName, preferredName } = getCustomerInfo() ?? {};
  const name = preferredName || firstName;

  useEffect(() => {
    getMfaDevices().then((devicesResponse) => {
      handleResponse(devicesResponse, (data) => {
        setContacts(data.devices);
      });
    });

    if (currentStep === MFA_STEP_CONTACT_SELECT) {
      fireTrack("squid", { page_description: "modal:mfa check point" });
    }
  }, []);

  return (
    <>
      {currentStep === MFA_STEP_CONTACT_SELECT && (
        <MfaContactSelectForm
          contacts={contacts}
          loading={loading}
          onSubmit={handleContactSelectSubmit}
        />
      )}
      {currentStep === MFA_STEP_ENTER_PASSCODE && (
        <MfaEnterPasscodeForm
          contact={selectedContact}
          loading={loading}
          onResend={handleResend}
          onRestart={handleRestart}
          onSubmit={handleEnterPasscodeSubmit}
          passwordResendSuccess={passwordResendSuccess}
          validationErrorKey={validationErrorKey}
        />
      )}
      {currentStep === MFA_STEP_SUCCESS && (
        <MfaSuccess loading={loading} onContinue={handleContinue} name={name} />
      )}
    </>
  );

  async function handleContactSelectSubmit(formData) {
    setSelectedContact(formData.contact);
    fireTrack("squid", {
      page_description: `button:mfa verification type, verificationmethod:${formData?.contact?.deviceType?.toLowerCase()}`,
    });

    const response = await sendMfaOtp(formData.contact.deviceType);

    handleResponse(response, () => {
      setCurrentStep(MFA_STEP_ENTER_PASSCODE);
    });
  }

  async function handleResend() {
    setPasswordResendSuccess(false);

    const response = await resendMfaOtp();

    fireTrack("squid", { page_description: "button:mfa verification request a new passcode" });

    handleResponse(response, () => {
      setPasswordResendSuccess(true);
    });
  }

  async function handleRestart() {
    setPasswordResendSuccess(false);

    const response = await restartMfa();

    fireTrack("squid", { page_description: "button:mfa choose other verification" });

    handleResponse(response, () => {
      setCurrentStep(MFA_STEP_CONTACT_SELECT);
    });
  }

  async function handleEnterPasscodeSubmit(passcodeFormData) {
    setValidationErrorKey(undefined);

    const response = await validateMfaOtp(passcodeFormData.passcode);

    handleResponse(
      response,
      async () => {
        await handleMfaAuthenticated();
        setCurrentStep(MFA_STEP_SUCCESS);
      },
      (callStatus) => {
        const errorKey = getErrorKey(callStatus);

        fireTrack("squid", { page_description: "modal:mfa wrong code" });

        if (errorKey && errorKey.includes?.("FIELD_ERROR")) {
          setValidationErrorKey(errorKey);
        } else {
          handleError(callStatus);
        }
      }
    );
  }

  function handleResponse(response, successCallback = undefined, errorCallback = undefined) {
    const { callStatus, ...data } = response;

    if (callStatus.ok) {
      successCallback && successCallback(data);
    } else {
      errorCallback ? errorCallback(callStatus) : handleError(callStatus);
    }
  }

  function getErrorKey(callStatus) {
    return getResponseErrorKey(getErrorDetails(callStatus));
  }

  function handleError(callStatus) {
    if (!callStatus.aborted) {
      displayServiceNotifications(getErrorDetails(callStatus));
      onError && onError(callStatus.errorCode);
    }
  }

  function getErrorDetails(callStatus) {
    return {
      data: {
        code: callStatus.errorCode,
        requestId: callStatus.serviceResponse.data.requestId,
      },
      url: MFA_API_ERROR_MAPPING_URL,
    };
  }

  function handleContinue() {
    onAuthorized();
  }
};

MfaAuthorizer.propTypes = {
  /**
   * Object containing MFA services.
   */
  mfaServices: PropTypes.shape({
    getMfaDevices: PropTypes.func,
    resendMfaOtp: PropTypes.func,
    restartMfaOtp: PropTypes.func,
    sendMfaOtp: PropTypes.func,
    validateMfaOtp: PropTypes.func,
  }),

  /**
   * Function to call when the user is authorized
   */
  onAuthorized: PropTypes.func,
};
