import { yupResolver } from "@hookform/resolvers/yup";
import React, { useContext } from "react";

import { useAppSetting } from "@swa-ui/application";
import { window } from "@swa-ui/browser";
import { VacationsLightTheme } from "@swa-ui/design-tokens";
import { encryptData, EncryptionContext } from "@swa-ui/encryption";
import { Form, SubmitFormField, TextFormField, useForm } from "@swa-ui/form";
import i18n from "@swa-ui/locale";
import { classNames } from "@swa-ui/string";

import styles from "./ManageMyVacationsForm.module.scss";
import { manageMyVacationsSchema } from "./ManageMyVacationsSchema";

export const ManageMyVacationsForm = () => {
  const formMethods = useForm({ resolver: yupResolver(manageMyVacationsSchema()) });
  const { encrypt } = useContext(EncryptionContext);
  const basePathOJTWebappManageMyBooking = useAppSetting("basePathOJTWebappManageMyBooking");

  return (
    <VacationsLightTheme mode="light">
      <div className={getContainerDescriptionClass()}>
        {i18n("ManageMyVacations__CAPTION_DESCRIPTION")}
      </div>
      <Form methods={formMethods} onSubmit={handleSubmit}>
        <TextFormField {...getFirstNameProps()} />
        <TextFormField {...getLastNameProps()} />
        <TextFormField {...getConfirmationNumberProps()} />
        <SubmitFormField {...getSubmitButtonProps()}>
          {i18n("ManageMyVacations__FORM_SUBMIT_BUTTON_TEXT")}
        </SubmitFormField>
      </Form>
    </VacationsLightTheme>
  );

  function getContainerDescriptionClass() {
    return classNames({
      [styles.formDescription]: true,
    });
  }

  function getFirstNameProps() {
    return getFormFieldProps({
      label: i18n("ManageMyVacations__FORM_FIRSTNAME_LABEL"),
      maxLength: 30,
      name: "firstName",
    });
  }

  function getLastNameProps() {
    return getFormFieldProps({
      label: i18n("ManageMyVacations__FORM_LASTNAME_LABEL"),
      maxLength: 30,
      name: "lastName",
    });
  }

  function getConfirmationNumberProps() {
    return getFormFieldProps({
      caption: i18n("ManageMyVacations__FORM_CONFIRMATION_NUMBER_HELPER_TEXT"),
      label: i18n("ManageMyVacations__FORM_CONFIRMATION_NUMBER_LABEL"),
      maxLength: 7,
      name: "confirmationNumber",
    });
  }

  function getSubmitButtonProps() {
    return {
      className: styles.submitButton,
      componentProps: {
        fullWidth: true,
      },
      name: "submitManageMyVacations",
    };
  }

  function getFormFieldProps(formProps) {
    const { caption, label, maxLength, name } = formProps;

    return {
      caption: caption || "",
      className: styles.formField,
      componentProps: {
        maxLength: maxLength,
      },
      label: label,
      name: name,
    };
  }

  // TODO - Possibly move this logic to the new swa-ui-vacations package. PEG-349
  async function handleSubmit(formData) {
    const formDataToEncrypt = {
      bookingReference: formData.confirmationNumber,
      firstName: formData.firstName,
      surname: formData.lastName,
    };

    const url = await encryptData(
      encrypt,
      formDataToEncrypt,
      basePathOJTWebappManageMyBooking,
      "ManageMyVacations",
      "vacationManageMyBookings",
      "booking"
    );

    window.location.assign(url);
  }
};
