import PropTypes from "prop-types";
import React from "react";

import { getBootstrapData } from "@swa-ui/bootstrap";
import { CardGrid, Link, LoadingDepiction } from "@swa-ui/core";
import {
  CheckboxFormField,
  Form,
  RadioButtonFormField,
  SubmitFormField,
  useForm,
  yupResolver,
} from "@swa-ui/form";
import i18n from "@swa-ui/locale";
import { classNames } from "@swa-ui/string";

import { MfaHeading } from "../MfaHeading";
import styles from "./MfaContactSelectForm.module.scss";
import { getMfaContactSelectFormSchema } from "./MfaContactSelectForm.schema";

/**
 * MfaContactSelectForm allows the user to select contact method they would like to use for MFA.
 */
export const MfaContactSelectForm = (props) => {
  const { contacts, loading, onSubmit } = props;
  const formMethods = useForm({ resolver: yupResolver(getMfaContactSelectFormSchema()) });
  const { MFA_HELP, MFA_TERMS_AND_CONDITIONS } = getBootstrapData("urls");

  return (
    <CardGrid className={styles.grid}>
      <MfaHeading prompt={i18n("MfaContactSelectForm__PROMPT")} key="contact-select-heading" />
      <Form
        methods={formMethods}
        insideGrid
        onSubmit={(formData) => {
          onSubmit({ ...formData, contact: JSON.parse(formData.contact) });
        }}
        noValidate
      >
        {!contacts && (
          <div className={styles.fullWidth}>
            <LoadingDepiction template={getContactLoadingTemplate()} />
          </div>
        )}
        {contacts && <RadioButtonFormField {...getContactProps()} />}
        <CheckboxFormField {...getTermsAgreeProps()} />
        <Link {...getNeedHelpLinkProps()}>{i18n("MfaContactSelectForm__NEED_HELP")}</Link>
        <SubmitFormField {...getSubmitProps()}>
          {i18n("MfaContactSelectForm__SUBMIT")}
        </SubmitFormField>
      </Form>
    </CardGrid>
  );

  function getContactLoadingTemplate() {
    return [...Array(2)]
      .map(() => [
        {
          bottomMargin: "xsmall",
          columnDefinitions: [{ gridColumn: "1/-1" }],
          height: "xsmall",
          numberOfColumns: 2,
        },
        {
          bottomMargin: "large",
          columnDefinitions: [{ gridColumn: "1/2" }],
          height: "xsmall",
          numberOfColumns: 2,
        },
      ])
      .flat();
  }

  function getContactProps() {
    return {
      className: classNames(styles.field, styles.contacts),
      componentProps: {
        direction: "column",
        fullWidth: true,
        radioButtonInfoList: contacts.map((contact) => ({
          caption: <div className={styles.contactCaption}>{contact.value}</div>,
          label: (
            <div className={styles.contactLabel}>
              {i18n(`MfaContactSelectForm__${contact.deviceType}_ACTION`)}
            </div>
          ),
          value: JSON.stringify(contact),
        })),
        styleType: "secondary",
      },
      disabled: loading,
      name: "contact",
    };
  }

  function getTermsAgreeProps() {
    return {
      className: classNames(styles.field, styles.termsAgree),
      componentProps: {
        label: (
          <span>
            {i18n("MfaContactSelectForm__TERMS_AGREE")}
            <Link {...getMfaTermsLinkProps()}>
              {i18n("MfaContactSelectForm__TERMS_AND_CONDITIONS")}
            </Link>
          </span>
        ),
      },
      disabled: loading,
      name: "termsAgree",
    };
  }

  function getMfaTermsLinkProps() {
    return {
      external: true,
      href: MFA_TERMS_AND_CONDITIONS,
      newWindow: true,
      onClick: (event) => event.stopPropagation(),
    };
  }

  function getNeedHelpLinkProps() {
    return {
      className: classNames(styles.fullWidth, styles.needHelp),
      disabled: loading,
      href: loading ? "" : MFA_HELP,
    };
  }

  function getSubmitProps() {
    return {
      className: styles.field,
      componentProps: {
        fullWidth: true,
        submitInProgress: loading,
      },
      name: "submit",
    };
  }
};

MfaContactSelectForm.propTypes = {
  /** The contacts available for the user to select. */
  contacts: PropTypes.arrayOf(
    PropTypes.shape({
      deviceType: PropTypes.string,
      value: PropTypes.string,
    })
  ),

  /** Indicates if the form is in a loading state. */
  loading: PropTypes.bool,

  /** Callback method to handle form submission. */
  onSubmit: PropTypes.func,
};
