import PropTypes from "prop-types";
import React from "react";

export const PriorityBoarding = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <g
        strokeWidth="1"
        fontFamily="Arial-BoldMT, Arial"
        fontSize="25"
        fontWeight="bold"
        letterSpacing="-0.9"
      >
        <text>
          <tspan x="-1" y="18">
            B-
          </tspan>
          <tspan x="23.5793945" y="18" letterSpacing="-3.3">
            1
          </tspan>
          <tspan x="34.1832031" y="18" letterSpacing="-2.4">
            5
          </tspan>
        </text>
      </g>
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      style,
      viewBox: "0 -15 48 48",
    };
  }
};

PriorityBoarding.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

PriorityBoarding.defaultProps = { role: "img" };
