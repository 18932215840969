/**
 * deepEquals provides a convenient way to determine if objects are equal to each other,
 * also checking deep equivalence of nested object values.
 *
 * @param {object} object1 object to check equivalence with
 * @param {object} object2 object to check equivalence with
 */

export const deepEquals = (object1, object2) => {
  let isEqual = object1 === object2;

  if (typeof object1 === "object" && typeof object2 === "object") {
    const object1Keys = Object.keys(object1);
    const object2Keys = Object.keys(object2);

    if (object1Keys.length === object2Keys.length) {
      isEqual = object1Keys.every((valueKey) => deepEquals(object1[valueKey], object2[valueKey]));
    }
  }

  return isEqual;
};
