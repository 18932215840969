import PropTypes from "prop-types";
import React from "react";

import { Badge } from "@swa-ui/core";
import { classNames } from "@swa-ui/string";

import { ItineraryDate } from "../ItineraryDate";
import { ItinerarySegment, itinerarySegmentPropTypes } from "../ItinerarySegment";
import { ItineraryStation } from "../ItineraryStation";
import styles from "./ItineraryBadge.module.scss";

const COLORS = ["black", "secondary", "transparent", "white"];

/**
 * ItineraryBadge renders a badge for variety of itinerary snippets, such as airport location or
 * travel date.
 */

export const ItineraryBadge = (props) => {
  const { className, height, items, padding, styleSetting } = props;

  return (
    <Badge {...getProps()}>
      <span className={getContainerClass()}>{items.map(renderLine)}</span>
    </Badge>
  );

  function renderLine(item) {
    const { content, format } = item;
    const key = `${content}-${format}`;
    let contentToRender;

    if (!format || format === "text") {
      contentToRender = (
        <ItinerarySegment {...getItinerarySegmentProps(item)} key={key}>
          {content}
        </ItinerarySegment>
      );
    } else if (isStationItem(format)) {
      contentToRender = <ItineraryStation {...getStationProps(item)} key={key} />;
    } else {
      contentToRender = <ItineraryDate {...getItineraryDateProps(item)} key={key} />;
    }

    return contentToRender;
  }

  function getProps() {
    const { background, border } = styleSetting;

    return {
      border,
      className: classNames(className, styles.itineraryBadge),
      height,
      padding,
      styleType: background,
    };
  }

  function getItinerarySegmentProps(item) {
    const { className: itemClassName, content, format, styleSetting: itemStyleSetting } = item;

    return {
      className: itemClassName,
      format,
      stationCode: content,
      styleSetting: itemStyleSetting,
    };
  }

  function getStationProps(item) {
    const { className: itemClassName, content, format, styleSetting: itemStyleSetting } = item;

    return {
      className: itemClassName,
      format: format,
      stationCode: content,
      styleSetting: itemStyleSetting,
    };
  }

  function getItineraryDateProps(item) {
    const { className: itemClassName, content, format, styleSetting: itemStyleSetting } = item;

    return {
      className: itemClassName,
      date: content,
      format,
      styleSetting: itemStyleSetting,
    };
  }

  function getContainerClass() {
    const { center, minimumWidth } = styleSetting;

    return classNames(styles.container, {
      [styles.center]: center,
      [styles.minimumWidthLarge]: minimumWidth === "large",
    });
  }

  function isStationItem(format) {
    return format === "stationCode" || format === "stationShortName" || format === "stationName";
  }
};

ItineraryBadge.propTypes = {
  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * Optional minimum height to be given to Background. For circle or square shapes, height will
   * also define the background's width.
   */
  height: PropTypes.oneOfType([
    PropTypes.oneOf(["large", "line", "lineHeight", "medium", "small", "xlarge", "xsmall"]),
    PropTypes.number,
  ]),

  /**
   * ItineraryDate, ItinerarySegment, ItineraryStation are used to render the badge content.
   * Which component used will depend on the format given for each item in the list.
   *
   * content - what will be rendered by one of these components. Refer to the components for info.
   * format - how the content will be rendered. Refer to the components for info.
   * styleSetting - style of the content to be rendered. Refer to ItinerarySegment for info.
   */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      className: PropTypes.string,
      content: PropTypes.string,
      format: PropTypes.string,
      styleSetting: PropTypes.shape(itinerarySegmentPropTypes),
    })
  ),

  /** Padding allows the caller to specify how much padding will be defined. */
  padding: PropTypes.oneOf(["large", "medium", "none", "small", "xlarge", "xsmall"]),

  /**
   * Display styling. See story representation for how these look. Also, good resource for props
   * are the core components Badge and Background which are used by ItineraryBadge directly or
   * indirectly.
   */
  styleSetting: PropTypes.shape({
    background: PropTypes.oneOf(COLORS),
    border: PropTypes.shape({
      color: PropTypes.oneOf(COLORS),
      radius: PropTypes.oneOf(["large", "medium", "small", "xlarge", "xsmall"]),
      style: PropTypes.oneOf(["dashed", "dotted", "double", "inset", "solid"]),
      width: PropTypes.oneOf(["large", "medium", "small", "xlarge", "xsmall"]),
    }),
    center: PropTypes.bool,
    minimumWidth: PropTypes.oneOf(["large", "small"]),
    padding: PropTypes.oneOf(["large", "medium", "none", "small", "xlarge", "xsmall"]),
  }),
};

ItineraryBadge.defaultProps = {
  items: [],
  styleSetting: {
    background: "white",
    center: true,
    padding: "small",
  },
};
