import * as yup from "yup";

import i18n from "@swa-ui/locale";

export const manageMyVacationsSchema = () =>
  yup.object().shape({
    firstName: yup
      .string()
      .trim()
      .required(i18n("ManageMyVacations__FORM_FIRSTNAME_REQUIRED_TEXT"))
      .matches(/^[A-Za-z ]*$/, i18n("ManageMyVacations__FORM_NAME_INVALID_TEXT"))
      .max(30)
      .label("firstName"),
    lastName: yup
      .string()
      .trim()
      .label("lastName")
      .required(i18n("ManageMyVacations__FORM_LASTNAME_REQUIRED_TEXT"))
      .max(30)
      .matches(/^[A-Za-z ]*$/, i18n("ManageMyVacations__FORM_NAME_INVALID_TEXT")),
    confirmationNumber: yup
      .string()
      .trim()
      .min(6, i18n("ManageMyVacations__FORM_CONFIRMATION_NUMBER_HELPER_TEXT"))
      .max(7, i18n("ManageMyVacations__FORM_CONFIRMATION_NUMBER_HELPER_TEXT"))
      .matches(/^[A-Za-z0-9]*$/, i18n("ManageMyVacations__FORM_CONFIRMATION_NUMBER_INVALID_TEXT"))
      .label("confirmationNumber"),
  });
