import PropTypes from "prop-types";
import React from "react";

import { Icon } from "@swa-ui/core";
import { classNames } from "@swa-ui/string";

import styles from "./FlightIndicator.module.scss";

export const FlightIndicator = (props) => {
  const { ariaLabel, children, className, disabled, iconColor, iconName, labelClass, size } = props;

  return (
    <div
      aria-label={ariaLabel}
      className={classNames(className, labelClass, styles.flightIndicator)}
    >
      <Icon {...getIconProps()} />
      <span aria-hidden="true" className={getLabelClass()}>
        {children}
      </span>
    </div>
  );

  function getIconProps() {
    return {
      className: styles.icon,
      color: iconColor,
      disabled,
      name: iconName,
      size,
    };
  }

  function getLabelClass() {
    return classNames({
      [styles.disabled]: disabled,
      [styles.text]: true,
    });
  }
};

FlightIndicator.propTypes = {
  /** Aria label for the indicator. */
  ariaLabel: PropTypes.string,

  /** Label / text for the indicator. */
  children: PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Indicator to show disabled styling. */
  disabled: PropTypes.bool,

  /** Default color for the flight indicator. */
  iconColor: PropTypes.string,

  /** Flight indicator icon. */
  iconName: PropTypes.string,

  /** Indicator component class for styling. */
  labelClass: PropTypes.string,

  /** Indicator size. */
  size: PropTypes.string,
};

FlightIndicator.defaultProps = {
  size: "size16",
};
