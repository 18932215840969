import PropTypes from "prop-types";
import React from "react";

import { merge } from "@swa-ui/object";

import { usePlacement } from "../usePlacement";
import { usePlacementIds } from "../usePlacementIds/usePlacementIds";
import { AdaptiveListPlacement } from "./AdaptiveListPlacement";

export const DynamicListPlacement = (props) => {
  const { listItemType, placement } = props;
  const { id } = placement;
  const placementIds = usePlacementIds(new RegExp(`${id}.*`)).sort();
  const items = placementIds.map((placementId) => {
    const placementData = usePlacement(placementId);
    const data = placementData?.placement?.placement;
    const item = merge(data, { text: { primary: { prefixIcon: data.icon } } });

    return item;
  });

  return <AdaptiveListPlacement placement={getProps()} />;

  function getProps() {
    return merge(placement, {
      items,
      listItemType,
    });
  }
};

DynamicListPlacement.propTypes = {
  placement: PropTypes.shape({
    /** Placement type id */
    id: PropTypes.string,

    /** Name of the item component to render. */
    listItemType: PropTypes.string,

    /** Placement configuration. */
    settings: PropTypes.shape({
      /** Array of arrays (matrix) with information about the layout to render. */
      layout: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),

      /** Override for all properties in settings by device screen size. */
      overrides: PropTypes.object,
    }),
  }),
};
