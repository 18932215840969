import PropTypes from "prop-types";
import React from "react";

import { swaDate } from "@swa-ui/date";

import { ItinerarySegment, itinerarySegmentPropTypes } from "../ItinerarySegment";

/**
 * ItineraryDate renders a date within a span element something like this: Wed, Feb. 29.
 * See propTypes and dayJS documentation to learn about ways to format and style the output.
 */

export const ItineraryDate = (props) => {
  const { className, date, format, styleSetting } = props;

  return (
    <ItinerarySegment className={className} {...styleSetting}>
      {getTimeOrDate()}
    </ItinerarySegment>
  );

  function getTimeOrDate() {
    let content;

    if (format.toUpperCase().indexOf("H") !== -1 || format.indexOf("m") !== -1) {
      content = getTime();
    } else {
      content = swaDate(date).format(format);
    }

    return content;
  }

  function getTime() {
    return swaDate(date, format.replace(/a|A/, " ")).format(`${format}`);
  }
};

ItineraryDate.propTypes = {
  /** Classes for positioning the component. */
  className: PropTypes.string,

  /** date should be formatted like so: 02/29/2024, 2024-2-29, etc. */
  date: PropTypes.string.isRequired,

  /**
   * Display format to render date. See https://day.js.org/docs/en/display/format for available
   * values.
   */
  format: PropTypes.string,

  /** Options to use to style rendered date. */
  styleSetting: PropTypes.shape(itinerarySegmentPropTypes),
};

ItineraryDate.defaultProps = {
  format: "MM/DD/YYYY",
};
