import PropTypes from "prop-types";
import React, { useContext } from "react";

import {
  ApplicationContext,
  commonPlacementComponents as placementComponents,
  useAppSetting,
  usePageId,
} from "@swa-ui/application";
import { AuthContext, useUserInfo } from "@swa-ui/authentication";
import { getBootstrapData } from "@swa-ui/bootstrap";
import { useGeolocation } from "@swa-ui/geolocation";
import { PersonasContext } from "@swa-ui/personas";
import { PlacementsProvider } from "@swa-ui/placements";

import { LocaleContext } from "../LocaleProvider";

/**
 * Dotcom wrapper around PlacementsProvider
 */

const DEFAULT_GLOBAL_PLACEMENT_IDS = [
  "footer",
  "globalNavFooter",
  "globalNavHeader",
  "messageIncidentAlert",
  "messageIncidentAlertfit",
  "messageSystemAlert",
  "messageSystemAlertfit",
  "messageTravelAdvisory",
  "messageTravelAdvisoryfit",
  "navBottom",
  "navTop",
];

export const DotcomPlacementsProvider = (props) => {
  const {
    additionalContentParameters,
    appPlacementComponents = {},
    children,
    globalPlacementIds,
    placementsConfig,
  } = props;
  const { placementParameters = "" } = additionalContentParameters ?? {};
  const { appId, channelId, screenSize, site } = useContext(ApplicationContext);
  const { isLoggedIn } = useContext(AuthContext);
  const { locale } = useContext(LocaleContext);
  const { pageId } = usePageId();
  const { getCorporateInfo } = useUserInfo();
  const { isCorporatePersona } = useContext(PersonasContext);
  const placementsBlocked = useAppSetting("placementsBlocked", false);
  const defaultPlacements = getBootstrapData("bootstrap-content/en/content");
  const geolocation = getBootstrapData("air/stations-data") && useGeolocation();

  return <PlacementsProvider {...getPlacementProviderProps()}>{children}</PlacementsProvider>;

  function getPlacementParameters() {
    const placementParameterEntries = placementParameters
      ?.split(";")
      .filter(Boolean)
      .map(
        (placementParameter) => placementParameter.includes(":") && placementParameter.split(":")
      );

    return Object.fromEntries(placementParameterEntries);
  }

  function getPlacementProviderProps() {
    return {
      additionalContentParameters: {
        loginState: isLoggedIn().toString(),
        nearestStation: geolocation?.nearestStation,
        ...(isLoggedIn() && isCorporatePersona && getCorporateUserPlacementPayload()),
      },
      appId,
      channelId,
      defaultOnly: placementsBlocked,
      defaultPlacements,
      lang: locale,
      pageId,
      globalPlacementIds: globalPlacementIds.concat(DEFAULT_GLOBAL_PLACEMENT_IDS),
      placementComponents: {
        ...placementComponents,
        ...appPlacementComponents,
      },
      placementsConfig,
      screenSize,
      site,
      updateOnPageLoad: false,
      ...getPlacementParameters(),
    };
  }

  function getCorporateUserPlacementPayload() {
    const { approvedCars, carOptionAvailable, hotelOptionAvailable, role } =
      getCorporateInfo() ?? {};

    return {
      corporateCarEnabled: carOptionAvailable,
      corporateCarVendors: approvedCars,
      corporateHotelEnabled: hotelOptionAvailable,
      corporateLoginState: "true",
      corporateRole: role,
    };
  }
};

DotcomPlacementsProvider.propTypes = {
  /** Additional content parameters to be passed. */
  additionalContentParameters: PropTypes.object,

  /** Application supplied object mapping to custom placement components. */
  appPlacementComponents: PropTypes.object,

  /** Content to be rendered on the page. */
  children: PropTypes.node.isRequired,

  /** Global placementIds that should be persisted between placement calls. */
  globalPlacementIds: PropTypes.arrayOf(PropTypes.string),

  /** Configuration settings used when rendering placements. */
  placementsConfig: PropTypes.shape({
    configuredCommands: PropTypes.object,
  }),
};

DotcomPlacementsProvider.defaultProps = {
  globalPlacementIds: [],
};
