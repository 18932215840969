import PropTypes from "prop-types";
import React from "react";

export const ChangePlanes = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path d="M13.11,16.22a2.56,2.56,0,0,0,.94-1.88A.54.54,0,0,0,14,14a.57.57,0,0,0-.32-.09,2.56,2.56,0,0,0-1.88.94l-1.57,1.57L5.12,15.1a.77.77,0,0,0-.45.09L4,16l4.35,2.37-.58.59a9.18,9.18,0,0,0-1,1.21L5.26,19.9a.62.62,0,0,0-.5.14l-.63.63,1.8.8a1.25,1.25,0,0,0-.18.72h0v0h0a1.3,1.3,0,0,0,.72-.18l.81,1.79L8,23.18a.62.62,0,0,0,.14-.5h0L7.9,21.16a12.86,12.86,0,0,0,1.21-1l.59-.58h0l2.37,4.35.68-.67a.45.45,0,0,0,.09-.45l-1.35-5.07h0Z" />
      <path d="M21.45,11.29A7.77,7.77,0,0,0,15,7.66h0a7.71,7.71,0,0,0-7.23,5h0l0,.09a.43.43,0,0,1-.09.18h0v.22A.66.66,0,0,0,8,14a.67.67,0,0,0,.85-.45L9,13.13A6.3,6.3,0,0,1,15,9a6.4,6.4,0,0,1,5.29,2.87l-1.66.81,3.5,1.39,1-3.63Z" />
      <path d="M27.06,16.63A2.56,2.56,0,0,0,28,14.74a.54.54,0,0,0-.09-.31.54.54,0,0,0-.31-.09,2.56,2.56,0,0,0-1.89.94l-1.57,1.57-5.07-1.34a.7.7,0,0,0-.45.09l-.67.67,4.35,2.38-.58.58a10.05,10.05,0,0,0-1,1.21l-1.48-.18a.56.56,0,0,0-.45.14l-.67.67,1.8.81a1.22,1.22,0,0,0-.18.71h0v.05h0a1.31,1.31,0,0,0,.72-.18l.81,1.79.67-.67a.58.58,0,0,0,.13-.49h0l-.22-1.53a14.26,14.26,0,0,0,1.21-1l.58-.59h0l2.38,4.35.67-.67a.45.45,0,0,0,.09-.45l-1.34-5.07h0Z" />
    </svg>
  );

  function getProps() {
    return {
      "aria-hidden": props["aria-hidden"],
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      style,
      viewBox: "0 0 32 32",
    };
  }
};

ChangePlanes.propTypes = {
  /** aria-hidden text to hide non-interactive content from the accessibility API. */
  "aria-hidden": PropTypes.string,

  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

ChangePlanes.defaultProps = {
  role: "img",
};
