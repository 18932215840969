import PropTypes from "prop-types";
import React from "react";

export const Family = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path d="M52.25,87.92c-.25-1.98-.42-3.99-.42-6.04,0-21.36,14.32-38.45,33.46-41.22-4.05-13.48-15.42-22.47-28.83-22.47-16.79,0-30.41,14.08-30.41,33.45,0,17.71,11.4,33.68,26.19,36.28Z" />
      <path d="M53.46,102.8c.55-.2,1.24-.45,1.96-.7-3.71-2-17.8-9.85-25.53-17.67,0,0-6.73,2.26-9.9,3.41C3.49,93.87,0,104.81,0,109s.65,8.01,.65,8.01c0,0,9,7.15,27.15,11.48,2-8.27,8.83-19.55,25.67-25.69Z" />
      <path d="M118.23,81.88c0,17.51-12.31,33.16-27.48,33.16s-27.49-15.65-27.49-33.16,12.3-30.23,27.49-30.23,27.48,12.73,27.48,30.23Z" />
      <path d="M121.91,114.61c-2.35-.86-7.77-2.55-7.77-2.55-9.19,8.5-23.5,15.88-23.5,15.88,0,0-15.81-8.23-23.92-16.43,0,0-6.08,2.03-8.94,3.09-14.91,5.44-18.08,15.33-18.08,19.12s.61,7.24,.61,7.24c0,0,16.65,13.28,50.47,13.28s48.61-13.28,48.61-13.28c0,0,.59-3.46,.59-7.24s-3.18-13.68-18.07-19.12Z" />
      <path d="M129.63,81.2c18.13-1.87,32.4-21.04,32.4-42.39,0-22.47-15.81-38.81-35.29-38.81s-35.28,16.34-35.28,38.81c0,.49,.05,.97,.06,1.46,21.23,.42,37.79,18.18,38.12,40.93Z" />
      <path d="M166.74,80.8c-3.03-1.1-9.98-3.26-9.98-3.26-10.83,10.01-27.16,18.81-29.76,20.18-.55,1.6-1.19,3.16-1.89,4.68,.42,.14,.79,.27,1.12,.39,17.55,6.42,24.21,18.4,25.86,26.74,25.53-4.59,37.06-14.87,37.06-14.87,0,0,.78-4.45,.78-9.3s-4.07-17.56-23.19-24.56Z" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      style,
      viewBox: "0 0 189.92 154.25",
    };
  }
};

Family.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Family.defaultProps = {
  role: "img",
};
