/* eslint-disable sort-keys */
export default {
  light: {
    "form-control-caption": "gray-4",
    "form-control-error": "red-1",
    "form-control-label": "blue-3",
  },
  dark: {
    "form-control-caption": "white",
    "form-control-error": "red-2",
    "form-control-label": "gray-1",
  },
};
