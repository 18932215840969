/* eslint-disable sort-keys */
import authenticationTheme from "@swa-ui/authentication/theme";
import { getBootstrapData } from "@swa-ui/bootstrap";
import { colors } from "@swa-ui/core/defines";
import coreTheme from "@swa-ui/core/theme";
import dotcomTheme from "@swa-ui/dotcom/theme";
import formTheme from "@swa-ui/form/theme";
import itineraryTheme from "@swa-ui/itinerary/theme";
import placementsTheme from "@swa-ui/placements/theme";
import { initializeColors, initializeTheme } from "@swa-ui/theme";
import vacationsTheme from "@swa-ui/vacations/theme";

const colorOverrides = getBootstrapData("color-overrides") ?? {};
const themeOverrides = getBootstrapData("theme-overrides") ?? {};

initializeColors({
  ...colors,
  ...colorOverrides,
});
initializeTheme({
  light: {
    ...authenticationTheme.light,
    ...coreTheme.light,
    ...dotcomTheme.light,
    ...formTheme.light,
    ...itineraryTheme.light,
    ...placementsTheme.light,
    ...vacationsTheme.light,
    ...themeOverrides.light,
  },
  dark: {
    ...authenticationTheme.dark,
    ...coreTheme.dark,
    ...dotcomTheme.dark,
    ...formTheme.dark,
    ...itineraryTheme.dark,
    ...placementsTheme.dark,
    ...vacationsTheme.dark,
    ...themeOverrides.dark,
  },
});
