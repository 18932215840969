import PropTypes from "prop-types";
import React from "react";

export const CreditCardCircle = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path d="M 24 0 C 10.7 0 0 10.7 0 24 C 0 37.2 10.7 48 24 48 C 37.3 48 48 37.2 48 24 C 48 10.7 37.3 0 24 0 z M 12.8125 16 L 35.3125 16 C 36.3125 16 37.09375 16.8125 37.09375 17.8125 L 37.09375 19.40625 L 11 19.40625 L 11 17.8125 C 11 16.8125 11.8125 16 12.8125 16 z M 11 21.8125 L 37.09375 21.8125 L 37.09375 30.90625 C 37.09375 31.90625 36.3125 32.6875 35.3125 32.6875 L 12.8125 32.6875 C 11.8125 32.6875 11 31.90625 11 30.90625 L 11 21.8125 z M 13.90625 23.8125 C 13.40625 23.8125 13.09375 24.09375 13.09375 24.59375 C 13.09375 24.99375 13.40625 25.40625 13.90625 25.40625 L 21.09375 25.40625 C 21.49375 25.40625 21.90625 24.99375 21.90625 24.59375 C 21.90625 24.19375 21.49375 23.8125 21.09375 23.8125 L 13.90625 23.8125 z M 31.1875 23.8125 C 30.6875 23.8125 30.40625 24.09375 30.40625 24.59375 C 30.40625 24.99375 30.7875 25.40625 31.1875 25.40625 L 34.3125 25.40625 C 34.7125 25.40625 35.09375 24.99375 35.09375 24.59375 C 35.09375 24.19375 34.7125 23.8125 34.3125 23.8125 L 31.1875 23.8125 z M 13.90625 26.90625 C 13.40625 26.90625 13.09375 27.2875 13.09375 27.6875 C 13.09375 28.0875 13.50625 28.5 13.90625 28.5 L 17 28.5 C 17.4 28.5 17.8125 28.0875 17.8125 27.6875 C 17.8125 27.2875 17.4 26.90625 17 26.90625 L 13.90625 26.90625 z" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "0 0 48 48",
    };
  }
};

CreditCardCircle.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

CreditCardCircle.defaultProps = { role: "img" };
