import { useContext } from "react";

import { MboxContext } from "../MboxProvider";

export const useMbox = (name, defaultValue) => {
  const { loading, mboxes } = useContext(MboxContext) ?? {};

  return {
    loading,
    mbox: mboxes?.test?.[name] ?? defaultValue,
  };
};
