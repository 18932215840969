import PropTypes from "prop-types";
import React, { useContext } from "react";

import { Link } from "@swa-ui/core";
import i18n from "@swa-ui/locale";

import { LocaleContext } from "../LocaleProvider";
import styles from "./LocaleSwitcher.module.scss";

/**
 * Simple component that renders a link to call the switch language function which is pass to this component.
 */

export const LocaleSwitcher = (props) => {
  const { light } = props;
  const { availableLocales, locale, switchLocale } = useContext(LocaleContext);
  const nextLocale = getNextLocale();

  return (
    <Link {...getProps()}>{i18n(`LocaleSwitcher__LANGUAGE_${nextLocale.toUpperCase()}`)}</Link>
  );

  function getProps() {
    return {
      className: styles.localeSwitcher,
      emphasis: false,
      light,
      onClick: handleClick,
      size: "fontSize13",
      suffixIcon: {
        color: "cmp-dotcom-color-header-control-link-fg",
        name: "Global",
        size: "size18",
      },
    };
  }

  function handleClick() {
    switchLocale(nextLocale);
  }

  function getNextLocale() {
    const currentIndex = availableLocales.indexOf(locale);
    const nextIndex = (currentIndex + 1) % availableLocales.length;

    return availableLocales[nextIndex];
  }
};

LocaleSwitcher.propTypes = {
  /** Indicates whether the app is using a dark Header background, to be passed to Link component for styling. */
  light: PropTypes.bool,
};
