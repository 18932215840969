import * as yup from "yup";

const MAX_LENGTH = 16;
const MIN_LENGTH = 8;
export const getNewPasswordSchema = () => {
  const pattern = {
    ALLOWED_CHARACTERS: /^[A-Za-z0-9!@#$%^*(),.;:/\\]*$/,
    DISALLOWED_NUMERIC_SEQUENCE: /^(?!.*12345).*/,
    DISALLOWED_STRING_SEQUENCE: /^(?!.*password).*/i,
    FIRST_CHARACTER_ALPHANUMERIC: /^[A-Za-z0-9]/,
    NUMERIC: /\d/,
    UPPERCASE: /[A-Z]/,
  };
  return yup
    .string()
    .min(MIN_LENGTH, "lengthRule")
    .max(MAX_LENGTH, "lengthRule")
    .matches(pattern.UPPERCASE, "caseRule")
    .matches(pattern.NUMERIC, "numberRule")
    .matches(pattern.FIRST_CHARACTER_ALPHANUMERIC, "firstCharacterRule")
    .matches(pattern.ALLOWED_CHARACTERS, "allowedCharacterRule")
    .matches(pattern.DISALLOWED_NUMERIC_SEQUENCE, "disallowedNumericSequence")
    .matches(pattern.DISALLOWED_STRING_SEQUENCE, "disallowedStringSequence");
};
