import PropTypes from "prop-types";
import React from "react";

export const FlyBy = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <g transform="translate(3.000000, 16.000000)">
        <path d="M3.6647644,9 C1.10818332,8.91668041 0.0419880067,7.75020611 0.0419880067,7.75020611 L0.0209940033,7.54816244 C0.0104970017,7.42452378 0,7.25565148 0,7.07471689 C0,6.73697222 0.490607942,5.90814934 1.46958012,5.28949541 C1.59757692,5.20860882 1.77438354,5.11211035 2,5 C2.59483002,5.6835308 3.70424056,6.08687191 3.95067075,6.17032994 L4,6.18657363 L4.28972452,6.0821867 C4.71894043,5.91432788 5.52413598,5.54682464 6,5 L6.30181731,5.15645662 L6.462354,5.24775113 C6.48639845,5.26209955 6.50908708,5.27601432 6.53041988,5.28949541 C7.50939206,5.90814934 8,6.73697222 8,7.07471689 C8,7.43658606 7.95801199,7.75020611 7.95801199,7.75020611 L7.87440323,7.81969625 L7.69124906,7.9534677 C7.22143959,8.27455437 6.13026708,8.87432669 4.41131871,8.98639913 L4,8.99875314 L4,9 L3.6647644,9 Z" />
        <path d="M4,0 C5.1028037,0 6,0.9919028 6,2.388664 C6,3.7651822 5.1028037,5 4,5 C2.8971963,5 2,3.7651822 2,2.388664 C2.0186916,0.9919028 2.8971963,0 4,0 Z" />
      </g>
      <g transform="translate(12.000000, 16.000000)">
        <path d="M3.6647644,9 C1.10818332,8.91668041 0.0419880067,7.75020611 0.0419880067,7.75020611 L0.0209940033,7.54816244 C0.0104970017,7.42452378 0,7.25565148 0,7.07471689 C0,6.73697222 0.490607942,5.90814934 1.46958012,5.28949541 C1.59757692,5.20860882 1.77438354,5.11211035 2,5 C2.59483002,5.6835308 3.70424056,6.08687191 3.95067075,6.17032994 L4,6.18657363 L4.28972452,6.0821867 C4.71894043,5.91432788 5.52413598,5.54682464 6,5 L6.30181731,5.15645662 L6.462354,5.24775113 C6.48639845,5.26209955 6.50908708,5.27601432 6.53041988,5.28949541 C7.50939206,5.90814934 8,6.73697222 8,7.07471689 C8,7.43658606 7.95801199,7.75020611 7.95801199,7.75020611 L7.87440323,7.81969625 L7.69124906,7.9534677 C7.22143959,8.27455437 6.13026708,8.87432669 4.41131871,8.98639913 L4,8.99875314 L4,9 L3.6647644,9 Z" />
        <path d="M4,0 C5.1028037,0 6,0.9919028 6,2.388664 C6,3.7651822 5.1028037,5 4,5 C2.8971963,5 2,3.7651822 2,2.388664 C2.0186916,0.9919028 2.8971963,0 4,0 Z" />
      </g>
      <g transform="translate(21.000000, 16.000000)">
        <path d="M3.6647644,9 C1.10818332,8.91668041 0.0419880067,7.75020611 0.0419880067,7.75020611 L0.0209940033,7.54816244 C0.0104970017,7.42452378 0,7.25565148 0,7.07471689 C0,6.73697222 0.490607942,5.90814934 1.46958012,5.28949541 C1.59757692,5.20860882 1.77438354,5.11211035 2,5 C2.59483002,5.6835308 3.70424056,6.08687191 3.95067075,6.17032994 L4,6.18657363 L4.28972452,6.0821867 C4.71894043,5.91432788 5.52413598,5.54682464 6,5 L6.30181731,5.15645662 L6.462354,5.24775113 C6.48639845,5.26209955 6.50908708,5.27601432 6.53041988,5.28949541 C7.50939206,5.90814934 8,6.73697222 8,7.07471689 C8,7.43658606 7.95801199,7.75020611 7.95801199,7.75020611 L7.87440323,7.81969625 L7.69124906,7.9534677 C7.22143959,8.27455437 6.13026708,8.87432669 4.41131871,8.98639913 L4,8.99875314 L4,9 L3.6647644,9 Z" />
        <path d="M4,0 C5.1028037,0 6,0.9919028 6,2.388664 C6,3.7651822 5.1028037,5 4,5 C2.8971963,5 2,3.7651822 2,2.388664 C2.0186916,0.9919028 2.8971963,0 4,0 Z" />
      </g>
      <path
        d="M16.7683204,6 C20.6540612,6 23.9778028,8.45026997 25.3476658,11.8798025 L25.5192113,12.347902 L25.6813581,12.8986013 C25.843264,13.3391608 25.5731799,13.8898601 25.141431,14 C24.7096819,14.1652097 24.277933,13.8898601 24.1160271,13.4493007 L23.9541213,12.8986013 C22.8747488,9.92482512 20.0604063,7.6520979 16.7683204,7.6520979 C14.123858,7.6520979 11.7492387,9.08391608 10.4000231,11.1765734 L12.3968621,12.1678322 L7.66688262,14 L7,9.41433566 L8.94287036,10.4606643 C10.558207,7.90845792 13.2803817,6.12631379 16.4293309,6.00644202 L16.7683204,6 Z"
        transform="translate(16.364413, 10.024231) scale(-1, 1) translate(-16.364413, -10.024231)"
      />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      style,
      viewBox: "2 2 28 28",
    };
  }
};

FlyBy.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

FlyBy.defaultProps = {
  role: "img",
};
