import React from "react";

import { useDeviceInfo } from "@swa-ui/browser";
import { Heading, ListScroller } from "@swa-ui/core";
import { useRecentSearchesAnalytics } from "@swa-ui/dotcom-analytics";
import { useRecentSearches } from "@swa-ui/form";
import i18n from "@swa-ui/locale";
import { PlacementFactory } from "@swa-ui/placements";

import styles from "./VacationsRecentSearch.module.scss";
import { VacationsRecentSearchItem } from "./VacationsRecentSearchItem";

export const VacationsRecentSearch = () => {
  const { recentSearches } = useRecentSearches({ maxResults: 3 });
  const { isSmallOrMedium } = useDeviceInfo();

  useRecentSearchesAnalytics(recentSearches);

  return (
    !!recentSearches?.length && (
      <div className={styles.container}>
        <Heading {...getProps()}>
          <PlacementFactory id="recentSearchesHeader" />
        </Heading>
        {isSmallOrMedium ? renderForSmallAndMediumScreens() : renderForLargeScreens()}
      </div>
    )
  );

  function renderForLargeScreens() {
    return (
      <div className={styles.recentSearchContainer}>
        {recentSearches.map((item) => (
          <VacationsRecentSearchItem key={item.timestamp} {...getItemProps(item.formData)} />
        ))}
      </div>
    );
  }

  function renderForSmallAndMediumScreens() {
    return <ListScroller {...getListScrollerProps()} />;
  }

  function getProps() {
    return {
      className: styles.heading,
      headingLevel: 2,
    };
  }

  function getListScrollerProps() {
    return {
      "aria-label-current-page": i18n("VacationsRecentSearch__ARIA_LABEL__CURRENT_PAGE"),
      "aria-label-next": i18n("VacationsRecentSearch__ARIA_LABEL__NEXT"),
      "aria-label-page-count": i18n("VacationsRecentSearch__ARIA_LABEL__PAGE_COUNT"),
      "aria-label-previous": i18n("VacationsRecentSearch__ARIA_LABEL__PREVIOUS"),
      className: styles.recentSearchItem,
      items: recentSearches.map((item) => (
        <VacationsRecentSearchItem key={item.timestamp} {...getItemProps(item.formData)} />
      )),
      layout: "none",
      showPositionIndicator: false,
    };
  }

  function getItemProps(formData) {
    const {
      adults,
      children,
      departureDate,
      desktopImage,
      destinationAirportCode,
      mobileImage,
      originationAirportCode,
      packageType,
      returnDate,
      rooms,
      searchVacationRedirectPageUrl,
    } = formData;

    return {
      adults,
      children,
      departureDate,
      desktopImage,
      destinationAirportCode,
      mobileImage,
      originationAirportCode,
      packageType,
      returnDate,
      rooms,
      searchVacationRedirectPageUrl,
    };
  }
};
