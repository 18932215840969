import PropTypes from "prop-types";
import React from "react";

export const External = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path d="M13,5.5 L4,5.5" strokeLinecap="round" strokeWidth="4" />
      <path d="M4,5 L4,28" strokeLinecap="butt" strokeWidth="4" />
      <path d="M4,28 L27,28" strokeLinecap="round" strokeWidth="4" />
      <path d="M27,28 L27,16" strokeLinecap="butt" strokeWidth="4" />
      <path d="M14,4 L17,7 L14,7 L14,5" strokeLinecap="square" strokeWidth="1" />
      <path d="M25.5,14 L28,16 L25.5,16 L25.5,14" strokeLinecap="square" strokeWidth="1" />
      <path
        d="M30,2 L30,11 L26.5,8 L15,19 L10.5,19 L24,6 L20.5,2 L30,2"
        strokeLinecap="square"
        strokeWidth="1"
      />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      style,
      viewBox: "0 0 32 32",
    };
  }
};

External.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

External.defaultProps = {
  role: "img",
};
