import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

import { focusFirstElement } from "@swa-ui/browser";
import { Button } from "@swa-ui/core";
import i18n from "@swa-ui/locale";
import { classNames } from "@swa-ui/string";

import styles from "./SkipToContent.module.scss";

/**
 * SkipToContent provides a hidden link, visible only to a screen reader, that when selected will move focus to the
 * first focusable item in the main content, bypassing the global nav with all of its options.
 */

export const SkipToContent = (props) => {
  const { contentSelector, light } = props;
  const { pathname } = useLocation();
  const ref = useRef(null);

  useEffect(() => {
    ref.current?.focus();
  }, [pathname]);

  return (
    <>
      {focusFirstElementOnPathChange()}
      <Button {...getProps()}>{i18n("SkipToContent__TEXT")}</Button>
    </>
  );

  function getProps() {
    return {
      className: classNames(styles.hiddenFromScreen, { [styles.lightForeground]: light }),
      emphasis: false,
      onClick: handleClick,
      styleType: "no-style",
    };
  }

  function handleClick() {
    focusFirstElement(contentSelector);
  }

  function focusFirstElementOnPathChange() {
    return <div ref={ref} tabIndex={-1} />;
  }
};

SkipToContent.propTypes = {
  /**
   * Css selector to main content area which will contain the first focusable element. This selector cannot return
   * more than a single result.
   */
  contentSelector: PropTypes.string,

  /** Indicates if the app is using a dark background header. This value is passed to the header links for styling. */
  light: PropTypes.bool,
};

SkipToContent.defaultProps = {
  contentSelector: '[role="main"]',
};
