import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { classNames } from "@swa-ui/string";

import { Transform, transformPropTypes } from "../Transform";
import styles from "./Fade.module.scss";

const CONTENT_SIZE = parseInt(styles.contentSize);

/**
 * Fade animates content on a page by change opacity/scale. This is typically used when a small area
 * needs to fade in or out potentially while it expands or collapses.
 */

export const Fade = React.memo((props) => {
  const { children, className, delay, duration, focalPoint, fullWidth, onTransformationEnd, to } =
    props;
  const [currentTo, setCurrentTo] = useState();

  useEffect(() => {
    setCurrentTo(to);
  }, [to]);

  return (
    <div {...getContainerProps()}>
      <Transform {...getTransformProps()}>{children}</Transform>
    </div>
  );

  function getContainerProps() {
    return {
      className: classNames(className, styles.fade, { [styles.standardWidth]: !fullWidth }),
      style:
        focalPoint && !fullWidth
          ? {
              transform: `translate(
              ${getCenter(focalPoint.x)}px, 
              ${getCenter(focalPoint.y)}px)`,
            }
          : undefined,
    };
  }

  function getTransformProps() {
    const playListTransformations = {
      ["FADE"]: ["fade", "explodeReset", "expandReset"],
      ["FADE-GROW"]: ["fade", "explode", "expand"],
      ["FADE-SHRINK"]: ["fade", "implode", "collapse"],
      ["FADED"]: ["faded", "explodeReset", "expandReset"],
      ["FADED-GROW"]: ["faded", "explode", "expand"],
      ["FADED-SHRINK"]: ["faded", "implode", "collapse"],
      ["SHOW"]: ["fadeReset", "explodeReset", "expandReset"],
      ["SHOW-GROW"]: ["fadeReset", "explode", "expand"],
      ["SHOW-SHRINK"]: ["fadeReset", "implode", "collapse"],
      [undefined]: ["fade", "implode", "collapse"],
    };

    return {
      className: styles.container,
      delay,
      duration,
      onTransformationEnd: currentTo === to ? onTransformationEnd : undefined,
      transformations: playListTransformations[currentTo || to],
      transformOrigin: "center",
    };
  }

  function getCenter(value) {
    return value - CONTENT_SIZE / 2;
  }
});

export const fadePropTypes = {
  /** Content that will be rendered. */
  children: PropTypes.node.isRequired,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Milliseconds value to defer the dot's animation start. */
  delay: transformPropTypes.delay,

  /** Length of animation in milliseconds. See Transform for details. */
  duration: transformPropTypes.duration,

  /** Position where center of child will render in pixels. */
  focalPoint: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),

  /**
   * Tells Fade children to fill its entire container - that is, set a height and width to 100%.
   * If not fullWidth is not given, Fade will set a default size for the children of 6rem. Having
   * a default facilitates the most common use cases for FadingDot and Ripple.
   */
  fullWidth: PropTypes.bool,

  /** Callback that will be called when fade animation is complete. No arguments will be returned. */
  onTransformationEnd: transformPropTypes.onTransformationEnd,

  /**
   * Type of animation to be preformed. These "actions" can update the child content's opacity and
   * scale.
   */
  to: PropTypes.oneOf([
    "FADE",
    "FADE-GROW",
    "FADE-SHRINK",
    "FADED",
    "FADED-GROW",
    "FADED-SHRINK",
    "SHOW",
    "SHOW-GROW",
    "SHOW-SHRINK",
  ]),
};

Fade.displayName = "Fade";

Fade.propTypes = fadePropTypes;

Fade.defaultProps = {
  color: "primary-500",
  fullWidth: true,
  to: "SHOW",
};
