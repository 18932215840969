import PropTypes from "prop-types";
import React, { useRef, useState } from "react";

import { useResizeObserver } from "@swa-ui/browser";
import { classNames } from "@swa-ui/string";

import { Transform } from "../Transform";
import styles from "./Width.module.scss";

/**
 * Width provides a way to have a div accommodate additional content smoothly.
 *
 * Note:
 * We will need to learn better when this component should be used - for the most part, we should not explicitly change
 * an element's width, but instead allow the browser to flow naturally.
 *
 * Important:
 * Do not set animate property until the page is fully laid out as the browser should be allowed to complete the page
 * layout without the application setting the element's width. Once an event occurs that will warrant the content to
 * change, then animate can be set so that it will adjust automatically.
 */

export const Width = (props) => {
  const { animate, children, className, id, onTransitionEnd } = props;
  const ref = useRef(null);
  const [contentWidth, setContentWidth] = useState(0);

  useResizeObserver({ callback: updateWidth, element: ref });

  return (
    <Transform {...getTransformProps()}>
      <div {...getPropsContainer()}>{children}</div>
    </Transform>
  );

  function getTransformProps() {
    let transformations = [];

    if (animate) {
      transformations = [
        {
          action: "width",
          amount: `${contentWidth}px`,
        },
      ];
    }

    return {
      className: classNames(className, styles.width),
      duration: animate ? undefined : 0,
      id,
      onTransformationEnd: handleTransformationEnd,
      transformations,
    };
  }

  function getPropsContainer() {
    return {
      className: styles.container,
      ref,
    };
  }

  function handleTransformationEnd() {
    onTransitionEnd && onTransitionEnd();
  }

  function updateWidth(value) {
    setContentWidth(value[0].contentRect.width);
  }
};

Width.propTypes = {
  /** The transition to and from concealed/revealed states will be animated by default. */
  animate: PropTypes.bool,

  /** Content that will be rendered when Area is revealed. */
  children: PropTypes.node,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * id can be useful when another DOM element "controls" the concealed/revealed state. The controlling element should
   * use aria-control to reflect this correlation.
   */
  id: PropTypes.string,

  /** Function called when the area is revealed or concealed. The revealed state will be returned as a boolean. */
  onTransitionEnd: PropTypes.func,
};

Width.defaultProps = {
  animate: false,
};
