import PropTypes from "prop-types";
import React from "react";

export const FlightStatus = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path d="M17.6,31.3c-7.6,0-13.7-6.1-13.7-13.7c0-7.6,6.1-13.7,13.7-13.7c7.6,0,13.7,6.2,13.7,13.7 C31.3,25.2,25.2,31.3,17.6,31.3 M17.6,0C7.9,0,0,7.9,0,17.6c0,9.7,7.9,17.6,17.6,17.6c9.7,0,17.6-7.9,17.6-17.6 C35.2,7.9,27.4,0,17.6,0" />
      <polygon points="25.3,21.2 19.7,16.4 19.7,7.7 15.6,7.7 15.6,18.3 22.7,24.4" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "0 0  36 36",
    };
  }
};

FlightStatus.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

FlightStatus.defaultProps = {
  role: "img",
};
