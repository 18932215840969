import PropTypes from "prop-types";
import React from "react";

export const Print = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <polygon points="29.5,23.8 21.7,23.8 19.7,23.8 11.9,23.8 8,32.8 19.7,32.8 21.7,32.8 33.4,32.8" />
      <path
        d="M37.3,13.4c-0.8,0-1.5-0.7-1.5-1.5c0-0.8,0.7-1.5,1.5-1.5c0.8,0,1.5,0.7,1.5,1.5
		C38.7,12.8,38.1,13.4,37.3,13.4 M31.6,8.3H9.4V3.2h22.2V8.3z M38.7,8.3h-3.9V1.6c0-0.9-0.7-1.6-1.6-1.6H7.8C6.9,0,6.2,0.7,6.2,1.6
		v6.7H2.3C1,8.3,0,9.3,0,10.6v15.1c0,1.2,1,2.3,2.3,2.3h3.5l2.5-5.8l1-2.4h2.6h7.8h2.1h7.8h2.6l1,2.4l2.5,5.8h3c1.2,0,2.3-1,2.3-2.3
		V10.6C40.9,9.3,39.9,8.3,38.7,8.3"
      />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "0 0 40.9 32.8",
    };
  }
};

Print.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Print.defaultProps = {
  role: "img",
};
