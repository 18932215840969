import PropTypes from "prop-types";
import React from "react";

export const MobileApps = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path
        d="M45.7,35.1H29.1V4.6h16.6V35.1z M39.2,37.8h-3.7c-0.3,0-0.6-0.2-0.6-0.5c0-0.3,0.3-0.5,0.6-0.5h3.7
		c0.3,0,0.6,0.2,0.6,0.5C39.8,37.6,39.5,37.8,39.2,37.8 M35.5,1.8h3.7c0.3,0,0.5,0.2,0.5,0.4c0,0.3-0.3,0.5-0.5,0.5h-3.7
		c-0.3,0-0.5-0.2-0.5-0.5C35,2,35.3,1.8,35.5,1.8 M47.5,4.7L47.5,4.7c0-1.5-0.6-3-2.8-3.7C42.2,0.1,37.4,0,37.4,0S32.5,0.1,30,0.9
		c-2.1,0.7-2.8,2.2-2.8,3.7l0,30.4l0-0.2c0,1.5,0.6,3.2,2.8,3.9c2.5,0.8,7.4,0.9,7.4,0.9s4.9-0.1,7.4-0.9c2.1-0.7,2.8-2.4,2.8-3.9h0
		V4.7z"
      />
      <path
        d="M17.5,33.2H1.8V5.5h15.7V33.2z M9.2,38.2c-1,0-1.8-0.8-1.8-1.8c0-1,0.8-1.8,1.8-1.8c1,0,1.8,0.8,1.8,1.8
		C11.1,37.3,10.2,38.2,9.2,38.2 M7.4,2.8H12c0.3,0,0.6,0.2,0.6,0.5c0,0.3-0.3,0.5-0.6,0.5H7.4c-0.3,0-0.5-0.2-0.5-0.4
		C6.9,3,7.1,2.8,7.4,2.8 M17.5,0.9H1.8C0.9,0.9,0,1.8,0,2.8v35.1c0,0.9,0.7,1.8,1.7,1.8l15.9,0c1,0,1.8-0.9,1.8-1.8V2.8
		C19.4,1.8,18.5,0.9,17.5,0.9"
      />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "0 0 47.5 39.7",
    };
  }
};

MobileApps.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

MobileApps.defaultProps = {
  role: "img",
};
