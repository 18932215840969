import PropTypes from "prop-types";
import React, { useState } from "react";

import { classNames } from "@swa-ui/string";

import { Button } from "../Button";
import { keyCodes } from "../defines/keyCodes";
import styles from "./VerticalList.module.scss";

/**
 * VerticalList renders a list of buttons and provides highlighting to show focus. VerticalList
 * also provides arrow/home/paging support. It doesn't provide scrolling or animated list changes.
 */

export const VerticalList = (props) => {
  const { className, items, onClick } = props;
  const [focusIndex, setFocusIndex] = useState(0);
  const [highlightIndex, setHighlightIndex] = useState(0);

  return (
    <div className={classNames(className)} onKeyDown={handleKeyDown} tabIndex={-1}>
      {items.map(renderItem)}
    </div>
  );

  function renderItem(item, index) {
    return <Button {...getButtonProps(index)}>{item}</Button>;
  }

  function getButtonProps(index) {
    return {
      className: getButtonClass(index),
      fullWidth: true,
      key: index,
      onClick: handleClick.bind(this, index),
      onFocus: () => {
        setHighlightIndex(index);
        setFocusIndex(index);
      },
      onMouseOver: () => {
        setHighlightIndex(index);
      },
      styleType: "no-style",
    };
  }

  function getButtonClass(index) {
    return classNames(styles.option, {
      [styles.focus]: index === focusIndex,
      [styles.hover]: index === highlightIndex,
    });
  }

  function handleClick(index) {
    processClick(index);
  }

  function handleKeyDown(event) {
    const { key } = event;

    if (key === keyCodes.KEY_DOWN || key === keyCodes.KEY_RIGHT) {
      processDown();
    } else if (key === keyCodes.KEY_LEFT || key === keyCodes.KEY_UP) {
      processUp();
    } else if (key === keyCodes.KEY_END || key === keyCodes.KEY_PAGE_DOWN) {
      processLast();
    } else if (key === keyCodes.KEY_ENTER || key === keyCodes.KEY_SPACE) {
      processSelect(event);
    } else if (key === keyCodes.KEY_HOME || key === keyCodes.KEY_PAGE_UP) {
      processFirst();
    }
  }

  function processDown() {
    setHighlightIndex((highlightIndex + 1) % items.length);
  }

  function processUp() {
    setHighlightIndex((highlightIndex - 1 + items.length) % items.length);
  }

  function processLast() {
    setHighlightIndex(items.length - 1);
  }

  function processSelect(event) {
    event.preventDefault();
    event.stopPropagation();
    processClick(highlightIndex);
  }

  function processFirst() {
    setHighlightIndex(0);
  }

  function processClick(index) {
    setFocusIndex(-1);
    setHighlightIndex(-1);
    onClick(index);
  }
};

VerticalList.propTypes = {
  /** Classes for positioning the component. */
  className: PropTypes.string,

  /** JSX items to be rendered. */
  items: PropTypes.arrayOf(PropTypes.node),

  /**
   * Callback to learn when an items has been clicked. The handler will receive the index of the
   * clicked item.
   */
  onClick: PropTypes.func,
};

VerticalList.defaultProps = {
  items: [],
};
