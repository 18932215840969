import PropTypes from "prop-types";
import React, { useContext } from "react";

import { PlacementFactory } from "../PlacementFactory";
import { PlacementsContext } from "../PlacementsContext";

/**
 * PlacementList component will be used to render multiple placements using a placement ID pattern.
 */

export const PlacementList = (props) => {
  const { additionalProps, listName, listPattern, maxPlacementsToDisplay } = props;
  const { WrapperComponent = React.Fragment } = additionalProps ?? {};
  const { placements } = useContext(PlacementsContext);

  return listName && listPattern
    ? getPlacementIdsToDisplay().map((id) => (
        <WrapperComponent key={id}>
          <PlacementFactory additionalProps={additionalProps} id={id} />
        </WrapperComponent>
      ))
    : null;

  function getPlacementIdsToDisplay() {
    const filteredPlacementIds = getFilteredPlacementIds();
    const sortedFilteredPlacementIds = sortPlacements(filteredPlacementIds);

    return limitPlacements(sortedFilteredPlacementIds);
  }

  function getFilteredPlacementIds() {
    const regex = new RegExp(listPattern);

    return Object.keys(placements).filter((key) => regex.test(key));
  }

  function sortPlacements(placementId) {
    return placementId.sort(
      (a, b) => parseFloat(placements[a]?.listOrder) - parseFloat(placements[b]?.listOrder)
    );
  }

  function limitPlacements(placementIds) {
    return maxPlacementsToDisplay?.[listName] !== undefined
      ? placementIds.slice(0, maxPlacementsToDisplay[listName])
      : placementIds;
  }
};

PlacementList.propTypes = {
  /**
   * Object with additional props.
   */
  additionalProps: PropTypes.object,

  /**
   * Root name of the placement list type currently displayed.
   */
  listName: PropTypes.string,

  /**
   * Regex string defining what list titles to display.
   */
  listPattern: PropTypes.string,

  /**
   * Object with listNames as keys, and number of max list items as values. If undefined, all list items will display.
   * Example: { placementListName1: 4, placementListName2: 1 }
   */
  maxPlacementsToDisplay: PropTypes.object,
};
