import PropTypes from "prop-types";
import React from "react";

export const Lake = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path d="M1.61475 2.59914C1.77911 2.59877 1.94178 2.63228 2.09261 2.69763C2.24343 2.76295 2.37916 2.85869 2.49131 2.97883C2.88899 3.35877 3.41786 3.57081 3.96787 3.57081C4.5179 3.57081 5.04674 3.35877 5.44443 2.97883C5.55707 2.85902 5.69308 2.76351 5.84402 2.69824C5.99498 2.63296 6.1577 2.59928 6.32217 2.59928C6.48663 2.59928 6.64935 2.63296 6.80031 2.69824C6.95128 2.76351 7.08726 2.85902 7.1999 2.97883C7.59759 3.35877 8.12645 3.57081 8.67646 3.57081C9.22649 3.57081 9.75534 3.35877 10.153 2.97883C10.2657 2.85902 10.4017 2.76351 10.5526 2.69824C10.7036 2.63296 10.8663 2.59928 11.0308 2.59928C11.1952 2.59928 11.3579 2.63296 11.5089 2.69824C11.6599 2.76351 11.7959 2.85902 11.9085 2.97883C12.3028 3.36468 12.8334 3.57942 13.3851 3.57649C13.5094 3.57649 13.6286 3.5271 13.7165 3.43919C13.8044 3.3513 13.8538 3.23207 13.8538 3.10774C13.8538 2.98342 13.8044 2.8642 13.7165 2.77628C13.6286 2.68837 13.5094 2.63899 13.3851 2.63899C13.2201 2.63955 13.0568 2.60587 12.9055 2.5401C12.7543 2.47436 12.6183 2.37794 12.5062 2.25696C12.1096 1.87486 11.5803 1.66138 11.0296 1.66138C10.4789 1.66138 9.94961 1.87486 9.55302 2.25696C9.44074 2.3774 9.30487 2.47345 9.15386 2.53912C9.00285 2.60479 8.83996 2.63868 8.67529 2.63868C8.51062 2.63868 8.34773 2.60479 8.19672 2.53912C8.04571 2.47345 7.90985 2.3774 7.79756 2.25696C7.40097 1.87486 6.8717 1.66138 6.32099 1.66138C5.77028 1.66138 5.24102 1.87486 4.84443 2.25696C4.73235 2.37735 4.59667 2.47335 4.44588 2.539C4.29506 2.60465 4.13235 2.63852 3.96787 2.63852C3.80338 2.63852 3.64068 2.60465 3.48986 2.539C3.33906 2.47335 3.20338 2.37735 3.09131 2.25696C2.69577 1.87327 2.1658 1.6596 1.61475 1.66165C1.49043 1.66165 1.3712 1.71103 1.28329 1.79894C1.19538 1.88685 1.146 2.00608 1.146 2.1304C1.146 2.25472 1.19538 2.37395 1.28329 2.46184C1.3712 2.54976 1.49043 2.59914 1.61475 2.59914Z" />
      <path d="M13.3851 5.8923C13.2203 5.89329 13.057 5.86005 12.9058 5.79471C12.7545 5.72934 12.6184 5.63329 12.5062 5.51262C12.1085 5.13267 11.5796 4.92065 11.0296 4.92065C10.4796 4.92065 9.95071 5.13267 9.55302 5.51262C9.44038 5.63245 9.3044 5.72794 9.15344 5.79321C9.00248 5.85848 8.83975 5.89216 8.67529 5.89216C8.51083 5.89216 8.3481 5.85848 8.19714 5.79321C8.0462 5.72794 7.9102 5.63245 7.79756 5.51262C7.39987 5.13267 6.87103 4.92065 6.32099 4.92065C5.77099 4.92065 5.24212 5.13267 4.84443 5.51262C4.732 5.63238 4.5962 5.72784 4.44543 5.79309C4.29468 5.85834 4.13214 5.892 3.96787 5.892C3.8036 5.892 3.64106 5.85834 3.49031 5.79309C3.33953 5.72784 3.20376 5.63238 3.09131 5.51262C2.69502 5.13063 2.16514 4.91869 1.61475 4.92199C1.49043 4.92199 1.3712 4.97137 1.28329 5.05929C1.19538 5.1472 1.146 5.26643 1.146 5.39074C1.146 5.51505 1.19538 5.6343 1.28329 5.72219C1.3712 5.81011 1.49043 5.85949 1.61475 5.85949C1.7792 5.85855 1.94204 5.89181 2.09295 5.95718C2.24385 6.02255 2.37951 6.11857 2.49131 6.23918C2.88789 6.62128 3.41716 6.83477 3.96787 6.83477C4.51858 6.83477 5.04784 6.62128 5.44443 6.23918C5.55707 6.11934 5.69308 6.02386 5.84402 5.95858C5.99498 5.89331 6.1577 5.85963 6.32217 5.85963C6.48663 5.85963 6.64935 5.89331 6.80031 5.95858C6.95128 6.02386 7.08726 6.11934 7.1999 6.23918C7.59649 6.62128 8.12575 6.83477 8.67646 6.83477C9.22717 6.83477 9.75644 6.62128 10.153 6.23918C10.2657 6.11934 10.4017 6.02386 10.5526 5.95858C10.7036 5.89331 10.8663 5.85963 11.0308 5.85963C11.1952 5.85963 11.3579 5.89331 11.5089 5.95858C11.6599 6.02386 11.7959 6.11934 11.9085 6.23918C12.304 6.62287 12.834 6.83655 13.3851 6.83449C13.51 6.83449 13.6298 6.78485 13.7182 6.69651C13.8065 6.60818 13.8561 6.48834 13.8561 6.3634C13.8561 6.23845 13.8065 6.11864 13.7182 6.03028C13.6298 5.94194 13.51 5.8923 13.3851 5.8923Z" />
      <path d="M13.3851 9.14079C13.2203 9.14177 13.057 9.10851 12.9058 9.04317C12.7545 8.9778 12.6184 8.88176 12.5062 8.7611C12.1082 8.38167 11.5794 8.17001 11.0296 8.17001C10.4797 8.17001 9.95099 8.38167 9.55302 8.7611C9.44038 8.88091 9.3044 8.97642 9.15344 9.04169C9.00248 9.10697 8.83975 9.14065 8.67529 9.14065C8.51083 9.14065 8.3481 9.10697 8.19714 9.04169C8.0462 8.97642 7.9102 8.88091 7.79756 8.7611C7.39959 8.38167 6.87084 8.17001 6.32099 8.17001C5.77115 8.17001 5.2424 8.38167 4.84443 8.7611C4.73217 8.88117 4.59644 8.97689 4.44567 9.04233C4.29487 9.10779 4.13226 9.14156 3.96787 9.14156C3.8035 9.14156 3.64087 9.10779 3.4901 9.04233C3.3393 8.97689 3.20357 8.88117 3.09131 8.7611C2.69577 8.3774 2.1658 8.16372 1.61475 8.16579C1.49043 8.16579 1.3712 8.21517 1.28329 8.30306C1.19538 8.39097 1.146 8.5102 1.146 8.63454C1.146 8.75885 1.19538 8.87808 1.28329 8.96599C1.3712 9.05388 1.49043 9.10329 1.61475 9.10329C1.7792 9.10233 1.94204 9.13558 2.09295 9.20095C2.24385 9.26632 2.37951 9.36236 2.49131 9.48295C2.88789 9.86505 3.41716 10.0785 3.96787 10.0785C4.51858 10.0785 5.04784 9.86505 5.44443 9.48295C5.55707 9.36314 5.69308 9.26763 5.84402 9.20236C5.99498 9.13708 6.1577 9.1034 6.32217 9.1034C6.48663 9.1034 6.64935 9.13708 6.80031 9.20236C6.95128 9.26763 7.08726 9.36314 7.1999 9.48295C7.59649 9.86505 8.12575 10.0785 8.67646 10.0785C9.22717 10.0785 9.75644 9.86505 10.153 9.48295C10.2657 9.36314 10.4017 9.26763 10.5526 9.20236C10.7036 9.13708 10.8663 9.1034 11.0308 9.1034C11.1952 9.1034 11.3579 9.13708 11.5089 9.20236C11.6599 9.26763 11.7959 9.36314 11.9085 9.48295C12.304 9.86665 12.834 10.0803 13.3851 10.0783C13.5094 10.0783 13.6286 10.0289 13.7165 9.94099C13.8044 9.85308 13.8538 9.73385 13.8538 9.60954C13.8538 9.4852 13.8044 9.36597 13.7165 9.27806C13.6286 9.19017 13.5094 9.14079 13.3851 9.14079Z" />
      <path d="M13.3851 12.4007C13.2203 12.4011 13.0573 12.3676 12.9061 12.3023C12.7549 12.237 12.6187 12.1413 12.5062 12.021C12.1085 11.6411 11.5796 11.4291 11.0296 11.4291C10.4796 11.4291 9.95071 11.6411 9.55302 12.021C9.44038 12.1409 9.3044 12.2363 9.15344 12.3016C9.00248 12.3669 8.83975 12.4006 8.67529 12.4006C8.51083 12.4006 8.3481 12.3669 8.19714 12.3016C8.0462 12.2363 7.9102 12.1409 7.79756 12.021C7.39987 11.6411 6.87103 11.4291 6.32099 11.4291C5.77099 11.4291 5.24212 11.6411 4.84443 12.021C4.732 12.1408 4.5962 12.2363 4.44543 12.3015C4.29468 12.3668 4.13214 12.4004 3.96787 12.4004C3.8036 12.4004 3.64106 12.3668 3.49031 12.3015C3.33953 12.2363 3.20376 12.1408 3.09131 12.021C2.69699 11.6352 2.16642 11.4204 1.61475 11.4234C1.49043 11.4234 1.3712 11.4728 1.28329 11.5607C1.19538 11.6486 1.146 11.7678 1.146 11.8921C1.146 12.0164 1.19538 12.1357 1.28329 12.2236C1.3712 12.3115 1.49043 12.3609 1.61475 12.3609C1.77935 12.3604 1.94226 12.3941 2.09315 12.4598C2.24404 12.5256 2.37963 12.622 2.49131 12.7429C2.88789 13.125 3.41716 13.3385 3.96787 13.3385C4.51858 13.3385 5.04784 13.125 5.44443 12.7429C5.55672 12.6225 5.69259 12.5264 5.8436 12.4607C5.9946 12.3951 6.15749 12.3612 6.32217 12.3612C6.48684 12.3612 6.64973 12.3951 6.80074 12.4607C6.95174 12.5264 7.08761 12.6225 7.1999 12.7429C7.59649 13.125 8.12575 13.3385 8.67646 13.3385C9.22717 13.3385 9.75644 13.125 10.153 12.7429C10.2653 12.6225 10.4012 12.5264 10.5522 12.4607C10.7032 12.3951 10.8661 12.3612 11.0308 12.3612C11.1954 12.3612 11.3583 12.3951 11.5093 12.4607C11.6603 12.5264 11.7962 12.6225 11.9085 12.7429C12.304 13.1266 12.834 13.3403 13.3851 13.3382C13.5094 13.3382 13.6286 13.2888 13.7165 13.2009C13.8044 13.113 13.8538 12.9938 13.8538 12.8695C13.8538 12.7452 13.8044 12.6259 13.7165 12.538C13.6286 12.4501 13.5094 12.4007 13.3851 12.4007Z" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "0 0 16 16",
    };
  }
};

Lake.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Lake.defaultProps = { role: "img" };
