import PropTypes from "prop-types";
import React from "react";

import { Section } from "@swa-ui/core";
import { classNames } from "@swa-ui/string";
import { convertToTitleCase } from "@swa-ui/string";

import styles from "./ContactInformation.module.scss";

export const ContactInformation = (props) => {
  const {
    city,
    contactNameBold,
    contactTypelabel,
    firstName,
    lastName,
    phoneNumber,
    postalCode,
    relationship,
    stateProvidenceRegion,
    street,
    styleType,
  } = props;

  return (
    <Section className={getContactInformationClassName()}>
      {contactTypelabel && <div className={getContactTypeLabelClassName()}>{contactTypelabel}</div>}
      {renderName()}
      {relationship && <div>{convertToTitleCase(relationship)}</div>}
      {phoneNumber && <div>{phoneNumber}</div>}
      {renderAddress()}
    </Section>
  );

  function renderAddress() {
    return (
      <div>
        {street && <span>{convertToTitleCase(`${street}`)}</span>}
        {city && <span>{convertToTitleCase(`, ${city}`)}</span>}
        {stateProvidenceRegion && <span>{`, ${stateProvidenceRegion}`}</span>}
        {postalCode && <span>{`, ${postalCode}`}</span>}
      </div>
    );
  }

  function renderName() {
    return (
      firstName &&
      lastName && (
        <div className={getContactNameClassName()}>
          {convertToTitleCase(`${firstName} ${lastName}`)}
        </div>
      )
    );
  }

  function getContactInformationClassName() {
    return classNames({
      [styles.secondaryContainer]: styleType === "secondary",
    });
  }

  function getContactNameClassName() {
    return classNames({
      [styles.bold]: contactNameBold,
    });
  }

  function getContactTypeLabelClassName() {
    return classNames({
      [styles.bold]: styleType === "secondary",
      [styles.secondaryContactType]: styleType === "secondary",
    });
  }
};

ContactInformation.propTypes = {
  /** Contact person address detail - city description. */
  city: PropTypes.string,

  /** Toggle to apply bold styling for contact name field. */
  contactNameBold: PropTypes.bool,

  /** Contact type label. */
  contactTypelabel: PropTypes.string,

  /** Contact person firstName. */
  firstName: PropTypes.string,

  /** Contact person lastName. */
  lastName: PropTypes.string,

  /** Contact person phone number. */
  phoneNumber: PropTypes.string,

  /** Contact person address detail - postal code description. */
  postalCode: PropTypes.string,

  /** Contact person relationship description. */
  relationship: PropTypes.string,

  /** Contact person address detail - region description. */
  stateProvidenceRegion: PropTypes.string,

  /** Contact person address detail - street description. */
  street: PropTypes.string,

  /** Parent Guardian style type. */
  styleType: PropTypes.oneOf(["primary", "secondary"]),
};
