import PropTypes from "prop-types";
import React from "react";

export const ShopDine = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path d="M0,12.2v25.2c0,1.1,0.7,1.9,2,1.9H22c1.3,0,2-0.8,2-1.9V12.2H0z" />
      <path
        d="M18.9,10.1h-3.4V8.5C15.5,6.5,14,5,12.1,5c-1.9,0-3.5,1.6-3.5,3.5v1.7H5.2V8.5c0-3.8,3.1-6.9,6.9-6.9
		c3.8,0,6.9,3.1,6.9,6.9V10.1z"
      />
      <path d="M8.7,16.3c0,1-0.8,1.8-1.8,1.8c-1,0-1.8-0.8-1.8-1.8c0-1,0.8-1.8,1.8-1.8C7.9,14.5,8.7,15.3,8.7,16.3" />
      <path d="M19.3,16.3c0,1-0.8,1.8-1.8,1.8c-1,0-1.8-0.8-1.8-1.8c0-1,0.8-1.8,1.8-1.8C18.5,14.5,19.3,15.3,19.3,16.3" />
      <path
        d="M36.9,0.8C36.9,0.5,36.5,0,36,0C35.5,0,35,0.5,35,0.8v8.4h-1.3V0.8c0-0.3-0.4-0.8-0.9-0.8
		c-0.5,0-0.9,0.5-0.9,0.8v8.4h-1.3V0.8c0-0.3-0.4-0.8-0.9-0.8c-0.5,0-0.9,0.5-0.9,0.8v8.4h0v4.7h0c0.1,1.7,1.2,3.1,2.8,3.6v21.8h2.8
		V17.4c1.5-0.6,2.5-1.9,2.6-3.6h0v-0.7c0,0,0,0,0,0V0.8z"
      />
      <path d="M45.1,16.6V0.3c-2.8,1-4.8,5.8-4.8,11.2c0,2.4,0.9,4.6,2,6.3v21.5h2.8L45.1,16.6L45.1,16.6z" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "0 0 47.5 39.7",
    };
  }
};

ShopDine.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

ShopDine.defaultProps = {
  role: "img",
};
