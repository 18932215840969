import PropTypes from "prop-types";
import React from "react";

import { Drawer } from "@swa-ui/core";
import i18n from "@swa-ui/locale";

import styles from "./ServiceErrorMessage.module.scss";

/**
 * Details of an error cause by a request to API endpoint
 */

export const ServiceErrorMessage = (props) => {
  const { children, experienceId, requestId, trackingCode } = props;
  const dateTime = new Date().toLocaleString("en-US", { hour12: false });

  return (
    <>
      <div data-test="serviceErrorMessageBody">{children}</div>
      <Drawer {...getDrawerProps()}>
        <div className={styles.content}>
          <b>{i18n("ServiceErrorMessage__ERROR_LEGEND")}:</b> {`${trackingCode} : ${dateTime}`}
          <br />
          <b>{i18n("ServiceErrorMessage__ERROR_INCIDENT_IDENTIFIER")}:</b>{" "}
          {`${experienceId} : ${requestId}`}
        </div>
      </Drawer>
    </>
  );

  function getDrawerProps() {
    return {
      alignedRight: true,
      className: styles.trace,
      "data-test": "serviceErrorMessageTrace",
      triggerContent: i18n("ServiceErrorMessage__ERROR_DETAILS"),
    };
  }
};

ServiceErrorMessage.propTypes = {
  /** Content for the message */
  children: PropTypes.node,

  /** Current user's experience identifier */
  experienceId: PropTypes.string,

  /** `requestId` provided in the response for tracking request through infrastructure */
  requestId: PropTypes.string,

  /** The 9 digit error code that identifies the error that occurred */
  trackingCode: PropTypes.string,
};
