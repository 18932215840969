import PropTypes from "prop-types";
import React from "react";

import { PlacementFactory, usePlacementIds } from "@swa-ui/placements";
import { classNames } from "@swa-ui/string";

import styles from "./DotcomMessages.module.scss";

export const DotcomMessages = (props) => {
  const { className } = props;
  const topMessagePlacementIds = usePlacementIds(/topMessageBlock.*/);
  const sortedPlacements = topMessagePlacementIds.sort();

  return (
    sortedPlacements.length > 0 && (
      <div className={classNames(styles.dotcomMessages, className)}>
        {sortedPlacements.map((placementId) => (
          <PlacementFactory id={placementId} key={placementId} />
        ))}
      </div>
    )
  );
};

DotcomMessages.propTypes = {
  /** Additional classes for positioning the component. */
  className: PropTypes.string,
};
