import PropTypes from "prop-types";
import React from "react";
import { animated, useSpring } from "react-spring";

export const Reverse = (props) => {
  const { className, custom, role, size, style } = props;
  const points1 = "18,4 7,4 7,1 1,6 7,11 7,8 18,8"; // top left
  const points2 = "1,4 12,4 12,1 18,6 12,11 12,8 1,8"; // top right
  const points3 = "1,13 12,13 12,10 18,15 12,20 12,17 1,17"; // bottom right
  const points4 = "18,13 7,13 7,10 1,15 7,20 7,17 18,17"; // bottom left
  const { morph } = useSpring({
    morph: custom && custom.type === "active" ? 1 : 0,
  });

  return (
    <svg {...getProps()}>
      <animated.polygon
        points={morph.interpolate({ output: [points1, points2] })}
      ></animated.polygon>
      <animated.polygon
        points={morph.interpolate({ output: [points3, points4] })}
      ></animated.polygon>
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      style,
      viewBox: "0 0 19 22",
    };
  }
};

Reverse.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Reverse.defaultProps = {
  role: "img",
};
