import PropTypes from "prop-types";
import React from "react";

export const Dollar = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path d="M17.5892095,25.5899582 L17.5892095,28 L14.1400734,28 L14.1400734,25.8410042 C12.4856913,25.7004184 10.2999015,25.0979079 8.69565217,24.2142259 L9.83867984,20.2376569 C11.6635135,20.9506276 14.3205515,21.8443515 16.2857569,21.4125523 C18.0002984,21.0410042 18.441467,19.4945607 16.4161022,18.6209205 C14.7115872,17.8476987 8.82599743,17.0443515 8.82599743,12.1238494 C8.82599743,10.0853556 10.3500343,7.27364017 14.1501,6.45020921 L14.1501,4 L17.5992361,4 L17.5992361,6.24937238 C19.0731402,6.34979079 20.5771239,6.6209205 22.3217451,7.17322176 C22.1512936,7.93640167 21.3993017,11.2401674 21.3993017,11.2401674 C20.1961147,10.8284519 18.2309093,10.1154812 16.3459163,10.2259414 C14.0197547,10.3564854 13.8493032,12.0635983 15.4234729,12.8468619 C18.6419981,14.3029289 23.3043478,15.5481172 23.3043478,19.8962343 C23.2943213,23.2401674 20.6874161,25.158159 17.5892095,25.5899582 L17.5892095,25.5899582 Z" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      style,
      viewBox: "0 0 32 32",
    };
  }
};

Dollar.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Dollar.defaultProps = {
  role: "img",
};
