import i18n from "@swa-ui/locale";

import { getMissingTemplateKeys } from "./getMissingTemplateKeys";
import interpolate from "./interpolate";
import { logMissingTemplateKeys } from "./logMissingTemplateKeys";

/**
 * preprocessContent recursively traverses through placement content and compiles template values for child .textContent and attribute properties.
 */

export const preprocessContent = (placement, templateDataRaw = {}) => {
  const missingTemplateKeys = getMissingTemplateKeys(placement, templateDataRaw);

  logMissingTemplateKeys(placement?.props?.id, missingTemplateKeys);

  const templateData = templateDataRaw;

  if (missingTemplateKeys.length) {
    missingTemplateKeys.forEach((key) => (templateData[key] = ""));
  }

  const children = placement.childContent ? placement.childContent : placement.content;
  const compiledAttributes = ["commandProps", "props", "styles"];
  const processedContent = JSON.parse(JSON.stringify(placement));

  if (children) {
    const processedContentArray = children.map((contentObj) =>
      preprocessContent(contentObj, templateData)
    );

    placement.childContent
      ? (processedContent.childContent = processedContentArray)
      : (processedContent.content = processedContentArray);
  }

  if (processedContent.textContent) {
    processedContent.textContent = i18n(interpolate(processedContent.textContent, templateData));
  }

  compiledAttributes.forEach((attribute) => {
    const processedAttribute = {};
    const attributeObject = processedContent[attribute];

    if (shouldProcessAttribute(attributeObject)) {
      Object.keys(attributeObject).forEach((key) => {
        processedAttribute[key] = interpolate(attributeObject[key], templateData);
      });
      processedContent[attribute] = processedAttribute;
    }
  });

  return processedContent;

  function shouldProcessAttribute(attribute) {
    return !!(attribute && typeof attribute === "object" && Object.keys(attribute).length !== 0);
  }
};
