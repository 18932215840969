import PropTypes from "prop-types";
import React from "react";

import { Heading, HorizontalList } from "@swa-ui/core";
import {
  CallToAction,
  IconAdapter,
  InformationTipAdapter,
  PlacementFactory,
  PlacementList,
  SvgAdapter,
  FlexPlacement as SwaUiFlexPlacement,
} from "@swa-ui/placements";

/**
 * FlexPlacement configured for responsive applications.
 */

export const FlexPlacement = (props) => {
  const {
    additionalProps,
    basePath,
    configuredCommands,
    placement,
    placementData,
    supportedComponents,
  } = props;

  return <SwaUiFlexPlacement {...getFlexPlacementProps()} />;

  function getFlexPlacementProps() {
    return {
      additionalProps,
      basePath,
      configuredCommands,
      placement: {
        ...placement,
        flexSettings: {
          ...placement.flexSettings,
          disableAbsolutePositioning: true,
        },
      },
      placementData,
      supportedComponents: {
        Button: CallToAction,
        Heading,
        HorizontalList,
        Icon: IconAdapter,
        InfoBox: InformationTipAdapter,
        Link: CallToAction,
        Placement: PlacementFactory,
        PlacementList,
        Svg: SvgAdapter,
        ...supportedComponents,
      },
      supportedElements: {
        a: "a",
        b: "b",
        br: "br",
        caption: "caption",
        col: "col",
        colgroup: "colgroup",
        div: "div",
        em: "em",
        h1: "h1",
        h2: "h2",
        h3: "h3",
        h4: "h4",
        h5: "h5",
        h6: "h6",
        i: "i",
        iframe: "iframe",
        img: "img",
        li: "li",
        p: "p",
        section: "section",
        span: "span",
        strong: "strong",
        sub: "sub",
        sup: "sup",
        table: "table",
        tbody: "tbody",
        td: "td",
        tfoot: "tfoot",
        th: "th",
        thead: "thead",
        tr: "tr",
        u: "u",
        ul: "ul",
      },
    };
  }
};

FlexPlacement.propTypes = {
  /** Additional props to supply to the rendered placement component */
  additionalProps: PropTypes.object,

  /** Base path of the application running in the browser. */
  basePath: PropTypes.string,

  /** An object containing functions to allow FlexPlacement to trigger application specific code. */
  configuredCommands: PropTypes.objectOf(PropTypes.func),

  /** Placement details supplied by WCM. */
  placement: PropTypes.object,

  /** Placement data supplied by WCM. */
  placementData: PropTypes.object,

  /** A list of components that map to placement `displayTypes` that can be rendered. */
  supportedComponents: PropTypes.object,
};
