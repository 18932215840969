import PropTypes from "prop-types";
import React from "react";

export const CheckCircle = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path
        d="M250,0C111.9,0,0,111.9,0,250s111.9,250,250,250s250-111.9,250-250S388.1,0,250,0z M414.5,177.1l-198,201.6
		c-5,5.1-13.1,5.1-18,0l-4-4.1l-4.3-4.4l-18.8-19.1c-0.1-0.1-0.1-0.1-0.1-0.2l-85.8-88c-5-5.1-5-13.3,0-18.3l36.9-37.5
		c5-5.1,13-5.1,18,0l67.2,69l152.1-154.8c5-5.1,13-5.1,18,0l36.8,37.5C419.5,163.8,419.5,172,414.5,177.1z"
      />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      style,
      viewBox: "0 0 500 500",
    };
  }
};

CheckCircle.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

CheckCircle.defaultProps = { role: "img" };
