import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import { classNames } from "@swa-ui/string";

import { Area } from "../Area";
import { keyCodes } from "../defines/keyCodes";
import { Link } from "../Link";
import { TransitionBlock } from "../TransitionBlock";
import styles from "./Tabs.module.scss";

const CONTENT_TRANSITION_IN = { delay: 150, duration: 400, transformations: ["fadeReset"] };
const CONTENT_TRANSITION_OUT = { duration: 200, transformations: ["fade"] };

/**
 * Tabs a list of tabs to navigate, which when selected, will update content panel below buttons.
 */
export const Tabs = (props) => {
  const {
    className,
    defaultSelectedIndex,
    fullWidth = false,
    items,
    onChange,
    padding = "normal",
    selectedIndex,
  } = props;
  const [currentItems, setCurrentItems] = useState([...items]);
  const [currentSelectedIndex, setCurrentSelectedIndex] = useState(getInitialSelectedIndex());
  const [focus, setFocus] = useState(false);
  const [hover, setHover] = useState(false);
  const tabsRef = useRef();

  useEffect(() => {
    setCurrentItems([...items]);
  }, [items]);

  useEffect(() => {
    selectedIndex !== undefined && setCurrentSelectedIndex(selectedIndex);
  }, [selectedIndex]);

  return (
    <>
      <ul {...getProps()}>{(currentItems || items).map(renderTab)}</ul>
      <div className={styles.content}>
        <Area revealed>
          <TransitionBlock {...getTransitionBlockProps()}>
            {currentItems[currentSelectedIndex].content}
          </TransitionBlock>
        </Area>
      </div>
    </>
  );

  function renderTab(item, index) {
    const { label } = item;

    return (
      <li {...getTabContainerProps(index)}>
        <Link {...getSizeDefiningLinkProps()}>{label}</Link>
        <Link {...getLinkProps(index)}>{label}</Link>
        <div className={getHoverIndicatorClass(index)} />
      </li>
    );
  }

  function getProps() {
    return {
      className: classNames(styles.tabsContainer, className),
      onBlur: () => setFocus(false),
      onFocus: () => setFocus(true),
      onKeyDown: handleKeyDown,
      ref: tabsRef,
      tabIndex: 0,
    };
  }

  function getTabContainerProps(index) {
    return {
      className: getTabClass(),
      key: currentItems[index].label,
      onMouseEnter: () => setHover(index),
      onMouseLeave: () => setHover(-1),
    };
  }

  function getSizeDefiningLinkProps() {
    return {
      "aria-hidden": true,
      className: classNames(styles.tab, styles.boldTextForPlacementOnly),
      emphasis: true,
      paddingForFocus: false,
      showUnderline: false,
      style: getPadding(),
      tabIndex: -1,
    };
  }

  function getLinkProps(index) {
    return {
      className: classNames(styles.tab, styles.onTopAndVisible, {
        [styles.focus]: isFocused(index),
      }),
      emphasis: index === currentSelectedIndex,
      onClick: () => updateSelectedIndex(index),
      onFocus: () => updateSelectedIndex(index),
      paddingForFocus: false,
      showUnderline: false,
      style: getPadding(),
      tabIndex: -1,
    };
  }

  function getPadding() {
    const paddingSizes = {
      none: "0",
      normal: "1rem",
      small: "0.5rem",
    };

    return { padding: paddingSizes[padding] };
  }

  function getTransitionBlockProps() {
    return {
      animationToken: currentSelectedIndex,
      transitionIn: CONTENT_TRANSITION_IN,
      transitionOut: CONTENT_TRANSITION_OUT,
    };
  }

  function getHoverIndicatorClass(index) {
    return classNames(styles.hoverIndicator, {
      [styles.hover]: hover === index,
      [styles.focus]: currentSelectedIndex === index,
    });
  }

  function getTabClass() {
    return classNames(styles.tabContainer, { [styles.fullWidth]: fullWidth });
  }

  function handleKeyDown(event) {
    const { key } = event;
    const tabsCount = currentItems.length;
    let newSelectedIndex = currentSelectedIndex;

    if (key === keyCodes.KEY_RIGHT) {
      newSelectedIndex = (currentSelectedIndex + 1) % tabsCount;
    } else if (key === keyCodes.KEY_LEFT) {
      newSelectedIndex = (currentSelectedIndex - 1 + tabsCount) % tabsCount;
    } else if (key === keyCodes.KEY_END || key === keyCodes.KEY_PAGE_DOWN) {
      newSelectedIndex = currentItems.length - 1;
    } else if (key === keyCodes.KEY_HOME || key === keyCodes.KEY_PAGE_UP) {
      newSelectedIndex = 0;
    }

    if (newSelectedIndex !== currentSelectedIndex) {
      setCurrentSelectedIndex(newSelectedIndex);
    }
  }

  function isFocused(index) {
    return focus && index === currentSelectedIndex;
  }

  function getInitialSelectedIndex() {
    return selectedIndex ?? defaultSelectedIndex ?? 0;
  }

  function updateSelectedIndex(index) {
    setCurrentSelectedIndex(index);
    tabsRef.current.focus();
    onChange?.(index);
  }
};

Tabs.propTypes = {
  /**
   * Additional classes for positioning the component. Given classes may only position this component
   * for layout purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * Initial value for selected tab when not "controlled". selectedIndex should not be specified
   * when defaultSelectedIndex is given.
   */
  defaultSelectedIndex: PropTypes.number,

  /** Setting this prop will set all buttons to the same width and spread with of its container. */
  fullWidth: PropTypes.bool,

  /** Content that will be rendered. */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.node,
      label: PropTypes.string,
    })
  ).isRequired,

  /** Event handle to be infomed when a new tab is selected. */
  onChange: PropTypes.func,

  /**
   * Predefined padding size to replace default padding.
   */
  padding: PropTypes.oneOf(["none", "normal", "small"]),

  /**
   * Index of selected tab when the caller controls which tab is selected. When selectIndex is not
   * given, Tabs will handle the selection of tabs internally.
   */
  selectedIndex: PropTypes.number,
};
