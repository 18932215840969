import PropTypes from "prop-types";
import React from "react";

import { classNames } from "@swa-ui/string";

import { Day } from "./Day";
import styles from "./Month.module.scss";

const INVALID = false;
const UNSELECTABLE = false;
const VALID = true;

/**
 * Component to display a calender representation of a month. Primarily, for the specified month, it determines how to
 * layout the grid of Day cells, which requires determining what day the month starts on and how many days in the
 * month.
 *
 * Currently not intended to be used outside of DateSelector.
 */

const MonthForwardRef = React.forwardRef((props, ref) => {
  const {
    className,
    dateEnd,
    dateStart,
    daysOfWeekInitials,
    directionChange,
    firstBookableDate = new Date(),
    focusField,
    hoverDate,
    lastBookableDate,
    minimumRows,
    month,
    numberDates,
    onClick,
    onMouseEnter,
    onMouseLeave,
    year,
  } = props;

  return (
    <div className={classNames(styles.month, className)} ref={ref}>
      {renderMonthAndYearTitle()}
      <div className={styles.days}>
        <div className={styles.daysOfWeekContainer}>{renderDaysOfWeek()}</div>
        {getRows().map(renderDays)}
      </div>
    </div>
  );

  function renderMonthAndYearTitle() {
    return (
      <div data-unstable className={styles.monthAndYearTitle}>
        {new Date(year, parseInt(month) - 1).toLocaleString("default", {
          month: "long",
        })}{" "}
        {year}
      </div>
    );
  }

  function renderDaysOfWeek() {
    const dayNameInitials = daysOfWeekInitials.split("");

    return dayNameInitials.map((day, index) => (
      <div className={styles.dayOfWeek} key={index}>
        {day}
      </div>
    ));
  }

  function renderDays(row, index) {
    return <div key={`row-${index}`}>{row.map(renderDay)}</div>;
  }

  function renderDay(dayInfo, index) {
    return <Day {...getDayProps(dayInfo, index)} />;
  }

  function getDayProps(dayInfo, index) {
    const lastBookable = new Date(lastBookableDate).getTime();
    const lastDay = new Date(isValidDate(dateEnd) ? dateEnd : hoverDate).getTime();
    const thisDay = new Date(dayInfo.date).getTime();
    const inRange =
      dateStart &&
      numberDates === 2 &&
      !dayInfo.disabled &&
      lastDay <= lastBookable &&
      new Date(dateStart).getTime() < thisDay &&
      lastDay > thisDay;

    return {
      ...dayInfo,
      dateEnd: !!(numberDates === 2 && dateEnd && dayInfo.date === dateEnd),
      dateStart: !!(dateStart && dayInfo.date === dateStart),
      directionChange,
      focusField,
      hover: hoverDate && dayInfo.date === hoverDate,
      inRange,
      key: `day-${index}`,
      numberDates,
      onClick: () => handleClick(dayInfo.date),
      onMouseEnter: () => handleMouseEnter(dayInfo.date),
      onMouseLeave,
    };
  }

  function handleClick(date) {
    onClick && onClick(date);
  }

  function handleMouseEnter(date) {
    onMouseEnter && onMouseEnter(date);
  }

  function getRows() {
    const days = getDays();
    const rows = [];
    let index;

    for (index = 0; index < 6; index += 1) {
      rows.push(days.splice(0, 7));
    }

    return rows;
  }

  function getDays() {
    const lastMonthDays = getPlaceholdersForLastMonth();
    const thisMonthDays = getDaysForThisMonth();
    const nextMonthDays = getPlaceholdersForNextMonth();
    let dayCells = lastMonthDays.concat(thisMonthDays, nextMonthDays);

    if (minimumRows && dayCells.length !== 7 * minimumRows) {
      dayCells = dayCells.concat(getBlankRowOfDays());
    }

    return dayCells;
  }

  function getPlaceholdersForLastMonth() {
    const startDatePrevMonth = new Date(
      parseInt(year),
      parseInt(month) - 1,
      -getDayOfWeekIndex() + 1
    ).getDate();

    return Array.from({ length: getDayOfWeekIndex() }, (_, index) =>
      getDayInfo(parseInt(month) - 1, index + startDatePrevMonth, UNSELECTABLE, INVALID)
    );
  }

  function getDaysForThisMonth() {
    return Array.from({ length: getDaysInMonth() }, (_, index) =>
      getDayInfo(parseInt(month), index + 1, isSelectable(index), VALID)
    );
  }

  function isSelectable(day) {
    const firstDate = new Date(firstBookableDate);
    const firstDay = firstDate.getDate();
    const firstMonth = firstDate.getMonth() + 1;
    const lastDate = new Date(lastBookableDate);
    const lastDay = lastDate.getDate();
    const lastMonth = lastDate.getMonth() + 1;
    let selectable = true;

    if (
      (parseInt(month) === firstMonth && day + 1 < firstDay) ||
      (parseInt(month) === lastMonth && day + 1 > lastDay)
    ) {
      selectable = false;
    }

    return selectable;
  }

  function isValidDate(value) {
    const date = new Date(value);

    return date instanceof Date && !isNaN(date);
  }

  function getPlaceholdersForNextMonth() {
    const gapsToFill = 7 - ((getDayOfWeekIndex() + getDaysInMonth()) % 7);
    let dates = [];

    if (gapsToFill !== 7) {
      dates = Array.from({ length: gapsToFill }, () => getDayInfo("", 0, UNSELECTABLE, INVALID));
    }

    return dates;
  }

  function getBlankRowOfDays() {
    return Array.from({ length: 7 }, () => getDayInfo("", 0, false, INVALID));
  }

  function getDayInfo(monthForDay, index, selectable, valid) {
    return {
      content: `${valid ? index : ""}`,
      date: selectable ? `${zeroPad(monthForDay)}/${zeroPad(index)}/${year}` : undefined,
      selectable,
    };
  }

  function getDayOfWeekIndex() {
    return new Date(parseInt(year), parseInt(month) - 1).getDay();
  }

  function getDaysInMonth() {
    return new Date(parseInt(year), parseInt(month), 0).getDate();
  }

  function zeroPad(value) {
    return `${value}`.padStart(2, "0");
  }
});

export const Month = React.memo(MonthForwardRef, skipRender);

function skipRender(prevProps, nextProps) {
  return (
    propsSame(prevProps, nextProps) ||
    isInvalid(nextProps.month, nextProps.year, nextProps.lastBookableDate)
  );
}

function propsSame(prevProps, nextProps) {
  /* eslint-disable -- disable the rule that prefers lodash _.isMatch */
  return (
    prevProps.month === nextProps.month &&
    prevProps.year === nextProps.year &&
    prevProps.dateEnd === nextProps.dateEnd &&
    prevProps.dateStart === nextProps.dateStart &&
    prevProps.hoverDate === nextProps.hoverDate
  );
  /* eslint-enable */
}

function isInvalid(month, year, lastBookableDate) {
  const lastDate = new Date(lastBookableDate);
  const lastMonth = lastDate.getMonth() + 1;
  const lastYear = lastDate.getFullYear();
  const todayDate = new Date();
  const todayMonth = todayDate.getMonth() - 1;
  const todayYear = todayDate.getFullYear();
  const workingMonth = parseInt(month);
  const workingYear = parseInt(year);
  const workingDate = new Date(year, workingMonth - 1).getTime();

  return (
    isNaN(workingDate) ||
    year < todayYear ||
    year > lastYear ||
    (workingMonth < todayMonth && workingYear === todayYear) ||
    (workingMonth > lastMonth && workingYear === lastYear)
  );
}

Month.displayName = "Month";

Month.propTypes = {
  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * End date when a range is needed. This string should be formatted with two digit months and dates, and four digits
   * for the year: 10/02/2001.
   */
  dateEnd: PropTypes.string,

  /**
   * For used for the starting date in a range, on when only one date is needed. This string should be formatted with
   * two digit months and dates, and four digits for the year.
   */
  dateStart: PropTypes.string,

  /** Initial letters of each day of the week: Sunday, Monday. */
  daysOfWeekInitials: PropTypes.string,

  /**
   * directionChange is given to Day so that it animate from left or right.
   */
  directionChange: PropTypes.oneOf(["next", "previous"]),

  /** First date that can be booked. Should be in this format: MM/DD/YYYY. */
  firstBookableDate: PropTypes.string,

  /** Indicates which date the traveler is selecting. */
  focusField: PropTypes.oneOf(["end", "start"]),

  /** Date string when the mouse is over a calendar date. */
  hoverDate: PropTypes.string,

  /** Last date that can be booked. Should be in this format: MM/DD/YYYY. */
  lastBookableDate: PropTypes.string,

  /**
   * Force Month to have at least a certain number of rows. This can ensure that all months, when displayed side by
   * side, will be the same height.
   */
  minimumRows: PropTypes.number,

  /**
   * Two digit month to use to display the month calendar. If not given, the year is determined by the dateStart prop
   * if given, otherwise the current month will be used.
   */
  month: PropTypes.string,

  /** One or two dates can be selected so the calendar will allow for start and end dates. */
  numberDates: PropTypes.number,

  /** Optional event handler to learn when a calendar date is selected. */
  onClick: PropTypes.func,

  /** Optional event handler to learn when the mouse is hovering over a calendar date. */
  onMouseEnter: PropTypes.func,

  /** Optional event handler to learn when the mouse is no longer hovering over a calendar date. */
  onMouseLeave: PropTypes.func,

  /**
   * Four digit year to use to display the month calendar. If not given, the year is determined by the dateStart prop
   * if given, otherwise the current year will be used.
   */
  year: PropTypes.string,
};
