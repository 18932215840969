export const removeDuplicateValues = (originalObject) => {
  const entries = Object.entries(originalObject);
  const uniqueEntries = entries.filter(
    (entry, index, array) => array.findIndex((element) => element[1] === entry[1]) === index
  );

  return Object.fromEntries(uniqueEntries);
};

export const removeExtraKeys = (keyConfigMap, jwksState) => {
  const keyConfigMapValues = Object.values(keyConfigMap);

  const filteredEntries = Object.entries(jwksState).filter(([key]) =>
    keyConfigMapValues.includes(key)
  );

  return Object.fromEntries(filteredEntries);
};

export const removeObjectKey = (stateObject, keyToRemove) => {
  delete stateObject[keyToRemove];

  return stateObject;
};
