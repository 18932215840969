import accounting from "accounting";

import { getBootstrapData } from "@swa-ui/bootstrap";

export default function currencyParser(number, currencyType) {
  const currencySettings = getBootstrapData("currency-settings");
  const currencySetting = currencySettings[currencyType];

  return {
    maxValueSign: currencySetting?.maxValueSign,
    negative: number < 0,
    number: Math.abs(number),
    symbol: currencySetting?.symbol,
    symbolLabel: currencySetting?.symbolLabel,
    symbolPosition: currencySetting?.symbolPosition,

    fullValue() {
      return accounting.formatMoney(this.number, {
        format: currencySetting?.formatWithoutSymbol,
        precision: currencySetting?.fullPrecision,
      });
    },

    fullValueWithSymbol() {
      return accounting.formatMoney(this.number, {
        format: currencySetting?.formatWithSymbol,
        precision: currencySetting?.fullPrecision,
        symbol: currencySetting?.symbol,
      });
    },

    fullValueWithLabel() {
      return accounting.formatMoney(this.number, {
        format: currencySetting?.formatWithLabel,
        precision: currencySetting?.fullPrecision,
        symbol: currencySetting?.symbolLabel,
      });
    },

    fullValueWithNegativeSign() {
      return this.getValueAndNegativeSign("fullValue");
    },

    fullValueWithSymbolAndNegativeSign() {
      return this.getValueAndNegativeSign("fullValueWithSymbol");
    },

    fullValueWithLabelAndNegativeSign() {
      return this.getValueAndNegativeSign("fullValueWithLabel");
    },

    roundedDownValue() {
      return this.getRoundedValue("down");
    },

    roundedDownValueWithSymbol() {
      return this.getRoundedValue("down", "withSymbol");
    },

    roundedDownValueWithLabel() {
      return this.getRoundedValue("down", "withLabel");
    },

    roundedValue() {
      return this.getRoundedValue();
    },

    roundedValueWithSymbol() {
      return this.getRoundedValue("default", "withSymbol");
    },

    roundedValueWithLabel() {
      return this.getRoundedValue("default", "withLabel");
    },

    roundedUpValue() {
      return this.getRoundedValue("up");
    },

    roundedUpValueWithSymbol() {
      return this.getRoundedValue("up", "withSymbol");
    },

    roundedUpValueWithLabel() {
      return this.getRoundedValue("up", "withLabel");
    },

    getValueAndNegativeSign(fullValueFormat) {
      const negative = this.negative ? "-" : "";

      return negative + this[fullValueFormat](this.number, currencySetting);
    },

    getRoundedValue(roundType, formatType) {
      const mathMethods = {
        default: "round",
        up: "ceil",
        down: "floor",
      };
      const formats = {
        withSymbol: { symbol: "symbol", format: "formatWithSymbol" },
        withLabel: { symbol: "symbolLabel", format: "formatWithLabel" },
      };
      const value = Math[mathMethods[roundType || "default"]](this.number);
      const settings = {
        precision: 0,
      };

      if (formatType) {
        settings.format = currencySetting[formats[formatType].format];
        settings.symbol = currencySetting[formats[formatType].symbol];
      } else {
        settings.format = currencySetting.formatWithoutSymbol;
      }

      return accounting.formatMoney(value, settings);
    },
  };
}
