import PropTypes from "prop-types";
import React from "react";

import { Transform, transformPropTypes } from "../Transform";

/**
 * Step is a slightly simplified way to pass props to Transform. It is intended specifically for use by
 * ControlledPlayList.
 */

export const Step = (props) => {
  const { className, onStepEnd, step, transformOrigin } = props;
  const { children } = step;

  return (
    <Transform {...getTransformProps()}>
      {typeof children === "function" ? children() : children}
    </Transform>
  );

  function getTransformProps() {
    const { clearAll, delay, duration, transformations } = step;

    return {
      className,
      clearAll,
      delay,
      duration,
      onTransformationEnd: onStepEnd,
      precision: 0.1,
      transformations: transformations,
      transformOrigin: step.transformOrigin || transformOrigin,
    };
  }
};

export const stepPropTypes = {
  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Informs the caller between each set of actions. */
  onStepEnd: PropTypes.func,

  /** Animation options to apply to child content. See Transform for more info. */
  step: PropTypes.shape({
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.string]),
    clearAll: transformPropTypes.clearAll,
    delay: transformPropTypes.delay,
    duration: transformPropTypes.duration,
    transformations: transformPropTypes.transformations,
    transformOrigin: transformPropTypes.transformOrigin,
  }).isRequired,

  /**
   * transformOrigin pertains to how things are rotated and scaled. See Transform for more info. transformOrigin can
   * be given for each step, or can be given at the component to affect all steps.
   */
  transformOrigin: transformPropTypes.transformOrigin,
};

Step.propTypes = stepPropTypes;
