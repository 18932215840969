import PropTypes from "prop-types";
import React from "react";

export const Dining = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path
        d="M95.106,82.792c1.461-1.018,2.844-1.82,3.865-2.089c2.521-0.636,5.582-0.795,6.496-0.834
    c3.208-0.127,6.377-1.23,8.823-3.681l25.476-25.951c0,0,35.402-33.781,22.738-46.439c-3.268-3.263-8.555-3.25-11.818,0.005
    l-67.52,67.52l-9.494-9.155l-4.176-18.201c-0.873-3.772-2.756-7.374-5.699-10.318c-0.67-0.663-1.383-1.28-2.098-1.843L23.024,0.646
    l-0.01-0.004v0.004c-1.199-0.932-2.92-0.859-4.021,0.246c-1.094,1.097-1.168,2.823-0.244,4.021l32.688,32.734
    c1.299,1.299,1.299,3.393-0.01,4.691c-1.289,1.291-3.388,1.291-4.679,0L13.958,9.706c-1.093-1.109-2.923-0.857-4.024,0.248
    c-1.105,1.099-1.287,2.997-0.232,4.023l32.631,32.784c1.291,1.293,1.291,3.391,0,4.69c-1.299,1.289-3.393,1.289-4.691,0
    L4.901,18.755c-1.182-0.928-2.91-0.859-4.009,0.243c-1.11,1.102-1.177,2.819-0.242,4.017l-0.032,0.034l31.161,38.669
    c0.569,0.734,1.179,1.431,1.831,2.108c2.963,2.934,6.552,4.835,10.339,5.689l-0.01,0.01l18.188,4.131l9.18,9.55L12.2,142.314
    c-4.071,4.059-4.071,10.644,0,14.695c4.055,4.071,10.633,4.071,14.694,0l55.358-62.414l59.68,62.061
    c4.057,4.064,10.645,4.064,14.696,0c4.071-4.061,4.071-10.643,0-14.705L95.106,82.792z"
      />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      style,
      viewBox: "0 0 165 160",
    };
  }
};

Dining.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Dining.defaultProps = {
  role: "img",
};
