import PropTypes from "prop-types";
import React from "react";

export const VehicleSize = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path
        d="M4,20.9c-1.1,0-1.9-0.9-1.9-1.9c0-1.1,0.9-1.9,1.9-1.9c1.1,0,1.9,0.9,1.9,1.9C5.9,20.1,5.1,20.9,4,20.9
		 M12.6,20.9l-4.6,0v-2c0,0,2.4,0,4.6,0v-4.2H4.9c0,0,2.2-4.2,2.2-4.2h5.7c0.2-0.8,0.7-1.3,1.2-1.8h-3.7h0H7.8h0
		c-1,0-1.7,0.3-2.2,0.8C5.4,9.8,5.2,10.1,5,10.5l-1.4,2.4H2c-0.5,0-0.7,0.4-0.7,0.9c0,0.5,0.2,0.9,0.7,0.9h0.7l-0.4,0.3
		c-0.9,0.2-2.3,1-2.3,2.8v9.9c0,0.4,0.3,0.7,0.7,0.7h2.2c0.4,0,0.7-0.3,0.7-0.7v-2.4H4h8.6V20.9z"
      />
      <path
        d="M18.5,14.8c0-1.5,1.2-2.8,2.8-2.8c1.5,0,2.8,1.2,2.8,2.8c0,1.5-1.2,2.8-2.8,2.8C19.8,17.6,18.5,16.4,18.5,14.8
		 M27.1,17.6v-2.9c0,0,12.1,0,12.1,0l0,2.9L27.1,17.6z M42.2,14.8c0-1.5,1.2-2.8,2.8-2.8c1.5,0,2.8,1.2,2.8,2.8
		c0,1.5-1.2,2.8-2.8,2.8C43.5,17.6,42.2,16.4,42.2,14.8 M43.7,8.6H22.6c0,0,3.2-6.1,3.2-6.1h14.8C40.5,2.6,43.7,8.6,43.7,8.6
		 M15.5,12.9v14.3c0,0.6,0.4,1,1,1h3.1c0.6,0,1-0.4,1-1v-3.5h0.7H45h0.7v3.5c0,0.6,0.4,1,1,1h3.1c0.6,0,1-0.4,1-1V12.9
		c0-2.6-2.1-3.7-3.4-4l-0.6-0.4h1.1c0.7,0,1-0.6,1-1.2c0-0.7-0.3-1.2-1-1.2h-2.4l-2-3.5c-0.2-0.5-0.5-1-0.9-1.4
		C41.9,0.4,40.9,0,39.4,0h0H36h0h-5.7h0h-3.4h0c-1.4,0-2.5,0.4-3.2,1.1C23.3,1.5,23,2,22.8,2.5l-2,3.5h-2.4c-0.7,0-1,0.6-1,1.2
		c0,0.7,0.3,1.2,1,1.2h1.1l-0.6,0.4C17.6,9.2,15.5,10.3,15.5,12.9"
      />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "0 0 52 29",
    };
  }
};

VehicleSize.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

VehicleSize.defaultProps = {
  role: "img",
};
