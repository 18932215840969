import PropTypes from "prop-types";
import React from "react";

export const AllInclusive = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path
        d="M8.6,18.5v-2c3.9-0.4,6.6-3.4,6.6-7.3c0-0.1,0-0.1,0-0.2l-2.1-8.3C13,0.3,12.6,0,12.1,0H3.1c-0.5,0-0.9,0.3-1,0.7L0,9
	c0,0.1,0,0.1,0,0.2c0,3.9,2.7,6.9,6.6,7.3v2c-2.4,0.2-5.2,0.9-5.2,2.7c0,1.9,3.2,2.8,6.1,2.8s6.1-0.9,6.1-2.8
	C13.8,19.4,11,18.6,8.6,18.5z M11.4,1.8L13,8.3H2.2l1.6-6.5H11.4z M6.6,20.3v0.2c0,0.5,0.5,0.9,1,0.9s1-0.4,1-0.9v-0.2
	c1.8,0.1,3,0.6,3.2,0.9c-0.3,0.3-1.5,0.9-4.1,0.9s-3.9-0.7-4.1-0.9C3.7,20.9,4.8,20.4,6.6,20.3z M7.6,14.7c-3,0-5.1-1.8-5.5-4.6
	h11.1C12.6,12.9,10.5,14.7,7.6,14.7z"
      />
      <path
        d="M23.2,0c-0.4,0-0.7,0.2-0.8,0.5l0,0l0,6c0,0.3-0.3,0.5-0.6,0.5c-0.4,0-0.8-0.2-0.8-0.4c0,0,0,0,0-0.1l0-6
	C21,0.2,20.7,0,20.4,0c-0.4,0-0.8,0.2-0.8,0.4c0,0,0,0,0,0.1l0,6c0,0.3-0.3,0.5-0.6,0.5c-0.4,0-0.8-0.2-0.8-0.4c0,0,0,0,0-0.1l0-6
	c0-0.3-0.3-0.5-0.7-0.5c-0.4,0-0.8,0.2-0.8,0.4l0,0l-0.2,6.7c0,1.1,0.9,2.1,2.5,2.5c0.2,0.1,0.4,0.2,0.4,0.4
	c-0.2,4.7-0.8,10.2-0.4,12c0.2,1.2,0.2,1.9,1.4,1.9c1.2,0,1.1-0.7,1.3-1.8c0.3-1.9-0.2-7.3-0.4-12.1c0-0.2,0.2-0.3,0.4-0.4
	C23.1,9.4,24,8.4,24,7.3l-0.2-6.7C23.8,0.2,23.6,0,23.2,0z"
      />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "-4 -6 32 32",
    };
  }
};

AllInclusive.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

AllInclusive.defaultProps = { role: "img" };
