import PropTypes from "prop-types";
import React from "react";

import { Link } from "@swa-ui/core";
import { PlacementFactory, usePlacement } from "@swa-ui/placements";
import { classNames } from "@swa-ui/string";

import { LinkColumns } from "./LinkColumns";
import styles from "./Overlay.module.scss";

/**
 * This components provides the content for the GlobalHeader product flyout. According to the data and the design, the
 * only flyout that requires the wide overlay is for the products links.
 */

const Overlay = (props) => {
  const { className, productIndex } = props;
  const globalNavPrimaryLink =
    usePlacement("globalNavHeader")?.placement?.globalNavPrimaryLinks?.[productIndex];
  const { linkColumns, utilityLinks } = globalNavPrimaryLink?.flyout ?? {};
  const { globalNavAd1, globalNavAd2, globalNavPromo } = globalNavPrimaryLink?.dynamicContent ?? {};

  return (
    <div className={classNames(className, styles.overlay)}>
      <div className={styles.menu}>
        <LinkColumns linkColumnInfo={linkColumns} />
        <div className={styles.utilitySection}>
          {utilityLinks && renderUtilitySection()}
          {globalNavAd1 && (
            <div className={styles.placements}>
              <PlacementFactory id={globalNavAd1} />
            </div>
          )}
          {globalNavAd2 && (
            <div className={styles.placements}>
              <PlacementFactory id={globalNavAd2} />
            </div>
          )}
        </div>
      </div>
      {globalNavPromo && (
        <div className={styles.placements}>
          <PlacementFactory id={globalNavPromo} />
        </div>
      )}
    </div>
  );

  function renderUtilitySection() {
    return <ul className={styles.list}>{utilityLinks.map(renderUtilityLinks)}</ul>;
  }

  function renderUtilityLinks(link) {
    const { prefixIcon, href, url, value } = link;

    return (
      <li key={value}>
        <Link href={href ?? url} prefixIcon={getUtilityIconProps(prefixIcon)} size="fontSize16">
          {value}
        </Link>
      </li>
    );
  }

  function getUtilityIconProps(prefixIcon) {
    return {
      name: prefixIcon,
      size: "size26",
    };
  }
};

Overlay.propTypes = {
  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Content that defines all options displayed in the GlobalNav flyout. */
  content: PropTypes.object,

  /** Provides an index into the globalHeader content data for the overlay links to render. */
  productIndex: PropTypes.number.isRequired,
};

export { Overlay };
