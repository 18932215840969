import PropTypes from "prop-types";
import React, { useState } from "react";

import { useDesignTokensTheme } from "@swa-ui/design-tokens";
import { classNames } from "@swa-ui/string";

import { Icon, iconPropTypes } from "../Icon";
import { Pulse } from "../Pulse";
import { Ripple } from "../Ripple";
import { TransitionBlock } from "../TransitionBlock";
import styles from "./Button.module.scss";

// TODO - consider making the button content configurable for initial animation

const ACTION_EXPAND = "expand";
const ACTION_NONE = "none";
const DURATION_TO_LOADING = 225;
const DURATION_TO_CONTENT = 125;

/**
 * The Button component provides a clickable area on a page to allow the traveler to perform actions while remaining on
 * the page. There are currently two main styles: "typical" button and no style. If an href is passed, then an anchor
 * tag will be instantiated, otherwise a button element will be used.
 * Anytime a button or a link that looks like a button is needed, then this Button component should be used. If a button
 * or link is needed and looks like a link, then the Link component should be used.
 */

export const Button = React.forwardRef((props, ref) => {
  const {
    alignment,
    animate,
    children,
    className,
    clickFeedback,
    disabled,
    emphasis,
    fullWidth,
    href,
    id,
    light,
    newWindow,
    newWindowDescription,
    onBlur,
    onClick,
    onFocus,
    onKeyDown,
    onMouseOver,
    overrideComponent,
    overrideComponentProps,
    prefixIcon,
    role,
    selected,
    showUnderline,
    size,
    style,
    styleType,
    submitInProgress,
    suffixIcon,
    tabIndex,
    transparent,
    type,
  } = props;
  const [actionState, setActionState] = useState(ACTION_NONE);
  const [clickPosition, setClickPosition] = useState();
  const Component = overrideComponent ?? (href ? "a" : "button");
  const { themeClass } = useDesignTokensTheme({
    component: "button",
    styleType: styleType === "no-style" ? "nostyle" : styleType,
  });

  return styleType === "no-style" ? renderSimpleButton() : renderStandardButton();

  function renderSimpleButton() {
    return <Component {...getProps(getClassSimple)}>{children}</Component>;
  }

  function renderStandardButton() {
    return (
      <Component {...getProps(getClassStandard)}>
        {renderRippleFeedback()}
        <div className={getContainerClass()}>
          {renderPrefixIcon()}
          {animate ? (
            <TransitionBlock {...getTransitionProps()}>{renderChildren()}</TransitionBlock>
          ) : (
            renderChildren()
          )}
          {renderSuffixIcon()}
        </div>
      </Component>
    );
  }

  function renderChildren() {
    return (
      <>
        <div className={classNames({ [styles.contentHidden]: submitInProgress })}>{children}</div>
        {submitInProgress && renderSubmitInProgress()}
      </>
    );
  }

  function renderRippleFeedback() {
    let content = null;

    if (actionState === ACTION_EXPAND && clickPosition && clickFeedback === "ripple") {
      content = <Ripple {...getRippleProps()} />;
    }

    return content;
  }

  function renderPrefixIcon() {
    return prefixIcon ? <Icon className={styles.prefixIcon} {...getIconProps(prefixIcon)} /> : null;
  }

  function renderSuffixIcon() {
    return suffixIcon ? <Icon className={styles.suffixIcon} {...getIconProps(suffixIcon)} /> : null;
  }

  function renderSubmitInProgress() {
    let content = null;

    if (submitInProgress) {
      content = (
        <div className={styles.loading}>
          {renderLoadingDot(0)}
          {renderLoadingDot(1)}
          {renderLoadingDot(2)}
          {renderLoadingDot(3)}
          {renderLoadingDot(4)}
        </div>
      );
    }

    return content;
  }

  function renderLoadingDot(index) {
    return (
      <Pulse className={styles.pulse} delayIndex={index}>
        <div className={getLoadingClassName()} />
      </Pulse>
    );
  }

  function getProps(getClass) {
    return {
      "aria-controls": props["aria-controls"],
      "aria-describedby": props["aria-describedby"],
      "aria-disabled": disabled || submitInProgress,
      "aria-expanded": props["aria-expanded"],
      "aria-hidden": props["aria-hidden"],
      "aria-label": getAriaLabel(),
      "aria-labelledby": props["aria-labelledby"],
      className: getClass(),
      "data-test": props["data-test"],
      disabled: disabled || submitInProgress,
      href,
      id,
      onBlur,
      onClick: handleClick,
      onFocus,
      onKeyDown,
      onMouseOver,
      ref,
      role,
      style,
      tabIndex,
      target: newWindow && href ? "_blank" : null,
      type,
      ...overrideComponentProps,
      ...(styleType === "tab" && { role: "tab" }),
      ...(styleType !== "tab" && styleType !== "link" && !role && { role: "button" }),
      ...(styleType !== "tab" &&
        selected !== undefined && { "aria-current": `${selected}`, "aria-pressed": selected }),
      ...(role === "checkbox" && { "aria-checked": `${selected ?? false}` }),
      ...(styleType !== "tab" && role === "button" && { "aria-pressed": `${selected ?? false}` }),
    };
  }

  function getIconProps(iconProps) {
    let computedColor = "cmp-core-color-button-fg";

    if (iconProps.color) {
      computedColor = iconProps.color;
    } else if (light) {
      computedColor = "light-button-foreground";
    }

    if (disabled) {
      computedColor = "standard-disabled-foreground";
    }

    return {
      actions: iconProps.actions,
      "aria-label": prefixIcon?.["aria-label"] ?? suffixIcon?.["aria-label"] ?? "",
      background: iconProps.background,
      border: iconProps.border,
      color: computedColor,
      duration: iconProps.duration,
      name: iconProps.name,
      shrink: iconProps.shrink,
      size: iconProps.size ? iconProps.size : size === "small" ? "size16" : "size20",
    };
  }

  function getTransitionProps() {
    const animationToken = typeof children === "string" ? children : children?.props?.children;

    return {
      animationToken: `${animationToken}-${submitInProgress}-${disabled}`,
      transitionIn: {
        duration: DURATION_TO_LOADING,
        transformations: ["opacityReset", "scaleXReset", "scaleYReset"],
      },
      transitionOut: {
        duration: DURATION_TO_CONTENT,
        transformations: [
          "fade",
          { action: "scaleX", amount: "0.8" },
          { action: "scaleY", amount: "0.4" },
        ],
      },
    };
  }

  function getRippleProps() {
    const styleTypeColors = {
      danger: "danger-button-feedback",
      primary: "primary-button-feedback",
      secondary: "secondary-button-feedback",
    };
    const computedColor = light ? "light-button-feedback" : styleTypeColors[styleType];

    return {
      className: getFeedbackClass(),
      color: computedColor,
      focalPoint: clickPosition,
      onTransformationEnd: handleTransformationEnd,
    };
  }

  function getClassSimple() {
    return classNames(className, styles.button, themeClass, {
      [styles.alignLeft]: alignment === "left",
      [styles.alignRight]: alignment === "right",
      [styles.disabled]: disabled,
      [styles.fullWidth]: fullWidth,
      [styles.small]: size === "small",
    });
  }

  function getClassStandard() {
    return classNames(className, styles.button, themeClass, {
      [styles.alignLeft]: alignment === "left",
      [styles.alignRight]: alignment === "right",
      [styles.capsuleLinkSelected]:
        overrideComponentProps?.to && styleType === "capsule" && selected,
      [styles.disabled]: disabled,
      [styles.emphasis]:
        (emphasis && styleType !== "capsule") || (emphasis && styleType === "link"),
      [styles.fullWidth]: fullWidth,
      [styles.href]: href,
      [styles.light]: light,
      [styles.link]: overrideComponentProps?.to || styleType === "link",
      [styles.minimal]: size === "minimal",
      [styles.selected]: selected,
      [styles.showUnderline]: showUnderline,
      [styles.small]: size === "small",
      [styles.standard]: isStandardButton(),
      [styles.tab]: styleType === "tab",
      [styles.transparent]: transparent,
    });
  }

  function getContainerClass() {
    return classNames({
      [styles.container]: true,
      [styles.minimal]: size === "minimal",
      [styles.small]: size === "small",
    });
  }

  function getLoadingClassName() {
    return classNames({
      [styles.dot]: true,
      [styles.dotBackgroundDanger]: styleType === "danger",
      [styles.dotBackgroundLight]: styleType === "light",
      [styles.dotBackgroundSecondary]: styleType === "secondary",
    });
  }

  function getFeedbackClass() {
    return classNames(styles.feedback, { [styles.small]: size === "small" });
  }

  function handleTransformationEnd() {
    setActionState(ACTION_NONE);
  }

  function handleClick(event) {
    if (!disabled) {
      if (isStandardButton()) {
        setClickPosition(getClickPosition(event));
        setActionState(ACTION_EXPAND);
      }

      onClick && onClick(event);
    }
  }

  function isStandardButton() {
    return (
      styleType === "capsule" ||
      styleType === "danger" ||
      styleType === "primary" ||
      styleType === "secondary" ||
      styleType === "tab" ||
      styleType === "tertiary"
    );
  }

  function getAriaLabel() {
    let ariaLabel = props["aria-label"] || "";

    if (newWindowDescription) {
      ariaLabel = `${ariaLabel} ${newWindowDescription}`;
    }

    return ariaLabel.trim();
  }

  function getClickPosition(event) {
    const { clientX, clientY, currentTarget } = event;
    const boundingRect = currentTarget.getBoundingClientRect();
    let x;
    let y;

    if (clientX + clientY === 0) {
      x = boundingRect.width / 2;
      y = boundingRect.height / 2;
    } else {
      x = clientX - boundingRect.x;
      y = clientY - boundingRect.y;
    }

    return { x, y };
  }
});

export const buttonPropTypes = {
  /** Text alignment for the content in the button. */
  alignment: PropTypes.oneOf(["center", "left", "right"]),

  /** Button content changes will animate, using TransitionBlock, unless turned off. */
  animate: PropTypes.bool,

  /**
   * Indicates the id of another area on a page when this button controls whether that area is
   * concealed or revealed. If Button doesn't control another area, then aria-controls should be
   * undefined.
   */
  "aria-controls": PropTypes.string,

  /**
   * aria-describedby id to element which provides additional accessibility description of input
   * element.
   */
  "aria-describedby": PropTypes.string,

  /**
   * Indicates when this button controls another area on the page whether than area is revealed. If
   * Button doesn't control another area, then aria-expanded should be undefined.
   */
  "aria-expanded": PropTypes.bool,

  /** aria-hidden indicates if button will be hidden for accessibility considerations. */
  "aria-hidden": PropTypes.bool,

  /** aria-label text to provide additional accessibility description of button element. */
  "aria-label": PropTypes.string,

  /**
   * aria-labelledby id to element which provides additional accessibility description of input
   * element.
   */
  "aria-labelledby": PropTypes.string,

  /** Content that will be rendered on the button. */
  children: PropTypes.node,

  /**
   * Additional classes for positioning the component. Given classes may only position this component
   * for layout purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * Indicates the visible feedback the traveler will see when a button is clicked. Ripple provides
   * a fading "ripple" similar to dropping a pebble in a lake, while dot represents a background
   * color change to show the selected state. Both the dot and ripple originate from the click
   * position, or the button's center for selection via keyboard.
   */
  clickFeedback: PropTypes.oneOf(["none", "ripple"]),

  /** Name to use for the data-test attribute used for automated testing. */
  "data-test": PropTypes.string,

  /**
   * Indicates Button should apply disabled styling, ignore click events and provide aria-disabled
   * attribute.
   */
  disabled: PropTypes.bool,

  /** Defines if the button text will be bold or normal weight. */
  emphasis: PropTypes.bool,

  /** Defines if the button will spread to the entire width of its container. */
  fullWidth: PropTypes.bool,

  /** Anchor tag hypertext reference (e.g., the link path or URL). */
  href: PropTypes.string,

  /** ID to be added to the element. */
  id: PropTypes.string,

  /**
   * Light prop indicates if the button will be rendered on a dark background, and is not affected
   * by the theme -- that is, the background will always be dark regardless of the theme background.
   */
  light: PropTypes.bool,

  /** Set to `true` if the link is should open a new tab/window when clicked. */
  newWindow: PropTypes.bool,

  /**
   * When newWindow or external are true, newWindowDescription must be specified to provide the
   * traveler with addition ARIA information. This prop is typically set to "Opens new window."
   */
  newWindowDescription: PropTypes.string,

  /** onBlur event handler will receive the event object as its only argument. */
  onBlur: PropTypes.func,

  /** onClick event handler will receive the event object as its only argument. */
  onClick: PropTypes.func,

  /** Event handler will receive the event object as its only argument. */
  onFocus: PropTypes.func,

  /** Optional event handler to learn when a key is pressed down. */
  onKeyDown: PropTypes.func,

  /** Event handler will receive the event object as its only argument. */
  onMouseOver: PropTypes.func,

  /** Component that when passed, will override <a> or <Button> render. */
  overrideComponent: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.shape({ current: PropTypes.elementType }),
  ]),

  /** Object that includes props to be passed to an override component, such as NavLink. */
  overrideComponentProps: PropTypes.object,

  /** Icon that can be placed before button label. See Icon component for more details. */
  prefixIcon: PropTypes.shape({
    actions: iconPropTypes.actions,
    "aria-label": iconPropTypes["aria-label"],
    background: iconPropTypes.background,
    border: iconPropTypes.border,
    color: iconPropTypes.color,
    name: iconPropTypes.name,
    shrink: iconPropTypes.shrink,
  }),

  /** Specify if the button should display "ripple" feedback when clicked. */
  ripple: PropTypes.bool,

  /** The role attribute for the button. */
  role: PropTypes.string,

  /**
   * Indicates if "selected styling" should be applied to the button. A button may be in selected
   * state which is different that focus state. Currently, only tertiary buttons look at this prop
   * to show selected treatment.
   */
  selected: PropTypes.bool,

  /** Allow the Link to have or not have an underline on focus/hover. */
  showUnderline: PropTypes.bool,

  /**
   * This property defines the height and padding for button, and size be set to any valid size for
   * link types. Sizes do not apply for simple button types.
   */
  size: PropTypes.oneOf(["large", "minimal", "small"]),

  /** Object to style the HTML button element. */
  style: PropTypes.object,

  /**
   * Buttons like primary and secondary look like standard buttons. Link styles looks like links, and
   * 'no-style' have minimal styling and are the responsibility of the app to style.
   */
  styleType: PropTypes.oneOf([
    "capsule",
    "danger",
    "link",
    "no-style",
    "primary",
    "secondary",
    "tab",
    "tertiary",
  ]),

  /**
   * If this prop is true and the type prop is 'submit', a loading animation will be displayed in
   * the button.
   */
  submitInProgress: PropTypes.bool,

  /** Icon that can be placed after button label. See Icon for more details. */
  suffixIcon: PropTypes.shape({
    actions: iconPropTypes.actions,
    "aria-label": iconPropTypes["aria-label"],
    background: iconPropTypes.background,
    border: iconPropTypes.border,
    color: iconPropTypes.color,
    name: iconPropTypes.name,
    shrink: iconPropTypes.shrink,
  }),

  /** Optional value to set the tabbing order. This can be negative one, zero, or any positive number. */
  tabIndex: PropTypes.number,

  /**
   * Optional setting to indicate if button's background is transparent. Applies to secondary
   * styleType.
   */
  transparent: PropTypes.bool,

  /** type is given as DOM attribute and has no style characteristic. */
  type: PropTypes.oneOf(["button", "submit"]),
};

Button.displayName = "Button";

Button.propTypes = buttonPropTypes;

Button.defaultProps = {
  alignment: "center",
  animate: true,
  clickFeedback: "ripple",
  disabled: false,
  emphasis: true,
  fullWidth: false,
  role: "button",
  showUnderline: true,
  size: "large",
  styleType: "primary",
  submit: false,
  transparent: false,
  type: "button",
};
