import PropTypes from "prop-types";
import React from "react";

import { SMALL, useDeviceInfo } from "@swa-ui/browser";
import { Card, Grid, Heading } from "@swa-ui/core";
import { classNames } from "@swa-ui/string";

import { applyOverrides } from "../applyOverrides";
import { CallToAction } from "../CallToAction";
import { HtmlValue } from "../HtmlValue";
import styles from "./BannerWithCardLayout1.module.scss";
import {
  backgroundProperties,
  callToActionPropType,
  cardAlignmentPropType,
  textPropType,
} from "./BannerWithCardProptypes";

export const BannerWithCardLayout1 = (props) => {
  const { background, callToAction, cardAlignment, text } = props;
  const { primaryText, primaryTitle, secondaryTitle } = text ?? {};
  const { screenSize } = useDeviceInfo();

  return screenSize === SMALL ? renderContentForSmallScreen() : renderContent();

  function renderContent() {
    return (
      <div className={styles.bannerWithCardContainer}>
        <div className={styles.backgroundContainer} style={{ ...getBackgroundStyles() }} />
        <Grid className={getGridContainerClass()}>
          <Card className={styles.cardContainer}>{renderCardContent()}</Card>
        </Grid>
      </div>
    );
  }

  function renderContentForSmallScreen() {
    return (
      <>
        <div className={styles.backgroundContainer} style={{ ...getBackgroundStyles() }} />
        {renderCardContent()}
      </>
    );
  }

  function renderCardContent() {
    const headingStyleLevel = 4;

    return (
      <div className={styles.cardContentContainer}>
        {primaryTitle?.value && <Heading {...getHeadingProps(primaryTitle, headingStyleLevel)} />}
        {secondaryTitle?.value && <Heading {...getHeadingProps(secondaryTitle)} />}
        {primaryText?.value && (
          <div {...getStyleProps(primaryText)}>
            <HtmlValue htmlValue={primaryText.value} />
          </div>
        )}
        {callToAction && <CallToAction {...getCallToActionProps()} />}
      </div>
    );
  }

  function getBackgroundStyles() {
    const backgroundContent = background?.[0] ?? {};
    const { color, imagePath, type } = applyOverrides(backgroundContent, screenSize);

    return {
      ...(type === "color" && { backgroundColor: color }),
      ...(type === "image" && { backgroundImage: `url(${imagePath})` }),
    };
  }

  function getCallToActionProps() {
    return {
      ...(screenSize === SMALL && { additionalProps: { fullWidth: true } }),
      ...callToAction,
    };
  }

  function getHeadingProps(headingTitle, styleLevel) {
    return {
      children: <span {...getStyleProps(headingTitle)}>{headingTitle.value}</span>,
      headingLevel: 2,
      styleLevel,
    };
  }

  function getStyleProps(styleProps) {
    const { color, fontSize, fontWeight } = styleProps ?? {};

    return {
      style: {
        color,
        fontSize,
        fontWeight,
      },
    };
  }

  function getGridContainerClass() {
    const { align } = applyOverrides(cardAlignment);

    return classNames({
      [styles.leftAlignmentGridContainer]: align === "left",
      [styles.rightAlignmentGridContainer]: align === "right",
    });
  }
};

BannerWithCardLayout1.propTypes = {
  /** Background image/color content for the banner. */
  background: PropTypes.oneOfType([backgroundProperties, PropTypes.arrayOf(backgroundProperties)]),

  /** Object that contains link/button props. */
  callToAction: callToActionPropType,

  /** Card to be aligned left/right on the background image. */
  cardAlignment: cardAlignmentPropType,

  /** Text content to be displayed in the banner placement. */
  text: PropTypes.shape({
    primaryText: textPropType,
    primaryTitle: textPropType,
    secondaryTitle: textPropType,
  }),
};
