import { window } from "@swa-ui/browser";

import { NATIVE_APP_INTERFACE_NAMES } from "../useHybrid/sendMessageToNativeApp";

const { PAGE_RENDERED } = NATIVE_APP_INTERFACE_NAMES;

export const isHybridEnabled = () => {
  const searchParams = new window.URLSearchParams(window?.location?.search);

  return (
    !!window.webkit?.messageHandlers?.[PAGE_RENDERED] ||
    !!window.AndroidInterface?.[PAGE_RENDERED] ||
    !!searchParams.get("webView")
  );
};
