import PropTypes from "prop-types";
import React, { useMemo } from "react";
import * as yup from "yup";

import { swaDate } from "@swa-ui/date";
import i18n from "@swa-ui/locale";
import { deepEquals } from "@swa-ui/object";
import { usePersistedState } from "@swa-ui/persistence";

/**
 * RecentSearchProvider provides recent searches functionality in a React context.
 *
 * addRecentSearch - Function to add a recent search.
 * clearRecentSearches - Function to clear recent searches based on the type.
 * isRemembering - Function to determine if the search will be remembered based on the type.
 * recentSearches - The recent searches.
 * startRememberingRecentSearches - Function to start remembering recent searches based on the type.
 * stopRememberingRecentSearches - Function to stop remembering recent searches based on the type.
 */

export const RecentSearchContext = React.createContext();
export const RecentSearchProvider = (props) => {
  const { children, maxToStorePerType, retentionDays, schemas, storageKey, supportedTypes } = props;
  const [rememberRecentSearches, setRememberRecentSearches] = usePersistedState({
    defaultValue: {},
    key: getStorageKey("RecentSearchProvider_rememberRecentSearches"),
    schema: getRememberRecentSearchesSchema(),
    storageType: "local",
  });
  const [searches, setSearches] = usePersistedState({
    defaultValue: [],
    key: getStorageKey("RecentSearchProvider_searches"),
    schema: getSearchesSchema(),
    storageType: "local",
  });
  const recentSearches = useMemo(getValidRecentSearches, [searches]);

  return (
    <RecentSearchContext.Provider
      value={{
        addRecentSearch,
        clearRecentSearches,
        isRemembering,
        recentSearches,
        startRememberingRecentSearches,
        stopRememberingRecentSearches,
      }}
    >
      {children}
    </RecentSearchContext.Provider>
  );

  function getStorageKey(keyType) {
    return `${keyType}${storageKey ? "-" : ""}${storageKey}`;
  }

  function getRememberRecentSearchesSchema() {
    const shape = supportedTypes.reduce(
      (accumulator, type) => ({
        ...accumulator,
        [type]: yup.boolean(),
      }),
      {}
    );

    return yup.object().shape(shape).noUnknown().strict(true);
  }

  function getSearchesSchema() {
    return yup.array().of(
      yup.object().shape({
        formData: yup.object(),
        timestamp: yup
          .string()
          .test("validateTimestamp", i18n("RecentSearchProvider__INVALID_TIMESTAMP"), (value) =>
            swaDate(value).isValid()
          ),
        type: yup.string().oneOf(supportedTypes),
      })
    );
  }

  function getValidRecentSearches() {
    const retentionDate = swaDate().subtract(retentionDays, "day");
    const validRecentSearches = searches.filter((recentSearch) =>
      retentionDate.isBefore(swaDate(recentSearch.timestamp))
    );

    return validRecentSearches.filter((recentSearch) =>
      isFormDataValid(recentSearch.formData, recentSearch.type)
    );
  }

  function isFormDataValid(formData, type) {
    let validFormData = false;

    if (schemas && Object.keys(schemas).length && schemas[type]) {
      validFormData = schemas[type].isValidSync(formData);
    }

    return validFormData;
  }

  function addRecentSearch(formData, type) {
    if (isRemembering(type) && isFormDataValid(formData, type)) {
      const newRecentSearches = removeDuplicateRecentSearchItem(formData);
      const recentSearchItem = createRecentSearchItem(formData, type);

      addRecentSearchItem(newRecentSearches, recentSearchItem);
      removeExcessRecentSearchItemByType(newRecentSearches, recentSearchItem);
      setSearches(newRecentSearches);
    }
  }

  function removeDuplicateRecentSearchItem(formData) {
    return recentSearches.filter((recentSearch) => !deepEquals(formData, recentSearch.formData));
  }

  function createRecentSearchItem(formData, type) {
    return { formData, timestamp: swaDate().toJSON(), type };
  }

  function addRecentSearchItem(newRecentSearches, recentSearchItem) {
    newRecentSearches.unshift(recentSearchItem);
  }

  function removeExcessRecentSearchItemByType(newRecentSearches, recentSearchItem) {
    const recentSearchOccurrencesByType = newRecentSearches.filter((newRecentSearch) =>
      isRecentSearchTypeEqual(newRecentSearch, recentSearchItem)
    ).length;

    if (recentSearchOccurrencesByType > maxToStorePerType) {
      newRecentSearches.splice(
        newRecentSearches.findLastIndex((newRecentSearch) =>
          isRecentSearchTypeEqual(newRecentSearch, recentSearchItem)
        ),
        1
      );
    }
  }

  function isRecentSearchTypeEqual(recentSearch1, recentSearch2) {
    return recentSearch1.type === recentSearch2.type;
  }

  function clearRecentSearches(types) {
    let newRecentSearches = [];

    if (types) {
      newRecentSearches = recentSearches.filter(
        (recentSearch) => !types.includes(recentSearch.type)
      );
    }

    setSearches(newRecentSearches);
  }

  function stopRememberingRecentSearches(types) {
    setRememberingByType(types, false);
    clearRecentSearches(types);
  }

  function startRememberingRecentSearches(types) {
    setRememberingByType(types, true);
  }

  function setRememberingByType(types, value) {
    const typesToSet = types ?? supportedTypes;

    const newRememberRecentSearches = typesToSet.reduce(
      (accumulator, type) => ({
        ...accumulator,
        [type]: value,
      }),
      rememberRecentSearches
    );

    setRememberRecentSearches(newRememberRecentSearches);
  }

  function isRemembering(type) {
    return rememberRecentSearches?.[type] ?? true;
  }
};

RecentSearchContext.displayName = "RecentSearchContext";
RecentSearchProvider.propTypes = {
  /** Content that will be rendered with the provided context. */
  children: PropTypes.node,

  /** Maximum number of recent searches to store for each type. */
  maxToStorePerType: PropTypes.number,

  /** Number of days to retain recent searches. */
  retentionDays: PropTypes.number,

  /** Schemas for the supported types. */
  schemas: PropTypes.objectOf(PropTypes.object).isRequired,

  /**
   * Storage key to uniquely identify the persisted rememberRecentSearches and searches in
   * local storage.
   */
  storageKey: PropTypes.string.isRequired,

  /** Types of searches that are supported, such as "air" and "car". */
  supportedTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
};

RecentSearchProvider.defaultProps = {
  maxToStorePerType: 3,
  retentionDays: 30,
};
