import PropTypes from "prop-types";
import React from "react";

import { useDeviceInfo } from "@swa-ui/browser";
import { ButtonGroup } from "@swa-ui/core";
import i18n from "@swa-ui/locale";
import { classNames } from "@swa-ui/string";

import { FLIGHT_CAR, FLIGHT_HOTEL, FLIGHT_HOTEL_CAR } from "../../defines/packageType";
import { packageTypeProps } from "../../proptypes/packageType";
import styles from "./PackageOptions.module.scss";

export const PackageOptions = (props) => {
  const { className, onChange, value } = props;
  const { screenSize } = useDeviceInfo();

  return <ButtonGroup {...getButtonGroupProps()} />;

  function getButtonGroupProps() {
    return {
      buttonList: [
        {
          label: getFlightHotelLabel(),
          props: getButtonProps(FLIGHT_HOTEL),
        },
        {
          label: getFlightHotelCarLabel(),
          props: getButtonProps(FLIGHT_HOTEL_CAR),
        },
        {
          label: getFlightCarLabel(),
          props: getButtonProps(FLIGHT_CAR),
        },
      ],
      className,
      enableKeyboardSupport: true,
      equalSize: screenSize === "small",
    };
  }

  function getFlightHotelLabel() {
    const selectedClass = getSelectedClass(FLIGHT_HOTEL);

    return screenSize === "small" ? (
      <div className={selectedClass}>
        <div>{i18n("PackageOptions__FLIGHT")}</div>
        <div>{i18n("PackageOptions__PLUS_HOTEL")}</div>
      </div>
    ) : (
      <div key="flightPlusHotel" className={selectedClass}>
        {i18n("PackageOptions__FLIGHT_HOTEL")}
      </div>
    );
  }

  function getFlightHotelCarLabel() {
    const selectedClass = getSelectedClass(FLIGHT_HOTEL_CAR);

    return screenSize === "small" ? (
      <div className={selectedClass}>
        <div>{i18n("PackageOptions__FLIGHT_CAR")}</div>
        <div>{i18n("PackageOptions__PLUS_HOTEL")}</div>
      </div>
    ) : (
      <div key="flightPlusHotelPlusCar" className={selectedClass}>
        {i18n("PackageOptions__FLIGHT_HOTEL_CAR")}
      </div>
    );
  }

  function getFlightCarLabel() {
    const selectedClass = getSelectedClass(FLIGHT_CAR);

    return screenSize === "small" ? (
      <div className={selectedClass}>
        <div>{i18n("PackageOptions__FLIGHT")}</div>
        <div>{i18n("PackageOptions__PLUS_CAR")}</div>
      </div>
    ) : (
      <div key="flightPlusCar" className={selectedClass}>
        {i18n("PackageOptions__FLIGHT_CAR")}
      </div>
    );
  }

  function getButtonProps(packageType) {
    return {
      className: styles.packageOptionButton,
      clickFeedback: "none",
      onClick: () => {
        handleClick(packageType);
      },
      styleType: "no-style",
    };
  }
  function handleClick(packageType) {
    onChange(packageType);
  }

  function getSelectedClass(packageType) {
    return classNames({
      [styles.packageOptionLabel]: true,
      [styles.selectedOption]: isCurrentValueSelected(packageType),
    });
  }

  function isCurrentValueSelected(packageType) {
    return packageType === value;
  }
};

PackageOptions.propTypes = {
  /** Additional class to position the component. */
  className: PropTypes.string,

  /** Callback function to be called after one of the package type button group is selected.  */
  onChange: PropTypes.func.isRequired,

  /** The type of package the user is searching for. */
  value: packageTypeProps.isRequired,
};
