import PropTypes from "prop-types";
import React from "react";

export const BabyOnBoard = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path d="M17.42,55.63c-.04,.07-.04,.11-.07,.15v-.15h.07Z" />
      <circle cx="47.69" cy="130.43" r="11.88" />
      <circle cx="119.08" cy="130.43" r="11.88" />
      <path d="M21.99,48.47h-3.95c-.39,0-.7-.3-.73-.69-.49-6.15-3.89-11.46-8.85-14.56-.89,.6-1.93,.93-3.06,.93-2.42,0-4.43-1.57-5.11-3.78-.2-.52-.28-1.09-.28-1.65,0-1.41,.56-2.7,1.45-3.66,.97-1.05,2.37-1.73,3.94-1.73,2.86,0,5.19,2.17,5.39,4.95,6.78,3.93,11.44,11.07,11.99,19.37,.03,.45-.34,.83-.79,.83Z" />
      <path d="M143.6,48.47h-48.2c-1.57,0-2.83-1.27-2.83-2.83V2.84c0-1.77,1.61-3.12,3.34-2.8,25.38,4.7,45.37,22.43,50.47,45,.4,1.76-.97,3.43-2.78,3.43Z" />
      <path d="M17.55,55.34c-.35,0-.51,.34-.51,.58,0,36.14,29.29,65.3,65.43,65.3s64.49-29.03,65.47-65.15c.01-.44-.26-.76-.85-.76l-129.54,.03Z" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      style,
      viewBox: "0 0 147.94 142.31",
    };
  }
};

BabyOnBoard.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

BabyOnBoard.defaultProps = {
  role: "img",
};
