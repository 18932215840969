import PropTypes from "prop-types";
import React from "react";

import { BannerWithCardLayout1 } from "./BannerWithCardLayout1";
import { BannerWithCardLayout2 } from "./BannerWithCardLayout2";

export const BannerWithCard = (props) => {
  const { placement } = props;
  const { layoutType } = placement ?? {};
  const layouts = {
    layout1: BannerWithCardLayout1,
    layout2: BannerWithCardLayout2,
  };

  const Component = layouts[layoutType];

  return Component && <Component {...placement} />;
};

BannerWithCard.propTypes = {
  /** Placement object for bannerType banner with card. */
  placement: PropTypes.shape({
    /** Type of banner layout for a given banner type. */
    // TODO : Remove layout3 and refactor Banner to pass the tests (PEGMON-299).
    layoutType: PropTypes.oneOf(["layout1", "layout2", "layout3"]).isRequired,
  }),
};
