import React from "react";

import { Grid } from "@swa-ui/core";
import i18n from "@swa-ui/locale";
import { PlacementFactory } from "@swa-ui/placements";
import { classNames } from "@swa-ui/string";

import styles from "./AppFooter.module.scss";

/**
 * The AppFooter component provides a horizontal footer which is specific to the SPA. This component may include
 * multiple links that can be rendered horizontally with a full bleed background.
 */

const AppFooter = () => (
  <nav
    aria-label={i18n("AppFooter__BOTTOM_NAV_ARIA")}
    className={styles.background}
    data-test="appFooter"
  >
    <Grid className={classNames(styles.layout, styles.footer)}>
      <PlacementFactory className={styles.content} id="globalNavFooterBanner" />
    </Grid>
  </nav>
);

export { AppFooter };
