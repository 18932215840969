import PropTypes from "prop-types";
import React from "react";

export const Parking = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path
        d="M 13.268184481218906 4.161490683229772  C 13.268184481218906 4.161490683229772  12.50061305473563 5.461505962297338  11.992098035207322 
    6.322764308395676  C 11.998406452047213 6.198939118630278  12.000968885793506 6.074122548131527  11.999673054154941 5.948426879190606  
    C 11.999673054154941 2.6477471473918968  9.283488691528955 -0.03405513469454036  5.948426879190586 0.00032694584501768986  C 2.6477471473918874 
    0.034709026384575736  -0.03405513469454024 2.716511308471013  0.0003269458450176887 6.051573120809394  C 0.03470902638457562 9.352252852608103  
    2.7165113084710035 12.03405513469454  6.051573120809373 11.999673054154982  C 6.430778908568018 11.999673054154982  6.801816093124783 11.96382196232446  
    7.161504336601734 11.895300282249423  C 7.058875750348645 12.21190934445417  6.999999999999976 12.564613194169851  6.999999999999976 12.95652173913038  
    C 6.999999999999976 12.95652173913038  6.999999999999976 25.180124223602547  6.999999999999976 25.180124223602547  C 6.999999999999976 25.627329192546654  
    7.373106219120107 26  7.820833682064376 26  C 7.820833682064376 26  10.507198459729715 26  10.507198459729715 26  C 10.9549259226737 26  11.328032141794115 
    25.627329192546654  11.328032141794115 25.180124223602434  C 11.328032141794115 25.180124223602434  11.328032141794115 22.19875776397521  11.328032141794115 
    22.19875776397521  L 11.92500209238638 22  L 32.0727379248757 22  L 32.669707875467964 22.19875776397521  C 32.669707875467964 22.19875776397521  
    32.669707875467964 25.180124223602434  32.669707875467964 25.180124223602434  C 32.669707875467964 25.627329192546654  33.042814094588095 26  
    33.49054155753237 26  C 33.49054155753237 26  36.17690633519771 26  36.17690633519771 26  C 36.62463379814169 26  36.9977400172621 25.627329192546654  
    36.9977400172621 25.180124223602434  C 36.9977400172621 25.180124223602434  36.9977400172621 12.95652173913038  36.9977400172621 12.95652173913038  
    C 37.07236126108596 10.795031055900608  35.28145140930916 9.826086956521749  34.236753995772624 9.52795031055905  L 33.714405289004496 9.15527950310559  
    C 33.714405289004496 9.15527950310559  34.609860214892755 9.15527950310559  34.609860214892755 9.15527950310559  C 35.20683016548502 9.15527950310559  
    35.43069389695716 8.708074534161483  35.43069389695716 8.111801242236083  C 35.43069389695716 7.51552795031057  35.20683016548502 7.068322981366464  
    34.609860214892755 7.068322981366464  C 34.609860214892755 7.068322981366464  32.52046538781997 7.068322981366464  32.52046538781997 7.068322981366464  
    C 32.52046538781997 7.068322981366464  30.878798023691168 4.161490683229772  30.878798023691168 4.161490683229772  C 30.729555536043172 3.7142857142856656  
    30.431070560746896 3.3416149068323193  30.132585585450904 2.9689440993788594  C 29.53561563485836 2.3726708074533462  28.640160708970104 2  
    27.446220807785572 2  L 24.535992298648097 2  L 19.610990206261977 2  L 16.700761697124506 2  C 15.506821795939974 2  14.611366870051434 
    2.3726708074533462  14.014396919459168 2.9689440993788594  C 13.641290700339036 3.2670807453416733  13.417426968866902 3.7142857142856656  
    13.268184481218906 4.161490683229772  Z M 32.147359168699836 12.285714285714334  C 33.49054155753237 12.285714285714334  34.5352389710689 13.32919254658384  
    34.5352389710689 14.67080745341616  C 34.5352389710689 15.937888198757719  33.49054155753237 17.055900621117985  32.147359168699836 17.055900621117985  
    C 30.804176779867312 17.055900621117985  29.759479366330776 16.01242236024848  29.759479366330776 14.67080745341616  C 29.759479366330776 13.32919254658384  
    30.804176779867312 12.285714285714334  32.147359168699836 12.285714285714334  Z M 27.22235707631372 14.596273291925515  L 27.22235707631372 17  
    L 16.850004184772786 17  L 16.850004184772786 14.596273291925515  L 27.22235707631372 14.596273291925515  Z M 14.312881894755442 14.67080745341616  
    C 14.312881894755442 15.937888198757719  13.268184481218906 17.055900621117985  11.92500209238638 17.055900621117985  C 10.581819703553853 
    17.055900621117985  9.537122290017319 16.01242236024848  9.537122290017319 14.67080745341616  C 9.537122290017319 13.32919254658384  10.581819703553853 
    12.285714285714334  11.92500209238638 12.285714285714334  C 13.268184481218906 12.285714285714334  14.312881894755442 13.32919254658384  14.312881894755442 
    14.67080745341616  Z M 15.805306771235966 4.161490683229772  L 28.416296977497964 4.161490683229772  L 31.102661755163304 9.378881987577643  
    C 31.102661755163304 9.378881987577643  13.04432074974677 9.378881987577643  13.04432074974677 9.378881987577643  C 13.04432074974677 9.378881987577643  
    15.805306771235966 4.236024844720532  15.805306771235966 4.161490683229772  Z M 6.017191040269815 9.558545335845565  C 5.501459832176218 9.558545335845565  
    5.123256946240968 9.145960369370641  5.123256946240968 8.698993322356273  C 5.123256946240968 8.252026275341905  5.501459832176218 7.839441308866981  
    6.017191040269815 7.839441308866981  C 6.532922248363412 7.839441308866981  6.911125134298662 8.217644194802233  6.911125134298662 8.698993322356273  
    C 6.876743053759104 9.145960369370641  6.498540167823853 9.558545335845565  6.017191040269815 9.558545335845565  Z M 6.842360973219433 2.4758367446941065  
    L 6.567304328902855 7.0830355369964755  L 5.432695671097102 7.0830355369964755  L 5.192021107320084 2.4758367446941065  L 6.842360973219433 
    2.4758367446941065  Z "
      />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "0 -10 40 40",
    };
  }
};

Parking.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Parking.defaultProps = { role: "img" };
