const window = typeof global.window !== "undefined" ? global.window : {};

const MILLISECONDS_IN_A_MINUTE = 1000 * 60;

/**
 * clearStorage is used to flush persisted data from the browser.
 */
export function clearStorage() {
  window.sessionStorage?.clear?.();
  window.localStorage?.clear?.();
}

/**
 * getItem is the main mechanism for retrieving data from persisted storage systems in the browser.
 *
 * @param {String} key - Name of data stored in persisted storage
 * @param {*} defaultValue  - What is returned if persisted storage does not exist
 * @param {object} [configuration] - Additional options for persistence mechanism
 * @param {boolean} [configuration.persistDefault] - Whether to store default value if data doesn't exist
 * @param {function} [configuration.schema] - `Yup` schema to ensure persisted data value is valid
 * @param {("session"|"local")} [configuration.storageType] - Retrieve data from sessionStorage or localStorage
 * @param {number} [configuration.timeToLiveInMinutes] - Number of minutes before data expires
 * @returns {object} stored Object in persisted storage, or `defaultValue` if not found.
 * @example
 *   const persistedData = getItem("foo", "default for foo", { storageType: "session" });
 */
export function getItem(key, defaultValue, configuration = {}) {
  const storageType = getStorageType(configuration);
  const storedItem = getPersistedData(key, storageType);
  let item = initializeItem(defaultValue, configuration);

  if (storedItem) {
    const { expiry: storedExpiry, value: storedValue } = storedItem;

    if (storedValue !== undefined && (!storedExpiry || storedExpiry > new Date().getTime())) {
      if (configuration.schema) {
        try {
          item = {
            expiry: storedItem.expiry,
            value: configuration.schema.validateSync(storedValue),
          };
        } catch (exception) {
          console.warn(`Schema validation failed, using default value for ${key}`, exception);
        }
      } else if (storageType === "localStorage") {
        console.warn("Schema MUST be used when accessing local storage!");
      } else {
        item = storedItem;
      }
    }
  }

  if (configuration.persistDefault) {
    setItem(key, defaultValue, configuration);
  }

  return item;
}

export function getPersistedData(key, storageType = "session") {
  const storedItem = window[storageType]?.getItem?.(key);

  return (storedItem && JSON.parse(storedItem)) || undefined;
}

/**
 * setItem is the main mechanism for storing data in persisted storage systems in the browser.
 *
 * @param {string} key - Name of data stored in persisted storage
 * @param {*} value  - What is stored in persisted storage
 * @param {object} [configuration] - Additional options for persistence mechanism
 * @param {("session"|"local")} [configuration.storageType] - Retrieve data from sessionStorage or localStorage
 * @param {number} [configuration.timeToLiveInMinutes] - Number of minutes before data expires
 * @example
 *   const persistedData = setItem("bar", "data for bar", { storageType: "session", timeToLiveInMinutes: 5 });
 */
export function setItem(key, value, configuration = {}) {
  const storageType = getStorageType(configuration);
  const item = initializeItem(value, configuration);

  window[storageType]?.setItem?.(key, JSON.stringify(item));
}

function getStorageType(configuration) {
  const { persist, storageType: storageTypeFromConfiguration = "session" } = configuration;
  let storageType = persist ?? storageTypeFromConfiguration;

  if (!storageType.endsWith("Storage")) {
    storageType = `${storageType}Storage`;
  }

  return storageType;
}

function getItemExpiry(configuration) {
  const { timeToLiveInMinutes } = configuration || {};
  let itemExpiry;

  if (timeToLiveInMinutes) {
    const now = new Date().getTime();

    itemExpiry = now + timeToLiveInMinutes * MILLISECONDS_IN_A_MINUTE;
  }

  return itemExpiry;
}

function initializeItem(value, configuration) {
  const itemExpiry = getItemExpiry(configuration);

  return {
    value,
    ...(itemExpiry ? { expiry: itemExpiry } : {}),
  };
}
