export const storesToDelete = {
  CORPORATE_STORES_TO_DELETE: ["CorporateStore", "CorporateProxyBookingStore"],
  MWEB_STORES_TO_DELETE: [
    "OAUTH_LOGIN_STATUS",
    "AccountInfo",
    "LocalStorageCache::chaseSessionId",
    "LocalStorageCache::userInfo",
  ],
  RESPONSIVE_STORES_TO_DELETE: ["AuthProvider", "useUserInfo"],
  VISION_STORES_TO_DELETE: ["CustomerStore", "ChaseStore", "MyAccount", "PurchasePageStore"],
};
