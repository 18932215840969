import PropTypes from "prop-types";
import React from "react";

export const Adventure = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path d="M1.25 13.1251V11.2501C1.25 11.1147 1.27083 10.9845 1.3125 10.8595C1.35417 10.7345 1.41667 10.6147 1.5 10.5001L6.71875 3.46883L6 2.50008C5.94792 2.42716 5.91146 2.35154 5.89062 2.27321C5.86979 2.19529 5.86458 2.11716 5.875 2.03883C5.88542 1.96091 5.91146 1.8855 5.95312 1.81258C5.99479 1.73966 6.05208 1.67716 6.125 1.62508C6.27083 1.52091 6.42708 1.47925 6.59375 1.50008C6.76042 1.52091 6.89583 1.60425 7 1.75008L7.5 2.42196L8 1.75008C8.10417 1.60425 8.23958 1.52091 8.40625 1.50008C8.57292 1.47925 8.72917 1.52091 8.875 1.62508C9.02083 1.72925 9.10417 1.86466 9.125 2.03133C9.14583 2.198 9.10417 2.35425 9 2.50008L8.28125 3.46883L13.5 10.5001C13.5833 10.6147 13.6458 10.7345 13.6875 10.8595C13.7292 10.9845 13.75 11.1147 13.75 11.2501V13.1251C13.75 13.3022 13.69 13.4505 13.57 13.5701C13.4504 13.6901 13.3021 13.7501 13.125 13.7501H1.875C1.69792 13.7501 1.54958 13.6901 1.43 13.5701C1.31 13.4505 1.25 13.3022 1.25 13.1251ZM7.5 4.51571L2.5 11.2501V12.5001H4.375L6.98438 8.84383C7.10938 8.66675 7.28125 8.57821 7.5 8.57821C7.71875 8.57821 7.89062 8.66675 8.01562 8.84383L10.625 12.5001H12.5V11.2501L7.5 4.51571ZM5.90625 12.5001H9.09375L7.5 10.2813L5.90625 12.5001Z" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "0 -1 16 16",
    };
  }
};

Adventure.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Adventure.defaultProps = { role: "img" };
