import React from "react";

import { useDeviceInfo } from "@swa-ui/browser";

import { channels } from "../defines";
import { SuppressForChannels } from "../SuppressForChannels";

export const SuppressForNative = (props) => {
  const { children } = props;
  const { isSwaWebView } = useDeviceInfo();

  return isSwaWebView ? null : (
    <SuppressForChannels channels={[channels.ios, channels.android]}>
      {children}
    </SuppressForChannels>
  );
};
