import * as yup from "yup";

import { regexes } from "../defines";

export const addYupMethods = () => {
  yup.addMethod(yup.string, "alpha", function () {
    return this.matches(regexes.alpha);
  });

  yup.addMethod(yup.string, "alphaHashtagHyphenPeriodSpace", function () {
    return this.matches(regexes.alphaHashtagHyphenPeriodSpace);
  });

  yup.addMethod(yup.string, "alphaNumeric", function () {
    return this.matches(regexes.alphaNumeric);
  });

  yup.addMethod(yup.string, "alphaNumericHyphenPeriodSpace", function () {
    return this.matches(regexes.alphaNumericHyphenPeriodSpace);
  });

  yup.addMethod(yup.string, "alphaNumericPunct", function () {
    return this.matches(regexes.alphaNumericPunct);
  });

  yup.addMethod(yup.string, "alphaNumericPunctSpace", function () {
    return this.matches(regexes.alphaNumericPunctSpace);
  });

  yup.addMethod(yup.string, "alphaNumericPunctWhiteSpace", function () {
    return this.matches(regexes.alphaNumericPunctWhiteSpace);
  });

  yup.addMethod(yup.string, "alphaNumericSpace", function () {
    return this.matches(regexes.alphaNumericSpace);
  });

  yup.addMethod(yup.string, "alphaSpace", function () {
    return this.matches(regexes.alphaSpace);
  });

  yup.addMethod(yup.string, "noBackslash", function () {
    return this.matches(regexes.noBackslash);
  });

  yup.addMethod(yup.string, "noDoubleQuotes", function () {
    return this.matches(regexes.noDoubleQuotes);
  });

  yup.addMethod(yup.string, "numeric", function () {
    return this.matches(regexes.numeric);
  });

  yup.addMethod(yup.string, "phone", function () {
    return this.matches(regexes.phone);
  });

  yup.addMethod(yup.string, "positive", function () {
    return this.matches(regexes.positive);
  });

  yup.addMethod(yup.string, "replace", function (pattern, replacement) {
    return this.transform((value) => value.replace(pattern, replacement));
  });

  yup.addMethod(yup.string, "stripAllWhitespace", function () {
    return this.replace(/\s+/g, "");
  });

  yup.addMethod(yup.string, "validDate", function (errorMessage) {
    return this.matches(regexes.validDate, errorMessage);
  });

  yup.addMethod(yup.string, "validEmail", function (errorMessage) {
    return this.matches(regexes.validEmail, errorMessage);
  });
};
