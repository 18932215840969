import PropTypes from "prop-types";
import React from "react";

import { DropDown } from "@swa-ui/core";
import { swaDate } from "@swa-ui/date";
import i18n from "@swa-ui/locale";

import { withFormField } from "../withFormField";

export const TimeFormField = (props) => {
  const { componentProps, defaultValue, endTime, interval, startTime, times } = props;
  const FormField = withFormField(DropDown, { useController: true });
  const timeFormat = i18n("TimeFormField__FORMAT");

  return <FormField {...getDropDownProps()} />;

  function getDropDownProps() {
    return {
      ...props,
      componentProps: {
        defaultValue,
        list: times ?? getTimes(),
        ...componentProps,
      },
    };
  }

  function getTimes() {
    const processedStartTime = swaDate(startTime, timeFormat);
    const defaultTimes = [processedStartTime];
    const processedEndTime = swaDate(endTime, timeFormat);

    while (defaultTimes[defaultTimes.length - 1].isBefore(processedEndTime)) {
      let nextTime = defaultTimes[defaultTimes.length - 1].add(interval, "minutes");

      if (nextTime.isAfter(processedEndTime)) {
        nextTime = processedEndTime;
      }

      defaultTimes.push(nextTime);
    }

    return defaultTimes.map(formatTime);
  }

  function formatTime(date) {
    return date.format(timeFormat);
  }
};

TimeFormField.propTypes = {
  /**
   * Props to be passed along to child DropDown component.
   */
  componentProps: PropTypes.object,

  /** Optional time string (default: "11:00 AM") representing what is selected by default. */
  defaultValue: PropTypes.string,

  /** Optional time string (default: "11:59 PM") for the start of the list of times to select. */
  endTime: PropTypes.string,

  /** Optional time interval, in minutes. */
  interval: PropTypes.number,

  /** Optional time string (default: "12:00 AM") for the end of the list of times to select. */
  startTime: PropTypes.string,

  /** Optional array of times to list as choices. */
  times: PropTypes.arrayOf(PropTypes.string),
};

TimeFormField.defaultProps = {
  defaultValue: "11:00 AM",
  endTime: "11:59 PM",
  interval: 30,
  startTime: "12:00 AM",
};
