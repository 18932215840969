export const transformationActions = {
  collapse: { action: "scaleY", amount: "0" },
  collapseReset: { action: "scaleY", amount: "1" },
  expand: { action: "scaleY", amount: "2" },
  expandReset: { action: "scaleY", amount: "1" },
  explode: { action: "scaleX", amount: "2" },
  explodeReset: { action: "scaleX", amount: "1" },
  fade: { action: "opacity", amount: "0" },
  faded: { action: "opacity", amount: "0.125" },
  fadeReset: { action: "opacity", amount: "1" },
  flip: { action: "rotateX", amount: "180deg" },
  flipReset: { action: "rotateX", amount: "0deg" },
  implode: { action: "scaleX", amount: "0" },
  implodeReset: { action: "scaleX", amount: "1" },
  opacityReset: { action: "opacity", amount: "1" },
  rotate45: { action: "rotateZ", amount: "45deg" },
  rotate90: { action: "rotateZ", amount: "90deg" },
  rotate120: { action: "rotateZ", amount: "120deg" },
  rotate180: { action: "rotateZ", amount: "180deg" },
  rotate270: { action: "rotateZ", amount: "270deg" },
  rotate360: { action: "rotateZ", amount: "360deg" },
  rotateReset: { action: "rotateZ", amount: "0deg" },
  rotateXReset: { action: "rotateX", amount: "0deg" },
  rotateYReset: { action: "rotateY", amount: "0deg" },
  rotateZReset: { action: "rotateY", amount: "0deg" },
  scaleXReset: { action: "scaleX", amount: "1" },
  scaleYReset: { action: "scaleY", amount: "1" },
  shrinkX: { action: "scaleX", amount: "0.85" },
  shrinkY: { action: "scaleY", amount: "0.85" },
  slideLeft: { action: "translateX", amount: "-100%" },
  slideReset: { action: "translateX", amount: "0%" },
  slideRight: { action: "translateX", amount: "100%" },
  spin: { action: "rotateY", amount: "180deg" },
  spinReset: { action: "rotateY", amount: "0deg" },
  translateXReset: { action: "translateX", amount: "0px" },
  translateYReset: { action: "translateY", amount: "0px" },
  translateZReset: { action: "translateZ", amount: "0px" },
};
