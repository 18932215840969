import { useEffect, useRef } from "react";

/**
 * Access a value from the previous render similar to prevProps/prevState arguments in componentDidMount
 *
 * @example
 * const [id, setId] = useState('');
 * const previousId = usePrevious(id);
 *
 * @param  {*} value
 * @returns {*} Returns value from previous render
 */
export const usePrevious = (value) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};
