import PropTypes from "prop-types";
import React from "react";

import { getBootstrapData } from "@swa-ui/bootstrap";
import { SelectFormField } from "@swa-ui/form";
import i18n from "@swa-ui/locale";

import { PHONE_COUNTRY_CODE_SCHEMA_FIELD_NAMES } from "./PhoneCountryCodeFormField.schema";

/**
 * PhoneCountryCodeFormField facilitates selecting a countryCode from a dropdown.
 */

export const PhoneCountryCodeFormField = (props) => {
  const { caption, className, defaultValue, disabled, label, name, value } = props;

  return <SelectFormField {...getFormFieldProps()} />;

  function getFormFieldProps() {
    const countryCodeList = getCountryCodeList();

    return {
      caption,
      componentProps: {
        "aria-label": props["aria-label"] ?? i18n("PhoneCountryCodeFormField__ARIA"),
        className,
        defaultValue: defaultValue ?? countryCodeList?.[0].value,
        list: countryCodeList,
        required: true,
        value,
      },
      disabled,
      label: label ?? i18n("PhoneCountryCodeFormField__LABEL"),
      name: name ?? PHONE_COUNTRY_CODE_SCHEMA_FIELD_NAMES.PHONE_COUNTRY_CODE,
    };
  }

  function getCountryCodeList() {
    const countryCodeSet = new Set();

    return getBootstrapData("countries-data")?.reduce((accumulatedCountriesData, countryData) => {
      const { fullName, numericCountryCode } = countryData;
      const countryLabel = `${fullName} (+${numericCountryCode})`;
      const newCountryEntry = {
        displayValue: countryLabel,
        label: countryLabel,
        value: numericCountryCode,
      };

      if (typeof numericCountryCode === "number" && !countryCodeSet.has(numericCountryCode)) {
        countryCodeSet.add(numericCountryCode);
        accumulatedCountriesData.push(newCountryEntry);
      }

      return accumulatedCountriesData;
    }, []);
  }
};

PhoneCountryCodeFormField.propTypes = {
  /** Content to be read by the screen reader. */
  "aria-label": PropTypes.string,

  /** Content below the form field. */
  caption: PropTypes.string,

  /** Class name for positioning the component. */
  className: PropTypes.string,

  /** Default value for Phone country code. */
  defaultValue: PropTypes.number,

  /** Indicates whether the field is disabled. */
  disabled: PropTypes.bool,

  /** Label to identify the form field. */
  label: PropTypes.node,

  /** Form field name used in schema validation. */
  name: PropTypes.string,

  /** String to control the selected country code value. */
  value: PropTypes.number,
};
