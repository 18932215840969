import { useRef } from "react";

import { getFocusElement, setFocusElement } from "../focus";

/**
 * Simple hook to save the element with focus so that it may be later restored.
 *
 * restoreFocusElement will set focus to the element previously saved when saveFocusElement is
 * called. saveFocusElement must be called before this function is called.
 * @return              undefined
 *
 * saveFocusElement will set save the element that currently has focus. Focus may later be restored
 * to this element by calling restoreFocusElement.
 * @return              DOM element currently focused
 */

export const useRestoreFocus = () => {
  const focusElement = useRef(null);

  return {
    restoreFocusElement,
    saveFocusElement,
  };

  function restoreFocusElement() {
    setFocusElement(focusElement.current);
  }

  function saveFocusElement() {
    focusElement.current = getFocusElement();

    return focusElement.current;
  }
};
