import PropTypes from "prop-types";
import React from "react";

import i18n from "@swa-ui/locale";

import { FlightIndicator } from "../FlightIndicator";
import styles from "./NextDayIndicator.module.scss";

/**
 * NextDayIndicator is a badge to indicate that the flight lands after midnight.
 */

export const NextDayIndicator = (props) => {
  const { className, disabled } = props;

  return <FlightIndicator {...getProps()}>{i18n("NextDayIndicator__TEXT")}</FlightIndicator>;

  function getProps() {
    return {
      ariaLabel: i18n("NextDayIndicator__TEXT_ARIA"),
      className,
      disabled,
      iconColor: "green-1",
      iconName: "NextDay",
      labelClass: styles.nextDayIndicator,
    };
  }
};

NextDayIndicator.propTypes = {
  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Indicator to show disabled styling. */
  disabled: PropTypes.bool,
};
