import { getBootstrapData } from "@swa-ui/bootstrap";
import { isHybridEnabled } from "@swa-ui/hybrid";

export function getUrlForSite(urlKey, site) {
  let url = getBootstrapData("urls")?.[urlKey];

  if (site === "mobile") {
    const mobileUrls = getBootstrapData("urls-mobile");

    url = isHybridEnabled()
      ? mobileUrls?.[`${urlKey}_WEBVIEW`] ?? mobileUrls?.[urlKey] ?? url
      : mobileUrls?.[urlKey] ?? url;
  }

  return url;
}
