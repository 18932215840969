/**
 * filter generates an object whose values are the result of filtering a base object's key-value pairs with an
 * equivalence method, or shorthand string value.
 *
 * @param {object} objectToTest the object to filter keys out of
 * @param {function|string} predicate Must either be a function that returns a boolean value, or a string for shorthand truthy checking
 */

export const filter = (objectToTest = {}, predicate = () => true) =>
  Object.fromEntries(
    Object.entries(objectToTest).filter(([, nestedObjectValue]) =>
      typeof predicate === "string" ? !!nestedObjectValue[predicate] : predicate(nestedObjectValue)
    )
  );
