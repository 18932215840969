import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import { useDeviceInfo, useResizeObserver, window } from "@swa-ui/browser";
import { classNames } from "@swa-ui/string";

import { AriaLive } from "../AriaLive";
import { TransitionBlock, transitionBlockPropTypes } from "../TransitionBlock";
import styles from "./ListMatrix.module.scss";

const CASCADE_DELAY = 32;
const EVENT_AGGREGATE_DURATION = 450;
const TRANSITION_IN = { duration: 250, transformations: ["fadeReset"] };
const TRANSITION_INITIAL = {
  duration: 0.001,
  transformations: [{ action: "opacity", amount: "0.01" }],
};
const TRANSITION_OUT = { duration: 150, transformations: ["fade"] };
const WIDTH_TOLERANCE = 2;

/**
 * ListMatrix displays items in a list and optionally lays them out according to a grid. This
 * component works well when there one or more lines of items to be displayed, particularly if a
 * grid layout is needed. To display items vertically or horizontally, see List, ListPaginator or
 * ListScroller, which also provide scrolling capabilities.
 *
 * Note: When changing the items list after initial render and the new list won't cause a
 * re-layout because the size of the rendered items won't change, it may be necessary to give
 * a new animationToken to force a re-render.
 */

export const ListMatrix = (props) => {
  const {
    animationToken,
    center,
    className,
    itemClassName,
    items,
    layout = "grid",
    numberOfColumns,
    onTransformationEnd,
    rowClassName,
    separator,
  } = props;
  const { gridColumnSpacing, screenSize } = useDeviceInfo();
  const [containerWidth, setContainerWidth] = useState();
  const [initialRender, setInitialRender] = useState();
  const columnInfo = getColumnInfo();
  const ref = useRef();
  const timer = useRef();

  useResizeObserver({ callback: handleUpdatePageInfo, element: ref });

  useEffect(() => {
    if (ref?.current) {
      if (Math.abs(containerWidth - ref.current.getBoundingClientRect().width) > WIDTH_TOLERANCE) {
        setContainerWidth(ref.current.getBoundingClientRect().width);
      }
    }
  }, []);

  useEffect(() => {
    if (containerWidth) {
      setInitialRender(true);
    }
  }, [containerWidth, initialRender]);

  return <ul {...getProps()}>{renderItems()}</ul>;

  function renderItems() {
    let listItems = undefined;

    if (columnInfo?.length && ref?.current) {
      listItems = <>{renderRows()}</>;
    }

    return listItems;
  }

  function renderRows() {
    const listItems = [];
    let index;

    for (index = 0; index < getNumberOfRows(columnInfo); index += 1) {
      listItems.push(renderRow(index));
    }

    return listItems;
  }

  function renderRow(index) {
    const numberOfColumnsToShow = getTotalColumnsInRow();

    return (
      <li key={index}>
        <ul className={getRowClass(index)} role="list">
          {columnInfo
            .slice(
              index * numberOfColumnsToShow,
              index * numberOfColumnsToShow + numberOfColumnsToShow
            )
            .map(renderItem)}
        </ul>
      </li>
    );
  }

  function renderItem(itemInfo, index) {
    return (
      <li key={index} className={classNames(styles.item, itemClassName)}>
        <AriaLive hiddenFromScreen={false}>
          <TransitionBlock {...getTransitionBlockProps(itemInfo, index)}>
            <div {...getItemProps(itemInfo)}>
              {itemInfo.index !== undefined && items[itemInfo.index].content}
            </div>
          </TransitionBlock>
        </AriaLive>
      </li>
    );
  }

  function getProps() {
    return {
      className: classNames(className, styles.container, { [styles.center]: center }),
      ref,
    };
  }

  function getTransitionBlockProps(itemInfo, index) {
    const delay = { delay: index * CASCADE_DELAY };
    const token = `${containerWidth}-${initialRender ? animationToken : ""}`;

    return {
      animationToken: token,
      onTransformationEnd: itemInfo.index === items.length - 1 ? onTransformationEnd : undefined,
      transitionIn: { ...TRANSITION_IN, ...delay },
      transitionInitial: TRANSITION_INITIAL,
      transitionOut: { ...TRANSITION_OUT, ...delay },
    };
  }

  function getItemProps(itemInfo) {
    return {
      className: classNames({
        [styles.horizontalSeparator]: shouldShowHorizontalSeparator(itemInfo),
        [styles.verticalSeparator]: separator && !itemInfo.last,
      }),
      style: layout === "grid" ? getStyle(itemInfo) : undefined,
    };
  }

  function shouldShowHorizontalSeparator(itemInfo) {
    const totalRows = getNumberOfRows(columnInfo);
    const isLastRow = itemInfo.row + 1 === totalRows;

    return separator && getTotalColumnsInRow() === 1 && !isLastRow;
  }

  function getRowClass(index) {
    return classNames(styles.row, rowClassName, {
      [styles.lastRow]: index === getNumberOfRows(columnInfo) - 1,
    });
  }

  function handleUpdatePageInfo(value) {
    if (timer.current) {
      window.clearTimeout(timer.current);
    }

    timer.current = window.setTimeout(() => {
      setContainerWidth(value[0].contentRect.width);
      timer.current = undefined;
    }, EVENT_AGGREGATE_DURATION);
  }

  function getStyle(item) {
    const { index, numberSpacesForItem } = item;
    const columnsInRow = getFillableColumnsInRow();
    const spaceUsedByMargins = (columnsInRow - 1) * gridColumnSpacing;
    let spaceWidth = (containerWidth - spaceUsedByMargins) / columnsInRow;

    if (columnsInRow >= numberSpacesForItem && numberSpacesForItem > 1) {
      spaceWidth = spaceWidth * numberSpacesForItem + gridColumnSpacing * (numberSpacesForItem - 1);
    }

    return {
      ...(index !== undefined && !item.last && getMarginStyles()),
      height: "100%",
      width:
        index === undefined
          ? 0
          : `${spaceWidth + (separator && !item.last ? gridColumnSpacing / 2 : 0)}px`,
    };
  }

  function getMarginStyles() {
    return separator
      ? {
          marginRight: `${gridColumnSpacing / 2}px`,
          paddingRight: `${gridColumnSpacing / 2 - 1}px`,
        }
      : {
          marginRight: `${gridColumnSpacing}px`,
        };
  }

  function getColumnInfo() {
    const info = getPlacementInfo();

    return layout === "grid" ? padRows(info) : info;
  }

  function getPlacementInfo() {
    const columnsInRow = getFillableColumnsInRow();
    const numberOfItems = items ? items.length : 0;
    const placementData = [];
    let currentRowNumber = 0;
    let index;
    let spacesFilledCurrentRow = 0;

    for (index = 0; index < numberOfItems; index += 1) {
      const currentItem = items[index];
      const numberSpacesForItem = getNumberSpacesForItem(currentItem.preferredWidth);
      const info = { index, numberSpacesForItem, row: currentRowNumber };

      if (spacesFilledCurrentRow + numberSpacesForItem <= columnsInRow) {
        spacesFilledCurrentRow += numberSpacesForItem;

        if (spacesFilledCurrentRow === columnsInRow) {
          info.last = true;
          currentRowNumber += 1;
          spacesFilledCurrentRow = 0;
        }
      } else {
        currentRowNumber += 1;
        info.row = currentRowNumber;
        spacesFilledCurrentRow = numberSpacesForItem;
      }

      placementData.push(info);
    }

    return placementData;
  }

  function getNumberSpacesForItem(preferredWidth) {
    return Math.min(
      layout === "none" || !preferredWidth ? 1 : preferredWidth,
      getFillableColumnsInRow()
    );
  }

  function padRows(info) {
    const columnsInRow = getTotalColumnsInRow();
    const numberOfRows = getNumberOfRows(info);
    let index;
    let newInfo = [];

    for (index = 0; index < numberOfRows; index += 1) {
      const itemsInRow = getItemsInRow(info, index);
      const padding = [...Array(columnsInRow || 4)].map(() => ({
        numberSpacesForItem: 0,
        row: index,
      }));

      newInfo = newInfo.concat(itemsInRow.concat(padding).slice(0, columnsInRow || 4));
    }

    return newInfo;
  }

  function getNumberOfRows(info) {
    const allRowNumbers = removeEmptyItems(info).map((item) => item.row);

    return Math.max(...allRowNumbers) + 1;
  }

  function getItemsInRow(info, index) {
    return info.filter((item) => item?.row === index);
  }

  function getTotalColumnsInRow() {
    return screenSize === "small" ? 1 : numberOfColumns || 4;
  }

  function getFillableColumnsInRow() {
    return screenSize === "small" ? 1 : numberOfColumns || (screenSize === "medium" ? 3 : 4);
  }

  function removeEmptyItems(itemsList) {
    return itemsList.filter((item) => item !== undefined);
  }
};

ListMatrix.propTypes = {
  /** Unique id that forces items to re-render. See TransitionBlock for details. */
  animationToken: transitionBlockPropTypes.animationToken,

  /** Whether to center the component. */
  center: PropTypes.bool,

  /**
   * Additional classes for positioning the component. Given classes may only position this component
   * for layout purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** className that will be applied to each item. */
  itemClassName: PropTypes.string,

  /** Complete list of items to be rendered. */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.node,
      preferredWidth: PropTypes.number,
    })
  ),

  /**
   * When displaying items, the items can be laid out in a grid that's appropriately defined for
   * each device size. Only the values used for gaps between items will utilize grid values. The
   * item width will be determined by whatever space is left over. If the numberOfColumns specified
   * match the count of columns appropriate for the device, then the items will match standard grids.
   * If layout is none, all item sizes and horizontal gaps between items must be defined by the
   * calling component.
   */
  layout: PropTypes.oneOf(["grid", "none"]),

  /**
   * Number of columns. One will always be applied for small (mobile) devices. If numberOfColumns not
   * given, the number of columns will be deduced from the device size.
   */
  numberOfColumns: PropTypes.number,

  /**
   * Function called when the items' transition is complete after initial render on when items are
   * changed.
   */
  onTransformationEnd: transitionBlockPropTypes.onTransformationEnd,

  /** className that will be applied to each row. */
  rowClassName: PropTypes.string,

  /** Option to provide separation indicator (border) between items. */
  separator: PropTypes.bool,
};
