import PropTypes from "prop-types";
import React from "react";

export const Tablet = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path
        d="M25.5,32.3H1.8V4.6h23.6V32.3z M13.7,37.2c-1,0-1.8-0.8-1.8-1.8c0-1,0.8-1.8,1.8-1.8c1,0,1.8,0.8,1.8,1.8
		C15.5,36.4,14.7,37.2,13.7,37.2 M11.3,1.8h4.6c0.3,0,0.6,0.2,0.6,0.5c0,0.3-0.3,0.5-0.6,0.5h-4.6c-0.3,0-0.5-0.2-0.5-0.4
		C10.8,2.1,11.1,1.8,11.3,1.8 M25.5,0H1.8C0.9,0,0,0.9,0,1.8v35.1c0,0.9,0.7,1.8,1.7,1.8l23.8,0c1,0,1.8-0.9,1.8-1.8V1.8
		C27.3,0.9,26.4,0,25.5,0"
      />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "0 0 27.3 38.8",
    };
  }
};

Tablet.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Tablet.defaultProps = {
  role: "img",
};
