import PropTypes from "prop-types";
import React from "react";
import { FormProvider } from "react-hook-form";

import { classNames } from "@swa-ui/string";

import { useForm } from "../useForm";
import styles from "./Form.module.scss";

/**
 * Form provides an HTML form, combined with child FormControl components, to facilitate form management, including
 * validation support.
 */

const Form = (props) => {
  const { children, className, insideGrid, methods, noValidate, onSubmit, shouldFocusError } =
    props;
  const formMethods = methods ?? useForm({ shouldFocusError });
  const { handleSubmit } = formMethods;

  return (
    <FormProvider {...formMethods}>
      <form
        className={getFormClass()}
        noValidate={noValidate}
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        {children}
      </form>
    </FormProvider>
  );

  function handleFormSubmit(data) {
    onSubmit({ ...data });
  }

  function getFormClass() {
    return classNames(className, styles.form, { [styles.insideGrid]: insideGrid });
  }
};

Form.propTypes = {
  /** Child content that is, in essences, form fields, but can contain any React nodes. */
  children: PropTypes.node.isRequired,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * Specify when form is to be used as a direct child element of the Grid component. Giving this prop will ensure all
   * grid css rules will be preserved (passed through from the form element).
   */
  insideGrid: PropTypes.bool,

  /**
   * Use form methods that should be used by the form.  If no methods are provided, the form will use the
   * defaults in @swa-ui/form useForm.
   */
  methods: PropTypes.object,

  /**
   * Indicate that the form data will not be validated on submit to suppress the browser form validation.
   */
  noValidate: PropTypes.bool,

  /**
   * When the form is submitted, it will be processed automatically, or validation errors will be returned. This props
   * allows an optional function to be given to prevent the submission or perform other actions.
   */
  onSubmit: PropTypes.func,

  /**
   * By default, if a validation error is detected, focus will automatically be given to the first offending field.
   * shouldFocusError allows this to be turned off. (Currently this is passed directly to RHF.)
   */
  shouldFocusError: PropTypes.bool,
};

Form.defaultProps = {
  noValidate: true,
  shouldFocusError: true,
};

export { Form };
