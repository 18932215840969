"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useDestinationOfferModuleAnalytics = useDestinationOfferModuleAnalytics;
var _react = require("react");
var _reactRouterDom = require("react-router-dom");
var _application = require("@swa-ui/application");
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function useDestinationOfferModuleAnalytics(placement, placementMetadata) {
  var _placement$placementD;
  var _useLocation = (0, _reactRouterDom.useLocation)(),
    pathname = _useLocation.pathname;
  var _useContext = (0, _react.useContext)(_application.MktgDataContext),
    setMktgPageData = _useContext.setMktgPageData;
  var _ref = placementMetadata !== null && placementMetadata !== void 0 ? placementMetadata : {},
    _ref$contentBlockId = _ref.contentBlockId,
    contentBlockId = _ref$contentBlockId === void 0 ? "" : _ref$contentBlockId;
  var _ref2 = (_placement$placementD = placement === null || placement === void 0 ? void 0 : placement.placementData) !== null && _placement$placementD !== void 0 ? _placement$placementD : {},
    _ref2$lfmaDetails = _ref2.lfmaDetails,
    lfmaDetails = _ref2$lfmaDetails === void 0 ? [] : _ref2$lfmaDetails;
  (0, _react.useEffect)(function () {
    if ((pathname === "/" || pathname === "/landing/home-page/") && lfmaDetails.length) {
      setMktgPageData({
        moduledetaildestinationoffers: getModuleDetails()
      }, "landingHomePage");
    }
  }, [pathname, lfmaDetails.length]);
  function getModuleDetails() {
    var contentDetails = null;
    if (lfmaDetails) {
      contentDetails = lfmaDetails.map(function (lfmaDetail) {
        return [contentBlockId].concat(_toConsumableArray(Object.values(lfmaDetail))).join(";");
      }).join(",");
    }
    return contentDetails;
  }
}