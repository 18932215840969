import PropTypes from "prop-types";
import React from "react";

export const Clipboard = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path d="M 3 7.259708737864071  L 3 27  L 23 27  L 23 7.259708737864071  L 20.102941176470534 4.354368932038843  L 26 4.354368932038843  L 26 30  L 0 30  L 0 4.354368932038843  L 5.874999999999867 4.354368932038843  L 3 7.259708737864071  Z M 5.051470588235356 9  L 21.0220588235294 9  L 16.838235294117755 4.288834951456327  C 16.838235294117755 4.288834951456327  16.838235294117755 3.7791262135922117  16.838235294117755 3.7791262135922117  C 16.838235294117755 1.696601941747598  15.132352941176466 0  13.02205882352931 0  C 10.919117647058979 0  9.205882352941156 1.689320388349525  9.205882352941156 3.7791262135922117  C 9.205882352941156 3.7791262135922117  9.205882352941156 4.288834951456327  9.205882352941156 4.288834951456327  L 5.051470588235356 9  Z M 6.882352941176556 18.83009708737862  L 19.25735294117642 18.83009708737862  L 19.25735294117642 16.63834951456305  L 6.882352941176556 16.63834951456305  L 6.882352941176556 18.83009708737862  Z M 6.882352941176556 20.67961165048539  L 6.882352941176556 22.871359223300956  L 19.25735294117642 22.871359223300956  L 19.25735294117642 20.67961165048539  L 6.882352941176556 20.67961165048539  Z M 6.882352941176556 13  L 6.882352941176556 15  L 19.25735294117642 15  L 19.25735294117642 13  L 6.882352941176556 13  Z M 14.367647058823643 3.66990291262133  C 14.367647058823643 4.383495145631064  13.779411764705888 4.966019417475695  13.05882352941169 4.966019417475695  C 12.338235294117778 4.966019417475695  11.750000000000021 4.383495145631064  11.750000000000021 3.66990291262133  C 11.750000000000021 2.9563106796115974  12.338235294117778 2.3737864077669655  13.05882352941169 2.3737864077669655  C 13.779411764705888 2.3737864077669655  14.367647058823643 2.9563106796115974  14.367647058823643 3.66990291262133  Z " />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "-3 0 32 32",
    };
  }
};

Clipboard.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Clipboard.defaultProps = { role: "img" };
