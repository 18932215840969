import React, { useEffect, useState } from "react";

import { window } from "@swa-ui/browser";

import { getInitialColors } from "../colors";
import { getInitialTheme } from "../initialTheme";

const colorPercentages = [0.4, 0.7, 0.85];
const colorShorthandRegex = /^.+ (-|\+)?\d+$/;

export const ColorThemeContext = React.createContext();
export const ColorThemeProvider = (props) => {
  const { children } = props;
  const [darkSetting, setDarkSetting] = useState(
    window?.sessionStorage?.getItem?.("darkSetting") || "light"
  );
  const [colors, setColors] = useState(getInitialColors());
  const [theme, setTheme] = useState(getInitialTheme());

  useEffect(() => {
    applyTheme();
  }, [darkSetting, colors, theme]);

  return (
    <ColorThemeContext.Provider value={getContextValue()}>{children}</ColorThemeContext.Provider>
  );

  function getContextValue() {
    return {
      addColorEntries,
      addThemeEntries,
      getColorName,
      getDark,
      getRgbValue,
      setColors,
      setDark,
      setTheme,
    };
  }

  function addColorEntries(colorEntries) {
    const colorEntriesAsHex = Object.keys(colorEntries).reduce((newColorEntries, newColor) => {
      let accumulatedColorEntries = newColorEntries;

      accumulatedColorEntries = {
        ...accumulatedColorEntries,
        [newColor]: getRgbValue(colorEntries[newColor]),
      };

      return accumulatedColorEntries;
    }, colors);

    setColors({
      ...colors,
      ...colorEntriesAsHex,
    });
  }

  function addThemeEntries(themeEntries) {
    if (Array.isArray(themeEntries) && themeEntries.length > 0) {
      setTheme(
        themeEntries.reduce(
          (newTheme, newThemeEntry) => ({
            light: {
              ...newTheme.light,
              ...newThemeEntry.light,
            },
            dark: {
              ...newTheme.dark,
              ...newThemeEntry.dark,
            },
          }),
          theme
        )
      );
    } else if (typeof themeEntries === "object" && (themeEntries.light || themeEntries.dark)) {
      setTheme({
        light: {
          ...theme.light,
          ...themeEntries.light,
        },
        dark: {
          ...theme.dark,
          ...themeEntries.dark,
        },
      });
    }
  }

  function getColorName(colorName) {
    const color = theme[getDark()][colorName];

    return color || colorName;
  }

  function getRgbValue(colorName) {
    const color = getColorName(colorName);

    return normalizeColor(color);
  }

  function getDark() {
    return window?.sessionStorage?.getItem?.("darkSetting") || "light";
  }

  function setDark(newDarkSetting) {
    window?.sessionStorage?.setItem?.("darkSetting", newDarkSetting);
    setDarkSetting(newDarkSetting);
    applyTheme();
  }

  function normalizeColor(color) {
    let normalizedColor = colors[color] || color;

    if (colorShorthandRegex.test(normalizedColor)) {
      const [baseColor, colorModifier] = color.split(" ");

      if (colorModifier !== "0") {
        let hexColor = getRgbValue(baseColor);

        if (hexColor.startsWith("#")) {
          hexColor = hexColor.replace("#", "");

          if (hexColor.length === 3) {
            hexColor = `${hexColor[0]}${hexColor[0]}${hexColor[1]}${hexColor[1]}${hexColor[2]}${hexColor[2]}`;
          }

          const [r, g, b] = hexColor.match(/.{2}/g).map((hexDuple) => {
            const adjustmentDirection = Math.sign(colorModifier);
            const adjustmentDegree = colorPercentages[Math.abs(colorModifier) - 1];
            const hexDupleBase10 = parseInt(hexDuple, 16);
            const adjustmentIntensity =
              adjustmentDirection > 0 ? 255 - hexDupleBase10 : hexDupleBase10;
            const adjustmentAmount = adjustmentDirection * adjustmentDegree * adjustmentIntensity;
            const rgbClamp = (value) => Math.min(255, Math.max(0, value));
            const computedhexDuple = rgbClamp(
              Math.round(hexDupleBase10 + adjustmentAmount)
            ).toString(16);

            return computedhexDuple.padStart(2, "0").toUpperCase();
          });

          normalizedColor = `#${r}${g}${b}`;
        }
      } else {
        normalizedColor = colors[baseColor] || color;
      }
    }

    return normalizedColor;
  }

  function applyTheme() {
    const root = document.getElementsByTagName("html")[0];
    const activeTheme = theme[darkSetting];
    const themeVariablesAsCSS = Object.keys(activeTheme).map(
      (colorNameKey) => `--${colorNameKey}: ${normalizeColor(activeTheme[colorNameKey])}`
    );
    const colorVariablesAsCSS = Object.keys(colors).map(
      (colorNameKey) => `--${colorNameKey}: ${colors[colorNameKey]}`
    );
    const newTheme = [...themeVariablesAsCSS, ...colorVariablesAsCSS].join(";");

    if (newTheme.length > 0) {
      root.style.cssText = newTheme;
    }
  }
};
