import PropTypes from "prop-types";
import React from "react";

export const Three = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path
        strokeWidth={0}
        d="M19,0C8.5,0,0,8.5,0,19s8.5,19,19,19s19-8.5,19-19S29.5,0,19,0z M22.7,25.6c-1,1-2.3,1.5-3.8,1.5
		c-1.4,0-2.6-0.4-3.5-1.2c-0.9-0.8-1.5-1.9-1.6-3.2l2.9-0.4c0.1,0.7,0.3,1.3,0.8,1.7c0.4,0.4,0.9,0.6,1.5,0.6s1.1-0.2,1.6-0.7
		s0.6-1.1,0.6-1.9s-0.2-1.4-0.6-1.8s-0.9-0.7-1.5-0.7c-0.4,0-0.8,0.1-1.4,0.2l0.3-2.5c0.8,0,1.4-0.2,1.9-0.5
		c0.4-0.4,0.6-0.9,0.6-1.5c0-0.5-0.2-1-0.5-1.3s-0.7-0.5-1.3-0.5c-0.5,0-1,0.2-1.3,0.5c-0.4,0.4-0.6,0.9-0.7,1.6L14,15.1
		c0.2-1,0.5-1.7,0.9-2.3s0.9-1,1.6-1.3S18,11,18.8,11c1.5,0,2.7,0.5,3.6,1.4c0.7,0.8,1.1,1.6,1.1,2.6c0,1.4-0.8,2.5-2.3,3.3
		c0.9,0.2,1.6,0.6,2.2,1.3c0.5,0.7,0.8,1.5,0.8,2.4C24.2,23.4,23.7,24.6,22.7,25.6z"
      />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      style,
      viewBox: "0 0 38 38",
    };
  }
};

Three.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Three.defaultProps = {
  role: "img",
};
