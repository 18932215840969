import { withFormField } from "@swa-ui/form";

import { AutocompleteLocations } from "../AutocompleteLocations";
import { getCarLocationName } from "../CarLocations";

/**
 * AutocompleteLocationsFormField provides support for a standard AutocompleteLocations
 * component to be used in a Form.
 */

export const AutocompleteLocationsFormField = withFormField(AutocompleteLocations, {
  renderCaption: (props, value) => (value ? getCarLocationName(value) : ""),
  useController: true,
});
