import { Input } from "@swa-ui/core";

import { withFormField } from "../withFormField";

/**
 * NumberFormField provides support for a standard Input component to be used as
 * a number input in a Form.
 */

export const NumberFormField = withFormField(Input, { type: "number" });
