import PropTypes from "prop-types";
import React from "react";

export const Wifi = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path d="M17.5,21.9c-1.2,0-2,0.9-2,2s0.9,2,2,2c1.2,0,2.1-0.9,2.1-2S18.7,21.9,17.5,21.9z" />
      <path d="M17.5,0C10.9,0,4.7,2.6,0,7.3l2.9,3c3.9-3.9,9.1-6,14.6-6s10.7,2.2,14.6,6l3-3C30.4,2.6,24.1,0,17.5,0z" />
      <path d="M5.8,13.1l2.4,2.4c2.5-2.5,5.8-3.9,9.4-3.9c3.5,0,6.8,1.4,9.4,3.9l2.4-2.4C26.1,9.9,22,8.2,17.5,8.2 C13.1,8.2,8.9,9.9,5.8,13.1z" />
      <path d="M11.1,18.4l1.8,1.8c1.2-1.2,2.9-1.9,4.6-1.9c1.7,0,3.4,0.7,4.6,1.9l1.8-1.8c-1.7-1.7-4-2.7-6.4-2.7 S12.8,16.7,11.1,18.4z" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "0 0 35.1 25.9",
    };
  }
};

Wifi.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Wifi.defaultProps = {
  role: "img",
};
