import React, { createContext, useContext } from "react";

import { useDeviceInfo } from "@swa-ui/browser";

/**
 * ApplicationContextProvider provides the application context in a React context so the application context is
 * available to components in an app's render tree.
 */

export const ApplicationContext = createContext({});
export const ApplicationContextProvider = (props) => {
  const { children, context } = props;
  const { screenSize } = useDeviceInfo();
  const contextFromContext = useContext(ApplicationContext);

  return <ApplicationContext.Provider value={getValue()}>{children}</ApplicationContext.Provider>;

  function getValue() {
    return {
      ...contextFromContext,
      ...context,
      screenSize,
    };
  }
};

ApplicationContext.displayName = "ApplicationContext";
