/**
 * createHashCode
 * Generates a 32-bit hash representation of a string.
 * @param string
 * @returns number
 */

export const createHashCode = (string) => {
  let hash = 0;
  let loopIndex;

  for (loopIndex = 0; loopIndex < string?.length; loopIndex += 1) {
    const char = string.charCodeAt(loopIndex);

    /* eslint-disable no-bitwise */
    hash = (hash << 5) - hash + char;
    hash = hash & hash;
    /* eslint-enable no-bitwise */
  }

  return hash;
};
