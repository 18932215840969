import PropTypes from "prop-types";
import React from "react";

import { classNames } from "@swa-ui/string";

import styles from "./Textarea.module.scss";

export const Textarea = React.forwardRef((props, ref) => {
  const {
    autoComplete,
    className,
    defaultValue,
    disabled,
    error,
    id,
    maxLength,
    minLength,
    name,
    onBlur,
    onChange,
    onClick,
    onFocus,
    onKeyDown,
    placeholder,
    readOnly,
    required,
    resize,
    textAlign,
  } = props;

  return <textarea {...getTextareaProps()} />;

  function getTextareaProps() {
    return {
      "aria-describedby": props["aria-describedby"],
      "aria-disabled": disabled,
      "aria-invalid": error,
      "aria-label": props["aria-label"],
      "aria-labelledby": props["aria-labelledby"],
      "aria-required": required,
      autoComplete,
      className: getClass(),
      "data-test": props["data-test"],
      defaultValue,
      disabled,
      id,
      maxLength,
      minLength,
      name,
      onBlur,
      onChange,
      onClick,
      onFocus,
      onKeyDown,
      placeholder,
      readOnly,
      ref,
      required,
    };
  }

  function getClass() {
    return classNames({
      [className]: className,
      [styles.center]: textAlign === "center",
      [styles.disabled]: disabled,
      [styles.error]: error,
      [styles.left]: textAlign === "left",
      [styles.prohibitResize]: !resize,
      [styles.right]: textAlign === "right",
      [styles.textarea]: true,
    });
  }
});

Textarea.displayName = "Textarea";

Textarea.propTypes = {
  /** aria-describedby id to element which provides additional accessibility description of input element. */
  "aria-describedby": PropTypes.string,

  /** aria-label text to provide additional accessibility description of input element. */
  "aria-label": PropTypes.string,

  /** aria-labelledby id to element which provides additional accessibility description of input element. */
  "aria-labelledby": PropTypes.string,

  /** HTML input element attribute for autoComplete. */
  autoComplete: PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Name to use for the data-test attribute used for automated testing. */
  "data-test": PropTypes.string,

  /**
   * Initial value for textarea. If given, Input assumes that the component will not be "controlled" and the value
   * will be maintained in the DOM.
   */
  defaultValue: PropTypes.string,

  /** Indicates Input should apply disabled styling and ignore mouse and key events. */
  disabled: PropTypes.bool,

  /** Indicates Input should apply error styling and apply aria-invalid attribute. */
  error: PropTypes.bool,

  /** ID to be added to input element. */
  id: PropTypes.string,

  /** Maximum number of characters allowed in input element. */
  maxLength: PropTypes.number,

  /** Minimum number of characters allowed in input element. */
  minLength: PropTypes.number,

  /** Name to be added to input element. */
  name: PropTypes.string,

  /** Optional event handler to learn when focus is lost from input element. */
  onBlur: PropTypes.func,

  /** Optional event handler to learn when input element's content is changed. */
  onChange: PropTypes.func,

  /** Optional event handler to learn when input element is clicked. */
  onClick: PropTypes.func,

  /** Optional event handler to learn when focus is given to input element. */
  onFocus: PropTypes.func,

  /** Optional event handler to learn when a key is pressed. */
  onKeyDown: PropTypes.func,

  /** Optional value to show when field is empty. */
  placeholder: PropTypes.string,

  /** Indicates Input should be immutable and ignore mouse and key events. */
  readOnly: PropTypes.bool,

  /** Indicates Input should apply aria-required attribute. */
  required: PropTypes.bool,

  /** Allow the textarea to be resized. */
  resize: PropTypes.bool,

  /** Indicates how the value is positioned inside the input element. */
  textAlign: PropTypes.oneOf(["center", "left", "right"]),
};

Textarea.defaultProps = {
  autoComplete: "off",
  resize: false,
};
