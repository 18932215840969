import PropTypes from "prop-types";
import React from "react";

export const RetailCircle = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path
        d="M19,0C8.5,0,0,8.5,0,19s8.5,19,19,19s19-8.5,19-19S29.5,0,19,0z M7.5,7.4c0-0.7,0.4-1.1,1.1-1.1
		c0.7,0,8.1,0,8.5,0c0.4,0,0.7,0.1,0.9,0.4l11.1,11.1c0.5,0.5,0.5,1.4,0,1.9L21,27.9c-0.5,0.5-1.4,0.5-1.9,0L7.9,16.8
		c-0.2-0.2-0.4-0.6-0.4-1C7.5,15.8,7.5,8.1,7.5,7.4z M30.1,25.8l-9.9,9.9L7.7,23.2v-3.5l12.4,12.4l8.2-8.2l1-1c0,0,0.4,0.4,0.9,1
		C30.6,24.4,30.6,25.3,30.1,25.8z"
      />
      <path
        d="M12.6,13.5c1.1,0,2.1-0.9,2.1-2.1c0-1.1-0.9-2.1-2.1-2.1c-1.1,0-2.1,0.9-2.1,2.1
		C10.6,12.7,11.5,13.6,12.6,13.5z"
      />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      style,
      viewBox: "-2 -2 42 42",
    };
  }
};

RetailCircle.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * Custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

RetailCircle.defaultProps = { role: "img" };
