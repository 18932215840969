import PropTypes from "prop-types";
import React from "react";

export const LapChild = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path d="M26.36,20.94,23.8,14.77a4,4,0,0,0-3.73-2.49h-1a4.72,4.72,0,1,0-6.13,0h-1A4,4,0,0,0,8.2,14.77L5.64,20.94A2,2,0,0,0,6.7,23.57h0l2.75,1.14v1.7A1.74,1.74,0,0,0,11.35,28h9.53a1.74,1.74,0,0,0,1.86-1.58V24.63l2.53-1.05h0A2,2,0,0,0,26.36,20.94ZM14.58,18.42a1.33,1.33,0,0,1,1.91.45A4,4,0,0,0,20.07,21a4.1,4.1,0,0,0,1.45-.27l-.92.38L16,23.05l-4.61-1.9-.55-.23Zm5.49,1.27A2.7,2.7,0,1,1,22.76,17,2.69,2.69,0,0,1,20.07,19.69Z" />
    </svg>
  );

  function getProps() {
    return {
      "aria-hidden": props["aria-hidden"],
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      style,
      viewBox: "0 0 32 32",
    };
  }
};

LapChild.propTypes = {
  /** aria-hidden text to hide non-interactive content from the accessibility API icon. */
  "aria-hidden": PropTypes.string,

  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

LapChild.defaultProps = {
  role: "img",
};
