import PropTypes from "prop-types";
import React, { useEffect } from "react";

import { getBootstrapData } from "@swa-ui/bootstrap";
import { useDeviceInfo, window } from "@swa-ui/browser";

export const ChatbotContext = React.createContext();

/**
 * ChatbotProvider initializes the salesforce chatbot functionality injected into the application via akamai, and exposes
 * a function capable of activating the chatbot inside of the application.
 *
 * activateChatbot - Function used to notify the salesforce chatbot mechanism to begin a chat session.
 */

export const ChatbotProvider = (props) => {
  const { children } = props;
  const { isSmallOrMedium } = useDeviceInfo();

  useEffect(() => {
    isSmallOrMedium && initializeChatbot();
  }, []);

  return <ChatbotContext.Provider value={{ activateChatbot }}>{children}</ChatbotContext.Provider>;

  function initializeChatbot() {
    const chatbotConfig = getBootstrapData("chatbot-config.mobile");

    if (chatbotConfig) {
      if (!window.embedded_svc) {
        const scriptTag = window.document.createElement("script");

        scriptTag.setAttribute("src", chatbotConfig.CHATBOT_EMBEDDED_WINDOW_URL);
        scriptTag.onload = () => {
          initESW();
        };
        window.document.body && window.document.body.appendChild(scriptTag);
      } else {
        initESW();
      }
    }

    function initESW() {
      const {
        CHATBOT_BASE_LIVE_AGENT_CONTENT_URL,
        CHATBOT_BASE_LIVE_AGENT_URL,
        CHATBOT_BUTTON_ID,
        CHATBOT_DEPLOYMENT_ID,
        CHATBOT_ESW_LIVE_AGENT_DEV_NAME,
        CHATBOT_ORG_ENDPOINT,
        CHATBOT_ORG_ID,
        CHATBOT_ORG_URL,
      } = chatbotConfig ?? {};
      const { embedded_svc } = window;

      if (embedded_svc) {
        embedded_svc.settings.displayHelpButton = false;
        embedded_svc.settings.language = "";
        embedded_svc.settings.chatbotAvatarImgURL =
          "https://www.southwest.com/swa-resources/images/logos/chatbotlogo.png";
        embedded_svc.settings.avatarImgURL =
          "https://www.southwest.com/swa-resources/images/logos/chatbotlogo.png";
        embedded_svc.settings.enabledFeatures = ["LiveAgent"];
        embedded_svc.settings.entryFeature = "LiveAgent";

        embedded_svc.init(
          CHATBOT_ORG_URL,
          CHATBOT_ORG_ENDPOINT,
          null,
          CHATBOT_ORG_ID,
          "Mobile_Web",
          {
            baseLiveAgentContentURL: CHATBOT_BASE_LIVE_AGENT_CONTENT_URL,
            deploymentId: CHATBOT_DEPLOYMENT_ID,
            buttonId: CHATBOT_BUTTON_ID,
            baseLiveAgentURL: CHATBOT_BASE_LIVE_AGENT_URL,
            eswLiveAgentDevName: CHATBOT_ESW_LIVE_AGENT_DEV_NAME,
            isOfflineSupportEnabled: false,
          }
        );
      }
    }
  }

  function activateChatbot() {
    window?.embedded_svc?.inviteAPI?.inviteButton?.acceptInvite?.();
  }
};

ChatbotProvider.propTypes = {
  /** Content that will be rendered as the page body. */
  children: PropTypes.node.isRequired,
};
