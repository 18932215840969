import PropTypes from "prop-types";
import React from "react";

export const Vacation = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path d="M49.3,12.7c-0.4-0.6-0.9-1-1.4-1.5l1.7-1.5c0.4-0.4,1.1-0.3,1.4,0.1c0.4,0.4,0.3,1.1-0.1,1.4L49.3,12.7z" />
      <path
        d="M43.5,9.5c-0.3,0-0.7,0-1,0l0.1-2.2c0-0.6,0.5-1,1.1-0.9c0.6,0,1,0.5,0.9,1.1l-0.1,2.2
                C44.2,9.6,43.8,9.5,43.5,9.5z"
      />
      <path d="M37.3,12l-1.5-1.7C35.5,10,35.5,9.3,35.9,9C36.3,8.6,37,8.6,37.4,9l1.5,1.7C38.3,11.1,37.8,11.5,37.3,12z" />
      <path
        d="M37.1,16.8c0.2-3.2,3-5.7,6.3-5.5c3.3,0.2,5.8,3,5.6,6.3l0,0c-0.3,3.2-3,5.6-6.3,5.5
                C39.4,22.9,36.9,20.1,37.1,16.8L37.1,16.8z"
      />
      <path d="M33.1,17.6c-0.6,0-1-0.5-0.9-1.1c0-0.6,0.5-1,1.1-0.9l2.1,0.1c0,0.7,0,1.4,0.1,2L33.1,17.6z" />
      <path d="M36.7,21.7c0.4,0.6,0.9,1,1.4,1.5l-1.7,1.5C36,25,35.4,25,35,24.5c-0.4-0.4-0.3-1.1,0.1-1.4L36.7,21.7z" />
      <path
        d="M48.7,22.4l1.5,1.7c0.4,0.4,0.3,1.1-0.1,1.4c-0.4,0.4-1.1,0.3-1.4-0.1l-1.5-1.7
                C47.8,23.3,48.3,22.9,48.7,22.4z"
      />
      <path d="M52.8,18.8l-2.4-0.1c0.2-0.6,0.3-1.3,0.4-2l2.2,0.1c0.6,0,1,0.5,1,1.1C53.9,18.4,53.4,18.8,52.8,18.8z" />
      <path
        d="M42.6,24.9c0.3,0,0.7,0,1,0l-0.1,2.2c0,0.6-0.5,1-1.1,1c-0.6,0-1-0.5-0.9-1.1l0.1-2.2
                C41.9,24.8,42.2,24.9,42.6,24.9z"
      />
      <path
        d="M7.4,9.4L1.8,7.7c2.7-4,7.5-4.3,11.2-2.7c2.3-3.7,6.8-6.3,11.5-4.1l-3.9,4.4c4.5-1.1,10,0.3,11.9,5.6
                l-8.7,0.5c2.5,2.2,4.1,5.4,3.1,9.3l-8.4-5.2l7.3,17.1c-1.7,0.6-4,1.7-5.5,2.6l-5.6-18.2l-4.6,9c-2.8-2.8-3.2-6.3-2.2-9.5l-7.7,4.1
                C-0.9,14.9,3,10.8,7.4,9.4z"
      />
      <path d="M34.1,32.8c-5.9,0-11.4,2.3-15.4,6h30.7C45.3,35.2,40,32.8,34.1,32.8z" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      style,
      viewBox: "0 0 54 39",
    };
  }
};

Vacation.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Vacation.defaultProps = {
  role: "img",
};
