import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import { classNames } from "@swa-ui/string";

import globalRules from "../assets/styles/globalRules.module.scss";
import { keyCodes } from "../defines/keyCodes";
import { Transform } from "../Transform";
import styles from "./RadioButton.module.scss";

/**
 * RadioButton provides a SWA themed radio button group analogous to a standard HTML radio button. RadioButton creates
 * a hidden HTML radio button to allow autofill in forms to preselect a radio button.
 */

export const RadioButton = React.forwardRef((props, ref) => {
  const {
    className,
    defaultValue,
    direction,
    disabled,
    error,
    labelAlignment,
    name,
    onBlur,
    onChange,
    onFocus,
    radioButtonInfoList,
    required,
    styleType,
    value,
  } = props;
  const [currentValue, setCurrentValue] = useState(defaultValue);
  const [currentFocus, setCurrentFocus] = useState(defaultValue); // current radio button item with focus
  const [componentFocus, setComponentFocus] = useState(); // outer element (whole component) has focus
  const clickPending = useRef(false);

  useEffect(() => {
    setCurrentValue(value || defaultValue);
    setComponentFocus(false); // force initial re-render to pick up defaultValue
  }, [defaultValue, value]);

  useEffect(() => {
    if (componentFocus && !clickPending.current) {
      setCurrentFocus(currentValue || radioButtonInfoList[0].value);
      clickPending.current = false;
    }
  }, [componentFocus]);

  return (
    <div {...getProps()}>
      {renderHtmlRadioButtonElements()}
      {renderRadioButtons()}
      <span aria-live="polite" className={globalRules.hiddenFromScreen} id={`radio-button-${name}`}>
        RadioButton group. {currentValue} option selected. &nbsp;
        {getIndexOfValue(currentValue) + 1} of {radioButtonInfoList.length}.
      </span>
    </div>
  );

  function renderHtmlRadioButtonElements() {
    return (
      <div
        aria-label={props["aria-label"]}
        className={globalRules.hiddenFromScreen}
        role="radiogroup"
      >
        {radioButtonInfoList.map((buttonInfo, index) => (
          <input key={buttonInfo.value} {...getHtmlRadioButtonProps(buttonInfo, index)} />
        ))}
      </div>
    );
  }

  function renderRadioButtons() {
    return radioButtonInfoList.map((buttonInfo, index) => {
      const { value: buttonValue } = buttonInfo;
      const { caption, label } = buttonInfo;

      return (
        <div {...getContainerProps(buttonValue)} key={buttonValue}>
          <div className={styles.focusRing}>
            <div className={classNames(styles.radioButtonElement)}>
              <Transform {...getSelectDotTransformProps(buttonValue)} />
            </div>
          </div>
          <div className={styles.labelContainer}>
            <div id={`${name}-id-${index}`} className={styles.label}>
              {label}
            </div>
            {caption && <div className={getCaptionClass(index)}>{caption}</div>}
          </div>
        </div>
      );
    });
  }

  function getProps() {
    return {
      className: classNames(className, styles.radioButton, {
        [styles.columnDirection]: direction === "column",
      }),
      onBlur: handleBlur,
      onFocus: handleFocus,
      onKeyDown: handleKeyDown,
      onMouseDown: handleMouseDown,
      ref,
      tabIndex: 0,
    };
  }

  function getHtmlRadioButtonProps(buttonInfo, index) {
    const buttonValue = buttonInfo.value;

    return {
      "aria-checked": buttonValue === currentValue,
      "aria-disabled": disabled,
      "aria-labelledby": `${name}-id-${index}`,
      "aria-required": required,
      checked: buttonValue === currentValue,
      "data-test": props["data-test"],
      disabled,
      name,
      onBlur,
      onChange,
      required,
      tabIndex: -1,
      type: "radio",
      value: buttonValue,
    };
  }

  function getContainerProps(buttonValue) {
    return {
      ["aria-controls"]: `radio-button-${name}`,
      ["aria-hidden"]: true,
      className: getContainerClass(buttonValue),
      onClick: handleClick.bind(this, buttonValue),
    };
  }

  function getSelectDotTransformProps(buttonValue) {
    const visible = buttonValue === currentValue;
    const opacity = visible ? "1" : "0.5";
    const scale = visible ? "0.90" : "0";

    return {
      className: styles.selectDot,
      transformations: [
        {
          action: "opacity",
          amount: opacity,
        },
        {
          action: "scaleX",
          amount: scale,
        },
        {
          action: "scaleY",
          amount: scale,
        },
      ],
    };
  }

  function getContainerClass(buttonValue) {
    return classNames({
      [styles.componentFocus]: componentFocus,
      [styles.container]: true,
      [styles.disabled]: disabled || radioButtonInfoList[getIndexOfValue(buttonValue)].disabled,
      [styles.error]: error,
      [styles.focus]: buttonValue === currentFocus,
      [styles.labelAlignBottom]: labelAlignment === "bottom",
      [styles.labelAlignTop]: labelAlignment === "top",
      [styles.secondary]: styleType === "secondary",
    });
  }

  function getCaptionClass(index) {
    const lastItem = index === radioButtonInfoList.length - 1;

    return classNames({
      [styles.caption]: true,
      [styles.captionColumnOrFirstInRow]:
        direction === "column" || (direction === "row" && index === 0),
      [styles.spaceBeforeNextColumn]: direction === "row" && !lastItem,
      [styles.spaceBeforeNextRow]: direction === "column" && !lastItem,
    });
  }

  function handleBlur(event) {
    onBlur && onBlur(event);
    setComponentFocus(false);
  }

  function handleMouseDown() {
    // Because focus event received before click, don't have focus set to a radio button if click will come along
    // and set it again. Instead, for click events, don't set focus until after click is processed.
    clickPending.current = true;
  }

  function handleClick(buttonValue) {
    !radioButtonInfoList[getIndexOfValue(buttonValue)].disabled && select(buttonValue);
  }

  function handleFocus(event) {
    setComponentFocus(true);
    onFocus && onFocus(event);
  }

  function handleKeyDown(event) {
    const { key } = event;
    const keyProcessors = {
      [keyCodes.KEY_DOWN]: processRight,
      [keyCodes.KEY_ENTER]: processEnter,
      [keyCodes.KEY_LEFT]: processLeft,
      [keyCodes.KEY_RIGHT]: processRight,
      [keyCodes.KEY_SPACE]: processEnter,
      [keyCodes.KEY_UP]: processLeft,
    };

    keyProcessors[key] &&
      event.target.className?.includes(styles.radioButton) &&
      keyProcessors[key](event);
  }

  function processEnter(event) {
    select(currentFocus);
    event.preventDefault();
  }

  function processLeft(event) {
    const index = getPreviousEnabledButton();
    const previousEnabledButtonValue = radioButtonInfoList[index].value;

    setCurrentFocus(previousEnabledButtonValue);
    select(previousEnabledButtonValue);
    event.preventDefault();
  }

  function getPreviousEnabledButton() {
    const { length } = radioButtonInfoList;
    let index = getIndexOfValue(currentFocus);

    if (index === 0) {
      index = length;
    }

    let list = radioButtonInfoList.slice(0, index).reverse();
    let previousIndex = list.findIndex((buttonItem) => !buttonItem.disabled);

    if (previousIndex === -1) {
      list = radioButtonInfoList.slice(index).reverse();
      previousIndex = list.findIndex((buttonItem) => !buttonItem.disabled);
      previousIndex = length - previousIndex - 1;
    } else {
      previousIndex = index - previousIndex - 1;
    }

    return previousIndex;
  }

  function processRight(event) {
    const index = getNextEnabledButton();
    const nextEnabledButtonValue = radioButtonInfoList[index].value;

    setCurrentFocus(nextEnabledButtonValue);
    select(nextEnabledButtonValue);
    event.preventDefault();
  }

  function getNextEnabledButton() {
    const { length } = radioButtonInfoList;
    let index = getIndexOfValue(currentFocus);

    index = index >= length - 1 ? 0 : index + 1;

    const list = radioButtonInfoList.slice(index);
    let nextIndex = list.findIndex((buttonItem) => !buttonItem.disabled);

    if (nextIndex === -1) {
      nextIndex = radioButtonInfoList.findIndex((buttonItem) => !buttonItem.disabled);
    } else {
      nextIndex += index;
    }

    return nextIndex;
  }

  function select(buttonValue) {
    setCurrentValue(buttonValue);
    setCurrentFocus(buttonValue);
    onChange(buttonValue);
  }

  function getIndexOfValue(buttonValue) {
    return Math.max(
      radioButtonInfoList.findIndex((buttonInfo) => buttonInfo.value === buttonValue),
      0
    );
  }
});

RadioButton.displayName = "RadioButton";

RadioButton.propTypes = {
  /** aria-label text to provide additional accessibility description of radio button element.  */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Name to use for the data-test attribute used for automated testing. */
  "data-test": PropTypes.string,

  /** Optional initial radio button selection. */
  defaultValue: PropTypes.string,

  /** The RadioButtons can be spread across the page, or can be stacked vertically in a column. */
  direction: PropTypes.oneOf(["column", "row"]),

  /** Indicates RadioButton should apply disabled styling, ignore click events and provide aria-disabled attribute. */
  disabled: PropTypes.bool,

  /** Indicates RadioButton should apply error styling. */
  error: PropTypes.bool,

  /** The label content can be aligned to radio button in three ways. */
  labelAlignment: PropTypes.oneOf(["bottom", "center", "top"]),

  /** Name given to radio button group when form is submitted. */
  name: PropTypes.string.isRequired,

  /** Optional event handler to learn when focus is lost from RadioButton element. */
  onBlur: PropTypes.func,

  /** Optional event handler to learn when RadioButton selection changes. */
  onChange: PropTypes.func.isRequired,

  /** Content that will be rendered for each radio button. Value represents what will submitted with form. */
  radioButtonInfoList: PropTypes.arrayOf(
    PropTypes.shape({
      caption: PropTypes.node,
      label: PropTypes.node,
      value: PropTypes.string,
    })
  ),

  /** Indicates RadioButton should apply aria-required attribute. */
  required: PropTypes.bool,

  /** Indicates the style of the component. */
  styleType: PropTypes.oneOf(["primary", "secondary"]),

  /** Optional radio button selection for controlled radio buttons. */
  value: PropTypes.string,
};

RadioButton.defaultProps = {
  direction: "row",
  onChange: () => {}, // because a value is set by this component, making it controlled, a change handler is required
};
