import PropTypes from "prop-types";
import React from "react";

import { classNames } from "@swa-ui/string";

import { Button } from "../Button";
import { Icon } from "../Icon";
import styles from "./CloseButton.module.scss";

const CLOSE_BUTTON_ICON_COLOR_DESIGN_TOKEN = "cmp-core-color-close-button-icon";

/**
 * A simple component to standardize the traditional "X" close button styling, a11y, and functionality.
 */

export const CloseButton = (props) => {
  const { className, color, onClick } = props;

  return (
    <Button {...getProps()}>
      <Icon color={color || CLOSE_BUTTON_ICON_COLOR_DESIGN_TOKEN} name="Close" />
    </Button>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className: classNames(className, styles.closeButton),
      onClick,
      styleType: "no-style",
    };
  }
};

CloseButton.propTypes = {
  /** aria-label text to provide additional accessibility description of button element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Color to use to style the Icon. */
  color: PropTypes.string,

  /** Callback to inform parent component when the parent should be "closed" because the CloseButton was clicked. */
  onClick: PropTypes.func,
};
