import PropTypes from "prop-types";
import React from "react";

export const Pinterest = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path
        strokeWidth={0}
        d="M16,2a14,14,0,0,0-5.08,27,13.31,13.31,0,0,1,.05-4c.26-1.09,1.64-6.95,1.64-6.95A5.05,5.05,0,0,1,12.19,16c0-1.94,1.13-3.39,2.53-3.39a1.76,1.76,0,0,1,1.77,2c0,1.2-.76,3-1.16,4.65a2,2,0,0,0,2.08,2.53c2.48,0,4.39-2.62,4.39-6.4A5.52,5.52,0,0,0,16,9.7a6,6,0,0,0-6.31,6.06,5.43,5.43,0,0,0,1,3.2.41.41,0,0,1,.09.4c-.1.44-.34,1.4-.39,1.59s-.2.32-.46.2c-1.75-.81-2.84-3.36-2.84-5.42,0-4.41,3.2-8.46,9.24-8.46,4.85,0,8.62,3.46,8.62,8.07,0,4.83-3,8.71-7.27,8.71a3.79,3.79,0,0,1-3.22-1.61l-.87,3.32a14.94,14.94,0,0,1-1.72,3.62A14,14,0,1,0,16,2Z"
      />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      style,
      viewBox: "0 0 32 32",
    };
  }
};

Pinterest.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Pinterest.defaultProps = {
  role: "img",
};
