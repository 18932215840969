import PropTypes from "prop-types";
import React from "react";

export const TravelTime = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path
        d="M25.6,31.5c-2.3,2.6-5.7,4.2-9.4,4.2c-7,0-12.6-5.7-12.6-12.6c0-3.7,1.6-7.1,4.2-9.4c2.2-2,5.2-3.2,8.4-3.2
		c7,0,12.6,5.7,12.6,12.6C28.8,26.3,27.6,29.2,25.6,31.5 M16.2,6.9c-4.2,0-8.1,1.6-11,4.3C2,14.1,0,18.3,0,23.1
		c0,9,7.3,16.2,16.2,16.2c4.7,0,9-2,11.9-5.2c2.7-2.9,4.3-6.7,4.3-11C32.4,14.1,25.2,6.9,16.2,6.9"
      />
      <path d="M14.6,24.3l1.3-10.1c0-0.2,0.2-0.4,0.4-0.4c0.2,0,0.4,0.2,0.5,0.4l1.6,10.1L14.6,24.3z" />
      <path
        d="M16.3,4.9c1,0,1.9,0.1,2.9,0.2V2.9c0-1.6-1.3-2.9-2.9-2.9c-1.6,0-2.9,1.3-2.9,2.9v2.2
		C14.4,5,15.3,4.9,16.3,4.9"
      />
      <path
        d="M26.8,8.1c0.5,0.4,1,0.7,1.5,1.1L29,8.1c0.6-0.8,0.4-2-0.5-2.6C27.7,5,26.6,5.2,26,6l-0.8,1.2
		C25.7,7.5,26.2,7.8,26.8,8.1"
      />
      <path
        d="M16.5,24.1c-0.6,0-1.2-0.1-1.9,0.1v0.2c0,1.1,0.9,1.9,1.9,1.9c1.1,0,1.9-0.9,1.9-1.9v-0.2
		C17.8,24.1,17.1,24.1,16.5,24.1"
      />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "-3 0 37 40",
    };
  }
};

TravelTime.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

TravelTime.defaultProps = {
  role: "img",
};
