import React from "react";

import { Icon } from "../Icon";

export const ArrowThinAdapter = (props) => {
  const { actions, name } = props;

  return <Icon {...getIconProps()} />;

  function getIconProps() {
    return {
      ...props,
      actions: [...actions, name === "ArrowThinRight" ? "rotate90" : "rotate270"],
      name: "ArrowThin",
    };
  }
};
