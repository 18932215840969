/**
 * getDestinationName outputs the display name from a given destination code.
 *
 * Parameter example: 'ABQ'
 * Return value: Albuquerque, NM - ABQ
 *
 */

export const getDestinationName = (station) => station.displayValue;

/**
 * getDestinationIdFromName returns the ID for the given station display name.
 *
 * Parameter example: 'Albuquerque, NM - ABQ'
 * Return value: station info
 *
 */

export const getDestinationFromName = (displayName, currentList) =>
  currentList?.find((destination) => destination.displayName === displayName);
