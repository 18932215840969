/**
 * classNames is the dotcom solution to constructing React component class strings for applying component styles.
 * @param {...(string|array|object)} args A collection of various data types used to construct the string
 * @returns A string constructed of all "truthy" arg values.
 * @example
 *
 *   classNames('foo', ['bar', 'baz'], { qux: true, quux: false });
 *   // returns 'foo bar baz qux'
 */

export function classNames(...args) {
  const classes = [];

  args.forEach((arg) => {
    if (arg) {
      const argType = typeof arg;

      if (argType === "string") {
        classes.push(arg);
      } else if (Array.isArray(arg)) {
        classes.push(classNames(...arg));
      } else if (argType === "object") {
        classes.push(...Object.keys(arg).filter((key) => arg[key]));
      }
    }
  });

  return classes.length > 0 ? classes.join(" ") : undefined;
}
