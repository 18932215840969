import PropTypes from "prop-types";
import React from "react";

export const PointsCircle = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path d="M16,2C8.3,2,2,8.3,2,16s6.3,14,14,14s14-6.3,14-14S23.7,2,16,2z M6,17.4c-0.6-1.4-1-2.7-1-4.2 C5,8.7,8.7,5,13.2,5c1.4,0,2.8,0.4,4.1,1.1C16.7,6.1,16,6,15.4,6v0c-5.3,0-9.5,4.3-9.5,9.6C5.9,16.2,6,16.8,6,17.4z M6.8,15.5 c0-4.7,3.9-8.7,8.6-8.7c1.5,0,3,0.4,4.2,1.1c-0.6-0.1-1.3-0.2-1.9-0.2v0c-5.5,0-10,4.5-10,10c0,0.6,0.1,1.3,0.2,1.9 C7.2,18.5,6.8,17,6.8,15.5z M17.9,27c-5,0-9.1-4.1-9.1-9.2c0-5.1,4.1-9.2,9.1-9.2s9.1,4.1,9.1,9.2C27,22.9,22.9,27,17.9,27z" />
      <path d="M14.8,15.2c-0.4,0-0.9,0.1-1.2,0.2l-0.7,4.1h0.9L14,18h0.4c1.1,0,1.8-0.6,1.8-1.6 C16.2,15.7,15.8,15.2,14.8,15.2z M14.5,17.2h-0.3l0.2-1.2c0.1,0,0.3-0.1,0.4-0.1c0.3,0,0.6,0.2,0.6,0.5C15.3,17,15,17.2,14.5,17.2 z" />
      <polygon points="20.2,15.2 16.8,15.2 16.6,16 17.9,16 17.3,19.5 18.2,19.5 18.8,16 20,16" />
      <path d="M20.4,16.6c0,0.5,0.2,0.8,0.9,1.1c0.5,0.2,0.7,0.4,0.7,0.6c0,0.3-0.3,0.5-0.8,0.5c-0.4,0-0.7-0.1-1-0.3v0 l-0.3,0.8c0.3,0.2,0.9,0.3,1.3,0.3c1.1,0,1.7-0.6,1.7-1.4c0-0.5-0.3-0.9-0.9-1.1c-0.5-0.2-0.6-0.3-0.6-0.6c0-0.3,0.3-0.5,0.7-0.5 c0.3,0,0.6,0.1,0.8,0.2l0.3-0.8c-0.3-0.1-0.6-0.2-1.1-0.2C21,15.2,20.4,15.7,20.4,16.6z" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "0 0 30 30",
    };
  }
};

PointsCircle.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

PointsCircle.defaultProps = { role: "img" };
