import PropTypes from "prop-types";
import React from "react";

import { useDeviceInfo } from "@swa-ui/browser";
import { Heading, Layout, List } from "@swa-ui/core";

import { applyOverrides } from "../applyOverrides";
import { CallToAction } from "../CallToAction";

const DEFAULT_HEADING_LEVEL = 4;

/** LinkGroup renders a series of sections comprised of a configurable Heading, and a List of Links. */

export const LinkGroup = (props) => {
  const {
    placement: { items },
  } = props;
  const { screenSize } = useDeviceInfo();

  return items.map((group, groupIndex) => (
    <div key={`link-group-${groupIndex}`}>
      {group?.heading && (
        <Layout top={groupIndex === 0 ? "none" : "medium"}>
          <Heading {...getHeadingProps(group.heading)}>{group.heading.value}</Heading>
        </Layout>
      )}
      {group?.links && <List items={group.links.map((link) => renderLink(link))} />}
    </div>
  ));

  function renderLink(link) {
    return (
      <Layout top="medium">
        <CallToAction {...getLinkProps(link)}>{link.text}</CallToAction>
      </Layout>
    );
  }

  function getHeadingProps(headingProps) {
    const overrideProps = applyOverrides(headingProps, screenSize);

    return {
      ...overrideProps,
      styleLevel: overrideProps.styleLevel || DEFAULT_HEADING_LEVEL,
    };
  }

  function getLinkProps(linkProps) {
    const overrideProps = applyOverrides(linkProps, screenSize);

    return {
      ...overrideProps,
      paddingForFocus: false,
      styleType: "link",
    };
  }
};

LinkGroup.propTypes = {
  /** Object consisting of an items array, a heading prop object and array of link prop objects, to process and render. */
  placement: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        heading: PropTypes.object,
        links: PropTypes.array,
      })
    ),
  }).isRequired,
};

LinkGroup.defaultProps = {
  placement: {
    items: [],
  },
};
