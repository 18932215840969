import { useContext } from "react";

import { ColorThemeContext } from "../ColorThemeProvider";

/**
 * useColorTheme uses React's useContext hook and Provider to provide methods for the SWA Heart color theme mechanism.
 * See ColorThemeProvider for more information about the methods that are available.
 */

export const useColorTheme = () => {
  const context = useContext(ColorThemeContext);

  if (context === undefined) {
    console.warn("useColorTheme must be used within a ColorThemeProvider!");
  }

  return context === undefined ? {} : context;
};
