import React from "react";

import { Link } from "@swa-ui/core";
import i18n from "@swa-ui/locale";

import styles from "./BaseAuthenticationForm.module.scss";

export const EnrollSection = ({ href }) => (
  <div className={styles.secondary}>
    {i18n("BaseAuthenticationForm__TEXT__NOT_A_MEMBER")}
    <Link href={href} className={styles.enrollLink} emphasis={true}>
      {i18n("BaseAuthenticationForm__LINK__ENROLL_NOW")}
    </Link>
  </div>
);
