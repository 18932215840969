import PropTypes from "prop-types";
import React from "react";

/**
 * Focusable is a wrapper component that allows children to receive focus.
 */
export const Focusable = (props) => {
  const { children, className, focusRef, id } = props;

  return (
    <div id={id} ref={focusRef} tabIndex="-1" className={className}>
      {children}
    </div>
  );
};

Focusable.propTypes = {
  /** Content that will receive focus. */
  children: PropTypes.node,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Ref used to initiate focus on the focusable element. */
  focusRef: PropTypes.object,

  /** ID to be added to the element. */
  id: PropTypes.string,
};
