import PropTypes from "prop-types";
import React from "react";

export const Airport = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <g>
        <path d="M36.5,7.7v1.1L24.1,9c0,0,0-4.9-5.1-4.9c-5,0-5,4.9-5,4.9H1.8l0-1.2l-1.7,0v2.9l5.1,0.8l8.2,1.4l4.9,0.8h1.7 l18.2-3.1V7.7H36.5z M16.4,8.9l0.3-1.5h4.7l0.3,1.5H16.4z" />
        <g>
          <circle cx="6" cy="12.5" r="1.5" />
          <circle cx="32.2" cy="12.5" r="1.5" />
        </g>
        <g>
          <circle cx="14.4" cy="13.5" r="0.9" />
          <circle cx="23.3" cy="13.5" r="0.9" />
        </g>
        <path d="M20.1,14c0,0.1,0,1.3,0,1.4c-0.1,0.4-0.5,0.7-0.9,0.7c-0.4,0-0.7-0.2-0.9-0.6c0-0.1-0.1-1.4-0.1-1.5 c0-0.5,0.4-0.9,0.9-0.9C19.6,13.1,20.1,13.5,20.1,14z" />
        <rect x="18.5" width="1.2" height="5.5" />
      </g>
      <path d="M26.4,17.8h-0.2H20l0.1,1.9h-1.9v-1.9h-6.7L0,39.3h11.5h4.7H22h4.3h12L26.4,17.8z M18,21.6h2.2l0.2,4.1h-2.5 L18,21.6z M17.2,37.6l0.5-9.2h2.8l0.5,9.2H17.2z" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "0 0 38.2 39.3",
    };
  }
};

Airport.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Airport.defaultProps = {
  role: "img",
};
