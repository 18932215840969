import PropTypes from "prop-types";
import React from "react";

import { useDeviceInfo } from "@swa-ui/browser";
import { Heading, Icon, ListMatrix } from "@swa-ui/core";

import { getBackgroundStyle } from "../Banner/BannerUtils";
import { CallToAction } from "../CallToAction";
import { HtmlValue } from "../HtmlValue";
import styles from "./BannerGridLayout1.module.scss";
import { callToActionPropType } from "./utils";

const BannerGridLayout1 = ({ background, callToAction, title, items }) => {
  const { screenSize } = useDeviceInfo();

  return (
    <div style={getBackgroundStyle(background)}>
      <Heading {...getHeadingProps()}></Heading>
      <ListMatrix {...getListMatrixProps()} />
      <div className={styles.ctaContainer}>{renderCallToAction()}</div>
    </div>
  );

  function getHeadingProps() {
    return {
      children: <span {...getStyleProps(title)}>{title.value}</span>,
      className: styles.heading,
      headingLevel: 2,
      styleLevel: 2,
    };
  }

  function getStyleProps(styleProps) {
    const { color, fontSize, fontWeight } = styleProps ?? {};

    return {
      style: {
        color,
        fontSize,
        fontWeight,
      },
    };
  }

  function getListMatrixProps() {
    const columnSizeMap = {
      large: 4,
      medium: 2,
      small: 1,
      xlarge: 4,
    };

    return {
      className: styles.layout1Wrapper,
      items: getItems(),
      layout: "grid",
      numberOfColumns: columnSizeMap[screenSize],
    };
  }

  function getItems() {
    return items.map((item) => {
      const { icon, text } = item;

      return {
        content: (
          <div className={styles.cardItems} key={text}>
            <div className={styles.cardItemHeader}>
              <Icon {...getIconProps(icon)} />
              {renderText(text?.primary, "primaryText")}
            </div>
            {renderText(text?.secondary, "secondaryText")}
          </div>
        ),
      };
    });
  }

  function renderText(text, type) {
    return (
      <div className={styles[type]} style={{ ...getTextProps(text) }}>
        <HtmlValue htmlValue={text?.value} />
      </div>
    );
  }

  function getIconProps(icon) {
    const { color, name, size } = icon;

    return {
      "aria-label": name,
      color,
      name,
      size,
    };
  }

  function getTextProps(text) {
    const { color, fontSize, fontWeight } = text;

    return {
      color,
      fontSize,
      fontWeight,
    };
  }

  function renderCallToAction() {
    return callToAction && <CallToAction {...callToAction} />;
  }
};

BannerGridLayout1.propTypes = {
  callToAction: callToActionPropType,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.shape({
        color: PropTypes.string,
        name: PropTypes.string,
        size: PropTypes.string,
      }),
      text: PropTypes.shape({
        primary: PropTypes.object,
        secondary: PropTypes.object,
      }),
    })
  ).isRequired,
  layoutType: PropTypes.string.isRequired,
};

export default BannerGridLayout1;
