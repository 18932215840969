import PropTypes from "prop-types";
import React from "react";

export const Two = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path
        strokeWidth={0}
        d="M19,0C8.5,0,0,8.5,0,19s8.5,19,19,19s19-8.5,19-19S29.5,0,19,0z M24.3,24.1v2.8H13.7c0.1-1.1,0.5-2.1,1-3
		c0.6-0.9,1.7-2.2,3.4-3.8c1.4-1.3,2.2-2.1,2.5-2.6c0.4-0.6,0.6-1.2,0.6-1.8c0-0.7-0.2-1.2-0.5-1.6c-0.4-0.4-0.9-0.5-1.5-0.5
		c-0.6,0-1.1,0.2-1.5,0.6c-0.4,0.4-0.6,1-0.6,1.9l-3-0.3c0.2-1.7,0.7-2.9,1.7-3.6c0.9-0.7,2.1-1.1,3.5-1.1c1.6,0,2.8,0.4,3.7,1.3
		c0.9,0.8,1.3,1.9,1.3,3.1c0,0.7-0.1,1.4-0.4,2c-0.3,0.6-0.7,1.3-1.2,2c-0.4,0.5-1,1.1-2,2c-1,0.9-1.6,1.5-1.8,1.7
		c-0.3,0.3-0.5,0.6-0.6,0.8H24.3z"
      />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      style,
      viewBox: "0 0 38 38",
    };
  }
};

Two.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Two.defaultProps = {
  role: "img",
};
