import PropTypes from "prop-types";
import React from "react";

import { CallToAction } from "../CallToAction";
import styles from "./ListPlacements.module.scss";

export const ListCallToAction = (props) => {
  const { callToAction, analyticsValue } = props;

  return (
    callToAction && (
      <div className={styles.callToAction}>
        <CallToAction {...callToAction} analyticsValue={analyticsValue} />
      </div>
    )
  );
};

ListCallToAction.propTypes = {
  callToAction: PropTypes.PropTypes.shape({
    /** This object is spread and mapped to any existing prop for Button component. */
    additionalProps: PropTypes.object,

    /** Mapped to aria-label of Button or Link component. */
    ariaLabel: PropTypes.string,

    /**
     * Mapped to styleType of Button component.
     * Standard Buttons will have 'primary' or 'button-primary' semantic values, while
     * buttonType with link values 'bright'/'dark'/'light' should render a Link.
     */
    buttonType: PropTypes.string,

    /** Content that will be rendered on the Button or Link component. */
    children: PropTypes.node,

    /** Additional class to position the component. */
    className: PropTypes.string,

    /** Mapped to external of Link component. */
    externalLink: PropTypes.bool,

    /** Mapped to href of Button or Link component. Prefer using `target` instead. */
    href: PropTypes.string,

    /**
     * Mapped to dark/light of Link component.
     * Standard Links should have 'bright'/'dark'/'light' values, while linkType with button
     * values like 'primary' or 'button-primary' should render a Button.
     */
    linkType: PropTypes.string,

    /** Mapped to newWindow of Button or Link component. */
    newWindow: PropTypes.bool,

    /** Object to style the element. */
    style: PropTypes.object,

    /**
     * Buttons like primary and secondary look like standard buttons. Link styles looks like links,  and 'framed' and
     * 'no-style' are have minimal styling and are the responsibility of the app to style.
     */
    styleType: PropTypes.string,

    /** Mapped to suffixIcon of Link component. */
    suffixIcon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),

    /** Mapped to href of Button or Link component. */
    target: PropTypes.string,

    /** Mapped to children of Button or Link component. */
    text: PropTypes.string,
  }),

  /** The dynamic value that will be used as an event description for analytics. */
  analyticsValue: PropTypes.string,
};
