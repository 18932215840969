import { window } from "../window";

/**
 * getWebViewType is a helper method that assesses whether the SWA native app custom user agent suffix exists,
 * and returns a string token of which type of web view the application is running inside of.
 *
 * @param {String[]} userAgentTokens Array of tokens to assess. Will check the end of the window user agent for each string value.
 * @param {String} passedUserAgent User agent to assess. Uses window.navigator.userAgent if not defined
 * @returns Native app user agent tokens if running inside of iOS or Android native app webview,
 * or undefined if not running in a SWA web view.
 */

export const getWebViewType = (userAgentTokens, passedUserAgent) => {
  const userAgent = passedUserAgent ?? window?.navigator?.userAgent ?? "";

  return userAgentTokens?.filter((userAgentValue) => userAgent.endsWith(userAgentValue))?.[0];
};
