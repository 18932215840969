import PropTypes from "prop-types";
import React from "react";

import { FetchOptions, timeouts } from "@swa-ui/fetch";

/**
 * Set up request headers using application configuration settings.
 */

export const EnterpriseFetchOptions = (props) => {
  const {
    apiKey,
    appId,
    appVersion,
    cacheLife,
    cachePolicy,
    channelId,
    children,
    diagnostic,
    experienceId,
    site,
  } = props;

  return <FetchOptions options={getOptions()}>{children}</FetchOptions>;

  function getOptions() {
    return {
      ...(cacheLife && { cacheLife }),
      ...(cachePolicy && { cachePolicy }),
      headers: {
        "x-api-key": apiKey,
        "x-app-id": appId,
        "x-app-version": appVersion,
        "x-channel-id": channelId,
        "x-diagnostic": diagnostic,
        "x-user-experience-id": experienceId,
        ...(site === "mobile" && { "x-mobile-js": 1 }),
      },
      timeout: timeouts.critical,
    };
  }
};

EnterpriseFetchOptions.propTypes = {
  /**
   * String that uniquely identifies the api service for current environment (dev, qa, etc.). The value is added to
   * request headers.
   */
  apiKey: PropTypes.string.isRequired,

  /**
   * String that identifies the app for when making requests. The value, something like: 'gift-card', is added to
   * request headers.
   */
  appId: PropTypes.string.isRequired,

  /**
   * String that identifies the version of the native app being used when in a webview. The value is added to request
   * headers.
   */
  appVersion: PropTypes.string.isRequired,

  /**
   * String that identifies the app for when making requests. The value, something like: 'southwest' or 'ios', is
   * added to request headers.
   */
  channelId: PropTypes.string.isRequired,

  /** Content to be rendered on the page. */
  children: PropTypes.node.isRequired,

  /** Unique identifier for every customer session. This should be passed down from the application. */
  experienceId: PropTypes.string.isRequired,

  /**
   * String that identifies where the app is located. When the value is 'mobile' a Shape required request header is
   * added.
   */
  site: PropTypes.string.isRequired,
};
