import PropTypes from "prop-types";
import React from "react";

import { Accordion, Icon, Link } from "@swa-ui/core";
import { HtmlValue } from "@swa-ui/placements";
import { classNames } from "@swa-ui/string";

import { useChatbot } from "../../ChatbotProvider/useChatbot";
import { DotcomHeaderMobileContext } from "./DotcomHeaderMobile";
import getMobileHref from "./getMobileHref";
import styles from "./OverlayMenus.module.scss";

/**
 * OverlayMenus generates list of options that are displayed in the DotcomHeaderMobile flyouts.
 */

export const OverlayMenus = (props) => {
  const { className, closeMenu, menus, onChangeRevealedMenu, revealedTitle } = props;
  const { activateChatbot } = useChatbot();
  const { displayPlacement } = React.useContext(DotcomHeaderMobileContext);

  return filterLinks(menus).map(renderMenus);

  function renderMenus(menuInfo, index) {
    const children = menuInfo?.content ?? menuInfo?.children;

    return (
      <ul className={classNames(className, styles.menuList)} key={index}>
        {filterLinks(children).map(renderOptions)}
      </ul>
    );
  }

  function renderOptions(menuInfo) {
    const { content, icon, canExpand, style, title } = menuInfo;
    const children = content ?? menuInfo?.children;

    return (
      <li key={title}>
        {canExpand ? (
          <Accordion {...getAccordionProps(icon, style, title)}>
            {children ? <ul className={styles.contentList}>{children.map(renderLinks)}</ul> : null}
          </Accordion>
        ) : (
          <>
            {renderMenuTitleLink(menuInfo)}
            {children ? <ul className={styles.contentList}>{children.map(renderLinks)}</ul> : null}
          </>
        )}
      </li>
    );
  }

  function renderMenuTitleLink(menuInfo) {
    const { icon, style, title } = menuInfo;

    return (
      <Link {...getMenuTitleLinkProps(menuInfo)}>
        <span className={getLabelContainerClass(style)}>{renderLabel(icon, style, title)}</span>
      </Link>
    );
  }

  function renderLabel(icon, style, title) {
    return (
      <span className={styles.labelContent}>
        {icon ? (
          <span className={styles.icon}>
            <Icon color="secondary-accordion-label" name={icon} size="size26" />
          </span>
        ) : null}
        <span className={getMenuTitleClass(style)}>{renderTitle(title)}</span>
      </span>
    );
  }

  function renderLinks(linkInfo) {
    const { background, color, icon, shrink, style, title } = linkInfo;

    return (
      <li key={title}>
        <Link {...getLinkProps(linkInfo)}>
          {icon ? (
            <Icon {...getLinkIconProps(background, color, icon, shrink, "size36", style)} />
          ) : null}
          <span className={styles.linkTitle}>{renderTitle(title)}</span>
        </Link>
      </li>
    );
  }

  function renderTitle(title) {
    return <HtmlValue htmlValue={title} />;
  }

  function filterLinks(items) {
    return items.filter((item) => item.hideForGuest !== true);
  }

  function getLabelContainerClass(style) {
    return classNames({
      [styles.labelContainerBold]: style === "bold",
      [styles.labelContainerSemiBold]: style === "semi-bold",
      [styles.labelContainerNormal]: style === "normal",
    });
  }

  function getMenuLinkClass(style) {
    return classNames({
      [styles.menuTitleLink]: style === "bold" || style === "semi-bold",
      [styles.menuTitleLinkNormal]: style === "normal",
    });
  }

  function getMenuTitleClass(style) {
    return classNames({
      [styles.menuTitleBold]: style === "bold",
      [styles.menuTitleSemiBold]: style === "semi-bold",
      [styles.menuTitleNormal]: style === "normal",
    });
  }

  function getMenuTitleLinkProps(menuInfo) {
    const { external, externalLink, linkType, style, target } = menuInfo;

    return {
      className: getMenuLinkClass(style),
      emphasis: false,
      external: (external ?? externalLink) || menuInfo.icon === "BROWSER_LINK",
      href: linkType !== "placement" ? getMobileHref(menuInfo) : undefined,
      newWindow: linkType === "browser",
      onClick: linkType === "placement" ? () => displayPlacement(target) : undefined,
    };
  }

  function getLinkIconProps(background, color, icon, shrink, size, style) {
    return {
      background,
      className: styles.smallIcon,
      color,
      name: icon,
      shrink,
      size,
      style,
    };
  }

  function getLinkProps(linkInfo) {
    const { external, externalLink, linkType, target } = linkInfo;
    const isChatLink = target === "livechat";

    return {
      className: styles.menuOption,
      emphasis: false,
      external: (external ?? externalLink) || linkInfo.icon === "BROWSER_LINK",
      href: isChatLink ? undefined : getMobileHref(linkInfo),
      onClick: isChatLink ? handleChatLinkClick : undefined,
      newWindow: linkType === "browser",
      size: "fontSize16",
    };
  }

  function handleChatLinkClick() {
    closeMenu();
    activateChatbot();
  }

  function getAccordionProps(icon, style, title) {
    return {
      fullWidthContent: false,
      indicatorClassName: styles.accordionIndicator,
      label: renderLabel(icon, style, title),
      name: `${title.replace(/ /g, "_")}`,
      onChange: onChangeRevealedMenu?.bind(this, title),
      revealed: title === revealedTitle,
    };
  }
};

OverlayMenus.propTypes = {
  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * Function to allow OverlayMenus to forceably "close" its parent. This is used to close DotcomHeaderMobile when
   * the chatbot menu item is selected.
   */
  closeMenu: PropTypes.func,

  menus: PropTypes.arrayOf(
    PropTypes.shape({
      childList: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          routeName: PropTypes.string,
          dataQa: PropTypes.string,
          query: PropTypes.object,
        })
      ),
      className: PropTypes.string,
      dataQa: PropTypes.string,
      iconType: PropTypes.string,
      isAccordion: PropTypes.bool,
      menuTitle: PropTypes.string,
      routeName: PropTypes.string,
    })
  ),

  /** Informs caller when the selected accordion menu changes. */
  onChangeRevealedMenu: PropTypes.func,

  /** Title of the revealed menu accordion. Only one accordion can be expanded, if any. */
  revealedTitle: PropTypes.string,
};

OverlayMenus.defaultProps = {
  revealedTitle: "",
};
