import PropTypes from "prop-types";
import React from "react";

import { Heading, Link } from "@swa-ui/core";
import { HtmlValue } from "@swa-ui/placements";
import { classNames } from "@swa-ui/string";

import styles from "./ColumnList.module.scss";

/**
 * ColumnList generates list of options that are displayed in the DotcomFooter.
 */

export const ColumnList = (props) => {
  const { linkColumns, className } = props;

  return linkColumns ? (
    <div className={classNames(styles.container, className)}>{linkColumns.map(renderColumn)}</div>
  ) : null;

  function renderColumn(columnInfo) {
    const { linkColumnHeader, linkColumnText } = columnInfo;
    const { value } = linkColumnHeader;

    return (
      <div key={value}>
        <Heading {...getHeadingProps()}>{renderHeadingContent(linkColumnHeader)}</Heading>
        <ul className={styles.list}>{linkColumnText.map(renderLinks)}</ul>
      </div>
    );
  }

  function renderHeadingContent(linkColumnHeader) {
    const { value } = linkColumnHeader;

    return (
      <Link {...getHeaderLinkProps(linkColumnHeader)}>
        <HtmlValue htmlValue={value} />
      </Link>
    );
  }

  function renderLinks(linkInfo) {
    const { value } = linkInfo;

    return (
      <li key={value}>
        <Link {...getLinkProps(linkInfo)}>
          <HtmlValue htmlValue={value} />
        </Link>
      </li>
    );
  }

  function getLinkProps(linkInfo) {
    const { external, externalLink, href, newWindow, url } = linkInfo;
    const isExternal = external ?? externalLink;

    return {
      emphasis: false,
      external: isExternal,
      href: href ?? url,
      newWindow: newWindow ?? isExternal,
      size: "fontSize14",
    };
  }

  function getHeadingProps() {
    return {
      className: styles.heading,
      headingLevel: 5,
      spacing: "xSmall",
    };
  }

  function getHeaderLinkProps(linkColumnHeader) {
    const { external, externalLink, href, url } = linkColumnHeader;
    const isNonLink = !(href || url);

    return {
      className: classNames({ [styles.nonLink]: isNonLink }),
      emphasis: true,
      external: external ?? externalLink,
      href: href ?? url,
      size: "fontSize16",
    };
  }
};

ColumnList.propTypes = {
  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Content that defines all options displayed in the GlobalNav flyout. */
  content: PropTypes.object,
};
