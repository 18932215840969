import PropTypes from "prop-types";
import React from "react";

import { Heading, Link } from "@swa-ui/core";
import { classNames } from "@swa-ui/string";
import { useColorTheme } from "@swa-ui/theme";

import styles from "./LinkColumns.module.scss";

/**
 * OverlayList generates list of options that are displayed in the GlobalHeader flyouts.
 */

const LinkColumns = (props) => {
  const { className, linkColumnInfo } = props;
  const { getRgbValue } = useColorTheme();

  return <div className={styles.mainLinksSection}>{linkColumnInfo.map(renderColumn)}</div>;

  function renderColumn(columnInfo) {
    const { linkColumnHeader, linkColumnText } = columnInfo;
    const { value } = linkColumnHeader;

    return (
      <div className={classNames(className, styles.column)} key={value}>
        <Heading {...getHeadingProps()}>
          <Link {...getHeaderLinkProps(linkColumnHeader)}>{value}</Link>
        </Heading>
        <ul className={styles.list}>{linkColumnText.map(renderLinks)}</ul>
      </div>
    );
  }

  function renderLinks(linkInfo) {
    const { value } = linkInfo;

    return (
      <li key={value}>
        <Link {...getLinkProps(linkInfo)}>{value}</Link>
      </li>
    );
  }

  function getLinkProps(linkInfo) {
    const { external, externalLink, href, newWindow, url } = linkInfo;
    const isExternal = external ?? externalLink;

    return {
      emphasis: false,
      external: isExternal,
      href: href ?? url,
      newWindow: newWindow ?? isExternal,
      size: "fontSize16",
    };
  }

  function getHeadingProps() {
    return {
      className: styles.heading,
      headingLevel: 3,
      styleLevel: 3,
    };
  }

  function getHeaderLinkProps(linkColumnHeader) {
    const { externalLink, prefixIcon, href, suffixIcon, url } = linkColumnHeader;
    const external = linkColumnHeader.external ?? externalLink;

    const linkProps = {
      emphasis: true,
      external,
      href: href ?? url,
      newWindow: external,
      size: "fontSize28",
      suffixIcon: external ? { name: "External", size: "fontSize16" } : suffixIcon,
    };

    if (prefixIcon) {
      linkProps.prefixIcon = {
        color: getRgbValue("primary-header-heading-icon"),
        name: prefixIcon,
      };
    }

    return linkProps;
  }
};

LinkColumns.propTypes = {
  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  // TODO flush this out when WCM content mature
  // columnListInfo: PropTypes.shape({
  // }),

  /** This is the information for each column's heading. */
  header: PropTypes.shape({
    externalLink: PropTypes.bool,
    prefixIcon: PropTypes.string.isRequired,
    // prefixIcon: PropTypes.oneOf(Object.keys(iconList)).isRequired, // TODO replace with this when wcm feed good
    value: PropTypes.string,
    url: PropTypes.string,
  }),

  /** This is the information for each column's list of links. */
  links: PropTypes.arrayOf(
    PropTypes.shape({
      externalLink: PropTypes.bool,
      value: PropTypes.string,
      url: PropTypes.string,
    })
  ),
};

export { LinkColumns };
