import PropTypes from "prop-types";
import React from "react";

import { TextFormField } from "@swa-ui/form";
import i18n from "@swa-ui/locale";

import { REDRESS_NUMBER_SCHEMA_FIELD_NAME } from "./RedressNumberFormField.schema";

/**
 * RedressNumberFormField facilitates populating or updating the redress number.
 */

export const RedressNumberFormField = (props) => {
  const { componentProps, description, label, name } = props;

  return <TextFormField {...getTextFormFieldProps()} />;

  function getTextFormFieldProps() {
    return {
      componentProps: {
        "aria-label": i18n("RedressNumberFormField__ARIA"),
        ...componentProps,
      },
      description: description ?? i18n("RedressNumberFormField__OPTIONAL"),
      label: label ?? i18n("RedressNumberFormField__LABEL"),
      name: name ?? REDRESS_NUMBER_SCHEMA_FIELD_NAME.REDRESS_NUMBER,
    };
  }
};

RedressNumberFormField.propTypes = {
  /** Component props for input component. */
  componentProps: PropTypes.shape({
    /** Content to be read by the screen reader. */
    "aria-label": PropTypes.string,

    /** Class name for positioning the component. */
    className: PropTypes.string,

    /** Default field value. */
    defaultValue: PropTypes.string,

    /** Callback handler for onBlur event. */
    onBlur: PropTypes.func,

    /** Callback handler for onChange event. */
    onChange: PropTypes.func,

    /** Option props for text input. */
    option: PropTypes.object,
  }),

  /** Additional description for this field. */
  description: PropTypes.string,

  /** Label to identify the form field. */
  label: PropTypes.node,

  /** Form field name used in schema validation and service call payload. */
  name: PropTypes.string,
};
