import PropTypes from "prop-types";
import React from "react";

export const TwoWayRadio = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path d="M18.62,8.52V4.77a.77.77,0,1,1,1.53,0V8.52Z" />
      <path d="M19.53,17.13h-7V11.88h7.05Zm-.6,4H13a.33.33,0,0,1-.34-.31.33.33,0,0,1,.34-.32h5.9c.18,0,.38.14.38.31a.37.37,0,0,1-.38.32m0,1.7H13a.33.33,0,0,1-.34-.31.34.34,0,0,1,.34-.33h5.9a.32.32,0,1,1,0,.64m0,1.69H13a.33.33,0,0,1-.34-.31.34.34,0,0,1,.34-.32h5.9a.37.37,0,0,1,.38.32c0,.17-.2.31-.38.31M20.18,9.77H16.85V9.13a.38.38,0,0,0-.41-.41h-.82a.38.38,0,0,0-.41.41v.64H14V9.13a.37.37,0,0,0-.41-.41h-.82a.38.38,0,0,0-.41.41v.64h-.56a1.51,1.51,0,0,0-1.42,1.42V26.58A1.52,1.52,0,0,0,11.82,28h8.36a1.52,1.52,0,0,0,1.42-1.42V11.19a1.51,1.51,0,0,0-1.42-1.42" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "4 4 24 24",
    };
  }
};

TwoWayRadio.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

TwoWayRadio.defaultProps = {
  role: "img",
};
