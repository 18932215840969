import { useContext, useEffect } from "react";

import { ContentLoadingContext } from "../ContentLoadingProvider";

export const useContentLoading = (loadingComponent) => {
  const contentLoadingContextValues = useContext(ContentLoadingContext);
  const { setLoadingComponent } = contentLoadingContextValues;

  useEffect(() => {
    setLoadingComponent(loadingComponent);
  }, [loadingComponent]);

  return contentLoadingContextValues;
};
