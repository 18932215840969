export const isValidLuhnNumber = (numberToValidate) => {
  if (!numberToValidate) {
    return false;
  } else if (typeof numberToValidate !== "string") {
    throw new TypeError("Expected string input");
  }

  numberToValidate = numberToValidate.replace(/ /g, "");

  const lookUpTable = [0, 2, 4, 6, 8, 1, 3, 5, 7, 9];

  let lengthOfNumber = numberToValidate.length;
  let bit = false;
  let sum = 0;

  while (lengthOfNumber) {
    lengthOfNumber = lengthOfNumber - 1;
    const digit = parseInt(numberToValidate.charAt(lengthOfNumber), 10);

    sum = sum + (bit ? lookUpTable[digit] : digit);
    bit = !bit;
  }

  return sum % 10 === 0;
};
