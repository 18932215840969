import PropTypes from "prop-types";
import React from "react";

export const SameDayStandby = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path d="m0,14.16c.97,0,1.94-.03,2.91,0,.49.01.63-.17.62-.64-.05-1.99-.1-3.97-.11-5.96-.02-3.78,2.49-6.84,6.22-7.45,4.79-.79,8.63,2.88,8.7,7.15,0,.5-.01,1,0,1.5.01.37-.14.54-.52.53-.71-.01-1.42-.01-2.13,0-.39,0-.51-.17-.51-.54-.01-.71.02-1.43-.1-2.12-.39-2.19-2.37-3.65-4.61-3.46-2.12.18-3.87,2.02-3.88,4.18,0,2.11.07,4.22.09,6.33,0,.37.18.48.51.47.78-.01,1.56,0,2.34,0,.18,0,.37.02.72.04-.45.52-.81.95-1.18,1.36-1.18,1.31-2.37,2.61-3.54,3.94-.31.36-.53.4-.86.02-1.33-1.51-2.67-3-4.02-4.49C.45,14.79.22,14.59,0,14.37c0-.07,0-.14,0-.21Z" />
      <path d="m12.13,17.03c.43-.5.75-.88,1.08-1.25,1.23-1.36,2.46-2.72,3.69-4.09.22-.24.41-.43.71-.09,1.54,1.73,3.1,3.45,4.65,5.18.03.04.04.09.09.22-.2.03-.37.06-.55.07-.75,0-1.49,0-2.24,0-.68,0-.69.01-.68.71.03,1.95.09,3.91.1,5.86.02,3.83-2.52,6.9-6.31,7.49-4.75.73-8.5-2.89-8.61-7.1-.01-.52,0-1.04,0-1.56-.01-.37.12-.55.51-.54.71.01,1.42.01,2.13,0,.38,0,.52.16.52.53,0,.71-.02,1.43.1,2.12.39,2.2,2.36,3.66,4.61,3.47,2.12-.18,3.87-2.02,3.88-4.18.01-2.11-.07-4.22-.09-6.33,0-.37-.18-.47-.52-.47-.78.01-1.56,0-2.34,0-.18,0-.37-.02-.72-.04Z" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "0 0 25 32",
    };
  }
};

SameDayStandby.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

SameDayStandby.defaultProps = { role: "img" };
