import PropTypes from "prop-types";
import { useContext } from "react";

import { RecentSearchContext } from "../RecentSearchProvider";

/**
 * useRecentSearches provides the functionality to filter recent searches by types and limit the
 * number of recent searches returned.
 */
export const useRecentSearches = (options) => {
  const { filterByTypes, maxResults } = options ?? {};
  let { recentSearches } = useContext(RecentSearchContext);

  if (filterByTypes) {
    recentSearches = recentSearches.filter((recentSearch) =>
      filterByTypes.includes(recentSearch.type)
    );
  }

  if (maxResults) {
    recentSearches = recentSearches.slice(0, maxResults);
  }

  return {
    recentSearches,
  };
};

useRecentSearches.propTypes = {
  /** Array of types to filter the recent searches. */
  filterByTypes: PropTypes.arrayOf(PropTypes.string),

  /** Maximum number of results to return. */
  maxResults: PropTypes.number,
};
