function fireTrack(identifier, addtionalData) {
  let browserWindow;

  if (typeof window !== "undefined" && window._satellite) {
    browserWindow = window;

    if (addtionalData) {
      browserWindow._satellite.track(identifier, addtionalData);
    } else {
      browserWindow._satellite.track(identifier);
    }
  }
}
function mktgAdobeSatelliteTracking(browserWindow, satelliteTrack, satelliteTrackData) {
  if (satelliteTrack && browserWindow._satellite) {
    browserWindow._satellite.track(satelliteTrack, satelliteTrackData);
  }
}
function dispatchMktgDataEvent(detail) {
  window.document?.dispatchEvent(new CustomEvent("mktgDataUpdate", { detail }));
}

function sendToDataLayer(data, satelliteTrack, satelliteTrackData) {
  let browserWindow;

  if (typeof window !== "undefined") {
    browserWindow = window;

    browserWindow["mktg_data"] = data;
    dispatchMktgDataEvent("sendToDataLayer");

    mktgAdobeSatelliteTracking(browserWindow, satelliteTrack, satelliteTrackData);
  }
}

function updateDataLayer(data, satelliteTrack, satelliteTrackData) {
  let browserWindow;

  if (typeof window !== "undefined") {
    browserWindow = window;

    browserWindow["mktg_data"] = { ...browserWindow["mktg_data"], ...data };
    dispatchMktgDataEvent("updateDataLayer");

    mktgAdobeSatelliteTracking(browserWindow, satelliteTrack, satelliteTrackData);
  }
}

function sendToAdobeDataLayer(data, satelliteTrack, satelliteTrackData) {
  let browserWindow;

  if (typeof window !== "undefined") {
    browserWindow = window;

    browserWindow["adobeDataLayer"] = browserWindow.adobeDataLayer || [];
    browserWindow["adobeDataLayer"].push(data);

    dispatchMktgDataEvent("sendToAdobeDataLayer");

    mktgAdobeSatelliteTracking(browserWindow, satelliteTrack, satelliteTrackData);
  }
}

module.exports = {
  fireTrack,
  sendToAdobeDataLayer,
  sendToDataLayer,
  updateDataLayer,
};
