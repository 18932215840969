import PropTypes from "prop-types";
import React from "react";

export const HearingAid = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path d="M23.18,11.24a8.8,8.8,0,0,1,0,12.43l1.6,1.58a11.06,11.06,0,0,0,0-15.6Z" />
      <path d="M20,14.38a4.36,4.36,0,0,1,1.28,3.08A4.31,4.31,0,0,1,20,20.51l1.22,1.2a6,6,0,0,0,0-8.51Z" />
      <path d="M13.67,24.73c-.55-.15-.94-1-1.35-1.93A8.24,8.24,0,0,0,10.59,20c-1.94-1.81-4.52-4.54-4.52-7.29a5.51,5.51,0,1,1,10.15,3l-.18,0a1.72,1.72,0,1,0,1.73,1.72,2.48,2.48,0,0,0,0-.27,7.51,7.51,0,0,0,1.42-4.41A7.58,7.58,0,0,0,4,12.74c0,3.56,3,6.73,5.17,8.8a6.5,6.5,0,0,1,1.26,2.11c.56,1.25,1.2,2.67,2.69,3.08a3.2,3.2,0,0,0,.83.11c1.17,0,2.56-.6,4.36-1.86l-1.18-1.7C15,24.78,14,24.83,13.67,24.73" />
      <path d="M23.18,11.21a8.79,8.79,0,0,1,0,12.42l1.6,1.59a11.06,11.06,0,0,0,0-15.6Z" />
      <path d="M20,14.35a4.31,4.31,0,0,1,0,6.13l1.22,1.2a6,6,0,0,0,0-8.52Z" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "4 4 24 24",
    };
  }
};

HearingAid.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

HearingAid.defaultProps = {
  role: "img",
};
