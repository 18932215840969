import PropTypes from "prop-types";
import React, { useRef, useState } from "react";

import { useResizeObserver } from "@swa-ui/browser";
import { classNames } from "@swa-ui/string";

import styles from "./Content.module.scss";

const EMPHASIS_THRESHOLD_MULTIPLIER = {
  large: 3,
  medium: 2,
  small: 1,
  xlarge: 4,
  xsmall: 0,
};
const FONT_RANGES = {
  large: [24, 36],
  medium: [16, 28],
  small: [10, 18],
  xlarge: [32, 48],
};
const LINE_HEIGHT_MULTIPLIER = {
  large: 1.75,
  medium: 1.5,
  small: 1.2,
  xlarge: 2,
};

/**
 * Content renders a div with children that can be scaled to accommodate its container's width using
 * scaling logic, with infinite font size fidelity (fontSizing === "highFidelity"), or by using
 * container queries to accomplish "t-shirt" sizing (fontSizing === "snapToGrid"). fontSizing can be
 * turned off if fontSizing is "none".
 *
 * When fontScaling is set to "highFidelity", Content attempts to break each line in the same place
 * even when the content is in different size containers. This doesn't work perfectly, but is
 * generally close. Keeping the container size small will allow this feature to work best.
 */
export const Content = (props) => {
  const {
    children,
    className,
    emphasisThreshold,
    fontSizing,
    isContainer,
    lineClamp,
    lineHeightSize,
    maxContainerWidth,
    minContainerWidth,
    size,
  } = props;
  const [containerWidth, setContainerWidth] = useState(0);
  const ref = useRef(null);
  const isHighFidelity = fontSizing === "highFidelity";

  useResizeObserver({ callback: handleWidthUpdate, element: ref });

  return (
    <div {...getProps()}>
      <div className={getCopyClass()}>{children}</div>
    </div>
  );

  function getProps() {
    const containerSizeRange = maxContainerWidth - minContainerWidth;
    const fontMax = FONT_RANGES[size][1];
    const fontMin = FONT_RANGES[size][0];
    const fontSizeRange = fontMax - fontMin;
    const fontSize =
      (Math.max((containerWidth - minContainerWidth) / containerSizeRange), 0) * fontSizeRange +
      fontMin;
    const thresholdCount = Object.keys(EMPHASIS_THRESHOLD_MULTIPLIER).length;
    const threshold =
      ((fontMax - fontMin) / thresholdCount) * EMPHASIS_THRESHOLD_MULTIPLIER[emphasisThreshold] +
      fontMin;
    const fontWeight =
      isHighFidelity && emphasisThreshold && fontSize > threshold ? "bold" : "normal";

    return {
      className: classNames(
        { [styles.content]: isContainer && fontSizing === "snapToGrid" },
        className
      ),
      ref,
      style: {
        fontWeight,
        maxWidth: maxContainerWidth,
        minWidth: minContainerWidth,
        ...(fontSizing === "highFidelity" && {
          fontSize: `${fontSize}px`,
          lineHeight: getLineHeight(fontSize),
        }),
        ...(lineClamp > 1 && { "--line-clamp": lineClamp }),
        ...(lineHeightSize === "large" && {
          "--large-line-height-multiplier": LINE_HEIGHT_MULTIPLIER.large,
        }),
        ...(lineHeightSize === "medium" && {
          "--medium-line-height-multiplier": LINE_HEIGHT_MULTIPLIER.medium,
        }),
        ...(lineHeightSize === "small" && {
          "--small-line-height-multiplier": LINE_HEIGHT_MULTIPLIER.small,
        }),
        ...(lineHeightSize === "xlarge" && {
          "--xlarge-line-height-multiplier": LINE_HEIGHT_MULTIPLIER.xlarge,
        }),
      },
    };
  }

  function getCopyClass() {
    return classNames({
      [styles.copy]: fontSizing === "snapToGrid",
      [styles.emphasisLarge]: emphasisThreshold === "large",
      [styles.emphasisMedium]: emphasisThreshold === "medium",
      [styles.emphasisSmall]: emphasisThreshold === "small",
      [styles.emphasisXlarge]: emphasisThreshold === "xlarge",
      [styles.emphasisXsmall]: emphasisThreshold === "xsmall",
      [styles.large]: !isHighFidelity && size === "large",
      [styles.lineClamp]: lineClamp > 1,
      [styles.medium]: !isHighFidelity && size === "medium",
      [styles.small]: !isHighFidelity && size === "small",
      [styles.xlarge]: !isHighFidelity && size === "xlarge",
      [styles.largeLineHeight]: !isHighFidelity && lineHeightSize === "large",
      [styles.mediumLineHeight]: !isHighFidelity && lineHeightSize === "medium",
      [styles.smallLineHeight]: !isHighFidelity && lineHeightSize === "small",
      [styles.xlargeLineHeight]: !isHighFidelity && lineHeightSize === "xlarge",
    });
  }

  function getLineHeight(highFidelityFontSize) {
    return `${highFidelityFontSize * LINE_HEIGHT_MULTIPLIER[lineHeightSize || "medium"]}px`;
  }

  function handleWidthUpdate(value) {
    setContainerWidth(value[0].contentRect.width);
  }
};

export const contentPropTypes = {
  /** Content that will be rendered. */
  children: PropTypes.node.isRequired,

  /** Class name that can be added to the outermost container for layout purposes. */
  className: PropTypes.string,

  /**
   * emphasisThreshold can be given to indicate when font size exceeds given value, font will
   * become bolded.
   */
  emphasisThreshold: PropTypes.oneOf(["large", "medium", "small", "xlarge", "xsmall"]),

  /**
   * Indicates which font scaling algorithm should be applied. If "snapToGrid" is set, fonts will be
   * sized according to break points and will only vary in four increments. On the other hand, if
   * "highFidelity" is set, then font sizes will be granular and will not cause content to wrap
   * when scaled down. With either approach, line-height will always be scaled along with the font
   * size. If fontSizing is set to "none", no scaling will occur.
   */
  fontSizing: PropTypes.oneOf(["none", "snapToGrid", "highFidelity"]),

  /** Indicates if the Content component should be treated as a container for containment context
   *  in container queries. If set to false, the container queries would look for next applicable
   *  containment context up in the DOM tree. This is useful when a Content component is a child
   *  of a parent component which has container-type set. */
  isContainer: PropTypes.bool,

  /** Content can be clamped to given number of lines. */
  lineClamp: PropTypes.number,

  /** Optional value to set the line height. If the consumer does not provide this value, line height
   * will scale as per a default multipler. */
  lineHeightSize: PropTypes.oneOf(["large", "medium", "small", "xlarge"]),

  /** Optional value to define the containers maximum width. */
  maxContainerWidth: PropTypes.number,

  /** Optional value to define the containers minimum width. */
  minContainerWidth: PropTypes.number,

  /**
   * General size of font. Font sizes vary depending on size of Content container, but giving size
   * defines a range of available font sizes that will be used.
   */
  size: PropTypes.oneOf(["large", "medium", "small", "xlarge"]),
};

Content.propTypes = contentPropTypes;

Content.defaultProps = {
  fontSizing: "none",
  isContainer: true,
  size: "small",
};
