import PropTypes from "prop-types";
import React from "react";

import { classNames } from "@swa-ui/string";

import styles from "./CardGrid.module.scss";

/**
 * CardGrid will automatically provide a right-sized container for the current device's viewport with the correct width
 * and column definitions. This component differs from the standard grid as it is intended to sit "inside" a Card,
 * allowing for the card's margins.
 */

export const CardGrid = (props) => {
  const { children, className, role, padding, size, style } = props;

  return (
    <div className={getClass()} role={role} style={style}>
      {children}
    </div>
  );

  function getClass() {
    return classNames({
      [className]: className,
      [styles.auto]: size === "auto",
      [styles.grid]: true,
      [styles.large]: size === "lg",
      [styles.medium]: size === "md",
      [styles.padding]: padding,
      [styles.small]: size === "sm",
      [styles.xlarge]: size === "xlg",
    });
  }
};

CardGrid.propTypes = {
  /** The content that will be displayed when Grid is rendered. */
  children: PropTypes.node,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Padding will be applied around the "outside" of the grid when this prop is set to true. */
  padding: PropTypes.bool,

  /** Name used for aria role. */
  role: PropTypes.string,

  /**
   * Provides a container of the appropriate size.
   *
   * When size of `auto` is used, media queries will determine the correct size. There are four possible grid
   * configurations:
   *
   * <ul>
   *   <li>`sm` (small): 4 columns of variable width, 16 pixels between columns, and 8 margins on left and right
   *   </li>
   *   <li>`md` (medium): 12 columns of variable width, 16 pixels between columns, and 16 margins on left and right
   *   </li>
   *   <li>`lg` (large): 12 columns of variable width, 16 pixels between columns, and 16 margins on left and right
   *   </li>
   *   <li>`xlg` (extra large): 12 columns of variable width, 16 pixels between columns, and 16 margins on left and
   *   right</li>
   * </ul>
   *
   * Additionally, the caller may request a specific container size.
   */
  size: PropTypes.oneOf(["auto", "sm", "md", "lg", "xlg"]),

  /** Additional CSS styling to be applied to outermost Grid container. */
  style: PropTypes.object,
};

CardGrid.defaultProps = {
  size: "auto",
};
