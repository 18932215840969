import PropTypes from "prop-types";
import React from "react";

export const Global = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path
        d="M89.412,1.852c-0.129-0.104-0.24-0.191-0.277-0.229l-0.141,0.158c-4.539-0.863-9.219-1.318-14.009-1.318
	s-9.47,0.455-14.019,1.318l-0.139-0.158c-0.039,0.037-0.148,0.125-0.27,0.229C26.049,8.588,0,38.963,0,75.441
	c0,41.402,33.568,74.973,74.985,74.973c41.404,0,74.974-33.57,74.974-74.973C149.959,38.963,123.918,8.588,89.412,1.852z
	 M134.205,50.625h-20.182c-2.75-14.66-7.678-25.734-12.441-33.617C116.232,23.707,127.957,35.744,134.205,50.625z M139.209,75.441
	c0,4.613-0.502,9.105-1.439,13.451h-22.205c0.426-4.326,0.659-8.914,0.659-13.756c0-4.852-0.233-9.418-0.659-13.752h22.068
	C138.652,65.914,139.209,70.609,139.209,75.441z M80.359,139.42V99.648h22.662c-4.873,23.137-15.605,35.639-19.355,39.41
	C82.57,139.207,81.467,139.324,80.359,139.42z M66.306,139.059c-3.76-3.771-14.483-16.273-19.358-39.41h22.653v39.771
	C68.486,139.324,67.4,139.207,66.306,139.059z M10.76,75.441c0-4.832,0.551-9.527,1.57-14.057h22.067
	c-0.419,4.334-0.661,8.9-0.661,13.752c0,4.842,0.242,9.43,0.661,13.756H12.191C11.264,84.547,10.76,80.055,10.76,75.441z
	 M44.488,75.137c0-4.889,0.288-9.451,0.753-13.752h24.359v27.508H45.241C44.776,84.592,44.488,80.033,44.488,75.137z M69.601,11.445
	v39.18H46.947c4.605-21.9,14.475-34.268,18.699-38.734C66.946,11.703,68.271,11.57,69.601,11.445z M84.325,11.891
	c4.224,4.467,14.083,16.834,18.696,38.734H80.359v-39.18C81.698,11.57,83.016,11.703,84.325,11.891z M80.359,88.893V61.385h24.361
	c0.474,4.301,0.752,8.863,0.752,13.752c0,4.896-0.278,9.455-0.752,13.756H80.359z M48.377,17.008
	c-4.76,7.883-9.693,18.957-12.429,33.617H15.746C22.012,35.744,33.729,23.707,48.377,17.008z M15.514,99.648h20.435
	c2.841,15.201,8.029,26.525,12.93,34.449C33.793,127.35,21.771,114.982,15.514,99.648z M101.07,134.098
	c4.914-7.924,10.111-19.248,12.953-34.449h20.424C128.182,114.982,116.148,127.35,101.07,134.098z"
      />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      style,
      viewBox: "0 0 150 150",
    };
  }
};

Global.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Global.defaultProps = {
  role: "img",
};
