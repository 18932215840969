import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import { useResizeObserver, window } from "@swa-ui/browser";
import { classNames } from "@swa-ui/string";

import { AriaLive } from "../AriaLive";
import { Button } from "../Button";
import { FadingDot } from "../FadingDot";
import { Icon } from "../Icon";
import { TransitionBlock } from "../TransitionBlock";
import styles from "./Banner.module.scss";

const BANNER_BUTTON_CONTENT_DESIGN_TOKEN = "cmp-core-color-banner-button-content";
const BANNER_FEEDBACK_DESIGN_TOKEN = "cmp-core-color-banner-feedback";
const BUTTON_WIDTHS = 80;
const EVENT_AGGREGATE_DURATION = 333;
const NEXT = 1;
const OFF = 0;
const PREVIOUS = -1;
const TRANSITION_DISTANCE_LEFT = -200;
const TRANSITION_DISTANCE_RIGHT = 200;
const TRANSITION_DURATION_DOT = 300;
const TRANSITION_DURATION_OUT = 100;
const TRANSITION_DURATION_SETUP = 0.001;
const TRANSITION_COMPLETE = {
  clearAll: true,
  transformations: [],
};
const TRANSITION_IN = {
  transformations: ["fadeReset", "collapseReset", "translateXReset"],
};
const TRANSITION_OUT = {
  duration: TRANSITION_DURATION_OUT,
  transformations: ["fade", "collapse", "translateXReset"],
};
const TRANSITION_SETUP_LEFT = {
  duration: TRANSITION_DURATION_SETUP,
  transformations: [
    "fade",
    "collapse",
    { action: "translateX", amount: `${TRANSITION_DISTANCE_LEFT}px` },
  ],
};
const TRANSITION_SETUP_RIGHT = {
  duration: TRANSITION_DURATION_SETUP,
  transformations: [
    "fade",
    "collapse",
    { action: "translateX", amount: `${TRANSITION_DISTANCE_RIGHT}px` },
  ],
};

/**
 * Banner provides a simple content scroller using previous/next buttons to allow the traveler to
 * sequentially view a list of items by clicking on navigation buttons.
 */

export const Banner = React.memo((props) => {
  const { className, items, onClick, onNewContentDisplayed, width } = props;
  const [containerWidth, setContainerWidth] = useState(0);
  const [itemIndex, setItemIndex] = useState(0);
  const [itemWidths, setItemWidths] = useState([]);
  const [on, setOn] = useState(OFF);
  const containerRef = useRef();
  const itemRefs = useRef([]);
  let timer;

  useResizeObserver({ callback: updatePageInfo, element: containerRef });

  useEffect(() => {
    if (itemRefs?.current?.length && !itemWidths?.length) {
      getItemWidths();
    }
  }, [containerWidth, itemRefs.current.length]);

  useEffect(() => {
    setContainerWidth(containerRef?.current?.getBoundingClientRect().width);
  }, [containerRef.current]);

  return (
    <div className={classNames(className, styles.banner)} ref={containerRef}>
      <div className={getContainerClass()}>
        {items?.length > 1 && renderButton(PREVIOUS)}
        {itemRefs?.current?.length ? renderContent() : renderAllContent()}
        {items?.length > 1 && renderButton(NEXT)}
        <AriaLive>{props["aria-label"]}</AriaLive>
      </div>
    </div>
  );

  function renderContent() {
    return (
      <div {...getContentProps()}>
        <TransitionBlock {...getTransitionBlockProps()}>{items[itemIndex]}</TransitionBlock>
      </div>
    );
  }

  function renderAllContent() {
    return items?.length ? items.map(renderEachContentItem) : null;
  }

  function renderEachContentItem(item, index) {
    return (
      <div key={index} ref={(element) => (itemRefs.current[index] = element)}>
        {item}
      </div>
    );
  }

  function renderButton(direction) {
    return (
      <Button {...getButtonProps(direction)}>
        {on === direction && <FadingDot {...getDotProps()} />}
        <Icon {...getIconProps(direction)} />
      </Button>
    );
  }

  function getContentProps() {
    return {
      className: styles.contentContainer,
      ...(width === "dynamic" && { style: { width: `${getWidestItem()}px` } }),
    };
  }

  function getTransitionBlockProps() {
    return {
      animationToken: itemIndex,
      onTransformationEnd: onNewContentDisplayed,
      transformOrigin: "right-center",
      transitionComplete: TRANSITION_COMPLETE,
      transitionIn: TRANSITION_IN,
      transitionOut: TRANSITION_OUT,
      transitionSetup: on === PREVIOUS ? TRANSITION_SETUP_LEFT : TRANSITION_SETUP_RIGHT,
    };
  }

  function getButtonProps(direction) {
    return {
      "aria-label": props[direction === PREVIOUS ? "aria-label-previous" : "aria-label-next"],
      className: classNames(styles.bannerButton, { [styles.next]: direction === NEXT }),
      clickFeedback: "none",
      onClick: () => handleClick(direction),
      styleType: "no-style",
    };
  }

  function getDotProps() {
    return {
      className: classNames(styles.dot, { [styles.none]: on !== OFF }),
      color: BANNER_FEEDBACK_DESIGN_TOKEN,
      duration: TRANSITION_DURATION_DOT,
      fullWidth: true,
      onTransformationEnd: handleTransformationEnd,
      to: "FADED-GROW",
    };
  }

  function getIconProps(direction) {
    return {
      actions: [direction === PREVIOUS ? "rotate270" : "rotate90"],
      className: styles.icon,
      color: BANNER_BUTTON_CONTENT_DESIGN_TOKEN,
      name: "ArrowThin",
      size: "size20",
      transparentBorder: true,
    };
  }

  function getContainerClass() {
    return classNames(styles.container, {
      [styles.dynamic]: width === "dynamic",
      [styles.preset]: width === "preset",
    });
  }

  function handleClick(direction) {
    const max = items.length;

    setItemIndex((itemIndex + direction + max) % max);
    setOn(direction);
    onClick && onClick();
  }

  function handleTransformationEnd() {
    setOn(OFF);
  }

  function getItemWidths() {
    setItemWidths(itemRefs.current.map((item) => item.getBoundingClientRect().width));
  }

  function getWidestItem() {
    return Math.min(Math.max(...itemWidths), containerWidth - BUTTON_WIDTHS);
  }

  function updatePageInfo() {
    if (timer) {
      window.clearTimeout(timer);
    }

    timer = window.setTimeout(() => {
      setContainerWidth(containerRef?.current?.getBoundingClientRect().width);
    }, EVENT_AGGREGATE_DURATION);
  }
});

Banner.propTypes = {
  /** aria-label text to provide accessibility description for Banner component. */
  "aria-label": PropTypes.string,

  /** aria-label text to provide accessibility description for previous item button. */
  "aria-label-next": PropTypes.string.isRequired,

  /** aria-label text to provide accessibility description for next item button. */
  "aria-label-previous": PropTypes.string.isRequired,

  /**
   * Additional classes for positioning the component. Given classes may only position this
   * component for layout purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * Content list to be displayed. This can be anything React can render. See List component for
   * more details.
   */
  items: PropTypes.arrayOf(PropTypes.node),

  /** Function called for when the banner arrows are clicked */
  onClick: PropTypes.func,

  /** Function called when the banner content is displayed. */
  onNewContentDisplayed: PropTypes.func,

  /**
   * width specifies if the Banner's width is restrained to specific widths. If width is defined as
   * "preset", the Banner's width will set to a device appropriate value. If width is "dynamic", the
   * Banner's width will be defined by the content's widest item.
   */
  width: PropTypes.oneOf(["dynamic", "preset"]),
};

Banner.displayName = "Banner";

Banner.defaultProps = {
  width: "dynamic",
};
