import PropTypes from "prop-types";
import React from "react";

export const LoveVoucher = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path
        d="M30,8 L30,24 L2,24 L2,19 C3.65685425,19 5,17.6568542 5,16 C5,14.3431458 3.65685425,13 2,13 L2,8 L30,8 Z M9,19 C8.44444444,19 8,19.4444444 8,20 C8,20.5555556 8.44444444,21 9,21 C9.55555556,21 10,20.5555556 10,20 C10,19.4444444 9.55555556,19 9,19 Z M22.0713309,12 C21.1406069,12 20.4663102,12.5556498 19.9978061,12.8141113 C19.5293021,12.5556498 18.8378627,12 17.9066795,12 C16.4518512,12 15,12.9476923 15,14.56 C15,16.5292308 17.7046022,18.4492308 19.9978061,20 C22.2910101,18.4615385 25,16.5292308 25,14.56 C25,12.9476923 23.5138302,12 22.0713309,12 Z M9,15 C8.44444444,15 8,15.4444444 8,16 C8,16.5555556 8.44444444,17 9,17 C9.55555556,17 10,16.5555556 10,16 C10,15.4444444 9.55555556,15 9,15 Z M9,11 C8.44444444,11 8,11.4444444 8,12 C8,12.5555556 8.44444444,13 9,13 C9.55555556,13 10,12.5555556 10,12 C10,11.4444444 9.55555556,11 9,11 Z"
        stroke="none"
      />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      style,
      viewBox: "2 3 25 26",
    };
  }
};

LoveVoucher.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

LoveVoucher.defaultProps = {
  role: "img",
};
