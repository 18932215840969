import PropTypes from "prop-types";
import React from "react";

export const Ribbon = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path
        d="M36.9,16.4c-0.3-0.5-0.5-1.3-0.5-1.8L37,9c0.1-0.5-0.3-1.1-0.9-1.3l-5.4-1.5c-0.5-0.2-1.2-0.6-1.5-1.1
		l-3.1-4.6c-0.3-0.5-1-0.6-1.5-0.4l-5.1,2.3c-0.5,0.2-1.3,0.3-1.9,0.1L12.3,1c-0.5-0.2-1.2,0.1-1.4,0.6l-2.4,5
		C8.2,7.1,7.6,7.7,7.1,8L2,10.3c-0.5,0.2-0.8,0.8-0.7,1.4l1.4,5.4c0.1,0.5,0,1.4-0.2,1.9l-2.4,5c-0.2,0.5-0.1,1.2,0.4,1.5L5,28.7
		c0.4,0.3,0.9,1,1.1,1.6l1.4,5.4c0.1,0.5,0.7,0.9,1.2,0.9l1.5-0.1c0.5,0,0.9,0.4,0.8,0.9L6.1,57.5C6,58,6.2,58.2,6.7,57.9l4.2-2.8
		c0.5-0.3,1.1-0.2,1.3,0.3l2.5,4.4c0.3,0.5,0.6,0.4,0.7-0.1l4.4-18.3c0.1-0.5,0.3-0.5,0.5,0l4.3,17.8c0.1,0.5,0.5,0.6,0.7,0.1
		l2.5-4.4c0.3-0.5,0.9-0.6,1.3-0.3l4.2,2.8c0.5,0.3,0.7,0.1,0.6-0.4l-5-20.9c-0.1-0.5,0.2-1,0.8-1l3.5-0.3c0.5,0,1-0.5,1.1-1.1
		l0.6-5.6c0.1-0.5,0.4-1.3,0.8-1.7l4-3.9c0.4-0.4,0.5-1.1,0.2-1.5L36.9,16.4z M20.1,31.5c-6.7,0-12.2-5.4-12.2-12.2
		S13.4,7.1,20.1,7.1s12.2,5.5,12.2,12.2S26.8,31.5,20.1,31.5z"
      />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      style,
      viewBox: "0 0 40 60",
    };
  }
};

Ribbon.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Ribbon.defaultProps = { role: "img" };
