import PropTypes from "prop-types";
import React from "react";

export const Cup = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path d="M2.6665 12.6667H13.3332V14H2.6665V12.6667ZM13.3332 2H2.6665V8.66667C2.6665 10.14 3.85984 11.3333 5.33317 11.3333H9.33317C10.8065 11.3333 11.9998 10.14 11.9998 8.66667V6.66667H13.3332C14.0732 6.66667 14.6665 6.06667 14.6665 5.33333V3.33333C14.6665 2.59333 14.0732 2 13.3332 2ZM10.6665 8.66667C10.6665 9.4 10.0665 10 9.33317 10H5.33317C4.59984 10 3.99984 9.4 3.99984 8.66667V3.33333H10.6665V8.66667ZM13.3332 5.33333H11.9998V3.33333H13.3332V5.33333Z" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "0 0 16 16",
    };
  }
};

Cup.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Cup.defaultProps = { role: "img" };
