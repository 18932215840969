import useHttpUseFetch from "use-http";

import { swaDate } from "@swa-ui/date";
import { logger } from "@swa-ui/log";

export const useFetch = (...args) => {
  const { get: httpGet, post: httpPost, ...remaining } = useHttpUseFetch(...args);

  async function get(...params) {
    const startTime = swaDate();
    const responseData = await httpGet(...params);

    const endTime = swaDate();

    if (responseData?.error) {
      responseData.error.callDuration = endTime.diff(startTime);
      logger.error("FETCH ERROR", responseData.error);
    }

    return responseData;
  }

  async function post(...params) {
    const startTime = swaDate();
    const responseData = await httpPost(...params);

    const endTime = swaDate();

    if (responseData?.error) {
      responseData.error.callDuration = endTime.diff(startTime);
      logger.error("FETCH ERROR", responseData.error);
    }

    return responseData;
  }

  return {
    get,
    post,
    ...remaining,
  };
};
