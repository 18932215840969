import PropTypes from "prop-types";
import React from "react";

import { classNames } from "@swa-ui/string";

import { keyCodes } from "../defines/keyCodes";
import { Transform } from "../Transform";
import styles from "./Toggle.module.scss";

/**
 * Simple component to allow the traveler to select if a feature is on or off. When the traveler toggles this option,
 * the calling component will be informed if the state is on or not on.
 */

export const Toggle = (props) => {
  const { checked, className, disabled, onChange } = props;

  return (
    <div {...getProps()}>
      <Transform {...getTransformProps()}>
        <div className={styles.knob} />
      </Transform>
    </div>
  );

  function getProps() {
    return {
      "aria-checked": checked,
      "aria-disabled": disabled,
      "aria-label": props["aria-label"],
      className: getClass(),
      onClick: handleClick,
      onKeyDown: handleKeyDown,
      role: "checkbox",
      tabIndex: 0,
    };
  }

  function getTransformProps() {
    return {
      transformations: checked
        ? [
            {
              action: "translateX",
              amount: "1.9375rem",
            },
          ]
        : [
            {
              action: "translateX",
              amount: "0rem",
            },
          ],
    };
  }

  function getClass() {
    return classNames(className, styles.toggle, {
      [styles.checked]: checked,
      [styles.disabled]: disabled,
    });
  }

  function handleClick() {
    toggle();
  }

  function handleKeyDown(event) {
    const { key } = event;

    if (key === keyCodes.KEY_ENTER || key === keyCodes.KEY_SPACE) {
      toggle();
    }
  }

  function toggle() {
    !disabled && onChange && onChange(!checked);
  }
};

Toggle.propTypes = {
  /** aria-label text to provide additional accessibility description of toggle component. */
  "aria-label": PropTypes.string.isRequired,

  /** Toggle is set to true or false. */
  checked: PropTypes.bool,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Indicates Toggle should apply disabled styling, ignore click events and provide aria-disabled attribute. */
  disabled: PropTypes.bool,

  /** Callback that will be informed when toggle state changes. The checked state will be returned. */
  onChange: PropTypes.func.isRequired,
};
