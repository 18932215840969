import PropTypes from "prop-types";
import React from "react";

import { DotcomDesktopFooter } from "./DotcomDesktopFooter";
import styles from "./DotcomFooter.module.scss";
import { DotcomMobileFooter } from "./DotcomMobileFooter";

/**
 * DotcomFooter provides the footer for each page. There are three different presentations rendered, based on screen
 * size.
 */

export const DotcomFooter = (props) => {
  const { type } = props;

  return (
    <footer className={styles.dotcomFooter}>
      {type === "desktop" ? <DotcomDesktopFooter /> : <DotcomMobileFooter />}
    </footer>
  );
};

DotcomFooter.propTypes = {
  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,
};
