import {
  getExperienceId as getExperienceIdFromStorage,
  setExperienceId as setExperienceIdInStorage,
} from "@swa-ui/application";
import { getSearchParam } from "@swa-ui/browser";
import { usePersistedState } from "@swa-ui/persistence";

import { getApiKey } from "../getApiKey";
import { getChannelId } from "../getChannelId";

export const usePersistedApplicationContext = () => {
  const [apiKey] = usePersistedState({
    key: "usePersistedApplicationContext-apiKey",
    defaultValue: getApiKey(),
    persistDefault: true,
  });
  const [appVersion] = usePersistedState({
    key: "usePersistedApplicationContext-appVersion",
    defaultValue: getAppVersion(),
    persistDefault: true,
  });
  const [channelId] = usePersistedState({
    key: "usePersistedApplicationContext-channelId",
    defaultValue: getChannelId(),
    persistDefault: true,
  });
  const [deviceType] = usePersistedState({
    key: "usePersistedApplicationContext-deviceType",
    defaultValue: getDeviceType(),
    persistDefault: true,
  });
  const [diagnostic] = usePersistedState({
    key: "usePersistedApplicationContext-diagnostic",
    defaultValue: getDiagnostic(),
    persistDefault: true,
  });
  const [returnToUrl] = usePersistedState({
    key: "usePersistedApplicationContext-returnToUrl",
    defaultValue: getReturnToUrl(),
    persistDefault: true,
  });

  return {
    apiKey,
    appVersion,
    channelId,
    deviceType,
    diagnostic,
    experienceId: getExperienceId(),
    returnToUrl,
  };

  function getAppVersion() {
    return [getSearchParam("appVersion"), process.env.VERSION].filter(Boolean).join("|");
  }

  function getDeviceType() {
    return getSearchParam("deviceType") ?? "";
  }

  function getDiagnostic() {
    const appVersionParam = getSearchParam("appVersion");

    return JSON.stringify({
      spa: process.env.VERSION,
      ...(appVersionParam && { native: appVersionParam }),
    });
  }

  function getExperienceId() {
    const experienceIdParam = getSearchParam("experienceId");

    experienceIdParam && setExperienceIdInStorage(experienceIdParam);

    return getExperienceIdFromStorage();
  }

  function getReturnToUrl() {
    const returnToUrlParam = getSearchParam("returnToURL");

    return returnToUrlParam ? decodeURIComponent(returnToUrlParam) : "";
  }
};
