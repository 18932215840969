import { useMemo } from "react";

import { usePersistedState } from "@swa-ui/persistence";

import { getGeolocation } from "../getGeolocation";
import { getGeolocationSchema } from "../schema";

export const useGeolocation = () => {
  const geolocationFromWindow = useMemo(getGeolocation, []);
  const [geolocation] = usePersistedState({
    defaultValue: geolocationFromWindow,
    key: "geolocation",
    persistDefault: true,
    schema: getGeolocationSchema(),
  });

  return geolocation;
};
