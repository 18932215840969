"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useFeaturedDealsAnalytics = void 0;
var _react = require("react");
var _reactRouterDom = require("react-router-dom");
var _application = require("@swa-ui/application");
var _routes = require("./defines/routes");
var useFeaturedDealsAnalytics = exports.useFeaturedDealsAnalytics = function useFeaturedDealsAnalytics(packagesDataList, heading) {
  var _useLocation = (0, _reactRouterDom.useLocation)(),
    pathname = _useLocation.pathname;
  var _useContext = (0, _react.useContext)(_application.MktgDataContext),
    setAdobePageData = _useContext.setAdobePageData;
  (0, _react.useEffect)(function () {
    if (shouldSetFeaturedDealsAnalytics() && packagesDataList.length) {
      var impressions = packagesDataList === null || packagesDataList === void 0 ? void 0 : packagesDataList.map(function (packageDataList, index) {
        var _hotelOption$address$, _hotelOption$address, _flightOptions$1$arri, _flightOptions$, _packageSavings$separ, _packageSavings$separ2, _packageSavings$combi, _packageSavings$combi2, _hotelOption$hotelTyp, _hotelOption$hotelTyp2;
        var packageData = packageDataList.packageData;
        var hotelOption = packageData.hotelOption,
          packageSavings = packageData.packageSavings,
          id = packageData.id,
          flightOptions = packageData.flightOptions;
        return {
          productcarousel: heading || "",
          productcode: id !== null && id !== void 0 ? id : "",
          productdestination: (_hotelOption$address$ = hotelOption === null || hotelOption === void 0 || (_hotelOption$address = hotelOption.address) === null || _hotelOption$address === void 0 ? void 0 : _hotelOption$address.city) !== null && _hotelOption$address$ !== void 0 ? _hotelOption$address$ : "",
          productorigin: (_flightOptions$1$arri = flightOptions === null || flightOptions === void 0 || (_flightOptions$ = flightOptions[1]) === null || _flightOptions$ === void 0 || (_flightOptions$ = _flightOptions$.arrival) === null || _flightOptions$ === void 0 ? void 0 : _flightOptions$.city) !== null && _flightOptions$1$arri !== void 0 ? _flightOptions$1$arri : "",
          productoriginalprice: (_packageSavings$separ = packageSavings === null || packageSavings === void 0 || (_packageSavings$separ2 = packageSavings.separateBookingAmount) === null || _packageSavings$separ2 === void 0 || (_packageSavings$separ2 = _packageSavings$separ2.cash) === null || _packageSavings$separ2 === void 0 || (_packageSavings$separ2 = _packageSavings$separ2.amount) === null || _packageSavings$separ2 === void 0 ? void 0 : _packageSavings$separ2.toString()) !== null && _packageSavings$separ !== void 0 ? _packageSavings$separ : "",
          productposition: "".concat(index + 1, "|").concat(packagesDataList === null || packagesDataList === void 0 ? void 0 : packagesDataList.length),
          productprice: (_packageSavings$combi = packageSavings === null || packageSavings === void 0 || (_packageSavings$combi2 = packageSavings.combineAndSaveAmount) === null || _packageSavings$combi2 === void 0 || (_packageSavings$combi2 = _packageSavings$combi2.cash) === null || _packageSavings$combi2 === void 0 || (_packageSavings$combi2 = _packageSavings$combi2.amount) === null || _packageSavings$combi2 === void 0 ? void 0 : _packageSavings$combi2.toString()) !== null && _packageSavings$combi !== void 0 ? _packageSavings$combi : "",
          producttype: (_hotelOption$hotelTyp = hotelOption === null || hotelOption === void 0 || (_hotelOption$hotelTyp2 = hotelOption.hotelType) === null || _hotelOption$hotelTyp2 === void 0 ? void 0 : _hotelOption$hotelTyp2.toString()) !== null && _hotelOption$hotelTyp !== void 0 ? _hotelOption$hotelTyp : ""
        };
      });
      setAdobePageData({
        event: "impression",
        eventinfo: {
          eventtype: "content"
        }
      }, "featuredDeals", impressions);
    }
  }, [packagesDataList.length, heading]);
  function shouldSetFeaturedDealsAnalytics() {
    return pathname === _routes.ROUTES.vacations || pathname === _routes.ROUTES.destination || pathname === _routes.ROUTES.deals;
  }
};