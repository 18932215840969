import * as yup from "yup";

import i18n from "@swa-ui/locale";

export const fieldMaxLengths = {
  passcode: 6,
};

export const fieldMinLengths = {
  passcode: 6,
};

export const getMfaEnterPasscodeFormSchema = () =>
  yup.object().shape({
    passcode: yup
      .string()
      .stripAllWhitespace()
      .required()
      .max(fieldMaxLengths.passcode)
      .min(fieldMinLengths.passcode)
      .numeric()
      .label(i18n("MfaEnterPasscodeForm__PASSCODE").toLowerCase()),
  });
