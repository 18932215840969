import PropTypes from "prop-types";
import React from "react";

export const Eight = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path
        strokeWidth={0}
        d="M18.9,17.1c0.6,0,1-0.2,1.4-0.5c0.3-0.3,0.5-0.8,0.5-1.4c0-0.6-0.2-1-0.5-1.4c-0.3-0.3-0.8-0.5-1.3-0.5
			c-0.6,0-1,0.2-1.4,0.5s-0.5,0.8-0.5,1.4c0,0.6,0.2,1.1,0.5,1.4C17.9,17,18.4,17.1,18.9,17.1z"
      />
      <path
        strokeWidth={0}
        d="M19,19.6c-0.7,0-1.3,0.3-1.6,0.8c-0.4,0.5-0.5,1.1-0.5,1.7c0,0.8,0.2,1.5,0.6,1.9c0.4,0.5,1,0.7,1.6,0.7
			s1.1-0.2,1.5-0.7s0.6-1.1,0.6-1.9c0-0.7-0.2-1.3-0.6-1.8C20.2,19.8,19.6,19.6,19,19.6z"
      />
      <path
        strokeWidth={0}
        d="M19,0C8.5,0,0,8.5,0,19s8.5,19,19,19s19-8.5,19-19S29.5,0,19,0z M22.8,25.7c-0.9,0.9-2.2,1.4-3.7,1.4
			c-1.4,0-2.6-0.4-3.6-1.1c-1.1-0.9-1.7-2.1-1.7-3.7c0-0.9,0.2-1.6,0.6-2.3c0.4-0.7,1.1-1.3,2-1.6c-0.8-0.3-1.3-0.8-1.7-1.4
			s-0.5-1.2-0.5-1.9c0-1.2,0.4-2.1,1.2-2.9c0.8-0.8,2-1.1,3.5-1.1s2.7,0.4,3.5,1.1c0.8,0.8,1.2,1.7,1.2,2.9c0,0.7-0.2,1.4-0.6,1.9
			c-0.4,0.6-0.9,1-1.6,1.3c0.9,0.4,1.5,0.9,2,1.5c0.5,0.7,0.7,1.5,0.7,2.3C24.2,23.6,23.7,24.8,22.8,25.7z"
      />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      style,
      viewBox: "0 0 38 38",
    };
  }
};

Eight.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Eight.defaultProps = {
  role: "img",
};
