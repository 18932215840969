import * as yup from "yup";

import i18n from "@swa-ui/locale";

export const fieldMinLengths = {
  companyId: 8,
  password: 8,
  username: 4,
};

export const fieldMaxLengths = {
  companyId: 8,
  password: 16,
  username: 20,
};

const companyIdRegex = /^99|94|97/;
const passwordSpecialCharacterSet = "!@#$%^*\\\\/(),.:;";
const passwordValidCharacterSet = "a-zA-Z0-9" + passwordSpecialCharacterSet;
const passwordRegex = new RegExp("^(?=[a-zA-Z0-9]+)(?!.*[^" + passwordValidCharacterSet + "]).*$");
const userNameRegex = /^[a-zA-Z\d#&!@$%^*(){}\[\]:;"'<>,.?/~`+-_=\\]{4,20}$/;

let storedLoginInfoSchema;

export const getLoginSchema = (
  corporateSite = false,
  shouldPreventUserNameOrCompanyIdChange = false,
  storedLogin = false
) => {
  const corporateOnly = {
    companyId: yup.string().when({
      is: () => shouldPreventUserNameOrCompanyIdChange == false,
      then: yup
        .string()
        .trim()
        .required(i18n("loginSchema__ENTER_VALID_COMPANY_ID"))
        .label(i18n("loginSchema__COMPANY_ID").toLowerCase())
        .max(fieldMaxLengths.companyId)
        .min(fieldMinLengths.companyId)
        .matches(companyIdRegex),
    }),
  };
  const commonFields = {
    username: yup.string().when({
      is: () => shouldPreventUserNameOrCompanyIdChange == false,
      then: yup
        .string()
        .trim()
        .required(i18n("loginSchema__ENTER_VALID_USERNAME"))
        .label(i18n("loginSchema__ACCOUNT_NUMBER_OR_USERNAME").toLowerCase())
        .max(fieldMaxLengths.username)
        .min(fieldMinLengths.username)
        .matches(userNameRegex),
    }),
    rememberMe: yup.boolean(),
  };
  const password = {
    password: yup
      .string()
      .required(i18n("loginSchema__ENTER_VALID_PASSWORD"))
      .label(i18n("loginSchema__PASSWORD").toLowerCase())
      .max(fieldMaxLengths.password)
      .min(fieldMinLengths.password)
      .matches(passwordRegex),
  };
  return yup.object().shape({
    ...commonFields,
    ...(!storedLogin ? password : {}),
    ...(corporateSite && !storedLogin ? corporateOnly : {}),
  });
};

export const getStoredLoginInfoSchema = () => {
  if (!storedLoginInfoSchema) {
    storedLoginInfoSchema = yup.object().shape({
      username: yup
        .string()
        .trim()
        .when({
          is: (value) => value?.length > 0,
          then: yup
            .string()
            .label(i18n("loginSchema__ACCOUNT_NUMBER_OR_USERNAME").toLowerCase())
            .max(fieldMaxLengths.username)
            .min(fieldMinLengths.username)
            .matches(userNameRegex),
        }),
      rememberMe: yup.boolean(),
    });
  }

  return storedLoginInfoSchema;
};
