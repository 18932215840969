import PropTypes from "prop-types";
import React, { createContext } from "react";

import { getBootstrapData } from "@swa-ui/bootstrap";
import { FetchInterceptor } from "@swa-ui/fetch";
import { usePersistedState } from "@swa-ui/persistence";

/**
 * UiMetadataProvider provides UI metadata in a React context so the metadata is available to components in an app's
 * render tree.
 *
 * uiMetadata - UI metadata for the SPA.
 * updateUiMetadata - Method to update the UI metadata.
 */

export const UiMetadataContext = createContext();
export const UiMetadataProvider = (props) => {
  const { children } = props;
  const [uiMetadata, setUiMetadata] = usePersistedState({
    defaultValue: getBootstrapData("metadata/metadata-data"),
    key: "UiMetadataProvider-uiMetadata",
  });

  return (
    <FetchInterceptor onResponse={checkResponseForUiMetadata}>
      <UiMetadataContext.Provider value={{ uiMetadata, updateUiMetadata }}>
        {children}
      </UiMetadataContext.Provider>
    </FetchInterceptor>
  );

  function checkResponseForUiMetadata(serviceResponse) {
    const responseUiMetadata = serviceResponse?.data?.uiMetadata;

    if (responseUiMetadata) {
      updateUiMetadata(responseUiMetadata);
    }

    return serviceResponse;
  }

  function updateUiMetadata(newUiMetadata) {
    setUiMetadata({
      ...uiMetadata,
      ...newUiMetadata,
      lastUpdateTime: new Date().getTime(),
    });
  }
};

UiMetadataContext.displayName = "UiMetadataContext";
UiMetadataProvider.propTypes = {
  /** Content to be rendered on the page. */
  children: PropTypes.node.isRequired,
};
