import PropTypes from "prop-types";
import React from "react";

export const NotPermitted = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path
        d="M17.6,31.3c-3,0-5.8-1-8-2.6L28.7,9.6c1.6,2.3,2.6,5,2.6,8C31.3,25.2,25.2,31.3,17.6,31.3 M3.9,17.6
		c0-7.6,6.1-13.7,13.7-13.7c3.1,0,5.9,1,8.2,2.8L6.7,25.8C5,23.6,3.9,20.7,3.9,17.6 M17.6,0C7.9,0,0,7.9,0,17.6
		c0,9.7,7.9,17.6,17.6,17.6c9.7,0,17.6-7.9,17.6-17.6C35.2,7.9,27.4,0,17.6,0"
      />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "0 0 36 36",
    };
  }
};

NotPermitted.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

NotPermitted.defaultProps = {
  role: "img",
};
