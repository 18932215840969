import PropTypes from "prop-types";
import React from "react";

import { PlacementFactory } from "@swa-ui/placements";
import { classNames } from "@swa-ui/string";

import styles from "./Callouts.module.scss";

/**
 * Displays callout section of global footer based on WCM content.
 */

export const Callouts = (props) => (
  <div className={classNames(styles.callouts, props.className)}>
    <div className={styles.calloutContainer}>
      <div className={styles.content}>
        <PlacementFactory id="globalNavFooterCallout" />
      </div>
    </div>
  </div>
);

Callouts.propTypes = {
  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,
};
