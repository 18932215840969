import PropTypes from "prop-types";
import React from "react";

import i18n from "@swa-ui/locale";
import { classNames } from "@swa-ui/string";

import styles from "./FlightDuration.module.scss";

/**
 * FlightDuration displays flight duration information.
 *
 * Outputs when duration is 211 minutes:
 * Leg duration: 3h 31m
 * Stops duration: 3h31m
 *
 * Leg duration outputs with zeros:
 * 10 minutes: 0h 10m
 * 60 minutes: 1h 0m
 */

export const FlightDuration = (props) => {
  const { className, duration, separator, styleType } = props;
  const formattedDuration = getFormattedDuration();

  return <div {...getProps()}>{formattedDuration}</div>;

  function getProps() {
    return {
      "aria-label": getAriaLabel(),
      className: getClass(),
    };
  }

  function getAriaLabel() {
    const durationAriaLabel = `${
      separator ? getLegDurationAriaLabel() : getStopsDurationAriaLabel()
    }.`;
    let ariaLabel = durationAriaLabel;

    if (props["aria-label"]) {
      ariaLabel = `${props["aria-label"]} ${durationAriaLabel}.`;
    }

    return ariaLabel;
  }

  function getClass() {
    return classNames({
      [className]: className,
      [styles.primary]: styleType === "primary",
      [styles.secondary]: styleType === "secondary",
    });
  }

  function getFormattedDuration() {
    const MINUTES_PER_HOUR = 60;
    const hours = Math.floor(duration / MINUTES_PER_HOUR);
    const minutes = duration % MINUTES_PER_HOUR;
    const formattedHours = hours + i18n("FlightDuration__HOURS_ABBREVIATION");
    const formattedMinutes = minutes + i18n("FlightDuration__MINUTES_ABBREVIATION");

    return separator
      ? `${formattedHours} ${formattedMinutes}`
      : `${formattedHours}${formattedMinutes}`;
  }

  function getLegDurationAriaLabel() {
    return formattedDuration
      .replace(i18n("FlightDuration__HOURS_ABBREVIATION"), ` ${i18n("FlightDuration__HOURS_ARIA")}`)
      .replace(
        i18n("FlightDuration__MINUTES_ABBREVIATION"),
        ` ${i18n("FlightDuration__MINUTES_ARIA")}`
      );
  }

  function getStopsDurationAriaLabel() {
    return formattedDuration
      .replace(
        i18n("FlightDuration__HOURS_ABBREVIATION"),
        ` ${i18n("FlightDuration__HOURS_ARIA")} `
      )
      .replace(
        i18n("FlightDuration__MINUTES_ABBREVIATION"),
        ` ${i18n("FlightDuration__MINUTES_ARIA")}`
      );
  }
};

FlightDuration.propTypes = {
  /** aria-label text to provide accessibility description. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Length in total minutes. */
  duration: PropTypes.number.isRequired,

  /** Indicates if there is a space between hours abbreviation and number of minutes. */
  separator: PropTypes.bool,

  /** Primary indicates styling for leg duration. Secondary indicates styling for stops duration. */
  styleType: PropTypes.oneOf(["primary", "secondary"]),
};

FlightDuration.defaultProps = {
  separator: true,
  styleType: "primary",
};
