import { useContext, useEffect } from "react";

import { ApplicationContext } from "@swa-ui/application";
import { getBootstrapData } from "@swa-ui/bootstrap";
import { window } from "@swa-ui/browser";
import { useGeolocation } from "@swa-ui/geolocation";

const PERFORMICS_DATA_KEY = "performics_vendor_data";

/**
 * This component executes any scripts that may be needed by pages used by external
 * vendor pages, third party websites or any external webpage which uses fm-dotcom-landing-page.
 */
export const VendorScripts = () => {
  const { site } = useContext(ApplicationContext);
  const geolocation = getBootstrapData("air/stations-data") && useGeolocation();
  const nearestStation = geolocation?.nearestStation;

  useEffect(() => {
    site === "external" && setVendorData();
  }, []);

  function setVendorData() {
    setPerformicsData();
  }

  function setPerformicsData() {
    const performicsData = window[PERFORMICS_DATA_KEY];

    if (performicsData) {
      window[PERFORMICS_DATA_KEY] = {
        ...performicsData,
        ...(nearestStation && { nearestStation }),
      };
    }
  }
};
