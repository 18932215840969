import PropTypes from "prop-types";
import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useDeviceInfo, window } from "@swa-ui/browser";
import { PlacementsContext } from "@swa-ui/placements";

import { ApplicationContext } from "../ApplicationContextProvider";
import { channels } from "../defines";
import { MktgDataContext } from "../MktgDataProvider";
import { usePageId } from "../usePageId";

const MESSAGE_DISPLAY_PLACEMENT_IDS = [
  "messageIncidentAlert",
  "messageIncidentAlertfit",
  "messageSystemAlert",
  "messageSystemAlertfit",
  "topBanner",
];

const TRAVEL_ADVISORY_PLACEMENT_IDS = ["messageTravelAdvisory", "messageTravelAdvisoryfit"];

export const AnalyticsDataUpdater = (props) => {
  const { additionalAnalyticsData, eventSpecificAnalyticsData, chaseAnalyticsData } = props;
  const { member_number, member_pointsbalance, member_status, user_loginstate, page_language } =
    additionalAnalyticsData ?? {};
  const { chase_acqsrccode, chase_highvalueindicator, chase_offeridentifier } =
    chaseAnalyticsData ?? {};
  const { appId, channelId } = useContext(ApplicationContext);
  const { pageId } = usePageId();
  const { pathname } = useLocation();
  const {
    placements = {},
    placementsAnalytics = {},
    setPlacementsAnalytics = {},
  } = useContext(PlacementsContext) ?? {};
  const { screenSize } = useDeviceInfo();
  const { setMktgEventData, updateMktgGlobalData, updateAdobeGlobalData, sendAdobeEventData } =
    useContext(MktgDataContext);

  useEffect(() => {
    const { eventType = "", eventDescription = "" } = placementsAnalytics;

    if (placementsAnalytics.eventDescription && appId === "vacations") {
      const adobeAnalyticsObj = {
        event: "clickaction",
        eventinfo: {
          eventtype: eventType,
        },
        page: {
          eventdescription: eventDescription,
          pagename: `swav ${getPageData().page_subchannel}`,
          section: "marketing",
        },
      };

      sendAdobeEventData(adobeAnalyticsObj);
      setPlacementsAnalytics({});
    }
  }, [placementsAnalytics]);

  useEffect(() => {
    updateMktgGlobalData({
      ...chaseAnalyticsData,
      chase_rr_number: "",
    });
  }, [chaseAnalyticsData]);

  useEffect(() => {
    if (appId === "vacations") {
      updateAdobeGlobalData({
        page: {
          pagename: `swav ${getPageData().page_subchannel}`,
          section: "marketing",
        },
      });
    }
  }, [pathname]);

  useEffect(() => {
    updateMktgGlobalData({
      devicetype: getDeviceType(),
      global_channelid: channelId,
      responsivesize: screenSize,
      ...getPageData(),
    });
    updateAdobeGlobalData({
      device: {
        devicetype: getDeviceType(),
        responsivesize: screenSize,
      },
    });
  }, [pathname, screenSize]);

  useEffect(() => {
    updateMktgGlobalData({
      ...additionalAnalyticsData,
    });
    updateAdobeGlobalData({
      customer: {
        authstatus: user_loginstate,
        chase_acqsrccode: chase_acqsrccode ? chase_acqsrccode : "",
        chase_highvalueindicator: chase_highvalueindicator ? "1" : "0",
        chase_offeridentifier: chase_offeridentifier ? chase_offeridentifier : "",
        membernumber: member_number,
        memberpointbalance: member_pointsbalance,
        membertierstatus: member_status,
      },
    });
  }, [additionalAnalyticsData]);

  useEffect(() => {
    setMktgEventData(eventSpecificAnalyticsData);
  }, [eventSpecificAnalyticsData]);

  useEffect(() => {
    const informationalMessageDisplayData = isInformationalMessageDisplayed()
      ? { informational_message_display: "1" }
      : {};
    const traveladvisory = isTravelAdvisoryDisplayed() ? { travel_advisory_displayed: "1" } : {};

    updateMktgGlobalData({
      contentblockid: getContentBlockId(),
      ...informationalMessageDisplayData,
      ...traveladvisory,
    });
    updateAdobeGlobalData({
      content: {
        contentblockid: getContentBlockId(),
        language: page_language,
      },
      ...traveladvisory,
    });
  }, [placements]);

  return null;

  function getContentBlockId() {
    const placementContentBlockId = Object.values(placements).map(
      (placement) => placement.contentBlockId
    );
    const wcmContentBlockId = Object.values(window.SWA?.wcm?.contentBlockId ?? {});

    return [...placementContentBlockId, ...wcmContentBlockId].filter(Boolean).join(":") || null;
  }

  function getPageData() {
    let location = pathname;

    if (location === "/") {
      location = "/landing/home-page";
    }

    const names = location.split("/", 4).filter(Boolean);
    const page = pageId;
    const page_channel = names[0];
    const page_subchannel = names[1] || names[0];
    const page_name = names[2] || "index";

    return { page, page_channel, page_name, page_subchannel };
  }

  function getDeviceType() {
    let deviceType = "desktop";

    if (channelId === channels.mweb) {
      deviceType = "mobile";
    } else if (channelId === channels.android) {
      deviceType = channels.android;
    } else if (channelId === channels.ios) {
      deviceType = channels.ios;
    }

    return deviceType;
  }

  function isInformationalMessageDisplayed() {
    const placementIds = Object.keys(placements);

    return placementIds.some((placementId) => MESSAGE_DISPLAY_PLACEMENT_IDS.includes(placementId));
  }

  function isTravelAdvisoryDisplayed() {
    const placementIds = Object.keys(placements);

    return placementIds.some((placementId) => TRAVEL_ADVISORY_PLACEMENT_IDS.includes(placementId));
  }
};

AnalyticsDataUpdater.propTypes = {
  additionalAnalyticsData: PropTypes.object,
  chaseAnalyticsData: PropTypes.object,
  eventSpecificAnalyticsData: PropTypes.object,
};
