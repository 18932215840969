import * as PropTypes from "prop-types";
import React from "react";

import { DialogHeading, DialogSubheading } from "@swa-ui/core";
import i18n from "@swa-ui/locale";

import styles from "./MfaHeading.module.scss";

/**
 * MfaHeading renders the heading and subheading for the MFA dialog.
 */
export const MfaHeading = (props) => {
  const {
    heading = i18n("MfaHeading__HEADING"),
    subheading = i18n("MfaHeading__SUBHEADING"),
    prompt,
  } = props;

  return (
    <>
      <DialogHeading className={styles.heading} styleLevel={3}>
        {heading}
      </DialogHeading>
      <DialogSubheading className={styles.subheading}>
        <div className={styles.subheading}>{subheading}</div>
        {prompt && <div className={styles.prompt}>{prompt}</div>}
      </DialogSubheading>
    </>
  );
};

MfaHeading.propTypes = {
  /** The heading content. */
  heading: PropTypes.node,

  /** The prompt content. */
  prompt: PropTypes.node,

  /** The subheading content. */
  subheading: PropTypes.node,
};
