import { useEffect, useRef, useState } from "react";

import { useFetch } from "@swa-ui/fetch";
import { logger } from "@swa-ui/log";

export function usePackageData(carouselContent) {
  const { get } = useFetch();
  const [packagesDataList, setPackagesDataList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const packagesDataRef = useRef(false);

  useEffect(() => {
    const fetchPackages = async () => {
      setIsLoading(true);
      const result = await getPackagesList(carouselContent?.packageIds);

      setPackagesDataList(result);
      setIsLoading(false);
    };

    if (!packagesDataRef.current) {
      fetchPackages();
      packagesDataRef.current = true;
    }
  }, [carouselContent]);

  return { isLoading, packagesDataList };

  /**
   * Fetches vacation package data for the list of unique package id combinations (tripProfileId + searchProfileId)
   * @param packageIds
   * @returns {Promise<unknown[]>}
   */
  async function getPackagesList(packageIds) {
    const currentPackagesSet = getHashTableForPackages(packageIds);

    await Promise.all(
      packageIds.map(async (packageIdCombination) => {
        const { searchProfileId, tripProfileId } = packageIdCombination;

        try {
          const packageResult = await getPackageDetails(packageIdCombination);

          currentPackagesSet[getUniqueIdForPackages(tripProfileId, searchProfileId)] =
            packageResult;
        } catch (error) {
          logger.error(
            `tripProfileId::${tripProfileId} searchProfileId::${searchProfileId}, errored out`,
            error
          );
        }
      })
    );

    return Object.values(filterInvalidPackagesData(currentPackagesSet));
  }

  /**
   * This function encapsulates the logic for fetching the data from API calls and setting the final result for a single package.
   * @param packageIdCombination
   * @returns {Promise<{navigationLink, packageData: *}|undefined>}
   */
  async function getPackageDetails(packageIdCombination) {
    const { tripProfileId, searchProfileId } = packageIdCombination || {};
    // fetch minimum priced package
    const minResultSet = await getMinimumResultSet(tripProfileId, searchProfileId);
    const { deepLink, resultSetId } = minResultSet || {};

    if (!resultSetId) {
      logger.error(`tripProfileId::${tripProfileId} is missing resultSetId`);
    }

    // fetch package (hotel + flight pricing) details
    const packageDetailsData = resultSetId && (await getPackageDetailsService(resultSetId));
    const packageData = packageDetailsData?.packageOptions?.[0];

    let resultingPackageData;

    if (deepLink !== undefined && packageData !== undefined) {
      resultingPackageData = {
        navigationLink: deepLink,
        packageData,
      };
    } else {
      logger.error(
        `tripProfileId::${tripProfileId} searchProfileId::${searchProfileId} missing deepLink/packageData`
      );
    }

    return resultingPackageData;
  }

  /**
   * Extracts a single minimum priced package from a list of search results.
   * @param tripProfileId
   * @param searchProfileId
   * @returns {Promise<*>}
   */
  async function getMinimumResultSet(tripProfileId, searchProfileId) {
    const searchResults = await getPackagesForSearchProfileService(tripProfileId, searchProfileId);

    return searchResults?.searches?.minResultSet;
  }

  /**
   * Fetches a list of package resultSet ids and deep links for a given tripProfileId + searchProfileId combination.
   * @param tripProfileId
   * @param searchProfileId
   * @returns {Promise<*>}
   */
  async function getPackagesForSearchProfileService(tripProfileId, searchProfileId) {
    let response;

    try {
      response = await get(
        `/vacations/packages/tripProfiles/${tripProfileId}/searchProfiles/${searchProfileId}`
      );
    } catch (error) {
      logger.error(
        `tripProfileId::${tripProfileId} searchProfileId::${searchProfileId}, api fetch failed`,
        error
      );
    }

    return response;
  }

  /**
   * Fetches hotel + pricing level details for a given package.
   * @param packageResultSetId
   * @returns {Promise<*>}
   */
  async function getPackageDetailsService(packageResultSetId) {
    let response;

    try {
      response = await get(`/vacations/packages/${packageResultSetId}`);
    } catch (error) {
      logger.error(
        `packageResultSetId::${packageResultSetId}, PackageDetailsService api fetch failed`,
        error
      );
    }

    return response;
  }

  /**
   * Creates a hash table (JS object) with keys that identifies a unique package.
   * This helps to maintain an exact slot in the carousel for a given packageId combination set in WCM.
   * @param packageIds
   * @returns {*}
   */
  function getHashTableForPackages(packageIds) {
    return packageIds.reduce((acc, current) => {
      acc[getUniqueIdForPackages(current.tripProfileId, current.searchProfileId)] = undefined;

      return acc;
    }, {});
  }

  /**
   * Generates unique id for packages.
   * @param tripProfileId
   * @param searchProfileId
   * @returns {string}
   */
  function getUniqueIdForPackages(tripProfileId, searchProfileId) {
    return `${tripProfileId}_${searchProfileId}`;
  }

  function filterInvalidPackagesData(currentPackagesSet) {
    return Object.fromEntries(
      Object.entries(currentPackagesSet).filter(([packageKey, packageValue]) =>
        hasValidData(packageKey, packageValue)
      )
    );
  }

  function hasValidData(key, card) {
    const { packageData = {} } = card || {};
    const { flightOptions, hotelOption, packageSavings, prices } = packageData;
    const { iataCode, dateTime, city } = flightOptions?.[0]?.departure || {};
    const earnQuantity = prices?.[0]?.earn?.total?.earnQuantity;
    const { address, duration, images, name, starRating = [] } = hotelOption || {};
    const { combineAndSaveAmount, separateBookingAmount } = packageSavings || {};
    let isValid = true;
    const missingKeys = [];

    if (!card) {
      isValid = false;
      logger.error(`key::${key} data is missing`);
    } else {
      if (!iataCode) {
        isValid = false;
        missingKeys.push("iataCode");
      }

      if (!dateTime) {
        isValid = false;
        missingKeys.push("dateTime");
      }

      if (!city) {
        isValid = false;
        missingKeys.push("city");
      }

      if (earnQuantity == null) {
        isValid = false;
        missingKeys.push("earnQuantity");
      }

      if (!images?.[0]?.url) {
        isValid = false;
        missingKeys.push("url");
      }

      if (!name) {
        isValid = false;
        missingKeys.push("hotelName");
      }

      if (!address?.city) {
        isValid = false;
        missingKeys.push("address city");
      }

      if (address.country?.code === "US" && !address?.state) {
        // TODO ECOM-63627 enable this condition, once state is present
        // isValid = false;
        missingKeys.push("state");
      }

      if (address.country?.code !== "US" && !address?.country?.name) {
        isValid = false;
        missingKeys.push("country name");
      }

      if (!duration) {
        isValid = false;
        missingKeys.push("duration");
      }

      if (!starRating) {
        isValid = false;
        missingKeys.push("starRating");
      }

      if (missingKeys.length > 0) {
        const message = `package key ${key} is missing fields, ${missingKeys.join(",")}`;

        logger.error(message);
      }
    }

    return isValid;
  }
}
