import PropTypes from "prop-types";
import React from "react";

import { classNames } from "@swa-ui/string";

import styles from "../assets/styles/globalRules.module.scss";

/**
 * The AriaLive component indicates that an element will be updated, and
 * describes the types of updates the user agents, assistive technologies, and
 * user can expect from the live region.
 */

const AriaLive = (props) => {
  const { announceContent, ariaLiveSetting, children, className, hiddenFromScreen, id } = props;

  return <div {...getProps()}>{announceContent ? children : ""}</div>;

  function getProps() {
    return {
      "aria-atomic": true,
      "aria-live": ariaLiveSetting,
      className: getClass(),
      id,
    };
  }

  function getClass() {
    return classNames(className, {
      [styles.hiddenFromScreen]: hiddenFromScreen,
    });
  }
};

AriaLive.defaultProps = {
  announceContent: true,
  ariaLiveSetting: "polite",
  hiddenFromScreen: true,
};

AriaLive.propTypes = {
  /**
   * This flag enables/disables the 'children' content to be read out by the screen reader.
   */
  announceContent: PropTypes.bool,
  /**
   * This sets the aria-live setting type, defaulted to 'polite'.
   */
  ariaLiveSetting: PropTypes.oneOf(["assertive", "off", "polite"]),

  /**
   * A single child component in the form of plain text, the contents of which is announced by the screen reader.
   */
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * Option to hide the content on screen, default set to true.
   */
  hiddenFromScreen: PropTypes.bool,

  /** ID for AriaLive. */
  id: PropTypes.string,
};

export { AriaLive };
