import PropTypes from "prop-types";
import React from "react";

export const TV = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path d="M33.4,29.2c0-1.5-1.5-3-3-3H14.5c-1.5,0-3,1.5-3,3L33.4,29.2z" />
      <path d="M41.9,23.5H39v-0.7h2.9V23.5z M3,3h38.9v18.8H3V3z M42.3,0H2.6C1.3,0,0,1.3,0,2.6v19.7 c0,1.3,1.3,2.6,2.6,2.6h39.8c1.3,0,2.6-1.3,2.6-2.6V2.6C44.9,1.3,43.6,0,42.3,0" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "0 0 44.9 29.2",
    };
  }
};

TV.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

TV.defaultProps = {
  role: "img",
};
