import PropTypes from "prop-types";
import React, { useMemo } from "react";

import { usePersonas } from "@swa-ui/personas";

import styles from "./DesktopHeaderControl.module.scss";
import { DesktopHeaderControlCorporate } from "./DesktopHeaderControlCorporate";
import { DesktopHeaderControlLeisure } from "./DesktopHeaderControlLeisure";

/**
 * DesktopHeaderControl provides the account and locale type controls for the header.
 */

export const DesktopHeaderControl = (props) => {
  const { light } = props;
  const { isCorporatePersona } = usePersonas();
  const HeaderControls = useMemo(() => getHeaderControls, [isCorporatePersona]);

  return (
    <div className={styles.headerControl}>
      <HeaderControls />
    </div>
  );

  function getHeaderControls() {
    return isCorporatePersona ? (
      <DesktopHeaderControlCorporate />
    ) : (
      <DesktopHeaderControlLeisure light={light} />
    );
  }
};

DesktopHeaderControl.propTypes = {
  /** Indicates whether the app is using a dark Header background, to be passed to Link component for styling. */
  light: PropTypes.bool,
};
