import { useLocation } from "react-router-dom";

/**
 * Returns true if the current path is the blankpage, used in hybrid applications.
 */

export const useIsBlankRoute = () => {
  const { pathname = "" } = useLocation();

  return pathname.endsWith("/blank");
};
