import PropTypes from "prop-types";
import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { window } from "@swa-ui/browser";

import { SiteStatusContext } from "../SiteStatusProvider";

/**
 * Simple component that will load the Flight In Trouble page when the Site Status Context contains the FIT status code.
 */

export const FitState = (props) => {
  const { children } = props;
  const { pathname } = useLocation();
  const { siteStatus } = useContext(SiteStatusContext);

  useEffect(() => {
    if (shouldRedirectToFitUrl()) {
      window.location.href = siteStatus.fitUrl;
    }
  }, [pathname, siteStatus]);

  return shouldRedirectToFitUrl() ? null : children;

  function shouldRedirectToFitUrl() {
    const { fitState, fitUrl } = siteStatus;

    return fitState && fitUrl && fitUrl !== pathname;
  }
};

FitState.propTypes = {
  /** Content that will be rendered as the page body. */
  children: PropTypes.node.isRequired,
};
