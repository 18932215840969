import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { useAppSetting } from "@swa-ui/application";
import { getBootstrapData } from "@swa-ui/bootstrap";
import { AriaLive, CardGrid, Icon, Link } from "@swa-ui/core";
import { swaDate } from "@swa-ui/date";
import { Form, SubmitFormField, TextFormField, useForm, yupResolver } from "@swa-ui/form";
import i18n from "@swa-ui/locale";
import { classNames } from "@swa-ui/string";

import { MfaHeading } from "../MfaHeading";
import styles from "./MfaEnterPasscodeForm.module.scss";
import { fieldMaxLengths, getMfaEnterPasscodeFormSchema } from "./MfaEnterPasscodeForm.schema";

/**
 * MfaEnterPasscodeForm component is used to collect the passcode from the user.
 */
export const MfaEnterPasscodeForm = (props) => {
  const {
    contact,
    loading,
    onResend,
    onRestart,
    onSubmit,
    passwordResendSuccess,
    validationErrorKey,
  } = props;
  const mfaResendDelayInSeconds = useAppSetting("mfaResendDelayInSeconds", 60);
  const mfaResendAriaLiveDelay = useAppSetting("mfaResendAriaLiveDelay", 15);
  const [secondsUntilResend, setSecondsUntilResend] = useState(mfaResendDelayInSeconds);
  const formMethods = useForm({ resolver: yupResolver(getMfaEnterPasscodeFormSchema()) });
  const { MFA_HELP } = getBootstrapData("urls");

  useEffect(() => {
    if (secondsUntilResend > 0 && !loading) {
      setTimeout(() => {
        setSecondsUntilResend(secondsUntilResend + (loading ? 0 : -1));
      }, 1000);
    }
  }, [secondsUntilResend, loading]);

  useEffect(() => {
    if (passwordResendSuccess) {
      setSecondsUntilResend(mfaResendDelayInSeconds);
      formMethods.clearErrors("passcode");
      formMethods.setFocus("passcode");
      formMethods.setValue("passcode", "");
    }
  }, [passwordResendSuccess]);

  useEffect(() => {
    if (validationErrorKey) {
      formMethods.setError("passcode", {
        message: i18n(validationErrorKey),
      });
      formMethods.setValue("passcode", "");
      formMethods.setFocus("passcode");
    }
  }, [validationErrorKey]);

  return (
    <CardGrid>
      <MfaHeading
        heading={i18n("MfaEnterPasscodeForm__HEADING")}
        key="enter-passcode-heading"
        subheading={
          <div>
            <span>{i18n(`MfaEnterPasscodeForm__PROMPT_${contact.deviceType}`)}</span>{" "}
            <span className={styles.contactValue}>{contact.value}.</span>{" "}
            <span>{i18n("MfaEnterPasscodeForm__EXPIRATION_NOTICE")}</span>
          </div>
        }
      />
      <Form methods={formMethods} onSubmit={onSubmit} noValidate>
        <TextFormField {...getPasscodeProps()} />
        <div className={styles.fullWidth}>
          {i18n("MfaEnterPasscodeForm__RESEND_PROMPT")} {renderResendLink()}
        </div>
        {passwordResendSuccess && secondsUntilResend > 0 && (
          <div className={classNames(styles.fullWidth, styles.resend)}>
            <Icon {...getRequestPasscodeIconProps()} />
            {i18n(`MfaEnterPasscodeForm__RESEND_SUCCESS_${contact.deviceType}`)}
          </div>
        )}
        <Link className={styles.fullWidth} disabled={loading} onClick={onRestart}>
          {i18n("MfaEnterPasscodeForm__RESTART")}
        </Link>
        <Link {...getNeedHelpLinkProps()}>{i18n("MfaContactSelectForm__NEED_HELP")}</Link>
        <SubmitFormField {...getSubmitProps()}>
          {i18n("MfaEnterPasscodeForm__SUBMIT")}
        </SubmitFormField>
      </Form>
      {renderResendAriaLive()}
    </CardGrid>
  );

  function renderResendAriaLive() {
    let ariaLiveText = "";

    if (
      passwordResendSuccess &&
      secondsUntilResend > mfaResendDelayInSeconds - mfaResendAriaLiveDelay
    ) {
      ariaLiveText = i18n(`MfaEnterPasscodeForm__RESEND_SUCCESS_${contact.deviceType}`);
    } else if (secondsUntilResend <= 0) {
      ariaLiveText = i18n("MfaEnterPasscodeForm__RESEND_LINK_ENABLED_ARIA_LIVE");
    } else if (secondsUntilResend > 0) {
      ariaLiveText = i18n("MfaEnterPasscodeForm__RESEND_TIMER_ARIA", {
        seconds: Math.ceil(secondsUntilResend / mfaResendAriaLiveDelay) * mfaResendAriaLiveDelay,
      });
    }

    return <AriaLive>{ariaLiveText}</AriaLive>;
  }

  function renderResendLink() {
    return (
      <span>
        <Link disabled={loading || secondsUntilResend > 0} onClick={onResend}>
          {i18n("MfaEnterPasscodeForm__RESEND_PASSCODE")}
        </Link>
        <span aria-hidden>(</span>
        <span
          role="timer"
          aria-label={i18n("MfaEnterPasscodeForm__RESEND_TIMER_ARIA", {
            seconds: secondsUntilResend,
          })}
        >
          {swaDate.duration(secondsUntilResend, "seconds").format("m:ss")}
        </span>
        <span aria-hidden>)</span>
      </span>
    );
  }

  function getPasscodeProps() {
    return {
      className: styles.fullWidth,
      componentProps: {
        maxLength: fieldMaxLengths.passcode,
      },
      disabled: loading,
      label: i18n("MfaEnterPasscodeForm__PASSCODE"),
      name: "passcode",
    };
  }

  function getNeedHelpLinkProps() {
    return {
      className: classNames(styles.fullWidth, styles.needHelp),
      disabled: loading,
      href: loading ? "" : MFA_HELP,
    };
  }

  function getSubmitProps() {
    return {
      className: styles.fullWidth,
      componentProps: {
        fullWidth: true,
        submitInProgress: loading,
      },
      name: "submit",
    };
  }

  function getRequestPasscodeIconProps() {
    return {
      "aria-label": i18n("MfaEnterPasscodeForm__RESEND_SUCCESS_ICON_ARIA_LABEL"),
      className: styles.icon,
      name: "Info2",
      size: "size20",
    };
  }
};

MfaEnterPasscodeForm.propTypes = {
  /**
   * The contact information for the user.
   */
  contact: PropTypes.shape({
    deviceType: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,

  /**
   * Indicates if the form is in a loading state.
   */
  loading: PropTypes.bool,

  /**
   * Callback function to handle resending the passcode.
   */
  onResend: PropTypes.func.isRequired,

  /**
   * Callback function to handle restarting the MFA process.
   */
  onRestart: PropTypes.func.isRequired,

  /**
   * Callback function to handle form submission.
   */
  onSubmit: PropTypes.func.isRequired,

  /**
   * Indicates if the passcode resend was successful.
   */
  passwordResendSuccess: PropTypes.bool,

  /**
   * The validation i18n error key to display.
   */
  validationErrorKey: PropTypes.string,
};
