import PropTypes from "prop-types";
import React from "react";

export const Laptop = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <polygon points="29.9,15.2 22,8 22.9,18.5 25.1,16.2 26.9,19.3 28.3,18.4 26.7,15.5" />
      <path d="M45,23.8H6.2V3.1H45V23.8z M45.5,0H5.7C4.4,0,3.2,1.3,3.2,2.6v24.3h44.9V2.5C48,1.3,46.8,0,45.5,0" />
      <path d="M48,31.6h-2.9v-0.7H48V31.6z M32,29.8c-0.2,0.5-0.8,0.9-1.3,0.9h-8.3c-0.6,0-1.1-0.4-1.3-0.9h-21 c0,1.5,1.5,3,3,3h45.2c1.5,0,3-1.5,3-3H32z" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "0 0 51.2 32.8",
    };
  }
};

Laptop.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Laptop.defaultProps = {
  role: "img",
};
