import PropTypes from "prop-types";
import React from "react";

import { classNames } from "@swa-ui/string";

import colors from "../../defines/colors";

/**
 * Airlines renders the text "Airlines" to accompany the Southwest logo.
 */

const Airlines = (props) => {
  const { className, fillColor, height, role, style, width } = props;

  return (
    <svg {...getProps()}>
      <path d="M5,5.74 h-3.25 L0.89,8 h-1 L3,0 h.89 L6.9,8 h-1 Z m-1.5-4 a1.76,1.76,0,0,1-.12-.45 h0 a1.66,1.66,0,0,1-.12.45 l-1.2,3.14 h2.66 Z" />
      <path d="M7.37,0.74 a.65.65,0,1,1,.66.64 h0 a.63.63,0,0,1-.65-.61 Z m.18,1.63 h.93 V8 h-.93 Z" />
      <path d="M13,2.36 l-.12.88 a2.82,2.82,0,0,0-.65-.08,2.08,2.08,0,0,0-1.29.42 V8 h-.93 V2.4 h.62 a.75.75,0,0,1,.16.35,2.61,2.61,0,0,1,1.46-.44 A3.16,3.16,0,0,1,13,2.36Z" />
      <path d="M14,0 h.93 V8 H14 Z" />
      <path d="M16.37,0.74 a.65.65,0,1,1,.66.64 h0 a.63.63,0,0,1-.65-.61Z m.18,1.63 h.93 V8 h-.93 Z" />
      <path d="M23.72,4.48 V8 h-.93 V4.73 c0-1.25-.43-1.61-1.3-1.61 a3,3,0,0,0-1.5.42 V8 h-.93 V2.4 h.64 a.61.61,0,0,1,.14.29,4.05,4.05,0,0,1,1.81-.43 C23.06,2.23,23.72,3,23.72,4.48 Z" />
      <path d="M30,5.43 h-4.18 a1.79,1.79,0,0,0,1.72,1.86 h.22 a4.27,4.27,0,0,0,1.72-.38 l.28.76 a5.11,5.11,0,0,1-2,.44,2.94,2.94,0,0,1-.12-5.87,2.34,2.34,0,0,1,2.44,2.23,2.26,2.26,0,0,1,0,.37 A5,5,0,0,0,30,5.43Z m-.9-.73 A1.45,1.45,0,0,0,27.86,3 a1.08,1.08,0,0,0-.31,0,1.72,1.72,0,0,0-1.74,1.68 h3.26 Z" />
      <path d="M32.39,8.1 a4.2,4.2,0,0,1-1.68-.33 l.14-.83 a3.74,3.74,0,0,0,1.51.34 c.8,0,1.2-.27,1.2-.82s-.42-.69-1.34-1 c-1.18-.39-1.48-.94-1.48-1.66,0-1,.79-1.56,2.06-1.56 a3.4,3.4,0,0,1,1.44.28 l-.18.78 a3.3,3.3,0,0,0-1.24-.24 c-.82,0-1.16.33-1.16.75 s.35.69,1.25,1 c1.09.37,1.56.8,1.56,1.64 S33.78,8.1,32.39,8.1 Z" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className: classNames(className),
      fill: fillColor,
      height,
      role,
      style,
      viewBox: "-1 -1 36 10",
      width,
    };
  }
};

Airlines.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Color name from color palette or name of an entry in color theme file, themes.js. */
  fillColor: PropTypes.oneOfType([PropTypes.oneOf(Object.keys(colors)), PropTypes.string]),

  /** Indicates SVG's height. The width, if not given, will be sized according to SVG's aspect ratio. */
  height: PropTypes.string,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates SVG's width. The height, if not given, will be sized according to SVG's aspect ratio. */
  width: PropTypes.string,
};

Airlines.defaultProps = {
  height: "32",
  role: "img",
};

export { Airlines };
