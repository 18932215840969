import PropTypes from "prop-types";
import React from "react";

/**
 * ContextVisualProvider provides a function `getContexts` which traverses the React FiberNode tree to find all
 * context providers and their values, to pass along to a developer tool to visualize context values.
 */

export const ContextVisualContext = React.createContext(null);
export const ContextVisualProvider = (props) => {
  const { children } = props;

  return (
    <ContextVisualContext.Provider value={{ getContexts }}>
      {children}
    </ContextVisualContext.Provider>
  );

  function getContextNodesFromChildren(childrenList = []) {
    const parentComponent = React.Children.only(childrenList)._owner;
    const childrenNodes = parentComponent ? traverseChildren(parentComponent) : [];

    return childrenNodes.filter(
      (node) => node?.elementType?._context?.displayName?.endsWith("Context") ?? false
    );

    function traverseChildren(node, accumulatedChildren = []) {
      let componentSiblings = [];

      if (node.sibling) {
        componentSiblings = traverseChildren(node.sibling, []);
      }

      return node.child
        ? traverseChildren(node.child, [...accumulatedChildren, ...componentSiblings, node])
        : [...accumulatedChildren, ...componentSiblings];
    }
  }

  function getContexts() {
    const contextNodes = getContextNodesFromChildren(children);

    return contextNodes.map((provider) => {
      const { elementType } = provider;

      return {
        context: elementType?._context,
        displayName: elementType?._context.displayName,
        memoizedValues: provider.memoizedProps?.value,
      };
    });
  }
};

ContextVisualProvider.propTypes = {
  /** Content that will be rendered. */
  children: PropTypes.node.isRequired,
};
