import React from "react";

import { Heading, Layout } from "@swa-ui/core";
import i18n from "@swa-ui/locale";

import { ManageMyVacationsForm } from "../ManageMyVacationsForm/ManageMyVacationsForm";

export const ManageMyVacationsWidget = () => (
  <>
    <Layout {...getLayoutProps()}>
      <Heading {...getHeadingProps()}>{i18n("ManageMyVacationsWidget__HEADER_TEXT")} </Heading>
    </Layout>
    <ManageMyVacationsForm />
  </>
);

function getLayoutProps() {
  return {
    center: true,
    top: "xlarge",
  };
}

function getHeadingProps() {
  return {
    headingLevel: 2,
  };
}
