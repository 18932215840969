import PropTypes from "prop-types";
import React from "react";

import { classNames } from "@swa-ui/string";

import styles from "./Grid.module.scss";

/**
 * Grid will automatically provide a right-sized container for the current device's viewport with
 * the correct width and column definitions. If only single column is needed, specify marginsOnly.
 */

export const Grid = (props) => {
  const { children, className, role, style } = props;

  return (
    <div className={getClass()} role={role} style={style}>
      {children}
    </div>
  );

  function getClass() {
    return classNames(className, styles.grid);
  }
};

Grid.propTypes = {
  /** The content that will be displayed when Grid is rendered. */
  children: PropTypes.node.isRequired,

  /** Additional classes for positioning the component. */
  className: PropTypes.string,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Additional CSS styling to be applied to outermost Grid container. */
  style: PropTypes.object,
};
