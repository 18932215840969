import PropTypes from "prop-types";
import React from "react";

export const HotelCircle = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <rect x="-457.1" y="279.4" className="st0" width="1.8" height="1.8" />
      <rect x="-460.3" y="276.2" className="st0" width="1.8" height="1.8" />
      <rect x="-460.3" y="282.6" className="st0" width="1.8" height="1.8" />
      <rect x="-457.1" y="276.2" className="st0" width="1.8" height="1.8" />
      <rect x="-460.3" y="279.4" className="st0" width="1.8" height="1.8" />
      <rect x="-450.8" y="282.6" className="st0" width="1.8" height="1.8" />
      <path
        className="st0"
        d="M-454.6,268.2c-6.8,0-12.4,5.5-12.4,12.4s5.5,12.4,12.4,12.4s12.4-5.5,12.4-12.4S-447.8,268.2-454.6,268.2z
		 M-447.2,286.2L-447.2,286.2h-6.4h-1.4h0v-3.6h-1.8v3.6h0v0h-4.6c-0.3,0-0.4-0.2-0.4-0.5c0-0.3,0.2-0.5,0.4-0.5v-9.9
		c-0.3,0-0.4-0.2-0.4-0.5c0-0.3,0.2-0.5,0.4-0.5l1.4,0v-0.2c0-0.3,0.2-0.5,0.5-0.5h1.3v-0.2c0-0.3,0.2-0.5,0.5-0.5h0.4
		c0.3,0,0.5,0.2,0.5,0.5v0.2h1.3c0.3,0,0.5,0.2,0.5,0.5v0.2l1.4,0c0.3,0,0.5,0.2,0.5,0.5c0,0.3-0.2,0.5-0.4,0.5v2.6h5.9
		c0.3,0,0.5,0.2,0.5,0.5v6.8c0.3,0,0.4,0.2,0.4,0.5C-446.8,286-447,286.2-447.2,286.2z"
      />
      <rect x="-454" y="282.6" className="st0" width="1.8" height="1.8" />
      <rect x="-450.8" y="279.4" className="st0" width="1.8" height="1.8" />
      <polygon
        className="st0"
        points="-452.5,279.4 -454,279.4 -454,281.2 -452.1,281.2 -452.1,279.4 -452.5,279.4"
      />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "-467 268.2 24.8 24.8",
    };
  }
};

HotelCircle.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

HotelCircle.defaultProps = { role: "img" };
