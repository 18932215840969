import PropTypes from "prop-types";
import React from "react";

export const Points = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path
        strokeWidth={0}
        d="M20.4,6c8,0,14.4,6.4,14.4,14.4s-6.4,14.4-14.4,14.4c-8,0-14.4-6.4-14.4-14.4c0,0,0,0,0,0
			C6,12.4,12.4,6,20.4,6C20.4,6,20.4,6,20.4,6z M26.7,16.6c-0.5,0-1,0.1-1.4,0.3c-0.4,0.2-0.7,0.4-0.9,0.7c-0.2,0.3-0.3,0.7-0.3,1
			c0,0.6,0.2,1.1,0.7,1.5c0.5,0.4,1,0.6,1.6,0.7l1.2,0.3c0.2,0.1,0.4,0.2,0.5,0.3c0.1,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.3,0.7
			c-0.3,0.2-0.7,0.3-1.1,0.3c-0.4,0-0.8-0.1-1.1-0.3c-0.2-0.2-0.4-0.5-0.5-0.8v-0.2l-1.4,0.1c0,0.7,0.3,1.4,0.8,1.9
			c0.6,0.4,1.3,0.7,2.1,0.6c0.5,0,1.1-0.1,1.6-0.3c0.4-0.2,0.7-0.4,1-0.8c0.2-0.4,0.4-0.8,0.3-1.3c0-0.4-0.1-0.8-0.3-1.1
			c-0.2-0.3-0.5-0.6-0.8-0.7c-0.5-0.2-1-0.4-1.6-0.5c-0.5-0.1-0.9-0.2-1.3-0.5c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.2,0.1-0.3,0.2-0.4
			c0.3-0.2,0.6-0.3,1-0.3c0.3,0,0.6,0.1,0.9,0.2c0.2,0.2,0.3,0.4,0.4,0.6v0.2h1.5c0-0.6-0.3-1.2-0.7-1.6
			C28.1,16.8,27.4,16.6,26.7,16.6z M13.6,16.7h-2.3V24h1.5v-2.7h0.9c0.5,0,1,0,1.5-0.1c0.3-0.1,0.5-0.2,0.8-0.3
			c0.3-0.2,0.5-0.4,0.6-0.7c0.2-0.3,0.3-0.7,0.2-1.1c0-0.5-0.1-1-0.4-1.4c-0.3-0.3-0.6-0.6-1-0.7c-0.5-0.1-1-0.1-1.5-0.1L13.6,16.7z
			 M23.2,16.7h-5.6V18h2.1v6H21v-6h2.1L23.2,16.7z M16.8,3.1c2.3,0,4.6,0.6,6.7,1.7c-1-0.2-2-0.3-3.1-0.3c-8.7,0-15.8,7.1-15.8,15.8
			l0,0c0,1.1,0.1,2.1,0.3,3.2c-1.1-2-1.7-4.3-1.7-6.7C3.1,9.3,9.1,3.2,16.8,3.1C16.7,3.1,16.7,3.1,16.8,3.1z M13.5,18
			c0.4,0,0.7,0,1.1,0c0.2,0,0.4,0.1,0.6,0.3c0.2,0.2,0.2,0.4,0.2,0.7c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.3,0.3-0.5,0.3
			C14.3,20,13.9,20,13.6,20h-0.8v-2L13.5,18z M13.2,0.2c2.3,0,4.5,0.6,6.4,1.7C11.4,0.4,3.5,5.8,1.9,14.1c-0.3,1.8-0.3,3.7,0,5.5
			C-1.6,13.4,0.5,5.5,6.7,1.9C8.7,0.8,10.9,0.2,13.2,0.2z"
      />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      style,
      viewBox: "0 0 35 35",
    };
  }
};

Points.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Points.defaultProps = {
  role: "img",
};
