import PropTypes from "prop-types";
import React from "react";

import { Layout } from "@swa-ui/core";
import { classNames } from "@swa-ui/string";

import styles from "./ItinerarySegment.module.scss";

const SIZES = ["large", "medium", "small", "xlarge", "xsmall"];

/**
 * ItinerarySegment renders a block level element with children using given styling such as bold.
 */
export const ItinerarySegment = (props) => {
  const { bold, bottomPadding, capital, children, className, emphasis, size, topPadding } = props;

  return <Layout {...getProps()}>{children}</Layout>;

  function getProps() {
    return {
      bottom: bottomPadding,
      className: getClass(),
      top: topPadding,
    };
  }

  function getClass() {
    return classNames(className, {
      [styles.bold]: bold,
      [styles[capital]]: capital,
      [styles[emphasis]]: emphasis,
      [styles[size]]: size,
    });
  }
};

export const itinerarySegmentPropTypes = {
  /** Font weight is bold or not. */
  bold: PropTypes.bool,

  /** Margin to be applied to element bottom. */
  bottomMargin: PropTypes.oneOf(SIZES),

  /** Children text can have the case translated. */
  capital: PropTypes.oneOf(["sentence", "uppercase"]),

  /** Content that will be rendered. */
  children: PropTypes.string,

  /** Classes for positioning the component. */
  className: PropTypes.string,

  /** Children text can have the color treatment of dark, normal or light. */
  emphasis: PropTypes.oneOf(["emphasis", "normal", "subtle"]),

  /** Children text can one of the preset sizes. */
  size: PropTypes.oneOf(SIZES),

  /** Margin to be applied to element top. */
  topMargin: PropTypes.oneOf(SIZES),
};

ItinerarySegment.propTypes = itinerarySegmentPropTypes;
ItinerarySegment.defaultProps = {
  bold: false,
  emphasis: "normal",
  size: "small",
};
