import React, { useContext, useEffect, useState } from "react";

import { window } from "@swa-ui/browser";
import { Dialog, Toggle, useDialog } from "@swa-ui/core";
import { filter } from "@swa-ui/object";
import { classNames } from "@swa-ui/string";

import { AppSettingsContext } from "../AppSettingsProvider";
import styles from "./ManageAppSettings.module.scss";

/**
 * ManageAppSettings displays a dialog when Shift + F4 is pressed that allows a user to override
 * an app's settings (toggles only for now). Any changes made will persist until Reset Changes is clicked.
 */

export const ManageAppSettings = () => {
  const { defaultAppSettings, overrideAppSettings } = useContext(AppSettingsContext);
  const { dialogRef, hideDialog, isDialogVisible, showDialog } = useDialog("center");
  const [toggleSettings, setToggleSettings] = useState(getToggleSettings());

  useEffect(() => {
    window.addEventListener("keyup", manageAppSettingsOnShiftF4); // NOSONAR

    return () => {
      window.removeEventListener("keyup", manageAppSettingsOnShiftF4);
    };
  }, []);

  useEffect(() => {
    setToggleSettings(getToggleSettings());
  }, [defaultAppSettings]);

  return <Dialog {...getDialogProps()}>{renderToggles()}</Dialog>;

  function getToggleSettings() {
    return filter(defaultAppSettings, (value) => {
      return typeof value === "boolean";
    });
  }

  function manageAppSettingsOnShiftF4(event) {
    if (event.keyCode === 115 && event.shiftKey) {
      showDialog();
    }
  }

  function renderToggles() {
    return Object.entries(toggleSettings).map(([key, value], index) => (
      <div
        className={classNames(styles.toggleRow, { [styles.zebra]: index % 2 === 0 })}
        key={`${key}-row`}
      >
        <div>{key}</div>
        <Toggle {...getToggleProps(key, value)} />
      </div>
    ));
  }

  function getDialogProps() {
    return {
      buttonGroupProps: {
        buttonList: [
          {
            label: "Reset Changes",
            props: {
              name: "resetChanges",
              onClick: handleResetChanges,
              styleType: "secondary",
              type: "submit",
            },
          },
          {
            label: "Apply",
            props: {
              name: "apply",
              onClick: handleApplySettings,
              styleType: "primary",
              type: "submit",
            },
          },
        ],
      },
      dialogRef,
      heading: "Manage App Settings",
      onClose: hideDialog,
      portal: false,
      revealed: isDialogVisible,
      showClose: true,
      width: "medium",
    };
  }

  function getToggleProps(toggleKey, toggleValue) {
    return {
      "aria-label": toggleKey,
      checked: toggleValue,
      onChange: (newToggleValue) => handleToggleChange(toggleKey, newToggleValue),
    };
  }

  function handleToggleChange(toggleKey, toggleValue) {
    setToggleSettings({
      ...toggleSettings,
      [toggleKey]: toggleValue,
    });
  }

  function handleApplySettings() {
    overrideAppSettings(toggleSettings);
    hideDialog();
  }

  function handleResetChanges() {
    overrideAppSettings({});
    hideDialog();
  }
};
