export const gradientConfig = {
  "bold-blue": [
    {
      designToken: "--gradient-bold-blue-stop-1",
      percentage: 0,
    },
    {
      designToken: "--gradient-bold-blue-stop-2",
      percentage: 40,
    },
    {
      designToken: "--gradient-bold-blue-stop-3",
      percentage: 100,
    },
  ],
  "loading-light": [
    {
      designToken: "--gradient-loading-light-stop-1",
      percentage: 0,
    },
    {
      designToken: "--gradient-loading-light-stop-2",
      percentage: 100,
    },
  ],
  "loading-medium": [
    {
      designToken: "--gradient-loading-medium-stop-1",
      percentage: 0,
    },
    {
      designToken: "--gradient-loading-medium-stop-2",
      percentage: 100,
    },
  ],
  "summit-silver": [
    {
      designToken: "--gradient-summit-silver-stop-1",
      percentage: 0,
    },
    {
      designToken: "--gradient-summit-silver-stop-2",
      percentage: 100,
    },
  ],
  "sunrise-yellow": [
    {
      designToken: "--gradient-sunrise-yellow-stop-1",
      percentage: 0,
    },
    {
      designToken: "--gradient-sunrise-yellow-stop-2",
      percentage: 100,
    },
  ],
  turquoise: [
    {
      designToken: "--gradient-turquoise-stop-1",
      percentage: 0,
    },
    {
      designToken: "--gradient-turquoise-stop-2",
      percentage: 100,
    },
  ],
  "warm-red": [
    {
      designToken: "--gradient-warm-red-stop-1",
      percentage: 0,
    },
    {
      designToken: "--gradient-warm-red-stop-2",
      percentage: 100,
    },
  ],
};

export const gradientPropTypes = Object.keys(gradientConfig);
