import PropTypes from "prop-types";
import React from "react";

import { classNames } from "@swa-ui/string";

import { HtmlValue } from "../HtmlValue";
import styles from "./TextBlock.module.scss";

/**
 * TextBlock renders content as a text block.
 */

export const TextBlock = (props) => {
  const { additionalProps = {}, text } = props;
  const { light } = additionalProps;

  return (
    <>
      {text.map((textItem, index) => (
        <HtmlValue
          className={classNames({ [styles.light]: light })}
          htmlValue={textItem.value}
          key={index}
        />
      ))}
    </>
  );
};

TextBlock.propTypes = {
  /**
   * Additional props that can include configuration for styling, such as `light` to indicate
   * whether the app is using a dark Header background.
   */
  additionalProps: PropTypes.object,

  /** Content to be rendered. */
  text: PropTypes.arrayOf(PropTypes.object),
};
