import { window } from "@swa-ui/browser";

const environmentTierTypes = [
  {
    id: "prod",
    hostMatches: /^(www|espanol|mobile|mobile-offline)\.(southwest|swabiz)\.com$/,
  },
  {
    id: "cstg",
    hostMatches: /^(espanol-)?(southwest|swabiz|mobile)\.cstg(\d+).qa\.southwest\.com$/,
  },
  {
    id: "efix",
    hostMatches: /^(espanol-)?(southwest|swabiz|mobile)\.efix(\d+).qa\.southwest\.com$/,
  },
  {
    id: "qa",
    hostMatches: /^(espanol-)?(southwest|swabiz|mobile)\.qa(\d+)\.southwest\.com$/,
  },
  {
    id: "ptest",
    hostMatches: /^(espanol-)?(southwest|swabiz|mobile)\.ptest(\d+).qa\.southwest\.com$/,
  },
  {
    id: "dev",
    hostMatches: /^(espanol-)?(southwest|swabiz|mobile)\.dev(\d+)\.southwest\.com$/,
  },
  {
    id: "local",
    hostMatches: /.*/,
  },
];

export const getEnvironmentTier = () => {
  const { hostname } = window?.location ?? { hostname: "www.southwest.com" };
  const environmentTierType = environmentTierTypes.find((tierType) =>
    tierType.hostMatches.test(hostname)
  );

  if (!environmentTierType || !environmentTierType?.id) {
    throw `No environment tier found for ${hostname}`;
  }

  return environmentTierType.id;
};
