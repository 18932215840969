import PropTypes from "prop-types";
import React from "react";

import { classNames } from "@swa-ui/string";

import "../../generated/platforms/scss/vacations-light-inverse.module.scss";
import "../../generated/platforms/scss/vacations.module.scss";
import { DesignTokensThemeProvider } from "../DesignTokensThemeProvider";

/**
 * Theme for SWA Vacations light inverse styles.
 */

export const VacationsLightInverseTheme = (props) => {
  const { children } = props;

  return <DesignTokensThemeProvider {...getProps()}>{children}</DesignTokensThemeProvider>;

  function getProps() {
    return {
      className: classNames("vacations-light-inverse-theme", "vacations-theme"),
      inverse: true,
      modeName: "light",
      themeName: "vacations",
    };
  }
};

VacationsLightInverseTheme.propTypes = {
  /** Content that will be rendered within the theme. */
  children: PropTypes.node,
};
