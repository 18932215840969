import PropTypes from "prop-types";
import React from "react";

export const RentalCompany = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path
        d="M22,5.6l1.8-1.4c0.7-0.6,1.7-0.5,2.4,0.1l6.1,6.1c0.6,0.6,0.7,1.7,0.1,2.3l-1.5,1.7L22,5.6z M28.9,22.6
		l6.7-8.3c1.3-1.5,1.2-3.8-0.2-5.2l-7.9-7.9c-1.4-1.4-3.7-1.5-5.3-0.3l-8.6,6.5c-1.7,1.4-1.9,4.1-0.3,5.7l9.7,9.7
		C24.8,24.4,27.4,24.3,28.9,22.6 M20.4,22.7L7.8,35.3l-5.1,0.9L1.5,35l12.1-12.1l-1.5-1.5L0,33.5l0.9-5l12.6-12.7L20.4,22.7z"
      />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "0 0 36.6 36.2",
    };
  }
};

RentalCompany.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

RentalCompany.defaultProps = {
  role: "img",
};
