import PropTypes from "prop-types";
import React from "react";

import { classNames } from "@swa-ui/string";

import styles from "./ConfirmationNumber.module.scss";

/**
 * Displays a formatted confirmation number for an itinerary. The label of "Confirmation #" can be optionally hidden.
 */

export const ConfirmationNumber = (props) => {
  const { ariaLabel, className, label, labelClassName, number, prefix, showLabelColon, size } =
    props;

  return (
    <div
      aria-label={getAriaLabel()}
      className={classNames(className, styles.confirmationNumber)}
      role={"none"}
    >
      {label && (
        <p className={classNames([styles.labelBase, labelClassName ?? styles.label])}>
          {label}
          {showLabelColon && ":"}
        </p>
      )}
      <span className={getNumberClass()}>{renderNumber()}</span>
    </div>
  );

  function renderNumber() {
    return (
      <>
        {prefix && <span className={styles.numberPrefix}>{prefix}</span>}
        <span tabIndex="0">{number.toUpperCase()}</span>
      </>
    );
  }

  function getNumberClass() {
    return classNames(styles.number, {
      [styles.large]: size === "large",
      [styles.small]: size === "small",
    });
  }

  function getAriaLabel() {
    const formattedNumber = number.toUpperCase();
    let ariaString = `${formattedNumber.split("").join(" ")}`;

    if (ariaLabel) {
      ariaString = `${ariaLabel} ${ariaString}`;
    } else if (label) {
      ariaString = `${label} ${ariaString}`;
    }

    return ariaString;
  }
};

ConfirmationNumber.propTypes = {
  /** Text to display as the label for the aria-label if it needs to be different from the provided label. */
  ariaLabel: PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Text to display as the label for the confirmation number. If no label is provided, none will be rendered. */
  label: PropTypes.string,

  /** Classname to apply to the confirmation number label. */
  labelClassName: PropTypes.string,

  /** The flight number string that should be displayed. */
  number: PropTypes.string.isRequired,

  /** Prefix for confimation number. */
  prefix: PropTypes.string,

  /** Indicates whether or not to show the colon after the label. */
  showLabelColon: PropTypes.bool,

  /** Size for confimation number. */
  size: PropTypes.oneOf(["large", "small"]),
};

ConfirmationNumber.defaultProps = {
  showLabelColon: true,
  size: "large",
};
