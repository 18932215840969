import PropTypes from "prop-types";
import React from "react";

export const Six = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path
        strokeWidth={0}
        d="M19,0C8.5,0,0,8.5,0,19s8.5,19,19,19s19-8.5,19-19S29.5,0,19,0z M22.8,25.6c-0.9,1-2.2,1.5-3.6,1.5
			c-1.6,0-2.9-0.6-3.9-1.9c-1-1.2-1.5-3.3-1.5-6.1c0-2.9,0.5-5,1.6-6.2c1.1-1.3,2.5-1.9,4.2-1.9c1.2,0,2.2,0.3,3,1s1.3,1.6,1.5,2.9
			L21,15.2c-0.1-0.6-0.3-1-0.6-1.3c-0.3-0.3-0.7-0.4-1.2-0.4c-0.6,0-1.2,0.3-1.6,0.9c-0.4,0.6-0.7,1.8-0.8,3.6
			c0.8-0.9,1.7-1.3,2.8-1.3c1.3,0,2.3,0.5,3.2,1.4c0.9,1,1.3,2.2,1.3,3.7C24.3,23.3,23.8,24.6,22.8,25.6z"
      />
      <path
        strokeWidth={0}
        d="M19.2,18.8c-0.6,0-1.1,0.2-1.5,0.7s-0.6,1.1-0.6,2c0,1,0.2,1.7,0.7,2.3s1,0.8,1.5,0.8c0.6,0,1-0.2,1.4-0.7
			c0.4-0.4,0.6-1.2,0.6-2.1c0-1-0.2-1.8-0.6-2.3C20.3,19,19.8,18.8,19.2,18.8z"
      />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      style,
      viewBox: "0 0 38 38",
    };
  }
};

Six.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Six.defaultProps = {
  role: "img",
};
