import PropTypes from "prop-types";
import React, { useContext } from "react";

import { AuthContext, useUserInfo } from "@swa-ui/authentication";
import { getBootstrapData } from "@swa-ui/bootstrap";
import { Badge, HorizontalList } from "@swa-ui/core";
import { Currency } from "@swa-ui/currency";
import i18n from "@swa-ui/locale";
import { PersonasContext } from "@swa-ui/personas";
import { classNames } from "@swa-ui/string";

import styles from "./CustomerInformation.module.scss";

/**
 * CustomerInformation provides the Customer's account info for the header.
 */

export const CustomerInformation = (props) => {
  const { accountLinkText, light, separator } = props;
  const { getCorporateInfo, getCustomerInfo, isMemberAccount } = useUserInfo();
  const { handleLogout, isLoggedIn } = useContext(AuthContext);
  const { firstName, preferredName, redeemablePoints, tier, tierStatusPending } =
    getCustomerInfo() ?? {};
  const { name: companyName } = getCorporateInfo() ?? {};
  const { LOYALTY__MY_ACCOUNT__INDEX } = getBootstrapData("urls");
  const loggedIn = isLoggedIn();
  const { isVacationsPersona } = useContext(PersonasContext);
  const name = preferredName || firstName;
  const shouldShowTier = (isMemberAccount() && tier !== "NON_ELITE") || tierStatusPending;
  const tierBadgeTextKey = tierStatusPending
    ? "DesktopHeaderControl__TIER_PENDING"
    : tier === "A_LIST_PREFERRED"
    ? "DesktopHeaderControl__TIER_A_LIST_PREFERRED"
    : "DesktopHeaderControl__TIER_A_LIST";

  return (
    <>
      {shouldShowTier && renderTierBadge()}
      {separator ? renderListsWithSeparator() : renderListsWithoutSeparator()}
    </>
  );

  function renderListsWithSeparator() {
    return (
      <HorizontalList
        items={[...getCustomerInfoItems(), ...getActionItems()]}
        {...getCommonListProps()}
      />
    );
  }

  function renderListsWithoutSeparator() {
    return (
      <>
        <HorizontalList
          items={getCustomerInfoItems()}
          separator={false}
          {...getCommonListProps()}
        />
        <HorizontalList items={getActionItems()} {...getCommonListProps()} />
      </>
    );
  }
  function renderTierBadge() {
    let badge;

    if (isVacationsPersona) {
      badge = <span className={styles.tierBadgeText}>{i18n(tierBadgeTextKey)}</span>;
    } else {
      badge = (
        <Badge height="xsmall" styleType="warning" type="flag">
          {i18n(tierBadgeTextKey)}
        </Badge>
      );
    }

    return badge;
  }

  function getCommonListProps() {
    return {
      light,
      spacing: "small",
    };
  }

  function getCustomerInfoItems() {
    const items = [];

    name &&
      items.push({
        componentProps: {
          children: (
            <span {...getItemWrapperProps(styles.greeting)}>
              {i18n("DesktopHeaderControl__GREETING", { name })}
            </span>
          ),
          emphasis: false,
        },
        tagName: "span",
      });
    isMemberAccount() &&
      items.push({
        componentProps: {
          children: (
            <span {...getItemWrapperProps()}>
              <Currency {...getCurrencyProps(redeemablePoints)} />
            </span>
          ),
          emphasis: false,
        },
        tagName: "span",
      });
    companyName &&
      items.push({
        componentProps: {
          children: <span {...getItemWrapperProps()}>{companyName}</span>,
          emphasis: false,
        },
        tagName: "span",
      });

    return items;
  }

  function getActionItems() {
    const items = [];

    loggedIn &&
      items.push({
        componentProps: {
          children: i18n(accountLinkText),
          href: LOYALTY__MY_ACCOUNT__INDEX,
          size: "fontSize13",
        },
      });
    items.push({
      componentProps: {
        children: i18n("DesktopHeaderControl__LOG_OUT"),
        onClick: handleLogout,
        size: "fontSize13",
      },
    });

    return items;
  }

  function getItemWrapperProps(className) {
    return {
      className: classNames({ [styles.itemWithSeparator]: separator }, className),
    };
  }

  function getCurrencyProps(value) {
    return {
      currencyCode: "POINTS",
      customLabel: i18n("DesktopHeaderControl__POINTS"),
      emphasis: false,
      showSymbol: false,
      value,
    };
  }
};

CustomerInformation.propTypes = {
  /** Content to be rendered on the page. */
  accountLinkText: PropTypes.string.isRequired,

  /** Indicates whether the app is using a dark Header background, to be passed to Link component for styling. */
  light: PropTypes.bool,

  /** Indicates if a separator should be rendered between customer info items */
  separator: PropTypes.bool,
};
