import PropTypes from "prop-types";
import React, { useContext } from "react";

import { ConfigurationContext } from "./ConfigurationContext";

/**
 * ConfigurationProvider provides all configuration settings in a React context
 * so the configuration settings is available to components in an app's render
 * tree.
 */

export const ConfigurationProvider = (props) => {
  const { children, configuration } = props;
  const contextConfiguration = useContext(ConfigurationContext);

  return (
    <ConfigurationContext.Provider value={{ ...contextConfiguration, ...configuration }}>
      {children}
    </ConfigurationContext.Provider>
  );
};

export const configurationProviderTypes = {
  /** App that will be provided with configuration values. */
  children: PropTypes.node.isRequired,

  /** Configuration object used to override global values with local values. */
  configuration: PropTypes.object,
};

ConfigurationContext.displayName = "ConfigurationContext";
ConfigurationProvider.propTypes = configurationProviderTypes;
