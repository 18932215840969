import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { classNames } from "@swa-ui/string";

import { Button } from "../Button";
import { Icon } from "../Icon";
import { Width } from "../Width";
import styles from "./CheckButton.module.scss";

/**
 * CheckButton provides a button that shows a check mark when selected. Much of the heavy lifting is done by Button.
 * This component simply sets up the correct icon values as part of the child content. All this could of been added to
 * Button, but wanted to avoid complicating that very common component with this edge case.
 */

export const CheckButton = React.forwardRef((props, ref) => {
  const {
    checked,
    children,
    className,
    defaultValue,
    disabled,
    fullWidth,
    iconAlwaysVisible,
    name,
    onBlur,
    onChange,
    onClick,
  } = props;
  const [animate, setAnimate] = useState(false);
  const [currentValue, setCurrentValue] = useState(defaultValue);
  const [transformationStep, setTransformationStep] = useState("");

  useEffect(() => {
    if (checked !== undefined) {
      setCurrentValue(checked);
    } else {
      setCurrentValue(defaultValue);
    }
  }, [checked]);

  useEffect(() => {
    setTransformationStep("idle");
  }, []);

  return (
    <div className={classNames(className, { [styles.disabled]: disabled })}>
      <Button {...getButtonProps()}>
        <span className={getInnerContainerClass()}>
          <Width {...getWidthProps()}>
            <span className={getIconOuterContainerClass()}>
              <span className={getIconContainerClass()}>
                <Icon {...getIconProps()} />
              </span>
            </span>
          </Width>
          <span className={styles.content}>{children}</span>
        </span>
      </Button>
    </div>
  );

  function getButtonProps() {
    return {
      "aria-controls": props["aria-controls"],
      "aria-describedby": props["aria-describedby"],
      "aria-expanded": props["aria-expanded"],
      "aria-label": props["aria-label"],
      "aria-labelledby": props["aria-labelledby"],
      className: getButtonClass(),
      disabled,
      fullWidth,
      name,
      onBlur,
      onChange,
      onClick: handleClick,
      ref,
      ripple: false,
      role: "checkbox",
      styleType: "no-style",
      type: "button",
    };
  }

  function getWidthProps() {
    return {
      animate,
      className: classNames({ [styles.width]: currentValue }),
    };
  }

  function getIconProps() {
    const custom = {
      type: !currentValue && transformationStep !== "setup" ? "inactive" : "active",
    };

    return {
      actions: !currentValue || transformationStep === "setup" ? ["fade"] : ["none"],
      background: {
        color: "check-button-background-icon",
        type: "circle",
      },
      className: styles.icon,
      color: "check-button-foreground-icon",
      custom,
      name: "Check",
      onTransformationEnd: transformationStep === "setup" ? handleTransformationEnd : undefined,
      size: "size16",
    };
  }

  function getButtonClass() {
    return classNames({
      [styles.button]: true,
      [styles.fullWidth]: fullWidth,
      [styles.selected]: currentValue,
    });
  }

  function getInnerContainerClass() {
    return classNames({
      [styles.alwaysVisibleContainer]: iconAlwaysVisible,
      [styles.container]: !iconAlwaysVisible,
    });
  }

  function getIconOuterContainerClass() {
    return classNames({
      [styles.iconOuterContainer]: true,
      [styles.iconOuterContainerHidden]: !iconAlwaysVisible && !currentValue,
    });
  }

  function getIconContainerClass() {
    return classNames({
      [styles.always]: iconAlwaysVisible,
      [styles.iconContainer]: true,
    });
  }

  function handleClick() {
    const checkedValue = !currentValue;

    setCurrentValue(checkedValue);
    setAnimate(true);

    onClick && onClick(checkedValue);
    onChange && onChange(checkedValue);

    if (!checkedValue) {
      setTransformationStep("setup");
    }
  }

  function handleTransformationEnd() {
    setTransformationStep("idle");
  }
});

CheckButton.displayName = "CheckButton";

CheckButton.propTypes = {
  /**
   * Indicates the id of another area on a page when this button controls whether that area is concealed or revealed.
   * If Button doesn't control another area, then aria-controls should be undefined.
   */
  "aria-controls": PropTypes.string,

  /** aria-describedby id to element which provides additional accessibility description of input element. */
  "aria-describedby": PropTypes.string,

  /**
   * Indicates when this button controls another area on the page whether than area is revealed. If Button doesn't
   * control another area, then aria-expanded should be undefined.
   */
  "aria-expanded": PropTypes.bool,

  /** aria-label text to provide additional accessibility description of button element. */
  "aria-label": PropTypes.string,

  /** aria-labelledby id to element which provides additional accessibility description of input element. */
  "aria-labelledby": PropTypes.string,

  /** State of CheckButton. */
  checked: PropTypes.bool,

  /** Content that will be rendered on the button. */
  children: PropTypes.node.isRequired,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Indicates if CheckButton is selected by default. */
  defaultValue: PropTypes.bool,

  /** Indicates CheckButton should apply disabled styling, ignore click events and provide aria-disabled attribute. */
  disabled: PropTypes.bool,

  /** Defines if the button will spread to the entire width of its container. */
  fullWidth: PropTypes.bool,

  /**
   * Indicates if checkmark will only be visible when it's checked, or if it's always revealed even
   * when not checked.
   */
  iconAlwaysVisible: PropTypes.bool,

  /** Name given to CheckButton when form is submitted. name is also used to set the id for the field. */
  name: PropTypes.string,

  /** Callback that will be called when CheckButton loses focus. */
  onBlur: PropTypes.func,

  /** Callback called when CheckButton is checked or unchecked. */
  onChange: PropTypes.func,

  /** Callback called when CheckButton is clicked. */
  onClick: PropTypes.func,
};

CheckButton.defaultProps = {
  defaultValue: false,
  iconAlwaysVisible: false,
  onChange: () => {}, // because a value is set by this component, making it controlled, a change handler is required
};
