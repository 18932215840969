import PropTypes from "prop-types";
import React from "react";

import { ItinerarySegment, itinerarySegmentPropTypes } from "../ItinerarySegment";
import { getShortDisplayStationName, getStationName } from "../Stations";

/**
 * ItineraryStation renders a station code/name within a span element something like this:
 * Dallas (Love Field), TX - DAL
 * stationCode can be rendered in different formats.
 * See propTypes to learn about ways to format and style the output.
 */

export const ItineraryStation = (props) => {
  const { className, format, stationCode, styleSetting } = props;

  return (
    <ItinerarySegment className={className} {...styleSetting}>
      {getStationDisplay()}
    </ItinerarySegment>
  );

  function getStationDisplay() {
    let content = stationCode;

    if (format === "stationName") {
      content = getStationName(stationCode);
    } else if (format === "shortStationName") {
      content = getShortDisplayStationName(stationCode);
    }

    return content;
  }
};

ItineraryStation.propTypes = {
  /** Classes for positioning the component. */
  className: PropTypes.string,

  /** This format indicates how to display the station name. See Station.js for more info. */
  format: PropTypes.oneOf(["stationCode", "stationName", "shortStationName"]),

  /** Three letter station code in all caps. */
  stationCode: PropTypes.string.isRequired,

  /** Options to use to style rendered station. */
  styleSetting: PropTypes.shape(itinerarySegmentPropTypes),
};

ItineraryStation.defaultProps = {
  format: "stationCode",
};
