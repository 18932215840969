import PropTypes from "prop-types";
import React from "react";

export const Instagram = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path
        strokeWidth={0}
        d="M22.42,11.14a2.65,2.65,0,0,0-1.56-1.56,4.7,4.7,0,0,0-1.53-.29c-.87,0-1.13,0-3.33,0l0,0c-2.2,0-2.46,0-3.34,0a4.37,4.37,0,0,0-1.52.29,2.39,2.39,0,0,0-.95.61,2.53,2.53,0,0,0-.62.95,4.77,4.77,0,0,0-.28,1.53c0,.88,0,1.15,0,3.34s0,2.46,0,3.32a4.79,4.79,0,0,0,.28,1.54,2.7,2.7,0,0,0,1.57,1.56,4.44,4.44,0,0,0,1.53.29c.88,0,1.14.05,3.34.05s2.46,0,3.33-.05a4.52,4.52,0,0,0,1.54-.28,2.65,2.65,0,0,0,1.56-1.56,4.44,4.44,0,0,0,.29-1.53c0-.87.05-1.13.05-3.33s0-2.46-.05-3.33A4.5,4.5,0,0,0,22.42,11.14ZM16,20.23A4.23,4.23,0,1,1,20.23,16,4.23,4.23,0,0,1,16,20.23Zm4.4-7.64a1,1,0,1,1,1-1A1,1,0,0,1,20.4,12.59Z"
      />
      <circle cx="16" cy="16" r="2.75" />
      <path d="M16,2A14,14,0,1,0,30,16,14,14,0,0,0,16,2Zm8.19,17.4a5.94,5.94,0,0,1-.39,2,3.8,3.8,0,0,1-.95,1.45,3.92,3.92,0,0,1-1.45.95,5.94,5.94,0,0,1-2,.39c-.88,0-1.16,0-3.4,0s-2.52,0-3.4,0a5.94,5.94,0,0,1-2-.39,3.8,3.8,0,0,1-1.45-.95,3.92,3.92,0,0,1-1-1.45,5.94,5.94,0,0,1-.39-2c0-.88,0-1.16,0-3.4s0-2.52,0-3.4a5.94,5.94,0,0,1,.39-2,3.8,3.8,0,0,1,1-1.45,3.92,3.92,0,0,1,1.45-1,5.94,5.94,0,0,1,2-.39c.88,0,1.16,0,3.4,0s2.52,0,3.4,0a5.94,5.94,0,0,1,2,.39,3.8,3.8,0,0,1,1.45,1,3.92,3.92,0,0,1,.95,1.45,5.94,5.94,0,0,1,.39,2c0,.88,0,1.16,0,3.4S24.23,18.52,24.19,19.4Z" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      style,
      viewBox: "0 0 32 32",
    };
  }
};

Instagram.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Instagram.defaultProps = {
  role: "img",
};
