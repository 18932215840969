import PropTypes from "prop-types";
import React, { createRef, useEffect, useState } from "react";

import { Icon, Password } from "@swa-ui/core";
import i18n from "@swa-ui/locale";
import { classNames, getUniqueId } from "@swa-ui/string";

import { getNewPasswordSchema } from "../../schema";
import styles from "./NewPassword.module.scss";

export const NewPassword = React.forwardRef((props, ref) => {
  const { error, id, name, onBlur, onChange } = props;
  const uniqueId = id || getUniqueId("password-id");
  const messagesDefault = [
    {
      content: i18n("NewPassword__LENGTH"),
      rule: "lengthRule",
      ruleMet: false,
    },
    {
      content: i18n("NewPassword__CASE"),
      rule: "caseRule",
      ruleMet: false,
    },
    {
      content: i18n("NewPassword__NUMBER"),
      rule: "numberRule",
      ruleMet: false,
    },
    {
      content: i18n("NewPassword__FIRST_CHARACTER"),
      rule: "firstCharacterRule",
      ruleMet: false,
    },
    {
      content: i18n("NewPassword__ALLOWED_CHARACTERS"),
      rule: "allowedCharacterRule",
      ruleMet: false,
    },
    {
      content: i18n("NewPassword__DISALLOWED_NUMERIC_SEQUENCE"),
      rule: "disallowedNumericSequence",
      ruleMet: true,
      showOnlyOnError: true,
    },
    {
      content: i18n("NewPassword__DISALLOWED_STRING_SEQUENCE"),
      rule: "disallowedStringSequence",
      ruleMet: true,
      showOnlyOnError: true,
    },
  ];
  const [messages, setMessages] = useState(messagesDefault);
  const [validationErrors, setValidationErrors] = useState(undefined);
  const inputRef = ref || createRef();

  // This sets the validation messages rules being met/unmet on change.
  useEffect(() => {
    const nextMessages = messages.map((message) => {
      const errorIndex = validationErrors?.findIndex(
        (validationError) => validationError === message.rule
      );

      if (errorIndex !== undefined) {
        message.ruleMet = errorIndex === -1;
      }

      return message;
    });

    setMessages(nextMessages);
  }, [validationErrors]);

  return (
    <>
      <Password {...getPasswordProps()} />
      {renderMessages()}
    </>
  );

  function renderMessages() {
    const listContent = messages.map(
      (message) => shouldShowMessage(message) && renderMessage(message)
    );

    return (
      <ul className={styles.messages} id={uniqueId}>
        {listContent}
      </ul>
    );
  }

  function renderMessage(message) {
    return (
      <li className={getMessageClass(message.ruleMet)} key={message.rule}>
        {message.content}
        <Icon {...getIconProps(message.ruleMet)} />
      </li>
    );
  }

  function getIconProps(ruleMet) {
    return {
      className: classNames({
        [styles.icon]: true,
        [styles.iconDefault]: validationErrors === undefined,
      }),
      color: ruleMet ? "green-1" : "red-1",
      name: ruleMet ? "Check" : "Close",
      size: "size15",
    };
  }

  function getMessageClass(ruleMet) {
    return classNames({
      [styles.defaultMode]: validationErrors === undefined,
      [styles.errorMode]: validationErrors !== undefined && !ruleMet,
      [styles.message]: true,
      [styles.validMode]: validationErrors !== undefined && ruleMet,
    });
  }

  function getPasswordProps() {
    return {
      "aria-describedby": props["aria-describedby"],
      "aria-label": props["aria-label"],
      defaultValue: "",
      error,
      hideAriaLabel: props["hideAriaLabel"],
      hideLabel: i18n("NewPassword__HIDE"),
      maxLength: 16,
      name,
      onBlur: handleBlur,
      onChange: handleChange,
      ref: inputRef,
      showAriaLabel: props["showAriaLabel"],
      showLabel: i18n("NewPassword__SHOW"),
    };
  }

  function handleBlur(event) {
    onBlur(event, { suppressTrigger: true });
  }

  function handleChange(event) {
    const passwordValue = event.target.value;
    const schema = getNewPasswordSchema();

    try {
      schema.validateSync(passwordValue, { abortEarly: false });
      setValidationErrors([]);
    } catch (e) {
      setValidationErrors(e.errors);
    }
    onChange(event, { suppressTrigger: true });
  }

  function shouldShowMessage(message) {
    const { ruleMet, showOnlyOnError } = message;

    return !showOnlyOnError || !ruleMet;
  }
});

NewPassword.propTypes = {
  /** aria-label text to provide accessibility description. */
  "aria-label": PropTypes.string,

  /** Indicates Input should apply error styling and apply aria-invalid attribute. */
  error: PropTypes.bool.isRequired,

  /** aria-label text to provide additional accessibility description of hide label button. */
  hideAriaLabel: PropTypes.string,

  /** ID to be added to the element. */
  id: PropTypes.string,

  /** Name given to Input field when form is submitted. name is also used to set the id for the field. */
  name: PropTypes.string.isRequired,

  /** Callback that will be called when Input loses focus. */
  onBlur: PropTypes.func.isRequired,

  /** Callback called when Input is changed. */
  onChange: PropTypes.func.isRequired,

  /** aria-label text to provide additional accessibility description of show label button. */
  showAriaLabel: PropTypes.string,
};
