import PropTypes from "prop-types";
import React from "react";

export const Alert = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path d="M16,24.9c-.95,0-2.21,0-3.1,0v0a3.1,3.1,0,0,0,6.2,0v-.06c-1.09,0-1.9.06-3.1.06" />
      <path d="M23.54,16.21C22.88,9.58,22.37,7,18.08,5.52,16.55,5,17.33,4,16,4s-.56,1-2.1,1.53C9.6,7,9,9.63,8.36,16.24c-.45,4.45-2.44,2.07-2.47,4.85,0,2,4.46,2.35,10.11,2.34s10.09-.35,10.11-2.38c0-2.78-2.11-.26-2.57-4.84" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "4 4 24 24",
    };
  }
};

Alert.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Alert.defaultProps = {
  role: "img",
};
