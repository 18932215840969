import { window } from "../window";

/**
 * Finds first parent element with matching property/value pair.
 *
 * @param {HTMLElement} element - The element to begin searching. Search will begin at this
 *    element's parent.
 * @param {HTMLElement} property - The CSS property to search.
 * @param {HTMLElement} value - Desired value set for property.
 * @returns {HTMLElement} - First matching parent, or null if not found.
 */

export const getFirstParentWithMatchingStyle = (element, property, value) => {
  let isStillLooking = true;

  while (element && isStillLooking) {
    element = element?.parentNode;

    if (element === window.document) {
      element = undefined;
    }

    if (element && isStillLooking) {
      const overflow = getComputedValue(element, property);

      if (overflow === value) {
        isStillLooking = false;
      }
    }
  }

  return element;
};

function getComputedValue(element, property) {
  return window.getComputedStyle(element).getPropertyValue(property);
}
