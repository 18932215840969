import * as yup from "yup";

import { getBootstrapData } from "@swa-ui/bootstrap";
import { swaDate } from "@swa-ui/date";
import i18n from "@swa-ui/locale";

let schema;

export const getDateSchema = () => {
  if (!schema) {
    const airDatesData = getBootstrapData("air/air-dates-data");
    const lastBookableDate = swaDate(airDatesData.currentLastBookableDate).add(1, "day");

    schema = yup
      .string()
      .test(
        "LastBookableDate",
        i18n("schema__ERROR_MAX_DATE", lastBookableDate.format("MM/DD/YYYY")),
        (value) => {
          let valid = true;

          if (value) {
            valid = swaDate(value).isBefore(lastBookableDate, "day");
          }

          return valid;
        }
      );
  }

  return schema;
};
