import * as yup from "yup";

import { getBootstrapData } from "@swa-ui/bootstrap";
import { dateFormats, swaDate } from "@swa-ui/date";
import i18n from "@swa-ui/locale";

import { FLIGHT_CAR, FLIGHT_HOTEL, FLIGHT_HOTEL_CAR } from "../defines/packageType";
import { getDateSchema } from "../schema/getDateSchema";

let schema;

const firstBookableDate = swaDate().set("date", swaDate().get("date"));

export const vacationsBookingFormSchema = () => {
  const stationIds = getBootstrapData("air/stations-data").map((station) => station.id);

  if (!schema) {
    schema = yup
      .object()
      .shape({
        packageType: yup
          .string()
          .trim()
          .required()
          .oneOf([FLIGHT_HOTEL, FLIGHT_CAR, FLIGHT_HOTEL_CAR])
          .default(FLIGHT_HOTEL),
        destinationAirportCode: yup
          .string()
          .trim()
          .required()
          .label(i18n("schema__DESTINATION").toLowerCase()),
        originationAirportCode: yup
          .string()
          .trim()
          .uppercase()
          .required()
          .oneOf(stationIds)
          .label(i18n("schema__DEPARTURE_AIRPORT").toLowerCase()),
        departureDate: getDateSchema()
          .test(
            "FirstBookableDate",
            i18n("schema__ERROR_MIN_DATE", firstBookableDate.format("MM/DD/YYYY")),
            (departureDateValue) => swaDate(departureDateValue).isAfter(swaDate(), "day")
          )
          .required()
          .label(i18n("schema__DEPART_DATE").toLowerCase()),
        returnDate: getDateSchema().required().label(i18n("schema__RETURN_DATE").toLowerCase()),
        promoCode: yup.string().alphaNumeric().trim(),
        travelers: yup
          .array()
          .default([{ adults: 2, children: 0, childrenAge: [], lapChildren: 0 }]),
      })
      .noUnknown();
  }

  return schema;
};
