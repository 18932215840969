import { CheckButton } from "@swa-ui/core";

import { withFormField } from "../withFormField";

/**
 * CheckButtonFormField provides support for a standard CheckButton component to be used in a Form.
 */

export const CheckButtonFormField = withFormField(CheckButton, {
  useController: true,
});
