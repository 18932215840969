import * as yup from "yup";

import i18n from "@swa-ui/locale";

export const PHONE_COUNTRY_CODE_SCHEMA_FIELD_NAMES = {
  PHONE_COUNTRY_CODE: "phoneCountryCode",
};

export const getPhoneCountryCodeFormFieldSchema = () =>
  yup.number().required().label(i18n("PhoneCountryCodeFormField__LABEL").toLowerCase());
