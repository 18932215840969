"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useRecentSearchesAnalytics = void 0;
var _react = require("react");
var _reactRouterDom = require("react-router-dom");
var _application = require("@swa-ui/application");
var _routes = require("./defines/routes");
var useRecentSearchesAnalytics = exports.useRecentSearchesAnalytics = function useRecentSearchesAnalytics(recentSearches) {
  var _useLocation = (0, _reactRouterDom.useLocation)(),
    pathname = _useLocation.pathname;
  var _useContext = (0, _react.useContext)(_application.MktgDataContext),
    setAdobePageData = _useContext.setAdobePageData;
  var recentSearchesType = [];
  (0, _react.useEffect)(function () {
    if (pathname === _routes.ROUTES.vacations && recentSearches.length) {
      var content = {};
      var impressions = [];
      var recentSearchesDestination = [];
      var adobeAnalyticsObj = {};
      recentSearches.forEach(function (search, index) {
        var _search$formData$dest, _search$formData, _search$formData$orig, _search$formData2;
        adobeAnalyticsObj = {
          productcarousel: "My Recent Searches",
          productcode: "",
          productdestination: (_search$formData$dest = search === null || search === void 0 || (_search$formData = search.formData) === null || _search$formData === void 0 ? void 0 : _search$formData.destinationAirportCode) !== null && _search$formData$dest !== void 0 ? _search$formData$dest : "",
          productorigin: (_search$formData$orig = search === null || search === void 0 || (_search$formData2 = search.formData) === null || _search$formData2 === void 0 ? void 0 : _search$formData2.originationAirportCode) !== null && _search$formData$orig !== void 0 ? _search$formData$orig : "",
          productposition: "".concat(index + 1, "|").concat(recentSearches.length),
          productprice: "",
          producttype: getProductType(search)
        };
        recentSearchesDestination.push("".concat(adobeAnalyticsObj.productorigin, "-").concat(adobeAnalyticsObj.productdestination));
        recentSearchesType.push(adobeAnalyticsObj.producttype);
        impressions.push(adobeAnalyticsObj);
      });
      content["recentsearchcount"] = recentSearches === null || recentSearches === void 0 ? void 0 : recentSearches.length.toString();
      content["recentsearchdestination"] = recentSearchesDestination === null || recentSearchesDestination === void 0 ? void 0 : recentSearchesDestination.toString();
      content["recentsearchtype"] = recentSearchesType === null || recentSearchesType === void 0 ? void 0 : recentSearchesType.toString();
      setAdobePageData({
        content: content
      }, "recentSearches", impressions);
    }
  }, [recentSearches.length]);
  function getProductType(search) {
    var _combinedProductType$;
    var combinedProductType = search === null || search === void 0 ? void 0 : search.type;
    var productType = (_combinedProductType$ = combinedProductType === null || combinedProductType === void 0 ? void 0 : combinedProductType.split(/(?=[A-Z])/).join("+")) !== null && _combinedProductType$ !== void 0 ? _combinedProductType$ : "";
    return productType;
  }
};