import React from "react";

import { useDeviceInfo, XLARGE } from "@swa-ui/browser";
import { Heading, Layout } from "@swa-ui/core";
import { TextContent, usePlacement } from "@swa-ui/placements";

import { VacationsBookingWidget } from "../VacationsBookingWidget";
import styles from "./VacationsBookingHero.module.scss";

export const VacationsBookingHero = () => {
  const { placement: heroImage } = usePlacement("heroImage");
  const { background, primaryHeading } = heroImage?.placement;
  const { screenSize } = useDeviceInfo();

  return (
    <div className={styles.container}>
      <div {...getBackgroundProps()}>
        <Layout sides center={screenSize === XLARGE}>
          <Heading className={styles.heading}>
            <TextContent {...primaryHeading} />
          </Heading>
          <div className={styles.wrapper}>
            <VacationsBookingWidget />
          </div>
        </Layout>
      </div>
    </div>
  );

  function getBackgroundProps() {
    const { imagePath } = background;

    return {
      className: styles.background,
      style: {
        backgroundImage: `url(${imagePath})`,
      },
    };
  }
};
