import PropTypes from "prop-types";
import React from "react";

import { InformationTip } from "@swa-ui/core";

/**
 * InformationTipAdapter adds props for adapting Vision InfoBox Tool Tip into the InformationTip component.
 */

export const InformationTipAdapter = (props) => {
  const { children, content, infoBoxSize, location, size, ...remainingProps } = props;

  return <InformationTip {...getProps()} />;

  function getProps() {
    const adaptedProps = {
      content: children ?? content,
      location: location ?? "above",
      size: size ?? getInfoIconSize(infoBoxSize),
    };

    return {
      ...remainingProps,
      ...adaptedProps,
    };

    function getInfoIconSize(sizeProp) {
      const sizeConverter = {
        micro: "small",
        small: "auto",
      };

      return sizeConverter[sizeProp];
    }
  }
};

InformationTipAdapter.propTypes = {
  /** Array of content that will be rendered when Caption is displayed. */
  children: PropTypes.array,

  /** Content that will appear in the flyout of Caption. */
  content: PropTypes.node,

  /** Size of information icon. */
  infoBoxSize: PropTypes.oneOf(["micro", "small"]),

  /** Location to display flyout. */
  location: PropTypes.string,

  /** Size of information icon. */
  size: PropTypes.oneOf(["auto", "small"]),
};
