import PropTypes from "prop-types";
import React from "react";

export const HtmlValue = (props) => {
  const { className, htmlValue } = props;

  return <div className={className} dangerouslySetInnerHTML={{ __html: htmlValue }} />;
};

HtmlValue.propTypes = {
  /** Classes for styling. */
  className: PropTypes.string,

  /** Raw HTML string to be rendered. */
  htmlValue: PropTypes.string,
};
