import PropTypes from "prop-types";
import React from "react";

import { useDeviceInfo } from "@swa-ui/browser";
import { ListMatrix } from "@swa-ui/core";
import { classNames } from "@swa-ui/string";

import { commonPlacementComponents } from "../defines";
import { PlacementFactory } from "../PlacementFactory";
import { usePlacementIds } from "../usePlacementIds";
import { ListCallToAction } from "./ListCallToAction";
import { ListHeading } from "./ListHeading";
import styles from "./ListPlacements.module.scss";

export const MatrixPlacement = (props) => {
  const { placement, placementData = {} } = props;
  const { background, callToAction, items, listItemType, settings, title } = placement;
  const { analyticsValue = "" } = placementData;
  const { screenSize } = useDeviceInfo();
  const gridItemIds = (usePlacementIds(new RegExp(`${placement?.id}*`)) || []).sort();

  return (
    <div style={background?.type === "color" ? { backgroundColor: background?.color } : {}}>
      <ListHeading title={title} />
      <ListMatrix {...getListMatrixProps()} />
      <ListCallToAction callToAction={callToAction} analyticsValue={analyticsValue} />
    </div>
  );

  function getListMatrixProps() {
    const numberOfColumns = getColumnCount();

    return {
      className: styles.content,
      items: getItems(),
      layout: "grid",
      numberOfColumns,
      rowClassName: styles.matrixRow,
      separator: settings?.separator,
    };
  }

  function getItems() {
    const layout = getDeviceLayout();
    const layoutConfig = [...layout.flat()];
    const Component = commonPlacementComponents[listItemType];
    let itemsContent = null;

    if (placement?.id && gridItemIds?.length) {
      itemsContent = gridItemIds.map((itemId, index) => ({
        content: <PlacementFactory id={itemId} />,
        preferredWidth: getItemWidth(index, layoutConfig),
      }));
    } else {
      itemsContent = items.map((item, index) => ({
        content: <Component key={index} placement={{ ...item }} />,
        preferredWidth: getItemWidth(index, layoutConfig),
      }));
    }

    return itemsContent;
  }

  function getItemWidth(position, layoutConfig) {
    return layoutConfig[position % layoutConfig.length];
  }

  function getColumnCount() {
    const layout = getDeviceLayout();
    const columns = layout.map((item) => getItemCount(item));

    return Math.max(...columns);
  }

  function getItemCount(item) {
    let count = 1;

    if (Array.isArray(item)) {
      count = item.reduce((sum, index) => sum + index, 0);
    }

    return count;
  }

  function getDeviceLayout() {
    const { overrides, layout } = settings ?? {};

    return overrides?.[screenSize]?.layout ?? layout;
  }
};

MatrixPlacement.propTypes = {
  placement: PropTypes.shape({
    /** List of items to show in Matrix, object shape depends on item type. */
    items: PropTypes.arrayOf(PropTypes.object),

    /** Name of the item component to render. */
    listItemType: PropTypes.string,

    /** Placement configuration. */
    settings: PropTypes.shape({
      /** Array of arrays (matrix) with information about the layout to render. */
      layout: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),

      /** Override for all properties in settings by device screen size. */
      overrides: PropTypes.object,

      /** Value used to display a divider between grid items. */
      separator: PropTypes.bool,
    }),
  }),

  placementData: PropTypes.shape({
    /** The dynamic value that will be used as an event description for analytics. */
    analyticsValue: PropTypes.string,
  }),
};
