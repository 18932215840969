import React from "react";

import { MktgRulesEngine, rulesEnginePropTypes } from "@swa-ui/application";

/**
 * DotcomRulesEngine provides a way to fire event callback based on facts. Dotcom specific rules and facts
 * will be configured in this component.
 */

export const DotcomRulesEngine = (props) => {
  const { allowUndefinedFacts, eventCallbacks, facts, operators, rules, rulesDictionary } = props;

  return <MktgRulesEngine {...getRuleEngineProps()} />;

  function getRuleEngineProps() {
    return {
      allowUndefinedFacts,
      eventCallbacks,
      facts,
      operators,
      rules,
      rulesDictionary,
    };
  }
};

DotcomRulesEngine.propTypes = rulesEnginePropTypes;
DotcomRulesEngine.defaultProps = {
  allowUndefinedFacts: true,
  rules: [],
};
