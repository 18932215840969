import React from "react";

import i18n from "@swa-ui/locale";

import styles from "./Copyright.module.scss";

/**
 * Component to display the copyright info at the bottom of the mobile header.
 */

export const Copyright = () => (
  <div className={styles.copyright}>
    <div>
      {i18n("Copyright__GLOBAL_HEADER_MOBILE_COPYRIGHT", {
        year: new Date().getFullYear(),
      })}
    </div>
    <div>{i18n("Copyright__ALL_RIGHTS_RESERVED")}</div>
  </div>
);
