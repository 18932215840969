import PropTypes from "prop-types";
import React from "react";

export const Seat = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path d="M7.39333 5.27667C6.87333 4.75667 6.87333 3.91 7.39333 3.39C7.91333 2.87 8.76 2.87 9.28 3.39C9.8 3.91 9.8 4.75667 9.28 5.27667C8.75333 5.80333 7.91333 5.80333 7.39333 5.27667ZM6.33333 12.3367V6.33667H5V12.3367C5 14.1767 6.49333 15.67 8.33333 15.67H12.3333V14.3367H8.33333C7.22667 14.3367 6.33333 13.4433 6.33333 12.3367ZM15.6667 15.05L12.2867 11.67H10V9.21667C10.9333 9.98333 12.4 10.6567 13.6667 10.6567V9.21667C12.56 9.23 11.26 8.63667 10.5533 7.85667L9.62 6.82333C9.49333 6.68333 9.33333 6.57 9.16 6.49C8.96667 6.39667 8.74667 6.33667 8.52 6.33667H8.5C7.67333 6.33667 7 7.01 7 7.83667V11.67C7 12.7767 7.89333 13.67 9 13.67H12.38L14.7133 16.0033L15.6667 15.05Z" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "0 0 16 16",
    };
  }
};

Seat.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Seat.defaultProps = { role: "img" };
