import PropTypes from "prop-types";
import React, { createContext, useContext, useEffect, useState } from "react";

import { PlacementsContext } from "@swa-ui/placements";

import { MboxContext } from "../MboxProvider";
import { useAppSetting } from "../useAppSetting";
import { getMktgDataItem, useMktgData } from "../useMktgData";

/**
 * MboxPlacementsProvider provides the method to load mbox placements in a React context so the method is available
 * to components in an app's render tree.
 *
 * loadMboxPlacements - Method to load mbox placements.
 */

export const MktgMediatorContext = createContext();
export const MktgMediatorProvider = (props) => {
  const { children, getAdditionalMboxContextAsync } = props;
  const contentParameters = useAppSetting("contentParameters", {});
  const mboxParameters = useAppSetting("mboxParameters", {});
  const [loading, setLoading] = useState({
    current: {},
    last: {},
  });
  const { loadPlacements, loading: placementsLoading } = useContext(PlacementsContext);
  const { loadingDetails: mboxLoadingDetails, loadMboxes, mboxes } = useContext(MboxContext);
  const { mktgData } = useMktgData();

  useEffect(() => {
    if (haveMboxesFinishedLoading()) {
      loadPlacements(mboxLoadingDetails.last.pathname, {
        ...loading.current.context,
        ...mboxes.target,
        ...getPlacementParameters(),
      });
    }
  }, [mboxLoadingDetails]);

  useEffect(() => {
    if (havePlacementsFinishedLoading() && loading.current.pathname) {
      setLoading({ current: {}, last: { pathname: loading.current.pathname } });
    }
  }, [placementsLoading]);

  return (
    <MktgMediatorContext.Provider value={{ loading, startUpdateCycle }}>
      {children}
    </MktgMediatorContext.Provider>
  );

  function haveMboxesFinishedLoading() {
    return !mboxLoadingDetails?.current?.pathname && mboxLoadingDetails?.last?.pathname;
  }

  function havePlacementsFinishedLoading() {
    return !placementsLoading?.current?.pathname && placementsLoading?.last?.pathname;
  }

  async function startUpdateCycle(pathnameToLoad, context = {}) {
    setLoading({
      ...loading,
      current: { context: context, pathname: pathnameToLoad },
    });
    const additionalMboxContext = await (getAdditionalMboxContextAsync
      ? getAdditionalMboxContextAsync()
      : Promise.resolve({}));

    loadMboxes(pathnameToLoad, {
      ...context,
      ...getMboxParameters(),
      ...additionalMboxContext,
    });
  }

  function getMboxParameters() {
    return getMktgDataParameters(mboxParameters);
  }

  function getPlacementParameters() {
    return {
      ...getMktgDataParameters(contentParameters),
    };
  }

  function getMktgDataParameters(parametersToRetrieve) {
    return Object.entries(parametersToRetrieve ?? {}).reduce(
      (result, [parameterKey, parameterPath]) => ({
        ...result,
        [parameterKey]: getMktgDataItem(mktgData, parameterPath),
      }),
      {}
    );
  }
};

MktgMediatorContext.displayName = "MktgMediatorContext";
MktgMediatorProvider.propTypes = {
  /** Content to be rendered inside MktgMediatorProvider wrapper. */
  children: PropTypes.node.isRequired,

  /** Async function for additional mbox context */
  getAdditionalMboxContextAsync: PropTypes.func,
};
