import PropTypes from "prop-types";
import React from "react";
import { NavLink } from "react-router-dom";

import { window } from "@swa-ui/browser";
import { isHybridEnabled } from "@swa-ui/hybrid";
import { interpolate } from "@swa-util/string";

import { getScaleAdjustedStyles } from "../getScaleAdjustedStyles";
import voidElements from "./html-void-elements";

const FlexPlacementComponent = (props) => {
  const {
    additionalProps: { templateData = {}, onPlacementClick },
    basePath,
    placement: { templateKeys = [] } = {},
  } = props;
  const interpolationData = templateKeys.reduce(
    (obj, key) => ({ ...obj, [key]: templateData[key] || "" }),
    {}
  );

  const shouldBeAbsolute = (type) => {
    const {
      placement: { flexSettings: { disableAbsolutePositioning = false } = {} } = {},
      notAbsolutelyPositioned,
    } = props;

    return !disableAbsolutePositioning && !notAbsolutelyPositioned[type];
  };

  const handleAnalyticsClick = (contentObject) => {
    const { contentBlockId, saveContentBlockIdOnStore } = props;
    const { onClick } = contentObject;

    contentBlockId && saveContentBlockIdOnStore(contentBlockId);
    onClick && onClick();
  };

  const handleActionableClick = (contentObject, event) => {
    handleAnalyticsClick(contentObject);
    onPlacementClick && onPlacementClick(event);
  };

  const getContentCommand = (contentObject) => {
    const { props: { command = {} } = {} } = contentObject;

    return props.configuredCommands[command];
  };

  const getComponentType = (type) =>
    props.supportedComponents[type] || props.supportedElements[type] || "div";

  const getClickActionHandler = (contentObject) =>
    contentObject.type === "Button" || contentObject.type === "Link"
      ? { onClick: (event) => handleActionableClick(contentObject, event) }
      : {};

  const getAppLink = (mobileTarget, href) => {
    const { pathname, search } = new window.URL(href, window.location.href);
    const sliceStart = pathname.startsWith("/") ? 1 : 0;
    const sliceEnd = pathname.endsWith("/") ? -1 : pathname.length;
    const trimmedPathname = pathname.slice(sliceStart, sliceEnd);

    return `${mobileTarget}://${trimmedPathname}${search}`;
  };

  const getLinkProps = (contentObject = {}) => {
    const {
      props: { href = "", mobileTarget },
      type,
    } = contentObject;
    const shouldUseAppLink = mobileTarget === "app" && isHybridEnabled();
    const shouldUseNavLink =
      mobileTarget === "webview" && type === "Link" && href.startsWith(basePath);

    return {
      href: shouldUseAppLink ? getAppLink(mobileTarget, href) : href,
      ...(shouldUseNavLink && {
        overrideComponent: NavLink,
        overrideComponentProps: {
          to: href,
        },
      }),
    };
  };

  const getClass = (contentObject) => {
    const { className: propClassName = "" } = props;
    const { props: { className = "" } = {} } = contentObject;

    return contentObject.rootNode ? `flex-placement ${propClassName}`.trim() : className;
  };

  const getStyles = (contentObject) => {
    const {
      additionalProps: { scaleFactor = 1 },
      placement: { flexSettings: { shouldScalePlacement = false } = {} } = {},
    } = props;

    return {
      ...getScaleAdjustedStyles(scaleFactor, shouldScalePlacement, contentObject.styles),
      ...(shouldBeAbsolute(contentObject.type) &&
        !contentObject.rootNode && { position: "absolute" }),
    };
  };

  const determineTextContent = (textContent) =>
    Object.keys(interpolationData).length > 0 && textContent
      ? interpolate(textContent, interpolationData)
      : textContent;

  const determineChildContent = (contentObject) =>
    determineTextContent(contentObject.textContent) ||
    contentObject.childContent ||
    contentObject.templateContent ||
    contentObject.content;

  const getInterpolatedProps = (contentObjectProps = {}) =>
    Object.keys(contentObjectProps).reduce(
      (obj, key) => ({
        ...obj,
        [key]: determineTextContent(contentObjectProps[key]) ?? "",
      }),
      {}
    );

  const getCommandProps = (contentObject, index) => ({
    ...props,
    ...getInterpolatedProps(contentObject.props),
    ...(index !== undefined && { key: index }),
    className: getClass(contentObject),
    trackAnalyticAction: () => handleAnalyticsClick({}),
  });

  const getProps = (contentObject, index) => ({
    ...getInterpolatedProps(contentObject.props),
    ...(index !== undefined && { key: index }),
    ...(contentObject.type === "Placement" && {
      id: contentObject.id,
      placementId: contentObject.id,
      preprocessResponse: props.preprocessResponse,
      useDefaultContent: props.useDefaultContent,
      waitForContentServerResponse: props.waitForContentServerResponse,
    }),
    ...(contentObject?.props?.href && getLinkProps(contentObject)),
    ...getClickActionHandler(contentObject),
    className: getClass(contentObject),
    ...(!contentObject.rootNode &&
      props.supportedComponents[contentObject?.type] && {
        ...props.additionalProps,
        additionalProps: props.additionalProps,
      }),
    style: getStyles(contentObject),
  });

  const renderContent = (contentObject, index) => {
    const Command = getContentCommand(contentObject);
    const Component = getComponentType(contentObject.type);

    const childContent = voidElements.includes(Component)
      ? null
      : determineChildContent(contentObject);
    const children = Array.isArray(childContent) ? childContent.map(renderContent) : childContent;

    const content = <Component {...getProps(contentObject, index)}>{children}</Component>;

    return Command ? (
      <Command {...getCommandProps(contentObject, index)}>
        {React.cloneElement(content, { className: "" })}
      </Command>
    ) : (
      content
    );
  };

  const renderFlexPlacement = () => renderContent({ rootNode: true, ...props.placement });

  return renderFlexPlacement();
};

FlexPlacementComponent.propTypes = {
  /** Additional props for the placement. */
  additionalProps: PropTypes.object,

  /**
   * Relative browser path during placement render, such as "/some/path"
   */
  basePath: PropTypes.string,

  /** Props passed to a command in a placement. */
  commandProps: PropTypes.object,

  /** Configured commands used by the placement. */
  configuredCommands: PropTypes.object,

  /** Specifies the content block ID. */
  contentBlockId: PropTypes.string,

  /** Specifies elements that are not absolutely positioned. */
  notAbsolutelyPositioned: PropTypes.object,

  /** Placement to be rendered. */
  placement: PropTypes.object.isRequired,

  /** Defines if there is a preprocess response. */
  preprocessResponse: PropTypes.bool,

  /** Function to save content block id. */
  saveContentBlockIdOnStore: PropTypes.func,

  /** Specifies the supported components. */
  supportedComponents: PropTypes.object,

  /** Specifies the supported elements. */
  supportedElements: PropTypes.object,

  /** Defines if default content will be used. */
  useDefaultContent: PropTypes.bool,

  /** Defines if will wait for content server response. */
  waitForContentServerResponse: PropTypes.bool,
};

FlexPlacementComponent.defaultProps = {
  additionalProps: {},
  basePath: "",
  configuredCommands: {},
  contentBlockId: "",
  notAbsolutelyPositioned: {},
  supportedComponents: {},
  supportedElements: {},
};

/**
 * FlexPlacement interprets and renders WCM data feed.
 */

export const FlexPlacement = React.memo(
  FlexPlacementComponent,
  (prevProps, nextProps) =>
    JSON.stringify(prevProps.placement) === JSON.stringify(nextProps.placement) &&
    JSON.stringify(prevProps.additionalProps) === JSON.stringify(nextProps.additionalProps)
);
