import { useEffect } from "react";

import { useRestoreFocus, window } from "@swa-ui/browser";

import { keyCodes } from "../defines/keyCodes";

/**
 * useDismiss encapsulates the functionality required to drive a Caption, Modal, or Dialog component.
 * Current functionality:
 * - ESC to close
 * - close on clicking outside of component.
 *
 * componentRef: Ref function that indicates the inner area of the component.
 * hideComponent: State setter function that sets component location state to "hidden."
 * isComponentVisible: Boolean that indicates whether component location state is "hidden."
 * onClose: callback to be informed when Dialog closes.
 */

export const useDismiss = (
  componentRef,
  hideComponent,
  isComponentVisible,
  onClose,
  restoreFocusOnClose = true
) => {
  const { restoreFocusElement, saveFocusElement } = useRestoreFocus();

  useEffect(() => {
    if (isComponentVisible) {
      addEventListeners();
      restoreFocusOnClose && saveFocusElement();
    } else {
      removeEventListeners();
      restoreFocusOnClose && restoreFocusElement();
    }

    return () => {
      removeEventListeners();
    };
  }, [isComponentVisible]);

  function addEventListeners() {
    window.addEventListener("click", handleClick, true); // NOSONAR
    window.addEventListener("keydown", handleKeyDown); // NOSONAR
  }

  function removeEventListeners() {
    window.removeEventListener("click", handleClick, true);
    window.removeEventListener("keydown", handleKeyDown);
  }

  function handleClick(event) {
    if (componentRef.current && !componentRef.current.contains(event.target)) {
      close(event);
    }
  }

  function handleKeyDown(event) {
    const { key } = event;

    if (key === keyCodes.KEY_ESCAPE) {
      close(event);
    }
  }

  function close(event) {
    hideComponent();
    onClose && onClose(event);
  }
};
