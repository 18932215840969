import PropTypes from "prop-types";
import React from "react";

export const Drink = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path d="M24.0068024,8 C25.1113719,8 26.0068024,8.8954305 26.0068024,10 C26.0068024,10.1002725 25.9992614,10.2004031 25.9842434,10.2995446 L23.2575866,28.2995446 C23.1094775,29.277285 22.2690401,30 21.2801455,30 L8.72235688,30 C7.73237186,30 6.89135216,29.275732 6.74448724,28.2967013 L4.04429747,10.2967013 C3.88043387,9.20435406 4.63311858,8.18599396 5.72546582,8.02213035 C5.82367963,8.00739725 5.92285441,8 6.02216712,8 L17.3648949,7.99917004 L18.5761714,3.48157914 C18.810462,2.60710928 19.6029454,1.99906974 20.5082573,1.99917004 L28,2 L28,4 L21.971373,3.99961322 C21.066007,3.99955513 20.2735187,4.60770294 20.0393186,5.48225307 L19.3648949,7.99917004 L24.0068024,8 Z M16.5518949,11.04617 L7,11.1064853 L7.826,16 L15.2218949,15.99917 L16.5518949,11.04617 Z M22.999,11.006 L18.6248949,11.03317 L17.2918949,15.99917 L22.164,16 L22.999,11.006 Z" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      style,
      viewBox: "0 0 32 32",
    };
  }
};

Drink.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Drink.defaultProps = {
  role: "img",
};
