import { window } from "@swa-ui/browser";

export const sendMessageToNativeApp = (interfaceName, value = "") => {
  window.webkit?.messageHandlers?.[interfaceName]?.postMessage?.(value);
  window.AndroidInterface?.[interfaceName]?.(value);
};

export const encodeMessage = (decodedMessage) => {
  try {
    const stringifiedMessage = JSON.stringify(decodedMessage);

    return window.btoa(stringifiedMessage);
  } catch (e) {
    return "";
  }
};
