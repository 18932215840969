/* eslint-disable sort-keys */
export default {
  light: {
    // header
    // TODO - Temporary local theme overrides for header. We will create an intake card
    // for WCM to publish these once we are set.
    heading: "primary-500",

    // application
    "primary-application-background": "neutral-700",

    // core overrides
    "input-foreground": "primary-500",

    // link
    "link-background-hover": "transparent",
    "link-focus": "secondary-vacations-700",

    // section
    "section-heading": "primary-500",
  },
  dark: {},
};
