import PropTypes from "prop-types";
import React, { useLayoutEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "regenerator-runtime/runtime";

import { MessageProvider, Version } from "@swa-ui/application";
import { getBootstrapData } from "@swa-ui/bootstrap";
import { window } from "@swa-ui/browser";
import { globalDefines } from "@swa-ui/core/defines";
import { BusinessLightTheme, CustomerLightTheme, VacationsLightTheme } from "@swa-ui/design-tokens";
import {
  DotcomApp,
  DotcomMessages,
  DotcomSystemMessages,
  getApiKey,
  getChannelId,
} from "@swa-ui/dotcom";
import { EncryptionProvider } from "@swa-ui/encryption";
import { RecentSearchProvider } from "@swa-ui/form";
import { logger } from "@swa-ui/log";
import { PlacementFactory } from "@swa-ui/placements";
import { classNames } from "@swa-ui/string";
import { useColorTheme } from "@swa-ui/theme";
import {
  FLIGHT_CAR,
  FLIGHT_HOTEL,
  FLIGHT_HOTEL_CAR,
  getOJTManageMyVacationSchema,
  ManageMyVacationCallToAction,
  ManageMyVacationsFlyout,
  UpdatePassengerInfoCallToAction,
  vacationsBookingFormSchema,
  VacationsBookingHero,
  VacationsBookingWidget,
  VacationsPackagesPlacement,
  VacationsRecentSearch,
} from "@swa-ui/vacations";

import { bootstrapDependencies } from "../../configure/configureBootstrap";
import { getMktgExportSchemas } from "../../schema/getMktgExportSchemas";
import appOverridesForVacationsTheme from "../../vacations-theme";
import { LandingPageFooter } from "../LandingPageFooter";
import { LandingPageHeader } from "../LandingPageHeader";
import { MboxScripts } from "../MboxScripts";
import { VendorScripts } from "../VendorScripts";
import "./App.module.scss";
import styles from "./App.module.scss";

/**
 * App is responsible for rendering the header, footer, placements and any other forms into any static html page.
 */
const FOOTER = window.document.getElementById("footer");
const PLACEMENT_ELEMENTS = window.document.getElementsByClassName("placement");

const App = (props) => {
  const { placementParameters = "", themeName } = props;
  const { media } = globalDefines;
  const themeSettings = getThemeSettings(themeName);
  const { HeaderTheme, PageTheme } = themeSettings;
  const { addThemeEntries } = useColorTheme();

  useLayoutEffect(() => {
    if (themeName === "vacations") {
      addThemeEntries(appOverridesForVacationsTheme);
    }
  }, []);

  return (
    <BrowserRouter>
      <EncryptionProvider {...getEncryptionProviderProps()}>
        <RecentSearchProvider {...getRecentSearchProviderProps()}>
          <DotcomApp {...getDotcomAppProps()}>
            <MboxScripts />
            <VendorScripts />
            <MessageProvider>
              <Version basePath={"/swa-ui/fm/dotcom-landing-page/v1/"} />
              <div className={getContainerClassName()}>
                <HeaderTheme>
                  <LandingPageHeader {...themeSettings} />
                  <DotcomSystemMessages />
                  <DotcomMessages className={styles.messages} />
                </HeaderTheme>
              </div>
              {Array.from(PLACEMENT_ELEMENTS).map((placementElement) =>
                ReactDOM.createPortal(
                  <PageTheme>
                    <PlacementFactory {...getPlacementFactoryProps(placementElement)} />
                  </PageTheme>,
                  placementElement
                )
              )}
              {FOOTER &&
                ReactDOM.createPortal(
                  <PageTheme>
                    <LandingPageFooter className={getContainerClassName()} />
                  </PageTheme>,
                  FOOTER
                )}
            </MessageProvider>
          </DotcomApp>
        </RecentSearchProvider>
      </EncryptionProvider>
    </BrowserRouter>
  );

  function getEncryptionProviderProps() {
    return {
      apiKey: getApiKey(),
      channelId: getChannelId(),
      jwtSettings: getBootstrapData("jwt-settings"),
      logger,
      schemas: getOJTManageMyVacationSchema(),
    };
  }

  function getRecentSearchProviderProps() {
    return {
      schemas: {
        [FLIGHT_CAR]: vacationsBookingFormSchema(),
        [FLIGHT_HOTEL]: vacationsBookingFormSchema(),
        [FLIGHT_HOTEL_CAR]: vacationsBookingFormSchema(),
      },
      storageKey: "vacations",
      supportedTypes: [FLIGHT_CAR, FLIGHT_HOTEL, FLIGHT_HOTEL_CAR],
    };
  }

  function getDotcomAppProps() {
    return {
      additionalContentParameters: { placementParameters },
      appId: "dotcom-landing-page",
      appPlacementComponents: {
        "manage-my-vacations": ManageMyVacationsFlyout,
        packages: VacationsPackagesPlacement,
      },
      basePath: "/dotcom-landing-page/",
      bootstrapDependencies,
      defaultAppSettings: {
        default: {
          loginAvailable: true,
          mktgExportSchemas: { ...getMktgExportSchemas() },
          searchAvailable: true,
          themeSelector: false,
        },
      },
      maintenanceCode: "503319996",
      placementsConfig: {
        configuredCommands: {
          SWAV_MANAGE_RESERVATION_ACTION: UpdatePassengerInfoCallToAction,
          SWAV_OJT_MANAGE_VACATION_ACTION: ManageMyVacationCallToAction,
        },
      },
    };
  }

  function getPlacementFactoryProps(placementElement) {
    return {
      componentRenderers: {
        vacationsBookingHero: VacationsBookingHero,
        vacationsBookingWidget: VacationsBookingWidget,
        vacationsRecentSearch: VacationsRecentSearch,
      },
      id: placementElement.attributes["data-placement-id"].value,
    };
  }

  function getThemeSettings(theme) {
    const themeConfig = {
      business: {
        HeaderTheme: BusinessLightTheme,
        light: true,
        PageTheme: BusinessLightTheme,
      },
      customer: {
        HeaderTheme: CustomerLightTheme,
        PageTheme: CustomerLightTheme,
      },
      vacations: {
        HeaderTheme: VacationsLightTheme,
        light: true,
        menuItemSeparator: "|",
        PageTheme: VacationsLightTheme,
        uppercaseMenuItems: false,
      },
    };

    return themeConfig[theme];
  }

  function getContainerClassName() {
    return classNames(styles.container, {
      [styles.desktopContainer]: isDesktopOnly(),
    });
  }

  function isDesktopOnly() {
    return media.mediumMax === "0px";
  }
};

App.prototype = {
  /** Additional placement parameters to be passed. */
  placementParameters: PropTypes.string,

  /** The name of the theme to use.  */
  themeName: PropTypes.oneOf(["business", "customer", "vacations"]),
};

App.defaultProps = {
  themeName: "customer",
};

export { App };
