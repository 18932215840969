import * as yup from "yup";

import { regexes } from "@swa-ui/form";
import i18n from "@swa-ui/locale";

export const KNOWN_TRAVELER_NUMBER_SCHEMA_FIELD_NAME = {
  KNOWN_TRAVELER_NUMBER: "knownTravelerNumber",
};

export const getKnownTravelerNumberFormFieldSchema = () =>
  yup
    .string()
    .when({
      is: (exists) => !!exists,
      then: yup.string().min(8),
    })
    .max(25)
    .matches(regexes.alphaNumeric)
    .label(i18n("KnownTravelerNumberFormField__LABEL").toLowerCase());
