export default {
  light: {
    // calendar
    "calendar-last-bookable-date-extension-text": "neutral-400",
    "calendar-last-bookable-date-text": "primary-100",
    "trip-selection-calendar-suffix-icon": "secondary-vacations-400",

    // package option button
    "option-button-background-focus": "transparent",
    "option-button-background-focus-visible": "transparent",
    "option-button-background-hover": "transparent",
    "option-button-border-focus": "transparent",
    "option-button-border-focus-visible": "primary-500",
    "option-button-border-hover": "transparent",
    "option-button-box-shadow": "secondary-vacations-400",
    "option-button-foreground": "primary-500",

    // package card
    "package-original-amount-foreground": "neutral-300",
    "package-savings-amount-foreground": "primary-300",

    // recent search
    "recent-search-card-border": "primary-500",
    "recent-search-icon-background": "neutral-900",
    "recent-search-text-color": "primary-200",

    // room selection
    "room-selection-separator-border": "neutral-200",

    // traveler selector
    "traveler-type-border": "neutral-500",
    "traveler-type-foreground": "primary-200",
    "traveler-type-description-foreground": "neutral-200",
    "traveler-type-select-age-label-color": "primary-500",
  },
  dark: {},
};
