import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import { getBootstrapData } from "@swa-ui/bootstrap";
import { window } from "@swa-ui/browser";
import { Autocomplete, Button, Icon, keyCodes } from "@swa-ui/core";
import i18n from "@swa-ui/locale";
import { HighlightSubstring } from "@swa-ui/string";
import { classNames } from "@swa-ui/string";

import { getEnvironmentTier } from "../DotcomApp";
import styles from "./SearchAutocomplete.module.scss";

const MINIMUM_CHARS_TO_SEARCH = 3;
const SEARCH_COOL_DOWN = 1200;

/**
 * The SearchAutocomplete components provides the autocomplete content for the GlobalHeader Special Offers and Rapid Rewards flyouts.
 */

export const SearchAutocomplete = (props) => {
  const { id, onSearch } = props;
  const { searchQueryUrl, searchSuggestionsParams, searchUrl } =
    getBootstrapData("search-environments");
  const [list, setList] = useState([]);
  const [value, setValue] = useState("");
  const valueRef = useRef("");

  let typing_timer;

  useEffect(() => {
    valueRef.current = value;

    if (isDisabled()) {
      setList([]);
    }
  }, [value]);

  return (
    <div className={styles.searchAutocomplete}>
      <div className={styles.inputBody}>
        <label className={styles.label}>
          {i18n("SearchAutocomplete__TEXT")}
          <Autocomplete {...getAutocompleteProps()} />
        </label>
      </div>
      <Button {...getButtonProps()}>
        <Icon {...getIconProps()} />
      </Button>
    </div>
  );

  function getAutocompleteProps() {
    return {
      "aria-describedby": props["aria-describedby"],
      className: styles.inputSection,
      id,
      inputStyleType: "secondary",
      list,
      minimumListWidth: "full-width",
      noMatchFoundText: "",
      onChange: handleChange,
      onInputChange: handleInputChange,
      onKeyDown: handleKeyDown,
      readOnly: false,
      resultsClassname: styles.results,
      showPointer: false,
      skipTransformation: true,
      value,
    };
  }

  function getButtonProps() {
    return {
      "aria-label": props["aria-label"],
      className: getClass(),
      disabled: isDisabled(),
      onClick: handleClick,
      size: "small",
      styleType: "primary",
    };
  }

  function getClass() {
    return classNames(styles.button, {
      [styles.disabled]: isDisabled(),
    });
  }

  function getIconProps() {
    return {
      color: "blue-2",
      disabled: isDisabled(),
      iconClassName: styles.icon,
      name: "Search",
      size: "size32",
    };
  }

  function handleChange(event) {
    setValue(event.target.value);
  }

  async function handleInputChange(event) {
    const { value: input } = event.target;

    window.clearTimeout(typing_timer);
    setValue(input);

    if (input.length >= MINIMUM_CHARS_TO_SEARCH) {
      typing_timer = window.setTimeout(async () => {
        await loadSuggestions(valueRef.current);
      }, SEARCH_COOL_DOWN);
    }
  }

  function handleKeyDown(event) {
    event.key === keyCodes.KEY_ENTER && handleClick();
  }

  async function loadSuggestions(input) {
    const suggestions = await getSuggestions(input);

    if (isEnabled()) {
      setList(suggestions.map(highlightResultsSubstring.bind(this, input)));
    }
  }

  function highlightResultsSubstring(input, suggestion) {
    return {
      label: HighlightSubstring({ substring: input, value: suggestion.disp }),
      value: suggestion.disp,
    };
  }

  async function getSuggestions(input) {
    const domainName = createDomainName();
    const query = createQuery(input);
    const response = await window.fetch(`${domainName}${query}`);

    return await response.json();
  }

  function createDomainName() {
    let environment = getEnvironmentTier();

    if (environment === "local") {
      environment = "dev";
    }

    return searchUrl[environment.toUpperCase()];
  }

  function createQuery(query) {
    const { params } = searchSuggestionsParams;

    return `${params}&partial_query=${query}`;
  }

  function handleClick() {
    window.location.href = createHref();

    onSearch && onSearch(value);
  }

  function createHref() {
    return `${createDomainName()}${searchQueryUrl}${value}`;
  }

  function isEnabled() {
    return !isDisabled();
  }

  function isDisabled() {
    return valueRef.current.length < MINIMUM_CHARS_TO_SEARCH;
  }
};

SearchAutocomplete.propTypes = {
  /** aria-describedby id to element which provides additional accessibility description of input element. */
  "aria-describedby": PropTypes.string,

  /** aria-label id which provides additional accessibility description of button element. */
  "aria-label": PropTypes.string,

  /** ID to be added to the element. */
  id: PropTypes.string,

  /**
   * Callback which informs caller when SearchAutoComplete button is click, and passes the SearchAutoComplete criteria as the only argument.
   */
  onSearch: PropTypes.func,
};
