"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fireUpcomingTripsCardAnalytics = exports.fireStopRememberingLinkAnalytics = exports.fireClearSearchesLinkAnalytics = exports.fireBookingWidgetAnalytics = void 0;
var _analytics = require("@swa-ui/analytics");
var fireBookingWidgetAnalytics = exports.fireBookingWidgetAnalytics = function fireBookingWidgetAnalytics(tabName) {
  (0, _analytics.fireTrack)("squid", {
    page_description: "paneldisplayed:".concat(tabName)
  });
};
var fireClearSearchesLinkAnalytics = exports.fireClearSearchesLinkAnalytics = function fireClearSearchesLinkAnalytics() {
  (0, _analytics.fireTrack)("squid", {
    page_description: "link:clear searches"
  });
};
var fireStopRememberingLinkAnalytics = exports.fireStopRememberingLinkAnalytics = function fireStopRememberingLinkAnalytics() {
  (0, _analytics.fireTrack)("squid", {
    page_description: "link:stop remembering"
  });
};
var fireUpcomingTripsCardAnalytics = exports.fireUpcomingTripsCardAnalytics = function fireUpcomingTripsCardAnalytics(upcomingTrips) {
  var _upcomingTrips$data, _upcomingTrips$data2;
  (upcomingTrips === null || upcomingTrips === void 0 || (_upcomingTrips$data = upcomingTrips.data) === null || _upcomingTrips$data === void 0 ? void 0 : _upcomingTrips$data.length) > 0 && (0, _analytics.fireTrack)("squid", {
    page_description: "banner:new upcoming trips",
    upcomingtrips_count: "".concat(upcomingTrips === null || upcomingTrips === void 0 || (_upcomingTrips$data2 = upcomingTrips.data) === null || _upcomingTrips$data2 === void 0 ? void 0 : _upcomingTrips$data2.length)
  });
};