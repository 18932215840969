import PropTypes from "prop-types";
import React from "react";

export const Winter = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path d="M7.50016 14.6667V11.4167L5.01683 13.9L4.30016 13.2L7.50016 10V8.50004H6.00016L2.86683 11.6334L2.1335 10.95L4.5835 8.50004H1.3335V7.50004H4.5835L2.0835 5.00004L2.80016 4.28337L6.00016 7.50004H7.50016V5.98337L4.36683 2.85004L5.06683 2.11671L7.50016 4.56671V1.33337H8.50016V4.56671L11.0002 2.06671L11.7002 2.78337L8.50016 5.98337V7.50004H10.0168L13.1668 4.35004L13.8835 5.05004L11.4335 7.50004H14.6668V8.50004H11.4335L13.9002 10.9834L13.2168 11.7L10.0168 8.50004H8.50016V10L11.7002 13.2167L11.0168 13.9334L8.50016 11.4167V14.6667H7.50016Z" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "0 0 16 16",
    };
  }
};

Winter.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Winter.defaultProps = { role: "img" };
