import PropTypes from "prop-types";
import React from "react";

export const Refund = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path d="m9.3,19.52h3.71c.98,0,1.47,1.18.77,1.87-1.99,1.99-3.98,3.98-5.97,5.97-.43.43-1.12.43-1.55,0-1.95-1.95-3.94-3.94-5.94-5.94-.69-.69-.2-1.87.77-1.87h3.81c.12-5.9,2.31-10.84,6.71-14.72C14.8,2,18.55.43,22.8.07c9.58-.81,17.6,5.18,20.24,13.29,2.76,8.47-.51,17.74-7.93,22.57-6.3,4.1-14.2,4.03-20.2.65-1.25-.71-1.45-2.43-.41-3.41l.03-.03c.7-.66,1.73-.79,2.57-.32,3.13,1.73,6.6,2.3,10.31,1.6,3.43-.64,6.29-2.35,8.57-4.98,4.49-5.18,4.9-12.89,1.02-18.55-3.9-5.67-11.19-8-17.73-5.62-6.27,2.28-10.03,8.23-9.96,14.25Z" />
      <path d="m28.81,19.52c0,2.41-1.94,4.36-4.35,4.36-2.39,0-4.33-1.94-4.35-4.33-.02-2.38,1.97-4.38,4.36-4.37,2.38,0,4.34,1.96,4.34,4.34Z" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "0 -5 50 50",
    };
  }
};

Refund.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Refund.defaultProps = { role: "img" };
