import PropTypes from "prop-types";
import React from "react";

import { classNames } from "@swa-ui/string";

import { getColorVariable } from "../getColorVariable";
import { Transform, transformPropTypes } from "../Transform";
import styles from "./Endow.module.scss";

/**
 * Endow provides an common entry point for all child icons, which are separate components. Endow simply instantiate the
 * desired child component.
 */

export const Endow = (props) => {
  const {
    actions,
    background,
    border,
    children,
    className,
    delay,
    disabled,
    duration,
    height,
    id,
    onClick,
    onTransformationEnd,
    style,
    tabIndex,
    width,
  } = props;

  return (
    <Transform {...getTransformProps()}>
      <span {...getContainerProps()}>{children}</span>
    </Transform>
  );

  function getTransformProps() {
    return {
      className,
      delay,
      duration,
      id,
      onTransformationEnd,
      style,
      transformations: [
        getOpacityTransformation(),
        getRotateXTransformation(),
        getRotateYTransformation(),
        getRotateZTransformation(),
        getScaleXTransformation(),
        getScaleYTransformation(),
      ],
    };
  }

  function getContainerProps() {
    const containerProps = {
      className: classNames(getBackgroundClass(), getBorderClass(), getContainerClass()),
      onClick,
      style: {
        backgroundColor: getBackgroundColor(),
        borderColor: getBorderColor(),
        height,
        width,
      },
      tabIndex,
    };

    if (!isEmpty(border)) {
      let { type } = border;

      if (!type) {
        type = "thick-circle";
      }

      containerProps.style.borderWidth = type === "thick-circle" ? parseInt(height) * 0.125 : 2;
    }

    return containerProps;
  }

  function getBackgroundClass() {
    let backgroundClassName = "";

    if (!isEmpty(background)) {
      const { type } = background;

      backgroundClassName = classNames({
        [styles.circle]: type === "circle",
        [styles.roundedSquare]: type === "rounded-square",
      });
    }

    return backgroundClassName;
  }

  function getBorderClass() {
    let borderClassName = "";

    if (!isEmpty(border)) {
      let { type } = border;

      if (!type) {
        type = "thick-circle";
      }

      borderClassName = classNames({
        [styles.thickCircle]: type === "thick-circle",
        [styles.thinCircle]: type === "thin-circle",
      });
    }

    return borderClassName;
  }

  function getContainerClass() {
    return classNames({
      [styles.container]: true,
      [styles.disabled]: disabled,
    });
  }

  function getBackgroundColor() {
    let backgroundColor = background?.color;

    if (!isEmpty(background)) {
      if (disabled) {
        backgroundColor = "body-disabled";
      } else if (!isEmpty(background) && !background.color) {
        backgroundColor = "body-primary";
      }
    }

    return getColorVariable(backgroundColor);
  }

  function getBorderColor() {
    const borderColor = border?.color;

    return getColorVariable(disabled ? "body-disabled" : borderColor);
  }

  function getOpacityTransformation() {
    let transformationAction = { action: "opacity", amount: "1" };

    if (actions.indexOf("fade") !== -1) {
      transformationAction = { action: "opacity", amount: "0" };
    }

    return transformationAction;
  }

  function getRotateXTransformation() {
    let transformationAction = { action: "rotateX", amount: "0deg" };

    if (actions.indexOf("flip") !== -1) {
      transformationAction = { action: "rotateX", amount: "180deg" };
    }

    return transformationAction;
  }

  function getRotateYTransformation() {
    let transformationAction = { action: "rotateY", amount: "0deg" };

    if (actions.indexOf("spin") !== -1) {
      transformationAction = { action: "rotateY", amount: "180deg" };
    }

    return transformationAction;
  }

  function getRotateZTransformation() {
    let transformationAction = { action: "rotateZ", amount: "0deg" };

    if (actions.indexOf("rotate45") !== -1) {
      transformationAction = { action: "rotateZ", amount: "45deg" };
    } else if (actions.indexOf("rotate90") !== -1) {
      transformationAction = { action: "rotateZ", amount: "90deg" };
    } else if (actions.indexOf("rotate180") !== -1) {
      transformationAction = { action: "rotateZ", amount: "180deg" };
    } else if (actions.indexOf("rotate270") !== -1) {
      transformationAction = { action: "rotateZ", amount: "270deg" };
    } else if (actions.indexOf("rotate360") !== -1) {
      transformationAction = { action: "rotateZ", amount: "360deg" };
    } else if (actions.indexOf("rotate720") !== -1) {
      transformationAction = { action: "rotateZ", amount: "720deg" };
    } else if (actions.indexOf("rotate1080") !== -1) {
      transformationAction = { action: "rotateZ", amount: "1080deg" };
    }

    return transformationAction;
  }

  function getScaleXTransformation() {
    let transformationAction = { action: "scaleX", amount: "1" };

    if (actions.indexOf("explode") !== -1) {
      transformationAction = { action: "scaleX", amount: "2" };
    } else if (actions.indexOf("implode") !== -1) {
      transformationAction = { action: "scaleX", amount: "0" };
    }

    return transformationAction;
  }

  function getScaleYTransformation() {
    let transformationAction = { action: "scaleY", amount: "1" };

    if (actions.indexOf("explode") !== -1) {
      transformationAction = { action: "scaleY", amount: "2" };
    } else if (actions.indexOf("implode") !== -1) {
      transformationAction = { action: "scaleY", amount: "0" };
    }

    return transformationAction;
  }
};

function isEmpty(objectToCheck) {
  return !(objectToCheck && Object.keys(objectToCheck).length);
}

export const endowPropTypes = {
  /**
   * Animation options to apply to child content. These actions can be applied as long as they don't conflict. For
   * example, it wouldn't make sense to rotate90 and rotate270. To "reset", specify none.
   */
  actions: PropTypes.arrayOf(
    PropTypes.oneOf([
      "explode",
      "fade",
      "flip",
      "implode",
      "none",
      "rotate45",
      "rotate90",
      "rotate180",
      "rotate270",
      "rotate360",
      "rotate720",
      "rotate1080",
      "spin",
    ])
  ),

  /** Optional background to surround icon. */
  background: PropTypes.shape({
    color: PropTypes.string,
    type: PropTypes.oneOf(["circle", "rounded-square", "square"]),
  }),

  /** Optional border to surround icon.  */
  border: PropTypes.shape({
    color: PropTypes.string,
    type: PropTypes.oneOf(["thick-circle", "thin-circle"]),
  }),

  /** Content that will be rendered. */
  children: PropTypes.node.isRequired,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Milliseconds before transformation begins. */
  delay: transformPropTypes.delay,

  /** Indicates child should apply disabled styling. */
  disabled: PropTypes.bool,

  /**
   * By default, the duration of the animation will be determined by ReactSpring's physics algorithm, but can be set
   * to a milliseconds value, or zero for immediate movement. If zero is given, the onTransitionMethod event will not
   * be fired.
   */
  duration: transformPropTypes.duration,

  /** Height to set during transformation. */
  height: PropTypes.string,

  /** ID to be added to the element. */
  id: PropTypes.string,

  /** Callback that will be called when Endow's content is clicked. */
  onClick: PropTypes.func,

  /** Informs the caller if actions is given that the animation has concluded. */
  onTransformationEnd: PropTypes.func,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /** Object to style the HTML span element. */
  style: PropTypes.object,

  /** Optional value to place Endow's content (typically an icon or SVG) in the browser's tab order. */
  tabIndex: PropTypes.number,

  /** Height to set during transformation. */
  width: PropTypes.string,
};

Endow.propTypes = endowPropTypes;

Endow.defaultProps = {
  actions: ["none"],
};
