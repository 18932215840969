import PropTypes from "prop-types";
import React from "react";

import { HorizontalList } from "@swa-ui/core";
import { classNames } from "@swa-ui/string";

import styles from "./HorizontalListAdapter.module.scss";

/**
 * HorizontalListAdapter renders a list of items using core horizontal list component with our desired customizations
 */

export const HorizontalListAdapter = (props) => {
  const { align } = props;

  return <HorizontalList {...getHorizontalListProps()} />;

  function getHorizontalListProps() {
    return {
      ...props,
      className: getHorizontalListClass(),
    };
  }

  function getHorizontalListClass() {
    return classNames({
      [styles.horizontalListCenterAlign]: align === "center",
      [styles.horizontalListLeftAlign]: align === "left",
      [styles.horizontalListRightAlign]: align === "right",
    });
  }
};

HorizontalListAdapter.propTypes = {
  /** Determines the alignment of the HorizontalList. */
  align: PropTypes.oneOf(["center", "left", "right"]),

  /** List of options to be rendered. */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      componentProps: PropTypes.object,
      tagName: PropTypes.oneOf(["button", "div", "link", "span"]),
    })
  ),

  /** Prop to be passed to child elements for styling on top of a dark background. */
  light: PropTypes.bool,

  /**
   * Role given to container. This will typically 'menubar', but when HorizontalList is used for a
   * legend, and there's no actionable items given in list, then role will be set to 'list'. This
   * prop will allow a custom role to be set if needed.
   */
  role: PropTypes.string,

  /** The amount of space before and after the heading can be set to one of the predefined settings. */
  spacing: PropTypes.oneOf([
    "large",
    "medium",
    "small",
    "space-around",
    "space-between",
    "space-evenly",
  ]),
};
