import { clone, getValue, setValue } from "@swa-ui/object";

export const applyOverrides = (content, screenSize) => {
  let newContent = clone(content ?? {});

  if (typeof newContent === "object") {
    if (newContent.overrides) {
      newContent = { ...newContent, ...newContent?.overrides?.[screenSize] };

      delete newContent.overrides;
    } else {
      const contentWithOverrides = Object.entries(newContent).reduce(
        (accumulatedContent, contentEntry) => {
          const [contentKey, contentData] = contentEntry;
          const contentOverridePaths = getOverridePaths(contentData);

          return {
            ...accumulatedContent,
            ...(contentOverridePaths.length > 0 ? { [contentKey]: contentOverridePaths } : {}),
          };
        },
        {}
      );

      Object.entries(contentWithOverrides).forEach((contentEntry) => {
        const [contentName, overridePaths] = contentEntry;

        overridePaths.forEach((contentOverridePath) => {
          const fullOverridePath = `${contentName}.${contentOverridePath}`;
          const overriddenData = applyOverrides(getValue(newContent, fullOverridePath), screenSize);

          setValue(newContent, fullOverridePath, overriddenData);
        });
      });
    }
  }

  return newContent;

  function getOverridePaths(object, currentPath) {
    return Object.entries(object ?? {}).reduce((accumulatedObjectPath, objectEntry) => {
      const [key, value] = objectEntry;
      const nextPath = [currentPath, key].filter(Boolean).join(".");

      if (key === "overrides") {
        accumulatedObjectPath = [...accumulatedObjectPath, currentPath];
      } else {
        if (typeof value === "object") {
          accumulatedObjectPath = [...accumulatedObjectPath, ...getOverridePaths(value, nextPath)];
        }
      }

      return accumulatedObjectPath;
    }, []);
  }
};
