import React, { useContext } from "react";

import { DialogContext } from "../Dialog";
import { Heading, headingPropTypes } from "../Heading";

/**
 * DialogHeading renders a heading appropriate for a Dialog. Sets the id of the heading to the
 * labelledById of the current Dialog.
 */
export const DialogHeading = (props) => {
  const { children, id } = props;
  const { labelledById } = useContext(DialogContext) ?? {};

  return (
    <Heading {...props} id={labelledById ?? id}>
      {children}
    </Heading>
  );
};

DialogHeading.propTypes = headingPropTypes;
