import PropTypes from "prop-types";
import React from "react";

export const Messaging = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path
        d="M851.74,419.64,848,422.06v-2.42H841.8l-.11-.11v-15.8l.11-.11h21.86a1.14,1.14,0,0,1,.11.11v2.21h3v-2.76a2.73,2.73,0,0,0-2.55-2.56h-23a2.74,2.74,0,0,0-2.56,2.56v16.88a2.73,2.73,0,0,0,2.56,2.55h4v4.76l7.17-4.76h2.59v-3h-3.34Z"
        transform="translate(-838.74 -400.62)"
      />
      <path
        d="M858,428.23a2.74,2.74,0,0,0,2.55,2.56H872.3l7.17,4.76v-4.76h4a2.73,2.73,0,0,0,2.55-2.56V411.35a2.73,2.73,0,0,0-2.55-2.56h-23a2.74,2.74,0,0,0-2.55,2.56Z"
        transform="translate(-838.74 -400.62)"
      />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "0 0 47.3 34.93",
    };
  }
};

Messaging.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Messaging.defaultProps = {
  role: "img",
};
