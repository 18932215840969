import PropTypes from "prop-types";
import React from "react";

export const MobilePhone = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path
        d="M19.167,0.5h-17c-1.033,0-2,0.973-2,2v38c0,1.027,0.761,1.982,1.793,1.982L19.167,42.5c1.033,0,2-0.973,2-2v-38
			C21.167,1.473,20.2,0.5,19.167,0.5z M8.167,2.5h5c0.279,0,0.608,0.225,0.608,0.502c0,0.276-0.329,0.498-0.608,0.498h-5
			c-0.279,0-0.545-0.211-0.545-0.488C7.621,2.735,7.888,2.5,8.167,2.5z M10.167,40.5c-1.104,0-2-0.896-2-2s0.896-2,2-2s2,0.896,2,2
			S11.271,40.5,10.167,40.5z M19.167,35.5h-17v-30h17V35.5z"
      />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      style,
      viewBox: "0 0 21 43",
    };
  }
};

MobilePhone.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

MobilePhone.defaultProps = {
  role: "img",
};
