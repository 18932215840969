import PropTypes from "prop-types";
import React, { useContext } from "react";

import { MktgMediatorProvider } from "@swa-ui/application";

import { ChaseContext } from "../ChaseProvider";

/**
 * Dotcom wrapper around MktgMediatorProvider
 */

export const DotcomMktgMediatorProvider = (props) => {
  const { children } = props;
  const { getChaseContext } = useContext(ChaseContext);

  return (
    <MktgMediatorProvider getAdditionalMboxContextAsync={getChaseContext}>
      {children}
    </MktgMediatorProvider>
  );
};

DotcomMktgMediatorProvider.propTypes = {
  /** Content to be rendered inside MktgMediatorProvider wrapper. */
  children: PropTypes.node.isRequired,
};
