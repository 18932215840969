import PropTypes from "prop-types";
import React from "react";

export const Passenger = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path d="M131.724,50.708c0,29.359-20.657,55.619-46.102,55.619c-25.464,0-46.109-26.26-46.109-55.619 C39.513,21.35,60.158,0,85.622,0C111.066,0,131.724,21.35,131.724,50.708z" />
      <path d="M137.888,105.574c-3.963-1.438-13.046-4.252-13.046-4.252c-15.41,14.241-39.403,26.63-39.403,26.63 s-26.526-13.803-40.114-27.55c0,0-10.206,3.423-15.012,5.172C5.301,114.715,0,131.309,0,137.658c0,6.337,0.999,12.146,0.999,12.146 s27.907,22.273,84.661,22.273c56.741,0,81.507-22.273,81.507-22.273s1.004-5.809,1.004-12.146 C168.171,131.309,162.858,114.715,137.888,105.574z" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      style,
      viewBox: "0 0 168 172",
    };
  }
};

Passenger.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Passenger.defaultProps = {
  role: "img",
};
