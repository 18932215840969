import { useContext } from "react";

import { MktgDataContext } from "../MktgDataProvider";

export const useMktgData = () => {
  const { mktgData } = useContext(MktgDataContext);

  return { mktgData };
};

export const getMktgDataItem = (mktgData, path, defaultValue) => {
  let value = defaultValue;

  if (path && path.split) {
    const pathItems = path.split(".");

    value = pathItems.reduce(
      (current, pathItem) => (current && current[pathItem] ? current[pathItem] : defaultValue),
      { mktg_data: mktgData }
    );
  }

  return value;
};
