import PropTypes from "prop-types";
import React from "react";

const initialState = {
  error: null,
  errorInfo: null,
};

/**
 * The ErrorBoundary component is responsible for catching runtime JS errors.
 * You can wrap any high React component with this, and if a JS error occurs, the
 * `FallbackComponent` prop will render in its place.
 */
export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  static propTypes = {
    /** The component to be rendered when a JS error occurs in the child */
    FallbackComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  };

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });
  }

  render() {
    const { children, FallbackComponent } = this.props;
    const { error, errorInfo } = this.state;
    let value = children;

    if (errorInfo) {
      value = FallbackComponent ? (
        <FallbackComponent
          error={error}
          errorInfo={errorInfo}
          resetErrorBoundary={this.resetErrorBoundary.bind(this)}
        />
      ) : null;
    }

    return value;
  }

  resetErrorBoundary() {
    this.setState(initialState);
  }
}
