import PropTypes from "prop-types";
import React from "react";

import { FetchInterceptor } from "@swa-ui/fetch";

export const MfaResponseTransformer = (props) => {
  const { children } = props;

  return <FetchInterceptor onResponse={handleFetchResponse}>{children}</FetchInterceptor>;

  function handleFetchResponse(response) {
    const errorBody =
      response.data?.screen?.properties?.customHTML?.value?.nextstep?.errors?.errorDetails?.error;
    let newResponse = response;

    if (errorBody) {
      const statusCode = parseInt(errorBody.errorCode.substring(0, 3));

      newResponse = response.clone();

      newResponse.data = {
        ...response.data,
        code: errorBody.errorCode,
        httpStatusCode: statusCode,
        requestId: errorBody.InteractionID,
      };
    }

    return newResponse;
  }
};

MfaResponseTransformer.propTypes = {
  /** Content that will be rendered as the page body. */
  children: PropTypes.node.isRequired,
};
