import PropTypes from "prop-types";
import React from "react";

export const MapSearch = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path d="M19.2,5.2c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4c0.8,0,1.4,0.6,1.4,1.4C20.5,4.6,19.9,5.2,19.2,5.2 M19.2,0c-2.1,0-3.8,1.7-3.8,3.9c0,0.7,0.1,1.3,0.4,2c1.2,3.1,3.4,5.8,3.4,5.8s2.2-2.6,3.4-5.7C22.8,5.3,23,4.6,23,3.9 C23,1.7,21.3,0,19.2,0" />
      <path d="M10.8,18.9l-4.4,2.9V9.3L10.8,7V18.9z M5.6,22l-3.9-2.2V7.2l3.9,2.1V22z M21.1,11.8v7.9L17,22V11.1l-0.8-1.4 v12.1l-4.5-2.9V7l3.9,2l-1.3-2.5c0,0-2.4-1.2-2.6-1.3c-0.2-0.1-0.6-0.1-0.8,0C10.6,5.2,6,7.6,6,7.6S1.2,5,0.8,4.9 C0.5,4.7,0,4.9,0,5.3v14.5c0,0.5,0.3,1,0.7,1.3c0.4,0.2,4.6,2.6,4.9,2.8c0.2,0.1,0.6,0.2,0.8,0c0.3-0.2,4.8-3.2,4.8-3.2 s4.7,3,4.9,3.2c0.2,0.2,0.5,0.2,0.8,0c0.3-0.2,4.5-2.5,5-2.8c0.6-0.3,0.8-0.7,0.8-1.4V9L21.1,11.8z" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "0 0 25 25",
    };
  }
};

MapSearch.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

MapSearch.defaultProps = {
  role: "img",
};
