import { getBootstrapData } from "@swa-ui/bootstrap";

import { getResponseErrorTrackingCode } from "../getResponseErrorTrackingCode";

export const getResponseErrorKey = (response, fallbackKey) => {
  const responseBody = response?.data;
  const getServiceErrorFromCode = () => getKeyFromErrorCodeMap(responseBody?.errors?.[0]);
  const getServiceErrorFromResponse = () => getKeyFromErrorCodeMap(response);
  const getServiceFormErrors = () => responseBody?.notifications?.formErrors?.[0]?.code;
  const getServiceFieldErrors = () => responseBody?.notifications?.fieldErrors?.[0]?.code;

  return (
    getServiceErrorFromCode() ??
    getServiceErrorFromResponse() ??
    getServiceFormErrors() ??
    getServiceFieldErrors() ??
    fallbackKey
  );

  function getKeyFromErrorCodeMap(error) {
    const url = error?.url ?? "";
    const trackingCode = getResponseErrorTrackingCode(error);
    const errorCodeMap = getBootstrapData("error-code-map") ?? {};
    const errorCodeUrl = Object.keys(errorCodeMap)?.find((key) => url.includes(key));

    return (
      errorCodeMap?.[errorCodeUrl]?.[trackingCode] ?? errorCodeMap?.["default"]?.[trackingCode]
    );
  }
};
