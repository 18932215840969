import { useContext, useEffect, useState } from "react";

import { encryptData } from "../encryptData";
import { EncryptionContext } from "../EncryptionProvider";

/**
 * Hook that will create a jwe token and then return the full href path with the encrypted search token as a query param
 * @param {object} dataToEncrypt - The content to be encrypted in the searchToken.
 * @param {string} path - The base path of the url
 * @param {string} component - The component triggering the hook call
 * @param {string} feature - The feature value to determine which encryption method to use
 *    Must be set in jwt-settings in bootstrap data
 * @returns {string} - Returns href with encrypted search token if encryptData returns valid url
 *    Otherwise, returns back "path" parameter without adding a queryParam
 */

export const useHref = (dataToEncrypt, path, component, feature) => {
  const [href, setHref] = useState(path);
  const { encrypt, refreshEncryptionToken } = useContext(EncryptionContext);

  useEffect(() => {
    async function encryptDataAndSetHref() {
      const url = await encryptData(encrypt, dataToEncrypt, path, component, feature);

      url && setHref(url);
    }
    encryptDataAndSetHref();
  }, [refreshEncryptionToken]);

  return { href };
};
