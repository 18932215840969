import { useForm as useReactHookForm } from "react-hook-form";

/**
 *  useForm is a custom hook that simply wraps React hook form to allow future features if ever required.
 */

const useForm = (options) =>
  useReactHookForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    shouldFocusError: true,
    ...options,
  });

export { useForm };
