import PropTypes from "prop-types";
import React from "react";

import { contentPropTypes } from "@swa-ui/core";

import { applyOverrides } from "../applyOverrides";
import { HtmlValue } from "../HtmlValue";
import { isHtmlValue } from "../isHtmlValue";

const getBackgroundStyle = (background, screenSize) => {
  const { color, imagePath, type } = applyOverrides(background, screenSize);

  return {
    ...(type === "image" && { backgroundImage: `url(${imagePath})` }),
    ...(type === "color" && { backgroundColor: color }),
  };
};

const getTextProps = (textType) => {
  const { color, fontSize, fontWeight } = textType || {};

  return {
    style: {
      color,
      fontSize,
      fontWeight,
    },
  };
};

const renderText = (textTypeObject) => {
  const { value } = textTypeObject || {};

  return (
    value &&
    (isHtmlValue(value) ? (
      <HtmlValue htmlValue={value} />
    ) : (
      <div {...getTextProps(textTypeObject)}>{value}</div>
    ))
  );
};

const backgroundPropType = PropTypes.shape({
  color: PropTypes.string,
  imagePath: PropTypes.string,
  overrides: PropTypes.shape({
    large: PropTypes.shape({
      imagePath: PropTypes.string,
    }),
    medium: PropTypes.shape({
      imagePath: PropTypes.string,
    }),
    small: PropTypes.shape({
      imagePath: PropTypes.string,
    }),
  }),
  type: PropTypes.oneOf(["color", "image", "none"]),
});

const callToActionPropType = PropTypes.shape({
  buttonType: PropTypes.string,
  newWindow: PropTypes.bool,
  target: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.string,
});

const graphicPropType = PropTypes.shape({
  align: PropTypes.oneOf(["left", "right", "top"]),
  altText: PropTypes.string,
  imagePath: PropTypes.string,
  overrides: PropTypes.shape({
    large: PropTypes.shape({
      display: PropTypes.string,
      imagePath: PropTypes.string,
    }),
    medium: PropTypes.shape({
      display: PropTypes.string,
      imagePath: PropTypes.string,
    }),
    small: PropTypes.shape({
      display: PropTypes.string,
      imagePath: PropTypes.string,
    }),
  }),
});

const textPropType = PropTypes.shape({
  color: PropTypes.string,
  fontSize: PropTypes.string,
  fontSizing: contentPropTypes.fontSizing,
  fontWeight: PropTypes.string,
  size: contentPropTypes.size,
  value: PropTypes.string,
});

const headingPropType = PropTypes.shape({
  below: PropTypes.bool,
  center: PropTypes.bool,
  color: PropTypes.string,
  value: PropTypes.string,
});

export {
  backgroundPropType,
  callToActionPropType,
  getBackgroundStyle,
  graphicPropType,
  headingPropType,
  renderText,
  textPropType,
};
