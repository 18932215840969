import { useContext, useEffect, useState } from "react";

import { EncryptionContext } from "../EncryptionProvider";

/**
 * Hook that will return only the encrypted searchToken
 * @param {object} dataToEncrypt - The content to be encrypted in the searchToken.
 * @param {string} component - The component triggering the hook call
 * @param {string} feature - The feature value to determine which encryption method to use
 *    Must be set in jwt-settings in bootstrap data
 * @returns {string} - Returns encrypted searchToken
 */

export const useSearchToken = (dataToEncrypt, component, feature) => {
  const [searchToken, setSearchToken] = useState();
  const { encrypt, refreshEncryptionToken } = useContext(EncryptionContext);

  useEffect(() => {
    async function encryptSearchToken() {
      const JWEToken = await encrypt?.(dataToEncrypt, component, feature);

      setSearchToken(JWEToken);
    }
    encryptSearchToken();
  }, [refreshEncryptionToken]);

  return { searchToken };
};
