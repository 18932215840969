"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ROUTES = void 0;
var ROUTES = exports.ROUTES = {
  destination: "/vacations/destination",
  destinationDetail: "/vacations/destination-detail",
  destinationAll: "/vacations/destination-all",
  deals: "/vacations/deals",
  vacations: "/vacations/",
  whySouthwestGetaways: "/vacations/why-southwest-getaways"
};