/**
 * setValue sets a value inside an object, traversing to the provided "path" parameter. This function mutates
 * the object passed in as the first parameter.
 *
 * @todo Need to support array traversal in path
 *
 * @param {object} object the object to set the value inside
 * @param {string|array} path either a string or array of instructions on how to traverse the object structure
 * @param {*} value value to set after traversing the object structure
 * @param {object} [options] additional options to further enhance usage
 * @param {string} options.delimiter string delimiter used to process the `path` parameter
 *
 * @example <caption>Use the return value</caption>
 * setValue({}, "foo.bar.baz", true);
 * @returns `{ foo: bar: { baz: true } }`
 *
 * @example <caption>Mutate the object parameter</caption>
 * const obj = { foo: "bar" };
 *
 * setValue(obj, "baz", true);
 * @returns obj is now set to `{ foo: "bar", baz: true }`
 */

export const setValue = (object, path, value, options = {}) => {
  const { delimiter = "." } = options;
  const [currentKey, ...remainingKeys] = splitPath(path);

  if (remainingKeys.length >= 1) {
    object[currentKey] = object[currentKey] ?? {};
    setValue(object[currentKey], remainingKeys.join(delimiter), value, options);
  } else {
    object[currentKey] = value;
  }

  return object;

  function splitPath(pathToSplit) {
    if (Array.isArray(pathToSplit)) {
      pathToSplit = pathToSplit.join(delimiter);
    }

    return pathToSplit?.split(delimiter);
  }
};
