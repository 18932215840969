export const keyCodes = {
  KEY_BACKSPACE: "Backspace",
  KEY_DELETE: "Delete",
  KEY_DOWN: "ArrowDown",
  KEY_END: "End",
  KEY_ENTER: "Enter",
  KEY_ESCAPE: "Escape",
  KEY_HOME: "Home",
  KEY_LEFT: "ArrowLeft",
  KEY_PAGE_DOWN: "PageDown",
  KEY_PAGE_UP: "PageUp",
  KEY_RIGHT: "ArrowRight",
  KEY_SPACE: " ",
  KEY_TAB: "Tab",
  KEY_UP: "ArrowUp",
};
