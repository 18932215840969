import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import { useResizeObserver } from "@swa-ui/browser";
import { classNames } from "@swa-ui/string";

import { Transform } from "../Transform";
import styles from "./Area.module.scss";

/**
 * Area provides a way to have a div transition to concealed or revealed states. The transition from
 * one state to the other can be animated or immediate. Area will determine if its content changes
 * and will adjust to accommodate.
 */

export const Area = (props) => {
  const {
    animate,
    children,
    className,
    duration,
    fullHeight,
    id,
    onTransitionEnd,
    revealed,
    role,
  } = props;
  const [animating, setAnimating] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const [visible, setVisible] = useState(false);
  const ref = useRef(null);

  useResizeObserver({ callback: updateHeight, element: ref });

  useEffect(() => {
    if (animate && revealed !== visible) {
      setAnimating(true);
    }

    if (revealed) {
      setVisible(true);
    }
  }, [revealed]);

  return (
    <Transform {...getTransformProps()}>
      <div {...getPropsContainer()}>{children}</div>
    </Transform>
  );

  function getTransformProps() {
    return {
      className: classNames(className, { [styles.area]: animating }),
      duration: animate ? duration : 0,
      id,
      onTransformationEnd: animating && contentHeight !== 0 ? handleTransformationEnd : undefined,
      transformations: [
        {
          action: "height",
          amount: fullHeight ? "100%" : revealed && contentHeight ? `${contentHeight}px` : "0px",
        },
      ],
    };
  }

  function getPropsContainer() {
    return {
      ["aria-hidden"]: !revealed,
      className: classNames({ [styles.hidden]: !visible }),
      ref,
      role,
    };
  }

  function handleTransformationEnd() {
    setAnimating(false);
    setVisible(revealed);
    onTransitionEnd && onTransitionEnd(revealed);
  }

  function updateHeight(value) {
    setContentHeight(value[0].contentRect.height);
  }
};

export const areaPropTypes = {
  /** The transition to and from concealed/revealed states will be animated by default. */
  animate: PropTypes.bool,

  /** Content that will be rendered when Area is revealed. */
  children: PropTypes.node,

  /**
   * Additional classes for positioning the component. Given classes may only position this component
   * for layout purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * Length of animation in milliseconds. This value is simply passed through to Transform. For no
   * animation, specify zero. If no value is specified, then the default Spring easing will be used.
   */
  duration: PropTypes.number,

  /** Indicates if Area will fill/stretch to fill entire height of parent container. */
  fullHeight: PropTypes.bool,

  /**
   * id can be useful when another DOM element "controls" the concealed/revealed state. The
   * controlling element should use aria-control to reflect this correlation.
   */
  id: PropTypes.string,

  /**
   *  Function called when the area is revealed or concealed. The revealed state will be returned as
   *  a boolean.
   */
  onTransitionEnd: PropTypes.func,

  /**
   * Indicates if the area is concealed or revealed. Area does not maintain its revealed/concealed
   * state. It's up to the caller to control Area's state.
   */
  revealed: PropTypes.bool,

  /** The accessibility role for the content container. */
  role: PropTypes.string,
};

Area.propTypes = areaPropTypes;
Area.defaultProps = {
  animate: true,
  fullHeight: false,
  revealed: false,
};
