import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";

import { useFormContext } from "@swa-ui/form";

import { PhoneCountryCodeFormField } from "../PhoneCountryCodeFormField";
import { PHONE_COUNTRY_CODE_SCHEMA_FIELD_NAMES } from "../PhoneCountryCodeFormField/PhoneCountryCodeFormField.schema";
import { PhoneNumberFormField } from "../PhoneNumberFormField";
import { PHONE_NUMBER_SCHEMA_FIELD_NAMES } from "../PhoneNumberFormField/PhoneNumberFormField.schema";
import { PhoneTypeFormField } from "../PhoneTypeFormField";
import { PHONE_TYPE_SCHEMA_FIELD_NAMES } from "../PhoneTypeFormField/PhoneTypeFormField.schema";

/**
 * PhoneFormFields is the combination of a country code dropdown and phone number text input,
 * applying conditional formatting to the phone number whether the country code is domestic.
 */

export const PhoneFormFields = (props) => {
  const {
    className,
    phoneNumberFieldComponentProps = {},
    countryCode,
    countryCodeFieldName,
    disabled,
    phoneNumber,
    phoneNumberFieldName,
    phoneType,
    phoneTypeDirection,
    phoneTypeFieldName,
    showPhoneType,
  } = props;
  const { setValue, watch } = useFormContext();
  const phoneCountryCodeFieldName =
    countryCodeFieldName ?? PHONE_COUNTRY_CODE_SCHEMA_FIELD_NAMES.PHONE_COUNTRY_CODE;
  const phoneNumberFormFieldName =
    phoneNumberFieldName ?? PHONE_NUMBER_SCHEMA_FIELD_NAMES.PHONE_NUMBER;
  const phoneTypeFormFieldName = phoneTypeFieldName ?? PHONE_TYPE_SCHEMA_FIELD_NAMES.PHONE_TYPE;
  const selectedCountryCode = watch(phoneCountryCodeFieldName) ?? countryCode ?? 1;
  const previouslySelectedCountryCode = useRef(selectedCountryCode);

  useEffect(() => {
    if (selectedCountryCode !== previouslySelectedCountryCode.current) {
      setValue(phoneNumberFormFieldName, "");
    }

    previouslySelectedCountryCode.current = selectedCountryCode;
  }, [selectedCountryCode]);

  return (
    <div className={className}>
      {showPhoneType && (
        <PhoneTypeFormField
          direction={phoneTypeDirection}
          disabled={disabled}
          defaultValue={phoneType}
          name={phoneTypeFormFieldName}
        />
      )}
      <PhoneCountryCodeFormField
        disabled={disabled}
        defaultValue={countryCode}
        name={countryCodeFieldName}
      />
      <PhoneNumberFormField
        componentProps={phoneNumberFieldComponentProps}
        disabled={disabled}
        domestic={selectedCountryCode === 1}
        defaultValue={phoneNumber}
        name={phoneNumberFieldName}
      />
    </div>
  );
};

PhoneFormFields.propTypes = {
  /** Class name for positioning the component. */
  className: PropTypes.string,

  /** Value used to control the country code form field. */
  countryCode: PropTypes.number,

  /** Name used to uniquely identify the PhoneCountryCodeFormField component. */
  countryCodeFieldName: PropTypes.string,

  /** Indicates whether the field is disabled. */
  disabled: PropTypes.bool,

  /** Value used to control the phone number form field. */
  phoneNumber: PropTypes.string,

  /** Additional props to pass to the phone number form field component. */
  phoneNumberFieldComponentProps: PropTypes.object,

  /** Name used to uniquely identify the PhoneNumberFormField component. */
  phoneNumberFieldName: PropTypes.string,

  /** Value used to control the phone type form field. */
  phoneType: PropTypes.string,

  /** Orientation that the PhoneTypeFormField is displayed in. */
  phoneTypeDirection: PropTypes.oneOf(["row", "column"]),

  /** Name used to uniquely identify the PhoneTypeFormField component. */
  phoneTypeFieldName: PropTypes.string,

  /** Toggle whether to display the PhoneTypeFormField component. */
  showPhoneType: PropTypes.bool,
};

PhoneFormFields.defaultProps = {
  phoneTypeDirection: "row",
  showPhoneType: false,
};
