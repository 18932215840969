import PropTypes from "prop-types";
import React from "react";

import { classNames } from "@swa-ui/string";

import { TransitionBlock } from "../TransitionBlock";
import styles from "./Day.module.scss";

const DELAY_FOR_THINGS_WITH_BACKGROUND = 50;
const DURATION = 175;
const DURATION_FOR_THINGS_WITH_BACKGROUND = 250;

/**
 * Day is a simple component to represent a date cell in Calendar. It simply manages its display styling and provides no
 * other functionality other than informing caller when a date is selected.
 */

const TRANSITION_IN = {
  transformations: [
    { action: "opacity", amount: "1" },
    { action: "scaleX", amount: "1" },
    { action: "scaleY", amount: "1" },
  ],
};
const TRANSITION_OUT = {
  transformations: [
    { action: "opacity", amount: "0.125" },
    { action: "scaleX", amount: "0.6" },
    { action: "scaleY", amount: "0.8" },
  ],
};
const TRANSITION_OUT_SELECTED = {
  transformations: [
    { action: "opacity", amount: "0" },
    { action: "scaleX", amount: "1" },
    { action: "scaleY", amount: "1" },
  ],
};

export const Day = (props) => {
  const {
    className,
    content,
    dateEnd,
    dateStart,
    directionChange,
    hover,
    inRange,
    onClick,
    onMouseEnter,
    onMouseLeave,
    selectable,
  } = props;

  return (
    <div
      data-unstable
      className={getClass()}
      onMouseDown={handleMouseDown}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <TransitionBlock {...getTransitionBlockProps()}>{content}</TransitionBlock>
    </div>
  );

  function getTransitionBlockProps() {
    const delay = typeof content === "string" && content.length ? parseInt(content) * 5 : undefined;
    const selectedOrRange = hasSelectedOrRange();
    const transitionIn = TRANSITION_IN;
    const transitionOut = selectedOrRange ? TRANSITION_OUT_SELECTED : TRANSITION_OUT;

    transitionIn.duration = selectedOrRange ? DURATION_FOR_THINGS_WITH_BACKGROUND : DURATION;
    transitionOut.duration = selectedOrRange ? DURATION_FOR_THINGS_WITH_BACKGROUND : DURATION;

    return {
      animationToken: content,
      className: getContentClass(),
      delay: selectedOrRange ? delay + DELAY_FOR_THINGS_WITH_BACKGROUND : delay,
      transformOrigin: directionChange === "next" ? "right-center" : "left-center",
      transitionIn,
      transitionOut,
    };
  }

  function getClass() {
    return classNames(className, styles.day, {
      [styles.disabled]: !selectable,
      [styles.hover]: hover,
      [styles.inRange]: inRange,
    });
  }

  function getContentClass() {
    return classNames(className, {
      // [styles.dateBoth]: dateEnd && dateStart, TODO decide if we're going to use this
      [styles.content]: true,
      [styles.hover]: hover,
      [styles.inRange]: inRange,
      [styles.selected]: dateEnd || dateStart,
    });
  }

  function handleMouseDown(event) {
    event.preventDefault();
    event.stopPropagation();

    if (selectable) {
      onClick && onClick();
    }
  }

  function handleMouseEnter() {
    if (selectable) {
      onMouseEnter && onMouseEnter();
    }
  }

  function handleMouseLeave() {
    if (selectable) {
      onMouseLeave && onMouseLeave();
    }
  }

  function hasSelectedOrRange() {
    return dateEnd || dateStart || inRange;
  }
};

Day.propTypes = {
  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** The date number that will appear for each of the calendar's day "cells" will be passed as content. */
  content: PropTypes.string,

  /** If dateEnd is set, then this is the last day in a range and will get styling to represent this. */
  dateEnd: PropTypes.bool,

  /**
   * If dateEnd is set, then this is the first day in a range and will get styling to represent this. Should dateEnd
   * and dateStart both be set, then the styling for this Day will reflect both start and end.
   */
  dateStart: PropTypes.bool,

  /**
   * when updating the day "cells", next/previous defines whether the animation appears to slide in from the right or
   * left.
   */
  directionChange: PropTypes.oneOf(["next", "previous"]),

  /** Indicates which date the traveler is selecting. */
  focusField: PropTypes.oneOf(["end", "start"]),

  /**
   * Indicates if the "inRange" styling should be applied because this Day component lays between start and end dates.
   */
  inRange: PropTypes.bool,

  /** One or two dates can be selected. This number defines how selected and hover states appear. */
  numberDates: PropTypes.number,

  /**
   * Event handler to learn when date is selected. The handler will receive the selected date in the MM/DD/YYYY
   * format.
   * */
  onClick: PropTypes.func,

  /**
   * Boolean to indicate if this Day component should respond to mouse events. It also will apply "disabled" styling
   * when it is not selectable.
   */
  selectable: PropTypes.bool,
};

Day.defaultProps = {
  selectable: true,
};
