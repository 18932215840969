import { useRef, useState } from "react";

import { useDismiss } from "../useDismiss";

/**
 * useCaption encapsulates the functionality required to drive a Caption component.
 * Current functionality:
 * - ESC to close
 * - close on clicking outside of component.
 *
 * location: String that indicates the directional position of the caption.
 * initialLocation: String that indicates the starting directional position of the caption.
 * onClose: callback to be informed when Dialog closes.
 */

export const useCaption = (
  location = "above",
  initialLocation = "hidden",
  onClose = () => {},
  restoreFocusOnClose = true
) => {
  const [captionLocation, setCaptionLocation] = useState(initialLocation);
  const captionRef = useRef(null);
  const isCaptionVisible = captionLocation !== "hidden";

  useDismiss(captionRef, hideCaption, isCaptionVisible, onClose, restoreFocusOnClose);

  return {
    captionLocation,
    captionRef,
    hideCaption,
    isCaptionVisible,
    showCaption,
  };

  function hideCaption() {
    setCaptionLocation("hidden");
  }

  function showCaption() {
    setCaptionLocation(location);
  }
};
