import { Button } from "@swa-ui/core";

import { withFormField } from "../withFormField";

/**
 * SubmitFormField provides support for a Button component to be used as a submit
 * button in a Form.
 */

export const SubmitFormField = withFormField(Button, {
  fieldFocusFromLabel: false,
  shouldRegister: false,
  type: "submit",
});
