import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import { getBootstrapData } from "@swa-ui/bootstrap";
import { useDeviceInfo, window } from "@swa-ui/browser";
import {
  Autocomplete,
  autocompletePropTypes,
  Button,
  Caption,
  CloseButton,
  Input,
  keyCodes,
  TransitionBlock,
} from "@swa-ui/core";
import i18n from "@swa-ui/locale";
import { classNames } from "@swa-ui/string";

import {
  getAllCarLocations,
  getCarLocation,
  getCarLocationForName,
  getCarLocationName,
} from "../CarLocations";
import { CAR } from "../defines/itineraryType";
import {
  addSimpleLabelValue,
  getIndexLetters,
  highlightMatchArea,
  shouldShowAutocomplete,
} from "../utilities/helpers/AutocompleteHelpers";
import styles from "./AutocompleteLocations.module.scss";

const DELAY_FOR_BROWSER = 16;
const MINIMUM_CHARS_TO_SEARCH = 3;

/**
 * AutocompleteLocations facilitates selecting a car location by filter selectable locations according to user entry
 * matches.
 *
 * Note that the calling application must set up bootstrap so location data is available. Primarily this requires
 * setDataKeyPrefix be called with application's prefix key.
 */

export const AutocompleteLocations = React.forwardRef((props, ref) => {
  const {
    className,
    defaultValue,
    disabled,
    error,
    id,
    labelText,
    maxItemsToDisplay,
    minimumListWidth,
    noMatchFoundText,
    onBlur,
    onChange,
    option,
    required,
    softEdge,
    suppressValidationTriggerOnChange,
    value,
  } = props;
  const { isTouchDevice } = useDeviceInfo();
  const [currentList, setCurrentList] = useState([]);
  const [currentValue, setCurrentValue] = useState(value);
  const [location, setLocation] = useState("hidden");
  const containerRef = useRef([]);
  const sectionRefs = getIndexLetters(CAR).map(() => useRef());
  const stations = getBootstrapData("car/car-locations-data");
  const { KEY_ENTER, KEY_ESCAPE, KEY_TAB } = keyCodes;
  let stationsAdded = useRef([]);

  useEffect(() => {
    const list = getList(value);

    setCurrentValue(value);
    setCurrentList(list);
  }, [value]);

  return <>{isTouchDevice ? renderFullScreen() : <Autocomplete {...getProps()} />}</>;

  function renderFullScreen() {
    return (
      <Caption {...getCaptionProps()}>
        <Input {...getInputProps()} />
      </Caption>
    );
  }

  function renderFullScreenContent() {
    return (
      <>
        <div className={styles.mainContainer} onKeyDown={handleEscape}>
          <div className={styles.headingOptions}>
            <div className={styles.labelText}>{labelText}</div>
            <Input {...getSearchInputProps()} />
          </div>
          <div className={styles.options} ref={containerRef}>
            <TransitionBlock
              animationToken={
                currentValue?.length >= MINIMUM_CHARS_TO_SEARCH ? currentList.length : "none"
              }
            >
              {shouldShowAutocomplete(currentValue, currentList)
                ? renderAutocompleteList()
                : renderList()}
            </TransitionBlock>
          </div>
        </div>
        <CloseButton {...getCloseButtonProps()} />
        {renderIndex()}
      </>
    );
  }

  function renderAutocompleteList() {
    return <ul className={styles.list}>{renderAutocompleteItems()}</ul>;
  }

  function renderAutocompleteItems() {
    return currentList.map((resultItem, index) => (
      <li {...getListItemProps(resultItem, index)} key={index}>
        {resultItem.label}
      </li>
    ));
  }

  function renderList() {
    const indexLetters = getIndexLetters(CAR);

    return indexLetters.map((indexLetter) => (
      <div key={indexLetter} ref={sectionRefs[indexLetter]}>
        <div className={styles.letterSection}>
          <div>{indexLetter}</div>
          <div className={styles.separator} />
        </div>
        {getAllCarLocations()?.map((station) => {
          const stationName = getCarLocationName(station.id);
          const firstLetter = stationName.charAt(0).toUpperCase();

          return firstLetter && indexLetter === firstLetter ? (
            <Button {...getButtonProps(station.id)}>{stationName}</Button>
          ) : null;
        })}
      </div>
    ));
  }

  function renderIndex() {
    const indexLetters = getIndexLetters(CAR);

    return (
      <div className={styles.indexContainer}>
        <div className={getIndexClass()}>
          {indexLetters.map((indexLetter, index) => (
            <Button key={indexLetter} {...getIndexButtonProps(index)}>
              {indexLetter}
            </Button>
          ))}
        </div>
      </div>
    );
  }

  function getProps() {
    return {
      "aria-describedby": props["aria-describedby"],
      "aria-required": required,
      className,
      "data-test": props["data-test"],
      defaultValue,
      disabled,
      error,
      id,
      inputMode: "text",
      list: currentList,
      location,
      maxItemsToDisplay,
      minimumListWidth,
      noMatchFoundText,
      onBlur: handleBlur,
      onChange: handleChange,
      onInputChange: handleInputChange,
      option,
      readOnly: false,
      ref,
      required,
      showArrow: false,
      softEdge,
      value: currentValue,
    };
  }

  function getCaptionProps() {
    return {
      adjoiningContent: renderFullScreenContent(),
      constrainFocus: false,
      id,
      location,
      mainClassName: styles.mainContent,
      showPointer: false,
      stackingContext: false,
    };
  }

  function getCloseButtonProps() {
    return {
      "aria-label": i18n("AutocompleteLocations__CLOSE_BUTTON__ARIA_LABEL"),
      className: styles.closeButton,
      onClick: handleClose,
      styleType: "no-style",
    };
  }

  function getInputProps() {
    return {
      "aria-describedby": props["aria-describedby"],
      disabled,
      error,
      id,
      inputMode: "none",
      onFocus: () => {
        setLocation("full-screen");
      },
      onInputChange: handleInputChange,
      option,
      readOnly: location === "full-screen",
      required,
      value: currentValue,
    };
  }

  function getListItemProps(listItem, index) {
    return {
      className: classNames(listItem.className, styles.listItem, {
        [styles.disabled]: listItem.disabled,
      }),
      onClick: handleAutocompleteClick.bind(this, index),
    };
  }

  function getSearchInputProps() {
    return {
      className: styles.input,
      onChange: handleInputChange,
      onKeyDown: handleKeyDown,
      value: currentValue,
    };
  }

  function getButtonProps(stationId) {
    return {
      className: styles.stationOption,
      key: stationId,
      onClick: handleClick.bind(this, stationId),
      styleType: "no-style",
      tabIndex: -1,
    };
  }

  function getIndexButtonProps(index) {
    return {
      className: styles.indexButton,
      onClick: handleIndexSelection.bind(this, index),
      styleType: "no-style",
      tabIndex: -1,
    };
  }

  function getIndexClass() {
    return classNames({
      [styles.indexes]: [styles.indexes],
      [styles.container]: shouldShowAutocomplete(currentValue, currentList),
    });
  }

  function handleBlur(event) {
    processValueOnExit();
    onBlur && onBlur(event);
  }

  function handleChange(event) {
    const stationId = getCarLocationForName(event.target.value)?.id;

    setCurrentValue(stationId);
    onChange && onChange({ target: { value: stationId } });
  }

  function handleClick(stationId) {
    setLocation("hidden");
    setCurrentValue(stationId);
    onChange && onChange({ target: { value: stationId } });
  }

  function handleInputChange(event) {
    const list = getList(event.target.value);

    setCurrentList(list);
    setCurrentValue(event.target.value);

    if (list.length !== currentList.length && !isTouchDevice) {
      setLocation("hidden");
      window.setTimeout(() => setLocation("below"), DELAY_FOR_BROWSER);
    }
  }

  function getList(newValue) {
    let list = [];

    if (newValue?.length >= MINIMUM_CHARS_TO_SEARCH) {
      newValue = newValue.toUpperCase();
      list = getStationList(newValue);

      if (!list.length && ((isTouchDevice && currentValue?.length) || !isTouchDevice)) {
        list = [
          {
            disabled: true,
            label: <i className={styles.invalidRoute}>{noMatchFoundText}</i>,
            value: noMatchFoundText,
          },
        ];
      }
    }

    return list;
  }

  function handleKeyDown(event) {
    const { key } = event;

    if (key === KEY_ENTER) {
      processValueOnExit();
      setLocation("hidden");
    } else if (key === KEY_TAB) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  function handleClose() {
    if (currentValue !== null && currentValue !== undefined) {
      processValueOnExit();
    }
    setLocation("hidden");
  }

  function handleAutocompleteClick(index) {
    const stationId = getCarLocationForName(currentList[index].value)?.id;

    setCurrentValue(stationId);
    onChange && onChange({ target: { value: stationId } });
    setLocation("hidden");
  }

  function handleEscape(event) {
    const { key } = event;

    if (key === KEY_ESCAPE) {
      onChange && onChange({ target: { value: currentValue } });
      setLocation("hidden");
    }
  }

  function handleIndexSelection(index) {
    containerRef.current.scrollTo({
      behavior: "smooth",
      left: 0,
      top:
        sectionRefs[index].current.getBoundingClientRect().y -
        containerRef.current.getBoundingClientRect().top +
        containerRef.current.scrollTop,
    });
  }

  function getStationList(searchCriteria) {
    stationsAdded.current = [];

    let resultList = getAirportMatch(searchCriteria);

    resultList = resultList.concat(getNameMatch(searchCriteria));

    resultList = resultList.map((station) => highlightMatchArea(CAR, searchCriteria, station));

    return resultList;
  }

  function getAirportMatch(searchCriteria) {
    const airportMatch = getCarLocation(searchCriteria);
    const resultList = [];

    if (airportMatch) {
      stationsAdded = addSimpleLabelValue(resultList, airportMatch, stationsAdded);
    }

    return resultList;
  }

  function getNameMatch(searchCriteria) {
    const resultList = [];

    stations.forEach((station) => {
      const { displayName } = station;
      const index = displayName.toUpperCase().indexOf(searchCriteria);

      if (index === 0 || index !== -1) {
        stationsAdded = addSimpleLabelValue(resultList, station, stationsAdded);
      }
    });

    return resultList;
  }

  function processValueOnExit() {
    let newValue = currentValue;

    if (newValue.length === MINIMUM_CHARS_TO_SEARCH) {
      const capitalizedNewValue = newValue.toUpperCase();
      const stationInfo = getCarLocation(capitalizedNewValue);

      if (stationInfo) {
        newValue = capitalizedNewValue;
        setCurrentValue(newValue);
      }
    }

    onChange &&
      onChange(
        { target: { value: newValue } },
        { suppressTrigger: suppressValidationTriggerOnChange }
      );
  }
});

AutocompleteLocations.propTypes = {
  /** aria-describedby id to element which provides additional accessibility description of input element. */
  "aria-describedby": PropTypes.string,

  /** Additional class for the component to provide a background color. */
  className: PropTypes.string,

  /**
   * Initial values to select in list. Each value should be separated by a pipe character. For example:
   * "option one|option three".
   */
  defaultValue: PropTypes.string,

  /** Indicates Input should apply disabled styling and ignore mouse and key events. */
  disabled: autocompletePropTypes.disabled,

  /**
   * Indicates Dropdown should apply error styling and apply aria-invalid attribute -- in essence, Input will
   * provide error styling.
   */
  error: autocompletePropTypes.error,

  /** ID to be added to input element. */
  id: autocompletePropTypes.id,

  /**
   * Text to appear about the search field that's displayed in the mobile view. This label would typically be the
   * same text that appears above the autocomplete field such as 'Depart' or 'Return'.
   */
  labelText: PropTypes.string.isRequired,

  /** Total number of items visible. If more items available than can be displayed, they can be scrolled into view. */
  maxItemsToDisplay: autocompletePropTypes.maxItemsToDisplay,

  /** Width of dropdown. See autocompletePropTypes/DropDown for more info. */
  minimumListWidth: autocompletePropTypes.minimumListWidth,

  /** Required text to display when no match is found. */
  noMatchFoundText: PropTypes.string.isRequired,

  /** Optional event handler to learn when focus is lost from input element. */
  onBlur: autocompletePropTypes.onBlur,

  /**
   * Optional callback to learn of selection changes. The values of the selected items will be returned as pipe-
   * separated strings. See defaultValue prop description for an example.
   */
  onChange: autocompletePropTypes.onChange,

  /** Option button to appear on left side of Input. See Input for more details. */
  option: autocompletePropTypes.option,

  /** Indicates Input should apply aria-required attribute. */
  required: PropTypes.bool,

  /** Indicates if the dropdown list requires soft edges. */
  softEdge: PropTypes.bool,

  /** Optional Suppression of React-hook-form's trigger on-change. Thus preventing validation
   * check until the time specified by the form's mode. */
  suppressValidationTriggerOnChange: PropTypes.bool,

  /** To allow the application to "control" the Input's value, the value prop may be given. */
  value: PropTypes.string,
};

AutocompleteLocations.defaultProps = {
  minimumListWidth: "large",
  softEdge: false,
  suppressValidationTriggerOnChange: false,
};
