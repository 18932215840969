import PropTypes from "prop-types";
import React from "react";

export const Share = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path d="M24.1,13.3v6.3l13.6-9.8L24.1,0v6.2c-4,0.1-13.7,1.7-15,15.4C9.1,21.5,14.6,13.2,24.1,13.3" />
      <path d="M28.2,25.8H4.2V9.5H9c1.2-1.6,2.6-3,4.2-4.1c0,0,0,0,0,0H2.1C0.9,5.3,0,6.2,0,7.4v20.5C0,29,0.9,30,2.1,30 h28.2c1.1,0,2.1-0.9,2.1-2.1v-9.1l-4.2,3V25.8z" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "0 0 37.8 30",
    };
  }
};

Share.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Share.defaultProps = {
  role: "img",
};
