import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { classNames } from "@swa-ui/string";

import globalRules from "../assets/styles/globalRules.module.scss";
import { keyCodes } from "../defines/keyCodes";
import { Icon } from "../Icon";
import styles from "./Checkbox.module.scss";

const TRANSFORM_DURATION = 100;

/**
 * Checkbox provides a SWA themed radio checkbox analogous to a standard HTML checkbox. Checkbox creates a hidden HTML
 * checkbox to allow autofill in forms to preselect a checkbox.
 * Accessibility in this component is provided through the aria attributes applied on the custom div element,
 * instead of the native input element.
 */

export const Checkbox = React.forwardRef((props, ref) => {
  const {
    className,
    defaultValue,
    disabled,
    label,
    name,
    onBlur,
    onChange,
    onMouseDown,
    required,
    right,
    tabIndex,
    value,
  } = props;
  const [currentValue, setCurrentValue] = useState(value || defaultValue);
  const [transformationStep, setTransformationStep] = useState("");

  useEffect(() => {
    setTransformationStep("idle");
  }, []);

  useEffect(() => {
    value !== undefined && setCurrentValue(value);
  }, [value]);

  return (
    <>
      <input {...getHtmlCheckboxProps()} />
      <div {...getProps()}>
        {renderLabel(true)}
        <span className={styles.focusRing}>
          <span className={styles.checkboxIcon}>
            <Icon {...getIconProps()} />
          </span>
        </span>
        {renderLabel(false)}
      </div>
    </>
  );

  function renderLabel(before) {
    let content = null;

    if (before === right) {
      content = (
        <span className={styles.label} id={`${name}-id`}>
          {label}
        </span>
      );
    }

    return content;
  }

  function getProps() {
    return {
      "aria-checked": currentValue ? "true" : "false",
      "aria-disabled": disabled,
      "aria-labelledby": `${name}-id`,
      "aria-required": required,
      className: getClass(),
      onClick: handleClick,
      onKeyDown: handleKeyDown,
      onMouseDown,
      role: "checkbox",
      tabIndex: disabled ? -1 : tabIndex,
    };
  }

  function getHtmlCheckboxProps() {
    return {
      "aria-hidden": true,
      checked: currentValue ? true : false,
      className: globalRules.hiddenFromScreen,
      "data-test": props["data-test"],
      disabled,
      name,
      onBlur,
      onChange,
      ref,
      required,
      tabIndex: -1,
      type: "checkbox",
      value: currentValue,
    };
  }

  function getIconProps() {
    const custom = {
      type: !currentValue && transformationStep !== "setup" ? "inactive" : "active",
    };

    return {
      actions: !currentValue || transformationStep === "setup" ? ["fade"] : ["none"],
      className: styles.icon,
      color: disabled ? "body-disabled" : "checkbox-foreground",
      custom,
      duration: TRANSFORM_DURATION,
      name: "Check",
      onTransformationEnd: transformationStep === "setup" ? handleTransformationEnd : undefined,
      size: "size20",
    };
  }

  function getClass() {
    return classNames(className, {
      [styles.checkbox]: true,
      [styles.disabled]: disabled,
      [styles.right]: right,
      [styles.selected]: currentValue,
    });
  }

  function handleClick() {
    toggleSelection();
  }

  function handleKeyDown(event) {
    const { key } = event;

    if (key === keyCodes.KEY_SPACE || key === keyCodes.KEY_ENTER) {
      toggleSelection();
    }

    if (key !== keyCodes.KEY_TAB) {
      event.preventDefault();
    }
  }

  function handleTransformationEnd() {
    setTransformationStep("idle");
  }

  function toggleSelection() {
    const checked = !currentValue;

    value === undefined && setCurrentValue(checked);
    onChange?.(checked);

    if (!checked) {
      setTransformationStep("setup");
    }
  }
});

Checkbox.displayName = "Checkbox";

Checkbox.propTypes = {
  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Name to use for the data-test attribute used for automated testing. */
  "data-test": PropTypes.string,

  /** Indicates if checkbox is selected by default. */
  defaultValue: PropTypes.bool,

  /** Indicates Checkbox should apply disabled styling, ignore click events and provide aria-disabled attribute. */
  disabled: PropTypes.bool,

  /** Mandatory text/element to be rendered next to checkbox. */
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),

  /** Name given to checkbox when form is submitted. name is also used to set the id for the field. */
  name: PropTypes.string.isRequired,

  /** Callback that will be called when checkbox loses focus. */
  onBlur: PropTypes.func,

  /** Callback called when checkbox is checked or unchecked. */
  onChange: PropTypes.func,

  /** Callback called when outer DOM div element receives a mouse down event. */
  onMouseDown: PropTypes.func,

  /** Indicates Checkbox should apply aria-required attribute. */
  required: PropTypes.bool,

  /** When right is true, the checkbox will appear to the right of the label. */
  right: PropTypes.bool,

  /** Value given to outermost div element to define focus support. */
  tabIndex: PropTypes.number,

  /** Value to be maintained by caller. This will define Checkbox as a controlled component. */
  value: PropTypes.bool,
};

Checkbox.defaultProps = {
  defaultValue: false,
  onChange: () => {}, // because a value is set by this component, making it controlled, a change handler is required
  right: false,
  tabIndex: 0,
};
