import PropTypes from "prop-types";
import React from "react";

import { Layout } from "../Layout";
import { Loading, loadingPropTypes } from "../Loading";
import styles from "./DepictionBulletItem.module.scss";

/**
 * DepictionBulletItem renders a depiction of a bullet followed by a rectangle. See LoadingDepiction
 * examples.
 */
export const DepictionBulletItem = (props) => {
  const { bulletColor, height, itemColor, numberOfRows } = props;
  const rowCount = [...Array(numberOfRows)];

  return rowCount.map(renderOption);

  function renderOption(_, index) {
    return (
      <Layout bottom="large" className={styles.depictionBulletItem} key={index}>
        <Layout right="xlarge">
          <Loading color={bulletColor} height={height} shape="circle" />
        </Layout>
        <Loading color={itemColor} height={height} />
      </Layout>
    );
  }
};

DepictionBulletItem.propTypes = {
  /** Color of shape that will represent the bullet icon. */
  bulletColor: loadingPropTypes.color,

  /** Height of bullet and line item. */
  height: loadingPropTypes.height,

  /** Color of shape that will represent line item following the bullet. */
  itemColor: loadingPropTypes.color,

  /** Number of repetitions of this row. */
  numberOfRows: PropTypes.number,
};

DepictionBulletItem.defaultProps = {
  height: "small",
  numberOfRows: 3,
};
