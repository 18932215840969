import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";

import { useAppSetting } from "@swa-ui/application";
import { AuthContext, LoginForm, useUserInfo } from "@swa-ui/authentication";
import { window } from "@swa-ui/browser";
import { Dialog, Icon, Link, useCaption } from "@swa-ui/core";
import { Currency } from "@swa-ui/currency";
import i18n from "@swa-ui/locale";

import { DotcomHeaderMobileContext } from "../DotcomHeader/DotcomHeaderMobile";
import { getUrlForSite } from "../getUrlForSite";
import styles from "./MobileHeaderControl.module.scss";

/**
 * MobileHeaderControl provides the account and locale type controls for the header.
 */

export const MobileHeaderControl = (props) => {
  const { headerControlPortalId, site } = props;
  const [headerControlPortal, setHeaderControlPortal] = useState(null);
  const { captionRef, hideCaption, isCaptionVisible, showCaption } = useCaption();
  const { closeHeaderMenu } = useContext(DotcomHeaderMobileContext) || {};
  const { handleLogout, isLoggedIn } = useContext(AuthContext);
  const { isMemberAccount, getCustomerInfo } = useUserInfo();
  const loggedIn = isLoggedIn();
  const loginAvailable = useAppSetting("loginAvailable", false);

  useEffect(() => {
    setHeaderControlPortal(window.document.getElementById(headerControlPortalId));
  }, []);

  return (
    <>
      {loginAvailable && (loggedIn ? renderLoggedIn() : renderLoggedOut())}
      {loginAvailable && headerControlPortal && renderHeaderControlPortal()}
    </>
  );

  function renderLoggedIn() {
    return (
      <Link className={styles.logoutLink} light onClick={handleLogout}>
        {i18n("MobileHeaderControl__LOG_OUT")}
      </Link>
    );
  }

  function renderLoggedOut() {
    return (
      <>
        <Dialog className={styles.loginDialog} revealed={isCaptionVisible} options={[]}>
          <div ref={captionRef}>
            <LoginForm onLoggedIn={handleLoggedIn} />
          </div>
        </Dialog>
        <Link {...getLoginLinkProps()}>{i18n("MobileHeaderControl__LOG_IN_ENROLL")}</Link>
      </>
    );
  }

  function renderHeaderControlPortal() {
    return ReactDOM.createPortal(
      loggedIn ? renderLoggedInPortal() : renderLoggedOutPortal(),
      headerControlPortal
    );
  }

  function renderLoggedInPortal() {
    const { firstName, preferredName, redeemablePoints, tier, tierStatusPending } =
      getCustomerInfo() ?? {};
    const name = preferredName || firstName;
    const shouldShowTier = (isMemberAccount() && tier !== "NON_ELITE") || tierStatusPending;
    const tierBadgeTextKey = tierStatusPending
      ? "MobileHeaderControl__TIER_PENDING"
      : tier === "A_LIST_PREFERRED"
      ? "MobileHeaderControl__TIER_A_LIST_PREFERRED"
      : "MobileHeaderControl__TIER_A_LIST";

    return (
      <div className={styles.portal}>
        <div className={styles.accountDetails}>
          <div>
            {name && <div>{i18n("MobileHeaderControl__GREETING", { name })}</div>}
            {shouldShowTier && <div className={styles.tier}>{i18n(tierBadgeTextKey)}</div>}
          </div>
          {isMemberAccount() && (
            <div className={styles.balance}>
              <Currency {...getCurrencyProps(redeemablePoints)} />
              <div className={styles.symbol}>{i18n("MobileHeaderControl__POINTS_ABBREVIATED")}</div>
            </div>
          )}
        </div>
        <div className={styles.links}>
          <Link
            href={getUrlForSite("LOYALTY__MY_ACCOUNT__INDEX", site)}
            size="fontSize14"
            suffixIcon={{
              name: "ArrowThin",
              actions: ["rotate90"],
              transparentBorder: false,
            }}
          >
            {i18n("MobileHeaderControl__MY_ACCOUNT_LINK_TEXT")}
          </Link>
        </div>
      </div>
    );
  }

  function getCurrencyProps(value) {
    return {
      currencyCode: "POINTS",
      emphasis: false,
      showSymbol: false,
      value,
    };
  }

  function renderLoggedOutPortal() {
    return (
      <Link
        onClick={() => {
          showCaption();
          closeHeaderMenu && closeHeaderMenu();
        }}
        className={styles.menuLoginLink}
      >
        <Icon name="Profile" size="size26" />
        <span className={styles.menuLoginText}>
          <strong>{i18n("MobileHeaderControl__LOG_IN_OR_ENROLL")} </strong>
          {i18n("MobileHeaderControl__LOGIN_PURPOSE")}
        </span>
      </Link>
    );
  }

  function getLoginLinkProps() {
    return {
      className: styles.loginLink,
      onClick: () => {
        isCaptionVisible ? hideCaption() : showCaption();
      },
      light: true,
      size: "fontSize14",
    };
  }

  function handleLoggedIn() {
    hideCaption();
  }
};

MobileHeaderControl.propTypes = {
  /* id use to display login status in the menu */
  headerControlPortalId: PropTypes.string,

  /* Current site the application is running on. Sample values like 'southwest', 'corporate' or 'mobile' */
  site: PropTypes.string,
};

MobileHeaderControl.defaultProps = {};
