import PropTypes from "prop-types";
import React from "react";

export const UnaccompaniedMinor = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path d="M125.76,137.42c0,4.74-.75,9.08-.75,9.08,0,0-18.52,16.65-60.95,16.65S.75,146.5,.75,146.5c0,0-.75-4.34-.75-9.08s3.96-17.16,22.67-23.99c2.84-1.04,8.53-2.92,10.79-3.66,1.28-.54,2.66-1.05,4.12-1.52,7.17,7.35,16.46,11.86,26.46,11.86s18.78-4.27,25.86-11.26c1.22,.44,2.38,.91,3.46,1.4h.01s6.79,2.1,9.76,3.18c18.67,6.83,22.65,19.24,22.65,23.99ZM82.12,28.28L69.91,1c-.52-1.17-2.11-1.36-2.89-.34l-13.23,17.13c-.81-.24-1.69-.24-2.54,.05l-9.9,3.38c-1.02,.35-1.8,1.06-2.29,1.93l-21.51-5.36c-1.17-.29-2.22,.77-1.93,1.93l7.35,29.06c.32,1.28,1.89,1.75,2.87,.86l16.76-15.3c.99,.53,2.19,.67,3.33,.28l9.9-3.38c1.11-.38,1.95-1.18,2.43-2.16l22.19,1.58c1.28,.09,2.19-1.22,1.67-2.39Zm16.38,44.12c0-21.95-15.45-37.92-34.47-37.92s-34.48,15.97-34.48,37.92,15.44,41.59,34.48,41.59,34.47-19.64,34.47-41.59" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      style,
      viewBox: "0 0 125.76 163.15",
    };
  }
};

UnaccompaniedMinor.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

UnaccompaniedMinor.defaultProps = {
  role: "img",
};
