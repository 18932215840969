import React from "react";

import { getAllCarLocations, getCarLocationName } from "../../CarLocations";
import { AIR, CAR, VACATION } from "../../defines/itineraryType";
import { getStationName, getStations } from "../../Stations";
import { getDestinationName } from "./VacationDestinations/VacationDestinations";

const AIRPORT_CODE_LENGTH = 3;
const AREA_AIRPORTS = "AREA AIRPORTS";
const MINIMUM_CHARS_TO_SEARCH = 3;

/**
 * Function that would highlight the matched area.
 *
 * @param {string} type Type can be AIR or CAR
 * @param {string} searchCriteria This is the user entered characters
 * @param {object} listItem This is the station object
 * @returns {object}
 */

export function highlightMatchArea(type, searchCriteria, listItem) {
  const { disabled, value: listItemValue } = listItem;
  const allCaps = listItemValue.toUpperCase();
  let item = listItem;
  let matchIndex = allCaps.lastIndexOf(searchCriteria);

  if (
    matchIndex === -1 ||
    matchIndex !== listItemValue.length - AIRPORT_CODE_LENGTH ||
    searchCriteria.length !== MINIMUM_CHARS_TO_SEARCH
  ) {
    matchIndex = allCaps.indexOf(searchCriteria);
  }

  if (
    matchIndex !== -1 &&
    (type === CAR || (type === AIR && !disabled && allCaps.indexOf(AREA_AIRPORTS) === -1))
  ) {
    const newLabel = (
      <>
        {listItemValue.substring(0, matchIndex)}
        <strong>{listItemValue.substring(matchIndex, matchIndex + searchCriteria.length)}</strong>
        {listItemValue.substring(matchIndex + searchCriteria.length)}
      </>
    );

    item = {
      "aria-label": listItem?.["aria-label"],
      className: listItem.className,
      custom: listItem.custom,
      disabled,
      indent: listItem.indent,
      label: newLabel,
      role: listItem.role,
      value: listItem.value,
    };
  }

  return item;
}

/**
 * Function that would return the index letters of station or location name.
 * @param {string} type Type can be AIR, CAR, or VACATION
 * @param {array} list Optionally pass in the list, only used for VACATIONS
 * @returns {string[]}
 */

export function getIndexLetters(type, list = []) {
  const indexLetters = [];
  let stationData;

  if (type === CAR) {
    stationData = getAllCarLocations();
  } else if (type === VACATION) {
    stationData = list;
  } else {
    stationData = getStations();
  }

  stationData?.forEach((station) => {
    let stationName;

    if (type === CAR) {
      stationName = getCarLocationName(station.id);
    } else if (type === VACATION) {
      stationName = getDestinationName(station);
    } else {
      stationName = getStationName(station.id);
    }

    const firstLetter = stationName.charAt(0).toUpperCase();

    if (firstLetter && !indexLetters.includes(firstLetter)) {
      indexLetters.push(firstLetter);
    }
  });

  return indexLetters;
}

/**
 * Function to determine whether to display autoComplete or not.
 * @param {string} currentValue The value the user entered
 * @param {array} currentList The current list of matched stations
 * @returns {boolean}
 */

export function shouldShowAutocomplete(currentValue, currentList) {
  return currentValue && currentList && currentValue.length && currentList.length;
}

/**
 * Function that would add the matching station to the list.
 * @param {object} resultList List of stations which have matched the search criteria with all the details
 * @param {object} station The current station object being iterated
 * @param {string[]} stationsAdded This is an array of existing station code that have matched
 * @param {bool} custom This is an array of existing station code that have matched
 * @param {string} parent Parent object of the passed station
 * @returns {string[]} If the provided station matches, it gets appended to the stationsAdded and returned
 */

export function addSimpleLabelValue(resultList, station, stationsAdded, custom, parent) {
  const { displayName } = station;
  const stationId = station.id;

  if (stationsAdded.current.indexOf(stationId) === -1) {
    resultList.push({
      ["aria-label"]: parent?.displayName ? `${parent?.displayName} - ${displayName}` : displayName,
      custom,
      indent: parent !== undefined,
      label: displayName,
      value: displayName,
    });

    stationsAdded.current.push(stationId);
  }

  return stationsAdded;
}
