import { getBootstrapData } from "@swa-ui/bootstrap";
import { getSearchParam } from "@swa-ui/browser";

import { getEnvironmentTier } from "../DotcomApp";

export const getApiKey = () => {
  const environmentTier = getEnvironmentTier();

  return getSearchParam("apiKey") || getBootstrapData("api-keys")?.[environmentTier];
};
