import PropTypes from "prop-types";
import React, { useContext } from "react";

import {
  AnalyticsInterceptor,
  ApplicationContext,
  EnterpriseFetchOptions,
  MaintenanceProvider,
  MboxProvider,
  MktgDataProvider,
  UiMetadataProvider,
  usePageId,
} from "@swa-ui/application";
import { AuthProvider, UserInfoProvider } from "@swa-ui/authentication";
import { AuthResponseHandler } from "@swa-ui/authentication";
import { getBootstrapData } from "@swa-ui/bootstrap";
import { FetchProvider } from "@swa-ui/fetch";

import { ChaseProvider } from "../ChaseProvider";
import { ChatbotProvider } from "../ChatbotProvider";
import { getEnvironmentTier } from "../DotcomApp";
import { DotcomMktgMediatorProvider } from "../DotcomMktgMediatorProvider";
import { DotcomPlacementsProvider } from "../DotcomPlacementsProvider";
import { LocaleProvider } from "../LocaleProvider";
import { SiteStatusProvider } from "../SiteStatusProvider";

/**
 * Wrapper component to provide all context objects for southwest.com.
 */

export const DotcomProviders = (props) => {
  const {
    additionalContentParameters,
    appPlacementComponents = {},
    children,
    globalPlacementIds,
    placementsConfig,
  } = props;
  const {
    apiKey,
    appId,
    appVersion,
    cacheLife,
    cachePolicy,
    channelId,
    chapi,
    diagnostic,
    experienceId,
    maintenanceCodes,
    persona,
    screenSize,
    site,
    waitingRoomCode,
  } = useContext(ApplicationContext);
  const { pageId } = usePageId();
  const defaultPlacements = getBootstrapData("bootstrap-content/en/content");
  const environmentTier = getEnvironmentTier();

  return (
    <LocaleProvider>
      <EnterpriseFetchOptions {...getEnterpriseFetchOptions()}>
        <UiMetadataProvider>
          <MaintenanceProvider {...{ chapi, maintenanceCodes, waitingRoomCode }}>
            <MktgDataProvider>
              <AnalyticsInterceptor chapi={chapi}>
                <AuthResponseHandler>
                  <FetchProvider>
                    <AuthProvider {...{ environmentTier, persona, site }}>
                      <UserInfoProvider>
                        <SiteStatusProvider>
                          <DotcomPlacementsProvider {...getDotcomPlacementsProviderProps()}>
                            <MboxProvider {...getMboxProviderProps()}>
                              <ChaseProvider>
                                <ChatbotProvider>
                                  <DotcomMktgMediatorProvider>
                                    {children}
                                  </DotcomMktgMediatorProvider>
                                </ChatbotProvider>
                              </ChaseProvider>
                            </MboxProvider>
                          </DotcomPlacementsProvider>
                        </SiteStatusProvider>
                      </UserInfoProvider>
                    </AuthProvider>
                  </FetchProvider>
                </AuthResponseHandler>
              </AnalyticsInterceptor>
            </MktgDataProvider>
          </MaintenanceProvider>
        </UiMetadataProvider>
      </EnterpriseFetchOptions>
    </LocaleProvider>
  );

  function getEnterpriseFetchOptions() {
    return {
      apiKey,
      appId,
      appVersion,
      cacheLife,
      cachePolicy,
      channelId,
      diagnostic,
      experienceId,
      site,
    };
  }

  function getDotcomPlacementsProviderProps() {
    return {
      additionalContentParameters,
      appId,
      appPlacementComponents,
      globalPlacementIds,
      placementsConfig,
    };
  }

  function getMboxProviderProps() {
    return {
      appId,
      channelId,
      defaultPlacements,
      pageId,
      persona,
      screenSize,
      site,
      updateOnPageLoad: false,
    };
  }
};

DotcomProviders.propTypes = {
  /** Additional content parameters to be passed. */
  additionalContentParameters: PropTypes.object,

  /** Application supplied object mapping to custom placement components. */
  appPlacementComponents: PropTypes.object,

  /** Content to be rendered on the page. */
  children: PropTypes.node.isRequired,

  /** Global placementIds that should be persisted between placement calls. */
  globalPlacementIds: PropTypes.arrayOf(PropTypes.string),

  /** Indicates whether to enable hybrid webview capability. */
  hybridEnabled: PropTypes.bool,

  /** Configuration settings used when rendering placements. */
  placementsConfig: PropTypes.shape({
    configuredCommands: PropTypes.object,
  }),
};

DotcomProviders.defaultProps = {
  globalPlacementIds: [],
};
