import { getExperienceId } from "@swa-ui/application";
import { logger } from "@swa-ui/log";

export const LOGIN_RR_MISMATCH_ERROR_CODE = "200003999";

/**
 * isValidLogin compares credentials and experience ids between request and response to determine if login is valid
 * @param {string} requestCredential
 * @param {object} responseBody
 * @param {object} responseHeaders
 * @returns {boolean}
 */
export const isValidLogin = (requestCredential, responseBody, responseHeaders) => {
  const responseCredential = responseBody?.["customers.userInformation.credential"];
  const responseExperienceId = responseHeaders?.get?.("x-user-experience-id");
  const requestExperienceId = getExperienceId();
  const responseCredentialValid =
    !!requestCredential &&
    !!responseCredential &&
    requestCredential.toLowerCase() === responseCredential.toLowerCase();
  const responseExperienceIdValid =
    !!requestExperienceId && !!responseExperienceId && requestExperienceId === responseExperienceId;

  return responseCredentialValid && responseExperienceIdValid;
};

/**
 * logInvalidLoginError logs error login is invalid
 * @param {string} requestCredential
 * @param {object} responseBody
 * @param {object} responseHeaders
 */
export const logInvalidLoginError = (requestCredential, responseBody, responseHeaders) => {
  const accountNumber = responseBody?.["customers.userInformation.accountNumber"];
  const channelIdInResp = responseHeaders?.get?.("x-channel-id");
  const experienceIdInResp = responseHeaders?.get?.("x-user-experience-id");
  const experienceIdReceived = getExperienceId();
  const requestIdInResp = responseHeaders?.get?.("x-request-id");
  const requestId = `${experienceIdReceived}:${requestIdInResp}:${channelIdInResp}`;
  const errorMessage = {
    customerIdInResp: accountNumber,
    experienceIdInResp,
    experienceIdReceived,
    httpCode: LOGIN_RR_MISMATCH_ERROR_CODE,
    location: window.location.href,
    requestId,
    timestamp: new Date().toISOString(),
    usernameInResp: responseBody?.["customers.userInformation.credential"],
    usernameReceived: requestCredential,
  };

  logger.error("SERVER FAILURE", errorMessage);
};
