import PropTypes from "prop-types";
import React from "react";

export const Pacemaker = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path d="M28.91,9.35a4.72,4.72,0,0,0-7.12-.93l-2.1,1.87-2.1-1.87a4.73,4.73,0,0,0-7.13.93,6.21,6.21,0,0,0-1,4.42H7.84V7.2H2.91v9.93A1.71,1.71,0,0,0,2,18.64a1.73,1.73,0,1,0,2.57-1.5V8.86H6.18v6.57H9.91a5.67,5.67,0,0,0,1.38,1.91l.23.2,8.17,7.26,8.17-7.26.22-.2a6.18,6.18,0,0,0,.83-8" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "2 2 28 28",
    };
  }
};

Pacemaker.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Pacemaker.defaultProps = {
  role: "img",
};
