import PropTypes from "prop-types";
import React from "react";

import { classNames } from "@swa-ui/string";

import styles from "./CaptionPointer.module.scss";

/**
 * CaptionPointer is a low level component that provides a simple caret, in one of four directions, that is current only
 * used by Caption.
 */

export const CaptionPointer = (props) => {
  const { direction } = props;

  return (
    <div className={getClass()}>
      <div className={getPositionClass()}>
        <div className={getPointerClass()} />
      </div>
    </div>
  );

  function getClass() {
    return classNames({
      [styles.captionPointer]: true,
      [styles.down]: direction === "down",
      [styles.left]: direction === "left",
      [styles.right]: direction === "right",
      [styles.up]: direction === "up",
    });
  }

  function getPositionClass() {
    return classNames({
      [styles.positionDown]: direction === "down",
      [styles.positionLeft]: direction === "left",
      [styles.positionRight]: direction === "right",
      [styles.positionUp]: direction === "up",
    });
  }

  function getPointerClass() {
    return classNames({
      [styles.down]: direction === "down",
      [styles.hidden]: direction === "hidden",
      [styles.left]: direction === "left",
      [styles.pointer]: true,
      [styles.right]: direction === "right",
      [styles.up]: direction === "up",
    });
  }
};

CaptionPointer.propTypes = {
  /** Defines which direction the CaptionPointer will point. */
  direction: PropTypes.oneOf(["down", "hidden", "left", "right", "up"]),
};
