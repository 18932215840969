import PropTypes from "prop-types";
import React, { useContext, useEffect } from "react";

import { classNames } from "@swa-ui/string";

import { DialogContext } from "../Dialog";
import { Focusable } from "../Focusable";
import styles from "./DialogSubheading.module.scss";

const DELAY_FOR_ANIMATION = 300;

/**
 * DialogSubheading renders a div with children.
 */
export const DialogSubheading = (props) => {
  const { children, className } = props;
  const { describedById, focusRef } = useContext(DialogContext) ?? {};

  useEffect(() => {
    focusRef && setTimeout(() => focusRef.current?.focus(), DELAY_FOR_ANIMATION);
  }, []);

  return (
    <Focusable
      className={classNames(className, styles.dialogSubheading)}
      focusRef={focusRef}
      id={describedById}
    >
      {children}
    </Focusable>
  );
};

DialogSubheading.propTypes = {
  /**
   *  Content to be rendered. This content will be used as the dialogs aria description and
   * will receive initial dialog focus. */
  children: PropTypes.node.isRequired,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,
};
