import PropTypes from "prop-types";
import React from "react";

export const Bed = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path d="M13.3332 8.00004C13.3332 7.26671 12.7332 6.66671 11.9998 6.66671V4.66671C11.9998 3.93337 11.3998 3.33337 10.6665 3.33337H5.33317C4.59984 3.33337 3.99984 3.93337 3.99984 4.66671V6.66671C3.2665 6.66671 2.6665 7.26671 2.6665 8.00004V11.3334H3.55317L3.99984 12.6667H4.6665L5.11317 11.3334H10.8932L11.3332 12.6667H11.9998L12.4465 11.3334H13.3332V8.00004ZM10.6665 6.66671H8.6665V4.66671H10.6665V6.66671ZM5.33317 4.66671H7.33317V6.66671H5.33317V4.66671ZM3.99984 8.00004H11.9998V10H3.99984V8.00004Z" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "2 2 12 12",
    };
  }
};

Bed.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Bed.defaultProps = { role: "img" };
