import PropTypes from "prop-types";
import React from "react";

export const Info = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path
        d="M22.4,11c-1.1,1-2.8,1-3.8,0c-0.9-1-0.8-2.6,0.4-3.6c1.1-1,2.8-1,3.8,0C23.7,8.4,23.5,10,22.4,11 M17.1,27.8
		c-2.5,0.9-4.6-0.1-4.2-2.5c0.4-2.4,2.8-7.6,3.1-8.6s-0.3-1.2-1-0.8c-0.4,0.2-1,0.7-1.5,1.2c-0.1-0.3-0.3-0.6-0.5-0.9
		c0.8-0.8,2.2-2,3.9-2.4c2-0.5,5.3,0.3,3.9,4.1c-1,2.7-1.7,4.6-2.2,6s0.1,1.7,0.9,1.1c0.6-0.4,1.3-1,1.8-1.4
		c0.2,0.4,0.3,0.5,0.5,0.9C21,25.1,18.8,27.2,17.1,27.8 M17.5,0C7.8,0,0,7.8,0,17.5C0,27.1,7.8,35,17.5,35S35,27.1,35,17.5
		C35,7.8,27.2,0,17.5,0"
      />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "0 0 35 35",
    };
  }
};

Info.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Info.defaultProps = {
  role: "img",
};
