import { Checkbox } from "@swa-ui/core";

import { withFormField } from "../withFormField";

/**
 * CheckboxFormField provides support for a standard Checkbox component to be used in a Form.
 */

export const CheckboxFormField = withFormField(Checkbox, {
  useController: true,
});
