const window = typeof global.window !== "undefined" ? global.window : {};

export function getCookie(name) {
  const cookies = `; ${window.document?.cookie}`;
  const parts = cookies.split(`; ${name}=`);

  return parts.length === 2 ? decodeValue(parts.pop().split(";").shift()) : null;
}

export function removeCookie(name, path = "/", domain = getRootDomain()) {
  if (window.document) {
    window.document.cookie = `${name}=;path=${path};domain=${domain};max-age=0;`;
  }
}

export function setCookie(name, value, domain = getRootDomain(), path = "/") {
  if (window.document) {
    window.document.cookie = `${name}=${value};domain=${domain};path=${path};`;
  }
}

export function decodeCookie(value) {
  const base64Payload = value?.split(".")?.[1];

  return base64Payload ? JSON.parse(atob(base64Payload)) : null;
}

function getRootDomain() {
  let root = null;

  if (window.location) {
    root = window.location.hostname.split(".").splice(-2);
    root = (root.length > 1 ? "." : "") + root.join(".");
  }

  return root;
}

function decodeValue(value) {
  return decodeURIComponent(value.replace(/\+/g, " "));
}
