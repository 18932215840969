import { logger } from "@swa-ui/log";

export function logMissingTemplateKeys(placementId, missingTemplateKeys) {
  if (missingTemplateKeys.length) {
    logger.error("Missing template keys", {
      missingTemplateKeys,
      placementId,
    });
  }
}
