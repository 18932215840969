import PropTypes from "prop-types";
import React from "react";

import { useDeviceInfo } from "@swa-ui/browser";

import { applyOverrides } from "../applyOverrides";
import { PlacementFactory } from "../PlacementFactory";
import { PlacementSection } from "../PlacementSection";

export const PageLayout = (props) => {
  const { componentRenderers, placement } = props;
  const { contentItems } = placement ?? {};
  const { screenSize } = useDeviceInfo();

  return contentItems?.map((item, index) => {
    const { id, type } = item;
    const { fullWidth } = applyOverrides(item, screenSize);
    let content;

    if (type === "placement") {
      content = <PlacementFactory additionalProps={{ fullWidth }} id={id} />;
    } else {
      content = componentRenderers[id] && componentRenderers[id]();
    }

    return (
      content && (
        <PlacementSection {...getProps(fullWidth, item)} key={`${id}${index}`}>
          {content}
        </PlacementSection>
      )
    );
  });

  function getProps(fullWidth, item) {
    return {
      ...item,
      fullWidth,
    };
  }
};

PageLayout.propTypes = {
  /** callback functions with custom registered components. */
  componentRenderers: PropTypes.object,

  /** Placement object for displayType page-layout. */
  placement: PropTypes.shape({
    contentItems: PropTypes.arrayOf(
      PropTypes.shape({
        /** Background image/color content for PlacementSection. */
        background: PropTypes.shape({
          color: PropTypes.string,
          imagePath: PropTypes.string,
          type: PropTypes.oneOf(["color", "image"]),
        }),

        /** Value for PlacementSection. */
        callToAction: PropTypes.object,

        /** Flag for PlacementSection. */
        fullWidth: PropTypes.bool,

        /** Value for PlacementSection. */
        heading: PropTypes.object,

        /** Name of the placement or component. */
        id: PropTypes.string,

        /** Value for PlacementSection. */
        layout: PropTypes.oneOf(["default", "compact"]),

        /** Flag for PlacementSection. */
        spaced: PropTypes.bool,

        /** Type of what is going to render. */
        type: PropTypes.oneOf(["component", "placement"]),
      })
    ),
  }),
};
