import React, { useContext, useRef, useState } from "react";

import { useAppSetting } from "@swa-ui/application";
import { getBootstrapData } from "@swa-ui/bootstrap";
import { window } from "@swa-ui/browser";
import { dateFormats, swaDate } from "@swa-ui/date";
import { useForm, yupResolver } from "@swa-ui/form";
import { RecentSearchContext } from "@swa-ui/form";
import { useGeolocation } from "@swa-ui/geolocation";
import { stationMethods } from "@swa-ui/itinerary";
import i18n from "@swa-ui/locale";

import { FLIGHT_HOTEL } from "../defines/packageType";
import { base64Encoder } from "../utils";
import styles from "./useVacationsFormLogic.module.scss";
import { vacationsBookingFormSchema } from "./vacationsBookingFormSchema";

const DEFAULT_DEPART_DATE_OFFSET = 13;
const DEFAULT_RETURN_DATE_OFFSET = 16;

export const useVacationsFormLogic = () => {
  const geolocation = useGeolocation();
  const { addRecentSearch } = useContext(RecentSearchContext);
  const [destinationIataCode, setDestinationIataCode] = useState("");
  const [destinationName, setDestinationName] = useState("");
  const [formData, setFormData] = useState(getDefaultFormData());
  const airDatesData = getBootstrapData("air/air-dates-data");
  const basePathOJTWebapp = useAppSetting("basePathOJTWebapp");
  const destinationSearchResultsLimit = useAppSetting("destinationSearchResultsLimit");
  const methods = useForm({
    resolver: yupResolver(vacationsBookingFormSchema()),
  });
  const returnDateRef = useRef();

  const { getValues, reset, setValue, trigger } = methods;

  return {
    defaultSubmitButtonText: i18n("useVacationsFormLogic__FIND_A_VACATION"),
    formData,
    formProps: {
      departureDateProps: getDepartureDateProps(),
      destinationAirportCodeProps: getDestinationFormFieldProps(),
      formComponentProps: getFormProps(),
      originationAirportCodeProps: getOriginFormFieldProps(),
      packageOptionsProps: getPackageOptionsProps(),
      promoCodeProps: getPromoCodeProps(),
      returnDateProps: getReturnDateProps(),
      submitButtonProps: getSubmitButtonProps(),
      travelerSelectorProps: getTravelerSelectorProps(),
    },
  };

  function renderCalendarAdjunctContent() {
    const calendarDateStringFormat = i18n("useVacationsFormLogic__CALENDAR_DATE_STRING_FORMAT");

    const formattedCurrentLastDateString = swaDate(airDatesData.currentLastBookableDate).format(
      calendarDateStringFormat
    );
    const formattedFutureOpenDateString = swaDate(airDatesData.futureBookingOpenDate).format(
      calendarDateStringFormat
    );
    const formattedFutureCloseDateString = swaDate(airDatesData.futureBookingCloseDate).format(
      calendarDateStringFormat
    );

    return (
      <div className={styles.lastBookableDate}>
        {i18n("useVacationsFormLogic__LAST_BOOKABLE_DATE", { formattedCurrentLastDateString })}
        <div className={styles.bookableScheduleExtension}>
          {i18n("useVacationsFormLogic__ON_OPEN_SCHEDULE", {
            formattedFutureOpenDateString,
            formattedFutureCloseDateString,
          })}
        </div>
        <div className={styles.bookableScheduleExtension}>
          {`${i18n("useVacationsFormLogic__SUBJECT_TO_CHANGE")}`}
        </div>
      </div>
    );
  }

  function getFormProps() {
    return {
      methods,
      onSubmit: handleFormSubmit,
    };
  }

  function getPackageOptionsProps() {
    const { packageType } = formData;

    return {
      name: "packageType",
      onChange: handlePackageOptionsChange,
      value: packageType,
    };
  }

  function getTravelerSelectorProps() {
    const { packageType, travelers } = formData;

    return {
      name: "travelers",
      onChange: handleTravelerSelectorChange,
      packageType,
      travelers,
    };
  }

  function getPromoCodeProps() {
    return {
      componentProps: {
        acceptableCharacters: "1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ",
        value: formData.promoCode,
        maxLength: 20,
        onChange: handlePromoCodeChange,
      },
      label: i18n("useVacationsFormLogic_PromoCode__FORM_FIELD_LABEL"),
      name: "promoCode",
    };
  }

  function getOriginFormFieldProps() {
    const { originationAirportCode } = formData;

    return {
      caption: originationAirportCode
        ? stationMethods.getStationName(getValues("originationAirportCode"))
        : undefined,
      componentProps: {
        defaultValue: originationAirportCode,
        invalidRouteText: i18n("useVacationsFormLogic__INVALID_ROUTE"),
        labelText: i18n("useVacationsFormLogic__FROM"),
        noMatchFoundText: i18n("useVacationsFormLogic__NO_MATCH_FOUND"),
        required: true,
        softEdge: true,
      },
      label: i18n("useVacationsFormLogic__FROM"),
      name: "originationAirportCode",
    };
  }

  function getDestinationFormFieldProps() {
    const { destinationAirportCode } = formData;

    return {
      componentProps: {
        defaultValue: destinationAirportCode,
        labelText: i18n("useVacationsFormLogic__TO"),
        maxItemsToDisplay: 10,
        noMatchFoundText: i18n("useVacationsFormLogic__NO_MATCH_FOUND"),
        onBlur: handleDestinationOnBlur,
        onChange: handleDestinationChange,
        required: true,
        searchResultsLimit: Number(destinationSearchResultsLimit),
        softEdge: true,
      },
      label: i18n("useVacationsFormLogic__TO"),
      name: "destinationAirportCode",
    };
  }

  function getDepartureDateProps() {
    const { departureDate } = formData;

    return {
      caption: getDateCaption(departureDate),
      componentProps: getCalendarProps(i18n("useVacationsFormLogic__DEPART_DATE_ARIA"), "start"),
      label: i18n("useVacationsFormLogic__DEPART_DATE"),
      name: "departureDate",
    };
  }

  function getReturnDateProps() {
    const { returnDate } = formData;

    return {
      caption: getDateCaption(returnDate),
      componentProps: getCalendarProps(i18n("useVacationsFormLogic__RETURN_DATE_ARIA"), "end"),
      label: i18n("useVacationsFormLogic__RETURN_DATE"),
      name: "returnDate",
    };
  }

  function getCalendarProps(ariaLabel, focusField) {
    const { departureDate, returnDate } = formData;

    return {
      adjunctContent: renderCalendarAdjunctContent(),
      "aria-label": ariaLabel,
      closeContent: i18n("useVacationsFormLogic__CLOSE"),
      dateEnd: returnDate,
      dateStart: departureDate,
      daysOfWeekInitials: i18n("useVacationsFormLogic__DAYS_OF_WEEK_INITIALS"),
      defaultValue: focusField === "end" ? returnDate : departureDate,
      firstBookableDate: swaDate().add(1, "day").format(dateFormats.default),
      focusField,
      format: i18n("useVacationsFormLogic__CALENDAR_INPUT_FORMAT"),
      lastBookableDate: swaDate(airDatesData.currentLastBookableDate).format(dateFormats.default),
      numberCalendars: 2,
      numberDates: 2,
      onSelectEnd: handleSelectEnd,
      onSelectStart: handleSelectStart,
      ref: focusField === "end" ? returnDateRef : null,
      required: true,
      suffixIcon: { color: "trip-selection-calendar-suffix-icon", name: "Calendar" },
      title: i18n("useVacationsFormLogic__DATE_SELECTOR_TITLE"),
    };
  }

  function getSubmitButtonProps() {
    return {
      componentProps: {
        fullWidth: true,
        styleType: "primary",
      },
      name: "submitVacationsBookingForm",
    };
  }

  function handleDestinationOnBlur(event) {
    if (event.target.isValid) {
      setDestinationIataCode(event.target.iataCode);
      setDestinationName(event.target.name);
    } else {
      validateToField();
    }
  }

  function handleDestinationChange(event) {
    setDestinationIataCode(event.target.iataCode);
    setDestinationName(event.target.name);
  }

  function getDefaultDepartDate() {
    return swaDate()
      .set("date", swaDate().get("date") + DEFAULT_DEPART_DATE_OFFSET)
      .format(dateFormats.default);
  }

  function getDefaultReturnDate() {
    return swaDate()
      .set("date", swaDate().get("date") + DEFAULT_RETURN_DATE_OFFSET)
      .format(dateFormats.default);
  }

  function validateToField() {
    setValue("destinationAirportCode", "");
    trigger("destinationAirportCode");
  }

  function getDateCaption(dateString) {
    return dateString
      ? swaDate(dateString).format(i18n("useVacationsFormLogic__CALENDAR_CAPTION_FORMAT"))
      : "";
  }

  function getDefaultFormData() {
    return {
      originationAirportCode: geolocation?.nearestStation || "",
      destinationAirportCode: "",
      promoCode: "",
      departureDate: getDefaultDepartDate(),
      packageType: FLIGHT_HOTEL,
      returnDate: getDefaultReturnDate(),
      travelers: [
        {
          adults: 2,
          children: 0,
          childrenAge: [],
          lapChildren: 0,
        },
      ],
    };
  }

  function handlePackageOptionsChange(packageType) {
    const defaultFormData = getDefaultFormData();

    reset(defaultFormData);
    setFormData({ ...defaultFormData, packageType });
    setValue("packageType", packageType);
  }

  function handleTravelerSelectorChange(value) {
    setFormData({ ...formData, travelers: value });
    setValue("travelers", value);
  }

  function handlePromoCodeChange(event) {
    const { value: eventValue } = event.target;
    const promoCodeValue = eventValue.replace(/\s/g, "").toUpperCase();
    setFormData({ ...formData, promoCode: promoCodeValue });
    setValue("promoCode", promoCodeValue);
  }

  function handleSelectEnd(value) {
    const { departureDate } = formData;
    const newFormData = { ...formData };
    let selectionError = departureDate === value;

    if (swaDate(departureDate).isSameOrAfter(value, "day")) {
      newFormData.departureDate = value;
      newFormData.returnDate = "";
      setValue("departureDate", value);
      setValue("returnDate", "");
      selectionError = true;
    } else {
      newFormData.returnDate = value;
      setValue("returnDate", value);
    }

    setFormData(newFormData);

    return selectionError;
  }

  function handleSelectStart(value) {
    const { returnDate } = formData;
    const newFormData = { ...formData };

    returnDateRef.current.focus();
    if (swaDate(value).isAfter(returnDate, "day")) {
      newFormData.returnDate = "";
      setValue("returnDate", "");
    }

    setValue("departureDate", value);
    newFormData.departureDate = value;

    setFormData(newFormData);
  }

  function handleFormSubmit(submittedFormData) {
    const encodedURL = getEncodedURL(submittedFormData);

    if (destinationName) {
      updateRecentSearches(submittedFormData, encodedURL);
      window.open(encodedURL, "_blank");
    } else {
      validateToField();
    }
  }

  function getEncodedURL(submittedFormData) {
    return `${basePathOJTWebapp}/package/dl/results?productType=package&search=${base64Encoder(
      submittedFormData
    )}`;
  }

  function updateRecentSearches(submittedFormData, encodedURL) {
    const { departureDate, originationAirportCode, packageType, returnDate, travelers } =
      submittedFormData;
    let adults = 0;
    let children = 0;

    travelers.forEach((room) => {
      adults += room.adults;
      children += room.children + room.lapChildren;
    });

    addRecentSearch(
      {
        adults,
        children,
        departureDate,
        desktopImage: `/swa-resources/images/swav/my_recent_searches/${destinationIataCode.toUpperCase()}/desktop.png`,
        destinationAirportCode: destinationName,
        mobileImage: `/swa-resources/images/swav/my_recent_searches/${destinationIataCode.toUpperCase()}/mobile.png`,
        originationAirportCode,
        packageType,
        returnDate,
        rooms: travelers.length,
        searchVacationRedirectPageUrl: encodedURL,
      },
      packageType
    );
  }
};
