"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useTravelerSelectionModalAnalytics = void 0;
var _react = require("react");
var _reactRouterDom = require("react-router-dom");
var _application = require("@swa-ui/application");
var _routes = require("./defines/routes");
var useTravelerSelectionModalAnalytics = exports.useTravelerSelectionModalAnalytics = function useTravelerSelectionModalAnalytics(isCaptionVisible) {
  var _useLocation = (0, _reactRouterDom.useLocation)(),
    pathname = _useLocation.pathname;
  var _useContext = (0, _react.useContext)(_application.MktgDataContext),
    sendAdobeEventData = _useContext.sendAdobeEventData;
  (0, _react.useEffect)(function () {
    if (pathname === _routes.ROUTES.vacations && isCaptionVisible) {
      var adobeAnalyticsObj = {
        event: "clickaction",
        eventinfo: {
          eventtype: "button"
        },
        page: {
          eventdescription: "modal:room + guest selection",
          pagename: "swav homepage",
          section: "marketing"
        }
      };
      sendAdobeEventData(adobeAnalyticsObj, "travelerSelectionModal");
    }
  }, [isCaptionVisible, pathname]);
};