/**
 * Determines if two rectangles intersect.
 *
 * @param {number} minAx - The minimum x-coordinate of rectangle A.
 * @param {number} minAy - The minimum y-coordinate of rectangle A.
 * @param {number} maxAx - The maximum x-coordinate of rectangle A.
 * @param {number} maxAy - The maximum y-coordinate of rectangle A.
 * @param {number} minBx - The minimum x-coordinate of rectangle B.
 * @param {number} minBy - The minimum y-coordinate of rectangle B.
 * @param {number} maxBx - The maximum x-coordinate of rectangle B.
 * @param {number} maxBy - The maximum y-coordinate of rectangle B.
 * @returns {boolean} - Returns true if the rectangles intersect, false otherwise.
 */

const doRectanglesIntersect = (minAx, minAy, maxAx, maxAy, minBx, minBy, maxBx, maxBy) => {
  const aboveB = minAy > maxBy;
  const belowB = maxAy < minBy;
  const leftOfB = maxAx < minBx;
  const rightOfB = minAx > maxBx;

  return !(leftOfB || rightOfB || aboveB || belowB);
};

export { doRectanglesIntersect };
