import PropTypes from "prop-types";
import React from "react";

export const ChangeFlight = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <polygon points="25.5,20.8 25.5,20.8 14.4,20.8 14.4,16.8 7.9,22.6 14.4,28.4 14.4,24.7 25.5,24.7" />
      <polygon points="9.3,15.4 9.3,15.4 20.4,15.4 20.4,19.4 26.8,13.5 20.4,7.7 20.4,11.5 9.3,11.5 9.3,11.5" />
      <path d="M17.6,31.3c-7.6,0-13.7-6.1-13.7-13.7S10,3.9,17.6,3.9s13.7,6.2,13.7,13.7C31.3,25.2,25.2,31.3,17.6,31.3 M17.6,0C7.9,0,0,7.9,0,17.6s7.9,17.6,17.6,17.6s17.6-7.9,17.6-17.6S27.4,0,17.6,0" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "0 0 36 36",
    };
  }
};

ChangeFlight.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

ChangeFlight.defaultProps = {
  role: "img",
};
