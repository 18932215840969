import * as yup from "yup";

// TODO - Move the schema to swa-ui-encryption package alongwith the schema defined in swa-ui-air-baggage so all encryption schemas are in one place. PEG-349
export const getOJTManageMyVacationSchema = () =>
  yup.object().shape({
    bookingReference: yup
      .string()
      .trim()
      .matches(/^[A-Za-z0-9]*$/)
      .max(7)
      .min(6),
    firstName: yup
      .string()
      .trim()
      .matches(/^[A-Za-z ]*$/)
      .max(30),
    surname: yup
      .string()
      .trim()
      .matches(/^[A-Za-z ]*$/)
      .max(30),
  });
