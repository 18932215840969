import PropTypes from "prop-types";
import React from "react";

import i18n from "@swa-ui/locale";
import { classNames } from "@swa-ui/string";

import styles from "./FlightTime.module.scss";

/**
 * FlightTime displays formatted time information.
 */

export const FlightTime = (props) => {
  const { className, time, type } = props;

  return <div className={classNames(className, styles.flightTime)}>{renderTimeInfo()}</div>;

  function renderTimeInfo() {
    const currentTime = time.split(":");
    const hours = parseInt(currentTime[0]);
    const minutes = parseInt(currentTime[1]);
    const timeOfDay = i18n("FlightTime__AM_PM", {
      TIMEPERIOD: hours >= 12 ? "PM" : "AM",
    });
    let timeValue = hours === 0 || hours === 12 ? "12" : (hours + 12) % 12;

    timeValue += minutes < 10 ? `:0${minutes}` : `:${minutes}`;

    return (
      <span aria-label={getAriaDescription(timeValue, timeOfDay)} className={styles.time}>
        <span className={styles.timeValue}>{timeValue}</span>
        <span className={styles.timeOfDay}>{timeOfDay}</span>
      </span>
    );
  }

  function getAriaDescription(timeValue, timeOfDay) {
    const typeLabel = i18n(
      type === "origination" ? "FlightTime__DEPARTS_ARIA" : "FlightTime__ARRIVES_ARIA"
    );

    return `${typeLabel} ${timeValue.replace(":", " ").replace("00", "")} ${timeOfDay
      .split("")
      .join(" ")},`;
  }
};

FlightTime.propTypes = {
  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Time value to be passed to the component. */
  time: PropTypes.string.isRequired,

  /** Type value to be used to decipher between arrival flight or departing flight. */
  type: PropTypes.oneOf(["origination", "destination"]),
};

FlightTime.defaultProps = {};
