import PropTypes from "prop-types";
import React from "react";

import { classNames, convertCamelCaseToSpaceSeparated } from "@swa-ui/string";

import colors from "../defines/colors";
import { Endow, endowPropTypes } from "../Endow";
import { getColorVariable } from "../getColorVariable";
import { ExternalSvg } from "./ExternalSvg";
import * as svgList from "./svgs";

/**
 * Svg provides an common entry point for all SVGs, which are individual, separate components. Svg
 * simply instantiate the desired child component, but provides an Endow wrapper to enable
 * transformation capabilities. See Endow for more info.
 */

export const Svg = (props) => {
  const {
    actions,
    background,
    border,
    className,
    delay,
    disabled,
    duration,
    fillColor,
    height,
    id,
    name,
    onClick,
    onTransformationEnd,
    size,
    src,
    strokeColor,
    style,
    svgClassName,
    width,
  } = props;
  const Component = svgList[name];

  return src ? (
    <Endow {...getProps()}>
      <ExternalSvg name={name} src={src} {...getSvgProps()} />
    </Endow>
  ) : Component ? (
    <Endow {...getProps()}>
      <Component {...getSvgProps()} />
    </Endow>
  ) : null;

  function getProps() {
    return {
      actions,
      background,
      border,
      className,
      delay,
      disabled,
      duration,
      height: getHeight(),
      id,
      onClick,
      onTransformationEnd,
      style,
    };
  }

  function getSvgProps() {
    return {
      "aria-hidden": props["aria-hidden"],
      "aria-label": convertCamelCaseToSpaceSeparated(props["aria-label"] || props.name),
      className: classNames(svgClassName),
      fillColor: getColorVariable(fillColor),
      height: getHeight(),
      strokeColor: getColorVariable(strokeColor),
      width: size || width || height,
    };
  }

  function getHeight() {
    return size || height || width;
  }
};

export const svgPropTypes = {
  /**
   * Svgs can have generic "action" behaviors, such as rotating 180 degress around the z or x axis.
   * For some actions, applying more than one behavior would cancel out the other.
   */
  actions: endowPropTypes.actions,

  /** aria-hidden text to hide non-interactive content from the accessibility API icon. */
  "aria-hidden": PropTypes.string,

  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /** Optional background to surround svg. */
  background: endowPropTypes.background,

  /** Optional border to surround svg. */
  border: endowPropTypes.border,

  /**
   * Additional classes for positioning the component. Given classes may only position this component
   *  for layout purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Milliseconds before transformation begins. */
  delay: endowPropTypes.delay,

  /** Indicates Svg should apply disabled styling. */
  disabled: endowPropTypes.disabled,

  /**
   * Duration of transformation when an action is animated. When the action(s) given don't cause a
   * transformation, duration is irrelevant.
   */
  duration: endowPropTypes.duration,

  /** Svgs can use any standard brand color or the name of a CSS var. */
  fillColor: PropTypes.oneOfType([PropTypes.oneOf(Object.keys(colors)), PropTypes.string]),

  /** Indicates SVG's height. The width, if not given, will be sized according to SVG's aspect ratio. */
  height: PropTypes.string,

  /** ID to be added to the element. */
  id: PropTypes.string,

  /** Name of svg such as Airlines or Heart. */
  name: PropTypes.oneOfType([PropTypes.oneOf(Object.keys(svgList)), PropTypes.string]),

  /** Callback that will be called when Svg is clicked. */
  onClick: PropTypes.func,

  /** Informs the caller if actions is given that the animation has concluded. */
  onTransformationEnd: endowPropTypes.onTransformationEnd,

  /** Indicates SVG's height. The width will be sized according to SVG's aspect ratio. */
  size: PropTypes.string,

  /** Defines the complete file path and file name without extension. */
  src: PropTypes.string,

  /** SVGs can use any standard brand color or the name of a CSS var. */
  strokeColor: PropTypes.oneOfType([PropTypes.oneOf(Object.keys(colors)), PropTypes.string]),

  /** Object to style the HTML span element. */
  style: endowPropTypes.style,

  /** Class to be appliced to svg, not to the other container which how the className props is applied. */
  svgClassName: PropTypes.string,

  /** Indicates SVG's width. The height, if not given, will be sized according to SVG's aspect ratio. */
  width: PropTypes.string,
};

Svg.propTypes = svgPropTypes;
