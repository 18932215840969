import PropTypes from "prop-types";
import React from "react";

import { Autocomplete, autocompletePropTypes } from "@swa-ui/core";
import i18n from "@swa-ui/locale";

/**
 * SuffixAutocomplete facilitates selecting a suffix by filtering selectable suffixes based on users input.
 *
 * Note: The application ingesting the SuffixAutocomplete must pass an array of suffixes.
 */

export const SuffixAutocomplete = React.forwardRef((props, ref) => {
  const {
    className,
    defaultValue,
    disabled,
    error,
    id,
    noMatchFoundText,
    onBlur,
    onChange,
    required,
    suffixList,
    value,
  } = props;

  return <Autocomplete {...getProps()} />;

  function getProps() {
    return {
      "aria-describedby": props["aria-describedby"],
      className,
      defaultValue,
      disabled,
      error,
      id,
      list: suffixList,
      noMatchFoundText: noMatchFoundText ?? i18n("SuffixAutocomplete__NO_MATCH_FOUND"),
      onBlur: handleBlur,
      onChange,
      ref,
      required,
      value,
    };
  }

  function handleBlur(event) {
    onBlur && onBlur(event);
    onChange && onChange(event);
  }
});

SuffixAutocomplete.propTypes = {
  /** aria-describedby id to element which provides additional accessibility description of input element. */
  "aria-describedby": PropTypes.string,

  /** Additional class for the component to provide a background color. */
  className: PropTypes.string,

  /**
   * Initial values to select in list. Each value should be separated by a pipe character. For example:
   * "option one|option three".
   */
  defaultValue: PropTypes.string,

  /** Indicates Input should apply disabled styling and ignore mouse and key events. */
  disabled: autocompletePropTypes.disabled,

  /**
   * Indicates Dropdown should apply error styling and apply aria-invalid attribute -- in essence, Input will
   * provide error styling.
   */
  error: autocompletePropTypes.error,

  /** ID to be added to input element. */
  id: autocompletePropTypes.id,

  /** Text to be displayed when no matches are found in the list. */
  noMatchFoundText: PropTypes.string,

  /** Optional callback to learn of selection changes. */
  onChange: autocompletePropTypes.onChange,

  /** Indicates Input should apply aria-required attribute. */
  required: PropTypes.bool,

  /** Suffixes to be displayed in dropdown.  */
  suffixList: PropTypes.arrayOf(PropTypes.string),

  /** Allows the application to control the Input's value. */
  value: PropTypes.string,
};

SuffixAutocomplete.defaultProps = {
  suffixList: [],
};
