import React, { useContext, useMemo } from "react";
import { useLocation } from "react-router-dom";

import { fireTrack, sendToAdobeDataLayer } from "@swa-ui/analytics";
import { window } from "@swa-ui/browser";
import { PlacementsContext } from "@swa-ui/placements";

import { MboxContext } from "../MboxProvider";
import { MktgDataContext } from "../MktgDataProvider";
import { MktgMediatorContext } from "../MktgMediatorProvider";
import { RulesEngine, rulesEnginePropTypes } from "../RulesEngine";
import { useAppSetting } from "../useAppSetting";
import { useMktgData } from "../useMktgData";
import { usePageId } from "../usePageId";
import { defaultRules, defaultRulesDictionary } from "./defaultRules";

/**
 * MktgRulesEngine provides a way to fire mbox and placement calls
 * based on mktg data facts.
 */

export const MktgRulesEngine = (props) => {
  const { allowUndefinedFacts, eventCallbacks, facts, operators, rules, rulesDictionary } = props;
  const appSettingRules = useAppSetting("rules", []);
  const appSettingFacts = useAppSetting("facts", {});
  const appSettingsRulesDictionary = useAppSetting("rulesDictionary", {});
  const { loading, startUpdateCycle } = useContext(MktgMediatorContext);
  const {
    appContextMap = {},
    loading: placementsLoading,
    loadPlacements,
    placementsConfig,
  } = useContext(PlacementsContext);
  const { loadingDetails: mboxLoadingDetails, loadMboxes } = useContext(MboxContext);
  const { adobeData } = useContext(MktgDataContext);
  const { mktgData } = useMktgData();
  const { pageId } = usePageId();
  const { pathname } = useLocation();
  const allRules = useMemo(getRules, [defaultRules, appSettingRules]);
  const allRulesDictionaries = useMemo(getRulesDictionary, [
    rulesDictionary,
    appSettingsRulesDictionary,
  ]);
  const placementsPageContext = placementsConfig?.pageContext || {};
  const placementsPageContextRequired = useAppSetting("placementsPageContextRequired", false);

  return <RulesEngine {...getRuleEngineProps()} />;

  function getRuleEngineProps() {
    return {
      allowUndefinedFacts,
      eventCallbacks: getEventCallbacks(),
      facts: getFacts(),
      operators,
      rules: allRules,
      rulesDictionary: allRulesDictionaries,
    };
  }

  function getFacts() {
    return {
      ...facts,
      adobeDataLayer: adobeData,
      isDefault: true,
      loadedPathname: loading?.last?.pathname,
      loadingPathname: loading?.current?.pathname,
      mboxLoadedPathname: mboxLoadingDetails?.last?.pathname,
      mboxLoadingPathname: mboxLoadingDetails?.current?.pathname,
      mktg_data: mktgData,
      pathname,
      placementsAppContext: appContextMap,
      placementsAppContextValue: Array.from(new Set(Object.values(appContextMap).flat()))?.join(
        ","
      ),
      placementsLoadedPathname: placementsLoading?.last?.pathname,
      placementsLoadingPathname: placementsLoading?.current?.pathname,
      placementsPageContext,
      placementsPageContextRequired,
      ...appSettingFacts,
    };
  }

  function getEventCallbacks() {
    return {
      ...eventCallbacks,
      fireAdobeDataLayerEvent: (params) => {
        sendToAdobeDataLayer({ ...adobeData, event: params.value });
      },
      fireOtterCall: () => {
        fireTrack("otter", { page: pageId });
      },
      loadMboxes: () => {
        loadMboxes(pathname);
      },
      loadMboxesAndPlacements: () => {
        startUpdateCycle(pathname, placementsPageContext);
      },
      loadPlacements: () => {
        loadPlacements(pathname, placementsPageContext);
      },
      reloadPage: () => {
        window?.location?.reload();
      },
    };
  }

  function getRules() {
    return [...defaultRules, ...rules, ...appSettingRules];
  }

  function getRulesDictionary() {
    return {
      ...defaultRulesDictionary,
      ...rulesDictionary,
      ...appSettingsRulesDictionary,
    };
  }
};

MktgRulesEngine.propTypes = rulesEnginePropTypes;
MktgRulesEngine.defaultProps = {
  allowUndefinedFacts: true,
  rules: [],
};
