import React, { useEffect, useMemo } from "react";

import { useAppSetting } from "@swa-ui/application";
import { getSearchParam } from "@swa-ui/browser";
import { Toast, useCaption } from "@swa-ui/core";
import i18n, { updateI18n } from "@swa-ui/locale";
import { logger } from "@swa-ui/log";

const DEFAULT_MESSAGE_TYPE = "information";

/**
 * StartupMessage processes query parameters on initial page load to display a message over the page, should
 * the message be an approved message by the application.
 */
export const StartupMessage = () => {
  const { captionRef, isCaptionVisible, showCaption } = useCaption();
  const approvedStartupMessages = useAppSetting("startupMessages", {});
  const messageI18nKey = useMemo(() => getSearchParam("startupMessage"), []);
  const messageType = useMemo(
    () => getSearchParam("startupMessageType") ?? DEFAULT_MESSAGE_TYPE,
    []
  );
  const isApproved = isApprovedStartupMessage(messageI18nKey);

  useEffect(() => {
    if (isApproved) {
      updateI18n(approvedStartupMessages);
      showCaption();
    } else {
      logger.error(`Invalid startup message: ${messageI18nKey}`, { component: "StartupMessage" });

      if (messageType === "error" || messageType === "danger") {
        showCaption();
      }
    }
  }, []);

  return isCaptionVisible && <Toast {...getToastProps()} />;

  function getToastProps() {
    let toastProps = {
      "aria-label-close": i18n("StartupMessage__CLOSE_BUTTON_ARIA"),
      captionRef,
      children: i18n(messageI18nKey),
      messageProps: {
        styleType: messageType,
      },
      on: isCaptionVisible,
    };

    if (!isApproved) {
      toastProps = {
        ...toastProps,
        children: i18n("StartupMessage__ERROR_DEFAULT_TEXT"),
        messageProps: {
          headingProps: { children: i18n("StartupMessage__ERROR_DEFAULT_TITLE") },
          styleType: "danger",
        },
      };
    }

    return toastProps;
  }

  function isApprovedStartupMessage(i18nKey) {
    return Object.keys(approvedStartupMessages).includes(i18nKey);
  }
};

StartupMessage.propTypes = {};
