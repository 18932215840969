import PropTypes from "prop-types";
import React from "react";

export const Movie = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <polygon points="22.8,12.6 26.7,12.6 30.1,8.7 26.2,8.7" />
      <polygon points="29.5,12.6 32.9,12.6 33,8.7" />
      <polygon points="16,12.6 19.9,12.6 23.3,8.7 19.5,8.7" />
      <polygon points="9.3,12.6 13.1,12.6 16.6,8.7 12.7,8.7" />
      <polygon points="2.5,12.6 6.4,12.6 9.8,8.7 5.9,8.7" />
      <polygon points="4,8 0.2,12.1 0.2,4.1" />
      <polygon points="22.2,1.2 26.1,0.8 30,4.3 26.1,4.8" />
      <polygon points="28.9,0.4 32.3,0 32.8,3.9" />
      <polygon points="15.5,2 19.4,1.6 23.2,5.1 19.4,5.6" />
      <polygon points="8.8,2.8 12.7,2.4 16.5,5.9 12.7,6.4" />
      <polygon points="2.1,3.7 5.9,3.2 9.8,6.7 6,7.2" />
      <path d="M0,29.1c0,1.3,1,2.3,2.3,2.3h28.2c1.3,0,2.3-1,2.3-2.3V15.1H0V29.1z" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "0 0 33 31.4",
    };
  }
};

Movie.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Movie.defaultProps = {
  role: "img",
};
