import PropTypes from "prop-types";
import React, { useState } from "react";

import { NumberSelector } from "@swa-ui/core";
import i18n from "@swa-ui/locale";

import styles from "./TravelerSelectorInputField.module.scss";

export const TravelerSelectorInputField = (props) => {
  const { defaultValue, maximumValue, minimumValue, onChange, passengerType, roomIndex } = props;
  const [value, setValue] = useState(defaultValue ?? 0);
  const type = passengerType.toUpperCase();

  return (
    <div className={styles.container}>
      <div {...getContainerProps()}>
        <span aria-hidden className={styles.label}>
          {i18n(`TravelerSelectorInputField__${type}_LABEL`)}
        </span>
        <span aria-hidden className={styles.description}>
          {i18n(`TravelerSelectorInputField__${type}_DESCRIPTION`)}
        </span>
        {passengerType === "children" && (
          <span aria-hidden className={styles.additionalMessage}>
            {i18n(`TravelerSelectorInputField__${type}_ADDITIONAL_MESSAGE`)}
          </span>
        )}
      </div>
      <NumberSelector {...getInputProps()} />
    </div>
  );

  function getContainerProps() {
    return {
      className: styles.labelContainer,
      id: `${passengerType}PassengersRoom${roomIndex}`,
    };
  }

  function getInputProps() {
    return {
      "aria-label-decrement": i18n(`TravelerSelectorInputField__ARIA_LABEL__${type}_DECREMENT`),
      "aria-label-increment": i18n(`TravelerSelectorInputField__ARIA_LABEL__${type}_INCREMENT`),
      "aria-label-value": i18n(`TravelerSelectorInputField__ARIA_LABEL__${type}_VALUE`, {
        roomNumber: roomIndex + 1,
      }),
      defaultValue: value,
      maximumValue,
      minimumValue,
      onChange: handleChange,
      value,
    };
  }

  function handleChange(newValue) {
    setValue(newValue);
    onChange(newValue);
  }
};

TravelerSelectorInputField.propTypes = {
  /** Original value from the form, used in component initialization. */
  defaultValue: PropTypes.number,

  /** Maximum value allowed on the number selector. */
  maximumValue: PropTypes.number,

  /** Minimum value allowed on the number selector. */
  minimumValue: PropTypes.number,

  /** Callback function for when the NumberSelector value changes. */
  onChange: PropTypes.func.isRequired,

  /** Passenger type to be used in internationalization strings. */
  passengerType: PropTypes.string.isRequired,

  /** Room number used in id for automation testing. */
  roomIndex: PropTypes.number.isRequired,
};
