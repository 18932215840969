import { useContext, useRef } from "react";

import { getBootstrapData } from "@swa-ui/bootstrap";
import { isResponseOk, useFetch } from "@swa-ui/fetch";

import { AuthContext } from "../../AuthProvider";

const API_BASE_PATH = "/api/mfa/v1/mfa";
const CIAM_TIER_CODE = "080";

export const useMfaServices = () => {
  const aborted = useRef(false);
  const errored = useRef(false);
  const {
    get,
    loading: initializeLoading,
    response: initializeResponse,
  } = useFetch("", {
    cachePolicy: "no-cache",
    onAbort: () => {
      aborted.current = true;
    },
    onError: () => {
      errored.current = true;
    },
  });
  const { loading, post, response } = useFetch("", {
    cachePolicy: "no-cache",
    onAbort: () => {
      aborted.current = true;
    },
    onError: () => {
      errored.current = true;
    },
  });
  const {
    auth: { idToken },
    environmentTier,
  } = useContext(AuthContext);
  const ids = useRef({});
  const mfaSettings = getBootstrapData("mfa-settings");

  return {
    getMfaDevices,
    loading: loading || initializeLoading,
    resendMfaOtp,
    restartMfa,
    sendMfaOtp,
    validateMfaOtp,
  };

  async function getMfaDevices() {
    let devices = [];
    let callStatus;
    const initializeBody = await get(getInitializationPath());

    callStatus = getCallStatus(initializeResponse, initializeBody);

    if (isResponseOk(initializeResponse)) {
      ids.current = { connectionId: initializeBody.connectionId, id: initializeBody.id };

      const devicesBody = await post(getPath(), getRequestBody({ id_token: idToken }));

      callStatus = getCallStatus(response, devicesBody);

      if (isResponseOk(response)) {
        const { devices: responseDevices = [] } =
          devicesBody?.screen?.properties?.customHTML?.value?.deviceDetails;

        updateCurrentId(devicesBody);

        devices = responseDevices.sort((a, b) => a.deviceType.localeCompare(b.deviceType));
      }
    }

    return { callStatus, devices };
  }

  async function sendMfaOtp(deviceType) {
    const body = await post(getPath(), getRequestBody({ method: deviceType }));

    updateCurrentId(body);

    return { callStatus: getCallStatus(response, body) };
  }

  async function resendMfaOtp() {
    const body = await post(getPath(), getRequestBody({ action: "resend" }));

    updateCurrentId(body);

    return { callStatus: getCallStatus(response, body) };
  }

  async function restartMfa() {
    const body = await post(getPath(), getRequestBody({ action: "restart" }));

    updateCurrentId(body);

    return { callStatus: getCallStatus(response, body) };
  }

  async function validateMfaOtp(otp) {
    const body = await post(
      getPath(),
      getRequestBody({
        action: "verify",
        otp,
      })
    );

    updateCurrentId(body);

    return { callStatus: getCallStatus(response, body) };
  }

  function getInitializationPath() {
    return `${getBasePath()}/as/authorize?client_id=${
      mfaSettings?.mfaClientIds?.[environmentTier]
    }&response_type=code&response_mode=pi.flow&scope=openid`;
  }

  function getPath() {
    return `${getBasePath()}/davinci/connections/${
      ids.current.connectionId
    }/capabilities/customHTMLTemplate`;
  }

  function getBasePath() {
    return `${API_BASE_PATH}/${mfaSettings?.mfaEnvIds?.[environmentTier]}`;
  }

  function getRequestBody(parameters) {
    return {
      eventName: "continue",
      id: ids.current.id,
      nextEvent: {
        constructType: "skEvent",
        eventName: "continue",
        eventType: "post",
        params: [],
        postProcess: {},
      },
      parameters: {
        buttonType: "form-submit",
        buttonValue: "submit",
        ...parameters,
      },
    };
  }

  function getCallStatus(serviceResponse, body) {
    const errorCode = isResponseOk(serviceResponse)
      ? undefined
      : (body && body.code?.match?.(/^\d{9}$/)?.[0]) ??
        `${serviceResponse.status ?? "000"}${CIAM_TIER_CODE}000`;

    if (errored.current && body === undefined) {
      serviceResponse = {
        data: {
          code: errorCode,
        },
      };
    }

    return {
      aborted: aborted.current,
      errorCode,
      ok: !errorCode && !aborted.current,
      serviceResponse: serviceResponse,
    };
  }

  function updateCurrentId(body) {
    ids.current.id = body && body.id;
  }
};
