import { getExperienceId } from "@swa-ui/application";
import { logger } from "@swa-ui/log";

import { getApiKey } from "../getApiKey";
import { getChannelId } from "../getChannelId";

export function initLogger(appId) {
  const apiKey = getApiKey();
  const channelId = getChannelId();
  const experienceId = getExperienceId();

  logger.init({ appId, apiKey, channelId, experienceId });
}
