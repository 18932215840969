import PropTypes from "prop-types";
import React from "react";

export const Baggage = (props) => {
  const { className, role, size, style } = props;

  return (
    <svg {...getProps()}>
      <path d="M41.6,11.1c0-0.8-1-1.7-1.7-1.7H37v24.2h2.9c0,0,1.7-0.1,1.7-1.7V11.1z" />
      <rect x="7.9" y="9.3" width="26" height="24.2" />
      <path d="M15.7,3.4h5.5h4.8v3.5h3.5V1.5C29.5,0.8,28.8,0,28,0H13.7c-0.8,0-1.5,0.8-1.5,1.5v5.3h3.5V3.4z" />
      <path d="M0,11.1c0-0.8,1-1.7,1.7-1.7h2.9v24.2H1.7c0,0-1.7-0.1-1.7-1.7L0,11.1z" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className,
      height: size,
      role,
      strokeWidth: "0",
      style,
      viewBox: "0 0 41.5 33.5",
    };
  }
};

Baggage.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * custom allows for component to implement styling for a specific characteristic. There is no obligation for a
   * component to implement the custom prop.
   */
  custom: PropTypes.object,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.string,

  /**
   * CSS object to applied to SVG element. Currently, style is expected to only define stroke and fill color, but
   * there is not such a restriction or requirement at this time.
   */
  style: PropTypes.object,
};

Baggage.defaultProps = {
  role: "img",
};
