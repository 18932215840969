import { getBootstrapData } from "@swa-ui/bootstrap";

let supportedTypes;

export const currencyTypes = {
  getSupportedTypes() {
    if (!supportedTypes) {
      supportedTypes = Object.keys(getBootstrapData("currency-settings"));
    }

    return supportedTypes;
  },
};
